<ngb-accordion #acc="ngbAccordion" [closeOthers]="true">
  <ngb-panel title="{{ dato.titolo }}" *ngFor="let dato of dati">
    <ng-template ngbPanelContent>
      <div class="table-responsive">
        <div class="list-table table-even">
          <table class="table-sm" >
            <tbody>
            <tr *ngFor="let contenuto of dato.dati">
              <td style="vertical-align: middle; width: 30%" >{{ contenuto.nome }}</td>
              <td style="{{contenuto.testo&&!contenuto.link?'text-align: justify':'text-align: center'}}; vertical-align: middle; padding-left: 40px">
                <span *ngIf="contenuto.testo">{{ contenuto.testo }}</span>
                <a class="btn btn-secondary btn-sm" *ngIf="contenuto.link" target="_blank" href="{{contenuto.link}}" download>
                  <svg class="s-icon icon-sm">
                    <use href="#download"></use>
                  </svg>
                </a>
                <iframe *ngIf="contenuto.video" [src]="sanitize(contenuto.video)" width="640" height="480" allow="autoplay"></iframe>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </ng-template>
  </ngb-panel>

</ngb-accordion>


