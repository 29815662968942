import {Component, OnInit} from '@angular/core';
import {FormGroup, FormControl} from '@angular/forms';
import {Router} from '@angular/router';
import {AslValidator, Lotti, Page, Strutture} from 'src/app/model/Models';
import {ApiService} from 'src/app/services/api.service';
import {SessionService} from 'src/app/services/session.service';
import {ToastService} from 'src/app/services/toast.service';
import {firstValueFrom} from "rxjs";
import {ModalDismissReasons, NgbDateStruct, NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  templateUrl: './dati-in-ingresso.component.html',
  styleUrls: ['./dati-in-ingresso.component.css']
})
export class DatiInIngressoComponent implements OnInit {

  isLoading: boolean = false;
  isNotDaValidare: boolean = false;
  ricercaForm!: FormGroup;
  currentYear = new Date().getFullYear();
  yearArray: any = [];
  strutture: Page<Strutture> = new Page<Strutture>();
  pagina: Page<AslValidator> = new Page<AslValidator>();
  tuttiDatiInIngressoCount: Page<AslValidator> = new Page<AslValidator>();
  tuttiDatiInIngresso: Page<AslValidator> = new Page<AslValidator>();
  selValidati: number[] = [];
  selScartati: number[] = [];
  motivazioneScarto: string = '';
  effectiveSearchedAnno = new Date().getFullYear();
  effectiveSearchedStato: number = 2;
  resetPagina = true;

  constructor(private apiService: ApiService,
              private toastService: ToastService,
              private router: Router,
              private sessionService: SessionService, private modalService: NgbModal) {
  }

  ngOnInit(): void {

    this.populateYearArray();
    this.getStrutture();
    this.ricercaFormInit();
    //this.getAslValidator();
    this.isLoading = false;
    this.pagina.loading = false;


  }

  getStrutture() {
    this.apiService.getStruttureAll("", "").subscribe((data: any) => {
      this.strutture = data;
    });
  }

  private populateYearArray() {

    for (let i = this.currentYear - 1; i >= 1900; i--) {

      this.yearArray.push(i);

    }

  }


  private ricercaFormInit() {

    this.ricercaForm = new FormGroup({
      anno: new FormControl(""),
      tipoStruttura: new FormControl(""),
      struttura: new FormControl(""),
      stato: new FormControl(""),
      codiceFiscale: new FormControl(""),
    });
    this.ricercaForm.get('anno')?.setValue(this.currentYear);
    this.ricercaForm.get('stato')?.setValue("2");

  }

  async getAslValidator() {
    if (this.resetPagina) {
      this.pagina.number = 0;
    }
    this.pagina.loading = true;

    this.selValidati = [];
    this.selScartati = [];

    var anno = this.ricercaForm.get('anno')?.value;
    var tipoStruttura = this.ricercaForm.get('tipoStruttura')?.value;
    var struttura = this.ricercaForm.get('struttura')?.value;
    var stato = this.ricercaForm.get('stato')?.value;
    var cf = this.ricercaForm.get('codiceFiscale')?.value;

    this.effectiveSearchedAnno = anno;
    this.effectiveSearchedStato = stato;

    if (stato != 2)
      this.isNotDaValidare = true;
    else
      this.isNotDaValidare = false;

    this.apiService.getDatiInterventiIngresso(anno, tipoStruttura, struttura, stato, cf, this.pagina.number,
      this.pagina.size).subscribe((data: any) => {
      this.pagina = data;
      this.resetPagina = true;
      this.pagina.loading = false;

    });
  }

  cambiaPagina(event: any) {
    this.resetPagina = false;
    this.getAslValidator();

  }

  resetFormRicerca() {

    this.ricercaForm.get('anno')?.setValue(this.currentYear);
    this.ricercaForm.get('tipoStruttura')?.setValue("");
    this.ricercaForm.get('struttura')?.setValue("");
    this.ricercaForm.get('stato')?.setValue("2");
    this.ricercaForm.get('codiceFiscale')?.setValue("");

  }

  getStato() {
    return this.ricercaForm.get('stato')?.value;
  }

  onChangeValida(event: any) {
    if (event.target.checked) {
      if (this.selScartati.indexOf(Number(event.target.value)) != -1)
        this.selScartati.splice(this.selScartati.indexOf(Number(event.target.value)), 1);

      this.selValidati.push(Number(event.target.value));

    } else if (this.selValidati.indexOf(Number(event.target.value)) != -1) {
      this.selValidati.splice(this.selValidati.indexOf(Number(event.target.value)), 1);
    }
  }

  onChangeScarta(event: any) {
    if (event.target.checked) {
      if (this.selValidati.indexOf(Number(event.target.value)) != -1)
        this.selValidati.splice(this.selValidati.indexOf(Number(event.target.value)), 1);

      this.selScartati.push(Number(event.target.value));

    } else if (this.selScartati.indexOf(Number(event.target.value)) != -1) {
      this.selScartati.splice(this.selScartati.indexOf(Number(event.target.value)), 1);
    }
  }


  isSelectedValidati(id: number | undefined) {
    let ret: boolean = false;
    this.selValidati.forEach(value => {
      if (value == id) {
        ret = true;
      }
    })
    return ret;
  }


  isSelectedScartati(id: number | undefined) {
    let ret: boolean = false;
    this.selScartati.forEach(value => {
      if (value == id) {
        ret = true;
      }
    })
    return ret;
  }

  validaTuttaLaPagina() {
    this.selValidati = [];
    this.selScartati = [];
    this.pagina.content.forEach(value => {
      if (value.id != undefined && (value.numeroContatti != 0 || value.numeroDiagnosi != 0 || value.numeroInterventi != 0 || value.numeroPresenze != 0 || value.numeroPrestazioni != 0))
        this.selValidati.push(value.id);
    })
  }

  closeResult = ''

  validaTutti() {
    this.selValidati = [];
    this.selScartati = [];

    this.tuttiDatiInIngresso.content.forEach(value => {
      if (value.id != undefined && (value.numeroContatti != 0 || value.numeroDiagnosi != 0 || value.numeroInterventi != 0 || value.numeroPresenze != 0 || value.numeroPrestazioni != 0))
        this.selValidati.push(value.id);
    })
    this.salvaTutto()
  }

  openValidaTuttiDialog(validatutto: any) {
    this.isLoading = true;
    var anno = this.ricercaForm.get('anno')?.value;
    var tipoStruttura = this.ricercaForm.get('tipoStruttura')?.value;
    var struttura = this.ricercaForm.get('struttura')?.value;
    var stato = this.ricercaForm.get('stato')?.value;
    var cf = this.ricercaForm.get('codiceFiscale')?.value;
    this.apiService.getDatiInterventiIngressoNoPaging(anno, tipoStruttura, struttura, stato, cf, true).subscribe((data: any) => {
      this.tuttiDatiInIngressoCount = data;
      this.resetPagina = true;
      this.apiService.getDatiInterventiIngressoNoPaging(anno, tipoStruttura, struttura, stato, cf, false).subscribe((alldatiingresso: any) => {
        this.tuttiDatiInIngresso = alldatiingresso;
        this.resetPagina = true;
        this.isLoading = false
      });
    });

    // this.storicoTitle = "";
    // this.apiService.getPresenzaAuditByIdAndStruttura(this.presenza?.id!!, this.sessionService.getSelectedStrutturaFromStorage()?.codice!).subscribe(res => {
    //
    //   if(res){
    //
    //     this.auditPresenza = res;
    //
    //   }
    //
    // });

    this.modalService.open(validatutto, {ariaLabelledBy: 'modal-basic-title', scrollable: true}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

      if (this.closeResult === 'Closed with: Back click') {
        this.tuttiDatiInIngresso = new Page<AslValidator>()
        this.tuttiDatiInIngressoCount = new Page<AslValidator>()
        this.back()

      }

    }, (reason) => {
      this.tuttiDatiInIngresso = new Page<AslValidator>()
      this.tuttiDatiInIngressoCount = new Page<AslValidator>()
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;

    });

  }

  openScartaTuttiDialog(scartatutto: any) {
    this.isLoading = true;
    var anno = this.ricercaForm.get('anno')?.value;
    var tipoStruttura = this.ricercaForm.get('tipoStruttura')?.value;
    var struttura = this.ricercaForm.get('struttura')?.value;
    var stato = this.ricercaForm.get('stato')?.value;
    var cf = this.ricercaForm.get('codiceFiscale')?.value;
    this.apiService.getDatiInterventiIngressoNoPaging(anno, tipoStruttura, struttura, stato, cf, true).subscribe((data: any) => {
      this.tuttiDatiInIngressoCount = data;
      this.resetPagina = true;
      this.apiService.getDatiInterventiIngressoNoPaging(anno, tipoStruttura, struttura, stato, cf, false).subscribe((alldatiingresso: any) => {
        this.tuttiDatiInIngresso = alldatiingresso;
        this.resetPagina = true;
        this.isLoading = false;
      });
    });
    this.modalService.open(scartatutto, {ariaLabelledBy: 'modal-basic-title', scrollable: true}).result.then((result) => {

      this.closeResult = `Closed with: ${result}`;

      if (this.closeResult === 'Closed with: Back click') {
        this.tuttiDatiInIngresso = new Page<AslValidator>()
        this.tuttiDatiInIngressoCount = new Page<AslValidator>()
        this.back()

      }

    }, (reason) => {
      this.tuttiDatiInIngresso = new Page<AslValidator>()
      this.tuttiDatiInIngressoCount = new Page<AslValidator>()
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;

    });

  }

  back() {

    this.router.navigate(['dati-in-ingresso'])

  }

  getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  scartaTutti() {
    this.selValidati = [];
    this.selScartati = [];
    this.tuttiDatiInIngresso.content.forEach(value => {
      if (value.id != undefined && (value.numeroContatti != 0 || value.numeroDiagnosi != 0 || value.numeroInterventi != 0 || value.numeroPresenze != 0 || value.numeroPrestazioni != 0))
        this.selScartati.push(value.id);
    })
    this.salvaTutto()
  }

  scartaTuttaLaPagina() {
    this.selValidati = [];
    this.selScartati = [];
    this.pagina.content.forEach(value => {
      if (value.id != undefined && (value.numeroContatti != 0 || value.numeroDiagnosi != 0 || value.numeroInterventi != 0 || value.numeroPresenze != 0 || value.numeroPrestazioni != 0))
        this.selScartati.push(value.id);
    })
  }

  salvaTutto() {
    if (this.selScartati.length > 0 && this.motivazioneScarto == '') {
      alert("La motivazione di scarto è un campo obbligatorio!");
      return;
    } else {
      this.aggiornaValidati();
      this.aggiornaScartati();
      this.pagina = new Page<AslValidator>();
      this.resetPagina = true;
      this.pagina.loading = false;
    }

  }

  aggiornaValidati() {
    if (this.selValidati.length > 0) {
      this.apiService.updateStatusAslValidator(3, '', this.selValidati, this.effectiveSearchedAnno).subscribe((data: any) => {
        this.getAslValidator();
        if (data)
          this.toastService.showSuccess("Processo di validazione effettuata con successo!");
        else
          this.toastService.showError("Errore nel salvataggio!");
      });
    }
  }

  aggiornaScartati() {

    if (this.selScartati.length > 0) {
      this.apiService.updateStatusAslValidator(4, this.motivazioneScarto, this.selScartati, this.effectiveSearchedAnno).subscribe((data: any) => {
        this.getAslValidator();
        if (data)
          this.toastService.showSuccess("Processo di validazione effettuata con successo!");
        else
          this.toastService.showError("Errore nel salvataggio !");
      });
    }
  }


  async riabilitaModifica(id: number | undefined, tipo: string) {
    await firstValueFrom(this.apiService.riabilitaModifica(id!, tipo));
    await this.getAslValidator();

  }
}
