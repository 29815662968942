
<tr>
  <ng-container *ngFor="let col of theadColumns">
      <th *ngIf="col.columnSortingName != ''"
          [class]="tableSorter.class(col.columnSortingName)"
          (click)="clickEvent(col)"  [innerHTML]="col.columnDescription">
      </th>
    <th *ngIf="col.columnSortingName == ''" style="cursor: default;" [innerHTML]="col.columnDescription">

    </th>
  </ng-container>

</tr>
