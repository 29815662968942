<div class="container py-3">
  <!-- Ricerca -->
  <div class="card">
    <div class="card-header">
      <div class="title-3">Ricerca prese in carico</div>
    </div>
    <div class="card-body">
      <form [formGroup]="ricercaForm">
        <div class="group-inline">
          <div class="groupo-inline">
            <div class="form-group field-16">
              <label>Stato</label>
              <select
                class="form-control"
                formControlName="stato"
                name="stato"
                value="DA_TRASMETTERE"
                >
                <option selected="selected" value="DA_TRASMETTERE" >Da trasmettere</option>
                <option value="TRASMESSO">Trasmesso</option>
              </select>
            </div>
          </div>

          <div class="groupo-inline">
            <div class="form-group field-16">
              <label>Attività</label>
              <select
                class="form-control"
                formControlName="attivita"
                name="attivita"
              >
                <option value="">Tutte</option>
                <option value="Territoriale">Territoriale</option>
                <option value="Semiresidenziale">Semiresidenziale</option>
              </select>
            </div>
          </div>

          <div class="form-group field-8">
            <label>Anno</label>
            <select class="form-control" formControlName="anno" name="anno">
              <option value="{{ currentYear }}" selected>
                {{ currentYear }}
              </option>

              <option *ngFor="let year of yearArray" value="{{ year }}">
                {{ year }}
              </option>
            </select>
          </div>
          <div class="form-group field-16">
            <label>Periodo</label>
            <select
              class="form-control"
              formControlName="periodo"
              name="periodo"
              tabindex="-98"
            >
              <option value="S1" selected="selected">Primo semestre</option>
              <option value="S2">Secondo semestre</option>
            </select>
          </div>
          <div class="form-group field-8" *ngIf="!isUtenteStruttura()">
            <label>Codice DSM</label>
            <select
              class="form-control"
              formControlName="codiceDSM"
              name="codiceDSM"
              tabindex="-98"
            >
              <option value=""></option>
              <option *ngFor="let dsm of codiciDsm" value="{{ dsm.codiceDsm }}">
                {{ dsm.codiceDsm }}
              </option>
            </select>
          </div>
        </div>
      </form>
    </div>
    <div class="card-footer">
      <div class="btn-toolbar">
        <div class="btn-group-left">
          <button
            class="btn btn-outline-secondary"
            (click)="resetFormRicerca()"
          >
            Pulisci
          </button>
        </div>
        <div class="btn-group-center"></div>
        <div class="btn-group-right">
          <button class="btn btn-secondary" (click)="getPreseInCarico()">
            Cerca
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Risultati -->
  <div class="card mt-5">
    <div class="card-body">
      <div class="table-responsive">
        <div class="list-table table-even">
          <table class="table-colf table-lg">
            <thead>
              <tr>
                <th class="colf-check">
                  <div class="form-group">
                    <div class="form-checkbox">
                      <div class="checkbox">
                        <input
                          *ngIf="pagina.content.length == 0"
                          type="checkbox"
                          name="trasmettiTutti"
                          id="trasmettiTutti"
                          value="1"
                          disabled="disabled"
                          />
                        <input
                          *ngIf="pagina.content.length > 0"
                          type="checkbox"
                          name="trasmettiTutti"
                          id="trasmettiTutti"
                          value="1"
                          [(ngModel)]="isMasterSel"
                          (change)="checkUncheckAll()"
                          [disabled]="!isAllNonCheckable()"
                        />
                        <label for="trasmettiTutti">&nbsp;</label>
                      </div>
                    </div>
                  </div>
                </th>
                <th>Stato</th>
                <th>Data</th>
                <th *ngIf="!isUtenteStruttura()">DSM</th>
                <th>Codice fiscale</th>
                <th>Cognome e nome</th>
                <th>N° dati di contatto</th>
                <th>N° prestazioni</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr class="tr-hover" *ngFor="let presaincarico of pagina.content">
                <td>
                  <div class="form-group">
                    <div class="form-checkbox">
                      <div class="checkbox">
                        <input
                          id="checkbox-{{presaincarico.idAnagrafica}}"
                          type="checkbox"
                          name="trasmetti"
                          value="1"
                          [(ngModel)]="presaincarico.isSelected"
                          (change)="isAllSelected()"
                          [disabled]="isStattoTrasmesso(presaincarico)"/>
                        <label for="checkbox-{{presaincarico.idAnagrafica}}">&nbsp;</label>
                      </div>
                    </div>
                  </div>
                </td>

                
                <td class="td-hover" data-label="Stato">
                  {{ presaincarico.stato }}
                </td>
                <td class="td-hover" data-label="Data">
                  {{ presaincarico.data }}
                </td>
                <td class="td-hover" data-label="DSM" *ngIf="!isUtenteStruttura()">
                  {{ presaincarico.dsm }}
                </td>
                <td class="td-hover" data-label="Codice fiscale">
                  {{ presaincarico.codiceFiscale }}
                </td>
                <td class="td-hover" data-label="Cognome e nome">
                  {{ presaincarico.cognomeNome }}
                </td>
                <td class="td-hover" data-label="N° dati di contatto">
                  {{ presaincarico.nDatiContatto }}
                </td>
                <td class="td-hover" data-label="N° prestazioni">
                  {{ presaincarico.nPrestazioni }}
                </td>
                <td class="td-hover" data-label="">
                  <a class="link-u"
                     (click)="openDettagloPresaInCarico(content,presaincarico.idAnagrafica!)">Dettaglio</a>
                </td>
              </tr>
              <tr *ngIf="pagina.content.length == 0">
                <td colspan="9">
                  <p class="text-center">Impostare un filtro di ricerca</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- PAGINATION -->
      <app-paginator
        [page]="pagina"
        (cambiaPaginaEvent)="cambiaPagina($event)"
      ></app-paginator>
    </div>
  </div>

  <!-- Toolbar -->
  <div class="btn-fixed">
    <div class="btn-toolbar">
      <div class="container">
        <div class="btn-group-left"></div>
        <div class="btn-group-center">
          <button type="button" class="btn btn-outline-secondary">
            Esporta risultati
          </button>
        </div>
        <div class="btn-group-right">
          <button
            *ngIf="!isEnableTrasmetti"
            type="button"
            class="btn btn-secondary"
            disabled=""
          >
            Trasmetti
          </button>
          <button
            *ngIf="isEnableTrasmetti"
            type="button"
            class="btn btn-secondary"
            (click)="trasmetti()"
          >
            Trasmetti
          </button>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- modal -->
<ng-template #content let-modal>

<!--  <div class="modal fade show" role="dialog" data-backdrop="static" data-keyboard="false" style="display: block;" aria-modal="true">-->
<!--    <div class="modal-dialog modal-lg" id="Modal1"><div class="modal-content" id="demonView7-70-2">-->
<!--        <div class="modal-header bg-primary text-white">-->
<!--          <h4 class="modal-title">Presa in carico</h4>-->
<!--          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')">-->
<!--            <svg class="s-icon icon-sm"><use href="/proto/flussi-lazio/flussi-lazio-v1.0.0/imgs/icons/sprite.svg#close"></use></svg>-->
<!--          </button>-->
<!--        </div>-->

<!--        <ul class="nav nav-tabs" id="detailTab" role="tablist">-->
<!--          <li class="nav-item">-->
<!--            <a class="nav-link title-4 active" id="anagrafica-tab" data-toggle="tab" href="#anagrafica" role="tab" aria-controls="anagrafica" aria-selected="true">-->
<!--              Anagrafica-->
<!--            </a>-->
<!--          </li>-->
<!--          <li class="nav-item">-->
<!--            <a class="nav-link title-4" id="contatto-tab" data-toggle="tab" href="#contatto" role="tab" aria-controls="contatto" aria-selected="false">-->
<!--              Contatti-->
<!--            </a>-->
<!--          </li>-->
<!--          <li class="nav-item">-->
<!--            <a class="nav-link title-4" id="prestazioni-tab" data-toggle="tab" href="#prestazioni" role="tab" aria-controls="prestazioni" aria-selected="false">-->
<!--              Prestazioni sanitarie-->
<!--            </a>-->
<!--          </li>-->
<!--        </ul>-->

<!--        <div class="modal-body">-->

<!--          <div class="tab-content" id="detailTabContent">-->

<!--            &lt;!&ndash; Anagrafica &ndash;&gt;-->
<!--            <div class="tab-pane fade show active" id="anagrafica" role="tabpanel" aria-labelledby="anagrafica-tab">-->

<!--              <div class="data-read">-->
<!--                <div class="group-inline">-->
<!--                  <div class="form-group">-->
<!--                    <label>Codice fiscale</label>-->
<!--                    <div class="textbold">{{dettaglioAnagrafica?.codiceFiscale}}</div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="group-inline">-->
<!--                  <div class="form-group">-->
<!--                    <label>Cognome</label>-->
<!--                    <div class="textbold">{{dettaglioAnagrafica?.cognome}}</div>-->
<!--                  </div>-->
<!--                  <div class="form-group">-->
<!--                    <label>Nome</label>-->
<!--                    <div class="textbold">{{dettaglioAnagrafica?.nome}}</div>-->
<!--                  </div>-->
<!--                  <div class="form-group">-->
<!--                    <label>Sesso</label>-->
<!--                    <div class="textbold">{{setSessoName(dettaglioAnagrafica?.sesso!!)}}</div>-->
<!--                  </div>-->
<!--                </div>-->

<!--                <div class="group-inline">-->

<!--                  <div class="form-group">-->
<!--                    <label>Stato civile</label>-->
<!--                    <div class="textbold">{{setStatoCivileName(this.dettaglioAnagrafica?.statoCivile!!)}}</div>-->
<!--                  </div>-->
<!--                  <div class="form-group">-->
<!--                    <label>Cittadinanza</label>-->
<!--                    <div class="textbold">{{setStatoName(dettaglioAnagrafica?.cittadinanza!!)}}</div>-->
<!--                  </div>-->
<!--                </div>-->


<!--                &lt;!&ndash; Nascita &ndash;&gt;-->
<!--                <div class="title-5 pt-5">-->
<!--                  Nascita-->
<!--                </div>-->
<!--                <div class="group-inline pt-4">-->

<!--                  <div class="form-group">-->
<!--                    <label>Data</label>-->
<!--                    <div class="textbold">{{dettaglioAnagrafica?.dataNascita | date: 'dd-mm-yyyy'}}</div>-->
<!--                  </div>-->

<!--                  <div class="form-group">-->
<!--                    <label>Nazione</label>-->
<!--                    <div class="textbold">{{setStatoName(dettaglioAnagrafica?.statoResidenza!!)}}</div>-->
<!--                  </div>-->

<!--                  <div class="form-group">-->
<!--                    <label>Comune</label>-->
<!--                    <div class="textbold">{{dettaglioAnagrafica?.descrizioneComuneNascita}}</div>-->
<!--                  </div>-->

<!--                </div>-->


<!--                &lt;!&ndash; Residenza &ndash;&gt;-->
<!--                <div class="title-5 pt-5">-->
<!--                  Residenza-->
<!--                </div>-->
<!--                <div class="group-inline pt-4">-->
<!--                  <div class="form-group">-->
<!--                    <label>Stato</label>-->
<!--                    <div class="textbold">{{setStatoName(dettaglioAnagrafica?.statoResidenza!!)}}</div>-->
<!--                  </div>-->
<!--                  <div class="form-group">-->
<!--                    <label>Regione</label>-->
<!--                    <div class="textbold">{{setRegioneName(dettaglioAnagrafica?.regioneResidenza!!)}}</div>-->
<!--                  </div>-->
<!--                  <div class="form-group">-->
<!--                    <label>Comune</label>-->
<!--                    <div class="textbold">{{setComuneName(dettaglioAnagrafica?.comuneResidenza!!)}}</div>-->
<!--                  </div>-->
<!--                  <div class="form-group">-->
<!--                    <label>Distretto</label>-->
<!--                    <div class="textbold">{{setDistrettoName(dettaglioAnagrafica?.distrettoResidenza!!)}}</div>-->
<!--                  </div>-->
<!--                  <div class="form-group">-->
<!--                    <label>ASL</label>-->
<!--                    <div class="textbold">{{setAslName(dettaglioAnagrafica?.aslResidenza!!)}}</div>-->
<!--                  </div>-->
<!--                </div>-->

<!--                &lt;!&ndash; &ndash;&gt;-->
<!--                <div class="group-inline pt-3">-->

<!--                  <div class="form-group">-->
<!--                    <label>Collocazione socio ambientale</label>-->
<!--                    <div class="textbold">{{setCollazioneSocioAmbientaleName(dettaglioAnagrafica?.collocazioneSocioAmbientale!!)}}</div>-->
<!--                  </div>-->
<!--                  <div class="form-group">-->
<!--                    <label>Titolo di studio</label>-->
<!--                    <div class="textbold">{{setTitoloDiStudioName(dettaglioAnagrafica?.titoloDiStudio!!)}}</div>-->
<!--                  </div>-->
<!--                  <div class="form-group field-36">-->
<!--                    <label>Attività</label>-->
<!--                    <div class="textbold">{{setAttivitaName(dettaglioAnagrafica?.attivita!!)}}</div>-->
<!--                  </div>-->

<!--                </div>-->

<!--                &lt;!&ndash; &ndash;&gt;-->
<!--                <div class="group-inline pt-3">-->
<!--                  <div class="form-group">-->
<!--                    <label>Invalidità</label>-->
<!--                    <div class="textbold">{{setInvaliditaName(dettaglioAnagrafica?.invalidita!!)}}</div>-->
<!--                  </div>-->

<!--                  <div class="form-group">-->
<!--                    <label>E' familiare di un paziente in trattamento</label>-->
<!--                    <div class="textbold">{{setFamigliareInTratamentoName(dettaglioAnagrafica?.familiarePaziente!!)}}</div>-->
<!--                  </div>-->

<!--                  <div class="form-group">-->
<!--                    <label>Tutela giuridica</label>-->
<!--                    <div class="textbold">{{setTuteleGiuridiceName(dettaglioAnagrafica?.tutelaGiuridica!!)}}</div>-->
<!--                  </div>-->

<!--                  <div class="form-group field-50">-->
<!--                    <label>Attualmente sottoposto a provvedimenti giudiziari o a una misura di sicurezza</label>-->
<!--                    <div class="textbold">{{setProvedimentiName(dettaglioAnagrafica?.attualmenteSottoposto!!)}}</div>-->
<!--                  </div>-->

<!--                  <div class="form-group field-50">-->
<!--                    <label>Precedente internamento o detenzione in ambito Psichiatrico Penitenziario</label>-->
<!--                    <div class="textbold">{{setPrecedemnteInternatoName(dettaglioAnagrafica?.precedentementeInternato!!)}}</div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->

<!--            </div>-->

<!--            &lt;!&ndash; Contatti &ndash;&gt;-->
<!--            <div class="tab-pane fade" id="contatto" role="tabpanel" aria-labelledby="contatto-tab">-->

<!--              <div class="card border-primary mb-4" *ngFor="let contatto of listContati">-->
<!--                <div class="card-body">-->

<!--                  <div class="data-read" >-->

<!--                    <div class="title-4">-->
<!--                      Presa in carico #{{contatto.id}}-->
<!--                    </div>-->

<!--                    <div class="group-inline pt-5">-->
<!--                      <div class="form-group">-->
<!--                        <label>Data richiesta</label>-->
<!--                        <div class="textbold">{{contatto.dataRichiesta|date:'dd-mm-yyyy'}}</div>-->
<!--                      </div>-->
<!--                      <div class="groupo-inline">-->
<!--                        <div class="form-group">-->
<!--                          <label>Tipo prestazione</label>-->
<!--                          <div class="textbold">{{contatto.tipoPrestazione}}</div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <div class="form-group field-30">-->
<!--                        <label>Richiesta prev</label>-->
<!--                        <div class="textbold">{{setRichiestaPrevName(contatto.richiestaPrev!!)}}</div>-->
<!--                      </div>-->

<!--                    </div>-->

<!--                    <div class="title-5 pt-4">-->
<!--                      Apertura-->
<!--                    </div>-->
<!--                    <div class="group-inline pt-4">-->
<!--                      <div class="form-group">-->
<!--                        <label>Data</label>-->
<!--                        <div class="textbold">{{contatto.dataApertura|date:'dd-mm-yyyy'}}</div>-->
<!--                      </div>-->
<!--                      <div class="form-group field-20">-->
<!--                        <label>Diagnosi</label>-->
<!--                        <div class="textbold">{{contatto.descrizioneDiagnosiApertura}}</div>-->
<!--                      </div>-->
<!--                      <div class="form-group field-40">-->
<!--                        <label>Precedenti contatti</label>-->
<!--                        <div class="textbold">{{setPrecedentiContatiName(contatto.precedentiContatti!!)}}</div>-->
<!--                      </div>-->
<!--                      <div class="form-group field-40">-->
<!--                        <label>Inviante</label>-->
<!--                        <div class="textbold">{{setInviantiName(contatto.inviante!!)}}</div>-->
<!--                      </div>-->
<!--                    </div>-->

<!--                    <div class="title-5 pt-4">-->
<!--                      Chiusura-->
<!--                    </div>-->
<!--                    <div class="group-inline pt-4">-->
<!--                      <div class="form-group">-->
<!--                        <label>Data</label>-->
<!--                        <div class="textbold">{{contatto.dataChiusura|date:'dd-mm-yyyy'}}</div>-->
<!--                      </div>-->
<!--                      <div class="form-group field-20">-->
<!--                        <label>Diagnosi</label>-->
<!--                        <div class="textbold">{{contatto.descrizioneDiagnosiChiusura}}</div>-->
<!--                      </div>-->
<!--                      <div class="form-group field-40">-->
<!--                        <label>Modalità conclusione</label>-->
<!--                        <div class="textbold">{{setModalitaConclusioneName(contatto.modalitaConclusione!!)}}</div>-->
<!--                      </div>-->

<!--                    </div>-->

<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->


<!--            </div>-->

<!--            &lt;!&ndash; Prestazioni &ndash;&gt;-->
<!--            <div class="tab-pane fade" id="prestazioni" role="tabpanel" aria-labelledby="prestazioni-tab">-->

<!--              <div class="card border-primary mb-4" *ngFor="let prestazione of listPrestazioni">-->
<!--                <div class="card-body">-->

<!--                  <div class="data-read">-->

<!--                    <div class="title-4">-->
<!--                      Prestazione {{prestazione.descrizioneTipo}} #{{prestazione.id}}-->
<!--                    </div>-->

<!--                    <div class="group-inline pt-5">-->
<!--                      <div class="form-group">-->
<!--                        <label>Data</label>-->
<!--                        <div class="textbold">{{prestazione.dataPrestazione|date:'dd-mm-yyyy'}}</div>-->
<!--                      </div>-->
<!--                      <div class="form-group field-60">-->
<!--                        <label>Tipo</label>-->
<!--                        <div class="textbold">{{prestazione.descrizioneTipo}}</div>-->
<!--                      </div>-->
<!--                      <div class="form-group field-16">-->
<!--                        <label>Modalità di erogazione</label>-->
<!--                        <div class="textbold">{{setModalitaErrogazioneName(prestazione.modalitaDiErogazione!!)}}</div>-->
<!--                      </div>-->
<!--                      <div class="form-group field-60">-->
<!--                        <label>Sede</label>-->
<!--                        <div class="textbold">{{setSedeName(prestazione.sede!!)}}</div>-->
<!--                      </div>-->
<!--                    </div>-->

<!--                    <div class="group-inline pt-3">-->
<!--                      <div class="form-group field-20">-->
<!--                        <label>Operatore 1</label>-->
<!--                        <div class="textbold">{{setOperatore1Name(prestazione.operatore1!!)}}</div>-->
<!--                      </div>-->
<!--                      <div class="form-group field-6">-->
<!--                        <label>Codice</label>-->
<!--                        <div class="textbold">{{prestazione.operatore1Codice}}</div>-->
<!--                      </div>-->
<!--                    </div>-->


<!--                    <div class="group-inline pt-3">-->
<!--                      <div class="form-group field-20">-->
<!--                        <label>Riferimento contatto id</label>-->
<!--                        <div class="textbold">{{prestazione.contatto?.id}}</div>-->
<!--                      </div>-->

<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->

<!--            </div>-->

<!--          </div>-->

<!--        </div>-->
<!--        <div class="modal-footer">-->
<!--          <div class="mr-auto">-->
<!--            <button type="button" class="btn btn-outline-secondary" data-dismiss="modal" (click)="modal.close('Back click')">Chiudi</button>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div></div>-->
<!--  </div>-->

</ng-template>



<!-- FINE modal -->
