import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from "../../environments/environment";




@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  private baseUrl = environment.apiUrl + '/v1/';


  private uploadFilesTerritorialiUrl = "files/territoriali/upload";

  private uploadFilesSemiresidenzialiUrl = "files/semiresidenziali/upload";

  private inviadatiregioneUrl = "files/inviadatiregione";

  private getIstanzaFlussoUrl = "files/getistanzaflusso";

  private getDataScadenzaFlussoUrl = "files/getdatascadenzaflusso";

  private getContattiUrl = "files/getcontatti";

  private getAnagraficheUrl = "files/getanagrafiche";

  private getPrestazioniUrl = "files/getprestazioni";

  private getHistoryUrl = "files/getSendedHistory";

  private downloadCsvUrl = "file/get"

  private downloadFileTrasmissioneUrl = "file/getTrasmissione"



  constructor(private httpClient: HttpClient) { }



  uploadFilesTerritoriali(codiceStruttura:string,anagFile:File|undefined,contattoFile:File|undefined,
    prestazioneFile:File|undefined,diagnosiFile:File|undefined,interventoFile:File|undefined){

    const fileInput:any = new FormData();

    fileInput.append("codiceStruttura", codiceStruttura)

    fileInput.append("anagraficaFile", anagFile)

    fileInput.append("contattoFile", contattoFile)

    fileInput.append("prestazioneFile", prestazioneFile)

    fileInput.append("diagnosiFile", diagnosiFile)

    fileInput.append("interventoFile", interventoFile)

    return this.httpClient.post<any>(this.baseUrl + this.uploadFilesTerritorialiUrl, fileInput)
  }

  uploadFilesSemiresidenziali(codiceStruttura:string,anagFile:File|undefined,contattoFile:File|undefined, presenzaFile:File|undefined,prestazioneFile:File|undefined){

    const fileInput:any = new FormData();


    fileInput.append("codiceStruttura", codiceStruttura)

    if(anagFile != undefined){

      fileInput.append("anagraficaFile", anagFile)
    }
    if(contattoFile!=null){

      fileInput.append("contattoFile", contattoFile)
    }
    if(presenzaFile != null){

      fileInput.append("presenzaFile", presenzaFile)
    }

    if(prestazioneFile != null){

      fileInput.append("prestazioneFile", prestazioneFile)
    }


    return this.httpClient.post<any>(this.baseUrl + this.uploadFilesSemiresidenzialiUrl, fileInput)
  }


  inviaDatiRegione(anno: string, flusso: string, etichetta: string, struttura: string, asl: string | undefined){

    const fileInput:any = new FormData();


    fileInput.append("anno", anno)
    fileInput.append("flusso", flusso)
    fileInput.append("etichetta", etichetta)
    fileInput.append("struttura", struttura)
    fileInput.append("asl", asl)


    return this.httpClient.post<any>(this.baseUrl + this.inviadatiregioneUrl, fileInput)
  }

  getCsvFile(){




    return this.httpClient.get<File>(this.baseUrl + this.downloadCsvUrl)
  }

  download(codStruttura: string): Observable<Blob> {
    return this.httpClient.get(this.baseUrl + this.downloadCsvUrl+ "?codStruttura=" + codStruttura, {
      responseType: 'blob'
    });
  }

  getIstanzaFlusso(
    anno: string,
    flusso: string,
    etichetta:string,
    struttura: string,
    asl?: string
  ) {
    let url = this.baseUrl + this.getIstanzaFlussoUrl + "?anno=" + anno + "&flusso=" + flusso + "&etichetta=" + etichetta
      + "&struttura=" + struttura+ "&asl=" + asl;
    return this.httpClient.get(url);

  }

  getDataScadenzaFlusso(
    id: number
  ) {
    let url = this.baseUrl + this.getDataScadenzaFlussoUrl + "?idistanzaflusso=" +id;
    return this.httpClient.get(url);

  }

  getContatti(
    anno: string,
    flusso: string,
    etichetta:string,
    struttura: string,
    asl?: string
  ) {
    let url = this.baseUrl + this.getContattiUrl + "?anno=" + anno + "&flusso=" + flusso + "&etichetta=" + etichetta
      + "&struttura=" + struttura+ "&asl=" + asl;
    return this.httpClient.get(url, {responseType: 'text'});

  }

  getAnagrafiche(
    anno: string,
    flusso: string,
    etichetta:string,
    struttura: string,
    asl?: string
  ) {
    let url = this.baseUrl + this.getAnagraficheUrl + "?anno=" + anno + "&flusso=" + flusso + "&etichetta=" + etichetta
      + "&struttura=" + struttura+ "&asl=" + asl;
    return this.httpClient.get(url, {responseType: 'text'});

  }

  getPrestazioni(
    anno: string,
    flusso: string,
    etichetta:string,
    struttura: string,
    asl?: string
  ) {
    let url = this.baseUrl + this.getPrestazioniUrl + "?anno=" + anno + "&flusso=" + flusso + "&etichetta=" + etichetta
      + "&struttura=" + struttura+ "&asl=" + asl;
    return this.httpClient.get(url, {responseType: 'text'});

  }


  public getStorico(dimensionePaginaSelezinata: number,
    numeroPaginaSelezionata: number,
    orderByName: string| null,
    orderByDirection: string| null){

    let url = this.baseUrl + this.getHistoryUrl;
    url += '?numeroPagina=' + numeroPaginaSelezionata;
    url += '&dimensionePagina=' + dimensionePaginaSelezinata;
    url += '&orderByName=' +  orderByName;
    url += '&orderByDirection=' +  orderByDirection;

    return this.httpClient.get(url);
  }

  downloadFileTrasmissione(
    idTrasmissione: number | undefined,
    fileName: string | undefined
  ): Observable<Blob> {
    let url = this.baseUrl + this.downloadFileTrasmissioneUrl;
    url += '?idTrasmissione=' + idTrasmissione;
    url += '&fileName=' + fileName;
    return this.httpClient.get(url, {
      responseType: 'blob'
    });
  }

}

