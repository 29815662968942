import {
  AnagraficaAudit, AnagraficaStorica,
  AnagraficaStruttura,
  ConfFiltriRicerca,
  ConfStato,
  ContattoAudit,
  ContattoDto,
  Dashboard,
  DettaglioLottoRipristinaBody,
  DettaglioPreseInCaricoDto,
  DiagnosiEScale,
  DiagnosiEscaleAudit,
  Intervento,
  InterventoAudit,
  NotAvailableDatesContainer,
  Presenza, PresenzaAudit, PresenzaDto,
  PrestazioneAudit,
  RispostaPrevalente, SearchFilterAnagraficaDTO, StoricoImportXml,
  StrutturaStr
} from './../model/Models';
import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {
  DettaglioAnagrafica,
  RicercaAnagrafica,
  Page,
  Contatto,
  Prestazione,
  Utente,
  Asl,
  StatoEstero,
  Comune,
  CollazioneSocioAmbientale,
  TitoloStudio,
  Ativitta,
  Invalidita,
  TutelaGiuridica,
  Provedimento,
  SoccioSanitario,
  Cittadinanza,
  Regione,
  RichiestaPrevalente,
  Diagnosi,
  Inviante,
  ModalitaConclusione,
  TipoPrestazione,
  ModalitaErogazione,
  SedeIntervento,
  Operatore,
  StatoCivile,
  PrecedenteContatto,
  PreseInCarico,
  Lotti,
  Strutture,
  DettaglioLotto
} from '../model/Models';
import {HttpClient, HttpContext, HttpHeaders, HttpParams} from "@angular/common/http";
import {DatePipe} from "@angular/common";
import {ToastService} from "./toast.service";
import {catchError, Observable, throwError, firstValueFrom, BehaviorSubject, map, shareReplay} from 'rxjs';
import {Distretto, Municipio} from '../model/Models';
import {SessionService} from './session.service';

@Injectable({
  providedIn: 'root'
})

export class ApiService {
  private baseUrl = environment.apiUrl + '/v1/';
  private knowageUrl = environment.knowageUrl;
  private asurUrl = environment.asurUrl + '/v1/asur/';
  private getPaginaAnagraficaUrl = "anagrafica/pagina";
  private getPaginaAnagrafica_2Url = "anagrafica/pagina_2";
  private getAnagraficaUrl = "anagrafica/:idAnagrafica";
  private getAnagraficaByCfUrl = "anagrafica/codicefiscale/:cf"
  private controllaCodiceENIUrl = "anagrafica/checkcodiceeni/:cod"
  private postAnagraficaUrl = "anagrafica/salva/";
  private postprestazioneUrl = "prestazione/salva/";
  private lastNrInseritoPrestazioneUrl="contatto/:idcontatto/prestazioni/nrinseritoprestazione"
  private postContattoUrl = "contatto/salva/"
  private getPaginaContattiUrl = "contatti/pagina";
  private getPaginaPrestazioniUrl = "prestazioni/pagina?";
  private getContattoByIdUrl = "contatto/";
  private getUtenteProfileUrl = "utente/";
  private getUtenteUrl = "utente";
  private getAslUrl = "asl/all"
  private getAslByComuneUrl = "comuni/:codiceComune/asl/all"
  private getStatiEsteriUrl = "statiesteri/all"
  private getStatiCiviliUrl = "staticivili/all"
  private getComuni = "comuni/all"
  private getComuniByRegione = "regione/:codRegione/comuni/all"
  private getRegioni = "regioni/all"
  private getCollazioneSocioAmbientali = "collazioneSocioAmbientali/all"
  private getTitoliStudio = "titolistudio/all"
  private getAtivittaUrl = "attivita/all"
  private getInvaliditaUrl = "invalidita/all"
  private getTuteleGiuridiceUrl = "tutelegiuridice/all"
  private getProvedimentiUrl = "provedimenti/all"
  private getCittadinanzaUrl = "cittadinanza/all"
  private getSocciSanitariUrl = "socisanitari/all"
  private getRichiestePrevalentiUrl = "richiesteprevalenti/all"
  private getDiagnosiUrl = "diagnosi/all"
  private getPrecedentiContattiUrl = "precedenti-contatti/all"
  private getInvianteTerritorialeUrl = "invianti/territoriale"
  private getInvianteSemiresidenzialiUrl = "invianti/semiresidenziali"
  private getModalitaConclusioneUrl = "modalitaconclusione/all"
  private getTipiPrestazioneTerritorialiUrl = "tipiprestazione/territoriali/all"
  private getTipiPrestazioneSemiresidenzialiUrl = "tipiprestazione/semiresidenziali/all"
  private getModalitaErogazioneUrl = "modalitaerogazione/all"
  private getSedeInterventoTerritorialiUrl = "sedeintervento/territoriali/all"
  private getSedeInterventoSemiResidenzialiUrl = "sedeintervento/semiresidenziali/all"
  private getOperatoriUrl = "operatori/all"
  private getPreseIncaricoUrl = "preseincarico/all"
  private getDettaglioPreseIncaricoUrl = "preseincarico/dettaglio"
  private getDettaglioPreseIncaricoByIdDettaglioLottoUrl = "preseincarico/dettagliobyid"
  private trasmettiPreseInCaricoUrl = "preseincarico/trasmetti";
  private getLottiUrl = "lotti/all";
  private getDistrettiUrl = "distretti/all";
  private getDistrettoByAslUrl = "asl/:codiceAsl/distretti/all"
  private getMunicipiByDistrettoResidenzaUrl = "municipi"
  private getMunicipiByAslUrl = "asl/:codiceAsl/municipi/all"
  private getMunicipiUrl = "municipi/all";
  private codiceFiscaleExxistUrl = "codice_fiscale/exist";
  private getLottiFlussiIngressoUrl = "lotti/flussiingresso/all";
  private getDatiIngressoUrl = "aslvalidator/datiingresso/all";
  private getDatiIngressoNoPagingUrl = "aslvalidator/datiingresso/all/nopaging";
  private getAnagraficaAslValidatorUrl = "aslvalidator/anagrafica";
  private getContattiAslValidatorUrl = "aslvalidator/contatti";
  private getInterventiAslValidatorUrl = "aslvalidator/interventi";
  private getDiagnosiEScaleAslValidatorUrl = "aslvalidator/diagnosiEScale";
  private getPresenzeAslValidatorUrl = "aslvalidator/presenze";
  private getPrestazioneAslValidatorUrl = "aslvalidator/prestazione";
  private updateStatusAslValidatorUrl = "aslvalidator/updatestatusmassive";
  private updateStatusContattiUrl = "aslvalidator/updatecontatti";
  private updateStatusInterventoUrl = "aslvalidator/updateintervento";
  private updateStatusDiagnosiUrl = "aslvalidator/updatediagnosi";
  private updateStatusPresenzaUrl = "aslvalidator/updatepresenza";
  private updateStatusPrestazioneUrl = "aslvalidator/updateprestazione";
  private riabilitaModificaUrl = "aslvalidator/riabilitaModifica/:tipo/:id";
  private getStruttureUrl = "strutture/all";
  private getStruttureUrlParams = "strutture/parameter";
  private getDettaglioLottoUrl = "dettagliolotto/all";
  private saveDettaglioLottoUrl = "dettagliolotto/save";
  private updateLottoUrl = "dettagliolotto/update";
  private getAslByParameterUrl = "asl/all/parameter";
  private getDashboardDataUrl = "dashboard/all";
  private anagraficaContattiUrl = "anagrafica/contatti/";
  private anagraficaCheckIfCanDleteUrl = "anagrafica/:id/canDelete/:codStruttura";
  private anagraficaCancellaUrl = "anagrafica/cancella/"
  private contattoCancellaUrl = "contatto/cancella/";
  private prestazioneCancellaUrl = "prestazione/cancella/"
  private postDettaglioLottoRipristinaUrl = "dettagliolotto/ripristina";
  private getPaginaInterventiUrl = "interventi/pagina?";
  private postInterventoUrl = "intervento/salva/";
  private postAggiornaIeInterventoUrl = "intervento/aggiornaIe/";
  private interventoCancellaUrl = "intervento/cancella/"
  private getPaginaDiagnosiEScaleUrl = "diagnosiescale/pagina?";
  private postDiagnosiEScaleUrl = "diagnosiescale/salva/";
  private diagnosiEScalaCancellaUrl = "diagnosiescale/cancella/"
  private getAllStrutture = "strutture"
  private getAllRispostePrevalentiUrl = "risposteprevalenti/all"
  private getContattiByAnagraficaIdUrl = "contatti/anagrafica/"
  private getContattoByIdAndCodiceStrutturaUrl = "contatto/"
  private getPaginaPresenzeUrl = "presenze/pagina?";
  private getListaPresenzeUrl = "contatto/:idContatto/presenza";
  private postPresenzaUrl = "presenza/salva/";
  private cancellPresenzaUrl = "presenza/cancella/"
  private chiudiContattoUrl = "contatto/chiudi/"
  private contatoNotAvailableDatesUrl = "contatto/:idContatto/dates/"
  private getPrestazioniByPresenzaIdUrl = "prestazioni/presenza/"
  private getPrestazioniByContattoUrl = "contatto/:idContatto/prestazioni";
  private getLastDiagnosiByContattoID: string = "contatto/:idContatto/lastDiagnosi";
  private getContattoApertoSettingUrl: string = "contatto/aperto-setting";
  private getContattiApertiSettingUrl: string = "contatto/aperti-setting";
  private getContattoChiusoSettingUrl: string = "contatto/chiuso-setting";
  private getAnagraficaStrutturaByCodStrutturaAndIdAnagraficaUrl = "anagrafica/struttura/:codiceStruttura/:anagraficaId";
  private saveAnagraficaStrutturaUrl = "anagrafica/struttura/salva";
  private anagraficaStoricaUrl = "contatto/:idContatto/anagraficaStorica";
  private getAllConfStatoUrl = "confstati/all";
  private getConfStatoByIdUrl = "confstati/:id";
  private getAllConfFiltriRicercaUrl = "conffiltriricerca/all";
  private getAnagraficaAuditByIdAndStrutturaUrl = "audit/anagrafica/:id/:struttura";
  private getContattoAuditByIdAndStrutturaUrl = "audit/contatto/:id/:struttura";
  private getInterventoAuditByIdAndStrutturaUrl = "audit/intervento/:id/:struttura";
  private getDiagnosiEscaleAuditByIdAndStrutturaUrl = "audit/diagnosiescale/:id/:struttura";
  private getPresenzaAuditByIdAndStrutturaUrl = "audit/presenza/:id/:struttura";
  private getGenericAuditUrl = "audit/by/:tipo";
  private getPrestazioneAuditByIdAndStrutturaUrl = "audit/prestazione/:id/:struttura/:tipo";
  private downloadCsvUrl = "csv/:entity";
  private getContattiByAnagraficaIdAndStrutturaUrl = "contatti/anagrafica/:id/struttura/:struttura"
  private downloadXsdUrl = "files/download/xsd/:name";
  private storicoImportByStrutturaUrl = "storicoimportxml/struttura";
  private downloadStoricoXmlImport = "files/download/storicoimport/:id/:tipo";
  private insertNotificaUrl = "email/insert/:aslContattoAperto/:aslUtente/:idContatto/:codiceStruttura";
  private saveAnagraficaStoricaUrl: string = "anagrafica/storica/save";

  constructor(private http: HttpClient, public datepipe: DatePipe,
              public toastService: ToastService, private sessionService: SessionService) {
  }


  public getContattoApertoSetting(idAnagrafica: number, ambito: string): Observable<Contatto> {
    let url = this.baseUrl + this.getContattoApertoSettingUrl + '?';
    url += 'idAnagrafica=' + idAnagrafica;
    url += '&ambito=' + ambito;
    return this.http.get<Contatto>(url)
      .pipe(catchError(error => this.handleError(error)));
  }

  public getContattiApertiSetting(idAnagrafica: number, ambito: string): Observable<Contatto[]> {
    let url = this.baseUrl + this.getContattiApertiSettingUrl + '?';
    url += 'idAnagrafica=' + idAnagrafica;
    url += '&ambito=' + ambito;
    return this.http.get<Contatto[]>(url)
      .pipe(catchError(error => this.handleError(error)));
  }

  public getContattoChiusoSetting(idAnagrafica: number, ambito: string): Observable<Contatto> {
    let url = this.baseUrl + this.getContattoChiusoSettingUrl + '?';
    url += 'idAnagrafica=' + idAnagrafica;
    url += '&ambito=' + ambito;
    return this.http.get<Contatto>(url)
      .pipe(catchError(error => this.handleError(error)));
  }

  public getPrestazioniByContatto(idContatto: number): Observable<Prestazione[]> {
    const url = this.baseUrl + this.getPrestazioniByContattoUrl
      .replace(':idContatto', idContatto + '');
    return this.http.get<Prestazione[]>(url)
      .pipe(catchError(error => this.handleError(error)));
  }


  dettaglioLottoRipristina(body: DettaglioLottoRipristinaBody) {
    let url = this.baseUrl + this.postDettaglioLottoRipristinaUrl;
    return this.http.post<any>(url, body)
      .pipe(catchError(error => this.handleError(error)));
  }

  public getAnagrafica(idAnagrafica: number): Observable<DettaglioAnagrafica> {
    const url = this.baseUrl + this.getAnagraficaUrl
      .replace(':idAnagrafica', idAnagrafica + '');

    return this.http.get<DettaglioAnagrafica>(url)
      .pipe(catchError(error => this.handleError(error)));
  }


  public getPaginaAnagrafica(
    isFormAdvanced: boolean,
    codiceFiscale: string | null,
    cfCertificati: string | null,
    cognome: string | null,
    id: number | null,
    nome: string | null,
    birthDate: Date | null,
    nazioneNascita: string | null,
    anno: number | undefined,
    ambito: string | undefined,
    tipo_apporto_SSN: number | undefined,
    struttura: string | undefined,
    apertura_contatto: number | undefined,
    entita: string | undefined,
    stato_attuale: number | undefined,
    numeroPagina: number,
    dimensionePagina: number,
    colonnaOrdinamento: string | null,
    direzioneOrdinamento: string | null,
    ruolo: number | undefined,
    asl: string | undefined): Observable<Page<RicercaAnagrafica>> {

    let url = this.baseUrl + this.getPaginaAnagraficaUrl + '?';

    url += 'isFormAdvanced=' + isFormAdvanced;

    url += '&numeroPagina=' + numeroPagina;
    url += '&dimensionePagina=' + dimensionePagina;
    url += ruolo ? '&ruolo=' + ruolo : "";

    let codiceStruttura = this.sessionService.getSelectedStrutturaFromStorage()?.codice
    url += codiceStruttura ? '&codicestruttura=' + codiceStruttura : '';

    if (codiceFiscale != null && codiceFiscale != '') {
      url += '&codiceFiscale=' + codiceFiscale;
    }
    if (cfCertificati != null && cfCertificati != '') {
      url += '&cfCertificati=' + cfCertificati;
    }
    if (cognome != null && cognome != '') {
      url += '&cognome=' + cognome;
    }
    if (id) {
      url += '&id=' + id;
    }
    if (nome != null && nome != '') {
      url += '&nome=' + nome;
    }
    if (stato_attuale != null) {
      url += '&stato_attuale=' + stato_attuale;
    }

    url += anno ? '&anno=' + anno : '';
    url += ambito ? '&ambito=' + ambito : '';
    url += tipo_apporto_SSN ? '&tipo_apporto_SSN=' + tipo_apporto_SSN : '';
    url += struttura ? '&struttura=' + struttura : '';
    url += apertura_contatto ? '&apertura_contatto=' + apertura_contatto : '';
    url += entita ? '&entita=' + entita : '';
    url += asl ? '&asl=' + asl : '';

    if (birthDate != null) {
      url += '&birthDate=' + this.datepipe.transform(birthDate, 'yyyy-MM-dd')
    }

    if (nazioneNascita != null && nazioneNascita != '') {
      url += '&nazioneNascita=' + nazioneNascita
    }

    if (colonnaOrdinamento != null) {
      url += '&colonnaOrdinamento=' + colonnaOrdinamento;
    }
    if (direzioneOrdinamento != null) {
      url += '&direzioneOrdinamento=' + direzioneOrdinamento;
    }
    return this.http.get<Page<RicercaAnagrafica>>(url)
      .pipe(catchError(error => this.handleError(error)));
  }

  public getPaginaAnagrafica_2(
    codiceFiscale: string | null,
    cognome: string | null,
    id: number | null,
    nome: string | null,
    birthDate: Date | null | undefined,
    anno: number | undefined,
    ambito: string | undefined,
    tipo_apporto_SSN: number | undefined,
    struttura: string | undefined,
    apertura_contatto: number | undefined,
    entita: string | undefined,
    stato_attuale: number | undefined,
    dimensionePagina: number,
    offset: number,
    colonnaOrdinamento: string | null,
    direzioneOrdinamento: string | null,
    ruolo: number | undefined,
    asl: string | undefined): Observable<RicercaAnagrafica[]> {

    let url = this.baseUrl + this.getPaginaAnagrafica_2Url;
    const map = new Map<string, Object | undefined | null>();
    let codiceStruttura = this.sessionService.getSelectedStrutturaFromStorage()?.codice
    map.set("tipoSSN", tipo_apporto_SSN);
    map.set("anno", anno);
    map.set("asl", asl);
    map.set("ambito", ambito);
    map.set("struttura", struttura);
    map.set("entita", entita);
    map.set("stato", stato_attuale);
    map.set("apertura", apertura_contatto);
    map.set("virtCF", codiceFiscale?.trim());
    map.set("virtCognome", cognome?.trim());
    map.set("virtNome", nome?.trim());
    map.set("idAna", id);
    map.set("codiceStrutturaOperatore", codiceStruttura);
    map.set("dataNascita", this.datepipe.transform(birthDate, 'yyyy-MM-dd'));
    const sd: SearchFilterAnagraficaDTO = {
      asl: asl, filter: Object.fromEntries(map), limit: dimensionePagina, offset: offset, ruolo: ruolo, sortColumn: colonnaOrdinamento, sortDirection: direzioneOrdinamento
    };
    return this.http.post<RicercaAnagrafica[]>(url, sd)
      .pipe(catchError(error => this.handleError(error)));
  }

  public getPaginaContatti(
    idAnagrafica: number,
    isFormAdvanced: boolean,
    codiceFiscale: string | null,
    cfCertificati: string | null,
    cognome: string | null,
    nome: string | null,
    birthDate: Date | null,
    nazioneNascita: string | null,
    anno: number | undefined,
    ambito: string | undefined,
    tipo_apporto_SSN: number | undefined,
    struttura: string | undefined,
    asl: string | undefined,
    apertura_contatto: number | undefined,
    entita: string | undefined,
    stato_attuale: number | undefined,
    numeroPagina: number,
    dimensionePagina: number,
    colonnaOrdinamento: string | null,
    direzioneOrdinamento: string | null,
    ruolo: number | undefined): Observable<Page<ContattoDto>> {

    let url = this.baseUrl + this.getPaginaContattiUrl + '?';
    url += 'idAnagrafica=' + idAnagrafica;
    url += '&isFormAdvanced=' + isFormAdvanced;

    url += '&numeroPagina=' + numeroPagina;
    url += '&dimensionePagina=' + dimensionePagina;


    let codiceStruttura = this.sessionService.getSelectedStrutturaFromStorage()?.codice
    url += '&codicestruttura=' + codiceStruttura;

    if (codiceFiscale != null && codiceFiscale != '') {
      url += '&codiceFiscale=' + codiceFiscale;
    }
    if (cfCertificati != null && cfCertificati != '') {
      url += '&cfCertificati=' + cfCertificati;
    }
    if (cognome != null && cognome != '') {
      url += '&cognome=' + cognome;
    }
    if (nome != null && nome != '') {
      url += '&nome=' + nome;
    }

    url += anno ? '&anno=' + anno : '';
    url += ambito ? '&ambito=' + ambito : '';
    url += tipo_apporto_SSN ? '&tipo_apporto_SSN=' + tipo_apporto_SSN : '';
    url += struttura ? '&struttura=' + struttura : '';
    url += asl ? '&asl=' + asl : '';
    url += apertura_contatto ? '&apertura_contatto=' + apertura_contatto : '';
    url += entita ? '&entita=' + entita : '';
    url += stato_attuale ? '&stato_attuale=' + stato_attuale : '';
    url += ruolo ? '&ruolo=' + ruolo : '';

    if (birthDate != null) {
      url += '&birthDate=' + this.datepipe.transform(birthDate, 'yyyy-MM-dd')
    }

    if (nazioneNascita != null && nazioneNascita != '') {
      url += '&nazioneNascita=' + nazioneNascita
    }

    if (colonnaOrdinamento != null) {
      url += '&colonnaOrdinamento=' + colonnaOrdinamento;
    }
    if (direzioneOrdinamento != null) {
      url += '&direzioneOrdinamento=' + direzioneOrdinamento;
    }
    return this.http.get<Page<ContattoDto>>(url)
      .pipe(catchError(error => this.handleError(error)));
  }


  // getPaginaContatti(id: number, numeroPagina: number,
  //   dimensionePagina: number,
  //   colonnaOrdinamento: string | null,
  //   direzioneOrdinamento: string | null): Observable<Page<ContattoDto>> {
  //
  //   let codiceStruttura = this.sessionService.getSelectedStrutturaFromStorage()?.codice
  //
  //   let url = this.baseUrl + this.getPaginaContattiUrl + "id=" + id  + "&codicestruttura=" +codiceStruttura!! + "&numeroPagina=" + numeroPagina + "&dimensionePagina=" + dimensionePagina
  //
  //   if (colonnaOrdinamento != null) {
  //     url += '&colonnaOrdinamento=' + colonnaOrdinamento;
  //   }
  //   if (direzioneOrdinamento != null) {
  //     url += '&direzioneOrdinamento=' + direzioneOrdinamento;
  //   }
  //
  //   return this.http.get<Page<ContattoDto>>(url)
  //     .pipe(catchError(error => this.handleError(error)));
  //
  // }


  saveDettaglioAnagrafica(dettaglioAnagrafica: DettaglioAnagrafica, codiceStruttura: string) {


    let url = this.baseUrl + this.postAnagraficaUrl + codiceStruttura
    let date = new Date(dettaglioAnagrafica.dataNascita!);
    date.setHours(12)
    dettaglioAnagrafica.dataNascita = date;
    return this.http.post<DettaglioAnagrafica>(url, JSON.parse(JSON.stringify(dettaglioAnagrafica)))
      .pipe(catchError(error => this.handleError(error)));

  }


  saveContatto(contatto: Contatto, codiceStruttura: string, redirect2interventoForm?: boolean, confermaDiagnosi?: boolean) {

    let url = this.baseUrl + this.postContattoUrl + codiceStruttura + "/" + confermaDiagnosi + (redirect2interventoForm ? "?itervento=true" : "");

    return this.http.post<Contatto>(url, JSON.parse(JSON.stringify(contatto)))
      .pipe(catchError(error => this.handleError(error)));
  }


  getPaginaPrestazioni(id: number, numeroPagina: number,
                       dimensionePagina: number,
                       colonnaOrdinamento: string | null,
                       direzioneOrdinamento: string | null): Observable<Page<Prestazione>> {

    let codiceStruttura = this.sessionService.getSelectedStrutturaFromStorage()?.codice

    let url = this.baseUrl + this.getPaginaPrestazioniUrl + "id=" + id + "&codicestruttura=" + codiceStruttura + "&numeroPagina=" + numeroPagina + "&dimensionePagina=" + dimensionePagina

    if (colonnaOrdinamento != null) {
      url += '&colonnaOrdinamento=' + colonnaOrdinamento;
    }
    if (direzioneOrdinamento != null) {
      url += '&direzioneOrdinamento=' + direzioneOrdinamento;
    }

    return this.http.get<Page<Prestazione>>(url)
      .pipe(catchError(error => this.handleError(error)));

  }

  getUtenteProfile(username: string): Observable<Utente> {

    let url = this.baseUrl + this.getUtenteProfileUrl + username

    return this.http.get<Utente>(url)
      .pipe(catchError(error => this.handleError(error)));
  }


  getUtente(): Observable<Utente> {

    let url = this.baseUrl + this.getUtenteUrl

    return this.http.get<Utente>(url)
      .pipe(catchError(error => this.handleError(error)));
  }

  getUtenteByRole(roleid: number): Observable<Utente> {
    let url = this.baseUrl + this.getUtenteUrl + "/withRole/" + roleid;
    return this.http.get<Utente>(url)
      .pipe(catchError(error => this.handleError(error)));
  }

  savePrestazione(prestazione: Prestazione, codiceStruttura: string) {

    //FIXME: prestazione.contatto?.diagnosiEScaleList=[];
    let url = this.baseUrl + this.postprestazioneUrl + codiceStruttura

    return this.http.post<Prestazione>(url, prestazione)
      .pipe(catchError(error => this.handleError(error)));
  }


  // RESOURCE DATA

  private asl$: Observable<Asl[]> | undefined;

  getAllAsl(): Observable<Asl[]> {

    let url = this.baseUrl + this.getAslUrl

    if (!this.asl$) {
      this.asl$ = this.http.get<Asl[]>(url).pipe(
        shareReplay()
      );
    }

    return this.asl$;
  }


  getDistrettoByAsl(codiceAsl: string): Observable<Distretto[]> {

    let url = this.baseUrl + this.getDistrettoByAslUrl.replace(":codiceAsl", codiceAsl)

    return this.http.get<Distretto[]>(url)
  }

  getMunicipiByAsl(codiceAsl: string): Observable<Municipio[]> {

    let url = this.baseUrl + this.getMunicipiByAslUrl.replace(":codiceAsl", codiceAsl)

    return this.http.get<Municipio[]>(url)
  }

  getMunicipiByDistrettoResidenza(distrettoResidenza: string): Observable<Municipio[]> {

    let url = this.baseUrl + this.getMunicipiByDistrettoResidenzaUrl
      + '?distrettoResidenza=' + distrettoResidenza

    return this.http.get<Municipio[]>(url)
  }


  getAslByComune(codiceComune: string): Observable<Asl[]> {

    let url = this.baseUrl + this.getAslByComuneUrl.replace(":codiceComune", codiceComune)

    return this.http.get<Asl[]>(url)
  }
  getLastNrInseritoPrestazione(idContatto: number): Observable<number> {

    let url = this.baseUrl + this.lastNrInseritoPrestazioneUrl.replace(":idcontatto", idContatto + '')

    return this.http.get<number>(url)
  }


  private statiEsteri$!: Observable<StatoEstero[]>;

  getAllStatiEsteri(): Observable<StatoEstero[]> {

    let url = this.baseUrl + this.getStatiEsteriUrl
    if (!this.statiEsteri$) {
      this.statiEsteri$ = this.http.get<StatoEstero[]>(url).pipe(shareReplay());
    }
    return this.statiEsteri$;
  }

  getAllStatiCivili(): Observable<StatoCivile[]> {

    let url = this.baseUrl + this.getStatiCiviliUrl

    return this.http.get<StatoCivile[]>(url)

  }


  getAllComuniByRegione(codRegione: string): Observable<Comune[]> {
    if (codRegione) {
      let url = this.baseUrl + this.getComuniByRegione.replace(":codRegione", codRegione)
      return this.http.get<Comune[]>(url).pipe(catchError(error => this.handleError(error)));
    } else {
      //let url = this.baseUrl + this.getComuni
      //return this.http.get<Comune[]>(url).pipe(catchError(error => this.handleError(error)));
      return this.getAllComuni();
    }

  }


  private comuni$!: Observable<Comune[]>;

  getAllComuni(): Observable<Comune[]> {

    let url = this.baseUrl + this.getComuni
    if (!this.comuni$) {
      this.comuni$ = this.http.get<Comune[]>(url).pipe(
        shareReplay()
      );
    }
    return this.comuni$;
  }

  private regioni!: Observable<Regione[]>;

  getAllRegioni(): Observable<Regione[]> {
    let url = this.baseUrl + this.getRegioni
    if (!this.regioni) {
      this.regioni = this.http.get<Regione[]>(url).pipe(
        shareReplay()
      );
    }
    return this.regioni;

  }

  private collocazioniSocioAmbientali$!: Observable<CollazioneSocioAmbientale[]>;

  getAllCollazioeSocioAmbientali(): Observable<CollazioneSocioAmbientale[]> {
    let url = this.baseUrl + this.getCollazioneSocioAmbientali
    if (!this.collocazioniSocioAmbientali$) {
      this.collocazioniSocioAmbientali$ = this.http.get<CollazioneSocioAmbientale[]>(url).pipe(shareReplay());
    }
    return this.collocazioniSocioAmbientali$;
  }

  private titoliStudio$!: Observable<TitoloStudio[]>;

  getAllTitoliStudio(): Observable<TitoloStudio[]> {
    let url = this.baseUrl + this.getTitoliStudio
    if (!this.titoliStudio$) {
      this.titoliStudio$ = this.http.get<TitoloStudio[]>(url).pipe(shareReplay())
    }
    return this.titoliStudio$;
  }

  private attivita$: Observable<Ativitta[]> | undefined;

  getAllAtivitta(): Observable<Ativitta[]> {

    let url = this.baseUrl + this.getAtivittaUrl

    if (!this.attivita$) {
      this.attivita$ = this.http.get<Ativitta[]>(url).pipe(
        shareReplay()
      );
    }
    return this.attivita$;
  }

  private invalidita$!: Observable<Invalidita[]>;

  getAllInvalidita(): Observable<Invalidita[]> {
    let url = this.baseUrl + this.getInvaliditaUrl
    if (!this.invalidita$) {
      this.invalidita$ = this.http.get<Invalidita[]>(url).pipe(shareReplay());
    }
    return this.invalidita$;
  }

  getAllTuteleGiuridice(): Observable<TutelaGiuridica[]> {

    let url = this.baseUrl + this.getTuteleGiuridiceUrl

    return this.http.get<TutelaGiuridica[]>(url)

  }

  getAllProvedimenti(): Observable<Provedimento[]> {

    let url = this.baseUrl + this.getProvedimentiUrl

    return this.http.get<Provedimento[]>(url)

  }

  getAllCitadinanza(): Observable<Cittadinanza[]> {

    let url = this.baseUrl + this.getCittadinanzaUrl

    return this.http.get<Cittadinanza[]>(url)

  }

  getAllSociSanitari(): Observable<SoccioSanitario[]> {

    let url = this.baseUrl + this.getSocciSanitariUrl

    return this.http.get<SoccioSanitario[]>(url)

  }

  private richiestePrevalenti$: Observable<RichiestaPrevalente[]> | undefined;

  getAllRichiestePrevalenti(): Observable<RichiestaPrevalente[]> {
    let url = this.baseUrl + this.getRichiestePrevalentiUrl
    if (!this.richiestePrevalenti$) {
      this.richiestePrevalenti$ = this.http.get<RichiestaPrevalente[]>(url).pipe(
        shareReplay()
      );
    }
    return this.richiestePrevalenti$;
  }

  private diagnosi$: Observable<Diagnosi[]> | undefined;

  getAllDiagnosi(): Observable<Diagnosi[]> {
    let url = this.baseUrl + this.getDiagnosiUrl
    if (!this.diagnosi$) {
      this.diagnosi$ = this.http.get<Diagnosi[]>(url).pipe(
        shareReplay()
      );
    }
    return this.diagnosi$;
  }

  getAllPrecedentiContatti(): Observable<PrecedenteContatto[]> {
    let url = this.baseUrl + this.getPrecedentiContattiUrl
    return this.http.get<PrecedenteContatto[]>(url)
  }

  getAllInviantiTerritoriale(): Observable<Inviante[]> {
    let url = this.baseUrl + this.getInvianteTerritorialeUrl
    return this.http.get<Inviante[]>(url)
  }

  getAllInviantiSemiresidenziali(): Observable<Inviante[]> {
    let url = this.baseUrl + this.getInvianteSemiresidenzialiUrl
    return this.http.get<Inviante[]>(url)
  }

  getAllModalitaConclusione(): Observable<ModalitaConclusione[]> {
    let url = this.baseUrl + this.getModalitaConclusioneUrl
    return this.http.get<ModalitaConclusione[]>(url)
  }

  private tipoPrestazioneTerritoriale$: Observable<TipoPrestazione[]> | undefined;

  getAllTipiPrestazioneTerritoriali(): Observable<TipoPrestazione[]> {
    let url = this.baseUrl + this.getTipiPrestazioneTerritorialiUrl
    if (!this.tipoPrestazioneTerritoriale$) {
      this.tipoPrestazioneTerritoriale$ = this.http.get<TipoPrestazione[]>(url).pipe(
        shareReplay()
      );
    }
    return this.tipoPrestazioneTerritoriale$;
  }

  private tipoPrestazioneSemiresidenziale$: Observable<TipoPrestazione[]> | undefined;

  getAllTipiPrestazioneSemiresidenziali(): Observable<TipoPrestazione[]> {

    let url = this.baseUrl + this.getTipiPrestazioneSemiresidenzialiUrl

    if (!this.tipoPrestazioneSemiresidenziale$) {
      this.tipoPrestazioneSemiresidenziale$ = this.http.get<TipoPrestazione[]>(url).pipe(
        shareReplay()
      );
    }
    return this.tipoPrestazioneSemiresidenziale$;

  }

  getAllModalitaErogazione(): Observable<ModalitaErogazione[]> {

    let url = this.baseUrl + this.getModalitaErogazioneUrl

    return this.http.get<ModalitaErogazione[]>(url)

  }

  getAllSedeinterventoTerritoriali(): Observable<SedeIntervento[]> {

    let url = this.baseUrl + this.getSedeInterventoTerritorialiUrl

    return this.http.get<SedeIntervento[]>(url)

  }

  getAllSedeinterventoSemiresidenziali(): Observable<SedeIntervento[]> {

    let url = this.baseUrl + this.getSedeInterventoSemiResidenzialiUrl

    return this.http.get<SedeIntervento[]>(url)

  }


  getAllOperatori(): Observable<Operatore[]> {

    let url = this.baseUrl + this.getOperatoriUrl

    return this.http.get<Operatore[]>(url)

  }

  getAllContattiByAnagraficaId(id: number): Observable<Contatto[]> {

    let url = this.baseUrl + this.getContattiByAnagraficaIdUrl + id

    return this.http.get<Contatto[]>(url)

  }

  getAllDistretti(): Observable<Distretto[]> {

    let url = this.baseUrl + this.getDistrettiUrl

    return this.http.get<Distretto[]>(url)

  }

  getAllMunicipi(): Observable<Municipio[]> {

    let url = this.baseUrl + this.getMunicipiUrl

    return this.http.get<Municipio[]>(url)
  }

  getStrutture(codiciList: string[] | null): Observable<StrutturaStr[]> {

    let url = this.baseUrl + this.getAllStrutture
    if (codiciList != null || codiciList != undefined) {
      url += "?codiciList=" + codiciList
    }


    return this.http.get<StrutturaStr[]>(url)
  }


  getAllRispostePrevalenti(): Observable<RispostaPrevalente[]> {

    let url = this.baseUrl + this.getAllRispostePrevalentiUrl

    return this.http.get<RispostaPrevalente[]>(url)
      .pipe(catchError(error => this.handleError(error)));

  }

  getDettaglioPreseIncarico(
    stato: string,
    attivita: string,
    anno: number,
    periodo: string | null,
    codiceDsm: string | null,
    idAnagrafica: number,
  ): Observable<DettaglioPreseInCaricoDto> {
    let url = this.baseUrl + this.getDettaglioPreseIncaricoUrl + "?stato=" + stato + "&attivita=" + attivita + "&anno=" + anno + "&periodo="
      + periodo + "&codiceDsm=" + codiceDsm;
    url += '&idAnagrafica=' + idAnagrafica;
    return this.http.get<DettaglioPreseInCaricoDto>(url)
      .pipe(catchError(error => this.handleError(error)));
  }


  getDettaglioPreseIncaricoByIdDettaglioLotto(
    idDettaglioLotto: number,): Observable<DettaglioPreseInCaricoDto> {
    let url = this.baseUrl + this.getDettaglioPreseIncaricoByIdDettaglioLottoUrl
      + "?idDettaglioLotto=" + idDettaglioLotto;

    return this.http.get<DettaglioPreseInCaricoDto>(url)
      .pipe(catchError(error => this.handleError(error)));
  }


  getPreseIncarico(
    stato: string,
    attivita: string,
    anno: number,
    periodo: string | null,
    codiceDsm: string | null,
    numeroPagina: number,
    dimensionePagina: number,
  ): Observable<Page<PreseInCarico>> {
    let url = this.baseUrl + this.getPreseIncaricoUrl + "?stato=" + stato + "&attivita=" + attivita + "&anno=" + anno + "&periodo=" + periodo + "&codiceDsm=" + codiceDsm;
    url += '&numeroPagina=' + numeroPagina;
    url += '&dimensionePagina=' + dimensionePagina;
    return this.http.get<Page<PreseInCarico>>(url)
      .pipe(catchError(error => this.handleError(error)));
  }

  getLotti(
    stato: string,
    id: number,
    data: string,
    numeroPagina: number,
    dimensionePagina: number,
  ): Observable<Page<Lotti>> {
    let url = this.baseUrl + this.getLottiUrl + "?stato=" + stato + "&id=" + id + "&data=" + data;
    url += '&numeroPagina=' + numeroPagina;
    url += '&dimensionePagina=' + dimensionePagina;
    return this.http.get<Page<Lotti>>(url)
      .pipe(catchError(error => this.handleError(error)));

  }

  trasmettiPreseInCarico(stato: string,
                         attivita: string,
                         anno: number,
                         periodo: string | null,
                         listaIdAnagrafichhe: number[]
  ) {
    let url = this.baseUrl + this.trasmettiPreseInCaricoUrl + "?stato=" + stato + "&attivita=" + attivita
      + "&anno=" + anno + "&periodo=" + periodo + "&listidanagrafica=" + listaIdAnagrafichhe;
    return this.http.get(url)
      .pipe(catchError(error => this.handleError(error)));

  }

  updateStatusAslValidator(
    stato: number,
    message: string,
    listaIdContatti: number[],
    anno: number
  ) {
    let url = this.baseUrl + this.updateStatusAslValidatorUrl + "?stato=" + stato + "&message=" + message + "&anno=" + anno
    //   + "&listidcontatti=" + listaIdContatti;
    // return this.http.get(url)
    //   .pipe(catchError(error => this.handleError(error)));
    return this.http.post<any>(url, JSON.parse(JSON.stringify(listaIdContatti)))
      .pipe(catchError(error => this.handleError(error)));
  }

  updateStatusContatti(
    stato: number,
    message: string,
    listaIdContatti: number[],
  ) {
    let url = this.baseUrl + this.updateStatusContattiUrl + "?stato=" + stato + "&message=" + message
      + "&listidcontatti=" + listaIdContatti;
    return this.http.get(url)
      .pipe(catchError(error => this.handleError(error)));

  }

  updateStatusIntervento(
    id: number,
    stato: number,
    message: string,
  ) {
    let url = this.baseUrl + this.updateStatusInterventoUrl + "?stato=" + stato + "&message=" + message
      + "&id=" + id;
    return this.http.get(url)
      .pipe(catchError(error => this.handleError(error)));

  }

  updateStatusDiagnosi(
    id: number,
    stato: number,
    message: string,
  ) {
    let url = this.baseUrl + this.updateStatusDiagnosiUrl + "?stato=" + stato + "&message=" + message
      + "&id=" + id;
    return this.http.get(url)
      .pipe(catchError(error => this.handleError(error)));

  }

  updateStatusPresenza(
    id: number,
    stato: number,
    message: string,
  ) {
    let url = this.baseUrl + this.updateStatusPresenzaUrl + "?stato=" + stato + "&message=" + message
      + "&id=" + id;
    return this.http.get(url)
      .pipe(catchError(error => this.handleError(error)));

  }


  updateStatusPrestazione(
    id: number,
    stato: number,
    message: string,
  ) {
    let url = this.baseUrl + this.updateStatusPrestazioneUrl + "?stato=" + stato + "&message=" + message
      + "&id=" + id;
    return this.http.get(url)
      .pipe(catchError(error => this.handleError(error)));

  }

  async codiceFiscaleExist(codiceFiscale: string) {

    let url = this.baseUrl + this.codiceFiscaleExxistUrl + "/" + codiceFiscale
    let exist = await firstValueFrom(this.http.get<string>(url))

    console.log("exist ", exist);
    if (exist) {


      return true
    } else {
      return false
    }

  }

  getAllAnagraficaContatti(id: number): Observable<Contatto[]> {

    let codiceStruttura = this.sessionService.getSelectedStrutturaFromStorage()?.codice

    let url = this.baseUrl + this.getContattiByAnagraficaIdUrl + id + "/struttura/" + codiceStruttura

    return this.http.get<Contatto[]>(url)

  }

  handleError(error: Response | any) {
    console.log('handleError ' + error);
    this.toastService.showProblemaTecnico();
    return throwError(error);
  }

  getLottiFlussiIngresso(
    anno: number,
    struttura: string,
    attivita: string,
    periodo: string,
    statoElaborazione: string,
    numeroPagina: number,
    dimensionePagina: number,
  ): Observable<Page<Lotti>> {
    let url = this.baseUrl + this.getLottiFlussiIngressoUrl + "?anno=" + anno + "&struttura=" + struttura + "&attivita=" + attivita + "&periodo=" + periodo + "&statoElaborazione=" + statoElaborazione;
    url += '&numeroPagina=' + numeroPagina;
    url += '&dimensionePagina=' + dimensionePagina;
    return this.http.get<Page<Lotti>>(url)
      .pipe(catchError(error => this.handleError(error)));

  }


  getDatiInterventiIngresso(
    anno: number,
    tipoStruttura: string,
    struttura: string,
    stato: string,
    cf: string | undefined,
    numeroPagina: number,
    dimensionePagina: number,
  ): Observable<Page<Lotti>> {
    let url = this.baseUrl + this.getDatiIngressoUrl + "?anno=" + anno + "&tipoStruttura=" + tipoStruttura + "&struttura=" + struttura + "&stato=" + stato + (cf ? "&cf=" + cf : "");
    url += '&numeroPagina=' + numeroPagina;
    url += '&dimensionePagina=' + dimensionePagina;
    return this.http.get<Page<Lotti>>(url)
      .pipe(catchError(error => this.handleError(error)));

  }
  getDatiInterventiIngressoNoPaging(
    anno: number,
    tipoStruttura: string,
    struttura: string,
    stato: string,
    cf: string | undefined,
    count: boolean
  ): Observable<Page<Lotti>> {
    let url = this.baseUrl + this.getDatiIngressoNoPagingUrl + "?anno=" + anno + "&tipoStruttura=" + tipoStruttura + "&struttura=" + struttura + "&stato=" + stato + (cf ? "&cf=" + cf : "")+"&count="+count;
    return this.http.get<Page<Lotti>>(url)
      .pipe(catchError(error => this.handleError(error)));

  }

  getAnagraficaAslValidator(
    idContatto: string | null
  ): Observable<DettaglioAnagrafica> {
    let url = this.baseUrl + this.getAnagraficaAslValidatorUrl + "?idcontatto=" + idContatto;
    return this.http.get<DettaglioAnagrafica>(url)
      .pipe(catchError(error => this.handleError(error)));
  }

  getContattiAslValidator(
    idContatto: string | null,
    statoAttuale: string | null
  ): Observable<Array<Contatto>> {
    let url = this.baseUrl + this.getContattiAslValidatorUrl + "?idcontatto=" + idContatto + "&statoattuale=" + statoAttuale;
    return this.http.get<Array<Contatto>>(url)
      .pipe(catchError(error => this.handleError(error)));
  }

  getInterventiAslValidator(
    idContatto: string | null,
    statoAttuale: string | null
  ): Observable<Array<Intervento>> {
    let url = this.baseUrl + this.getInterventiAslValidatorUrl + "?idcontatto=" + idContatto + "&statoattuale=" + statoAttuale;
    return this.http.get<Array<Intervento>>(url)
      .pipe(catchError(error => this.handleError(error)));
  }

  getDiagnosiEScaleAslValidator(
    idContatto: string | null,
    statoAttuale: string | null
  ): Observable<Array<Intervento>> {
    let url = this.baseUrl + this.getDiagnosiEScaleAslValidatorUrl + "?idcontatto=" + idContatto + "&statoattuale=" + statoAttuale;
    return this.http.get<Array<Intervento>>(url)
      .pipe(catchError(error => this.handleError(error)));
  }


  getPresenzeAslValidator(
    idContatto: string | null,
    statoAttuale: string | null
  ): Observable<Array<Presenza>> {
    let url = this.baseUrl + this.getPresenzeAslValidatorUrl + "?idcontatto=" + idContatto + "&statoattuale=" + statoAttuale;
    return this.http.get<Array<Presenza>>(url)
      .pipe(catchError(error => this.handleError(error)));
  }


  getPrestazioneAslValidator(
    idContatto: string | null,
    statoAttuale: string | null
  ): Observable<Array<Prestazione>> {
    let url = this.baseUrl + this.getPrestazioneAslValidatorUrl + "?idcontatto=" + idContatto + "&statoattuale=" + statoAttuale;
    return this.http.get<Array<Prestazione>>(url)
      .pipe(catchError(error => this.handleError(error)));
  }

  getStruttureAll(
    codStruttura: string,
    codAzienda: string
  ) {
    // : Observable<Page<Strutture>> {
    let url = this.baseUrl + this.getStruttureUrl + "?cod_struttura=" + codStruttura + "&cod_azienda=" + codAzienda;
    return this.http.get<Page<Strutture>>(url)
      .pipe(catchError(error => this.handleError(error)));

  }

  getStruttureByParams(
    anno: number,
    ambito: string,
    ssn: string,
    competenze: string,
    asl: string,
    idRuolo: number
  ) {
    // : Observable<Page<Strutture>> {
    let url = this.baseUrl + this.getStruttureUrlParams + "?anno=" + anno + "&ssn=" + ssn + "&ambito=" + ambito + "&competenze=" + competenze + "&asl=" + asl + "&idRuolo=" + idRuolo;
    return this.http.get<Page<Strutture>>(url)
      .pipe(catchError(error => this.handleError(error)));

  }

  getDettaglioLottoAll(
    idLotto: number,
    semestre: string,
    annoCompetenza: number,
    numeroPagina: number,
    dimensionePagina: number,
  ): Observable<Page<DettaglioLotto>> {
    let url = this.baseUrl + this.getDettaglioLottoUrl + "?idLotto=" + idLotto + "&semestre=" + semestre + "&annoCompetenza=" + annoCompetenza;
    url += '&numeroPagina=' + numeroPagina;
    url += '&dimensionePagina=' + dimensionePagina;
    return this.http.get<Page<DettaglioLotto>>(url)
      .pipe(catchError(error => this.handleError(error)));

  }


  updateLotto(
    idLotto: number,
    stato: string, motivazioneScarto: string) {
    let url = this.baseUrl + this.updateLottoUrl + "?idLotto=" + idLotto
      + "&stato=" + stato
      + "&motivazioneScarto=" + motivazioneScarto;
    return this.http.get(url).pipe(catchError(error => this.handleError(error)));
  }

  saveDettaglioLotto(
    idLotto: number,
    stato: string, idDettaglio: Number) {
    let url = this.baseUrl + this.saveDettaglioLottoUrl + "?idLotto=" + idLotto
      + "&stato=" + stato
      + "&idDettaglio=" + idDettaglio;
    return this.http.get(url).pipe(catchError(error => this.handleError(error)));
  }

  getAslByParameter(
    struttura: string,
    numeroPagina: number,
    dimensionePagina: number
  ): Observable<Page<Asl>> {
    let url = this.baseUrl + this.getAslByParameterUrl + "?cod_struttura=" + struttura;
    url += '&numeroPagina=' + numeroPagina;
    url += '&dimensionePagina=' + dimensionePagina;
    return this.http.get<Page<Asl>>(url)
      .pipe(catchError(error => this.handleError(error)));

  }

  getDashboardData(
    anno: number,
    ambito: string,
    ssn: string,
    struttura: string,
    competenzeTerritoriali: string,
    asl: string,
    ruolo: number | undefined,
    firstSection: boolean,
    secondSection:boolean,
    thirdSection: boolean
  ): Observable<Array<Dashboard>> {
    let url = this.baseUrl + this.getDashboardDataUrl + "?anno=" + anno + "&ambito=" + ambito + "&ssn=" + ssn + "&struttura=" + struttura + "&competenze=" + competenzeTerritoriali + "&asl=" + asl + "&firstSection=" + firstSection+ "&secondSection=" + secondSection + "&thirdSection=" + thirdSection;
    url = ruolo ? url + "&ruolo=" + ruolo : url;
    return this.http.get<Array<Dashboard>>(url)
      .pipe(catchError(error => this.handleError(error)));

  }


  cancellaAnagrafica(id: number, struttura: string) {

    let url = this.baseUrl + this.anagraficaCancellaUrl + id + "/" + struttura

    return this.http.delete(url)

  }

  cancellaContatto(id: number) {
    let url = this.baseUrl + this.contattoCancellaUrl + id
    return this.http.delete(url)
  }

  cancellaPrestazione(id: number) {
    let url = this.baseUrl + this.prestazioneCancellaUrl + id
    return this.http.delete(url)
  }


  cancellaIntervento(id: number) {
    let url = this.baseUrl + this.interventoCancellaUrl + id
    return this.http.delete(url)
  }

  cancellaPresenza(id: number) {
    let url = this.baseUrl + this.cancellPresenzaUrl + id
    return this.http.delete(url)
  }

  getPaginaInterventi(id: number, numeroPagina: number,
                      dimensionePagina: number,
                      colonnaOrdinamento: string | null,
                      direzioneOrdinamento: string | null): Observable<Page<Intervento>> {

    let codicestruttura = this.sessionService.getSelectedStrutturaFromStorage()?.codice

    let url = this.baseUrl + this.getPaginaInterventiUrl + "id=" + id + "&codicestruttura=" + codicestruttura + "&numeroPagina=" + numeroPagina + "&dimensionePagina=" + dimensionePagina

    if (colonnaOrdinamento != null) {
      url += '&colonnaOrdinamento=' + colonnaOrdinamento;
    }
    if (direzioneOrdinamento != null) {
      url += '&direzioneOrdinamento=' + direzioneOrdinamento;
    }

    return this.http.get<Page<Intervento>>(url)
      .pipe(catchError(error => this.handleError(error)));

  }

  saveIntervento(intervento: Intervento, codiceStruttura: string) {

    let url = this.baseUrl + this.postInterventoUrl + codiceStruttura

    return this.http.post<Intervento>(url, JSON.parse(JSON.stringify(intervento)))
      .pipe(catchError(error => this.handleError(error)));

  }

  aggiornaIeIntervento(intervento: Intervento, codiceStruttura: string) {
    let url = this.baseUrl + this.postAggiornaIeInterventoUrl + codiceStruttura
    return this.http.post<Intervento>(url, JSON.parse(JSON.stringify(intervento)))
      .pipe(catchError(error => this.handleError(error)));
  }


  cancellaDiagnosiEScala(id: number) {
    let url = this.baseUrl + this.diagnosiEScalaCancellaUrl + id
    return this.http.delete(url)
  }

  getPaginaDiagnosiEScala(id: number, numeroPagina: number,
                          dimensionePagina: number,
                          colonnaOrdinamento: string | null,
                          direzioneOrdinamento: string | null): Observable<Page<DiagnosiEScale>> {

    let codicestruttura = this.sessionService.getSelectedStrutturaFromStorage()?.codice

    let url = this.baseUrl + this.getPaginaDiagnosiEScaleUrl + "id=" + id + "&codicestruttura=" + codicestruttura + "&numeroPagina=" + numeroPagina + "&dimensionePagina=" + dimensionePagina

    if (colonnaOrdinamento != null) {
      url += '&colonnaOrdinamento=' + colonnaOrdinamento;
    }
    if (direzioneOrdinamento != null) {
      url += '&direzioneOrdinamento=' + direzioneOrdinamento;
    }

    return this.http.get<Page<DiagnosiEScale>>(url)
      .pipe(catchError(error => this.handleError(error)));

  }

  saveDiagnosiEScala(diagnosiEScale: DiagnosiEScale, codiceStruttura: string) {

    let url = this.baseUrl + this.postDiagnosiEScaleUrl + codiceStruttura

    return this.http.post<DiagnosiEScale>(url, JSON.parse(JSON.stringify(diagnosiEScale)))
      .pipe(catchError(error => this.handleError(error)));

  }


  getContattoById(id: number): Observable<Contatto> {

    let codiceStruttura = this.sessionService.getSelectedStrutturaFromStorage()?.codice

    let url = this.baseUrl + this.getContattoByIdAndCodiceStrutturaUrl + id + "/struttura/" + codiceStruttura

    return this.http.get<Contatto>(url)

  }

  chiudiContattoById(id: number, chiudiContattoMode: string | undefined) {

    let url = this.baseUrl + this.chiudiContattoUrl + id + '?mode=' + chiudiContattoMode;


    return this.http.get(url)
  }

  getListaPresenze(idContatto: number): Observable<Presenza[]> {
    let url = this.baseUrl + this.getListaPresenzeUrl
      .replace(':idContatto', idContatto + '');

    return this.http.get<Presenza[]>(url);
  }

  getPaginaPresenze(id: number, numeroPagina: number,
                    dimensionePagina: number,
                    colonnaOrdinamento: string | null,
                    direzioneOrdinamento: string | null): Observable<Page<PresenzaDto>> {

    let codiceStruttura = this.sessionService.getSelectedStrutturaFromStorage()?.codice

    let url = this.baseUrl + this.getPaginaPresenzeUrl + "id=" + id + "&codicestruttura=" + codiceStruttura + "&numeroPagina=" + numeroPagina + "&dimensionePagina=" + dimensionePagina

    if (colonnaOrdinamento != null) {
      url += '&colonnaOrdinamento=' + colonnaOrdinamento;
    }
    if (direzioneOrdinamento != null) {
      url += '&direzioneOrdinamento=' + direzioneOrdinamento;
    }

    return this.http.get<Page<PresenzaDto>>(url)


  }

  savePresenza(presenza: Presenza, codiceStruttura: string) {

    let url = this.baseUrl + this.postPresenzaUrl + codiceStruttura

    return this.http.post<Presenza>(url, presenza)


  }

  private chacheAllContattoNotAvailabeDates: { "urlc": string, "tmstmp": number, "data": Observable<NotAvailableDatesContainer> }[] = []

  async getAllContattoNotAvailabeDates(tipo_struttura: string, idContatto: number) {
    let url = this.baseUrl + this.contatoNotAvailableDatesUrl
      .replace(':idContatto', idContatto + '') + tipo_struttura;

    const oi = this.chacheAllContattoNotAvailabeDates ? this.chacheAllContattoNotAvailabeDates.find(value => value.urlc == url) : undefined;
    const now = Math.floor(Date.now() / 1000);
    if (oi && (now - oi.tmstmp < 8)) {
      //console.log("From cache");
      return await firstValueFrom(oi.data);
    } else {
      //console.log("NOT From cache");
      if (oi) {
        const index = this.chacheAllContattoNotAvailabeDates.indexOf(oi);
        if (index > -1) {
          this.chacheAllContattoNotAvailabeDates.splice(index, 1);
        }
      }
      const o = {
        urlc: url,
        tmstmp: Math.floor(Date.now() / 1000),
        data: this.http.get<NotAvailableDatesContainer>(url)
      };
      this.chacheAllContattoNotAvailabeDates.push(o);
      return await firstValueFrom(o.data)
    }
    //this.chacheAllContattoNotAvailabeDates = this.http.get<NotAvailableDatesContainer>(url)
  }

  getPrestazioniByPresenzaId(id: number) {


    let url = this.baseUrl + this.getPrestazioniByPresenzaIdUrl + id

    return this.http.get<Prestazione[]>(url)


  }

  selectContattoById(id: number) {


    let url = this.baseUrl + this.getContattoByIdUrl + id

    return this.http.get<Contatto>(url)


  }

  getLatestDiagnosi4Contatto(id: number | undefined) {
    let url = this.baseUrl + this.getLastDiagnosiByContattoID.replace(':idContatto', id + '');

    return this.http.get<DiagnosiEScale>(url)
  }

  knowageLogout() {

    return this.knowageUrl + "/knowage/servlet/AdapterHTTP?ACTION_NAME=LOGOUT_ACTION&LIGHT_NAVIGATOR_DISABLED=TRUE&NEW_SESSION=TRUE"

  }

  asurGetAssistito(cf: string | undefined) {

    let url = this.asurUrl + "assistito/search?codiceFiscale=" + cf;
    return this.http.get<any>(url);

  }

  public getAnagraficaByCf(codiceFiscale: string): Observable<DettaglioAnagrafica> {

    const url = this.baseUrl + this.getAnagraficaByCfUrl
      .replace(':cf', codiceFiscale + '');
    return this.http.get<DettaglioAnagrafica>(url)
      .pipe(catchError(error => this.handleError(error)));

  }

  public getAnagraficaStrutturaByCodStrutturaAndIdAnagrafica(codStruttura: string, idAnagrafica: number): Observable<AnagraficaStruttura> {
    const url = this.baseUrl + this.getAnagraficaStrutturaByCodStrutturaAndIdAnagraficaUrl
      .replace(':codiceStruttura', codStruttura + '').replace(':anagraficaId', idAnagrafica + '');
    return this.http.get<AnagraficaStruttura>(url)
      .pipe(catchError(error => this.handleError(error)));
  }

  saveAnagraficaStruttura(anagraficaStruttura: AnagraficaStruttura, idRuolo: number) {


    let url = this.baseUrl + this.saveAnagraficaStrutturaUrl + "?idRuolo=" + idRuolo;

    return this.http.post<AnagraficaStruttura>(url, JSON.parse(JSON.stringify(anagraficaStruttura)))
      .pipe(catchError(error => this.handleError(error)));


  }

  private confStati$: Observable<ConfStato[]> | undefined;

  clearAllCache() {
    this.confStati$ = undefined;
    this.chacheAllContattoNotAvailabeDates = [];
    this.confDiltriRicerca$ = undefined;
    this.diagnosi$ = undefined;
  }

  getAllConfStato(): Observable<ConfStato[]> {
    const url = this.baseUrl + this.getAllConfStatoUrl;
    if (!this.confStati$) {
      this.confStati$ = this.http.get<ConfStato[]>(url)
        .pipe(catchError(error => this.handleError(error)), shareReplay());
    }
    return this.confStati$;
  }

  getAnagraficaStorica(idContatto: number): Observable<DettaglioAnagrafica> {
    let url = this.baseUrl + this.anagraficaStoricaUrl
      .replace(':idContatto', idContatto + '')
    return this.http.get<DettaglioAnagrafica>(url)
  }

  private confDiltriRicerca$: Observable<ConfFiltriRicerca[]> | undefined;

  getAllConfFiltriRicerca(): Observable<ConfFiltriRicerca[]> {
    const url = this.baseUrl + this.getAllConfFiltriRicercaUrl;
    if (!this.confDiltriRicerca$) {
      this.confDiltriRicerca$ = this.http.get<ConfFiltriRicerca[]>(url)
        .pipe(catchError(error => this.handleError(error)), shareReplay());
    }
    return this.confDiltriRicerca$;

  }

  controllaCodiceEni(codice: string): Observable<any> {
    const url = this.baseUrl + this.controllaCodiceENIUrl.replace(':cod', codice + '');
    return this.http.get<any>(url)
      .pipe(catchError(error => this.handleError(error)));
  }

  riabilitaModifica(id: number, tipo: string): Observable<any> {
    const url = this.baseUrl + this.riabilitaModificaUrl.replace(':tipo', tipo + '').replace(':id', id + '');
    return this.http.post<any>(url, {})
      .pipe(catchError(error => this.handleError(error)));

  }

  getAnagraficaAuditByIdAndStruttura(id: number, struttura: string): Observable<AnagraficaAudit[]> {
    let url = this.baseUrl + this.getAnagraficaAuditByIdAndStrutturaUrl
      .replace(':id', id + '').replace(':struttura', struttura + '')
    return this.http.get<AnagraficaAudit[]>(url)
  }

  getContattoAuditByIdAndStruttura(id: number, struttura: string): Observable<ContattoAudit[]> {
    let url = this.baseUrl + this.getContattoAuditByIdAndStrutturaUrl
      .replace(':id', id + '').replace(':struttura', struttura + '')
    return this.http.get<ContattoAudit[]>(url)
  }

  getInterventoAuditByIdAndStruttura(id: number, struttura: string): Observable<InterventoAudit[]> {
    let url = this.baseUrl + this.getInterventoAuditByIdAndStrutturaUrl
      .replace(':id', id + '').replace(':struttura', struttura + '')
    return this.http.get<InterventoAudit[]>(url)
  }

  getIDiagnosiEscaleAuditByIdAndStruttura(id: number, struttura: string): Observable<DiagnosiEscaleAudit[]> {
    let url = this.baseUrl + this.getDiagnosiEscaleAuditByIdAndStrutturaUrl
      .replace(':id', id + '').replace(':struttura', struttura + '')
    return this.http.get<DiagnosiEscaleAudit[]>(url)
  }

  getPrestazioneAuditByIdAndStruttura(id: number, struttura: string, tipoStruttura: number): Observable<any[]> {
    let url = this.baseUrl + this.getPrestazioneAuditByIdAndStrutturaUrl
      .replace(':id', id + '').replace(':struttura', struttura + '').replace(':tipo', tipoStruttura + '')
    return this.http.get<any[]>(url)
  }

  getPresenzaAuditByIdAndStruttura(id: number, struttura: string): Observable<PresenzaAudit[]> {
    let url = this.baseUrl + this.getPresenzaAuditByIdAndStrutturaUrl
      .replace(':id', id + '').replace(':struttura', struttura + '')
    return this.http.get<PresenzaAudit[]>(url)
  }

  checkIfCanDeleteAnagrafica(id: number, codStruttura: string) {
    let url = this.baseUrl + this.anagraficaCheckIfCanDleteUrl
      .replace(':id', id + '').replace(':codStruttura', codStruttura + '')
    return this.http.get<any>(url)
  }

  downloadCsv(
    entity: string,
    anno: number,
    periodo: number,
    asl: string | undefined,
    ambito: string,
    ssn: number,
    strutture: string | undefined,
    statoContatto: number,
    statoRecord: number,
    tipoIdentificativo: string,
    nome: string,
    cognome: string,
    codiceFiscale: string,
    competenzeTerritoriali: string,
    struttureOperatore: string[] | undefined,
    misuraDiFrequenza: string,
    dateDa: string,
    dateA: string,
    avvioContatto: string,
    ruolo: number
  ): Observable<ArrayBuffer> {
    let headers = new HttpHeaders();
    let options: {
      headers?: HttpHeaders;
      observe?: 'body';
      params?: HttpParams;
      reportProgress?: boolean;
      responseType: 'arraybuffer';
      withCredentials?: boolean;
    } = {
      responseType: 'arraybuffer'
    };
    let url = this.baseUrl + this.downloadCsvUrl
      .replace(':entity', entity + '');
    url += "?anno=" + anno + "&periodo=" + periodo
      + "&asl=" + asl
      + "&ambito=" + ambito
      + "&ssn=" + ssn
      + "&strutture=" + strutture
      + "&statoContatto=" + statoContatto
      + "&statoRecord=" + statoRecord
      + "&tipoIdentificativo=" + tipoIdentificativo
      + "&nome=" + nome
      + "&cognome=" + cognome
      + "&codiceFiscale=" + codiceFiscale
      + "&competenzeTerritoriali=" + competenzeTerritoriali
      + "&struttureOperatore=" + struttureOperatore
      + "&misuraDiFrequenza=" + misuraDiFrequenza
      + "&dateDa=" + dateDa
      + "&dateA=" + dateA
      + "&ruolo=" + ruolo
      + "&avvioContatto=" + avvioContatto;
    return this.http.get(url, options).pipe(
      map((file: ArrayBuffer) => {
        return file;
      })
    );
  }

  getAllContattiByAnagraficaIdAndStruttura(id: number, struttura: string): Observable<Contatto[]> {

    let url = this.baseUrl + this.getContattiByAnagraficaIdAndStrutturaUrl.replace(':id', id + '').replace(":struttura", struttura + '');

    return this.http.get<Contatto[]>(url)

  }

  getConfStatoById(id: number): Observable<ConfStato> {
    const url = this.baseUrl + this.getConfStatoByIdUrl.replace(':id', id + '');

    return this.http.get<ConfStato>(url);

  }

  downloadXsd(name: string): Observable<ArrayBuffer> {

    let options: {
      headers?: HttpHeaders;
      observe?: 'body';
      params?: HttpParams;
      reportProgress?: boolean;
      responseType: 'arraybuffer';
      withCredentials?: boolean;
    } = {
      responseType: 'arraybuffer'
    };

    let url = this.baseUrl + this.downloadXsdUrl
      .replace(':name', name + '');

    return this.http.get(url, options).pipe(
      map((file: ArrayBuffer) => {

        return file;

      })
    );

  }

  public getStoricoByStruttura(
    struttura: string,
    numeroPagina: number,
    dimensionePagina: number,
    colonnaOrdinamento: string | null,
    direzioneOrdinamento: string | null,
  ): Observable<Page<StoricoImportXml>> {

    let url = this.baseUrl + this.storicoImportByStrutturaUrl + '?';

    url += '&numeroPagina=' + numeroPagina;
    url += '&dimensionePagina=' + dimensionePagina;
    url += '&struttura=' + struttura;

    if (colonnaOrdinamento != null) {
      url += '&orderBy=' + colonnaOrdinamento;
    }
    if (direzioneOrdinamento != null) {
      url += '&sortDirection=' + direzioneOrdinamento;
    }
    return this.http.get<Page<StoricoImportXml>>(url)
      .pipe(catchError(error => this.handleError(error)));
  }

  downloadXmlimport(id: number, tipo: string): Observable<ArrayBuffer> {

    let options: {
      headers?: HttpHeaders;
      observe?: 'body';
      params?: HttpParams;
      reportProgress?: boolean;
      responseType: 'arraybuffer';
      withCredentials?: boolean;
    } = {
      responseType: 'arraybuffer'
    };

    let url = this.baseUrl + this.downloadStoricoXmlImport
      .replace(':id', id + '').replace(':tipo', tipo + '');

    return this.http.get(url, options).pipe(
      map((file: ArrayBuffer) => {

        return file;

      })
    );

  }

  /**
   *
   * @param aslContattoAperto
   * @param aslUtente
   * @param contattoId
   * @param anagrafica
   */
  insertNotifica(aslContattoAperto: string, aslUtente: string, contattoId: string, anagrafica: DettaglioAnagrafica, codiceStruttura: string) {

    let url = this.baseUrl + this.insertNotificaUrl.replace(':aslContattoAperto', aslContattoAperto + '').replace(":aslUtente", aslUtente + '').replace(":idContatto", contattoId + '').replace(":codiceStruttura", codiceStruttura + '');

    return this.http.post<any>(url, anagrafica)
      .pipe(catchError(error => this.handleError(error)));

  }

  getStoricoContattiTerritoriali(idAnagrafica: number, codiceStruttura: string, codiceASL: string | undefined): Observable<any[]> {
    let url = this.baseUrl + this.getGenericAuditUrl
      .replace(':tipo', '1');
    //url+="?asl_struttura="+codiceASL+"&struttura="+codiceStruttura+"&anagrafica_id="+idAnagrafica;

    url += "?struttura=" + codiceStruttura + "&anagrafica_id=" + idAnagrafica;
    return this.http.get<any[]>(url)
  }

  getStoricoContattiSemiresidenziale(idAnagrafica: number, codiceStruttura: string, codiceASL: string | undefined): Observable<any[]> {
    let url = this.baseUrl + this.getGenericAuditUrl
      .replace(':tipo', '2');
    //url+="?asl_struttura="+codiceASL+"&struttura="+codiceStruttura+"&anagrafica_id="+idAnagrafica;
    url += "?struttura=" + codiceStruttura + "&anagrafica_id=" + idAnagrafica;
    return this.http.get<any[]>(url)
  }

  getStoricoInterventoTerritoriale(idContatto: number): Observable<any[]> {
    let url = this.baseUrl + this.getGenericAuditUrl
      .replace(':tipo', '3');
    //url+="?asl_struttura="+codiceASL+"&struttura="+codiceStruttura+"&anagrafica_id="+idAnagrafica;
    url += "?contatto_id=" + idContatto;
    return this.http.get<any[]>(url)
  }

  getStoricoDiagnosiEScaleTerritoriale(idContatto: number): Observable<any[]> {
    let url = this.baseUrl + this.getGenericAuditUrl
      .replace(':tipo', '4');
    //url+="?asl_struttura="+codiceASL+"&struttura="+codiceStruttura+"&anagrafica_id="+idAnagrafica;
    url += "?contatto_id=" + idContatto;
    return this.http.get<any[]>(url)
  }

  getStoricoPresenzeSemiresidenziale(idContatto: number): Observable<any[]> {
    let url = this.baseUrl + this.getGenericAuditUrl
      .replace(':tipo', '5');
    //url+="?asl_struttura="+codiceASL+"&struttura="+codiceStruttura+"&anagrafica_id="+idAnagrafica;
    url += "?contatto_id=" + idContatto;
    return this.http.get<any[]>(url)
  }

  getStoricoPrestazioneSemiresidenziale(idContatto: number): Observable<any[]> {
    let url = this.baseUrl + this.getGenericAuditUrl
      .replace(':tipo', '6');
    //url+="?asl_struttura="+codiceASL+"&struttura="+codiceStruttura+"&anagrafica_id="+idAnagrafica;
    url += "?contatto_id=" + idContatto;
    return this.http.get<any[]>(url)
  }

  getStoricoPrestazioneTerritoriale(idContatto: number): Observable<any[]> {
    let url = this.baseUrl + this.getGenericAuditUrl
      .replace(':tipo', '7');
    //url+="?asl_struttura="+codiceASL+"&struttura="+codiceStruttura+"&anagrafica_id="+idAnagrafica;
    url += "?contatto_id=" + idContatto;
    return this.http.get<any[]>(url)
  }

  getStoricoAnagrafica(codiceStruttura: string, codiceASL: string | undefined): Observable<any[]> {
    let url = this.baseUrl + this.getGenericAuditUrl
      .replace(':tipo', '1');
    //url+="?asl_struttura="+codiceASL+"&struttura="+codiceStruttura+"&anagrafica_id="+idAnagrafica;

    url += "?struttura=" + codiceStruttura;
    return this.http.get<any[]>(url)
  }

  getStoricoSingoloContattoSemiresidenziale(idContatto: number): Observable<any[]> {
    let url = this.baseUrl + this.getGenericAuditUrl
      .replace(':tipo', '10');
    //url+="?asl_struttura="+codiceASL+"&struttura="+codiceStruttura+"&anagrafica_id="+idAnagrafica;
    url += "?contatto_id=" + idContatto;
    return this.http.get<any[]>(url)
  }

  getStoricoSingoloContattoTerritoriale(idContatto: number): Observable<any[]> {
    let url = this.baseUrl + this.getGenericAuditUrl
      .replace(':tipo', '9');
    //url+="?asl_struttura="+codiceASL+"&struttura="+codiceStruttura+"&anagrafica_id="+idAnagrafica;
    url += "?contatto_id=" + idContatto;
    return this.http.get<any[]>(url)
  }

  saveAnagraficaStorica(anagraficaStorica: AnagraficaStorica, idRuolo: number) {


    let url = this.baseUrl + this.saveAnagraficaStoricaUrl + "?idRuolo=" + idRuolo;

    return this.http.post<AnagraficaStorica>(url, JSON.parse(JSON.stringify(anagraficaStorica)))
      .pipe(catchError(error => this.handleError(error)));


  }


}
