import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {TheadColumn} from "./thead-column";
import {TableSorter} from "./table-sorter";


@Component({
  selector: '[app-thead]',
  templateUrl: './thead.component.html',
  styleUrls: ['./thead.component.css']
})
export class TheadComponent implements OnInit {

  @Input()
  theadColumns:TheadColumn[] = [];
  @Input()
  tableSorter: TableSorter = new TableSorter();

  @Output()
  changeOrder = new EventEmitter<string>();

  constructor() {
  }

  ngOnInit(): void {
    for (let i=0; i<this.theadColumns.length; i++){
      this.tableSorter.add(this.theadColumns[i].columnSortingName);
    }
  }


  changeOrderEmit() {
    this.changeOrder.emit('changeOrder');
  }

  clickEvent(col: TheadColumn) {
      this.tableSorter.toggle(col.columnSortingName);
      this.changeOrderEmit();
  }
}
