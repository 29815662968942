import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { Router } from '@angular/router';
import { TableSorter } from 'src/app/components/table/thead/table-sorter';
import { TheadColumn } from 'src/app/components/table/thead/thead-column';
import { Funzionalita } from 'src/app/model/Funzionalita';
import {Asl, ConfStato, Contatto, Intervento, Page, RispostaPrevalente, Utente} from 'src/app/model/Models';
import { ApiService } from 'src/app/services/api.service';
import { SessionService } from 'src/app/services/session.service';
import {CommonsUtil} from "../../util/CommonsUtil";

@Component({
  selector: 'app-interventi-tab',
  templateUrl: './interventi-tab.component.html',
  styleUrls: ['./interventi-tab.component.css']
})
export class InterventiTabComponent implements OnInit {



  pageInterventi: Page<Intervento> = new Page<Intervento>();

  contatto!:Contatto

  tableSorter: TableSorter = new TableSorter();

  theadColumns!: TheadColumn[];

  listRispotePrevalenti:RispostaPrevalente[]= []

  @Output() hasError = new EventEmitter<boolean>();

  confStati: ConfStato[] = [];
  private aslList: Asl[] = [];

  constructor(private apiService:ApiService,private sessionService:SessionService,private router:Router) { }



  ngOnInit(): void {

    this.loadContatto()

    this.loadRispostePrevalentiList()
    this.theadColumns = this.loadTableHead()

    this.loadInterventi(this.contatto.id!!, 0, this.pageInterventi.size, null, null)

    this.apiService.getAllConfStato().subscribe(data => {
      if(data){
        this.confStati = data;
      }
    })

    this.apiService.getAllAsl().subscribe(data => {
      for (let item of data) {
        if (item.codiceAzienda != "98" && item.codiceAzienda != "098" && item.codiceAzienda != "998" && item.codiceAzienda != "999") {
          this.aslList.push(item);
        }
      }
    });
  }



  loadContatto(){
    this.sessionService.selectedContatto.subscribe(res => {

      this.contatto = res

    })
  }

  private loadTableHead(): TheadColumn[] {
    let res: TheadColumn[] = [];
    res.push(new TheadColumn('', 'Alert'));
    res.push(new TheadColumn('id', 'IDIntervento'));

    // res.push(new TheadColumn('struttura', 'Codice - Denom. Struttura - ASL'));
    res.push(new TheadColumn('codiceStrutturaOperatoreInserimento', 'Codice - Denom. Struttura - ASL'));

    res.push(new TheadColumn('contatto.dataApertura', 'Data apertura contatto'));
    res.push(new TheadColumn('data', 'Data Intervento'));
    res.push(new TheadColumn('rispostaPrevalente', 'Risposta prevalente'));
    // res.push(new TheadColumn('statoTrasmissione', 'Stato trasmissione'));
    res.push(new TheadColumn('statoAttuale', 'Stato trasmissione'));


    return res;
  }

  loadInterventi(id: number, pageNr: number, pageSize: number, order: string | null, direction: string | null) {


    if (id != null) {

      this.apiService.getPaginaInterventi(id, pageNr, pageSize, order, direction).subscribe(res => {

        this.pageInterventi = res

        if(this.pageInterventi.content.some(value => this.alert(value.statoAttuale))){
          this.hasError.emit(true);
        } else {
          this.hasError.emit(false);
        }

      })

    }



  }

  descrizioneStato(stato: number) {

    for(let confStato of this.confStati){

      if (stato == 5 && !this.getRuolo(this.OPERATORE_REGIONE))
        stato = 3;

      if(stato == confStato.id){

        return confStato.descrizione;

      }

    }
    return "Stato non trovato";
    // return this.apiService.getConfStatoById(stato).subscribe(data =>{
    //    return data.descrizione
    //  })
     // return new CommonsUtil().descrizioneStato(stato);
   }

  aggiungiInterventoVisibile() {
    let utente = this.sessionService.getUtenteFromStorage();
    let elegible = utente?.funzionalita.includes(Funzionalita.ANAGRAFICHE_INSERIMENTO) && this.contatto.aperturaFlag !== "2"
    return elegible
  }

  openInterventoForm() {
    this.sessionService.setSelectedIntervento(JSON.stringify(new Intervento()));
    this.router.navigate(['intervento-form'])
  }

  cambiaPagina() {
    this.loadInterventi(this.contatto.id!!, this.pageInterventi.number, this.pageInterventi.size, this.tableSorter.orderByName(), this.tableSorter.orderByDirection())
  }

  changeOrder() {
    this.loadInterventi(this.contatto.id!!, this.pageInterventi.number, this.pageInterventi.size, this.tableSorter.orderByName(), this.tableSorter.orderByDirection())

  }

  interventoClick(intervento:Intervento){

    this.sessionService.setSelectedIntervento(JSON.stringify(intervento))

    this.router.navigate(['intervento-form'])
  }

  loadRispostePrevalentiList(){

    this.apiService.getAllRispostePrevalenti().subscribe(res => {

      this.listRispotePrevalenti = res
    })
  }

  getRispostaPrevalenteDescrizione(codice:string):string{

    let desc = ""

    this.listRispotePrevalenti.forEach(rp => {
      if (rp.codice == codice){

        desc = rp.descrizione!!
      }
    })

    return desc
  }

  contattoIsClosed():boolean{
    return this.contatto.dataChiusura != null || this.contatto.dataChiusura != undefined &&
    this.contatto.codiceDiagnosiChiusura != null || this.contatto.codiceDiagnosiChiusura != undefined
  }


  alert(statoAttuale: ConfStato) {
    return  new CommonsUtil().alert(statoAttuale);
  }

  isValidato(){
    if(this.contatto.statoAttuale.id == 3){
      return true
    } else {
      return false;
    }
  }

  public OPERATORE_STRUTTURA: String = "operatore-struttura";
  public OPERATORE_ASL: String = "operatore-asl";
  public OPERATORE_DSM: String = "operatore-dsm";
  public OPERATORE_REGIONE: String = "operatore-regione";
  public utente: Utente = new Utente();
  public getRuolo(ruolo: String) {
  this.getUtente( );
    if (this.utente) {

      if (this.utente.nomeRuolo == ruolo) {

        return true;

      }

    }

    return false;

  }
  public getUtente() {

    var usr = sessionStorage.getItem("utente");

    if (usr != null && usr != undefined) {

      this.utente = JSON.parse(usr);

    }

  }

  getDescrizioneASL(codiceAslOperatoreInserimento: string | undefined) {
    return this.aslList.find(value => value.codiceAzienda==codiceAslOperatoreInserimento)?.denominazioneAzienda;
  }
}
