import { Component, OnInit } from '@angular/core';
import {DettaglioLotto, DettaglioLottoRipristinaBody, DettaglioLottoUscita, Page} from "../../model/Models";
import {ApiService} from "../../services/api.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ToastService} from "../../services/toast.service";
import {SessionService} from "../../services/session.service";
import {Router} from "@angular/router";
import {PresaInCaricoDettaglioModalComponent} from "../presa-in-carico-dettaglio-modal/presa-in-carico-dettaglio-modal.component";

@Component({
  selector: 'app-dettaglio-lotto-uscita',
  templateUrl: './dettaglio-lotto-uscita.component.html',
  styleUrls: ['./dettaglio-lotto-uscita.component.css']
})
export class DettaglioLottoUscitaComponent implements OnInit {

  flusso: string = "";
  struttura: string = "";
  data: string = "";
  attivita: string = "";
  semestre: string = "";
  id: number = 0;
  annoCompetenza: number = 0;
  pagina: Page<DettaglioLottoUscita> = new Page<DettaglioLottoUscita>();
  selectedDettaglio = new Set<number>()
  motivazioneScarto:string = '';
  stato:string = '';

  constructor(private apiService: ApiService,
              private modalService: NgbModal,
              private toastService: ToastService,
              private sessionService: SessionService,private router: Router) { }

  ngOnInit(): void {
    this.loadDataFromSelectedLotto();
    this.getDettaglioLotto();
  }

  loadDataFromSelectedLotto() {
    this.sessionService.selectedLotto.subscribe(res => {
      var data = JSON.parse(res);
      this.flusso = "SISM";
      this.struttura = data.struttura;
      this.data = data.dataLotto;
      this.attivita = data.tipoPrestazione;
      this.semestre = data.semestreCompetenza;
      this.id = data.id;
      this.annoCompetenza = data.annoCompetenza;
      this.motivazioneScarto = data.motivazioneScarto;
      this.stato = data.stato;
    });

  }

  async getDettaglioLotto() {

    this.apiService.getDettaglioLottoAll(this.id, this.semestre, this.annoCompetenza, this.pagina.number,
      this.pagina.size).subscribe((data: any) => {

      this.pagina = data;

    });

  }

  cambiaPagina(event: any) {
    this.getDettaglioLotto();
  }


  async ripristina() {
    let body = new DettaglioLottoRipristinaBody();
    body.listaIdDettagliLotto = Array.from(this.selectedDettaglio);
    this.apiService.dettaglioLottoRipristina(body)
      .subscribe(res => {
          this.getDettaglioLotto();
          this.toastService.showSuccess('Salvataggio effettuato con successo');
        })
  }

  navigateBack() {
    this.router.navigate(['flussi']);
  }

  listaItems() {
    if (this.pagina) {
      return this.pagina.content;
    } else
      return [];
  }


  ripristinaDisabled() {
    return this.stato != 'ELABORATO';
  }

  openDettagloPresaInCarico( idDettaglioLotto: number) {
    this.apiService.getDettaglioPreseIncaricoByIdDettaglioLotto(idDettaglioLotto)
      .subscribe(res => {
        const modalRef = this.modalService.open(PresaInCaricoDettaglioModalComponent,
          {scrollable: true, ariaLabelledBy: 'modal-basic-title'});
        modalRef.componentInstance.instanceModalRef = modalRef;
        modalRef.componentInstance. dettaglioAnagrafica = res.anagrafica;
        modalRef.componentInstance.listContati = res.contatti
        modalRef.componentInstance.listPrestazioni = res.prestazioni
        modalRef.result.then((result) => {
          console.log(`Closed with: ${result}`);
        }, (reason) => {
        });
      });
  }

  checkboxRipristinaHidden(item: DettaglioLottoUscita) {
      return  item.stato == null || item.stato == ''
        || item.stato == 'VALIDATO'
        || item.stato == 'SCARTATO_E_CORRETTO';
  }

  isChecked(item: DettaglioLottoUscita) {
    return this.selectedDettaglio.has(item.id);
  }

  checkboxClick(item: DettaglioLottoUscita) {
      if (this.selectedDettaglio.has(item.id)){
        this.selectedDettaglio.delete(item.id)
      } else {
        this.selectedDettaglio.add(item.id)
      }
  }
}
