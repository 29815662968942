import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {
  AnagraficaStorica,
  AnagraficaStruttura,
  Asl, Ativitta,
  Cittadinanza, CollazioneSocioAmbientale,
  Comune,
  DettaglioAnagrafica,
  Distretto, Invalidita,
  Municipio, Provedimento,
  Regione, StatoCivile,
  StatoEstero, TitoloStudio, TutelaGiuridica, Utente
} from "../../model/Models";
import {firstValueFrom, Observable, OperatorFunction, Subject} from "rxjs";
import {ApiService} from "../../services/api.service";
import {SessionService} from "../../services/session.service";
import {debounceTime, distinctUntilChanged, map, takeUntil} from "rxjs/operators";
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {
  codiceFiscaleValidator,
  dataNonValidaValidator,
  emptyComuneNascitaValidator
} from "../../util/codicefiscale.validators";
import {NgbTypeahead} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-storico-anagrafica-edit',
  templateUrl: './storico-anagrafica-edit.component.html',
  styleUrls: ['./storico-anagrafica-edit.component.css']
})
export class StoricoAnagraficaEditComponent implements OnInit {

  @Input() idContatto: number | undefined;
  @ViewChild('instance', {static: true}) instance: NgbTypeahead | undefined;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  dettaglioAnagrafica: DettaglioAnagrafica | undefined;
  private destroy: Subject<any> = new Subject();
  listCitadiNanza: Cittadinanza[] = [];
  listRegione: Regione[] = [];
  listComuneResidenza: Comune[] = [];
  listAsl: Asl[] = [];
  listMunicipi: Municipio[] = [];
  listAtivitta: Ativitta[] = [];
  listInvalidita: Invalidita[] = [];
  listTuteleGiuridice: TutelaGiuridica[] = [];
  listComune: Comune[] = [];
  listDistretti: Distretto[] = [];
  listStatiesteri: StatoEstero[] = [];
  listStatiCivili: StatoCivile[] = [];
  listProvedimenti: Provedimento[] = [];
  listDescrizioniComune: Comune[] = [];
  listCollazioneSocioAmbientali: CollazioneSocioAmbientale[] = [];
  listTitoliStudio: TitoloStudio[] = [];


  public modelDescrizioneComuneResidenza?: Comune;
  dettaglioAnagraficaForm!: FormGroup;
  comuneResidenzaSearch: OperatorFunction<string, readonly Comune[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term: string) => term.length < 1 ? []
        : this.listComuneResidenza.filter(v => v && v.descrizione && v.descrizione.toLowerCase().indexOf(term.toLowerCase()) > -1)
          .sort((a, b) => this.comuneCompare(a.descrizione, b.descrizione, term))
          .slice(0, 10)
      )
    )
  inFormatter = (comune: any) => comune && comune.descrizione ? comune.descrizione : this.listComune.find(value => value.codice == comune)?.descrizione;

  resFormatter = (comune: Comune) => comune.descrizione;

  constructor(private apiService: ApiService, private sessionService: SessionService, private fb: FormBuilder) {

  }

  comuneSortingValue(a: string, term: string) {
    return 10000
      * (1 / (a.toLowerCase().indexOf(term.toLowerCase()) + 1))
      * (1 / a.length);
  }

  comuneCompare(a: string, b: string, term: string) {
    return this.comuneSortingValue(b, term) - this.comuneSortingValue(a, term);

  }

  loaded = false;

  async ngOnInit(): Promise<void> {
    await this.getAllVal().then(value => null);
    await this.loadAnagrafica().then(async r => {
      this.initForm();
      if (this.dettaglioAnagrafica?.cfCertificato === '2') {
        await this.makeDefaultFormCalls().then(value => null);
        this.dettaglioAnagraficaForm.get('cittadinanza')?.patchValue(this.dettaglioAnagrafica.cittadinanzaCod);
        this.dettaglioAnagraficaForm.get('regioneResidenza')?.patchValue(this.dettaglioAnagrafica.regioneResidenzaCod);
        this.dettaglioAnagraficaForm.get('aslResidenza')?.patchValue(this.dettaglioAnagrafica.aslResidenzaCod);
        this.dettaglioAnagraficaForm.get('distrettoResidenza')?.patchValue(this.dettaglioAnagrafica.distrettoResidenzaCod);
        this.dettaglioAnagraficaForm.get('municipioResidenza')?.patchValue(this.dettaglioAnagrafica.municipioResidenzaCod);
        this.dettaglioAnagraficaForm.get('statoResidenza')?.patchValue(this.dettaglioAnagrafica.statoResidenzaCod);
        this.dettaglioAnagraficaForm.get('statoCivile')?.patchValue(this.dettaglioAnagrafica.statoCivileCod);
        this.dettaglioAnagraficaForm.get('collocazioneSocioAmbientale')?.patchValue(this.dettaglioAnagrafica.collocazioneSocioAmbientaleCod);
        this.dettaglioAnagraficaForm.get('titoloDiStudio')?.patchValue(this.dettaglioAnagrafica.titoloStudioCod);
        this.dettaglioAnagraficaForm.get('attivita')?.patchValue(this.dettaglioAnagrafica.attivitaCod);
        this.dettaglioAnagraficaForm.get('familiarePaziente')?.patchValue(this.dettaglioAnagrafica.familiarePaziente);
        this.dettaglioAnagraficaForm.get('invalidita')?.patchValue(this.dettaglioAnagrafica.invaliditaCod);
        this.dettaglioAnagraficaForm.get('tutelaGiuridica')?.patchValue(this.dettaglioAnagrafica.tutelaGiuridicaCod);
        this.dettaglioAnagraficaForm.get('attualmenteSottoposto')?.patchValue(this.dettaglioAnagrafica.attualmenteSottopostoCod);
        this.dettaglioAnagraficaForm.get('precedentementeInternato')?.patchValue(this.dettaglioAnagrafica.precedentementeInternato);
        this.modelDescrizioneComuneResidenza = new Comune();
        let comuneRes = this.listComuneResidenza.find(x => x.codice == this.dettaglioAnagrafica!!.comuneResidenzaCod)
        if (comuneRes) {
          this.modelDescrizioneComuneResidenza!.descrizione = comuneRes!.descrizione
          this.modelDescrizioneComuneResidenza!.codice = comuneRes!.codice
        }
      }
    });
    this.loaded = true;
  }

  async initForm() {
    this.dettaglioAnagraficaForm = this.fb.group({

        cittadinanza: new FormControl(this.dettaglioAnagrafica?.cittadinanzaCod),
        statoResidenza: new FormControl(this.dettaglioAnagrafica?.statoResidenzaCod),
        regioneResidenza: new FormControl(this.dettaglioAnagrafica?.regioneResidenzaCod),
        comuneResidenza: new FormControl(this.dettaglioAnagrafica?.comuneResidenzaCod),
        aslResidenza: new FormControl(this.dettaglioAnagrafica?.aslResidenzaCod),
        distrettoResidenza: new FormControl(this.dettaglioAnagrafica?.distrettoResidenzaCod),
        municipioResidenza: new FormControl(this.dettaglioAnagrafica?.municipioResidenzaCod),
        statoCivile: new FormControl(this.dettaglioAnagrafica?.statoCivileCod),
        collocazioneSocioAmbientale: new FormControl(this.dettaglioAnagrafica?.collocazioneSocioAmbientaleCod),
        titoloDiStudio: new FormControl(this.dettaglioAnagrafica?.titoloStudioCod),
        attivita: new FormControl(this.dettaglioAnagrafica?.attivitaCod),
        invalidita: new FormControl(this.dettaglioAnagrafica?.invaliditaCod),
        familiarePaziente: new FormControl(this.dettaglioAnagrafica?.familiarePaziente),
        tutelaGiuridica: new FormControl(this.dettaglioAnagrafica?.tutelaGiuridicaCod),
        attualmenteSottoposto: new FormControl(this.dettaglioAnagrafica?.attualmenteSottopostoCod),
        precedentementeInternato: new FormControl(this.dettaglioAnagrafica?.precedentementeInternato)
      }
      // , {validator: codiceFiscaleValidator}
    );
  }

  async makeDefaultFormCalls() {

    await this.getAslbyComune(this.dettaglioAnagrafica?.comuneResidenzaCod!);
    await this.getAllComuniByRegione(this.dettaglioAnagrafica?.regioneResidenzaCod!);
    await this.getDistrettobyAsl(this.dettaglioAnagrafica?.aslResidenzaCod!);
    await this.getMunicipiByDistrettoResidenza(this.dettaglioAnagrafica?.distrettoResidenzaCod!);

  }

  async getAllVal() {

    await this.getAllComuni()
    await this.getAllStatiCivili();
    await this.getAllCitadinanza();
    await this.getAllRegioni();
    await this.getAllStatiEsteri();
    await this.getAllCollazioneSocioAmbientali();
    await this.getAllTitoliStudio();
    await this.getAllAtivitta();
    await this.getAllInvalidita();
    await this.getAllTuteleGiuridiche()
    await this.getAllProvedimenti();

  }

  async loadAnagrafica(): Promise<void> {
    const res = await firstValueFrom(this.sessionService.selectedAnagrafica.pipe(takeUntil(this.destroy)));
    this.dettaglioAnagrafica = this.idContatto ? await firstValueFrom(this.apiService.getAnagraficaStorica(this.idContatto)) : JSON.parse(res);
  }

  async getAllAtivitta() {

    this.apiService.getAllAtivitta().subscribe(res => {
      this.listAtivitta = res
    })

  }

  async getAllProvedimenti() {

    this.apiService.getAllProvedimenti().subscribe(res => {
      this.listProvedimenti = res
    })

  }

  async getAllInvalidita() {

    this.apiService.getAllInvalidita().subscribe(res => {
      this.listInvalidita = res
    })

  }

  /**
   * Recupera tutte le cittadinanze
   */
  async getAllCitadinanza() {

    this.apiService.getAllCitadinanza().subscribe(res => {
      this.listCitadiNanza = res
      // set italia first element
      let italiaIndex = this.listCitadiNanza.findIndex(c => c.alpha2 === "IT");
      this.listCitadiNanza = this.moveObjectIndex(this.listCitadiNanza, italiaIndex, 0)
      // set apolide last element
      let apolideIndex = this.listCitadiNanza.findIndex(c => c.alpha2 === "ZZ");
      this.listCitadiNanza = this.moveObjectIndex(this.listCitadiNanza, apolideIndex, this.listCitadiNanza.length)
    })

  }

  async getAllTuteleGiuridiche() {

    this.apiService.getAllTuteleGiuridice().subscribe(res => {
      this.listTuteleGiuridice = res
    })

  }

  async getAllCollazioneSocioAmbientali() {

    this.apiService.getAllCollazioeSocioAmbientali().subscribe(res => {
      this.listCollazioneSocioAmbientali = res
    })

  }

  async getAllStatiEsteri() {

    this.apiService.getAllStatiEsteri().subscribe(res => {
      this.listStatiesteri = res

      // set italia first element
      let italiaIndex = this.listStatiesteri.findIndex(c => c.alpha2 === "IT");
      //   this.listStatiesteri = this.moveObjectIndex(this.listStatiesteri, italiaIndex, 0)
      this.listStatiesteri.splice(italiaIndex, 1);

      // set apolide last element
      let apolideIndex = this.listStatiesteri.findIndex(c => c.alpha2 === "ZZ");
      this.listStatiesteri = this.moveObjectIndex(this.listStatiesteri, apolideIndex, this.listStatiesteri.length)
    })
  }

  async getAllRegioni() {
    this.apiService.getAllRegioni().subscribe(res => {
      this.listRegione = res
    })
  }

  async getAllStatiCivili() {
    this.apiService.getAllStatiCivili().subscribe(res => {
      this.listStatiCivili = res
    })

  }

  async getAllComuni() {

    this.listComune = await firstValueFrom(this.apiService.getAllComuni());
    //.subscribe(res => {
    //  this.listComune = res
    this.listDescrizioniComune = [];
    this.listComune.forEach(elem => {
      this.listDescrizioniComune.push(elem)
    });

    // })
  }

  async getAllTitoliStudio() {

    this.apiService.getAllTitoliStudio().subscribe(res => {
      this.listTitoliStudio = res
    })

  }

  moveObjectIndex(array: any, sourceIndex: number, destIndex: number) {
    var placeholder = {};

    var objectToMove = array.splice(sourceIndex, 1, placeholder)[0];
    // place the object in the desired position
    array.splice(destIndex, 0, objectToMove);
    // take out the temporary object
    array.splice(array.indexOf(placeholder), 1);

    return array
  }

  async getAllComuniByRegione(codice: string) {
    if (codice != '') {
      this.listComuneResidenza = await firstValueFrom(this.apiService.getAllComuniByRegione(codice));
    }

  }

  async getAslbyComune(codiceComune: string) {

    if (codiceComune != '') {
      this.apiService.getAslByComune(codiceComune).subscribe(res => {
        this.listAsl = res
      })
    } else {
      this.listAsl = [];
    }
  }

  showStatoResidenza: boolean = false;
  showComuneResidenza: boolean = false;
  showAslResidenza: boolean = false;
  showDistrettoResidenza: boolean = false;
  showMunicipioResidenza: boolean = false;

  setRegioneCodice(event: any) {

    if (this.dettaglioAnagrafica == null) {
      this.dettaglioAnagrafica = new DettaglioAnagrafica();
    }
    this.dettaglioAnagrafica.regioneResidenzaCod = event.target.value;
    this.getAllComuniByRegione(event.target.value);
    this.dettaglioAnagrafica.comuneResidenza = '';
    this.modelDescrizioneComuneResidenza = undefined;
    this.dettaglioAnagrafica.aslResidenza = '';
    this.dettaglioAnagrafica.aslResidenzaCod = '';
    this.dettaglioAnagrafica.distrettoResidenza = '';
    this.dettaglioAnagrafica.distrettoResidenzaCod = '';
    this.dettaglioAnagrafica.municipioResidenza = '';
    this.dettaglioAnagrafica.municipioResidenzaCod = '';
    this.setFieldResidenza();
  }


  setFieldResidenza() {

    if (this.dettaglioAnagrafica?.regioneResidenzaCod == '098' || this.dettaglioAnagrafica?.regioneResidenzaCod == '999') {

      this.removeFieldResidenza();
      this.dettaglioAnagrafica!.municipioResidenzaCod = '';
      this.dettaglioAnagrafica!.municipioResidenza = '';
      this.dettaglioAnagrafica!.distrettoResidenzaCod = '';
      this.dettaglioAnagrafica!.distrettoResidenza = '';
      this.dettaglioAnagrafica!.aslResidenzaCod = '';
      this.dettaglioAnagrafica!.aslResidenza = '';
      this.dettaglioAnagrafica!.comuneResidenzaCod = '';
      this.dettaglioAnagrafica!.comuneResidenza = '';
      this.dettaglioAnagrafica!.statoResidenzaCod = '';
      this.dettaglioAnagrafica!.statoResidenza = '';

    } else if (this.dettaglioAnagrafica?.regioneResidenzaCod == '998') {

      this.dettaglioAnagrafica!.municipioResidenzaCod = '';
      this.dettaglioAnagrafica!.municipioResidenza = '';
      this.dettaglioAnagrafica!.distrettoResidenzaCod = '';
      this.dettaglioAnagrafica!.distrettoResidenza = '';
      this.dettaglioAnagrafica!.aslResidenzaCod = '';
      this.dettaglioAnagrafica!.aslResidenza = '';
      this.dettaglioAnagrafica!.comuneResidenzaCod = '';
      this.dettaglioAnagrafica!.comuneResidenza = '';
      this.removeFieldResidenza();
      this.dettaglioAnagraficaForm.addControl("statoResidenza",
        new FormControl(""));
      this.showStatoResidenza = true;

    } else if (this.dettaglioAnagrafica?.regioneResidenzaCod == '120') {
      this.dettaglioAnagraficaForm.removeControl("statoResidenza");
      this.removeFieldResidenza();
      this.dettaglioAnagraficaForm.addControl("comuneResidenza",
        new FormControl(""));
      this.dettaglioAnagraficaForm.addControl("distrettoResidenza",
        new FormControl(""));
      this.dettaglioAnagraficaForm.addControl("aslResidenza",
        new FormControl(""));
      // this.dettaglioAnagraficaForm.addControl("municipioResidenza",
      //   new FormControl(""));
      this.dettaglioAnagrafica!.statoResidenzaCod = 'IT';
      this.dettaglioAnagrafica!.statoResidenza = 'IT';
      this.showComuneResidenza = true;
      this.showDistrettoResidenza = true;
      this.showAslResidenza = true;

      if (this.dettaglioAnagrafica.comuneResidenzaCod == '058091') {

        this.dettaglioAnagraficaForm.addControl("municipioResidenza",
          new FormControl(""));
        this.getMunicipiByDistrettoResidenza(this.dettaglioAnagrafica!.distrettoResidenzaCod);
        this.showMunicipioResidenza = true;
      }

    } else {
      this.dettaglioAnagraficaForm.removeControl("statoResidenza");
      this.removeFieldResidenza();
      this.dettaglioAnagrafica!.statoResidenzaCod = 'IT';
      this.dettaglioAnagrafica!.statoResidenza = 'IT';
      this.dettaglioAnagraficaForm.addControl("comuneResidenza",
        new FormControl(""));
      this.showComuneResidenza = true;

    }

  }

  removeFieldResidenza() {
    if (this.dettaglioAnagraficaForm.contains("statoResidenza")) {

      this.dettaglioAnagraficaForm.removeControl("statoResidenza");

    }
    if (this.dettaglioAnagraficaForm.contains("distrettoResidenza")) {

      this.dettaglioAnagraficaForm.removeControl("distrettoResidenza");

    }
    if (this.dettaglioAnagraficaForm.contains("aslResidenza")) {

      this.dettaglioAnagraficaForm.removeControl("aslResidenza");

    }
    if (this.dettaglioAnagraficaForm.contains("municipioResidenza")) {

      this.dettaglioAnagraficaForm.removeControl("municipioResidenza");

    }
    if (this.dettaglioAnagraficaForm.contains("comuneResidenza")) {

      this.dettaglioAnagraficaForm.removeControl("comuneResidenza");

    }

    this.showStatoResidenza = false;
    this.showComuneResidenza = false;
    this.showAslResidenza = false;
    this.showDistrettoResidenza = false;
    this.showMunicipioResidenza = false;
  }

  setComuneCodice(event: any) {

    this.dettaglioAnagrafica!!.comuneResidenza = event.item.codice!;
    this.getAslbyComune(this.dettaglioAnagrafica!.comuneResidenza!);
    this.setFieldResidenza();

    this.dettaglioAnagrafica!!.aslResidenza = '';
    this.dettaglioAnagrafica!!.municipioResidenza = '';
    this.dettaglioAnagrafica!!.distrettoResidenza = '';

  }


  setAslResidenza(event: any) {

    this.dettaglioAnagrafica!!.aslResidenza = event.target.value;
    this.getDistrettobyAsl(this.dettaglioAnagrafica!.aslResidenza!);
    this.dettaglioAnagrafica!!.municipioResidenza = '';
    this.dettaglioAnagrafica!!.distrettoResidenza = '';
  }

  async setDistrettoResidenza(event: any) {

    if (this.dettaglioAnagrafica!!.comuneResidenza == '058091') {

      this.dettaglioAnagraficaForm.addControl("municipioResidenza",
        new FormControl(""));
      this.showMunicipioResidenza = true;

    } else {
      this.dettaglioAnagraficaForm.removeControl("municipioResidenza");
      this.showMunicipioResidenza = false;
    }
    this.dettaglioAnagrafica!!.distrettoResidenza = event.target.value;
    this.dettaglioAnagrafica!!.municipioResidenza = '';


    await this.getMunicipiByDistrettoResidenza(event.target.value)

  }

  setState(event: any) {
    this.dettaglioAnagrafica!!.statoResidenza = event.target.value;
    this.dettaglioAnagrafica!!.statoResidenzaCod = event.target.value;
    // this.setFieldResidenza()

    this.dettaglioAnagraficaForm.addControl("statoResidenza",
      new FormControl(""));
  }

  async getMunicipiByDistrettoResidenza(distrettoResidenza: string) {
    if (distrettoResidenza != '') {
      this.listMunicipi = await firstValueFrom(this.apiService.getMunicipiByDistrettoResidenza(distrettoResidenza));
    } else {
      this.listMunicipi = [];
      this.dettaglioAnagraficaForm.removeControl("municipioResidenza")
      this.showMunicipioResidenza = false;
    }
  }

  async getDistrettobyAsl(codiceAsl: string) {

    if (codiceAsl != '') {
      this.apiService.getDistrettoByAsl(codiceAsl).subscribe(res => {
        this.listDistretti = res
      })
    } else {
      this.listDistretti = [];
    }
  }


  /**
   * Recupera dati dal form
   */
  getFormData() {

    let anagrafica = new AnagraficaStorica()

    if (this.dettaglioAnagrafica?.cfCertificato == '2') {

      if(this.dettaglioAnagraficaForm.contains('comuneResidenza')){

        if(this.dettaglioAnagraficaForm.controls['comuneResidenza'].value.codice ){

          anagrafica.comuneResidenza =   this.dettaglioAnagraficaForm.controls['comuneResidenza'].value.codice ;

        } else {

          anagrafica.comuneResidenza =  this.dettaglioAnagraficaForm.controls['comuneResidenza'].value

        }

      }

      anagrafica.regioneResidenza = this.dettaglioAnagraficaForm.contains('regioneResidenza') ? this.dettaglioAnagraficaForm.controls['regioneResidenza'].value : null;
      anagrafica.municipioResidenza = this.dettaglioAnagraficaForm.contains('municipioResidenza') ? this.dettaglioAnagraficaForm.controls['municipioResidenza'].value : null;
      anagrafica.distrettoResidenza = this.dettaglioAnagraficaForm.contains('distrettoResidenza') ? this.dettaglioAnagraficaForm.controls['distrettoResidenza'].value : null;
      anagrafica.aslResidenza = this.dettaglioAnagraficaForm.contains('aslResidenza') ? this.dettaglioAnagraficaForm.controls['aslResidenza'].value : null;
      anagrafica.statoResidenza = this.dettaglioAnagraficaForm.contains('statoResidenza') ? this.dettaglioAnagraficaForm.controls['statoResidenza'].value : null;

    }

    anagrafica.statoCivile = this.dettaglioAnagraficaForm.controls['statoCivile'].value
    anagrafica.cittadinanza = this.dettaglioAnagraficaForm.controls['cittadinanza'].value
    anagrafica.titoloDiStudio = this.dettaglioAnagraficaForm.controls['titoloDiStudio'].value
    anagrafica.collocazioneSocioAmbientale = this.dettaglioAnagraficaForm.controls['collocazioneSocioAmbientale'].value
    anagrafica.attivita = this.dettaglioAnagraficaForm.controls['attivita'].value
    anagrafica.familiarePaziente = this.dettaglioAnagraficaForm.controls['familiarePaziente'].value
    anagrafica.invalidita = this.dettaglioAnagraficaForm.controls['invalidita'].value
    anagrafica.tutelaGiuridica = this.dettaglioAnagraficaForm.controls['tutelaGiuridica'].value
    anagrafica.attualmenteSottoposto = this.dettaglioAnagraficaForm.controls['attualmenteSottoposto'].value
    anagrafica.precedentementeInternato = this.dettaglioAnagraficaForm.controls['precedentementeInternato'].value
    anagrafica.id = this.dettaglioAnagrafica?.id!;
    anagrafica.contattoId = this.idContatto;
    return anagrafica;

  }

  mainUser!: Utente

  loadUser() {

    this.mainUser = this.sessionService.getUtenteFromStorage()!!;

  }

  //TODO: Implementare il save
  // async save() {
  //   let anagrafica = this.getFormData();
  //   this.loadUser();
  //   await firstValueFrom(this.apiService.saveAnagraficaStorica(anagrafica, this.mainUser.idRuolo!));
  // }

  public async salva(): Promise<boolean> {
    let anagrafica = this.getFormData();
    let ok = true;
    if (!anagrafica.statoCivile) {
      this.dettaglioAnagraficaForm.controls['statoCivile'].setErrors({'incorrect': true});
      ok = false;
    }
    if (!anagrafica.cittadinanza) {
      this.dettaglioAnagraficaForm.controls['cittadinanza'].setErrors({'incorrect': true});
      ok = false;
    }
    if (!anagrafica.titoloDiStudio) {
      this.dettaglioAnagraficaForm.controls['titoloDiStudio'].setErrors({'incorrect': true});
      ok = false;
    }
    if (!anagrafica.collocazioneSocioAmbientale) {
      this.dettaglioAnagraficaForm.controls['collocazioneSocioAmbientale'].setErrors({'incorrect': true});
      ok = false;
    }
    if (!anagrafica.attivita) {
      this.dettaglioAnagraficaForm.controls['attivita'].setErrors({'incorrect': true});
      ok = false;
    }
    if (!anagrafica.familiarePaziente) {
      this.dettaglioAnagraficaForm.controls['familiarePaziente'].setErrors({'incorrect': true});
      ok = false;
    }
    if (!anagrafica.invalidita) {
      this.dettaglioAnagraficaForm.controls['invalidita'].setErrors({'incorrect': true});
      ok = false;
    }
    if (!anagrafica.tutelaGiuridica) {
      this.dettaglioAnagraficaForm.controls['tutelaGiuridica'].setErrors({'incorrect': true});
      ok = false;
    }
    if (!anagrafica.attualmenteSottoposto) {
      this.dettaglioAnagraficaForm.controls['attualmenteSottoposto'].setErrors({'incorrect': true});
      ok = false;
    }
    if (!anagrafica.precedentementeInternato) {
      this.dettaglioAnagraficaForm.controls['precedentementeInternato'].setErrors({'incorrect': true});
      ok = false;
    }
    if (this.dettaglioAnagrafica?.cfCertificato == '2' && !anagrafica.regioneResidenza) {
      this.dettaglioAnagraficaForm.controls['regioneResidenza'].setErrors({'incorrect': true});
      ok = false;
    }
    if (this.dettaglioAnagrafica?.cfCertificato == '2' && (anagrafica.regioneResidenza != '999' && anagrafica.regioneResidenza != '098') && anagrafica.regioneResidenza == '998' && !anagrafica.statoResidenza) {
      this.dettaglioAnagraficaForm.controls['statoResidenza'].setErrors({'incorrect': true});
      ok = false;
    }
    if (this.dettaglioAnagrafica?.cfCertificato == '2' && (anagrafica.regioneResidenza != '999' && anagrafica.regioneResidenza != '098') && anagrafica.regioneResidenza != '998' && anagrafica.regioneResidenza != '120' && !anagrafica.comuneResidenza) {
      this.dettaglioAnagraficaForm.controls['comuneResidenza'].setErrors({'incorrect': true});
      ok = false;
    }
    if (this.dettaglioAnagrafica?.cfCertificato == '2' && anagrafica.regioneResidenza == '120' && !anagrafica.comuneResidenza) {
      this.dettaglioAnagraficaForm.controls['comuneResidenza'].setErrors({'incorrect': true});
      ok = false;
    }
    if (this.dettaglioAnagrafica?.cfCertificato == '2' && anagrafica.regioneResidenza == '120' && !anagrafica.aslResidenza) {
      this.dettaglioAnagraficaForm.controls['aslResidenza'].setErrors({'incorrect': true});
      ok = false;
    }
    if (this.dettaglioAnagrafica?.cfCertificato == '2' && anagrafica.regioneResidenza == '120' && !anagrafica.distrettoResidenza) {
      this.dettaglioAnagraficaForm.controls['distrettoResidenza'].setErrors({'incorrect': true});
      ok = false;
    }
    if (this.dettaglioAnagrafica?.cfCertificato == '2' && anagrafica.regioneResidenza == '120' && this.showMunicipioResidenza && !anagrafica.municipioResidenza && this.listMunicipi.length>0 ) {
      this.dettaglioAnagraficaForm.controls['municipioResidenza'].setErrors({'incorrect': true});
      ok = false;
    }
    if(ok) {
      this.loadUser();
      await firstValueFrom(this.apiService.saveAnagraficaStorica(anagrafica, this.mainUser.idRuolo!));
    }
    return ok;
  }

}
