<iframe width="1" height="1" [src]="safeLogout"></iframe>
<header class="navbar navbar-expand-lg fixed-top bg-white">
  <div class="navbar-main navbar-dark bg-primary">
    <div class="nav-main dd-hover">

      <a href="javascript:;" class="navbar-brand" title="Flussi Lazio">
        <img src="assets/logo.png"/>
      </a>
      <div class="nav-splitter">
        <ul class="nav navbar-nav left">
          <li class="nav-item" [routerLinkActive]="['active']">
            <a routerLink="/dashboard" class="nav-item nav-link" [routerLinkActive]="['active']"
               *ngIf="dashboardVisibile()">Home</a>
          </li>
          <li class="nav-item active">
            <a routerLink="/ricerca-anagrafica" class="nav-item nav-link" [routerLinkActive]="['active']">Gestione
              Pazienti</a>
          </li>
          <li class="nav-item active" *ngIf="isOperatoreRegione() || isOperatoreAmministratore()">
            <a routerLink="/invio-dati-a-regione" class="nav-item nav-link" [routerLinkActive]="['active']">Invio Dati
              in Piattaforma</a>
          </li>
          <li *ngIf="isOperatoreAsl()" class="nav-item">
            <a routerLink="/dati-in-ingresso" class="nav nav-link">
              Validazione Dati
            </a>
          </li>
          <li *ngIf="isOperatoreStruttura()" class="nav-item">
            <a routerLink="/importazioneDati" class="nav-item nav-link" [routerLinkActive]="['active']">Importazione
              dati</a>
          </li>
        </ul>
        <ul class="nav navbar-nav right">
          <li class="dropdown nav-item item-notify">
            <a href="javascript:" class="nav-link">
              <svg class="s-icon icon-sm">
                <use xlink:href="./assets/icons/sprite.svg"></use>
              </svg>
              <span class="badge badge-danger"></span>
            </a>
          </li>
          <li class="dropdown nav-item item-profile" *ngIf="isOperatoreStruttura()">
            <a href="javascript:" class="nav-link" data-toggle="dropdown" role="button" aria-expanded="false">
              <span [ngbTooltip]="getFullStrutturaName() + ' ' +getDescrTipoStruttura()"
                    placement="left">{{getStrutturaName()}} {{getDescrTipoStruttura()}}</span>
            </a>
            <ul class="nav navbar-nav" *ngIf="listStrutture.length > 1">
              <li class="nav-item">
                <a href="javascript:" class="nav-link text-secondary" style="font-weight: bold"
                   (click)="cambiaStruttura()">
                  CAMBIA STRUTTURA
                </a>
              </li>
            </ul>
          </li>
          <li class="dropdown nav-item item-profile">
            <a href="javascript:" class="nav-link" data-toggle="dropdown" role="button" aria-expanded="false">
              <span class="s-icon icon-sm" style="font-size: 80%">{{getUtenteCharacters(utente!!)}}</span>
            </a>
            <ul class="nav navbar-nav">
              <li class="disabled nav-item item-profile">
                <span class="nav-link"><span>UTENTE: </span> <span
                  style="font-weight: bolder; padding-left: 5px">{{utente?.userName}}</span></span>
              </li>
              <li class="disabled nav-item item-profile">
                <span class="nav-link"><span>RUOLO: </span> <span
                  style="font-weight: bolder; padding-left: 5px">{{utente?.nomeRuolo}}</span></span>
              </li>

              <li class="nav-item item-profile">
                <a routerLink="/documentazione">
                  <span class="nav-link">SUPPORTO: <svg class="s-icon icon-sm">
                  <use xlink:href="./assets/icons/sprite.svg#help"></use>
                </svg></span>
                </a>
              </li>
              <li class="disabled nav-item item-label" style="text-align: center">
                <span class="text-secondary"><span>SW.V.: </span> <span
                  style="font-weight: bolder; padding-left: 5px">{{version}}</span></span>
              </li>
              <li class="nav-item">
                <a *ngIf="utente && utente.ruoli && utente!.ruoli!.length>1" href="javascript:"
                   class="nav-link text-secondary" style="font-weight: bold" (click)="cambiaRuolo()">
                  CAMBIA RUOLO
                </a>
              </li>
              <li class="nav-item">
                <div class="nav-link bg-secondary justify-content-end"
                     style="cursor: pointer; color: white !important;padding-right: 20px" (click)="logOut()">
                  ESCI
                </div>
              </li>


            </ul>
          </li>
        </ul>
      </div>

      <div class="navbar-mobile">
        <a class="mobile-login demon-unlogged demon-hide" href="">
          <div class="icon-round">
            <img src="assets/logo.png"/>
            <svg class="s-icon">
              <use xlink:href="assets/icons/sprite.svg#it-user"></use>
            </svg>
          </div>
        </a>
        <a class="mobile-hamburger" href="">
          <div></div>
          <div></div>
          <div></div>
        </a>
      </div>

    </div>
  </div>
  <div class="nav-mobile">
    <div class="mobile-inner"></div>
  </div>
</header>


<ng-template #selectStrutturaModal let-modal>
  <div class="modal-header bg-primary text-white">
    <h2 class="modal-title" id="modal-basic-title">Seleziona Codice Struttura
    </h2>
    <!-- <a class="close" aria-label="Close" (click)="modal.dismiss('Cross click');">
      <svg class="s-icon icon-sm">
        <use href="./assets/icons/sprite.svg#close"></use>
      </svg>
    </a> -->
  </div>
  <div class="modal-body">


    <div class="container">

      <table class="table table-bordered border-primary">
        <thead>
        <tr>
          <th scope="col">Struttura</th>
          <th scope="col">Codice</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let str of listStrutture">

          <td>{{str.denominazione}}</td>

          <td>
            <div class="form-check">
              <input style="cursor: pointer;" class="form-check-input" type="radio" name="selectStrutturaBtn"
                     id="selectStrutturaBtn-{{str.codice!!}}"
                     [value]="str.codice" (change)="selectStruttura($event)" [checked]="isSelected(str.codice!!)">
              <label style="cursor: pointer;" class="form-check-label" for="selectStrutturaBtn-{{str.codice!!}}">
                {{str.codice}}
              </label>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

  </div>

</ng-template>
<ng-template #selectRoleModal let-modal>
  <div class="modal-header bg-primary text-white">
    <h2 class="modal-title" id="modal-basic-title_2">Seleziona un ruolo
    </h2>
  </div>
  <div class="modal-body">
    <div class="container">
      <table class="table">
        <thead>
        <tr>
          <th scope="col">Ruolo</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let str of utente!.ruoli">
          <td class="group-inline">
            <div class="form-check">
              <input style="cursor: pointer;" class="form-check-input" type="radio" name="selectStrutturaBtn"
                     id="selectStrutturaBtn-{{str.id!!}}"
                     [value]="str.id" (change)="selectRuolo($event)"
                     [checked]="utente&&utente.nomeRuolo==str.nome && selectedRole != 0">
            </div>
            <span style="margin-left: 20px">{{str.nome}}</span></td>

        </tr>
        </tbody>
      </table>
    </div>

  </div>

</ng-template>
