<div class="container py-3" demon-cf="prestazione" demon-cf-id="prestazione" id="demonView6-7" demon-ev="true">

  <div class="card">
    <div class="card-header">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">

          <li class="breadcrumb-item"><a (click)="$event.preventDefault();navigateToRicercaAnagrafica()"
              href="">Gestione
              pazienti</a></li>
          <li class="breadcrumb-item"><a (click)="$event.preventDefault();navigateToAnagrafica()"
              href="">{{getTitleName()}}</a></li>

        </ol>
      </nav>
      <div class="row">
        <div class="title-3 col-9" demon-prestazione="titolo">Prestazione {{getDicituraTipoPrestazioneContatto()}}
          #{{prestazione.id}}</div>
        <div class="col-3">
          <button class="btn btn-outline-secondary btn-sm" *ngIf="(getRuolo(OPERATORE_STRUTTURA) || getRuolo(OPERATORE_REGIONE)) && prestazione?.id != null && prestazione?.id != undefined" (click)="$event.preventDefault(); openAuditDialog(audit)" >
            Storico modifiche
          </button>
        </div>
      </div>

    </div>

    <div class="card-body">
<!--      <div class="pt-3" *ngIf="!isPrestazioneNew(prestazione)">-->
<!--        <span class="">Trasmissione: </span> <span class="badge badge-warning">{{prestazione.statoTrasmissione}}</span>-->
<!--      </div>-->

      <!-- Stato e motivazione scarto -->
        <div class="group pt-4 alert alert-danger"  style="background-color: #ffe1cc !important;" *ngIf="prestazione.id != null && (prestazione.statoAttuale.id == 4 ||prestazione.statoAttuale.id ==  7 ||prestazione.statoAttuale.id ==  9)">

        <div class="form-group field-10">
          Stato: {{prestazione.statoAttuale.descrizione}}
        </div>
        <div class="form-group field-10" *ngIf="prestazione.motivazioneScarto != null && prestazione.motivazioneScarto != ''">
          Motivazione dello scarto: {{prestazione.motivazioneScarto}}
        </div>
      </div>

      <form [formGroup]="prestazioneForm" (ngSubmit)="submitForm()" autocomplete="off">

        <!-- Prestazioni sanitarie -->
        <div class="pt-5" demon-prestazione="tipo-1">


          <div class="group-inline mb-4" *ngIf="!isFormTerritoriale()">

            <div class="form-group field-30">
              <label>Seleziona Data Presenza/Prestazione</label>
              <div>
                <select class="form-control" name="presenza" formControlName="presenza"
                  [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null" >
                  <option value=""></option>
                  <option [value]="pr.id" *ngFor="let pr of listPresenze">{{pr.dataPresenza|date:"yyyy-MM-dd"}}</option>
                </select>
              </div>

              <div *ngIf="prestazioneForm.hasError('required', 'presenza') && formSubmitted" class="alert alert-danger">
                Campo obbligatorio
              </div>
            </div>



          </div>

          <div class="group-inline mt-4">
            <!-- Data -->
            <div class="form-group field-10 has-feedback" [hidden]="!isFormTerritoriale()">
              <label>Data*</label>
              <div class="input-group">
                <input class="form-control" placeholder="aaaa-mm-gg" name="date" [(ngModel)]="dateModel" ngbDatepicker
                  #d="ngbDatepicker" [minDate]="minDate" [maxDate]="maxDate" formControlName="date"
                  [readonly]="!hasWritePermission(mainUser) || mustDisableForm()">

                <button class="btn btn-secondary" (click)="d.toggle()" type="button"
                  [attr.disabled]="(!hasWritePermission(mainUser)   )? false:null || mustDisableForm()? false: null">
                  <img class="img-responsive" src="../assets/icons/calendar_ko.png" style="width: 20px; height:20px">
                </button>

              </div>

              <div *ngIf="prestazioneForm.hasError('required', 'date') && formSubmitted" class="alert alert-danger">
                Campo obbligatorio
              </div>

              <div *ngIf="prestazioneForm.hasError('dateExist', 'date') && formSubmitted"
                                    class="alert alert-danger">
                                    Questo dato ha già una prestazione
                                </div>

              <div *ngIf="prestazioneForm.controls['date'].hasError('ngbDate') && formSubmitted"
                class="alert alert-danger">
                Il formato della data non è valido
              </div>
            </div>

            <!-- Tipo -->
            <div class="form-group field-60">
              <label>Tipo*</label>
              <div>
                <select class="form-control" name="prestazioneTipo" formControlName="prestazioneTipo"
                  [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                  <option value=""></option>
                  <option [value]="tp.codice" *ngFor="let tp of listTipoPrestazione">{{tp.descrizione}}</option>
                </select>
                <div *ngIf="prestazioneForm.hasError('required', 'prestazioneTipo')
                        && formSubmitted"
                     class="alert alert-danger">Campo obbligatorio
                </div>

              </div>
            </div>

            <!-- modalita -->

              <!-- modalita erogazione -->
              <div class="form-group field-16" *ngIf="isFormTerritoriale()">
                <label>Modalità di erogazione*</label>
                <div>
                  <select class="form-control" name="prestazioneModalita" formControlName="prestazioneModalita"
                    [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                    <option value=""></option>
                    <option [value]="me.codice" *ngFor="let me of listModalitaErrogazione">{{me.descrizione}}</option>
                  </select>
                </div>
                <div *ngIf="prestazioneForm.hasError('required', 'prestazioneModalita') && formSubmitted"
                class="alert alert-danger">Campo obbligatorio
              </div>
              </div>



            <!-- sede -->
            <div class="form-group field-50 has-feedback">
              <label>Sede intervento*</label>
              <div>
                <select class="form-control" name="sedeIntervento" formControlName="sedeIntervento"
                  [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                  <option value=""></option>
                  <option [value]="seda.codice" *ngFor="let seda of listSedeIntervento">{{seda.descrizione}}
                  </option>

                </select>

              </div>
              <div *ngIf="prestazioneForm.hasError('required', 'sedeIntervento') && formSubmitted"
                class="alert alert-danger">Campo obbligatorio
              </div>
            </div>

          </div>

          <hr>

          <!-- operatore1 -->
          <div class="group-inline">
            <div class="form-group field-20">
              <label>Operatore 1*</label>
              <div>
                <select class="form-control" name="prestazioneOperatore1" demon-cf="fieldRelated"
                  formControlName="prestazioneOperatore1" [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                  <option value=""></option>
                  <option [value]="op.codice" *ngFor="let op of listOperatori">{{op.descrizione}}</option>

                </select>


              </div>

              <div *ngIf="prestazioneForm.hasError('required', 'prestazioneOperatore1') && formSubmitted"
                class="alert alert-danger">Campo obbligatorio</div>
            </div>
            <div class="form-group field-8">
              <label>Codice*</label>
              <input type="number" class="form-control" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" maxlength="5" minlength="4" name="prestazioneOperatoreCodice1"
                formControlName="prestazioneOperatoreCodice1"
                [attr.disabled]="!hasWritePermission(mainUser)?false:null || mustDisableForm()? false: null">


              <div *ngIf="prestazioneForm.hasError('required', 'prestazioneOperatoreCodice1') && formSubmitted"
                class="alert alert-danger">Campo obbligatorio
              </div>

              <div
                *ngIf="prestazioneForm.hasError('minlength', 'prestazioneOperatoreCodice1') && prestazioneForm.controls['prestazioneOperatoreCodice1'].touched"
                class="alert alert-danger">Il codice deve essere di almeno 4 cifre
              </div>
              <div
                *ngIf="prestazioneForm.hasError('min', 'prestazioneOperatoreCodice1') && prestazioneForm.controls['prestazioneOperatoreCodice1'].touched"
                class="alert alert-danger">Il codice deve essere di almeno 4 cifre
              </div>

            </div>


          </div>

          <!-- operatore2 -->
          <div class="group-inline">
            <div class="form-group field-20">
              <label>Operatore 2</label>
              <div>
                <select class="form-control" name="prestazioneOperatore2" formControlName="prestazioneOperatore2"
                  [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null" (change)="setOp2Codice($event)">
                  <option value=""></option>
                  <option [value]="op.codice" *ngFor="let op of listOperatori">{{op.descrizione}}</option>
                </select>

              </div>
            </div>
            <div class="form-group field-8">
              <label>Codice</label>
              <input type="number" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" class="form-control disabled" maxlength="5" name="prestazioneOperatoreCodice2"
                formControlName="prestazioneOperatoreCodice2"
                [attr.disabled]="!hasOperatoreValue(prestazioneForm.get('prestazioneOperatore2')?.value)?false:null || !hasWritePermission(mainUser)?false:null || mustDisableForm()? false: null" >

              <div *ngIf="prestazioneForm.hasError('required', 'prestazioneOperatoreCodice2') && formSubmitted"
                class="alert alert-danger">Campo obbligatorio
              </div>

              <div
                *ngIf="prestazioneForm.hasError('minlength', 'prestazioneOperatoreCodice2') && prestazioneForm.controls['prestazioneOperatoreCodice2'].touched"
                class="alert alert-danger">Il codice deve essere almeno di 4 cifre
              </div>
              <div
              *ngIf="prestazioneForm.hasError('min', 'prestazioneOperatoreCodice2') && prestazioneForm.controls['prestazioneOperatoreCodice2'].touched"
              class="alert alert-danger">Il codice deve essere almeno di 4 cifre
            </div>


            </div>
          </div>

          <!-- operatore3 -->
          <div class="group-inline">
            <div class="form-group field-20">
              <label>Operatore 3</label>
              <div>
                <select class="form-control" name="prestazioneOperatore3" formControlName="prestazioneOperatore3"
                        [attr.disabled]="!hasOperatoreValue(prestazioneForm.get('prestazioneOperatore2')?.value)?false:null || !hasWritePermission(mainUser)?false:null || mustDisableForm()? false: null"
                        (change)="setOp3Codice($event)">
                  <option value=""></option>
                  <option [value]="op.codice" *ngFor="let op of listOperatori">{{op.descrizione}}</option>

                </select>


              </div>
            </div>
            <div class="form-group field-8">
              <label>Codice</label>
              <input type="number" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" maxlength="5" class="form-control disabled" name="prestazioneOperatoreCodice3"
                formControlName="prestazioneOperatoreCodice3"
                [attr.disabled]="!hasOperatoreValue(prestazioneForm.get('prestazioneOperatore3')?.value)?false:null || !hasWritePermission(mainUser)?false:null || mustDisableForm()? false: null">


              <div *ngIf="prestazioneForm.hasError('required', 'prestazioneOperatoreCodice3') && formSubmitted"
                class="alert alert-danger">Campo obbligatorio
              </div>

              <div
                *ngIf="prestazioneForm.hasError('minlength', 'prestazioneOperatoreCodice3') && prestazioneForm.controls['prestazioneOperatoreCodice3'].touched"
                class="alert alert-danger">Il codice deve essere almeno di 4 cifre
              </div>
              <div
              *ngIf="prestazioneForm.hasError('min', 'prestazioneOperatoreCodice3') && prestazioneForm.controls['prestazioneOperatoreCodice3'].touched"
              class="alert alert-danger">Il codice deve essere almeno di 4 cifre
            </div>
            </div>


          </div>
        </div>


      </form>
    </div>
  </div>

  <block-ui>

  </block-ui>

  <!-- Toolbar -->
  <div class="btn-fixed fixed" demon-cf="toolbarFixed" demon-ev="true" demon-cf-id="8">
    <div class="btn-toolbar">
      <div class="container">
        <div class="btn-group-left">
          <button type="button" class="btn btn-outline-secondary" demon-bt="indietro" demon-cf="ModalConfirm"
            demon-body="Tornando indietro perderai i dati inseriti nella prestazione, vuoi continuare comunque?"
            demon-call="$D._sbObj.oprestazione.evIndietro" demon-ev="true" demon-cf-id="9"
            (click)="openGoBackDialog(content)">
            Torna alla lista
          </button>
        </div>
        <div class="btn-group-right">

          <button [attr.disabled]="mustDisableCancelBtn()? false: null" *ngIf="cancellaVisibile()" type="button" class="btn btn-outline-danger"
            (click)="openCancellaDialog(cancella)">Cancella</button>
        </div>
        <div class="btn-group-right">
          <button [attr.disabled]="mustDisableForm()? false: null" *ngIf="salvaVisibile()" type="button" class="btn btn-secondary" (click)="submitForm()">
            Salva
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header" style="background-color: #012346 !important; cursor: pointer;">
    <h4 class="modal-title" id="modal-basic-title" style="color: white;">Attenzione</h4>
    <a (click)="modal.dismiss('Cross click')"><svg class="s-icon icon-xxs">
        <use xlink:href="assets/icons/sprite.svg#close"></use>
      </svg></a>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <label>Tornando indietro perderai i dati inseriti nell'prestazione, vuoi continuare comunque?</label>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Back click')">Ok</button>
  </div>
</ng-template>


<ng-template #cancella let-modal>
  <div class="modal-header" style="background-color: #012346 !important; cursor: pointer;">
    <h4 class="modal-title" id="modal-basic-title-2" style="color: white;">Attenzione</h4>
    <a (click)="modal.dismiss('Cross click')"><svg class="s-icon icon-xxs">
        <use xlink:href="assets/icons/sprite.svg#close"></use>
      </svg></a>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <label>Sei sicuro di voler cancellare prestazione con id {{prestazione!!.id!!}} ?</label>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" (click)="cancellaPrestazione()">Cancella</button>
  </div>
</ng-template>



<ng-template #prestazioniwarning let-modal>
  <div class="modal-header" style="background-color: #012346 !important; cursor: pointer;">
    <h4 class="modal-title" id="modal-basic-title-4" style="color: white;">Attenzione</h4>
    <a (click)="modal.dismiss('Cross click')"><svg class="s-icon icon-xxs">
      <use xlink:href="assets/icons/sprite.svg#close"></use>
    </svg></a>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        {{getDicituraPrestazioniWarning()}}
      </div>
      <div class="row">
        Si vuole procedere comunque al salvataggio?
      </div>
      </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="confermaPrestazioniWarningDialog(modal)">Conferma</button>
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('')">Annulla</button>
  </div>
</ng-template>

<ng-template #audit let-modal>
  <div class="modal-header" style="background-color: #012346 !important; cursor: pointer;">
    <h4 class="modal-title" id="modal-basic-title" style="color: white;">Storico modifiche Prestazione {{prestazione!!.id!!}}</h4>
  </div>
  <div class="modal-body">
    <app-dyn-table [title]="storicoTitle" [dataMessageResults]="auditPrestazione"></app-dyn-table>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('')">Ok</button>
  </div>
</ng-template>
