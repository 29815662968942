export class Funzionalita {
  static readonly  ANAGRAFICHE_INSERIMENTO = 'ANAGRAFICHE_INSERIMENTO';
  static readonly  ANAGRAFICHE_VISUALIZZAZIONE = 'ANAGRAFICHE_VISUALIZZAZIONE';
  static readonly  DASHBOARD_VISUALIZZAZIONE = 'DASHBOARD_VISUALIZZAZIONE';
  static readonly  ELENCO_POSIZIONI_VISUALIZZAZIONE = 'ELENCO_POSIZIONI_VISUALIZZAZIONE';
  static readonly  ELENCO_POSIZIONI_TRASMISSIONE = 'ELENCO_POSIZIONI_TRASMISSIONE';
  static readonly  FLUSSI_INVIATI_VISUALIZZAZIONE = 'FLUSSI_INVIATI_VISUALIZZAZIONE';
  static readonly  FLUSSI_INGRESSO_VISUALIZZAZIONE = 'FLUSSI_INGRESSO_VISUALIZZAZIONE';
  static readonly  FLUSSI_USCITA_VISUALIZZAZIONE = 'FLUSSI_USCITA_VISUALIZZAZIONE';
  static readonly  SELEZIONA_STRUTTURA = 'SELEZIONA_STRUTTURA';
  static readonly  INFO_STRUTTURA_VISUALIZZAZIONE = 'INFO_STRUTTURA_VISUALIZZAZIONE';

}
