import {Component, Input, OnInit} from '@angular/core';
import {Contatto, Intervento, Utente} from "../../model/Models";
import {Funzionalita} from "../../model/Funzionalita";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {SessionService} from "../../services/session.service";

@Component({
  selector: 'app-progetto-di-intervento',
  templateUrl: './progetto-di-intervento.component.html',
  styleUrls: ['./progetto-di-intervento.component.css']
})
export class ProgettoDiInterventoComponent implements OnInit {

  rispostaPrevalente = ''
  intervento!: Intervento
  @Input() contatto!: Contatto
  enableStatiAttuali: Array<number> = [0, 1, 2, 4, 7, 9, 0];
  @Input() mainUser!: Utente
  formSubmitted = false
  _groupPInt: FormGroup | undefined;
  @Input() set groupPInt(v: FormGroup) {
    /*v.addControl('piTrattamentoPsicoterapeutico', new FormControl("", []));
    v.addControl('piTrattamentoFarmacologico', new FormControl("", []));
    v.addControl('piInterventiRiabilitativi', new FormControl("", []));
    v.addControl('piInserimentiLavorativi', new FormControl("", []));
    v.addControl('piInterventoSociale', new FormControl("", []));
    v.addControl('piColloquiSostegno', new FormControl("", []));
    v.addControl('piCoinvolgimentoFamiliari', new FormControl("", []));
    v.addControl('piAccertamentiMedicoLegali', new FormControl("", []));
    v.addControl('piInvioInStruttura', new FormControl("", []));
    v.addControl('piInvioInOspedale', new FormControl("", []));
    v.addControl('piConsultazioneValutazione', new FormControl("", []));
    v.addControl('piAltro', new FormControl("", []));*/

    v.addControl('ieTrattamentoPsicoterapeutico', new FormControl("", []));
    v.addControl('ieTrattamentoFarmacologico', new FormControl("", []));
    v.addControl('ieInterventiRiabilitativi', new FormControl("", []));
    v.addControl('ieInserimentiLavorativi', new FormControl("", []));
    v.addControl('ieInterventoSociale', new FormControl("", []));
    v.addControl('ieColloquiSostegno', new FormControl("", []));
    v.addControl('ieCoinvolgimentoFamiliari', new FormControl("", []));
    v.addControl('ieAccertamentiMedicoLegali', new FormControl("", []));
    v.addControl('ieInvioInStruttura', new FormControl("", []));
    v.addControl('ieInvioStpit', new FormControl("", []));
    v.addControl('ieLuogoStpit', new FormControl("", []));
    v.addControl('ieInvioSrtri', new FormControl("", []));
    v.addControl('ieLuogoSrtri', new FormControl("", []));
    v.addControl('ieInvioSrtre', new FormControl("", []));
    v.addControl('ieLuogoSrtre', new FormControl("", []));
    v.addControl('ieInvioSrsr24', new FormControl("", []));
    v.addControl('ieLuogoSrsr24', new FormControl("", []));
    v.addControl('ieInvioSrsr12', new FormControl("", []));
    v.addControl('ieLuogoSrsr12', new FormControl("", []));
    v.addControl('ieInvioGa', new FormControl("", []));
    v.addControl('ieLuogoGa', new FormControl("", []));
    v.addControl('ieInvioCd', new FormControl("", []));
    v.addControl('ieLuogoCd', new FormControl("", []));
    v.addControl('ieInvioOspedale', new FormControl("", []));
    v.addControl('ieInvioOspedaleRep40', new FormControl("", []));
    v.addControl('ieLuogoOspedale', new FormControl("", []));
    v.addControl('ieConsultazioneValutazione', new FormControl("", []));
    v.addControl('ieAltro', new FormControl("", []));
    this._groupPInt = v;
  }

  constructor(private sessionSevice: SessionService,) {
  }

  invioInStrutturaSelected = false


  // for luogo stpit
  ieInvioStpitSelected = false

  // for luogo srtri
  ieInvioSrtriSelected = false

  // for luogo srtre
  ieInvioSrtreSelected = false

  // for luogo srsr24
  ieInvioSrsr24Selected = false

  // for luogo Srsr12
  ieInvioSrsr12Selected = false

  // for luogo ga
  ieInvioGaSelected = false

  // for luogo cd
  ieInvioCdSelected = false


  // for luogo rep 40
  ieInvioOspedaleSelected = false

  ngOnInit(): void {
    if (this.contatto.interventiList && this.contatto.interventiList?.length > 0) {
      const interventi: Intervento[] = this.contatto.interventiList;
      const interventiS: Intervento[] = interventi.sort((a, b) => a.id && b.id && a.id >= b.id ? -1 : 1);
      this.intervento = interventiS[0];
      this.rispostaPrevalente = this.intervento.rispostaPrevalente ? this.intervento.rispostaPrevalente : '';
      if (this.rispostaPrevalente == '1' || this.rispostaPrevalente == '2' || this.rispostaPrevalente == '3') {
        this.sessionSevice.setSelectedIntervento(JSON.stringify(interventiS[0]));
      } else {
        this.sessionSevice.setSelectedIntervento('');
      }
      this.setFormValues(this.intervento)
    }
    this.observeForm()

  }

  setFormValues(intervento: Intervento) {
    if (this._groupPInt) {
      /*this._groupPInt!.get('piTrattamentoPsicoterapeutico')!!.patchValue(this.getValueFromBoolean(intervento.piTrattamentoPsicoterapeutico));
      this._groupPInt!.get('piTrattamentoFarmacologico')!!.patchValue(this.getValueFromBoolean(intervento.piTrattamentoFarmacologico));
      this._groupPInt!.get('piInterventiRiabilitativi')!!.patchValue(this.getValueFromBoolean(intervento.piInterventiRiabilitativi));
      this._groupPInt!.get('piInserimentiLavorativi')!!.patchValue(this.getValueFromBoolean(intervento.piInserimentiLavorativi));
      this._groupPInt!.get('piInterventoSociale')!!.patchValue(this.getValueFromBoolean(intervento.piInterventoSociale));
      this._groupPInt!.get('piColloquiSostegno')!!.patchValue(this.getValueFromBoolean(intervento.piColloquiSostegno));
      this._groupPInt!.get('piCoinvolgimentoFamiliari')!!.patchValue(this.getValueFromBoolean(intervento.piCoinvolgimentoFamiliari));
      this._groupPInt!.get('piAccertamentiMedicoLegali')!!.patchValue(this.getValueFromBoolean(intervento.piAccertamentiMedicoLegali));
      this._groupPInt!.get('piInvioInStruttura')!!.patchValue(this.getValueFromBoolean(intervento.piInvioInStruttura));
      this._groupPInt!.get('piInvioInOspedale')!!.patchValue(this.getValueFromBoolean(intervento.piInvioInOspedale));
      this._groupPInt!.get('piConsultazioneValutazione')!!.patchValue(this.getValueFromBoolean(intervento.piConsultazioneValutazione));
      this._groupPInt!.get('piAltro')!!.patchValue(intervento.piAltro);*/
      this._groupPInt!.get('ieTrattamentoPsicoterapeutico')!!.patchValue(this.getValueFromBoolean(intervento.ieTrattamentoPsicoterapeutico));
      this._groupPInt!.get('ieTrattamentoFarmacologico')!!.patchValue(this.getValueFromBoolean(intervento.ieTrattamentoFarmacologico));
      this._groupPInt!.get('ieInterventiRiabilitativi')!!.patchValue(this.getValueFromBoolean(intervento.ieInterventiRiabilitativi));
      this._groupPInt!.get('ieInserimentiLavorativi')!!.patchValue(this.getValueFromBoolean(intervento.ieInserimentiLavorativi));
      this._groupPInt!.get('ieInterventoSociale')!!.patchValue(this.getValueFromBoolean(intervento.ieInterventoSociale));
      this._groupPInt!.get('ieColloquiSostegno')!!.patchValue(this.getValueFromBoolean(intervento.ieColloquiSostegno));
      this._groupPInt!.get('ieCoinvolgimentoFamiliari')!!.patchValue(this.getValueFromBoolean(intervento.ieCoinvolgimentoFamiliari));
      this._groupPInt!.get('ieAccertamentiMedicoLegali')!!.patchValue(this.getValueFromBoolean(intervento.ieAccertamentiMedicoLegali));
      this._groupPInt!.get('ieInvioInStruttura')!!.patchValue(this.getValueFromBoolean(intervento.ieInvioInStruttura));
      this._groupPInt!.get('ieInvioStpit')!!.patchValue(this.getValueFromBoolean(intervento.ieInvioStpit));
      this._groupPInt!.get('ieLuogoStpit')!!.patchValue(this.getValueFromBoolean(intervento.ieLuogoStpit));
      this._groupPInt!.get('ieInvioSrtri')!!.patchValue(this.getValueFromBoolean(intervento.ieInvioSrtri));
      this._groupPInt!.get('ieLuogoSrtri')!!.patchValue(this.getValueFromBoolean(intervento.ieLuogoSrtri));
      this._groupPInt!.get('ieInvioSrtre')!!.patchValue(this.getValueFromBoolean(intervento.ieInvioSrtre));
      this._groupPInt!.get('ieLuogoSrtre')!!.patchValue(this.getValueFromBoolean(intervento.ieLuogoSrtre));
      this._groupPInt!.get('ieInvioSrsr24')!!.patchValue(this.getValueFromBoolean(intervento.ieInvioSrsr24));
      this._groupPInt!.get('ieLuogoSrsr24')!!.patchValue(this.getValueFromBoolean(intervento.ieLuogoSrsr24));
      this._groupPInt!.get('ieInvioSrsr12')!!.patchValue(this.getValueFromBoolean(intervento.ieInvioSrsr12));
      this._groupPInt!.get('ieLuogoSrsr12')!!.patchValue(this.getValueFromBoolean(intervento.ieLuogoSrsr12));
      this._groupPInt!.get('ieInvioGa')!!.patchValue(this.getValueFromBoolean(intervento.ieInvioGa));
      this._groupPInt!.get('ieLuogoGa')!!.patchValue(this.getValueFromBoolean(intervento.ieLuogoGa));
      this._groupPInt!.get('ieInvioCd')!!.patchValue(this.getValueFromBoolean(intervento.ieInvioCd));
      this._groupPInt!.get('ieLuogoCd')!!.patchValue(this.getValueFromBoolean(intervento.ieLuogoCd));
      this._groupPInt!.get('ieInvioOspedale')!!.patchValue(this.getValueFromBoolean(intervento.ieInvioOspedale));
      this._groupPInt!.get('ieInvioOspedaleRep40')!!.patchValue(this.getValueFromBoolean(intervento.ieInvioOspedaleRep40));
      this._groupPInt!.get('ieLuogoOspedale')!!.patchValue(this.getValueFromBoolean(intervento.ieLuogoOspedale));
      this._groupPInt!.get('ieConsultazioneValutazione')!!.patchValue(this.getValueFromBoolean(intervento.ieConsultazioneValutazione));
      this._groupPInt!.get('ieAltro')!!.patchValue(intervento.ieAltro);
    }
  }

  getValueFromBoolean(value: Boolean | undefined): string {

    let res = ""
    if (value == true) {
      res = "true"
    }
    if (value == false) {
      res = "false"
    }

    return res
  }

  rispostaPrevalenteIs12() {
    return this.rispostaPrevalente == "1" || this.rispostaPrevalente == "2"
  }

  rispostaPrevalenteIsIn123(value: string) {

    return value != "" && value == "1" || value == "2" || value == "3"

  }

  contattoHasDataChiusura(contatto: Contatto) {
    return contatto.dataChiusura != undefined && contatto.dataChiusura != null
  }

  hasWritePermission(utente: Utente): boolean {
    if (utente.funzionalita.includes(Funzionalita.ANAGRAFICHE_INSERIMENTO)) {
      return true
    } else {
      return false
    }
  }

  mustDisableForm() {
    if (this.intervento.statoAttuale && this.intervento.statoAttuale.id && this.intervento.statoAttuale.id >= 0) {
      return this.enableStatiAttuali.indexOf(this.intervento.statoAttuale.id) == -1
    }
    return false;
  }

  rispostaPrevalenteIs3(value: string) {
    return value == "3"
  }

  observeForm() {

    this._groupPInt!.valueChanges.subscribe(val => {

      let formObj = val

      this.validateFirstIeSection(this.rispostaPrevalente, this.contatto)

      this.validateSecondIeSection(formObj.ieInvioInStruttura)

      this.validateInvioSection(formObj.ieInvioStpit, formObj.ieInvioSrtri, formObj.ieInvioSrtre,
        formObj.ieInvioSrsr24, formObj.ieInvioSrsr12, formObj.ieInvioGa, formObj.ieInvioCd, formObj.ieInvioOspedale)

      this.setIeAltroValidators(formObj.ieInvioInStruttura, formObj.ieInvioOspedale, formObj.ieTrattamentoPsicoterapeutico,
        formObj.ieTrattamentoFarmacologico, formObj.ieInterventiRiabilitativi, formObj.ieInserimentiLavorativi, formObj.ieInterventoSociale,
        formObj.ieColloquiSostegno, formObj.ieCoinvolgimentoFamiliari, formObj.ieAccertamentiMedicoLegali, formObj.ieConsultazioneValutazione)


      //INIZIO - ripulisco i campi nascosti
      if (!this.ieInvioOspedaleSelected) {
        this._groupPInt!.controls['ieInvioOspedaleRep40'].patchValue(null, {emitEvent: false, onlySelf: true});
        this._groupPInt!.controls['ieLuogoOspedale'].patchValue(null, {emitEvent: false, onlySelf: true});
      }

      if (!this.invioInStrutturaSelected) {
        this._groupPInt!.controls['ieInvioStpit'].patchValue(null, {emitEvent: false, onlySelf: true});
        this._groupPInt!.controls['ieLuogoStpit'].patchValue(null, {emitEvent: false, onlySelf: true});
        this._groupPInt!.controls['ieInvioSrtri'].patchValue(null, {emitEvent: false, onlySelf: true});
        this._groupPInt!.controls['ieLuogoSrtri'].patchValue(null, {emitEvent: false, onlySelf: true});
        this._groupPInt!.controls['ieInvioSrtre'].patchValue(null, {emitEvent: false, onlySelf: true});
        this._groupPInt!.controls['ieLuogoSrtre'].patchValue(null, {emitEvent: false, onlySelf: true});
        this._groupPInt!.controls['ieInvioSrsr24'].patchValue(null, {emitEvent: false, onlySelf: true});
        this._groupPInt!.controls['ieLuogoSrsr24'].patchValue(null, {emitEvent: false, onlySelf: true});
        this._groupPInt!.controls['ieInvioSrsr12'].patchValue(null, {emitEvent: false, onlySelf: true});
        this._groupPInt!.controls['ieLuogoSrsr12'].patchValue(null, {emitEvent: false, onlySelf: true});
        this._groupPInt!.controls['ieInvioGa'].patchValue(null, {emitEvent: false, onlySelf: true});
        this._groupPInt!.controls['ieLuogoGa'].patchValue(null, {emitEvent: false, onlySelf: true});
        this._groupPInt!.controls['ieInvioCd'].patchValue(null, {emitEvent: false, onlySelf: true});
        this._groupPInt!.controls['ieLuogoCd'].patchValue(null, {emitEvent: false, onlySelf: true});
      }

      if (!this.ieInvioStpitSelected) {
        this._groupPInt!.controls['ieLuogoStpit'].patchValue(null, {emitEvent: false, onlySelf: true});
      }
      if (!this.ieInvioSrtriSelected) {
        this._groupPInt!.controls['ieLuogoSrtri'].patchValue(null, {emitEvent: false, onlySelf: true});
      }
      if (!this.ieInvioSrtreSelected) {
        this._groupPInt!.controls['ieLuogoSrtre'].patchValue(null, {emitEvent: false, onlySelf: true});
      }
      if (!this.ieInvioSrsr24Selected) {
        this._groupPInt!.controls['ieLuogoSrsr24'].patchValue(null, {emitEvent: false, onlySelf: true});
      }
      if (!this.ieInvioSrsr12Selected) {
        this._groupPInt!.controls['ieLuogoSrsr12'].patchValue(null, {emitEvent: false, onlySelf: true});
      }
      if (!this.ieInvioGaSelected) {
        this._groupPInt!.controls['ieLuogoGa'].patchValue(null, {emitEvent: false, onlySelf: true});
      }
      if (!this.ieInvioCdSelected) {
        this._groupPInt!.controls['ieLuogoCd'].patchValue(null, {emitEvent: false, onlySelf: true});
      }
      if (!this.ieInvioOspedaleSelected) {
        this._groupPInt!.controls['ieInvioOspedaleRep40'].patchValue(null, {emitEvent: false, onlySelf: true});
        this._groupPInt!.controls['ieLuogoOspedale'].patchValue(null, {emitEvent: false, onlySelf: true});
      }
    })
  }

  setIeAltroValidators(ieInvioSrsSS: string, invioOSP: string, iePsicot: string, ieFarma: string, ieRiab: string, ieLav: string,
                       ieIntsoc: string, ieColl: string, ieFam: string, ieMleg: string, ieCons: string) {

    if (this.fieldIsFalse(iePsicot) && this.fieldIsFalse(ieFarma) && this.fieldIsFalse(ieRiab) && this.fieldIsFalse(ieLav)
      && this.fieldIsFalse(ieIntsoc) && this.fieldIsFalse(ieColl) && this.fieldIsFalse(ieFam) && this.fieldIsFalse(ieMleg)
      && this.fieldIsFalse(ieInvioSrsSS) && this.fieldIsFalse(invioOSP) && this.fieldIsFalse(ieCons)) {

      this._groupPInt!.controls['ieAltro'].setValidators([Validators.required])
      this._groupPInt!.controls['ieAltro'].updateValueAndValidity({ onlySelf: true })


    } else if (this.fieldIsFalse(ieRiab) && this.fieldIsFalse(ieLav) && this.fieldIsFalse(ieIntsoc)
      && this.fieldIsFalse(ieColl) && this.fieldIsFalse(ieFam)) {
      this._groupPInt!.controls['ieAltro'].setValidators([Validators.required])
      this._groupPInt!.controls['ieAltro'].updateValueAndValidity({ onlySelf: true })
    } else {
      this._groupPInt!.controls['ieAltro'].removeValidators([Validators.required])
      this._groupPInt!.controls['ieAltro'].updateValueAndValidity({ onlySelf: true })
    }

    if(this.fieldIsTrue(iePsicot) || this.fieldIsTrue(ieFarma) || this.fieldIsTrue(ieRiab)
      || this.fieldIsTrue(ieLav)|| this.fieldIsTrue(ieIntsoc) || this.fieldIsTrue(ieColl) || this.fieldIsTrue(ieFam)
      || this.fieldIsTrue(ieMleg) || this.fieldIsTrue(ieInvioSrsSS) || this.fieldIsTrue(invioOSP) || this.fieldIsTrue(ieCons)){

      this._groupPInt!.controls['ieAltro'].removeValidators([Validators.required])
      this._groupPInt!.controls['ieAltro'].updateValueAndValidity({ onlySelf: true })

    }
  }
  validateFirstIeSection(rispostaPrevalente: string, contatto: Contatto) {
    if (this.rispostaPrevalenteIsIn123(rispostaPrevalente) && this.contattoHasDataChiusura(contatto)) {
      this._groupPInt!.controls['ieTrattamentoPsicoterapeutico'].setValidators([Validators.required])
      this._groupPInt!.controls['ieTrattamentoFarmacologico'].setValidators([Validators.required])
      this._groupPInt!.controls['ieInterventiRiabilitativi'].setValidators([Validators.required])
      this._groupPInt!.controls['ieInserimentiLavorativi'].setValidators([Validators.required])
      this._groupPInt!.controls['ieInterventoSociale'].setValidators([Validators.required])
      this._groupPInt!.controls['ieColloquiSostegno'].setValidators([Validators.required])
      this._groupPInt!.controls['ieCoinvolgimentoFamiliari'].setValidators([Validators.required])
      this._groupPInt!.controls['ieAccertamentiMedicoLegali'].setValidators([Validators.required])
      this._groupPInt!.controls['ieInvioInStruttura'].setValidators([Validators.required])
      this._groupPInt!.controls['ieConsultazioneValutazione'].setValidators([Validators.required])
    } else {
      this._groupPInt!.controls['ieTrattamentoPsicoterapeutico'].removeValidators([Validators.required])
      this._groupPInt!.controls['ieTrattamentoPsicoterapeutico'].updateValueAndValidity({ onlySelf: true })
      this._groupPInt!.controls['ieTrattamentoFarmacologico'].removeValidators([Validators.required])
      this._groupPInt!.controls['ieTrattamentoFarmacologico'].updateValueAndValidity({ onlySelf: true })
      this._groupPInt!.controls['ieInterventiRiabilitativi'].removeValidators([Validators.required])
      this._groupPInt!.controls['ieInterventiRiabilitativi'].updateValueAndValidity({ onlySelf: true })
      this._groupPInt!.controls['ieInserimentiLavorativi'].removeValidators([Validators.required])
      this._groupPInt!.controls['ieInserimentiLavorativi'].updateValueAndValidity({ onlySelf: true })
      this._groupPInt!.controls['ieInterventoSociale'].removeValidators([Validators.required])
      this._groupPInt!.controls['ieInterventoSociale'].updateValueAndValidity({ onlySelf: true })
      this._groupPInt!.controls['ieColloquiSostegno'].removeValidators([Validators.required])
      this._groupPInt!.controls['ieColloquiSostegno'].updateValueAndValidity({ onlySelf: true })
      this._groupPInt!.controls['ieCoinvolgimentoFamiliari'].removeValidators([Validators.required])
      this._groupPInt!.controls['ieCoinvolgimentoFamiliari'].updateValueAndValidity({ onlySelf: true })
      this._groupPInt!.controls['ieAccertamentiMedicoLegali'].removeValidators([Validators.required])
      this._groupPInt!.controls['ieAccertamentiMedicoLegali'].updateValueAndValidity({ onlySelf: true })
      this._groupPInt!.controls['ieInvioInStruttura'].removeValidators([Validators.required])
      this._groupPInt!.controls['ieInvioInStruttura'].updateValueAndValidity({ onlySelf: true })
      this._groupPInt!.controls['ieInvioOspedale'].removeValidators([Validators.required])
      this._groupPInt!.controls['ieInvioOspedale'].updateValueAndValidity({ onlySelf: true })
      this._groupPInt!.controls['ieConsultazioneValutazione'].removeValidators([Validators.required])
      this._groupPInt!.controls['ieConsultazioneValutazione'].updateValueAndValidity({ onlySelf: true })
      // this._groupPInt!.updateValueAndValidity({ onlySelf: true, emitEvent: false })
    }
  }
  validateSecondIeSection(invioInStruttura: string) {
    if (this.fieldIsTrue(invioInStruttura)) {
      this.invioInStrutturaSelected = true
    } else {
      this.invioInStrutturaSelected = false
    }
  }
  fieldIsTrue(value: string) {

    return value == "true"
  }

  validateInvioSection(ieInvioStpit: string, ieInvioSrtri: string, ieInvioSrtre: string,
                       ieInvioSrsr24: string, ieInvioSrsr12: string, ieInvioGa: string, ieInvioCd: string, ieInvioOspedale: string) {


    if (this.invioInStrutturaSelected && this.fieldIsFalse(ieInvioStpit) && this.fieldIsFalse(ieInvioSrtri) && this.fieldIsFalse(ieInvioSrtre)
      && this.fieldIsFalse(ieInvioSrsr24) && this.fieldIsFalse(ieInvioSrsr12) && this.fieldIsFalse(ieInvioGa)
      && this.fieldIsFalse(ieInvioCd)) {



      this._groupPInt!.get('ieInvioInStruttura')?.setErrors({ 'invioNotSelected': true })
      // this._groupPInt!.get('ieInvioInStruttura')?.updateValueAndValidity({ onlySelf: true, emitEvent: false })

    } else {

      this._groupPInt!.get('ieInvioInStruttura')?.setErrors({ 'invioNotSelected': null })
      this._groupPInt!.get('ieInvioInStruttura')?.updateValueAndValidity({ onlySelf: true, emitEvent: false })


      this._groupPInt!.updateValueAndValidity({ onlySelf: true, emitEvent: false })

    }


    // invio stpit
    if (this.fieldIsTrue(ieInvioStpit)) {

      this.ieInvioStpitSelected = true

      this._groupPInt!.controls['ieLuogoStpit'].setValidators([Validators.required])
      // this._groupPInt!.controls['ieLuogoStpit'].updateValueAndValidity({ onlySelf: true })

    } else {
      this.ieInvioStpitSelected = false
      this._groupPInt!.controls['ieLuogoStpit'].removeValidators([Validators.required])
      this._groupPInt!.controls['ieLuogoStpit'].updateValueAndValidity({ onlySelf: true })

    }


    // invio srtri
    if (this.fieldIsTrue(ieInvioSrtri)) {


      this.ieInvioSrtriSelected = true

      this._groupPInt!.controls['ieLuogoSrtri'].setValidators([Validators.required])
      // this._groupPInt!.controls['ieLuogoSrtri'].updateValueAndValidity({ onlySelf: true })

    } else {

      this.ieInvioSrtriSelected = false

      this._groupPInt!.controls['ieLuogoSrtri'].removeValidators([Validators.required])
      this._groupPInt!.controls['ieLuogoSrtri'].updateValueAndValidity({ onlySelf: true })

    }


    // invio srtre
    if (this.fieldIsTrue(ieInvioSrtre)) {

      this.ieInvioSrtreSelected = true

      this._groupPInt!.controls['ieLuogoSrtre'].setValidators([Validators.required])
      // this._groupPInt!.controls['ieLuogoSrtre'].updateValueAndValidity({ onlySelf: true })

    } else {
      this.ieInvioSrtreSelected = false

      this._groupPInt!.controls['ieLuogoSrtre'].removeValidators([Validators.required])
      this._groupPInt!.controls['ieLuogoSrtre'].updateValueAndValidity({ onlySelf: true })

    }




    // invio srs24
    if (this.fieldIsTrue(ieInvioSrsr24)) {

      this.ieInvioSrsr24Selected = true

      this._groupPInt!.controls['ieLuogoSrsr24'].setValidators([Validators.required])
      // this._groupPInt!.controls['ieLuogoSrsr24'].updateValueAndValidity({ onlySelf: true })


    } else {
      this.ieInvioSrsr24Selected = false
      this._groupPInt!.controls['ieLuogoSrsr24'].removeValidators([Validators.required])
      this._groupPInt!.controls['ieLuogoSrsr24'].updateValueAndValidity({ onlySelf: true })

    }

    // invio srsr12
    if (this.fieldIsTrue(ieInvioSrsr12)) {

      this.ieInvioSrsr12Selected = true

      this._groupPInt!.controls['ieLuogoSrsr12'].setValidators([Validators.required])
      // this._groupPInt!.controls['ieLuogoSrsr12'].updateValueAndValidity({ onlySelf: true })


    } else {
      this.ieInvioSrsr12Selected = false
      this._groupPInt!.controls['ieLuogoSrsr12'].removeValidators([Validators.required])
      this._groupPInt!.controls['ieLuogoSrsr12'].updateValueAndValidity({ onlySelf: true })

    }


    // invio ga
    if (this.fieldIsTrue(ieInvioGa)) {

      this.ieInvioGaSelected = true

      this._groupPInt!.controls['ieLuogoGa'].setValidators([Validators.required])
      // this._groupPInt!.controls['ieLuogoGa'].updateValueAndValidity({ onlySelf: true })


    } else {
      this.ieInvioGaSelected = false
      this._groupPInt!.controls['ieLuogoGa'].removeValidators([Validators.required])
      this._groupPInt!.controls['ieLuogoGa'].updateValueAndValidity({ onlySelf: true })

    }


    // invio cd
    if (this.fieldIsTrue(ieInvioCd)) {

      this.ieInvioCdSelected = true

      this._groupPInt!.controls['ieLuogoCd'].setValidators([Validators.required])
      // this._groupPInt!.controls['ieLuogoCd'].updateValueAndValidity({ onlySelf: true })

    } else {
      this.ieInvioCdSelected = false
      this._groupPInt!.controls['ieLuogoCd'].removeValidators([Validators.required])
      this._groupPInt!.controls['ieLuogoCd'].updateValueAndValidity({ onlySelf: true })
    }


    // invio invio ospedale
    if (this.fieldIsTrue(ieInvioOspedale)) {

      this.ieInvioOspedaleSelected = true
      this._groupPInt!.controls['ieInvioOspedaleRep40'].setValidators([Validators.required])
      // this._groupPInt!.controls['ieInvioOspedaleRep40'].updateValueAndValidity({ onlySelf: true })

      this._groupPInt!.controls['ieLuogoOspedale'].setValidators([Validators.required])
      // this._groupPInt!.controls['ieLuogoOspedale'].updateValueAndValidity({ onlySelf: true })

    } else {
      this.ieInvioOspedaleSelected = false
      this._groupPInt!.controls['ieInvioOspedaleRep40'].removeValidators([Validators.required])
      this._groupPInt!.controls['ieInvioOspedaleRep40'].updateValueAndValidity({ onlySelf: true })
      this._groupPInt!.controls['ieLuogoOspedale'].removeValidators([Validators.required])
      this._groupPInt!.controls['ieLuogoOspedale'].updateValueAndValidity({ onlySelf: true })
    }

    // this._groupPInt!.updateValueAndValidity({ onlySelf: true, emitEvent: false })

  }

  fieldIsFalse(value: string) {
    return value == "false"
  }
}
