<div
  *ngIf="isLoading"
  class="spinner-border text-primary landingspinner"
  style="width: 5rem; height: 5rem"
  role="status">
  <span class="sr-only">Loading...</span>
</div>
<div class="container py-3" demon-cf="anagrafica" demon-cf-id="anagrafica" id="demonView6-3" demon-ev="true">
  <!--div class="card-header">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/ricerca-anagrafica"
                                       (click)="$event.preventDefault();">Anagrafica</a></li>
      </ol>
    </nav>
    <div class="row">
      <div class="title-3 col-9" demon-anagrafica="titolo"> {{getTitleName()}}</div>
      <div class="col-3" style="text-align: end">
        <button class="btn btn-outline-secondary btn-sm" *ngIf="getRuolo(OPERATORE_STRUTTURA) && dettaglioAnagrafica?.id " (click)=" openAuditDialog(audit)" >
        Storico modifiche
        </button>
      </div>
    </div>
  </div-->



  <!-- Anagrafica -->
  <div id="anagrafica" role="tabpanel"
       aria-labelledby="anagrafica-tab">

    <form [formGroup]="dettaglioAnagraficaForm" autocomplete="off">

      <!-- Controlla codicefiscale -->
      <div *ngIf="showControllaCfSection" class="card">
        <div class="card-body" style="margin-top: 25px">
          <div class="form-group field-16 has-feedback">
            <div class="input-group" style="padding: 5px; line-height: 25px; align-items: center">
              <label style="padding: 10px;">Codice fiscale*</label>
              <input type="text" class="form-control" name="anagraficaCF" maxlength="16" (beforeinput)="clearFormInput('anagraficaSCF')"
                     formControlName="anagraficaCF" style="width: 200px !important;{{anagraficaCF?.invalid && (anagraficaCF?.dirty || anagraficaCF?.touched)?'border-color:red !important':''}}"
                     [pattern]="'^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$'">
              <label style="padding: 10px;">o codice ENI/STP</label>
              <input type="text" class="form-control" name="anagraficaSCF" maxlength="16" (beforeinput)="clearFormInput('anagraficaCF')" style="{{anagraficaSCF?.invalid && (anagraficaSCF?.dirty || anagraficaSCF?.touched)?'border-color:red !important':''}}"
                     formControlName="anagraficaSCF"
                     [pattern]="'^[ENI|eni|stp|STP]{3}[0-9]{13}$'">
              <div class="input-group" style="padding: 10px;">
                <div class="input-group-text icon-success" *ngIf="verificatoConSuccesso">
                  <svg class="s-icon icon-sm text-success icon-status" data-toggle="tooltip" title=""
                       data-original-title="Codice verificato con successo">
                    <use xlink:href="assets/icons/sprite.svg#success"></use>
                  </svg>
                </div>
                <button class="btn btn-secondary"  data-toggle="tooltip" title="" data-original-title="Controlla il codice fiscale"
                        (click)="getAnagraficaByCf()">
                  Ricerca in ASUR
                </button>
              </div>
            </div>

            <div class="form-feedback">
              <div class="feedback-text invalid-feedback">&nbsp;</div>
            </div>
          </div>
        </div>
      </div>

      <!-- Dati certificati ASUR -->
      <div *ngIf="isControllaClick">
        <div class="title-5 alert-warning" demon-anagrafica="datiCertificati" style="padding: 10px; margin: 10px" *ngIf="messageDatiCertificati">
          <!-- Dati certificati ASUR -->
          {{messageDatiCertificati}}
        </div>

        <!-- INPUTS -->
        <div class="group-inline pt-4">


          <!-- CODICE_FISCALE -->
          <div class="form-group field-16 has-feedback">
            <label>Codice fiscale o ENI/STP *</label>
            <input type="text" class="form-control" name="codiceFiscale" style="width: 200px!important;"
                   [pattern]="'^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}|^[ENI|eni|stp|STP]{3}[0-9]{13}$'"
                   formControlName="codiceFiscale" maxlength="16" [readonly]="!hasWritePermission(mainUser)">
            <div
              *ngIf="dettaglioAnagraficaFormSubmitted && dettaglioAnagraficaForm.hasError('required', 'codiceFiscale')"
              class="alert alert-danger">Campo obbligatorio
            </div>
            <div
              *ngIf="dettaglioAnagraficaFormSubmitted && dettaglioAnagraficaForm.hasError('pattern', 'codiceFiscale')"
              class="alert alert-danger">Il codice non è formattato correttamente
            </div>


            <div *ngIf="dettaglioAnagraficaForm.controls['codiceFiscale'].hasError('codiceCfNonValid')"
                 class="alert alert-danger">Il codice non è valido
            </div>

            <div *ngIf="dettaglioAnagraficaForm.controls['codiceFiscale'].hasError('codiceFiscaleExist')"
                 class="alert alert-danger">Il codice fiscale è già presente nel database
            </div>
          </div>


          <!-- datanascita -->
          <div class="form-group field-10 has-feedback">

            <label>Data nascita*</label>
            <div class="input-group">

              <!-- <input type="text" class="form-control datepicker" name="anagraficaNsData" readonly="readonly"> -->
              <input class="form-control" placeholder="aaaa-mm-gg" name="dp" id="datepicker"
                     [(ngModel)]="dateModel"
                     ngbDatepicker [minDate]="minDate" [maxDate]="maxDate" #d="ngbDatepicker"
                     formControlName="dataNascita" [readonly]="!hasWritePermission(mainUser)">

              <button class="btn btn-secondary" (click)="d.toggle()" type="button"
                      [disabled]="!hasWritePermission(mainUser) || dataNascitaButtonDisable">
                <img class="img-responsive" src="../assets/icons/calendar_ko.png"
                     style="width: 20px; height:20px">
              </button>

            </div>
            <div
              *ngIf="dettaglioAnagraficaForm.hasError('required', 'dataNascita') && dettaglioAnagraficaFormSubmitted"
              class="alert alert-danger">Campo obbligatorio
            </div>

            <div
              *ngIf="dettaglioAnagraficaForm.controls['dataNascita'].hasError('dataNascitaError') "
              class="alert alert-danger">
              La data inserita non è congrua con il codice fiscale.
            </div>

            <div
              *ngIf="dettaglioAnagraficaForm.controls['dataNascita'].hasError('ngbDate')
                  && dettaglioAnagraficaFormSubmitted"
              class="alert alert-danger">
              Il formato della data non è valido
            </div>
          </div>


          <!-- COGNOME -->
          <div class="form-group field-20 has-feedback">
            <label>Cognome*</label>
            <input type="text" class="form-control" name="cognome" formControlName="cognome"
                   [readonly]="!hasWritePermission(mainUser)">
            <div *ngIf="dettaglioAnagraficaFormSubmitted
                    && dettaglioAnagraficaForm.controls['nome'].hasError('required')" class="alert alert-danger">Campo
              obbligatorio
            </div>

            <div *ngIf="dettaglioAnagraficaForm.controls['cognome'].hasError('cognomeError')"
                 class="alert alert-danger">Cognome non congruo con cf
            </div>
          </div>

          <!-- NOME -->
          <div class="form-group field-20 has-feedback">
            <label>Nome*</label>
            <input type="text" class="form-control" name="nome" formControlName="nome"
                   [readonly]="!hasWritePermission(mainUser)">

            <div *ngIf="dettaglioAnagraficaFormSubmitted && dettaglioAnagraficaForm.hasError('required', 'nome')"
                 class="alert alert-danger">Campo obbligatorio
            </div>

            <div *ngIf="dettaglioAnagraficaForm.controls['nome'].hasError('nomeError')"
                 class="alert alert-danger">
              nome non congruo con cf
            </div>
          </div>


        </div>


        <div class="group-inline">

          <!-- SESSO -->
          <div class="form-group has-feedback">
            <label>Sesso*</label>
            <div class="form-radio">
              <div class="radio">
                <input type="radio" name="sesso" id="anagraficaSesso1" value="M" formControlName="sesso" checked
                       [readonly]="!hasWritePermission(mainUser)">
                <label for="anagraficaSesso1">Maschio</label>
              </div>
              <div class="radio">
                <input type="radio" name="sesso" id="anagraficaSesso2" value="F" formControlName="sesso"
                       [readonly]="!hasWritePermission(mainUser)">
                <label for="anagraficaSesso2">Femmina</label>
              </div>
              <div class="radio">
                <input type="radio" name="sesso" id="anagraficaSesso9" value="N" formControlName="sesso"
                       [readonly]="!hasWritePermission(mainUser)">
                <label for="anagraficaSesso9">Non noto/Non risulta</label>
              </div>

            </div>

            <div
              *ngIf="dettaglioAnagraficaFormSubmitted && dettaglioAnagraficaForm.controls['sesso'].hasError('sessoEmpty')"
              class="alert alert-danger">Campo obbligatorio
            </div>

            <div *ngIf="dettaglioAnagraficaFormSubmitted && dettaglioAnagraficaForm.hasError('required', 'sesso')"
                 class="alert alert-danger">Campo obbligatorio
            </div>

            <div *ngIf="dettaglioAnagraficaForm.controls['sesso'].hasError('genderError')"
                 class="alert alert-danger">Sesso non congruo con cf
            </div>

            <div *ngIf="dettaglioAnagraficaFormSubmitted
                  && dettaglioAnagraficaForm.controls['sesso'].hasError('genderNonValid')" class="alert alert-danger">
              Per il momento non posiamo validare questo dato
            </div>
          </div>


        </div>

        <div class="row">


          <!-- COMUNE nascita TYPEAHEAD -->
          <div class="form-group field-20">
            <label>Comune o stato estero di nascita*</label>
            <div>
              <input id="typeahead-focus" type="text" class="form-control"
                     [(ngModel)]="modelDescrizioneComuneNascita" [ngbTypeahead]="comuneNascitaSearch"
                     (focus)="focus$.next($any($event).target.value)"
                     (click)="click$.next($any($event).target.value)" #instance="ngbTypeahead"
                     [inputFormatter]="formatter" [resultFormatter]="formatter" [editable]="false"
                     formControlName="descrizioneComuneNascita" [readonly]="!hasWritePermission(mainUser)"/>
            </div>

            <div *ngIf="dettaglioAnagraficaFormSubmitted
            && dettaglioAnagraficaForm.controls['descrizioneComuneNascita'].hasError('emptyComuneError')"
                 class="alert alert-danger">Campo Obbligatorio
            </div>

            <div *ngIf="dettaglioAnagraficaFormSubmitted
            && dettaglioAnagraficaForm.controls['descrizioneComuneNascita'].hasError('wrongComuneError')"
                 class="alert alert-danger">Il valore deve essere selezionato tra quelli proposti
            </div>

            <div *ngIf="dettaglioAnagraficaFormSubmitted
                && dettaglioAnagraficaForm.controls['descrizioneComuneNascita'].hasError('comuneError')"
                 class="alert alert-danger">comune nascita non congruo con cf
            </div>


          </div>


          <!-- stato civile citadinanza-->
          <div class="group-inline">
            <!-- <div class="form-group field-18 has-feedback">
              <label>Stato civile*</label>
              <div>
                <select class="form-control" name="statoCivile" formControlName="statoCivile"
                  [attr.disabled]="!hasWritePermission(mainUser)? true:null">
                  <option value=""></option>
                  <option [value]="se.codice" *ngFor="let se of listStatiCivili">{{se.descrizione}}</option>
                </select>
              </div>
              <div
                *ngIf="dettaglioAnagraficaForm.hasError('required', 'statoCivile') && dettaglioAnagraficaFormSubmitted"
                class="alert alert-danger">Campo obbligatorio</div>

            </div> -->

            <!-- citadinanza -->
            <div class="form-group field-20 has-feedback" *ngIf="hasWritePermission(mainUser)||dettaglioAnagrafica?.cfCertificato==='1'">
              <label>Cittadinanza* </label>
              <div>
                <select class="form-control" name="anagraficaCittadinanza" formControlName="cittadinanza"
                        [disabled]="!hasWritePermission(mainUser)? true:false">
                  <option value=""></option>
                  <option [value]="cit.alpha2" *ngFor="let cit of listCitadiNanza">{{cit.descrizione}}</option>
                </select>
              </div>

              <div
                *ngIf="dettaglioAnagraficaForm.hasError('required', 'cittadinanza') && dettaglioAnagraficaFormSubmitted"
                class="alert alert-danger">Campo obbligatorio
              </div>

              <div
                *ngIf="dettaglioAnagraficaForm.hasError('citadinanzaError', 'cittadinanza') && dettaglioAnagraficaFormSubmitted"
                class="alert alert-danger">Campo obbligatorio
              </div>
            </div>
          </div>


        </div>


        <!-- Residenza -->
        <hr>


        <div class="title-5" *ngIf="this.dettaglioAnagrafica?.cfCertificato === '1' || hasWritePermission(mainUser)">
          Residenza
        </div>

        <div class="group-inline pt-4"  *ngIf="this.dettaglioAnagrafica?.cfCertificato  === '1' || hasWritePermission(mainUser)">


          <!-- Regione -->
          <div class="form-group field-24 has-feedback">
            <label>Regione*</label>

            <div>

              <select class="form-control" name="regioneResidenza" formControlName="regioneResidenza"
                      (change)="setRegioneCodice($event)"
                      [attr.disabled]="!hasWritePermission(mainUser)? false:null">

                <option value=""></option>
                <option [value]="reg.codRegione" *ngFor="let reg of listRegione">{{reg.regione}}</option>
              </select>
            </div>
            <div
              *ngIf="dettaglioAnagraficaForm.hasError('required', 'regioneResidenza') && dettaglioAnagraficaFormSubmitted"
              class="alert alert-danger">Campo obbligatorio
            </div>
          </div>


          <!-- Stato -->
          <div class="form-group field-24 has-feedback" *ngIf="isStatoResidenzaPresente()">
            <label>Stato*</label>
            <div>
              <select class="form-control" name="statoResidenza" formControlName="statoResidenza"
                      (change)="setState($event)" [attr.disabled]="!hasWritePermission(mainUser)? false:null">
                <option value=""></option>
                <option [value]="se.alpha2" *ngFor="let se of listStatiesteri">{{se.descrizione}}</option>
              </select>

            </div>
            <div
              *ngIf="dettaglioAnagraficaForm.hasError('required', 'statoResidenza') && dettaglioAnagraficaFormSubmitted"
              class="alert alert-danger">Campo obbligatorio
            </div>
          </div>

          <!-- Comune residenza -->
          <div class="form-group field-20 has-feedback" *ngIf="isComuneResidenzaPresente()">
            <label>Comune*</label>
            <div>
              <input id="typeahead-focus2" type="text" class="form-control"
                     [(ngModel)]="modelDescrizioneComuneResidenza" [ngbTypeahead]="comuneResidenzaSearch"
                     (focus)="focus$.next($any($event).target.value)"
                     (click)="click$.next($any($event).target.value);" (selectItem)="setComuneCodice($event)"
                     #instance="ngbTypeahead"
                     [inputFormatter]="formatter" [resultFormatter]="formatter" name="comuneResidenza" [editable]="false"
                     formControlName="comuneResidenza" [readonly]="!hasWritePermission(mainUser)"/>
            </div>
            <!-- <div>
              <select class="form-control" name="comuneResidenza" formControlName="comuneResidenza"
                (change)="setComuneCodice($event)" [attr.disabled]="!hasWritePermission(mainUser)? false:null">
                <option value=""></option>
                <option [value]="com.codice" *ngFor="let com of listComuneResidenza">{{com.descrizione}}</option>
              </select>
            </div> -->
            <div
              *ngIf="dettaglioAnagraficaForm.hasError('required', 'comuneResidenza') && dettaglioAnagraficaFormSubmitted"
              class="alert alert-danger">Campo obbligatorio
            </div>
          </div>

          <!-- Asl -->
          <div class="form-group field-16 has-feedback" *ngIf="isAslResidenzaPresente()">
            <label>ASL*</label>
            <div>
              <select class="form-control" name="aslResidenza" formControlName="aslResidenza"
                      (change)="setAslResidenza($event)"
                      [attr.disabled]="!hasWritePermission(mainUser)? false:null">
                <option value=""></option>
                <option [value]="asl.codiceAzienda" *ngFor="let asl of listAsl">{{asl.denominazioneAzienda}}
                </option>

              </select>
            </div>

            <div
              *ngIf="dettaglioAnagraficaForm.hasError('required', 'aslResidenza') && dettaglioAnagraficaFormSubmitted"
              class="alert alert-danger">Campo obbligatorio
            </div>
          </div>

          <!-- Distretto -->
          <div class="form-group field-20 has-feedback demon-related" *ngIf="isDistrettoResidenzaPresente()">
            <label>Distretto socio sanitario*</label>
            <div>
              <select class="form-control" name="distrettoResidenza" formControlName="distrettoResidenza" style="min-width: 350px !important;"
                      (change)="setDistrettoResidenza($event)"
                      [attr.disabled]="!hasWritePermission(mainUser)? false:null">
                <option value=""></option>
                <option [value]="dis.codice" *ngFor="let dis of listDistretti">{{dis.descrizione}}</option>
              </select>
            </div>
            <div
              *ngIf="dettaglioAnagraficaForm.hasError('required', 'distrettoResidenza') && dettaglioAnagraficaFormSubmitted"
              class="alert alert-danger">Campo obbligatorio
            </div>
          </div>

          <!-- Municipio -->
          <div class="form-group field-20 has-feedback demon-related demon-hide" demon-related="residenzaComune"
               demon-value="5775" *ngIf="isMunicipioResidenzaPresente()">
            <label>Municipio*</label>
            <div>
              <select class="form-control" name="municipioResidenza" formControlName="municipioResidenza" style="min-width: 550px !important;"
                      [attr.disabled]="!hasWritePermission(mainUser)? false:null">
                <option value=""></option>
                <option [value]="mun.codice" *ngFor="let mun of listMunicipi">{{mun.descrizione}}</option>
              </select>
            </div>
            <div
              *ngIf="dettaglioAnagraficaForm.hasError('required', 'municipioResidenza') && dettaglioAnagraficaFormSubmitted"
              class="alert alert-danger">Campo obbligatorio
            </div>
          </div>


        </div>

        <hr *ngIf="isAnagraficaStrutturaFieldsVisible()">


        <div *ngIf="isAnagraficaStrutturaFieldsVisible()" class="group-inline">


          <div class="form-group field-18 has-feedback">
            <label>Stato civile*</label>
            <div>
              <select class="form-control" name="statoCivile" formControlName="statoCivile"
                      [attr.disabled]="!hasWritePermission(mainUser)? true:null">
                <option value=""></option>
                <option [value]="se.codice" *ngFor="let se of listStatiCivili">{{se.descrizione}}</option>
              </select>
            </div>
            <div
              *ngIf="dettaglioAnagraficaForm.hasError('required', 'statoCivile') && dettaglioAnagraficaFormSubmitted"
              class="alert alert-danger">Campo obbligatorio
            </div>

          </div>

          <!-- COLLAZIONE SOCIO AMBIANTALE  -->
          <div class="form-group field-60 has-feedback">
            <label>Collocazione socio ambientale*</label>
            <div>
              <select class="form-control" name="collocazioneSocioAmbientale"
                      formControlName="collocazioneSocioAmbientale"
                      [attr.disabled]="!hasWritePermission(mainUser)? false:null">
                <option value=""></option>
                <option [value]="coll.codice" *ngFor="let coll of listCollazioneSocioAmbientali">
                  {{coll.descrizione}}</option>
              </select>
            </div>

            <div
              *ngIf="dettaglioAnagraficaForm.hasError('required', 'collocazioneSocioAmbientale') && dettaglioAnagraficaFormSubmitted"
              class="alert alert-danger">Campo obbligatorio
            </div>

          </div>

          <!-- TITOLO DI STUDIO -->
          <div class="form-group field-30 has-feedback">
            <label>Titolo di studio*</label>
            <div>
              <select class="form-control" name="titoloDiStudio" formControlName="titoloDiStudio"
                      [attr.disabled]="!hasWritePermission(mainUser)? false:null">
                <option value=""></option>
                <option [value]="tit.codice" *ngFor="let tit of listTitoliStudio">{{tit.descrizione}}</option>

              </select>
            </div>
            <div
              *ngIf="dettaglioAnagraficaForm.hasError('required', 'titoloDiStudio') && dettaglioAnagraficaFormSubmitted"
              class="alert alert-danger">Campo obbligatorio
            </div>

          </div>

          <hr>

          <!-- ATTIVITA -->
          <div class="form-group field-36 has-feedback">
            <label>Attività*</label>
            <div>
              <select class="form-control" name="attivita" formControlName="attivita"
                      [attr.disabled]="!hasWritePermission(mainUser)? false:null">
                <option value=""></option>
                <option [value]="at.codice" *ngFor="let at of listAtivitta">{{at.descrizione}}</option>

              </select>
            </div>

            <div
              *ngIf="dettaglioAnagraficaForm.hasError('required', 'attivita') && dettaglioAnagraficaFormSubmitted"
              class="alert alert-danger">Campo obbligatorio
            </div>

          </div>


          <!-- FAMILIARE PAZIENTE -->
          <div class="form-group has-feedback">
            <label>E' familiare di un paziente in trattamento*</label>
            <div class="form-radio">
              <div class="radio">
                <input type="radio" name="familiarePaziente" id="anagraficaFamiliare1" value="1"
                       formControlName="familiarePaziente"
                       [attr.disabled]="!hasWritePermission(mainUser)? false:null">
                <label for="anagraficaFamiliare1">Sì</label>
              </div>
              <div class="radio">
                <input type="radio" name="familiarePaziente" id="anagraficaFamiliare2" value="2"
                       formControlName="familiarePaziente"
                       [attr.disabled]="!hasWritePermission(mainUser)? false:null">
                <label for="anagraficaFamiliare2">No</label>
              </div>
              <div class="radio">
                <input type="radio" name="familiarePaziente" id="anagraficaFamiliare9" value="9"
                       formControlName="familiarePaziente"
                       [attr.disabled]="!hasWritePermission(mainUser)? false:null">
                <label for="anagraficaFamiliare9">Non noto/Non risulta</label>
              </div>
            </div>

            <div
              *ngIf="dettaglioAnagraficaForm.hasError('required', 'familiarePaziente') && dettaglioAnagraficaFormSubmitted"
              class="alert alert-danger">Campo obbligatorio
            </div>
          </div>

          <!-- INVALIDITA -->
          <div class="form-group field-60 has-feedback">
            <label>Invalidità*</label>
            <div>
              <select class="form-control" name="invalidita" formControlName="invalidita"
                      [attr.disabled]="!hasWritePermission(mainUser)? false:null">
                <option value=""></option>
                <option [value]="inv.codice" *ngFor="let inv of listInvalidita">{{inv.descrizione}}</option>

              </select>
            </div>

            <div
              *ngIf="dettaglioAnagraficaForm.hasError('required', 'invalidita') && dettaglioAnagraficaFormSubmitted"
              class="alert alert-danger">Campo obbligatorio
            </div>

          </div>


          <!-- TUTELE GIURIDICE -->
          <div class="form-group field-24 has-feedback">
            <label>Tutela giuridica*</label>
            <div>
              <select class="form-control" name="tutelaGiuridica" formControlName="tutelaGiuridica"
                      [attr.disabled]="!hasWritePermission(mainUser)? false:null">
                <option value=""></option>
                <option [value]="tut.codice" *ngFor="let tut of listTuteleGiuridice">{{tut.descrizione}}</option>

              </select>
            </div>
            <div
              *ngIf="dettaglioAnagraficaForm.hasError('required', 'tutelaGiuridica') && dettaglioAnagraficaFormSubmitted"
              class="alert alert-danger">Campo obbligatorio
            </div>

          </div>


        </div>

        <!-- -->
        <hr *ngIf="isAnagraficaStrutturaFieldsVisible()">


        <div *ngIf="isAnagraficaStrutturaFieldsVisible()" class="group-inline">


          <!-- ATTUALMENTE SOTTOPOSTO -->
          <div class="form-group field-50 has-feedback">
            <label>Attualmente sottoposto a provvedimenti giudiziari o a una misura di sicurezza*</label>
            <div>
              <select class="form-control" name="attualmenteSottoposto" formControlName="attualmenteSottoposto"
                      [attr.disabled]="!hasWritePermission(mainUser)? false:null">
                <option value=""></option>
                <option [value]="pr.codice" *ngFor="let pr of listProvedimenti">{{pr.descrizione}}</option>

              </select>
            </div>
            <div
              *ngIf="dettaglioAnagraficaForm.hasError('required', 'attualmenteSottoposto') && dettaglioAnagraficaFormSubmitted"
              class="alert alert-danger">Campo obbligatorio
            </div>
          </div>

          <!-- PRECEDEMENTE INTERNATO -->
          <div class="form-group field-50 has-feedback">
            <label>Precedente internamento o detenzione in ambito Psichiatrico Penitenziario *</label>
            <div class="form-radio">
              <div class="radio">
                <input type="radio" name="precedentementeInternato" id="anagraficaPenitenziaria1" value="1"
                       formControlName="precedentementeInternato"
                       [attr.disabled]="!hasWritePermission(mainUser)? false:null">
                <label for="anagraficaPenitenziaria1">Sì</label>
              </div>
              <div class="radio">
                <input type="radio" name="precedentementeInternato" id="anagraficaPenitenziaria2" value="2"
                       formControlName="precedentementeInternato"
                       [attr.disabled]="!hasWritePermission(mainUser)? false:null">
                <label for="anagraficaPenitenziaria2">No</label>
              </div>
              <div class="radio">
                <input type="radio" name="precedentementeInternato" id="anagraficaPenitenziaria9" value="9"
                       formControlName="precedentementeInternato"
                       [attr.disabled]="!hasWritePermission(mainUser)? false:null">
                <label for="anagraficaPenitenziaria9">Non noto/Non risulta</label>
              </div>
            </div>

            <div
              *ngIf="dettaglioAnagraficaForm.hasError('required', 'precedentementeInternato') && dettaglioAnagraficaFormSubmitted"
              class="alert alert-danger">Campo obbligatorio
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>


  <!-- Contatti e prestazioni -->
  <!--Contatti non + in uso-->


  <block-ui>

  </block-ui>

  <!-- Toolbar -->
  <div *ngIf="isTabShowedAnagrafica()" class="btn-fixed fixed" demon-cf="toolbarFixed" demon-ev="true" demon-cf-id="6">
    <div class="btn-toolbar">
      <div class="container">
        <div class="btn-group-left">
          <button type="button" class="btn btn-outline-secondary" demon-bt="indietro" demon-cf="ModalConfirm"
                  demon-body="Tornando indietro perderai i dati inseriti nell'anagrafica, vuoi continuare comunque?"
                  demon-call="$D._sbObj.oanagrafica.evIndietro" demon-ev="true" demon-cf-id="7"
                  (click)="openGoBackDialog(content)">
            Torna alla lista
          </button>
        </div>

        <div class="btn-group-right">
          <!--button *ngIf="cancellaVisibile()" type="button" class="btn btn-outline-danger"
                  (click)="openCancellaDialog(cancella)">Cancella
          </button-->
        </div>
        <div class="btn-group-right">
          <button *ngIf="salvaVisibile()" type="button" class="btn btn-secondary" demon-bt="salva"
            (click)="saveAnagrafica()" [disabled]="dettaglioAnagraficaForm.pristine">Salva</button>
        </div>
      </div>
    </div>
  </div>

</div>

<ng-template #content let-modal>
  <div class="modal-header" style="background-color: #012346 !important; cursor: pointer;">
    <h4 class="modal-title" id="modal-basic-title_3" style="color: white;">Attenzione</h4>
    <a (click)="modal.dismiss('Cross click')">
      <svg class="s-icon icon-xxs">
        <use xlink:href="assets/icons/sprite.svg#close"></use>
      </svg>
    </a>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <label>Tornando indietro perderai i dati inseriti nell'anagrafica, vuoi continuare comunque?</label>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Back click')">Ok</button>
  </div>
</ng-template>

<ng-template #cancella let-modal>
  <div class="modal-header" style="background-color: #012346 !important; cursor: pointer;">
    <h4 class="modal-title" id="modal-basic-title_31" style="color: white;">Attenzione</h4>
    <a (click)="modal.dismiss('Cross click')">
      <svg class="s-icon icon-xxs">
        <use xlink:href="assets/icons/sprite.svg#close"></use>
      </svg>
    </a>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <label>Sei sicuro di voler cancellare i dati anagrafici:</label>
      </div>
      <div class="row">
        <label>ID: {{dettaglioAnagrafica!!.id!!}} - NOME:{{dettaglioAnagrafica!!.nome!!}} - COGNOME: {{dettaglioAnagrafica!!.cognome!!}} </label>
      </div>
      <div class="row" *ngIf="deleteWarningMessage">
        <label class="alert-warning" style="font-weight: bold">{{deleteWarningMessage}}</label>
      </div>
    </div>
  </div>
  <div class="modal-footer" style="align-self: end">
    <button type="button" class="btn btn-outline-danger" (click)="cancellaAnagrafica()">Conferma cancellazione</button>
  </div>
</ng-template>

<ng-template #audit let-modal>
  <div class="modal-header" style="background-color: #012346 !important; cursor: pointer;">
    <h4 class="modal-title" id="modal-basic-title" style="color: white;">Storico modifiche anagrafica {{dettaglioAnagrafica!!.id!!}}</h4>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="table-responsive">
          <div class="list-table table-even">
            <table class="table-lg">
              <thead>
                <tr>
                <th >Data</th>
                <th >Username</th>
                <th >Codice Fiscale</th>
                <th >Data nascita</th>
                <th >Cognome</th>
                <th >Nome</th>
                <th >Sesso</th>
                <th >Comune nascita</th>
                <th >Cittadinanza</th>
                <th >Regione residenza</th>
                <th >Comune residenza</th>
                <th >Asl residenza</th>
                <th >Stato estero residenza</th>
                <th >Distretto</th>
                <th >Municipio</th>
                <th >Stato civile</th>
                <th >
                  Collocazione socio ambientale
                </th>
                <th >Titolo di studio</th>
                <th >Attività</th>
                <th >Familiare</th>
                <th >Invalidità</th>
                <th >Tutela giuridica</th>
                <th >Precedentemente internato</th>
                <th >Attualmente sottoposto</th>
                <th >Id anonimo struttura</th>
                <th >Data eliminazione</th>
                <th >Eliminato</th>
              </tr>
              </thead>
              <tbody>
                <tr class="tr-hover" *ngFor="let item of auditAnagrafica">
                  <td>{{item.dataAudit | date:'yyyy-MM-dd hh:mm'}}</td>
                  <td>{{item.usernameAudit }}</td>
                  <td>{{item.codiceFiscale }}</td>
                  <td>{{item.dataNascita | date:'yyyy-MM-dd' }}</td>
                  <td>{{item.cognome }}</td>
                  <td>{{item.nome }}</td>
                  <td>{{item.sesso }}</td>
                  <td>{{item.descrizioneComuneNascita}}</td>
                  <td>{{item.cittadinanza}}</td>
                  <td>{{item.regioneResidenza}}</td>
                  <td>{{item.comuneResidenza}}</td>
                  <td>{{item.aslResidenza}}</td>
                  <td>{{item.statoResidenza}}</td>
                  <td>{{item.distrettoResidenza}}</td>
                  <td>{{item.municipioResidenza}}</td>
                  <td>{{item.statoCivile}}</td>
                  <td>{{item.collocazioneSocioAmbientale}}</td>
                  <td>{{item.titoloDiStudio}}</td>
                  <td>{{item.attivita}}</td>
                  <td>{{item.familiarePaziente}}</td>
                  <td>{{item.invalidita}}</td>
                  <td>{{item.tutelaGiuridica}}</td>
                  <td>{{item.precedentementeInternato}}</td>
                  <td>{{item.attualmenteSottoposto}}</td>
                  <td>{{item.idAnonimoStr}}</td>
                  <td>{{item.dataEliminazione | date:'yyyy-MM-dd hh:mm' }}</td>
                  <td>{{ item.eliminato ? 'Si' : 'No'}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('')">Ok</button>
  </div>
</ng-template>
