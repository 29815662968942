import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { LandingComponent } from './pages/landing/landing.component';
import { NopagefoundComponent } from './pages/nopagefound/nopagefound.component';
import {HeaderComponent} from "./components/header/header.component";
import {FooterComponent} from "./components/footer/footer.component";
import {AppRoutingModule} from "./app-routing.module";
import {KeycloakAngularModule, KeycloakService} from "keycloak-angular";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {HttpClientModule, HTTP_INTERCEPTORS} from "@angular/common/http";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import { DatePipe} from "@angular/common";
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { PrestazioniComponent } from './pages/prestazioni/prestazioni.component';
import { RicercaAnagraficaComponent } from './pages/ricerca-anagrafica/ricerca-anagrafica.component';
import { AnagraficaComponent } from './pages/anagrafica/anagrafica.component';
import { ContattoComponent } from './pages/contatto/contatto.component';
import {BlockUIModule} from "ng-block-ui";
import { ToastsComponent } from './components/toasts/toasts.component';
import {NgxPaginationModule} from "ngx-pagination";
import { TheadComponent } from './components/table/thead/thead.component';
import { PaginatorComponent } from './components/table/paginator/paginator.component';
import { initializer } from './util/app.init';
import { HttpInterceptorService } from './services/http-interceptor.service';
import { ApiService } from './services/api.service';
import { PreseInCaricoComponent } from './pages/prese-in-carico/prese-in-carico.component';
import { FlussiComponent } from './pages/flussi/flussi.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FlussiInIngressoComponent }from './pages/flussi-in-ingresso/flussi-in-ingresso.component';
import { ControlboardModule } from './modules/controlboard/controlboard.module';
import {DettaglioLottoComponent} from "./pages/dettaglio-lotto/dettaglio-lotto.component";
import { PresaInCaricoDettaglioModalComponent } from './pages/presa-in-carico-dettaglio-modal/presa-in-carico-dettaglio-modal.component';
import { DettaglioLottoUscitaComponent } from './pages/dettaglio-lotto-uscita/dettaglio-lotto-uscita.component';
import { InterventoComponent } from './pages/intervento/intervento.component';
import { InterventiTabComponent } from './pages/interventi-tab/interventi-tab.component';
import { DiagnosiScaleTabComponent } from './pages/diagnosi-scale-tab/diagnosi-scale-tab.component';
import { DiagnosiScaleComponent } from './pages/diagnosi-scale/diagnosi-scale.component';
import { PresenzeTabComponent } from './pages/presenze-tab/presenze-tab.component';
import { PresenzaComponent } from './pages/presenza/presenza.component';
import { DatiInIngressoComponent } from './pages/dati-in-ingresso/dati-in-ingresso.component';
import { DettaglioDatiInIngressoComponent } from './pages/dettaglio-dati-in-ingresso/dettaglio-dati-in-ingresso.component';
import { StoricoAnagraficaComponent } from './components/storico-anagrafica/storico-anagrafica.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { InvioDatiARegioneComponent } from './pages/invio-dati-a-regione/invio-dati-a-regione.component';
import { ProgettoDiInterventoComponent } from './components/progetto-di-intervento/progetto-di-intervento.component';
import { ImportazionedatiComponent } from './pages/importazionedati/importazionedati.component';
import {DynTableComponent} from "./components/dyn-table/dyn-table.component";
import { ContattiListComponent } from './pages/contatti-list/contatti-list.component';
import { StoricoAnagraficaEditComponent } from './components/storico-anagrafica-edit/storico-anagrafica-edit.component';
import { DocsComponent } from './components/docs/docs.component';
import { DocumentazioneComponent } from './pages/documentazione/documentazione.component';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    NopagefoundComponent,
    HeaderComponent,
    FooterComponent,
    DashboardComponent,
    PrestazioniComponent,
    RicercaAnagraficaComponent,
    DettaglioLottoComponent,
    AnagraficaComponent,
    ContattoComponent,
    ToastsComponent,
    TheadComponent,
    PaginatorComponent,
    PreseInCaricoComponent,
    FlussiComponent,
    FlussiInIngressoComponent,
    PresaInCaricoDettaglioModalComponent,
    DettaglioLottoUscitaComponent,
    InterventoComponent,
    InterventiTabComponent,
    DiagnosiScaleTabComponent,
    DiagnosiScaleComponent,
    PresenzeTabComponent,
    PresenzaComponent,
    DatiInIngressoComponent,
    DettaglioDatiInIngressoComponent,
    StoricoAnagraficaComponent,
    InvioDatiARegioneComponent,
    ProgettoDiInterventoComponent,
    ImportazionedatiComponent,
    DynTableComponent,
    ContattiListComponent,
    StoricoAnagraficaEditComponent,
    DocsComponent,
    DocumentazioneComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    BlockUIModule.forRoot(),
    NgxPaginationModule,
    KeycloakAngularModule,
    NgSelectModule,
    ControlboardModule,
    NgbDropdownModule,
    NgbAccordionModule
  ],
  providers: [DatePipe,
    {
      provide : APP_INITIALIZER,
      useFactory: initializer,
      deps: [ KeycloakService ],
      multi   : true,
    },

    {
      provide:HTTP_INTERCEPTORS,
      useClass:HttpInterceptorService,
      multi:true
    },
    ApiService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


