import { Component, OnInit } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {DomSanitizer} from "@angular/platform-browser";

interface Contenuto {
  link: string;
  video:string;
  nome: string;
  testo: string;
}

interface Dato {
  titolo: string;
  dati: Contenuto[];
}


@Component({
  selector: 'app-docs',
  templateUrl: './docs.component.html',
  styleUrls: ['./docs.component.css']
})
export class DocsComponent implements OnInit {
  dati: Dato[] | undefined;

  constructor(private http: HttpClient, public sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.http.get<Dato[]>('/assets/docs/docs.json').subscribe((data) => {
      this.dati = data;
    });
  }

  sanitize(video: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(video);
  }
}
