<footer class="footer bg-primary text-white" style="display: none;">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 py-2">
        <span class="pr-5">© Copyright</span>
        <span class="pr-5">Versione n° {{version}}</span>
        <span class="pr-5">Assistenza</span>
      </div>
    </div>
  </div>
</footer>
