import { DettaglioLotto, Page } from './../../model/Models';
import { SessionService } from "../../services/session.service";
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service'
import { Router } from '@angular/router';
import {ToastService} from "../../services/toast.service";
import {PresaInCaricoDettaglioModalComponent} from "../presa-in-carico-dettaglio-modal/presa-in-carico-dettaglio-modal.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-dettaglio-lotto',
  templateUrl: './dettaglio-lotto.component.html',
  styleUrls: ['./dettaglio-lotto.component.css']
})
export class DettaglioLottoComponent implements OnInit {

  flusso: string = "";
  struttura: string = "";
  data: string = "";
  attivita: string = "";
  semestre: string = "";
  id: number = 0;
  annoCompetenza: number = 0;
  pagina: Page<DettaglioLotto> = new Page<DettaglioLotto>();
  selectedDettaglio = new Set<DettaglioLotto>()
  motivazioneScarto:string = '';
  stato:string = '';

  constructor(private apiService: ApiService,
              private modalService: NgbModal,
              private toastService: ToastService,
              private sessionService: SessionService,private router: Router) { }

  ngOnInit(): void {

    this.loadDataFromSelectedLotto();
    this.getDettaglioLotto();
  }

  loadDataFromSelectedLotto() {
    this.sessionService.selectedLotto.subscribe(res => {
      var data = JSON.parse(res);
      this.flusso = "SISM";
      this.struttura = data.struttura;
      this.data = data.dataLotto;
      this.attivita = data.tipoPrestazione;
      this.semestre = data.semestreCompetenza;
      this.id = data.id;
      this.annoCompetenza = data.annoCompetenza;
      this.motivazioneScarto = data.motivazioneScarto;
      this.stato = data.stato;
    });

  }

  async getDettaglioLotto() {

    this.apiService.getDettaglioLottoAll(this.id, this.semestre, this.annoCompetenza, this.pagina.number,
      this.pagina.size).subscribe((data: any) => {

        this.pagina = data;

      });

  }

  cambiaPagina(event: any) {
    this.getDettaglioLotto();
  }

//TODO gestire i casi in cui le chiamate vanno in errore
  async saveDettaglioLotto() {
    for (let dettaglio of this.selectedDettaglio) {
      console.log(' ' + dettaglio.stato)
      await this.apiService.saveDettaglioLotto(this.id,  dettaglio.stato, dettaglio.id).toPromise()
    }
    console.log(' end ' )
  }


  async saveLotto() {
    await this.saveDettaglioLotto();

    await this.apiService.updateLotto(this.id, 'NON_ELABORATO',
      this.motivazioneScarto).toPromise();

    this.toastService.showSuccess('Salvataggio effettuato con successo');
  }

  async saveAndInviaRegioneLotto() {
    await this.saveDettaglioLotto();
    await this.apiService.updateLotto(this.id, 'ELABORATO',
      this.motivazioneScarto).toPromise();

    this.toastService.showSuccess('Salvataggio effettuato con successo');
  }

  navigateBack() {
    this.router.navigate(['flussi-in-ingresso']);
  }

  listaItems() {
    if (this.pagina) {
      return this.pagina.content;
    } else
      return [];
    }

  validatoChecked(item: DettaglioLotto) {
      return item.stato == 'VALIDATO';
  }

  scartatoChecked(item: DettaglioLotto) {
    return item.stato == 'SCARTATO_DA_ASL';
  }

  validatoClick(item: DettaglioLotto) {
     console.log(item.stato);
     item.stato = 'VALIDATO';
     console.log(item.stato);
     this.selectedDettaglio.add(item);
     console.log(this.selectedDettaglio.size);
  }

  scartatoClick(item: DettaglioLotto) {
      item.stato = 'SCARTATO_DA_ASL';
    this.selectedDettaglio.add(item);
    console.log(this.selectedDettaglio.size);
  }

  salvaDisabled() {
    return this.stato == 'ELABORATO';
  }

  openDettagloPresaInCarico( idDettaglioLotto: number) {
    this.apiService.getDettaglioPreseIncaricoByIdDettaglioLotto(idDettaglioLotto)
        .subscribe(res => {
            const modalRef = this.modalService.open(PresaInCaricoDettaglioModalComponent,
              {scrollable: true, ariaLabelledBy: 'modal-basic-title'});
                modalRef.componentInstance.instanceModalRef = modalRef;
                modalRef.componentInstance. dettaglioAnagrafica = res.anagrafica;
                modalRef.componentInstance.listContati = res.contatti
                modalRef.componentInstance.listPrestazioni = res.prestazioni
      modalRef.result.then((result) => {
        console.log(`Closed with: ${result}`);
      }, (reason) => {
      });
    });
  }
}
