<div [formGroup]="_groupPInt!" class="container"
     *ngIf="rispostaPrevalenteIs12() || rispostaPrevalenteIsIn123(rispostaPrevalente)">
  <!-- tittle -->
  <div class="row">

    <div class="title-5">
      Intervento effettuato durante il contatto
    </div>
  </div>


  <hr class="py-0 mt-4">

  <!-- first ie-section checks-->
  <div class="row" *ngIf="!rispostaPrevalenteIs3(rispostaPrevalente)">

    <div class="group-inline pt-4">

      <!-- tratamento psicoterapeutico -->
      <div class="form-group form-group-radio">
        <label style="max-width: 220px">Trattamento psicoterapeutico</label>

        <div class="form-radio">
          <div class="radio">
            <input type="radio" name="ieTrattamentoPsicoterapeutico"
                   id="ieTrattamentoPsicoterapeuticoSi" value="true"
                   formControlName="ieTrattamentoPsicoterapeutico"
            >
            <label for="ieTrattamentoPsicoterapeuticoSi">Sì</label>
          </div>
          <div class="radio">
            <input type="radio" name="ieTrattamentoPsicoterapeutico"
                   id="ieTrattamentoPsicoterapeuticoNo" value="false"
                   formControlName="ieTrattamentoPsicoterapeutico"
            >
            <label for="ieTrattamentoPsicoterapeuticoNo">No</label>
          </div>
        </div>


        <div *ngIf="_groupPInt!.hasError('required', 'ieTrattamentoPsicoterapeutico') && formSubmitted"
             class="alert alert-danger">Campo obbligatorio
        </div>


      </div>

      <!-- tratamento farmacologico -->
      <div class="form-group form-group-radio">
        <label style="max-width: 220px">Trattamento farmacologico</label>
        <div class="form-radio">
          <div class="radio">
            <input type="radio" name="ieTrattamentoFarmacologico"
                   id="ieTrattamentoFarmacologicoSi" value="true"
                   formControlName="ieTrattamentoFarmacologico"
            >
            <label for="ieTrattamentoFarmacologicoSi">Sì</label>
          </div>
          <div class="radio">
            <input type="radio" name="ieTrattamentoFarmacologico"
                   id="ieTrattamentoFarmacologicoNo" value="false"
                   formControlName="ieTrattamentoFarmacologico"
            >
            <label for="ieTrattamentoFarmacologicoNo">No</label>
          </div>
        </div>

        <div *ngIf="_groupPInt!.hasError('required', 'ieTrattamentoFarmacologico') && formSubmitted"
             class="alert alert-danger">Campo obbligatorio
        </div>
      </div>

      <!-- intervento riabilitativi -->
      <div class="form-group form-group-radio">
        <label style="max-width: 220px">Interventi riabilitativi</label>
        <div class="form-radio">
          <div class="radio">
            <input type="radio" name="ieInterventiRiabilitativi"
                   id="ieInterventiRiabilitativiSi" value="true"
                   formControlName="ieInterventiRiabilitativi"
            >
            <label for="ieInterventiRiabilitativiSi">Sì</label>
          </div>
          <div class="radio">
            <input type="radio" name="ieInterventiRiabilitativi"
                   id="ieInterventiRiabilitativiNo" value="false"
                   formControlName="ieInterventiRiabilitativi"
            >
            <label for="ieInterventiRiabilitativiNo">No</label>
          </div>
        </div>

        <div *ngIf="_groupPInt!.hasError('required', 'ieInterventiRiabilitativi') && formSubmitted"
             class="alert alert-danger">Campo obbligatorio
        </div>
      </div>

      <!-- inserimenti lavorativi -->
      <div class="form-group form-group-radio">
        <label style="max-width: 220px">Inserimenti lavorativi</label>
        <div class="form-radio">
          <div class="radio">
            <input type="radio" name="ieInserimentiLavorativi"
                   id="ieInserimentiLavorativiSi" value="true"
                   formControlName="ieInserimentiLavorativi"
            >
            <label for="ieInserimentiLavorativiSi">Sì</label>
          </div>
          <div class="radio">
            <input type="radio" name="ieInserimentiLavorativi"
                   id="ieInserimentiLavorativiNo" value="false"
                   formControlName="ieInserimentiLavorativi"
            >
            <label for="ieInserimentiLavorativiNo">No</label>
          </div>
        </div>

        <div *ngIf="_groupPInt!.hasError('required', 'ieInserimentiLavorativi') && formSubmitted"
             class="alert alert-danger">Campo obbligatorio
        </div>
      </div>

      <!-- interventi sociali -->
      <div class="form-group form-group-radio">
        <label style="max-width: 220px">Interv. sociale e/o di rete</label>
        <div class="form-radio">
          <div class="radio">
            <input type="radio" name="ieInterventoSociale"
                   id="ieInterventoSocialeSi" value="true"
                   formControlName="ieInterventoSociale"
            >
            <label for="ieInterventoSocialeSi">Sì</label>
          </div>
          <div class="radio">
            <input type="radio" name="ieInterventoSociale"
                   id="ieInterventoSocialeNo" value="false"
                   formControlName="ieInterventoSociale"
            >
            <label for="ieInterventoSocialeNo">No</label>
          </div>
        </div>

        <div *ngIf="_groupPInt!.hasError('required', 'ieInterventoSociale') && formSubmitted"
             class="alert alert-danger">Campo obbligatorio
        </div>
      </div>


      <!-- colloqui psichiatrici -->
      <div class="form-group form-group-radio">
        <label style="max-width: 220px">Colloqui psichiatrici e psicologici di sostegno</label>
        <div class="form-radio">
          <div class="radio">
            <input type="radio" name="ieColloquiSostegno" id="ieColloquiSostegnoSi"
                   value="true" formControlName="ieColloquiSostegno"
            >
            <label for="ieColloquiSostegnoSi">Sì</label>
          </div>
          <div class="radio">
            <input type="radio" name="ieColloquiSostegno" id="ieColloquiSostegnoNo"
                   value="false" formControlName="ieColloquiSostegno"
            >
            <label for="ieColloquiSostegnoNo">No</label>
          </div>
        </div>

        <div *ngIf="_groupPInt!.hasError('required', 'ieColloquiSostegno') && formSubmitted"
             class="alert alert-danger">Campo obbligatorio
        </div>
      </div>

      <!-- coinvolgimento sostegno familiari -->
      <div class="form-group form-group-radio">
        <label style="max-width: 220px">Coinvolgimento/sostegno ai familiari</label>
        <div class="form-radio">
          <div class="radio">
            <input type="radio" name="ieCoinvolgimentoFamiliari"
                   id="ieCoinvolgimentoFamiliariSi" value="true"
                   formControlName="ieCoinvolgimentoFamiliari"
            >
            <label for="ieCoinvolgimentoFamiliariSi">Sì</label>
          </div>
          <div class="radio">
            <input type="radio" name="ieCoinvolgimentoFamiliari"
                   id="ieCoinvolgimentoFamiliariNo" value="false"
                   formControlName="ieCoinvolgimentoFamiliari"
            >
            <label for="ieCoinvolgimentoFamiliariNo">No</label>
          </div>
        </div>

        <div *ngIf="_groupPInt!.hasError('required', 'ieCoinvolgimentoFamiliari') && formSubmitted"
             class="alert alert-danger">Campo obbligatorio
        </div>
      </div>

      <!-- accertamenti medico legali -->
      <div class="form-group form-group-radio">
        <label style="max-width: 220px">Accertamenti medico legali</label>
        <div class="form-radio">
          <div class="radio">
            <input type="radio" name="ieAccertamentiMedicoLegali"
                   id="ieAccertamentiMedicoLegaliSi" value="true"
                   formControlName="ieAccertamentiMedicoLegali"
            >
            <label for="ieAccertamentiMedicoLegaliSi">Sì</label>
          </div>
          <div class="radio">
            <input type="radio" name="ieAccertamentiMedicoLegali"
                   id="ieAccertamentiMedicoLegaliNo" value="false"
                   formControlName="ieAccertamentiMedicoLegali"
            >
            <label for="ieAccertamentiMedicoLegaliNo">No</label>
          </div>
        </div>

        <div *ngIf="_groupPInt!.hasError('required', 'ieAccertamentiMedicoLegali') && formSubmitted"
             class="alert alert-danger">Campo obbligatorio
        </div>
      </div>

      <!-- invio in struttura residenziale/semiresidenziale -->
      <div class="form-group form-group-radio">
        <label style="max-width: 220px">Invio in struttura residenziale/semires.</label>
        <div class="form-radio">
          <div class="radio">
            <input type="radio" name="ieInvioInStruttura" id="ieInvioInStrutturaSi"
                   value="true" formControlName="ieInvioInStruttura"
            >
            <label for="ieInvioInStrutturaSi">Sì</label>
          </div>
          <div class="radio">
            <input type="radio" name="ieInvioInStruttura" id="ieInvioInStrutturaNo"
                   value="false" formControlName="ieInvioInStruttura"
            >
            <label for="ieInvioInStrutturaNo">No</label>
          </div>
        </div>

        <div *ngIf="_groupPInt!.hasError('required', 'ieInvioInStruttura') && formSubmitted"
             class="alert alert-danger">Campo obbligatorio
        </div>


      </div>


      <hr class="py-0 mt-4"
          *ngIf="(this.invioInStrutturaSelected || this.ieInvioOspedaleSelected) && !rispostaPrevalenteIs3(rispostaPrevalente)">

      <!-- second ie-section checks -->
      <div class="row align-items-center"
           *ngIf="this.invioInStrutturaSelected && !rispostaPrevalenteIs3(rispostaPrevalente)">

        <div class="row">
          <div *ngIf="_groupPInt!.hasError('invioNotSelected', 'ieInvioInStruttura')"
               class="alert alert-danger">Si deve selezionare almeno una tra i valori degli
            invii
          </div>
        </div>

        <div class="group-inline pt-4">


          <!-- invio in struttura -->
          <div class="form-group form-group-radio">
            <label style="max-width: 220px">Invio struttura STIPT</label>
            <div class="form-radio">
              <div class="radio">
                <input type="radio" name="ieInvioStpit" id="ieInvioStpitSi" value="true"
                       formControlName="ieInvioStpit"
                >
                <label for="ieInvioStpitSi">Sì</label>
              </div>
              <div class="radio">
                <input type="radio" name="ieInvioStpit" id="ieInvioStpitNo"
                       value="false" formControlName="ieInvioStpit"
                >
                <label for="ieInvioStpitNo">No</label>
              </div>
            </div>

            <div *ngIf="_groupPInt!.hasError('required', 'ieInvioStpit') && formSubmitted"
                 class="alert alert-danger">Campo obbligatorio
            </div>
          </div>

          <!-- luogo struttura stpit -->
          <div class="form-group form-group-radio" *ngIf="ieInvioStpitSelected">
            <label style="max-width: 220px">Luogo struttura STIPT</label>
            <div class="form-radio">
              <div class="radio">
                <input type="radio" name="ieLuogoStpit" id="ieLuogoStpitRegione"
                       value="true" formControlName="ieLuogoStpit"
                >
                <label for="ieLuogoStpitRegione">Regione</label>
              </div>
              <div class="radio">
                <input type="radio" name="ieLuogoStpit" id="ieLuogoStpitFuoriRegione"
                       value="false" formControlName="ieLuogoStpit"
                >
                <label for="ieLuogoStpitFuoriRegione">Fuori regione</label>
              </div>
            </div>

            <div *ngIf="_groupPInt!.hasError('required', 'ieLuogoStpit') && formSubmitted"
                 class="alert alert-danger">Campo obbligatorio
            </div>
          </div>

          <!-- invio in struttura SRTRI -->
          <div class="form-group form-group-radio">
            <label style="max-width: 220px">Invio struttura SRTRI</label>
            <div class="form-radio">
              <div class="radio">
                <input type="radio" name="ieInvioSrtri" id="ieInvioSrtriSi" value="true"
                       formControlName="ieInvioSrtri"
                >
                <label for="ieInvioSrtriSi">Si</label>
              </div>
              <div class="radio">
                <input type="radio" name="ieInvioSrtri" id="ieInvioSrtriNo"
                       value="false" formControlName="ieInvioSrtri"
                >
                <label for="ieInvioSrtriNo">No</label>
              </div>
            </div>

            <div *ngIf="_groupPInt!.hasError('required', 'ieInvioSrtri') && formSubmitted"
                 class="alert alert-danger">Campo obbligatorio
            </div>
          </div>

          <!-- luogo struttura SRTRI -->
          <div class="form-group form-group-radio" *ngIf="ieInvioSrtriSelected">
            <label style="max-width: 220px">Luogo struttura SRTRI</label>
            <div class="form-radio">
              <div class="radio">
                <input type="radio" name="ieLuogoSrtri" id="ieLuogoSrtriSi" value="true"
                       formControlName="ieLuogoSrtri"
                >
                <label for="ieLuogoSrtriSi">Regione</label>
              </div>
              <div class="radio">
                <input type="radio" name="ieLuogoSrtri" id="ieLuogoSrtriNo"
                       value="false" formControlName="ieLuogoSrtri"
                >
                <label for="ieLuogoSrtriNo">Fuori Regione</label>
              </div>
            </div>

            <div *ngIf="_groupPInt!.hasError('required', 'ieLuogoSrtri') && formSubmitted"
                 class="alert alert-danger">Campo obbligatorio
            </div>
          </div>


          <!-- invio struttura SRTRE -->
          <div class="form-group form-group-radio">
            <label style="max-width: 220px">Invio struttura SRTRE</label>
            <div class="form-radio">
              <div class="radio">
                <input type="radio" name="ieInvioSrtre" id="ieInvioSrtreSi" value="true"
                       formControlName="ieInvioSrtre"
                       [attr.disabled]="!hasWritePermission(mainUser)? false:null">
                <label for="ieInvioSrtreSi">Si</label>
              </div>
              <div class="radio">
                <input type="radio" name="ieInvioSrtre" id="ieInvioSrtreNo"
                       value="false" formControlName="ieInvioSrtre"
                >
                <label for="ieInvioSrtreNo">No</label>
              </div>
            </div>

            <div *ngIf="_groupPInt!.hasError('required', 'ieInvioSrtre') && formSubmitted"
                 class="alert alert-danger">Campo obbligatorio
            </div>
          </div>

          <!-- luogo struttura SRTRE -->
          <div class="form-group form-group-radio" *ngIf="ieInvioSrtreSelected">
            <label style="max-width: 220px">Luogo struttura SRTRE</label>
            <div class="form-radio">
              <div class="radio">
                <input type="radio" name="ieLuogoSrtre" id="ieLuogoSrtreSi" value="true"
                       formControlName="ieLuogoSrtre"
                >
                <label for="ieLuogoSrtreSi">Regione</label>
              </div>
              <div class="radio">
                <input type="radio" name="ieLuogoSrtre" id="ieLuogoSrtreNo"
                       value="false" formControlName="ieLuogoSrtre"
                >
                <label for="ieLuogoSrtreNo">Fuori regione</label>
              </div>
            </div>

            <div *ngIf="_groupPInt!.hasError('required', 'ieLuogoSrtre') && formSubmitted"
                 class="alert alert-danger">Campo obbligatorio
            </div>
          </div>

          <!-- invio struttura srs24 -->
          <div class="form-group form-group-radio">
            <label style="max-width: 220px">Invio struttura SRSR24</label>
            <div class="form-radio">
              <div class="radio">
                <input type="radio" name="ieInvioSrsr24" id="ieInvioSrsr24Si"
                       value="true" formControlName="ieInvioSrsr24"
                >
                <label for="ieInvioSrsr24Si">Si</label>
              </div>
              <div class="radio">
                <input type="radio" name="ieInvioSrsr24" id="ieInvioSrsr24No"
                       value="false" formControlName="ieInvioSrsr24"
                >
                <label for="ieInvioSrsr24No">No</label>
              </div>
            </div>

            <div *ngIf="_groupPInt!.hasError('required', 'ieInvioSrsr24') && formSubmitted"
                 class="alert alert-danger">Campo obbligatorio
            </div>
          </div>

          <!-- luogo struttura SRSR24 -->
          <div class="form-group form-group-radio" *ngIf="ieInvioSrsr24Selected">
            <label style="max-width: 220px">Luogo struttura SRSR24</label>
            <div class="form-radio">
              <div class="radio">
                <input type="radio" name="ieLuogoSrsr24" id="ieLuogoSrsr24Si"
                       value="true" formControlName="ieLuogoSrsr24"
                >
                <label for="ieLuogoSrsr24Si">Regione</label>
              </div>
              <div class="radio">
                <input type="radio" name="ieLuogoSrsr24" id="ieLuogoSrsr24No"
                       value="false" formControlName="ieLuogoSrsr24"
                >
                <label for="ieLuogoSrsr24No">Fuori regione</label>
              </div>
            </div>

            <div *ngIf="_groupPInt!.hasError('required', 'ieLuogoSrsr24') && formSubmitted"
                 class="alert alert-danger">Campo obbligatorio
            </div>
          </div>


          <!-- invio struttura SRSR12 -->
          <div class="form-group form-group-radio">
            <label style="max-width: 220px">Invio struttura SRSR12</label>
            <div class="form-radio">
              <div class="radio">
                <input type="radio" name="ieInvioSrsr12" id="ieInvioSrsr12Si"
                       value="true" formControlName="ieInvioSrsr12"
                >
                <label for="ieInvioSrsr12Si">Si</label>
              </div>
              <div class="radio">
                <input type="radio" name="ieInvioSrsr12" id="ieInvioSrsr12No"
                       value="false" formControlName="ieInvioSrsr12"
                >
                <label for="ieInvioSrsr12No">No</label>
              </div>
            </div>

            <div *ngIf="_groupPInt!.hasError('required', 'ieInvioSrsr12') && formSubmitted"
                 class="alert alert-danger">Campo obbligatorio
            </div>
          </div>


          <!-- Luogo struttura SRSR12 -->
          <div class="form-group form-group-radio" *ngIf="ieInvioSrsr12Selected">
            <label style="max-width: 220px">Luogo struttura SRSR12</label>
            <div class="form-radio">
              <div class="radio">
                <input type="radio" name="ieLuogoSrsr12" id="ieLuogoSrsr12Si"
                       value="true" formControlName="ieLuogoSrsr12"
                >
                <label for="ieLuogoSrsr12Si">Regione</label>
              </div>
              <div class="radio">
                <input type="radio" name="ieLuogoSrsr12" id="ieLuogoSrsr12No"
                       value="false" formControlName="ieLuogoSrsr12"
                >
                <label for="ieLuogoSrsr12No">Fuori regione</label>
              </div>
            </div>

            <div *ngIf="_groupPInt!.hasError('required', 'ieLuogoSrsr12') && formSubmitted"
                 class="alert alert-danger">Campo obbligatorio
            </div>
          </div>
        </div>

        <div class="group-inline pt-4">

          <!-- invio in struttura gruppo appartamento (GA) -->
          <div class="form-group form-group-radio">
            <label style="max-width: 220px">Invio in struttura gruppo appartamento (GA)</label>
            <div class="form-radio">
              <div class="radio">
                <input type="radio" name="ieInvioGa" id="ieInvioGaSi" value="true"
                       formControlName="ieInvioGa"
                >
                <label for="ieInvioGaSi">Si</label>
              </div>
              <div class="radio">
                <input type="radio" name="ieInvioGa" id="ieInvioGaNo" value="false"
                       formControlName="ieInvioGa"
                >
                <label for="ieInvioGaNo">No</label>
              </div>
            </div>

            <div *ngIf="_groupPInt!.hasError('required', 'ieInvioGa') && formSubmitted"
                 class="alert alert-danger">Campo obbligatorio
            </div>
          </div>

          <!-- Luogo struttura gruppo appartamento (GA) -->
          <div class="form-group form-group-radio" *ngIf="ieInvioGaSelected">
            <label style="max-width: 220px">Luogo struttura gruppo appartamento (GA)</label>
            <div class="form-radio">
              <div class="radio">
                <input type="radio" name="ieLuogoGa" id="ieLuogoGaSi" value="true"
                       formControlName="ieLuogoGa"
                >
                <label for="ieLuogoGaSi">Regione</label>
              </div>
              <div class="radio">
                <input type="radio" name="ieLuogoGa" id="ieLuogoGaNo" value="false"
                       formControlName="ieLuogoGa"
                >
                <label for="ieLuogoGaNo">Fuori regione</label>
              </div>
            </div>

            <div *ngIf="_groupPInt!.hasError('required', 'ieLuogoGa') && formSubmitted"
                 class="alert alert-danger">Campo obbligatorio
            </div>
          </div>


          <!-- Invio in struttura centro diurno (CD) -->
          <div class="form-group form-group-radio">
            <label style="max-width: 220px">Invio in struttura centro diurno (CD)</label>
            <div class="form-radio">
              <div class="radio">
                <input type="radio" name="ieInvioCd" id="ieInvioCdSi" value="true"
                       formControlName="ieInvioCd"
                >
                <label for="ieInvioCdSi">Si</label>
              </div>
              <div class="radio">
                <input type="radio" name="ieInvioCd" id="ieInvioCdNo" value="false"
                       formControlName="ieInvioCd"
                >
                <label for="ieInvioCdNo">No</label>
              </div>
            </div>

            <div *ngIf="_groupPInt!.hasError('required', 'ieInvioCd') && formSubmitted"
                 class="alert alert-danger">Campo obbligatorio
            </div>
          </div>

          <!-- Luogo struttura centro diurno (CD) -->
          <div class="form-group form-group-radio" *ngIf="ieInvioCdSelected">
            <label style="max-width: 220px">Luogo struttura centro diurno (CD)</label>
            <div class="form-radio">
              <div class="radio">
                <input type="radio" name="ieLuogoCd" id="ieLuogoCdSi" value="true"
                       formControlName="ieLuogoCd"
                >
                <label for="ieLuogoCdSi">Regione</label>
              </div>
              <div class="radio">
                <input type="radio" name="ieLuogoCd" id="ieLuogoCdNo" value="false"
                       formControlName="ieLuogoCd"
                >
                <label for="ieLuogoCdNo">Fuori regione</label>
              </div>
            </div>

            <div *ngIf="_groupPInt!.hasError('required', 'ieLuogoCd') && formSubmitted"
                 class="alert alert-danger">Campo obbligatorio
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Invio in ospedale -->
    <div class="form-group form-group-radio">
      <label style="max-width: 220px">Invio in ospedale</label>
      <div class="form-radio">
        <div class="radio">
          <input type="radio" name="ieInvioOspedale" id="ieInvioOspedaleSi"
                 value="true" formControlName="ieInvioOspedale"
          >
          <label for="ieInvioOspedaleSi">Si</label>
        </div>
        <div class="radio">
          <input type="radio" name="ieInvioOspedale" id="ieInvioOspedaleNo"
                 value="false" formControlName="ieInvioOspedale"
          >
          <label for="ieInvioOspedaleNo">No</label>
        </div>
      </div>

      <div *ngIf="_groupPInt!.hasError('required', 'ieInvioOspedale') && formSubmitted"
           class="alert alert-danger">Campo obbligatorio
      </div>
    </div>


    <div class="group-inline pt-4" *ngIf="ieInvioOspedaleSelected && !rispostaPrevalenteIs3(rispostaPrevalente)">

      <!-- Reparto ospedaliero psichiatrico (Rep.40) -->
      <div class="form-group  form-group-radio">
        <label style="max-width: 220px">Reparto ospedaliero psichiatrico (Rep.40)</label>
        <div class="form-radio">
          <div class="radio">
            <input type="radio" name="ieInvioOspedaleRep40"
                   id="ieInvioOspedaleRep40Si" value="true"
                   formControlName="ieInvioOspedaleRep40"
            >
            <label for="ieInvioOspedaleRep40Si">Si</label>
          </div>
          <div class="radio">
            <input type="radio" name="ieInvioOspedaleRep40"
                   id="ieInvioOspedaleRep40No" value="false"
                   formControlName="ieInvioOspedaleRep40"
            >
            <label for="ieInvioOspedaleRep40No">No</label>
          </div>
        </div>

        <div *ngIf="_groupPInt!.hasError('required', 'ieInvioOspedaleRep40') && formSubmitted"
             class="alert alert-danger">Campo obbligatorio
        </div>
      </div>


      <div class="form-group form-group-radio">
        <label style="max-width: 220px">Luogo struttura ospedaliera</label>
        <div class="form-radio">
          <div class="radio">
            <input type="radio" name="ieLuogoOspedale" id="ieLuogoOspedaleSi"
                   value="true" formControlName="ieLuogoOspedale"
            >
            <label for="ieLuogoOspedaleSi">Regione</label>
          </div>
          <div class="radio">
            <input type="radio" name="ieLuogoOspedale" id="ieLuogoOspedaleNo"
                   value="false" formControlName="ieLuogoOspedale"
            >
            <label for="ieLuogoOspedaleNo">Fuori regione</label>
          </div>
        </div>

        <div *ngIf="_groupPInt!.hasError('required', 'ieLuogoOspedale') && formSubmitted"
             class="alert alert-danger">Campo obbligatorio
        </div>
      </div>
    </div>
    <!-- consultazione valutazione -->
    <div class="form-group form-group-radio">
      <label style="max-width: 220px">Consultazione valutazione</label>
      <div class="form-radio">
        <div class="radio">
          <input type="radio" name="ieConsultazioneValutazione"
                 id="ieConsultazioneValutazioneSi" value="true"
                 formControlName="ieConsultazioneValutazione"
          >
          <label for="ieConsultazioneValutazioneSi">Si</label>
        </div>
        <div class="radio">
          <input type="radio" name="ieConsultazioneValutazione"
                 id="ieConsultazioneValutazioneNo" value="false"
                 formControlName="ieConsultazioneValutazione"
          >
          <label for="ieConsultazioneValutazioneNo">No</label>
        </div>
      </div>

      <div *ngIf="_groupPInt!.hasError('required', 'ieConsultazioneValutazione') && formSubmitted"
           class="alert alert-danger">Campo obbligatorio
      </div>
    </div>

    <div class="form-group form-group-altro">


      <!-- <div class="form-group field-wide"> -->
      <label style="max-width: 220px">Altro</label>
      <input type="text" style="width: 80%" class="form-control" name="ieAltro" formControlName="ieAltro"
      >
      <!-- </div> -->

      <div *ngIf="_groupPInt!.hasError('required', 'ieAltro') && formSubmitted"
           class="alert alert-danger">Campo obbligatorio
      </div>


    </div>

  </div>


  <div *ngIf="rispostaPrevalenteIs3(rispostaPrevalente)" class="row">
    <div class="form-group form-group-radio">
      <label style="max-width: 220px">Consultazione valutazione</label>
      <div class="form-radio">
        <div class="radio">
          <input type="radio" name="ieConsultazioneValutazione"
                 id="ieConsultazioneValutazioneSi" value="true"
                 formControlName="ieConsultazioneValutazione"
          >
          <label for="ieConsultazioneValutazioneSi">Si</label>
        </div>
        <div class="radio">
          <input type="radio" name="ieConsultazioneValutazione"
                 id="ieConsultazioneValutazioneNo" value="false"
                 formControlName="ieConsultazioneValutazione"
          >
          <label for="ieConsultazioneValutazioneNo">No</label>
        </div>
      </div>

      <div *ngIf="_groupPInt!.hasError('required', 'ieConsultazioneValutazione') && formSubmitted"
           class="alert alert-danger">Campo obbligatorio
      </div>
    </div>
    <div class="form-group form-group-altro">


      <!-- <div class="form-group field-wide"> -->
      <label style="max-width: 220px">Altro</label>
      <input type="text" style="width: 80%" class="form-control" name="ieAltro" formControlName="ieAltro"
      >
      <!-- </div> -->

      <div *ngIf="_groupPInt!.hasError('required', 'ieAltro') && formSubmitted"
           class="alert alert-danger">Campo obbligatorio
      </div>


    </div>

  </div>

</div>
