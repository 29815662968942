import { PreseInCarico, Page, Comune, Invalidita, TutelaGiuridica, Provedimento, RichiestaPrevalente, Inviante, PrecedenteContatto, Diagnosi, ModalitaConclusione, TipoPrestazione, ModalitaErogazione, Operatore, SedeIntervento, Utente } from './../../model/Models';
import { ApiService } from 'src/app/services/api.service';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DettaglioAnagrafica, Contatto, Prestazione, StatoCivile, Cittadinanza, StatoEstero, Regione, Distretto, CollazioneSocioAmbientale, Asl, TitoloStudio, Ativitta } from '../../model/Models';
import { firstValueFrom, Observable } from 'rxjs';
import { SessionService } from '../../services/session.service';
import { ToastService } from "../../services/toast.service";
import { PresaInCaricoDettaglioModalComponent } from "../presa-in-carico-dettaglio-modal/presa-in-carico-dettaglio-modal.component";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap/modal/modal-ref";
@Component({
  selector: 'app-prese-in-carico',
  templateUrl: './prese-in-carico.component.html',
  styleUrls: ['./prese-in-carico.component.css']
})
export class PreseInCaricoComponent implements OnInit {

  currentYear = new Date().getFullYear();
  yearArray: any = [];
  ricercaForm!: FormGroup;
  codiciDsm: any = [];
  pagina: Page<PreseInCarico> = new Page<PreseInCarico>();

  isMasterSel: boolean = false;
  preseInCaricoList: any;
  checkedPreseInCaricoList: any = [];
  isEnableTrasmetti: boolean = false


  dettaglioAnagrafica?: DettaglioAnagrafica
  listContati?: Contatto[]
  listPrestazioni: Prestazione[] = []
  closeResult = ""

  listStatiCivili?: StatoCivile[]

  listStati: StatoEstero[] = []

  listRegioni: Regione[] = []

  listComuni: Comune[] = []

  listDistretti: Distretto[] = []

  listAsl: Asl[] = []

  listCollazioneSoci: CollazioneSocioAmbientale[] = []

  listTitoliDiStudio: TitoloStudio[] = []

  listAttivita: Ativitta[] = []

  listInvalidita: Invalidita[] = []

  listTuteleGiuridice: TutelaGiuridica[] = []

  listProvedimenti: Provedimento[] = []

  listRichiestePrevalenti: RichiestaPrevalente[] = []

  listInvianti: Inviante[] = []

  listPrecedentiContatti: PrecedenteContatto[] = []

  listaModalitaConclusione: ModalitaConclusione[] = []


  listTipoPrestazione: TipoPrestazione[] = []

  listOperatori: Operatore[] = []



  listSedeIntervento: SedeIntervento[] = []

  contatto?: Contatto

  utente?: Utente

  constructor(private apiService: ApiService, private modalService: NgbModal, private sessionService: SessionService,
    public toastService: ToastService,) {
    this.getCheckedItemList();
  }

  ngOnInit(): void {

    this.ricercaFormInit();
    this.populateYearArray();
    this.getCodiciDsm();
    this.getPreseInCarico();



  }

  private populateYearArray() {

    for (let i = this.currentYear - 1; i >= 1900; i--) {

      this.yearArray.push(i);

    }

  }

  private ricercaFormInit() {

    this.ricercaForm = new FormGroup({
      stato: new FormControl(""),
      attivita: new FormControl(""),
      anno: new FormControl(""),
      periodo: new FormControl(""),
      codiceDSM: new FormControl(""),
    });


    this.ricercaForm.get('stato')?.setValue("DA_TRASMETTERE");
    this.ricercaForm.get('periodo')?.setValue("S2");
    this.ricercaForm.get('anno')?.setValue(this.currentYear);
  }

  resetFormRicerca() {

    this.ricercaForm.get('stato')?.setValue("DA_TRASMETTERE");
    this.ricercaForm.get('attivita')?.setValue("");
    this.ricercaForm.get('anno')?.setValue(this.currentYear);
    this.ricercaForm.get('periodo')?.setValue("S1");
    this.ricercaForm.get('codiceDSM')?.setValue("");

  }

  async getPreseInCarico() {
    var stato = this.ricercaForm.get('stato')?.value;
    var attivita = this.ricercaForm.get('attivita')?.value;
    var anno = Number(this.ricercaForm.get('anno')?.value);
    var periodo = this.ricercaForm.get('periodo')?.value;
    var codiceDsm = this.ricercaForm.get('codiceDSM')?.value;
    this.apiService.getPreseIncarico(
      stato,
      attivita,
      anno,
      periodo,
      codiceDsm,
      this.pagina.number,
      this.pagina.size
    ).subscribe(data => {
      this.pagina = data;

      this.isAllNonCheckable()

    })
  }

  cambiaPagina(event: any) {

    this.getPreseInCarico();

  }

  getCodiciDsm() {
    this.apiService.getAllSociSanitari().subscribe(data => {

      this.codiciDsm = data;
    });
  }

  trasmetti() {
    var stato = this.ricercaForm.get('stato')?.value;
    var attivita = this.ricercaForm.get('attivita')?.value;
    var anno = Number(this.ricercaForm.get('anno')?.value);
    var periodo = this.ricercaForm.get('periodo')?.value;
    var listAnagrafica = JSON.parse(this.checkedPreseInCaricoList).map((x: any) => x).join(",");


    this.apiService.trasmettiPreseInCarico(stato, attivita, anno, periodo, listAnagrafica)
      .subscribe(data => {
        console.log("Trasmessi")
        this.toastService.showSuccess('Trasmissione effettuata con successo');
        this.getPreseInCarico();
      });

  }

  checkUncheckAll() {
    for (var i = 0; i < this.pagina.content.length; i++) {
      this.pagina.content[i].isSelected = this.isMasterSel;
    }
    this.getCheckedItemList();
  }

  isAllSelected() {
    this.isMasterSel = this.pagina.content.every(function (item: any) {
      return item.isSelected == true;
    })
    this.getCheckedItemList();
  }

  getCheckedItemList() {
    this.checkedPreseInCaricoList = [];
    for (var i = 0; i < this.pagina.content.length; i++) {
      if (this.pagina.content[i].isSelected)
        this.checkedPreseInCaricoList.push(this.pagina.content[i].idAnagrafica);
    }
    this.checkedPreseInCaricoList = JSON.stringify(this.checkedPreseInCaricoList);
    this.enableTrasmettiButton();
  }
  enableTrasmettiButton() {

    if (JSON.parse(this.checkedPreseInCaricoList).length > 0) {

      this.isEnableTrasmetti = true;
    } else {
      this.isEnableTrasmetti = false;
    }
  }


  async loadSelectedAngrafica(id: number) {
    this.dettaglioAnagrafica = await firstValueFrom(this.apiService.getAnagrafica(id))

  }

  async loadContattiOfSelectedAnagrafica(id: number) {

    this.apiService.getAllAnagraficaContatti(id).subscribe(res => {

      this.listContati = res

      this.loadAllPrestazioniDegliContatti(this.listContati)
    })


  }

  loadAllPrestazioniDegliContatti(listContati: Contatto[]) {

    listContati.forEach(contato => {
      contato.prestazioniList?.forEach(prestazione => {
        prestazione.contatto = contato
        prestazione.dataInserimento = new Date(prestazione.dataInserimento!!)
        this.listPrestazioni.push(prestazione)
        this.listPrestazioni.forEach(pres => {
          console.log(pres.contatto?.id);

        })
        this.filterPrestazioniByDataInserimento(this.listPrestazioni)
      })
    })
  }

  filterPrestazioniByDataInserimento(listPrestazioni: Prestazione[]) {
    listPrestazioni = this.listPrestazioni.sort(
      (prA, prB) => prB.dataInserimento!!.getTime() - prA.dataInserimento!!.getTime(),
    );
  }

  openDettagloPresaInCarico(content: any, id: number) {
    var stato = this.ricercaForm.get('stato')?.value;
    var attivita = this.ricercaForm.get('attivita')?.value;
    var anno = Number(this.ricercaForm.get('anno')?.value);
    var periodo = this.ricercaForm.get('periodo')?.value;
    var codiceDsm = this.ricercaForm.get('codiceDSM')?.value;

    this.apiService.getDettaglioPreseIncarico(
      stato,
      attivita,
      anno,
      periodo,
      codiceDsm,
      id).subscribe(res => {
        const modalRef = this.modalService.open(PresaInCaricoDettaglioModalComponent,
          { scrollable: true, ariaLabelledBy: 'modal-basic-title' });
        modalRef.componentInstance.instanceModalRef = modalRef;
        modalRef.componentInstance.dettaglioAnagrafica = res.anagrafica;
        modalRef.componentInstance.listContati = res.contatti
        modalRef.componentInstance.listPrestazioni = res.prestazioni

        modalRef.result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
          if (this.closeResult === 'Closed with: Back click') { }
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      });
  }

  loadDialogModalData(id: number) {
    this.loadAnagraficaData(id)
    this.loadContattiData()
    this.loadPrestazioniData()
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  loadAnagraficaData(id: number) {

    // ANAGRAFICA

    this.getStatiCivili()

    this.getAllStati()

    this.getAllRegioni()

    this.getAllComuni()

    this.getAllDistretti()

    this.getAllCollazioneSocioAmbientali()

    this.getAllAsl()

    this.getAllTitoliDiStudio()

    this.getAllAttivitta()

    this.getAllInvalidita()

    this.getAllTuteleGiuridice()

    this.getAllProvedimenti()

    this.loadSelectedAngrafica(id)

    this.loadContattiOfSelectedAnagrafica(id)

  }

  loadContattiData() {

    // CONTATTO


    this.getAllPrecedentiContatti()

    this.getAllModalitaConclusione()

    this.getRichiestePrevalenti()

    this.getInviantiTerritoriali()

    this.getInviantiSemiResidenziali()

  }

  loadPrestazioniData() {




    this.loadOperatori()

    this.loadSedeInterventoTerritoriali()

    this.loadSedeInterventoSemiresidenziali()



  }

  // ANAGRAFICA
  async getStatiCivili() {
    this.apiService.getAllStatiCivili().subscribe(res => {
      this.listStatiCivili = res

    })
  }

  async getAllStati() {

    this.apiService.getAllStatiEsteri().subscribe(res => {
      this.listStati = res
    })

  }

  async getAllRegioni() {
    this.apiService.getAllRegioni().subscribe(res => {

      this.listRegioni = res
    })
  }

  async getAllComuni() {
    this.apiService.getAllComuni().subscribe(res => {
      this.listComuni = res
    })
  }


  async getAllDistretti() {
    this.apiService.getAllDistretti().subscribe(res => {
      this.listDistretti = res
    })

  }

  async getAllAsl() {
    this.apiService.getAllAsl().subscribe(res => {
      this.listAsl = res
    })

  }

  async getAllCollazioneSocioAmbientali() {
    this.apiService.getAllCollazioeSocioAmbientali().subscribe(res => {

      this.listCollazioneSoci = res
    })

  }

  async getAllTitoliDiStudio() {
    this.apiService.getAllTitoliStudio().subscribe(res => {
      this.listTitoliDiStudio = res
    })

  }

  async getAllAttivitta() {
    this.apiService.getAllAtivitta().subscribe(res => {
      this.listAttivita = res
    })
  }

  async getAllInvalidita() {

    this.apiService.getAllInvalidita().subscribe(res => {
      this.listInvalidita = res
    })

  }

  async getAllTuteleGiuridice() {

    this.apiService.getAllTuteleGiuridice().subscribe(res => {
      this.listTuteleGiuridice = res
    })

  }

  async getAllProvedimenti() {

    this.apiService.getAllProvedimenti().subscribe(res => {
      this.listProvedimenti = res
    })

  }


  // CONTATTO


  async getAllPrecedentiContatti() {
    this.apiService.getAllPrecedentiContatti().subscribe(res => {
      this.listPrecedentiContatti = res
    })
  }

  async getAllModalitaConclusione() {
    this.apiService.getAllModalitaConclusione().subscribe(res => {
      this.listaModalitaConclusione = res
    })
  }

  async getRichiestePrevalenti() {
    this.apiService.getAllRichiestePrevalenti().subscribe(res => {
      this.listRichiestePrevalenti = res
    })
  }

  async getInviantiTerritoriali() {

    this.apiService.getAllInviantiTerritoriale().subscribe(res => {
      this.listInvianti = res
    })


  }

  async getInviantiSemiResidenziali() {

    this.apiService.getAllInviantiSemiresidenziali().subscribe(res => {
      this.listInvianti = res
    })


  }

  // PRESTAZIONI


  async loadOperatori() {

    this.apiService.getAllOperatori().subscribe(res => {
      this.listOperatori = res


    })

  }

  async loadSedeInterventoTerritoriali() {

    this.apiService.getAllSedeinterventoTerritoriali().subscribe(res => {
      let sede = res

      this.mergeSedeIntervento(sede)

    })

  }

  async loadSedeInterventoSemiresidenziali() {

    this.apiService.getAllSedeinterventoSemiresidenziali().subscribe(res => {
      let sede = res
      this.mergeSedeIntervento(sede)

    })

  }

  mergeSedeIntervento(listSede: SedeIntervento[]) {

    this.listSedeIntervento = [...listSede]
  }


  // ANAGRAFICA NAME
  setStatoCivileName(codice: string): string | null {

    let stato = null

    this.listStatiCivili?.forEach(st => {

      if (st.codice == codice) {

        stato = st.descrizione;

      }

    })

    return stato
  }

  setStatoName(codice: string): string | null {

    let stato = null

    this.listStati?.forEach(st => {

      if (st.alpha2 == codice) {

        stato = st.descrizione;

      }

    })

    return stato
  }

  setSessoName(sesso: string) {
    if (sesso === 'M') {
      return "Maschio"
    } else {
      return "Femmina"
    }
  }

  setRegioneName(codice: string): string | null {

    let regione = null
    this.listRegioni.forEach(reg => {
      if (codice == reg.codRegione) {
        regione = reg.regione
      }
    })

    return regione
  }

  setComuneName(codice: string): string | null {
    let comune = null
    this.listComuni.forEach(com => {

      if (codice == com.codice) {
        comune = com.descrizione
      }
    })

    return comune
  }

  setDistrettoName(codice: string): string | null {
    let distretto = null
    this.listDistretti.forEach(dist => {

      if (codice == dist.codice) {
        distretto = dist.descrizione
      }
    })

    return distretto
  }

  setAslName(codice: string): string | null {
    let asl = null
    this.listAsl.forEach(as => {

      if (codice == as.codiceAzienda) {
        asl = as.denominazioneAzienda
      }
    })

    return asl
  }

  setCollazioneSocioAmbientaleName(codice: string): string | null {
    let collazione = null
    this.listCollazioneSoci.forEach(coll => {

      if (codice == coll.codice) {
        collazione = coll.descrizione
      }
    })

    return collazione
  }

  setTitoloDiStudioName(codice: string): string | null {
    let titolo = null
    this.listTitoliDiStudio.forEach(ts => {

      if (codice == ts.codice) {
        titolo = ts.descrizione
      }
    })

    return titolo
  }

  setAttivitaName(codice: string): string | null {
    let attivita = null
    this.listAttivita.forEach(ativita => {

      if (codice == ativita.codice) {
        attivita = ativita.descrizione
      }
    })

    return attivita
  }

  setInvaliditaName(codice: string): string | null {
    let invalidita = null
    this.listInvalidita.forEach(inv => {

      if (codice == inv.codice) {
        invalidita = inv.descrizione
      }
    })

    return invalidita
  }

  setFamigliareInTratamentoName(codice: string) {

    if (codice === "1") {
      return "Si"
    } else if (codice === "2") {
      return "No"
    } else {
      return "Non noto/Non risulta"
    }


  }

  setTuteleGiuridiceName(codice: string): string | null {
    let tutela = null
    this.listTuteleGiuridice.forEach(tut => {

      if (codice == tut.codice) {
        tutela = tut.descrizione
      }
    })

    return tutela
  }

  setProvedimentiName(codice: string): string | null {
    let provedimento = null
    this.listProvedimenti.forEach(prv => {

      if (codice == prv.codice) {
        provedimento = prv.descrizione
      }
    })

    return provedimento
  }

  setPrecedemnteInternatoName(codice: string) {

    if (codice === "1") {
      return "Si"
    } else if (codice === "2") {
      return "No"
    } else {
      return "Non noto/Non risulta"
    }


  }



  // CONTATTO NAME

  setRichiestaPrevName(codice: string): string | null {
    let richiesta = null
    this.listRichiestePrevalenti.forEach(rp => {

      if (codice == rp.codice) {
        richiesta = rp.descrizione
      }
    })

    return richiesta
  }

  setPrecedentiContatiName(codice: string): string | null {
    let precedenteContatto = null
    this.listPrecedentiContatti.forEach(pc => {

      if (codice == pc.codice) {
        precedenteContatto = pc.descrizione
      }
    })

    return precedenteContatto
  }

  setInviantiName(codice: string): string | null {
    let inviante = null
    this.listInvianti.forEach(inv => {

      if (codice == inv.codice) {
        inviante = inv.descrizione
      }
    })

    return inviante
  }

  setModalitaConclusioneName(codice: string): string | null {
    let modalita = null
    this.listaModalitaConclusione.forEach(mc => {

      if (codice == mc.codice) {
        modalita = mc.descrizione
      }
    })

    return modalita
  }

  // PRESTAZIONI NAME

  setModalitaErrogazioneName(codice: string) {

    if (codice === "1") {
      return "Programmato"
    } else if (codice === "2") {
      return "Non programmato"
    } else {
      return ""
    }

  }

  setSedeName(codice: string): string | null {
    let seda = null
    this.listSedeIntervento.forEach(sd => {

      if (codice == sd.codice) {
        seda = sd.descrizione
      }
    })

    return seda
  }

  setOperatore1Name(codice: string) {

    let operatore = null
    this.listOperatori.forEach(op => {

      if (codice == op.codice) {
        operatore = op.descrizione
      }
    })

    return operatore

  }

  isUtenteStruttura() {
    this.utente = this.sessionService.getUtenteFromStorage()!!

    if (this.utente.nomeRuolo == 'operatore-struttura') {
      return true
    } else {
      return false
    }
  }


  isStattoTrasmesso(presaInCarico: PreseInCarico) {

    if (presaInCarico.stato == "Trasmesso") {
      return true
    } else {
      return false
    }
  }

  isAllNonCheckable() {

    return this.pagina.content.every(pr => pr.stato != "Trasmesso")
    
  }

}


