import {Injectable, TemplateRef} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  toasts: any[] = [];
  constructor() { }


  show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.toasts.push({ textOrTpl, ...options });
  }

  remove(toast: any) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }

  showProblemaTecnico( ) {
    let textOrTpl: string | TemplateRef<any> =  'Attenzione, si è verificato un problema tecnico';
    let options: any = { classname: 'bg-danger text-light', delay: 10000 ,header: 'ATTENZIONE !!!', error:true};
    if(this.toasts.length==0){
      this.toasts.push(
        { textOrTpl, ...options }
      );
    }
  }

  showError( textOrTpl:string) {
    let options: any = { classname: 'bg-danger text-light', delay: 10000 , header: 'ATTENZIONE !!!', error:true};
    if(this.toasts.length==0){
      this.toasts.push(
        { textOrTpl, ...options }
      );
    }
  }

  showSuccess( textOrTpl:string) {
    let options: any = { classname: 'bg-success text-light', delay: 10000, header: 'OPERAZIONE ESEGUITA CON SUCCESSO !', success:true };
    if(this.toasts.length==0){
      this.toasts.push(
        { textOrTpl, ...options }
      );
    }
  }
}
