import { PresenzaAudit } from './../../model/Models';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Route, Router } from '@angular/router';
import { ModalDismissReasons, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Funzionalita } from 'src/app/model/Funzionalita';
import { Contatto, DettaglioAnagrafica, Presenza, Prestazione, Utente } from 'src/app/model/Models';
import { ApiService } from 'src/app/services/api.service';
import { SessionService } from 'src/app/services/session.service';
import { ToastService } from 'src/app/services/toast.service';
import { dataNonValidaValidator } from 'src/app/util/codicefiscale.validators';
import {find, firstValueFrom} from "rxjs";

@Component({
  selector: 'app-presenza',
  templateUrl: './presenza.component.html',
  styleUrls: ['./presenza.component.css']
})
export class PresenzaComponent implements OnInit {


  anagrafica!: DettaglioAnagrafica

  contatto!: Contatto

  presenzaForm!:FormGroup

  minDate?: NgbDateStruct

  maxDate = { year: 2035, month: 1, day: 1 }

  dateModel!: NgbDateStruct

  closeResult = ''

  formSubmitted = false;

  mainUser!: Utente

  presenza!:Presenza

  listPrestazioni:Prestazione[] = []

  listDate: string[] = []

  enableStatiAttuali: Array<number> = [1,2,4,7,9,0];

  @BlockUI()
  blockUI!: NgBlockUI;


  constructor(private sessionService:SessionService,private router:Router,
    private toastService: ToastService, private modalService: NgbModal,private apiService:ApiService) { }

  async ngOnInit(): Promise<void> {


    this.loadUser()

    this.loadAnagrafica()

    this.loadContatto()

    await this.getListOfNotAvailableDates()

    this.loadPresenzaForm()

    this.loadPresenza()

    this.observeFormForDuplicateDates()

    this.observeStrutturaChange()

    this.limitMaxDate()

    //controllo che per le date inserite sia possibile aggiungere una nuova presenza
    if (this.presenza.id == null) {
      if (this.contattoHasPresenze()) {
        let ultimaPresenzaNonEliminata = this.getUltimaPresenzaNonEliminata(this.contatto.presenzaList!!);
        if (ultimaPresenzaNonEliminata != null
          && this.sessionService.equalsDate(
            new Date(
              ultimaPresenzaNonEliminata.dataPresenza!), new Date())) {
//              console.log('Impossibile inserire');
          this.toastService.showError('Attenzione: per la data odierna è già stata aggiunta una presenza');
          this.back();
        }
      }
    }
  }

  loadAnagrafica() {

    this.sessionService.selectedAnagrafica.subscribe(res => {

      this.anagrafica = JSON.parse(res)
    })
  }

  loadUser() {
    this.mainUser = this.sessionService.getUtenteFromStorage()!!
  }

  loadDateModels(contatto:Contatto){

    let data = this.sessionService.convertToDateStruct(new Date(contatto.dataApertura!!))

    this.minDate = {year:data?.year!!, month:data?.month!!,day:data?.day!!}

  }


  mustDisableForm(){


      if(this.presenza.statoAttuale.id && this.presenza.statoAttuale.id >= 0 ){

      return this.enableStatiAttuali.indexOf(this.presenza.statoAttuale.id) == -1

    }

    return false;

 }

  mustDisableCancelBtn() {

    if (this.presenza.statoAttuale.id && this.presenza.statoAttuale.id > 0) {

      let statiAttuali: Array<number> = [1, 2, 4, 7, 9];
      return statiAttuali.indexOf(this.presenza.statoAttuale.id) == -1

    }

    return true;

  }


  loadContatto() {

    this.sessionService.selectedContatto.subscribe(res => {
      this.contatto = res
      this.loadDateModels(this.contatto)

    })
  }





  loadPresenzaForm(){

    this.presenzaForm = new FormGroup({

      data: new FormControl("", [Validators.required, dataNonValidaValidator()]),
      modPresenza: new FormControl("", [Validators.required]),
      tipoStruttura:new FormControl("", [Validators.required])
    })

    this.presenzaForm.get('tipoStruttura')?.patchValue("2");



  }


  loadPresenza(){


    this.sessionService.selectedPresenza.subscribe(res => {

      this.presenza = res



      if(!this.presenzaIsNew(this.presenza)){

        this.setFormValues(this.presenza)

        this.loadPrestazioniOfPresenza(this.presenza.id!!)
      }

      if(this.presenzaIsNew(this.presenza) && this.contattoHasPresenze()){
        const ultimaPresenzaNonEliminata = this.getUltimaPresenzaNonEliminata(this.contatto.presenzaList!!);

    //    console.log( 'ultimaPresenzaNonEliminata ' + JSON.stringify(ultimaPresenzaNonEliminata) );
        if(ultimaPresenzaNonEliminata!=null){
          this.limitDateByPresenzaDate( ultimaPresenzaNonEliminata )
        }
      }
      if(this.mustDisableForm()){
        this.mustDisableForm();
      }
    })
  }

  disableForm(){
    this.presenzaForm.get('modPresenza')?.disable();
    this.presenzaForm.get('tipoStruttura')?.disable();

  }

  async getListOfNotAvailableDates() {
    this.listDate = await firstValueFrom(this.sessionService.listPresenzeDataNotAvailable);
  }

  contattoHasPresenze(){

//    return this.contatto.presenzaList?.length != 0
    return this.getUltimaPresenzaNonEliminata(this.contatto.presenzaList!)!=null;
  }


  getUltimaPresenzaNonEliminata(presenzas: Presenza[]): Presenza|null{
      let res: Presenza|null = null;

      for(let i = presenzas.length-1 ; i>= 0; i--) {
          if(presenzas[i].eliminato == false ){
              if(res == null
                || res.dataPresenza! < presenzas[i].dataPresenza!) {
                res = presenzas[i];
              }
          }
      }
      return  res;
  }

  setFormValues(presenza:Presenza){

    this.dateModel = this.sessionService.convertToDateStruct(new Date(presenza.dataPresenza!!))!!

    this.presenzaForm.get('modPresenza')?.patchValue(presenza.modPresenza)

    this.presenzaForm.get('tipoStruttura')?.patchValue(presenza.tipoSemires)
  }

  submitForm(values:any){
    this.formSubmitted = true

    if(this.presenzaForm.valid){

      let presenza = new Presenza()
      let cs = this.sessionService.getSelectedStrutturaFromStorage()?.codice
      if(this.presenza.id != null){
        presenza.id = this.presenza.id;
      }
      presenza.dataPresenza = this.sessionService.toNativeDate(values.data)!!
      presenza.modPresenza = values.modPresenza
      presenza.tipoSemires = values.tipoStruttura
      this.contatto.anagrafica = this.anagrafica
      presenza.contatto = this.contatto
      presenza.codiceAslOperatoreInserimento = this.contatto.codiceAslOperatoreInserimento

      this.blockUI.start("Salvataggio in corso")
      this.apiService.savePresenza(presenza, cs!!).subscribe({
        next:(res) => {

          this.presenza = res

          this.sessionService.setSelectedPresenza(this.presenza)
          this.toastService.showSuccess('Salvataggio effettuato con successo');
          this.refreshData()
          this.back();


        },

        error:(err) => {

          console.log(err);
          this.toastService.showError('Correggere gli errori di compilazione');


        },
        complete:()=> {

          this.blockUI.stop()
        }
      })

    } else {
      this.toastService.showError('Correggere gli errori di compilazione');
    }
  }

  hasWritePermission(utente: Utente): boolean {

    if (utente.funzionalita.includes(Funzionalita.ANAGRAFICHE_INSERIMENTO)) {
      return true
    } else {
      return false
    }

  }

  getTitleName(): string {

    if (this.anagrafica.nome === "") {
      return "Nuova Prestazione"
    } else {
      return "" + this.anagrafica.cognome + " " + this.anagrafica.nome + ' (CF: ' + this.anagrafica.codiceFiscale + ")"
    }
  }

  navigateToAnagrafica() {
    this.router.navigate(['contatto'])
  }


  openGoBackDialog(content: any) {

    if (!this.presenzaForm.dirty) {

      this.back()

    } else {

      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;

        if (this.closeResult === 'Closed with: Back click') {
          this.back()

        }
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;


      });
    }

  }

  getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  openCancellaDialog(cancella: any) {

    this.modalService.open(cancella, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

      if (this.closeResult === 'Closed with: Back click') {
        this.refreshData();
        this.back()

      }
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;

    });


  }

  cancellaVisibile(): Boolean {

    if (this.presenza.id != null && this.salvaVisibile()) {

      return true
    } else {
      return false
    }

  }

  salvaVisibile() {

    return this.mainUser.funzionalita.includes(Funzionalita.ANAGRAFICHE_INSERIMENTO);
  }

  back() {

    this.sessionService.setOpenTabContatto("TabPresenze")
    this.router.navigate(['contatto'])

  }

  cancellaPresenza(id: number) {

    this.modalService.dismissAll()

    this.apiService.cancellaPresenza(id).subscribe({

      next: (res) => {

        this.toastService.showSuccess("presenza con id " + res + " cancellata con successo")
        this.refreshData();
        this.back();
      },
      error: (err) => {

        console.log(err);
        this.toastService.showError("e successo un problema durante la cancellazione")
      },
      complete: () => {



      }
    })
    console.log("id for delete " ,id);

    // this.blockUI.start("Cancellazione  in corso")

  }

  refreshData(){

    this.sessionService.setRefreshdata(true)
  }

  presenzaIsNew(presenza:Presenza){

    return presenza.id == null || presenza.id == undefined
  }

  observeStrutturaChange(){


    this.sessionService.strutturaChanged.subscribe(res => {

      if(res){
        this.router.navigate(['ricerca-anagrafica'])
      }
    })
  }

  limitDateByPresenzaDate(presenza:Presenza) {
    // let tempDate = new Date(presenza.dataPresenza!!);
    let tempDate = new Date(this.contatto?.dataApertura!!);
    tempDate.setDate( tempDate.getDate() + 1 );

    let minDateModel = this.sessionService.convertToDateStruct(tempDate);
    this.minDate = { year: minDateModel!!.year, month: minDateModel!!.month, day: minDateModel!!.day }
  }

  limitMaxDate(){

    let todayDate = this.sessionService.convertToDateStruct(new Date())

    if(this.contatto?.dataChiusura){

      let dataChiusuraContatto = this.sessionService.convertToDateStruct(new Date(this.contatto.dataChiusura!!));
      this.maxDate = {year:dataChiusuraContatto?.year!!, month:dataChiusuraContatto?.month!!, day:dataChiusuraContatto?.day!!}

    } else {

      this.maxDate = {year:todayDate?.year!!, month:todayDate?.month!!, day:todayDate?.day!!}

  }


  }

  observeFormForDuplicateDates() {

    this.presenzaForm.get('data')?.valueChanges.subscribe(val => {

      this.dateExistInDates(val)
    })
  }

  dateExistInDates(date: NgbDateStruct) {
    // @ts-ignore
    var d = new Date(this.presenza.dataPresenza)
    var ngbDateStruct = { year: d.getUTCFullYear(),  month: d.getUTCMonth() + 1, day: d.getDate() };
    this.listDate = this.removeItem(this.listDate, JSON.stringify(ngbDateStruct));
    // let index = this.listDate.indexOf(JSON.stringify(ngbDateStruct));
    let exist = this.listDate.indexOf(JSON.stringify(date)) !== -1
    if (exist === true) {
      this.presenzaForm.get('data')?.setErrors({ dateExist: true })
    } else {
      this.presenzaForm.get('data')?.setErrors({ dateExist: null })
      this.presenzaForm.controls['data'].updateValueAndValidity({onlySelf:true, emitEvent:false})
    }
  }

  removeItem(array: any, item: string){
    var i = array.length;
    while(i--){
      if(array[i] === item){
        array.splice(i, 1);
      }
    }
    return array;
  }
  loadPrestazioniOfPresenza(id:number){

    this.apiService.getPrestazioniByPresenzaId(id).subscribe({

      next:(res) => {

        this.listPrestazioni= res



      }
    });

  }

  public OPERATORE_STRUTTURA: String = "operatore-struttura";
  public OPERATORE_ASL: String = "operatore-asl";
  public OPERATORE_DSM: String = "operatore-dsm";
  public OPERATORE_REGIONE: String = "operatore-regione";
  auditPresenza: PresenzaAudit[] = [];
  storicoTitle: any;
  public getRuolo(ruolo: String) {

    if (this.mainUser) {

      if (this.mainUser.nomeRuolo == ruolo) {

        return true;

      }

    }

    return false;

 }


 openAuditDialog(audit: any){

    this.storicoTitle = "";
  this.apiService.getPresenzaAuditByIdAndStruttura(this.presenza?.id!!, this.sessionService.getSelectedStrutturaFromStorage()?.codice!).subscribe(res => {

    if(res){

      this.auditPresenza = res;

    }

  });

  this.modalService.open(audit, { ariaLabelledBy: 'modal-basic-title', size: 'xl', scrollable: true}).result.then((result) => {

    this.closeResult = `Closed with: ${result}`;

    if (this.closeResult === 'Closed with: Back click') {

      this.back()

    }

  }, (reason) => {

    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;

  });

 }

}
