import {Component, Input, OnInit} from '@angular/core';
import {ApiService} from "../../services/api.service";
import {DettaglioAnagrafica} from "../../model/Models";
import {firstValueFrom, Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {SessionService} from "../../services/session.service";

@Component({
  selector: 'app-storico-anagrafica',
  templateUrl: './storico-anagrafica.component.html',
  styleUrls: ['./storico-anagrafica.component.css']
})
export class StoricoAnagraficaComponent implements OnInit {

  @Input() idContatto: number | undefined;
  dettaglioAnagrafica: DettaglioAnagrafica|undefined;
  private destroy: Subject<any> = new Subject();
  constructor(private apiService: ApiService, private sessionService: SessionService,) {

  }
  ngOnInit(){
    this.loadAnagrafica().then(r => {});
  }
  async loadAnagrafica(): Promise<void>{
    const res = await firstValueFrom(this.sessionService.selectedAnagrafica.pipe(takeUntil(this.destroy)));
    this.dettaglioAnagrafica = this.idContatto?await firstValueFrom(this.apiService.getAnagraficaStorica(this.idContatto)):JSON.parse(res);
  }

}
