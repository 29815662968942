import { FlussiInIngressoComponent } from './pages/flussi-in-ingresso/flussi-in-ingresso.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {LandingComponent} from "./pages/landing/landing.component";
import {NopagefoundComponent} from "./pages/nopagefound/nopagefound.component";
import {PrestazioniComponent} from "./pages/prestazioni/prestazioni.component";
import {DashboardComponent} from "./pages/dashboard/dashboard.component";
import {RicercaAnagraficaComponent} from "./pages/ricerca-anagrafica/ricerca-anagrafica.component";
import {AnagraficaComponent} from "./pages/anagrafica/anagrafica.component";
import {ContattoComponent} from "./pages/contatto/contatto.component";
import { PreseInCaricoComponent } from './pages/prese-in-carico/prese-in-carico.component';
import { FlussiComponent } from './pages/flussi/flussi.component';
import { DettaglioLottoComponent } from './pages/dettaglio-lotto/dettaglio-lotto.component';
import {DettaglioLottoUscitaComponent} from "./pages/dettaglio-lotto-uscita/dettaglio-lotto-uscita.component";
import { InterventoComponent } from './pages/intervento/intervento.component';
import { DiagnosiScaleComponent } from './pages/diagnosi-scale/diagnosi-scale.component';
import { PresenzaComponent } from './pages/presenza/presenza.component';
import { DatiInIngressoComponent } from './pages/dati-in-ingresso/dati-in-ingresso.component';
import { DettaglioDatiInIngressoComponent } from './pages/dettaglio-dati-in-ingresso/dettaglio-dati-in-ingresso.component';
import { InvioDatiARegioneComponent } from './pages/invio-dati-a-regione/invio-dati-a-regione.component';
import {ImportazionedatiComponent} from "./pages/importazionedati/importazionedati.component";
import {ContattiListComponent} from "./pages/contatti-list/contatti-list.component";
import {DocumentazioneComponent} from "./pages/documentazione/documentazione.component";
const routes: Routes = [
  {path:'', redirectTo:'/dashboard', pathMatch:'full'},
  {path:'landing', component:LandingComponent},
  {path:'prestazione', component:PrestazioniComponent},
  {path:'intervento-form', component:InterventoComponent},
  {path:'diagnosiescale-form', component:DiagnosiScaleComponent},
  {path:'presenza-form', component:PresenzaComponent},
  {path:'dashboard', component:DashboardComponent},
  {path:'ricerca-anagrafica', component:RicercaAnagraficaComponent},
  {path:'anagrafica', component:AnagraficaComponent},
  {path:'contattiList', component:ContattiListComponent},
  {path:'contatto', component:ContattoComponent},
  {path:'prese-in-carico', component:PreseInCaricoComponent},
  {path:'flussi', component:FlussiComponent},
  {path:'flussi-in-ingresso', component:FlussiInIngressoComponent},
  {path:'dati-in-ingresso',  component:DatiInIngressoComponent},
  {path:'invio-dati-a-regione', component:InvioDatiARegioneComponent},
  {path:'dettaglio-lotto', component:DettaglioLottoComponent},
  {path:'dettaglio-lotto-uscita', component:DettaglioLottoUscitaComponent},
  {path:'dettaglio-dati-in-ingresso/:id/:stato', component:DettaglioDatiInIngressoComponent},
  {path:'importazioneDati', component:ImportazionedatiComponent},
  {path:'documentazione', component:DocumentazioneComponent},
  {path:'**', component:NopagefoundComponent},

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' } )],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule { }
