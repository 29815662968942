<div class="tab-pane fade active show" id="anagrafica" role="tabpanel"
     aria-labelledby="anagrafica-tab">


  <div class="group-inline pt-4" style="padding: 10px">
    <!-- datanascita -->
    <div class="group-inline pt-4">
      <div class="form-group field-16 has-feedback">
        <label style="font-weight: bold">Codice fiscale: <span
          class="bordodati">{{dettaglioAnagrafica?.codiceFiscale?.toUpperCase() ?? ' N.D.'}}</span></label>
      </div>
      <div class="form-group field-10 has-feedback">
        <label style="font-weight: bold">Data di nascita: <span
          class="bordodati">{{dettaglioAnagrafica?.dataNascita | date:'yyyy-MM-dd'}}</span></label>
      </div>
      <div class="form-group field-20 has-feedback">
        <label style="font-weight: bold">Cognome: <span
          class="bordodati">{{dettaglioAnagrafica?.cognome?.toUpperCase() ?? ' N.D.'}}</span></label>
      </div>
      <div class="form-group field-20 has-feedback">
        <label style="font-weight: bold">Nome: <span
          class="bordodati">{{dettaglioAnagrafica?.nome?.toUpperCase() ?? ' N.D.'}}</span></label>
      </div>
      <div class="form-group has-feedback">
        <label style="font-weight: bold">Sesso: <span
          class="bordodati">{{dettaglioAnagrafica?.sesso == 'M' ? 'MASCHIO' : (dettaglioAnagrafica?.sesso == 'F' ? 'FEMMINA' : 'Non noto/Non risulta')}}</span></label>
      </div>
      <!-- COMUNE nascita TYPEAHEAD -->
      <div class="form-group field-20">
        <label style="font-weight: bold">Comune di nascita: <span
          class="bordodati">{{dettaglioAnagrafica?.descrizioneComuneNascita?.toUpperCase() ?? ' N.D.'}}</span></label>
      </div>
    </div>

  </div>
  <div *ngIf="dettaglioAnagrafica?.cfCertificato! == '1'">
    <!-- stato civile citadinanza-->
    <div class="group-inline" style="padding: 10px">
      <!-- citadinanza -->
      <div class="form-group field-20 has-feedback">
        <label style="font-weight: bold">Cittadinanza: <span
          class="bordodati">{{dettaglioAnagrafica?.cittadinanza?.toUpperCase() ?? ' N.D.'}}</span></label>
      </div>
    </div>
    <div class="group-inline pt-4" style="padding: 10px">
      <!-- Regione -->
      <div class="form-group field-24 has-feedback">
        <label style="font-weight: bold">Regione: <span
          class="bordodati">{{dettaglioAnagrafica?.regioneResidenza?.toUpperCase() ?? ' N.D.'}}</span></label>
      </div>
      <!-- Comune residenza -->
      <div class="form-group field-20 has-feedback" *ngIf="dettaglioAnagrafica?.comuneResidenza">
        <label style="font-weight: bold">Comune: <span
          class="bordodati">{{dettaglioAnagrafica?.comuneResidenza?.toUpperCase() ?? ' N.D.'}}</span></label>
      </div>
      <!-- Stato -->
      <div class="form-group field-24 has-feedback" *ngIf="dettaglioAnagrafica?.statoResidenza">

        <label style="font-weight: bold">Stato: <span
          class="bordodati">{{dettaglioAnagrafica?.statoResidenza?.toUpperCase() ?? ' N.D.'}}</span></label>
      </div>
      <!-- Asl -->
      <div class="form-group field-16 has-feedback" *ngIf="dettaglioAnagrafica?.aslResidenza">
        <label style="font-weight: bold">ASL: <span
          class="bordodati">{{dettaglioAnagrafica?.aslResidenza?.toUpperCase() ?? ' N.D.'}}</span></label>
      </div>
      <!-- Distretto -->
      <div class="form-group field-20 has-feedback" *ngIf="dettaglioAnagrafica?.distrettoResidenza">
        <label style="font-weight: bold">Distretto: <span
          class="bordodati">{{dettaglioAnagrafica?.distrettoResidenza?.toUpperCase() ?? ' N.D.'}}</span></label>
      </div>
      <!-- Municipio -->
      <div class="form-group field-20 has-feedback demon-related demon-hide"
           *ngIf="dettaglioAnagrafica?.municipioResidenza">
        <label style="font-weight: bold">Municipio: <span
          class="bordodati">{{dettaglioAnagrafica?.municipioResidenza?.toUpperCase() ?? ' N.D.'}}</span></label>
      </div>
    </div>
  </div>
  <form [formGroup]="dettaglioAnagraficaForm" autocomplete="off" *ngIf="loaded">
    <div *ngIf="dettaglioAnagrafica?.cfCertificato! === '2'">
      <div class="form-group field-20 has-feedback">
        <label>Cittadinanza: </label>
        <div>
          <select class="form-control" name="anagraficaCittadinanza" formControlName="cittadinanza">
            <option value=""></option>
            <option [value]="cit.alpha2" *ngFor="let cit of listCitadiNanza">{{cit.descrizione}}</option>
          </select>
        </div>
      </div>
      <hr>
      <div class="title-5">
        Residenza
      </div>
      <div class="group-inline pt-4">
        <div class="form-group field-24 has-feedback">
          <label>Regione * </label>
          <div>
            <select class="form-control" name="regioneResidenza" formControlName="regioneResidenza"
                    (change)="setRegioneCodice($event)">
              <!--          -->
              <option value=""></option>
              <option [value]="reg.codRegione" *ngFor="let reg of listRegione">{{reg.regione}}</option>
            </select>
          </div>
          <div
            *ngIf="dettaglioAnagraficaForm.controls['regioneResidenza'].invalid"
            class="alert alert-danger">
            <div *ngIf="dettaglioAnagraficaForm.controls['regioneResidenza'].errors">
              Campo obbligatorio
            </div>
          </div>
        </div>
        <!-- Stato -->
        <div class="form-group field-24 has-feedback"
             *ngIf="(dettaglioAnagrafica?.statoResidenzaCod && dettaglioAnagrafica?.statoResidenzaCod != 'IT') || showStatoResidenza">
          <label>Stato*</label>
          <div>
            <select class="form-control" name="statoResidenza" formControlName="statoResidenza"
                    (change)="setState($event)">
              <option value=""></option>
              <option [value]="se.alpha2" *ngFor="let se of listStatiesteri">{{se.descrizione}}</option>
            </select>
          </div>
          <div
            *ngIf="dettaglioAnagraficaForm.controls['statoResidenza'].invalid"
            class="alert alert-danger">
            <div *ngIf="dettaglioAnagraficaForm.controls['statoResidenza'].errors">
              Campo obbligatorio
            </div>
          </div>
        </div>
        <div class="form-group field-20 has-feedback"
             *ngIf="(dettaglioAnagrafica?.comuneResidenzaCod != null && dettaglioAnagrafica?.comuneResidenzaCod != '') || showComuneResidenza">
          <label>Comune * </label>
          <div>
            <!--input id="typeahead-focus2" type="text" class="form-control"
                   [(ngModel)]="modelDescrizioneComuneResidenza?.descrizione" [ngbTypeahead]="comuneResidenzaSearch"
                   (focus)="focus$.next($any($event).target.value)"
                   (click)="click$.next($any($event).target.value);" (selectItem)="setComuneCodice($event)"
                   #instance="ngbTypeahead"
                   [inputFormatter]="formatter" [resultFormatter]="formatter" name="comuneResidenza" [editable]="false"
                   formControlName="comuneResidenza"/-->
            <input id="typeahead-focus2" type="text" class="form-control"
                   [ngbTypeahead]="comuneResidenzaSearch"
                   name="comuneResidenza"
                   [resultFormatter]="resFormatter"
                   [inputFormatter]="inFormatter"
                   (selectItem)="setComuneCodice($event)"
                   formControlName="comuneResidenza"/>
          </div>
          <div
            *ngIf="dettaglioAnagraficaForm.controls['comuneResidenza'].invalid"
            class="alert alert-danger">
            <div *ngIf="dettaglioAnagraficaForm.controls['comuneResidenza'].errors">
              Campo obbligatorio
            </div>
          </div>
        </div>
        <div class="form-group field-16 has-feedback" *ngIf="dettaglioAnagrafica?.aslResidenza || showAslResidenza">
          <label>ASL *</label>
          <div>
            <select class="form-control" name="aslResidenza" formControlName="aslResidenza"
                    (change)="setAslResidenza($event)">
              <option value=""></option>
              <option [value]="asl.codiceAzienda" *ngFor="let asl of listAsl">{{asl.denominazioneAzienda}}
              </option>

            </select>
          </div>
          <div
            *ngIf="dettaglioAnagraficaForm.controls['aslResidenza'].invalid "
            class="alert alert-danger">
            <div *ngIf="dettaglioAnagraficaForm.controls['aslResidenza'].errors">
              Campo obbligatorio
            </div>
          </div>
        </div>
        <div class="form-group field-20 has-feedback demon-related"
             *ngIf="dettaglioAnagrafica?.distrettoResidenza || showDistrettoResidenza">
          <label>Distretto socio sanitario *</label>
          <div>
            <select class="form-control" name="distrettoResidenza" formControlName="distrettoResidenza"
                    style="min-width: 350px !important;"
                    (change)="setDistrettoResidenza($event)">
              <option value=""></option>
              <option [value]="dis.codice" *ngFor="let dis of listDistretti">{{dis.descrizione}}</option>
            </select>
          </div>
          <div
            *ngIf="dettaglioAnagraficaForm.controls['distrettoResidenza'].invalid "
            class="alert alert-danger">
            <div *ngIf="dettaglioAnagraficaForm.controls['distrettoResidenza'].errors">
              Campo obbligatorio
            </div>
          </div>
        </div>
        <div class="form-group field-20 has-feedback demon-related demon-hide" demon-related="residenzaComune"
             demon-value="5775" *ngIf="(dettaglioAnagrafica?.municipioResidenza || showMunicipioResidenza) && listMunicipi.length>0">
          <label>Municipio *</label>
          <div>
            <select class="form-control" name="municipioResidenza" formControlName="municipioResidenza"
                    style="min-width: 550px !important;">
              <option value=""></option>
              <option [value]="mun.codice" *ngFor="let mun of listMunicipi">{{mun.descrizione}}</option>
            </select>
          </div>
          <div
            *ngIf="dettaglioAnagraficaForm.controls['municipioResidenza'].invalid "
            class="alert alert-danger">
            <div *ngIf="dettaglioAnagraficaForm.controls['municipioResidenza'].errors">
              Campo obbligatorio
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr>
    <div class="group-inline">
      <div class="form-group field-18 has-feedback">
        <label>Stato civile *</label>
        <div>
          <select class="form-control" name="statoCivile" formControlName="statoCivile">
            <option value=""></option>
            <option [value]="se.codice" *ngFor="let se of listStatiCivili">{{se.descrizione}}</option>
          </select>
        </div>
        <div
          *ngIf="dettaglioAnagraficaForm.controls['statoCivile'].invalid && (dettaglioAnagraficaForm.controls['statoCivile'].dirty || dettaglioAnagraficaForm.controls['statoCivile'].touched)"
          class="alert alert-danger">
          <div *ngIf="dettaglioAnagraficaForm.controls['statoCivile'].errors">
            Campo obbligatorio
          </div>
        </div>
      </div>
      <!-- COLLAZIONE SOCIO AMBIANTALE  -->
      <div class="form-group field-60 has-feedback">
        <label>Collocazione socio ambientale *</label>
        <div>
          <select class="form-control" name="collocazioneSocioAmbientale"
                  formControlName="collocazioneSocioAmbientale"
          >
            <option value=""></option>
            <option [value]="coll.codice" *ngFor="let coll of listCollazioneSocioAmbientali">
              {{coll.descrizione}}</option>
          </select>
        </div>
        <div
          *ngIf="dettaglioAnagraficaForm.controls['collocazioneSocioAmbientale'].invalid && (dettaglioAnagraficaForm.controls['collocazioneSocioAmbientale'].dirty || dettaglioAnagraficaForm.controls['collocazioneSocioAmbientale'].touched)"
          class="alert alert-danger">
          <div *ngIf="dettaglioAnagraficaForm.controls['collocazioneSocioAmbientale'].errors">
            Campo obbligatorio
          </div>
        </div>
      </div>
      <!-- TITOLO DI STUDIO -->
      <div class="form-group field-30 has-feedback">
        <label>Titolo di studio *</label>
        <div>
          <select class="form-control" name="titoloDiStudio" formControlName="titoloDiStudio">
            <option value=""></option>
            <option [value]="tit.codice" *ngFor="let tit of listTitoliStudio">{{tit.descrizione}}</option>

          </select>
        </div>
        <div
          *ngIf="dettaglioAnagraficaForm.controls['titoloDiStudio'].invalid && (dettaglioAnagraficaForm.controls['titoloDiStudio'].dirty || dettaglioAnagraficaForm.controls['titoloDiStudio'].touched)"
          class="alert alert-danger">
          <div *ngIf="dettaglioAnagraficaForm.controls['titoloDiStudio'].errors">
            Campo obbligatorio
          </div>
        </div>
      </div>
      <div class="form-group field-36 has-feedback">
        <label>Attività *</label>
        <div>
          <select class="form-control" name="attivita" formControlName="attivita">
            <option value=""></option>
            <option [value]="at.codice" *ngFor="let at of listAtivitta">{{at.descrizione}}</option>
          </select>
        </div>
        <div
          *ngIf="dettaglioAnagraficaForm.controls['attivita'].invalid && (dettaglioAnagraficaForm.controls['attivita'].dirty || dettaglioAnagraficaForm.controls['attivita'].touched)"
          class="alert alert-danger">
          <div *ngIf="dettaglioAnagraficaForm.controls['attivita'].errors">
            Campo obbligatorio
          </div>
        </div>
      </div>
      <div class="form-group has-feedback">
        <label>E' familiare di un paziente in trattamento *</label>
        <div class="form-radio">
          <div class="radio">
            <input type="radio" name="familiarePaziente" id="anagraficaFamiliare1" value="1"
                   formControlName="familiarePaziente">
            <label for="anagraficaFamiliare1">Sì</label>
          </div>
          <div class="radio">
            <input type="radio" name="familiarePaziente" id="anagraficaFamiliare2" value="2"
                   formControlName="familiarePaziente">
            <label for="anagraficaFamiliare2">No</label>
          </div>
          <div class="radio">
            <input type="radio" name="familiarePaziente" id="anagraficaFamiliare9" value="9"
                   formControlName="familiarePaziente">
            <label for="anagraficaFamiliare9">Non noto/Non risulta</label>
          </div>
        </div>
        <div
          *ngIf="dettaglioAnagraficaForm.controls['familiarePaziente'].invalid && (dettaglioAnagraficaForm.controls['familiarePaziente'].dirty || dettaglioAnagraficaForm.controls['familiarePaziente'].touched)"
          class="alert alert-danger">
          <div *ngIf="dettaglioAnagraficaForm.controls['familiarePaziente'].errors">
            Campo obbligatorio
          </div>
        </div>
      </div>
      <div class="form-group field-60 has-feedback">
        <label>Invalidità *</label>
        <div>
          <select class="form-control" name="invalidita" formControlName="invalidita">
            <option value=""></option>
            <option [value]="inv.codice" *ngFor="let inv of listInvalidita">{{inv.descrizione}}</option>
          </select>
        </div>
        <div
          *ngIf="dettaglioAnagraficaForm.controls['invalidita'].invalid && (dettaglioAnagraficaForm.controls['invalidita'].dirty || dettaglioAnagraficaForm.controls['invalidita'].touched)"
          class="alert alert-danger">
          <div *ngIf="dettaglioAnagraficaForm.controls['invalidita'].errors">
            Campo obbligatorio
          </div>
        </div>
      </div>
      <div class="form-group field-24 has-feedback">
        <label>Tutela giuridica *</label>
        <div>
          <select class="form-control" name="tutelaGiuridica" formControlName="tutelaGiuridica">
            <option value=""></option>
            <option [value]="tut.codice" *ngFor="let tut of listTuteleGiuridice">{{tut.descrizione}}</option>

          </select>
        </div>
        <div
          *ngIf="dettaglioAnagraficaForm.controls['tutelaGiuridica'].invalid && (dettaglioAnagraficaForm.controls['tutelaGiuridica'].dirty || dettaglioAnagraficaForm.controls['tutelaGiuridica'].touched)"
          class="alert alert-danger">
          <div *ngIf="dettaglioAnagraficaForm.controls['tutelaGiuridica'].errors">
            Campo obbligatorio
          </div>
        </div>
      </div>
    </div>
    <hr>
    <div class="group-inline">
      <div class="form-group field-50 has-feedback">
        <label>Attualmente sottoposto a provvedimenti giudiziari o a una misura di sicurezza *</label>
        <div>
          <select class="form-control" name="attualmenteSottoposto" formControlName="attualmenteSottoposto">
            <option value=""></option>
            <option [value]="pr.codice" *ngFor="let pr of listProvedimenti">{{pr.descrizione}}</option>

          </select>
        </div>
        <div
          *ngIf="dettaglioAnagraficaForm.controls['attualmenteSottoposto'].invalid && (dettaglioAnagraficaForm.controls['attualmenteSottoposto'].dirty || dettaglioAnagraficaForm.controls['attualmenteSottoposto'].touched)"
          class="alert alert-danger">
          <div *ngIf="dettaglioAnagraficaForm.controls['attualmenteSottoposto'].errors">
            Campo obbligatorio
          </div>
        </div>
      </div>
      <div class="form-group field-50 has-feedback">
        <label>Precedente internamento o detenzione in ambito Psichiatrico Penitenziario *</label>
        <div class="form-radio">
          <div class="radio">
            <input type="radio" name="precedentementeInternato" id="anagraficaPenitenziaria1" value="1"
                   formControlName="precedentementeInternato">
            <label for="anagraficaPenitenziaria1">Sì</label>
          </div>
          <div class="radio">
            <input type="radio" name="precedentementeInternato" id="anagraficaPenitenziaria2" value="2"
                   formControlName="precedentementeInternato">
            <label for="anagraficaPenitenziaria2">No</label>
          </div>
          <div class="radio">
            <input type="radio" name="precedentementeInternato" id="anagraficaPenitenziaria9" value="9"
                   formControlName="precedentementeInternato">
            <label for="anagraficaPenitenziaria9">Non noto/Non risulta</label>
          </div>
        </div>
        <div
          *ngIf="dettaglioAnagraficaForm.controls['precedentementeInternato'].invalid && (dettaglioAnagraficaForm.controls['precedentementeInternato'].dirty || dettaglioAnagraficaForm.controls['precedentementeInternato'].touched)"
          class="alert alert-danger">
          <div *ngIf="dettaglioAnagraficaForm.controls['precedentementeInternato'].errors">
            Campo obbligatorio
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
