<div class="container py-3">
  <!-- Ricerca -->
  <div class="card">
    <div class="card-header">
      <div class="title-3">Ricerca flussi</div>
    </div>
    <div class="card-body">
     <form [formGroup]="ricercaForm">
      <div class="group-inline">
        <div class="groupo-inline">
          <div class="form-group field-16">
            <label>Stato</label>
            <select
              class="form-control"
              formControlName="stato"
              name="stato"   >
              <option value="tutti" selected>Tutti</option>
              <option value="ELABORATO">Elaborato</option>
              <option value="NON_ELABORATO">Non Elaborato</option>
            </select>
          </div>
        </div>
        <div class="form-group field-10">
          <label>ID</label>
          <input
            type="text"
            class="form-control"
            formControlName="id"
            name="id"
          />
        </div>
        <div class="form-group field-10">
          <label>Data</label>
          <div class="input-group">
            <input
              class="form-control"
              formControlName="data"
              placeholder="aaaa-mm-gg"
              name="dp"
              [(ngModel)]="dateModel"
              ngbDatepicker
              [minDate]="minDate"
              [maxDate]="maxDate"
              #d="ngbDatepicker"
            />

            <button
              class="btn btn-secondary"
              (click)="d.toggle()"
              type="button"
            >
              <img
                class="img-responsive"
                src="../assets/icons/calendar_ko.png"
                style="width: 20px; height: 20px"
              />
            </button>
          </div>
        </div>
      </div>
    </form>
    </div>
    <div class="card-footer">
      <div class="btn-toolbar">
        <div class="btn-group-left">
          <button class="btn btn-outline-secondary" (click)="resetFormRicerca()" >Pulisci</button>
        </div>
        <div class="btn-group-center"></div>
        <div class="btn-group-right">
          <button class="btn btn-secondary" (click)="getLotti()">Cerca</button>
        </div>
      </div>
    </div>
  </div>

  <!-- Risultati -->
  <div class="card mt-5">
    <div class="card-body">
      <div class="table-responsive">
        <div class="list-table table-even">
          <table class="table-colf table-lg">
            <thead>
              <tr>
                <th>Stato</th>
                <th>Validati</th>
                <th>Scartati</th>
                <th>Da Correggere</th>
                <th>Data</th>
                <th>ID</th>
                <th>Attività</th>
              </tr>
            </thead>
            <tbody>
              <tr  class="tr-hover" *ngFor="let lotto of pagina.content"
                (click)="selectLotto(lotto)">
                <td class="td-hover" data-label="Stato">
                  <div>
                    <span > {{lotto.descrizioneStato}}</span>
                    <!-- <span *ngIf="lotto.stato=='SCARTATO'" class="badge badge-danger">{{lotto.stato}} {{lotto.descrizioneStato}}</span> -->
                  </div>
                </td>
<!--                <td class="td-hover" data-label="">-->
<!--                  <span > {{lotto.descrizioneScartatiValidati}}</span>-->
<!--                </td>-->
                <td>{{lotto.numeroValidati}}</td>
                <td>{{lotto.numeroScartati}}</td>
                <td>{{lotto.numeroDaCorreggere}}</td>
                <td class="td-hover" data-label="Data e ora">
                  <div>{{lotto.dataLotto}}</div>
                </td>
                <td class="td-hover" data-label="ID"><div>{{lotto.id}}</div></td>
                <td class="td-hover" data-label="Attività">
                  <div>{{lotto.tipoPrestazione}}</div>
                </td>
              </tr>
              <tr *ngIf="pagina.content.length == 0">
                <td colspan="4">
                  <p class="text-center">Impostare un filtro di ricerca</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="d-flex justify-content-between pt-3">
        <!-- PAGINATION -->
        <app-paginator
          [page]="pagina"
          (cambiaPaginaEvent)="cambiaPagina($event)"
        ></app-paginator>
      </div>
    </div>
  </div>

  <!-- Toolbar -->
  <div class="btn-fixed fixed">
    <div class="btn-toolbar">
      <div class="container">
        <div class="btn-group-left"></div>
        <div class="btn-group-center">
          <button type="button" class="btn btn-outline-secondary">
            Esporta risultati
          </button>
        </div>
        <div class="btn-group-right"></div>
      </div>
    </div>
  </div>
</div>
