import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbDateStruct, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Lotti, Page } from 'src/app/model/Models';
import { ApiService } from 'src/app/services/api.service';
import {Router} from "@angular/router";
import {SessionService} from "../../services/session.service";
@Component({
  selector: 'app-flussi',
  templateUrl: './flussi.component.html',
  styleUrls: ['./flussi.component.css']
})

export class FlussiComponent implements OnInit {

  ricercaForm!: FormGroup;
  dateModel!: NgbDateStruct;
  pagina: Page<Lotti> = new Page<Lotti>();
  minDate = { year: 1920, month: 1, day: 1 };
  maxDate = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() }
  constructor(
    private apiService: ApiService,
  private router: Router,
  private sessionService: SessionService) { }

  ngOnInit(): void {

    this.ricercaFormInit();
    this.getLotti();
  }

  private ricercaFormInit() {

    this.ricercaForm = new FormGroup({
      stato: new FormControl(""),
      id: new FormControl(""),
      data: new FormControl(""),
    });

  }

  resetFormRicerca() {

    this.ricercaForm.get('stato')?.setValue("");
    this.ricercaForm.get('id')?.setValue("");
    this.ricercaForm.get('data')?.setValue("");

  }

 async getLotti() {

    var stato = this.ricercaForm.get('stato')?.value;
    var id = this.ricercaForm.get('id')?.value;
    var data = this.ricercaForm.get('data')?.value;
    
    if(data == null || data == "undefined") {
      data = "";
    }else {
      data = data.year+"-"+data.month+"-"+data.day;
    }

    this.apiService.getLotti(stato, id, data, this.pagina.number,
      this.pagina.size).subscribe((data: any) => {

        this.pagina = data;

      });

  }

  cambiaPagina(event: any) {

    this.getLotti();

  }


  selectLotto(lotto: Lotti) {
    this.sessionService.setSelectedLotto(JSON.stringify(lotto));
    this.router.navigate(['dettaglio-lotto-uscita']);
  }

}
