import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  AnagraficaSessionSettings, Asl,
  ConfStato,
  Contatto,
  ContattoDto,
  DettaglioAnagrafica,
  Page,
  Utente
} from "../../model/Models";
import {firstValueFrom, Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {SessionService} from "../../services/session.service";
import {TheadColumn} from "../../components/table/thead/thead-column";
import {Funzionalita} from "../../model/Funzionalita";
import {TableSorter} from "../../components/table/thead/table-sorter";
import {Router} from "@angular/router";
import {ApiService} from "../../services/api.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {BlockUI, NgBlockUI} from "ng-block-ui";

@Component({
  selector: 'app-contatti-list',
  templateUrl: './contatti-list.component.html',
  styleUrls: ['./contatti-list.component.css']
})
export class ContattiListComponent implements OnInit, OnDestroy {

  constructor(private apiService: ApiService,
              private router: Router,
              public sessionService: SessionService,
              private modalService: NgbModal,) {
    this.theadColumns = this.theadColumnsInit();

  }

  isLoading: boolean = false;
  dettaglioAnagrafica?: DettaglioAnagrafica | null
  mainUser!: Utente
  utente!: Utente
  public OPERATORE_STRUTTURA: String = "operatore-struttura";
  public OPERATORE_ASL: String = "operatore-asl";
  public OPERATORE_DSM: String = "operatore-dsm";
  public OPERATORE_REGIONE: String = "operatore-regione";
  private destroy: Subject<any> = new Subject();
  theadColumns: TheadColumn[];
  tableSorter: TableSorter = new TableSorter();
  page: Page<ContattoDto> = new Page<ContattoDto>();
  allAnagraficaContatti: Contatto[] = []
  confStati: ConfStato[] = [];
  storicoList: any[] = [];
  annoricerca: number | undefined;

  @BlockUI()
  blockUI!: NgBlockUI;
  private aslList: Asl[] = [];

  async ngOnInit(): Promise<void> {

    // @ts-ignore
    const res = await firstValueFrom(this.sessionService.selectedAnagrafica.pipe(takeUntil(this.destroy)));
    if (res) {
      this.loadUser();
      this.dettaglioAnagrafica = JSON.parse(res);
      if (this.dettaglioAnagrafica?.id != null) {
        this.loadContatti(this.dettaglioAnagrafica?.id!!, 0, this.page.size, null, null);
        this.loadHasOpenContattiStatus(this.dettaglioAnagrafica);
      }
    }
    this.loadContatti(this.dettaglioAnagrafica?.id!!, this.page.number, this.page.size, this.tableSorter.orderByName(), this.tableSorter.orderByDirection());
    this.apiService.getAllConfStato().subscribe(data => {
      if (data) {
        this.confStati = data;
      }
    })
    this.apiService.getAllAsl().subscribe(data => {
      for (let item of data) {
        if (item.codiceAzienda != "98" && item.codiceAzienda != "098" && item.codiceAzienda != "998" && item.codiceAzienda != "999") {
          this.aslList.push(item);
        }
      }
    });

  }

  ngOnDestroy() {
    this.sessionService.setOpenTabAnagrafica("")
    this.destroy.next({});
  }

  descrizioneStato(stato: number) {
    for (let confStato of this.confStati) {
      if (stato == 5) {
        return "Validato ASL";
      }
      if (stato == confStato.id) {
        return confStato.descrizione;
      }
    }
    return "Stato non trovato";
  }

  rigaContattoClick(contattoDto: ContattoDto) {
    this.apiService.selectContattoById(contattoDto.id).subscribe(res => {
      this.sessionService.setSelectedContatto(res);
      this.router.navigate(['contatto']);
    });
  }

  aggiungiContattoVisibile() {
    let utente = this.sessionService.getUtenteFromStorage();
    return utente?.funzionalita.includes(Funzionalita.ANAGRAFICHE_INSERIMENTO);

  }

  loadHasOpenContattiStatus(anagrafica: DettaglioAnagrafica) {

    this.blockUI.start("")
    this.apiService.getAllAnagraficaContatti(anagrafica.id!!).subscribe(res => {

      if (res) {

        this.allAnagraficaContatti = res;

      }
      this.blockUI.stop();
    })

  }

  getTitleName(): string {
    if (!this.dettaglioAnagrafica?.nome) {
      return "Nuovo Paziente"
    } else {
      return "" + this.dettaglioAnagrafica?.cognome + " " + this.dettaglioAnagrafica?.nome + ' (CF: ' + this.dettaglioAnagrafica?.codiceFiscale + ")"
    }
  }

  loadUser() {
    this.mainUser = this.sessionService.getUtenteFromStorage()!!;
  }

  public getRuolo(ruolo: String) {
    this.getUtente();
    if (this.utente) {
      if (this.utente.nomeRuolo == ruolo) {
        return true;
      }
    }
    return false;
  }

  public getUtente() {
    var usr = sessionStorage.getItem("utente");
    if (usr != null && usr != undefined) {
      this.utente = JSON.parse(usr);
    }
  }

  private theadColumnsInit(): TheadColumn[] {
    let res: TheadColumn[] = [];

    let utente = this.sessionService.getUtenteFromStorage();

    res.push(new TheadColumn('', 'Alert'));
    if (this.getRuolo(this.OPERATORE_REGIONE) || this.getRuolo(this.OPERATORE_STRUTTURA)) {
      res.push(new TheadColumn('asl', 'ASL <br/> residenza'));
    }
    if (this.getRuolo(this.OPERATORE_ASL) || this.getRuolo(this.OPERATORE_DSM)) {
      res.push(new TheadColumn('asl', 'Residente'));
    }
    // res.push(new TheadColumn('IDCONT', 'IDContatto'));
    res.push(new TheadColumn('c.id', 'IDContatto'));

    if (!this.getRuolo(this.OPERATORE_STRUTTURA))
      res.push(new TheadColumn('tipo_prestazione', 'Ambito'));

    res.push(new TheadColumn('descrizione_struttura_operatore_inserimento', 'Codice -<br/>Denom. Struttura - ASL'));

    //console.log(this.page.content);
    if (!this.getRuolo(this.OPERATORE_STRUTTURA) || (this.getRuolo(this.OPERATORE_STRUTTURA) && this.getDescrTipoStruttura() == 'territoriale')) {
      res.push(new TheadColumn('data_richiesta', 'Data<br/> richiesta'));
      res.push(new TheadColumn('', 'Apertura<br/> contatto'));
    }
    res.push(new TheadColumn('data_apertura', 'Data <br/>apertura contatto'));
    res.push(new TheadColumn('data_chiusura', 'Data <br/>chiusura'));

    if (!this.getRuolo(this.OPERATORE_STRUTTURA) || (this.getRuolo(this.OPERATORE_STRUTTURA) && this.getDescrTipoStruttura() == 'semiresidenziale'))
      res.push(new TheadColumn('', 'N° presenze'));

    res.push(new TheadColumn('', 'N° prestazioni'));
    res.push(new TheadColumn('stato_attuale', 'Stato trasmissione'));


    return res;
  }

  getDescrTipoStruttura() {
    if (this.sessionService.getSelectedStrutturaFromStorage() != null) {
      if (this.sessionService.isSelectedStrutturaFromStorageTerritoriale()) {
        return "territoriale";
      } else {
        return "semiresidenziale"
      }
      ;
    } else {
      return "";
    }
  }

  utenteHasInfoStrutturaVisualizzazione() {
    let utente = this.sessionService.getUtenteFromStorage();
    return utente?.funzionalita.includes(Funzionalita.INFO_STRUTTURA_VISUALIZZAZIONE);
  }

  getStringParameter() {
    const anss: AnagraficaSessionSettings = this.sessionService.getAnagraficaSessionSettings();
    const annorc = this.sessionService.annoRicercaContatti;
    return " ( " + (annorc ? annorc : "") +
      (anss.asl ? " - ASL: " + anss.asl : "") +
      (anss.struttura ? " - Struttura: " + anss.struttura : "") +
      (anss.apertura_contatto ? " - Apertura: " + anss.apertura_contatto : "") +
      (anss.entita ? " - Entita: " + anss.entita : "") +
      (anss.stato_attuale ? " - Stato: " + anss.stato_attuale : "") +
      (anss.tipo_apporto_SSN ? " - SSN: " + anss.tipo_apporto_SSN : "") +
      (anss.ambito ? " - Ambito: " + anss.ambito : "") +
      " ) ";


  }

  async loadContatti(id: number, pageNr: number, pageSize: number, order: string | null, direction: string | null) {
    const anss: AnagraficaSessionSettings = this.sessionService.getAnagraficaSessionSettings();
    const annorc = this.sessionService.annoRicercaContatti;
    this.apiService.getPaginaContatti(id,
      false, //FIXME questo non dovrebbe servire
      '',
      null,  //FIXME togliere questo parametro
      '',
      '',
      null,
      null,  //FIXME togliere questo parametro
      annorc,//anss.anno,
      anss.ambito,
      anss.tipo_apporto_SSN,
      anss.struttura,
      anss.asl,
      anss.apertura_contatto,
      anss.entita,
      anss.stato_attuale,
      pageNr, pageSize, order, direction,
      this.mainUser.idRuolo).subscribe(res => {
      this.page = res
    })


    // this.apiService.getPaginaContatti(id, pageNr, pageSize, order, direction)
    //   .subscribe(res => {
    //   this.page = res
    // })
  }

  cambiaPagina(event: any) {
    this.loadContatti(this.dettaglioAnagrafica?.id!!, this.page.number, this.page.size, this.tableSorter.orderByName(), this.tableSorter.orderByDirection())
  }

  changeOrder(event: any) {
    this.loadContatti(this.dettaglioAnagrafica?.id!!, this.page.number, this.page.size, this.tableSorter.orderByName(), this.tableSorter.orderByDirection())

  }

  openAggiungiContattoForm() {

    this.sessionService.clearSelectedContatto();
    this.sessionService.setSelectedContatto(new Contatto())
    this.router.navigate(['anagrafica'])
  }

  areAllContattiClosed(): boolean {


    let isAllContattiClosed = this.allAnagraficaContatti.every(
      cont => cont.dataChiusura != null || cont.dataChiusura != undefined || cont.aperturaFlag == "2")

    return isAllContattiClosed
  }

  async openStoricoContatti(audit: any) {
    if (this.sessionService.isSelectedStrutturaFromStorageTerritoriale()) {
      await this.apiService.getStoricoContattiTerritoriali(this.dettaglioAnagrafica?.id!, this.sessionService.getSelectedStrutturaFromStorage()?.codice!, undefined).subscribe(res => {
        if (res) {
          this.storicoList = res;
        }
      });
    } else {
      await this.apiService.getStoricoContattiSemiresidenziale(this.dettaglioAnagrafica?.id!, this.sessionService.getSelectedStrutturaFromStorage()?.codice!, undefined).subscribe(res => {
        if (res) {
          this.storicoList = res;
        }
      });
    }
    this.modalService.open(audit, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
      windowClass: "sismModal",
      scrollable: true
    }).result.then((result) => {
    }, (reason) => {
    });

  }

  getDescrizioneASL(codiceAslOperatoreInserimento: string | undefined) {
    return this.aslList.find(value => value.codiceAzienda==codiceAslOperatoreInserimento)?.denominazioneAzienda;
  }
}
