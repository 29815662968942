import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { Router } from '@angular/router';
import { TableSorter } from 'src/app/components/table/thead/table-sorter';
import { TheadColumn } from 'src/app/components/table/thead/thead-column';
import { Funzionalita } from 'src/app/model/Funzionalita';
import {Asl, ConfStato, Contatto, Page, Presenza, PresenzaDto, Utente} from 'src/app/model/Models';
import { ApiService } from 'src/app/services/api.service';
import { SessionService } from 'src/app/services/session.service';
import {CommonsUtil} from "../../util/CommonsUtil";

@Component({
  selector: 'app-presenze-tab',
  templateUrl: './presenze-tab.component.html',
  styleUrls: ['./presenze-tab.component.css']
})
export class PresenzeTabComponent implements OnInit {

  pagePresenze: Page<PresenzaDto> = new Page<PresenzaDto>();

  contatto!:Contatto

  tableSorter: TableSorter = new TableSorter();

  theadColumns!: TheadColumn[];
  @Output() hasError = new EventEmitter<boolean>();

  confStati: ConfStato[] = [];

  constructor(private sessionService:SessionService,private apiService:ApiService,private router:Router) { }

  ngOnInit(): void {

    this.theadColumns = this.loadTableHead()
    this.loadContatto()
    this.loadPresenze(this.contatto.id!!, 0, this.pagePresenze.size, null, null)

    this.apiService.getAllConfStato().subscribe(data => {
      if(data){
        this.confStati = data;
      }
    })
    this.apiService.getAllAsl().subscribe(data => {
      for (let item of data) {
        if (item.codiceAzienda != "98" && item.codiceAzienda != "098" && item.codiceAzienda != "998" && item.codiceAzienda != "999") {
          this.aslList.push(item);
        }
      }
    });
  }

  private loadTableHead(): TheadColumn[] {
    let res: TheadColumn[] = [];
    res.push(new TheadColumn('', 'Alert'));
    res.push(new TheadColumn('id', 'IDPresenza'));
    res.push(new TheadColumn('struttura', 'Codice - Denom. Struttura - ASL'));
    res.push(new TheadColumn('contatto.dataApertura', 'Data apertura contatto'));
    res.push(new TheadColumn('dataPresenza', 'Data presenza'));
    // res.push(new TheadColumn('tipoSemires', 'Tipo struttura'));
    res.push(new TheadColumn('modPresenza', 'Modalita presenza'));
    res.push(new TheadColumn('', 'Nr. Prestazioni'));

    res.push(new TheadColumn('statoTrasmissione', 'Stato trasmissione'));


    return res;
  }

  loadContatto(){
    this.sessionService.selectedContatto.subscribe(res => {

      this.contatto = res

    })
  }

  loadPresenze(id: number, pageNr: number, pageSize: number, order: string | null, direction: string | null) {


    if (id != null) {

      this.apiService.getPaginaPresenze(id, pageNr, pageSize, order, direction).subscribe({


        next:(res) => {

          this.pagePresenze = res
          if(this.pagePresenze.content.some(value => this.alert(value.presenza.statoAttuale))){
            this.hasError.emit(true);
          }else{
            this.hasError.emit(false);
          }

        },
        error:(err) => {

          console.log(err);

        },
        complete:()=>{

        }



      })

    }



  }

  presenzaClick(presenza:Presenza){

    this.sessionService.setSelectedPresenza(presenza)

    this.router.navigate(['presenza-form'])
  }

  cambiaPagina() {
    this.loadPresenze(this.contatto.id!!, this.pagePresenze.number, this.pagePresenze.size, this.tableSorter.orderByName(), this.tableSorter.orderByDirection())
  }

  aggiungiPresenzaVisibile() {
    let utente = this.sessionService.getUtenteFromStorage();
    let elegible = utente?.funzionalita.includes(Funzionalita.ANAGRAFICHE_INSERIMENTO) && this.contatto.aperturaFlag !== "2"
    return elegible
  }

  contattoIsClosed():boolean{


    return this.contatto.dataChiusura != null || this.contatto.dataChiusura != undefined &&
    this.contatto.codiceDiagnosiChiusura != null || this.contatto.codiceDiagnosiChiusura != undefined

  }

  changeOrder() {
    this.loadPresenze(this.contatto.id!!, this.pagePresenze.number, this.pagePresenze.size, this.tableSorter.orderByName(), this.tableSorter.orderByDirection())

  }

  descrizioneStato(stato: number) {

    for(let confStato of this.confStati){

      if (stato == 5 && !this.getRuolo(this.OPERATORE_REGIONE))
        stato = 3;

      if(stato == confStato.id){

        return confStato.descrizione;

      }

    }
    return "Stato non trovato";
    // return this.apiService.getConfStatoById(stato).subscribe(data =>{
    //    return data.descrizione
    //  })
     // return new CommonsUtil().descrizioneStato(stato);
   }

  openPresenzaForm() {
    this.sessionService.setSelectedPresenza(new Presenza())
    this.router.navigate(['presenza-form'])
  }

  getModalitaLabel(value:string):string{
    if(value =="1"){

      return "Presenza semiresidenziale < 4 ore"
    }else{

      return "Presenza semiresidenziale > 4 ore"
    }
  }

  getTipoStrutturaLabel(value:string){



    if(value == "2"){
      return "Centro diurno"
    }else{
      return ""
    }
  }

  alert(statoAttuale: ConfStato) {
    return  new CommonsUtil().alert(statoAttuale);
  }
  isValidato(){
    if(this.contatto.statoAttuale.id == 3){
      return true
    } else {
      return false;
    }
  }

  public OPERATORE_STRUTTURA: String = "operatore-struttura";
  public OPERATORE_ASL: String = "operatore-asl";
  public OPERATORE_DSM: String = "operatore-dsm";
  public OPERATORE_REGIONE: String = "operatore-regione";
  public utente: Utente = new Utente();
  public getRuolo(ruolo: String) {
  this.getUtente( );
    if (this.utente) {

      if (this.utente.nomeRuolo == ruolo) {

        return true;

      }

    }

    return false;

  }
  public getUtente() {

    var usr = sessionStorage.getItem("utente");

    if (usr != null && usr != undefined) {

      this.utente = JSON.parse(usr);

    }

  }
  private aslList: Asl[] = [];

  getDescrizioneASL(codiceAslOperatoreInserimento: string | undefined) {
    return this.aslList.find(value => value.codiceAzienda==codiceAslOperatoreInserimento)?.denominazioneAzienda;
  }
}
