import {NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import {FormGroup, ValidationErrors} from "@angular/forms";
import {ConfStato} from "../model/Models";

export class CommonsUtil {
  static isValidDate(d: any) {
    return d instanceof Date;
  }

  static isEmpty(s: string) {
    if (s === ''
      || s === null) {
      return true;
    } else {
      return false;
    }
  }

  static getFilenameFromContentDisposition(contentDisposition: string | null): string {
    if (contentDisposition!=null){

      let res = contentDisposition.substring(22);
      res =  res.replace('"', '').replace('"', '');
      return res;
    } else {
      return 'nome-non-trovato';
    }
  }

  equalsDate(date1:  Date , date2: Date) :boolean {
    return date1.getFullYear() == date2.getFullYear()
      && date1.getMonth() == date2.getMonth()
      && date1.getDate() == date2.getDate() ;
  }


  static equalsNgbDateStruct(date1:  NgbDateStruct , date2: NgbDateStruct) :boolean {
     return date1.year == date2.year
       && date1.month == date2.month
       && date1.day == date2.day ;
  }

  static beforeNgbDateStruct(date1:  NgbDateStruct , date2: NgbDateStruct) :boolean {
    return date1.year < date2.year
      ||  (date1.year == date2.year && date1.month < date2.month)
      ||  (date1.year == date2.year && date1.month == date2.month && date1.day < date2.day );
  }

  static afterNgbDateStruct(date1:  NgbDateStruct , date2: NgbDateStruct) :boolean {
    return date1.year > date2.year
      ||  (date1.year == date2.year && date1.month > date2.month)
      ||  (date1.year == date2.year && date1.month == date2.month && date1.day > date2.day );
  }

  static convert(b64Data:string) {
    let sliceSize=512;
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays,  );
    return blob;
  }


  validFields(form: FormGroup) {
    let res = true;
    Object.keys(form.controls).forEach(key => {
  //    res = res && form.get(key)!.valid;
      // @ts-ignore
      const controlErrors: ValidationErrors = form.get(key).errors;
     // console.log(key);
      //console.log(controlErrors);
      // @ts-ignore
      res = res && controlErrors == null;
    });

    //console.log('  - validFields -  ' + res );
    return res;
  }

  // formControlErrorLength(controlErrors: ValidationErrors |null){
  //   let res = 0;
  //   // @ts-ignore
  //   if (controlErrors != null) {
  //     res = Object.keys(controlErrors).length;
  //   }
  //   return res;
  // }

  printFormValidationErrors(form: FormGroup) {
    console.log('form valid ' + form.valid)
    console.log('form valid ' + this.validFields(form) )
    Object.keys(form.controls).forEach(key => {
      // @ts-ignore
      const controlErrors: ValidationErrors = form.get(key).errors;
      // @ts-ignore
      console.log('Key control: ' + key + ', valid: ' + form.get(key)?.valid  );
      console.log('Key control: ' + key + ', invalid: ' + form.get(key)!.invalid  );
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }

  descrizioneStato(stato:number){
      switch (stato) {
        case 0: return 'Da modificare';
        case 1: return 'Da trasmettere';
        case 2: return 'Da validare Asl';
        case 3: return 'Validato Asl';
        case 4: return 'Scartato Asl';
        case 5: return 'Inviato Regione';
        case 6: return 'Validato Regione';
        case 7: return 'Scartato Regione';
        case 8: return 'Validato Ministero';
        case 9: return 'Scartato Ministero';
      }
    return 'Non trovato';
  }

  alert(statoAttuale: ConfStato) {
      if(statoAttuale) {
        return statoAttuale.id == 4;
          // || statoAttuale.id == 7
          // || statoAttuale.id == 9;
      } else return  false;

  }

}
