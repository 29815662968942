import {Asl, ConfFiltriRicerca} from './../../model/Models';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {FormControl, FormGroup} from '@angular/forms';
import {ApiService} from "../../services/api.service";
import {ToastService} from "../../services/toast.service";
import {
  AnagraficaSessionSettings,
  ConfStato,
  DettaglioAnagrafica,
  Page,
  RicercaAnagrafica,
  StatoEstero,
  Strutture,
  Utente
} from '../../model/Models';
import {TheadColumn} from "../../components/table/thead/thead-column";
import {TableSorter} from "../../components/table/thead/table-sorter";
import {SessionService} from "../../services/session.service";
import {ModalDismissReasons, NgbDateStruct, NgbModal, NgbTypeahead} from '@ng-bootstrap/ng-bootstrap';
import {Funzionalita} from "../../model/Funzionalita";
import {DocumentService} from '../../services/document.service';
// @ts-ignore
import {saveAs} from 'file-saver';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {firstValueFrom, Subject} from "rxjs";

@Component({
  selector: 'app-ricerca-anagrafica',
  templateUrl: './ricerca-anagrafica.component.html',
  styleUrls: ['./ricerca-anagrafica.component.css']
})
export class RicercaAnagraficaComponent implements OnInit, OnDestroy {

  Arr = Array;
  minDate = {year: 1920, month: 1, day: 1};
  maxDate = {year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate()}
  annoSelezionato: number | undefined;
  struttureList: Strutture[] = [];

  @BlockUI()
  blockUI!: NgBlockUI;

  ricercaForm!: FormGroup;

  pagina: Page<RicercaAnagrafica> = new Page<RicercaAnagrafica>();

  theadColumns: TheadColumn[];

  tableSorter: TableSorter = new TableSorter();

  isFormAdvanced = false;

  searchDateModel!: NgbDateStruct

  listStatiesteri: StatoEstero[] = []

  uploadAnagraficeDto?: UploadXmlAnagraficheResponseDto

  listConfStati?: ConfStato[] = [];
  listConfFiltriRicerca?: ConfFiltriRicerca[] = [];

  strutturaPrevalente: string | undefined = '';

  @ViewChild('uploadTabellaModal') uploadTabellaModal: any;
  @ViewChild('instance', {static: true}) instance: NgbTypeahead | undefined;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  tipoFile = 'empty'

  anagraficaXmlFile: File | undefined = undefined
  contattoXmlFile: File | undefined = undefined
  prestazioneXmlFile: File | undefined = undefined
  diagnosiXmlFile: File | undefined = undefined
  interventoXmlFile: File | undefined = undefined
  presenzaXmlFile: File | undefined = undefined

  isTerritoriale = true
  actived = "";
  activedNo = "active";
  showFilterMessage: boolean = false;
  pagina_2: RicercaAnagrafica[] | undefined;

  constructor(
    private router: Router,
    private sessionService: SessionService,
    private documentService: DocumentService, private apiService: ApiService, private toastService: ToastService, private modalService: NgbModal,
    private route: ActivatedRoute
  ) {
    this.pagina.size = this.sessionService.getAnagraficaSessionSettings().pageSize;
    this.theadColumns = this.theadColumnsInit();
    this.tableSorter.toggle('cognome');
  }


  async ngOnInit() {


    this.ricercaFormInit();
    await this.getAllStrutture();
    this.routeHasParams();
    if (this.getRuolo(this.OPERATORE_REGIONE)) {
      await this.getAllAsl()
    }

    if (!this.getRuolo(this.OPERATORE_STRUTTURA)) {
      this.ricercaForm.addControl("struttura", new FormControl())

      // this.modelStruttura = new Strutture();

      await this.activateStrutture();
      // this.modelStruttura.codStruttura = "";
      // this.modelStruttura.denominazioneStruttura = "";
    }
    this.cercaPaginaAnagrafica();


    this.getAllStatiEsteri();
    this.getAllConfStato();
    this.getAllConfFiltriRicerca();

    let strutturaPrevalenteStr = sessionStorage.getItem("struttura");
    if (strutturaPrevalenteStr != null) {
      let struttPrev: Strutture = JSON.parse(strutturaPrevalenteStr);
      //console.log(struttPrev?.codice);
      this.strutturaPrevalente = struttPrev?.codice;
    }
  }

  isStrutturaTerritoriale() {
    return this.sessionService.getSelectedStrutturaFromStorage()?.descrTipoStruttura?.includes("TERRITORIALE");
  }

  private ricercaFormInit() {

    this.ricercaForm = new FormGroup({
      codiceFiscale: new FormControl(""),
      cfCertificati: new FormControl(""),
      cognome: new FormControl(""),
      id: new FormControl(""),
      nome: new FormControl(""),
      selectedNazione: new FormControl(""),
      selectedDate: new FormControl(""),
      asl: new FormControl("")
    });
    this.ricercaForm.get('asl')?.setValue(this.defaultSelectAsl);
  }

  // public getAllStrutture() {
  //   of(this.apiService.getStruttureAll("", "").subscribe(data => {
  //     this.struttureList = data.content;
  //   }));
  // }
  async switchToAdvancedForm() {

    this.isFormAdvanced = true
    // this.ricercaForm.addControl("cognome", new FormControl(""))
    // this.ricercaForm.addControl("nome", new FormControl(""))
    // this.ricercaForm.addControl("selectedDate", new FormControl(""))
    // this.ricercaForm.addControl("selectedNazione", new FormControl(""))
    this.ricercaForm.addControl("anno", new FormControl())
    this.ricercaForm.addControl("tipo_apporto_SSN", new FormControl(""))
    this.ricercaForm.addControl("ambito", new FormControl(""))
    this.ricercaForm.addControl("apertura_contatto", new FormControl(""))
    this.ricercaForm.addControl("entita", new FormControl(""))
    this.ricercaForm.addControl("stato_attuale", new FormControl(""))
    this.ricercaForm.addControl("asl", new FormControl(""))

    var usr = sessionStorage.getItem("utente");
    var utente: Utente = new Utente;
    if (usr != null && usr != undefined) {
      utente = JSON.parse(usr);
    }
    this.modelStruttura = new Strutture();
    if (utente.nomeRuolo == "operatore-struttura") {
      this.ricercaForm.addControl("struttura", new FormControl())

      await this.getAllStrutture();
      this.activateStrutture();
      this.modelStruttura.codStruttura = this.sessionService.getSelectedStrutturaFromStorage()?.codice;
      this.modelStruttura.denominazioneStruttura = this.sessionService.getSelectedStrutturaFromStorage()?.denominazione;

      this.ricercaForm.get('struttura')?.disable();
      this.ricercaForm.get('asl')?.disable();

      // this.ricercaForm.controls['struttura'].patchValue(this.sessionService.getSelectedStrutturaFromStorage()?.codice);

    } else {

      this.modelStruttura.codStruttura = "";
      this.modelStruttura.denominazioneStruttura = "";

    }

  }


  switchToSimpleForm() {
    this.isFormAdvanced = false
    this.actived = "";
    this.activedNo = "active";
    // this.ricercaForm.removeControl("cognome")
    // this.ricercaForm.removeControl("nome")
    // this.ricercaForm.removeControl("selectedDate")
    // this.ricercaForm.removeControl("selectedNazione")
  }

  ngOnDestroy(): void {
    localStorage.setItem('RicercaAnagraficaComponent.ricercaForm', JSON.stringify(this.ricercaForm.value));
  }

  async getAllStatiEsteri() {

    this.apiService.getAllStatiEsteri().subscribe(res => {
      this.listStatiesteri = res

      // set italia first element
      let italiaIndex = this.listStatiesteri.findIndex(c => c.alpha2 === "IT");
      this.listStatiesteri = this.moveObjectIndex(this.listStatiesteri, italiaIndex, 0)

      // set apolide last element
      let apolideIndex = this.listStatiesteri.findIndex(c => c.alpha2 === "ZZ");
      this.listStatiesteri = this.moveObjectIndex(this.listStatiesteri, apolideIndex, this.listStatiesteri.length)
    })


  }

  async getAllConfStato() {

    this.apiService.getAllConfStato().subscribe(res => {
      const index = res.findIndex(value => value.id == 5);
      if (index > -1) {
        res.splice(index, 1);
      }
      if( this.getRuolo(this.OPERATORE_ASL) || this.getRuolo(this.OPERATORE_DSM)){
        let index0 = res.findIndex(value => value.id == 0);
        if (index0 > -1) {
          res.splice(index0, 1);
        }
        let index1 = res.findIndex(value => value.id == 1);
        if (index1 > -1) {
          res.splice(index1, 1);
        }
      }
      this.listConfStati = res;
    });

  }

  async getAllConfFiltriRicerca() {

    this.apiService.getAllConfFiltriRicerca().subscribe(res => {
      this.listConfFiltriRicerca = res;
      if(this.getDescrTipoStruttura()=="semiresidenziale") {
        this.listConfFiltriRicerca.forEach((v, i) => {
          if (v.descrizione == "Contatti senza prestazioni") {

            this.listConfFiltriRicerca![i] = {descrizione: "Contatti senza presenze", id: v.id};
          }
          if(v.descrizione == "Contatti con ultima prestazione più lontana di 180 giorni"){
            this.listConfFiltriRicerca![i] = {
              descrizione: "Contatti con ultima presenza più lontana di 180 giorni",
              id: v.id
            };
          }



        })
      }



    });

  }

  rigaAnagraficaClick() {
    this.router.navigate(['anagrafica']);
  }

  resetPagina = true;
  cambiaPagina(event: any) {
    this.resetPagina = false;
    this.cercaPaginaAnagrafica();
  }

  prossimaPagina(){
    this.resetPagina = false;
    this.pagina.number++;
    this.cercaPaginaAnagrafica();
  }
  precedentePagina(){
    this.resetPagina = false;
    this.pagina.number--;
    this.cercaPaginaAnagrafica();
  }
  go2page(page:number){
      this.resetPagina = false;
      this.pagina.number =page;
      this.cercaPaginaAnagrafica();
  }

  async cercaPaginaAnagrafica() {
    if(this.isFormAdvanced&&this.getEntita()&&! this.ricercaForm.get('stato_attuale')?.value){
      this.toastService.show("Attenzione se il campo entità è impostato, lo stato diventa obbligatorio.");
      this.ricercaForm.get('stato_attuale')?.setErrors({'incorrect': true});
      return;
    }
    if(this.isFormAdvanced&&this.ricercaForm.get('stato_attuale')?.value&&!this.entitaSelezionata()){
      this.toastService.show("Attenzione se il campo stato è impostato, l'entità' diventa obbligatoria.");
      this.ricercaForm.get('entita')?.setErrors({'incorrect': true});
      return;
    }
    if(this.resetPagina){
      this.pagina.number = 0;
    }
    this.pagina.loading = true;
    let selectedDate = this.sessionService.toNativeDate(this.searchDateModel)
    let nazioneNascita = this.ricercaForm.get('selectedNazione')?.value
    let codiceFiscale = this.ricercaForm.get('codiceFiscale')?.value
    let cfCertificati = this.ricercaForm.get('cfCertificati')?.value
    let cognome = this.ricercaForm.get('cognome')?.value
    let id = this.ricercaForm.get('id')?.value
    let nome = this.ricercaForm.get('nome')?.value

    const anss: AnagraficaSessionSettings = this.sessionService.getAnagraficaSessionSettings();

    anss.anno = this.ricercaForm.get('anno')?.value;
    anss.ambito = this.ricercaForm.get('ambito')?.value;
    anss.asl = this.ricercaForm.get('asl')?.value;
    anss.tipo_apporto_SSN = this.ricercaForm.get('tipo_apporto_SSN')?.value;
    if (this.sessionService.getUtenteFromStorage() && !this.sessionService.getUtenteFromStorage()!.funzionalita.includes(Funzionalita.INFO_STRUTTURA_VISUALIZZAZIONE)) {
      anss.struttura = this.sessionService.getSelectedStrutturaFromStorage()?.codice;
      anss.asl = this.sessionService.getSelectedStrutturaFromStorage()?.codAzienda+'';
    } else {
       //anss.struttura = this.ricercaForm.get('struttura')?.value;
      anss.struttura = this.modelStruttura?.codStruttura;
    }


    anss.apertura_contatto = this.ricercaForm.get('apertura_contatto')?.value;
    anss.entita = this.ricercaForm.get('entita')?.value;
    anss.stato_attuale = this.ricercaForm.get('stato_attuale')?.value ? this.ricercaForm.get('stato_attuale')?.value : undefined;
    if (anss.stato_attuale != undefined) {
      anss.stato_attuale = Number(anss.stato_attuale);
    }
    if (cfCertificati == "" || this.isFormAdvanced) {
      cfCertificati = null
    }
    if (cognome == "" || this.isFormAdvanced) {
      cognome = null
    }
    if (id == "" || this.isFormAdvanced) {
      id = null
    }
    if (nome == "" || this.isFormAdvanced) {
      nome = null
    }
    if (this.isFormAdvanced) {
      selectedDate = null
    }
    if (nazioneNascita == "" || this.isFormAdvanced) {
      nazioneNascita = null
    }
    anss.pageSize = this.pagina.size;

    //this.sessionService.setAnagraficaSessionSettings(anss);
    var usr = sessionStorage.getItem("utente");
    var utente: Utente = new Utente;
    if (usr != null && usr != undefined) {
      utente = JSON.parse(usr);
    }
    if (anss.anno == 0) {
      anss.anno = undefined;
    }

    /*this.apiService.getPaginaAnagrafica(
      this.isFormAdvanced,
      this.isFormAdvanced ? undefined : codiceFiscale,
      this.isFormAdvanced ? undefined : cfCertificati,
      this.isFormAdvanced ? undefined : cognome,
      this.isFormAdvanced ? undefined : id,
      this.isFormAdvanced ? undefined : nome,
      this.isFormAdvanced ? null : selectedDate,
      this.isFormAdvanced ? undefined : nazioneNascita,
      !this.isFormAdvanced ? undefined : anss.anno,
      !this.isFormAdvanced ? undefined : anss.ambito,
      !this.isFormAdvanced ? undefined : anss.tipo_apporto_SSN,
      anss.struttura,
      !this.isFormAdvanced ? undefined : anss.apertura_contatto,
      !this.isFormAdvanced ? undefined : anss.entita,
      !this.isFormAdvanced ? undefined : anss.stato_attuale,
      this.pagina.number,
      this.pagina.size,
      this.tableSorter.orderByName(),
      this.tableSorter.orderByDirection(),
      utente.idRuolo,
      !this.isFormAdvanced ? undefined : anss.asl
    ).subscribe(res => {
      this.pagina = res;
      this.pagina.loading = false;
      this.resetPagina = true;
     // console.log(this.pagina.content)
    });*/
    var trapSSN:number|undefined = undefined;
    if (utente.nomeRuolo == "operatore-struttura") {
      trapSSN = this.sessionService.getSelectedStrutturaFromStorage()&&this.sessionService.getSelectedStrutturaFromStorage()?.tipoRapportoSsn?+(this.sessionService.getSelectedStrutturaFromStorage()!.tipoRapportoSsn!):undefined;
    } else {
     trapSSN=!this.isFormAdvanced||!(anss.tipo_apporto_SSN) ? undefined : +anss.tipo_apporto_SSN;
    }

    var tambito = !this.isFormAdvanced||!(anss.ambito) ? undefined : anss.ambito;
    if(!tambito&&utente.nomeRuolo == "operatore-struttura")tambito=this.sessionService.getSelectedStrutturaFromStorage()?.descrTipoStruttura?.indexOf('TERRITORIALE') != -1?"TERRITORIALE":"SEMIRESIDENZIALE";
    this.apiService.getPaginaAnagrafica_2(
      this.isFormAdvanced ? undefined : (codiceFiscale?codiceFiscale:undefined),
      this.isFormAdvanced ? undefined : (cognome?cognome:undefined),
      this.isFormAdvanced ? undefined : id,
      this.isFormAdvanced ? undefined : (nome?nome:undefined),
      this.isFormAdvanced ? undefined : selectedDate,
      !this.isFormAdvanced ? undefined : anss.anno?+anss.anno:undefined,
      tambito,
      trapSSN,
      anss.struttura?anss.struttura:undefined,
      !this.isFormAdvanced ? undefined : (anss.apertura_contatto? +anss.apertura_contatto:undefined),
      !this.isFormAdvanced ? undefined : anss.entita,
      !this.isFormAdvanced ? undefined : anss.stato_attuale,
      this.pagina.size,
      this.pagina.size*(this.pagina.number),
      'anagrafica_id',
      'desc',
      utente.idRuolo,
      anss.asl?anss.asl:undefined
    ).subscribe(res => {
      this.pagina_2 = res;
      this.pagina.loading = false;
      this.resetPagina = true;
      // console.log(this.pagina.content)
    });
  }


  private theadColumnsInit(): TheadColumn[] {
    let res: TheadColumn[] = [];
    res.push(new TheadColumn('', 'Alert'));
    res.push(new TheadColumn('id', 'IDANA'));
    res.push(new TheadColumn('codice_fiscale', 'Codice fiscale'));
    res.push(new TheadColumn('cf_certificato', 'ASUR'));
    res.push(new TheadColumn('cognome', 'Cognome'));
    res.push(new TheadColumn('nome', 'Nome'));

    if (this.getRuolo(this.OPERATORE_ASL))
      res.push(new TheadColumn('', 'Residente'));
    else
      res.push(new TheadColumn('', 'Asl Residenza'));

    if (!this.getRuolo(this.OPERATORE_STRUTTURA))
      res.push(new TheadColumn('', "Codice, denominazione struttura e ASL\n che ha erogato l'ultima prestazione"))


    if (this.getRuolo(this.OPERATORE_STRUTTURA))
      res.push(new TheadColumn('', "Attualmente ha un contatto attivo "))

    if (!this.getRuolo(this.OPERATORE_STRUTTURA))
      res.push(new TheadColumn('', "Attualmente ha un \ncontatto attivo territoriale"))

    res.push(new TheadColumn('', 'Data Ult. Prestazione'));

    if (!this.getRuolo(this.OPERATORE_STRUTTURA))
      res.push(new TheadColumn('', "Attualmente ha un \ncontatto attivo semiresidenziale"))

    if (!this.getRuolo(this.OPERATORE_STRUTTURA) || (this.getRuolo(this.OPERATORE_STRUTTURA) && this.getDescrTipoStruttura() == 'semiresidenziale'))
      res.push(new TheadColumn('', 'Data Ult. Presenza'));


    if (this.getRuolo(this.OPERATORE_STRUTTURA))
      res.push(new TheadColumn('tipo_prestazione', "Tipo ultima prestazione"))


    // res.push(new TheadColumn('cf_certificato', 'CF certificato'));
    // res.push(new TheadColumn('tipo', 'Tipo ult. prest.'));
    // res.push(new TheadColumn('data_prestazione', 'Data ult. prest.'));

    return res;
  }

  getDescrTipoStruttura() {
    if (this.sessionService.getSelectedStrutturaFromStorage() != null) {
      if (this.sessionService.isSelectedStrutturaFromStorageTerritoriale()) {
        return "territoriale";
      } else {
        return "semiresidenziale"
      }
      ;
    } else {
      return "";
    }
  }


  changeOrder(event: any) {
    this.cercaPaginaAnagrafica();
  }


  aggiungiNuovaAnagrafica() {
    this.sessionService.setSelectedAnagrafica(JSON.stringify(new DettaglioAnagrafica()));
    this.router.navigate(['anagrafica']);
  }

  pulisciFiltri() {
    this.ricercaForm.reset({
      "codiceFiscale": "",
      "cfCertificati": "",
      "cognome": "",
      "nome": "",
      "id": "",
      "selectedNazione": "",
      "asl": "",
      "apertura_contatto": "",
      "entita": "",
      "stato_attuale": "",
      "ambito": "",
      "tipo_apporto_SSN": "",
      "struttura": ""

    })
    /*this.ricercaForm.controls['tipo_apporto_SSN'].patchValue(this.sessionService.getSelectedStrutturaFromStorage()?.tipoRapportoSsn);
    this.ricercaForm.controls['anno'].patchValue(anno);
    if (this.sessionService.getSelectedStrutturaFromStorage()?.descrTipoStruttura?.indexOf('TERRITORIALE') != -1) {
      this.ricercaForm.controls['ambito'].patchValue("Territoriale");
    } else {
      this.ricercaForm.controls['ambito'].patchValue("Semiresidenziale");
    }*/
    this.getAllStrutture();

    this.cercaPaginaAnagrafica()

  }

  async selectAnagrafica(anagrafica: RicercaAnagrafica) {
    this.blockUI.start("Apertura contatto " + anagrafica.codiceFiscale + " in corso")
    const res = await firstValueFrom(this.apiService.getAnagrafica(anagrafica.idAnagrafica));
    if (res) {
      this.sessionService.setSelectedAnagrafica(JSON.stringify(res));
      this.sessionService.annoRicercaContatti = this.isFormAdvanced&&this.ricercaForm.get('anno')?.value!=0? this.ricercaForm.get('anno')?.value:undefined;
      this.blockUI.stop();
      this.router.navigate(['contattiList']);
    } else {
      this.blockUI.stop();
      this.toastService.showProblemaTecnico();
    }
  }

  closeResult: string = '';
  public anni = this.generateArrayOfYears();

  generateArrayOfYears() {

    const max = new Date().getFullYear();
    const min = 2012;
    const years = [];

    for (let i = max; i >= min; i--) {

      years.push('' + i);

    }

    return years;

  }

  open(content: any) {

    this.modalService.open(content, {size: "lg", ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;


    }, (reason) => {


      this.restoreFiles()


      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {

      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  moveObjectIndex(array: any, sourceIndex: number, destIndex: number) {
    var placeholder = {};

    var objectToMove = array.splice(sourceIndex, 1, placeholder)[0];

    array.splice(destIndex, 0, objectToMove);

    array.splice(array.indexOf(placeholder), 1);

    return array
  }

  /**
   * metodi per la gestione dei permessi
   */
  aggiungiNuovaAnagraficaVisibile() {
    let utente = this.sessionService.getUtenteFromStorage();
    return utente?.funzionalita.includes(Funzionalita.ANAGRAFICHE_INSERIMENTO);
  }


  onAnagraficaFileSelect(event: any) {


    this.anagraficaXmlFile = event.target.files[0]


  }

  onContattoFileSelect(event: any) {


    this.contattoXmlFile = event.target.files[0]

  }

  onPrestazioneFileSelect(event: any) {


    this.prestazioneXmlFile = event.target.files[0]

  }

  onPresenzaFileSelect(event: any) {


    this.presenzaXmlFile = event.target.files[0]

  }

  onDiagnosiFileSelect(event: any) {

    this.diagnosiXmlFile = event.target.files[0]
  }

  onInterventoFileSelect(event: any) {
    this.interventoXmlFile = event.target.files[0]
  }

  upload() {

    let codiceStruttura = this.sessionService.getSelectedStrutturaFromStorage()?.codice

    if (this.isStrutturaTerritoriale() == true) {

      this.blockUI.start("Salvataggio in corso")
      this.documentService.uploadFilesTerritoriali(codiceStruttura!!, this.anagraficaXmlFile, this.contattoXmlFile, this.prestazioneXmlFile, this.diagnosiXmlFile, this.interventoXmlFile).subscribe({

        next: (response) => {
          this.modalService.dismissAll()
          this.uploadAnagraficeDto = response
          this.checkAnagraficaDtoForErrors(this.uploadAnagraficeDto!!)
          this.openTabellaDtoModal()
          this.restoreFiles()

        },
        error: (err) => {

          this.restoreFiles()
          this.modalService.dismissAll()

          if (err.status == 400) {

            this.uploadAnagraficeDto = err.error

            this.downloadErrorCsvDocument()
            this.openTabellaDtoModal()

            this.toastService.showError("Sono presenti errori di compilazione, eseguito il download del file ")
            this.cercaPaginaAnagrafica()
          }
          if (err.status == 500) {
            this.toastService.showError("Sono presenti errori di compilazione, eseguito il download del file ")
            this.cercaPaginaAnagrafica()
          }
          if (err.status == 504) {
            alert("Attenzione, l'importazione dati sta richiedendo tempi più lunghi del previsto, verificare l'esito aggiornando la pagina di importazione dati premendo il tasto F5. ");
            this.router.navigate(['importazioneDati']);
          }

          this.blockUI.stop()
        },
        complete: () => {

          this.anagraficaXmlFile = undefined
          this.toastService.showSuccess("Documenti inseriti")
          this.cercaPaginaAnagrafica()
          this.restoreFiles()

          this.blockUI.stop()
        }
      })
    } else {


      this.blockUI.start("Salvataggio in corso")

      this.documentService.uploadFilesSemiresidenziali(codiceStruttura!!, this.anagraficaXmlFile, this.contattoXmlFile, this.presenzaXmlFile, this.prestazioneXmlFile).subscribe({
        next: (response) => {


          this.modalService.dismissAll()
          this.uploadAnagraficeDto = response
          this.checkAnagraficaDtoForErrors(this.uploadAnagraficeDto!!)
          this.openTabellaDtoModal()
          this.restoreFiles()


        },
        error: (err) => {

          this.modalService.dismissAll()
          if (err.status == 400) {
            this.uploadAnagraficeDto = err.error
            this.restoreFiles()
            this.toastService.showError("Sono presenti errori di compilazione, eseguito il download del file")
            this.downloadErrorCsvDocument()
            this.cercaPaginaAnagrafica()
            this.openTabellaDtoModal()

          }
          if (err.status == 500) {
            this.restoreFiles()
            this.toastService.showError("Sono presenti errori di compilazione, eseguito il download del file ")
            this.cercaPaginaAnagrafica()
          }

          this.blockUI.stop()

        },
        complete: () => {

          this.anagraficaXmlFile = undefined
          this.toastService.showSuccess("Documenti inseriti")
          this.cercaPaginaAnagrafica()
          this.restoreFiles()
          this.blockUI.stop()
        }
      })
    }


  }

  setTipo(event: any) {

    if (event.target.value == "true") {

      this.isTerritoriale = true
    } else {
      this.isTerritoriale = false
    }

  }


  hasFileToUpload() {
    return this.anagraficaXmlFile != null || this.contattoXmlFile != null || this.presenzaXmlFile != null || this.interventoXmlFile != null || this.prestazioneXmlFile != null || this.diagnosiXmlFile != null
  }

  downloadErrorCsvDocument() {

    this.documentService.download(this.sessionService.getSelectedStrutturaFromStorage()?.codice!).subscribe(blob => {
      saveAs(blob, "report_import_xml_" + this.utente.userName + "_" + this.sessionService.getSelectedStrutturaFromStorage()?.codice + ".csv");
    });

  }

  restoreFiles() {

    this.anagraficaXmlFile = undefined;
    this.contattoXmlFile = undefined;
    this.prestazioneXmlFile = undefined;
    this.presenzaXmlFile = undefined;
    this.interventoXmlFile = undefined;
    this.diagnosiXmlFile = undefined;

  }

  openTabellaDtoModal() {

    this.modalService.open(this.uploadTabellaModal);

  }

  checkAnagraficaDtoForErrors(uploadAnagraficeDto: UploadXmlAnagraficheResponseDto) {

    uploadAnagraficeDto.anagraficheDto.forEach(ana => {

      if (ana.errati > 0) {
        this.downloadErrorCsvDocument();
      }


    })

    uploadAnagraficeDto.contattiDto.forEach(cont => {

      if (cont.errati > 0) {
        this.downloadErrorCsvDocument()
      }


    })

    uploadAnagraficeDto.prestazioniDto.forEach(pres => {

      if (pres.errati > 0) {
        this.downloadErrorCsvDocument()
      }

    });
    uploadAnagraficeDto.interventiDto.forEach(intrv => {

      if (intrv.errati > 0) {
        this.downloadErrorCsvDocument()
      }

    });
    uploadAnagraficeDto.diagnosiDto.forEach(diag => {

      if (diag.errati > 0) {
        this.downloadErrorCsvDocument()
      }

    });
    uploadAnagraficeDto.presenzeDto.forEach(pres => {

      if (pres.errati > 0) {
        this.downloadErrorCsvDocument()
      }

    });

  }

  changeAnno() {

    this.getAllStrutture()

  }

  changeAmbito() {

    this.getAllStrutture()

  }

  changeTipo_apporto_SSN() {

    this.getAllStrutture()

  }

  public async getAllStrutture() {
    // let anno: number = (new Date()).getFullYear(), ambito: string = "", ssn: string = "0";
    let anno: number, ambito: string = "", ssn: string = "", asl: string = "";

    if (this.ricercaForm.get('tipo_apporto_SSN')?.value != undefined) {

      ssn = this.ricercaForm.get('tipo_apporto_SSN')?.value;

    } else {
      ssn = '';
    }

    // if (this.ricercaForm.get('anno')?.value != undefined) {
    if (this.route.snapshot.queryParamMap.get('anno') != undefined) {

      anno = Number(this.route.snapshot.queryParamMap.get('anno'));

    } else if (this.ricercaForm.get('anno')?.value != undefined) {

      anno = this.ricercaForm.get('anno')?.value;

    } else {
      anno = (new Date()).getFullYear()
    }

    if (this.ricercaForm.get('ambito')?.value != undefined) {

      ambito = this.ricercaForm.get('ambito')?.value;

    }

    if (this.getRuolo(this.OPERATORE_REGIONE) && this.ricercaForm.get('asl')?.value != undefined) {
      asl = this.ricercaForm.get('asl')?.value;
    }
    let data = await firstValueFrom(this.apiService.getStruttureByParams(anno, ssn, ambito, "", asl,this.utente.idRuolo!));
    this.struttureList = data!.content;
    this.modelStruttura = {codStruttura :''}

    // if(!this.getRuolo(this.OPERATORE_REGIONE)){
    //
    //   let struttura = new Strutture();
    //   struttura.codStruttura = "fuoriasl";
    //   struttura.denominazioneStruttura = "Strutture fuori ASL";
    //   this.struttureList.push(struttura)
    //   let strutturaDellAsl = new Strutture();
    //   strutturaDellAsl.codStruttura = "struttureInAsl";
    //   strutturaDellAsl.denominazioneStruttura = "Strutture dell'Asl";
    //   this.struttureList.push(strutturaDellAsl);
    //
    // }

    var usr = sessionStorage.getItem("utente");
    var utente: Utente = new Utente;

    if (usr != null && usr != undefined) {

      utente = JSON.parse(usr);

    }
    if (utente.nomeRuolo == "operatore-struttura") {
      this.ricercaForm.get('struttura')?.disable();
      this.ricercaForm.get('asl')?.disable();
      this.ricercaForm.get('tipo_apporto_SSN')?.disable();
      this.ricercaForm.get('ambito')?.disable();
      this.ricercaForm.controls['struttura'] ? null : this.ricercaForm.addControl("struttura", new FormControl());
      this.modelStruttura = new Strutture();
      this.modelStruttura.codStruttura = this.sessionService.getSelectedStrutturaFromStorage()?.codice;
      this.modelStruttura.denominazioneStruttura = this.sessionService.getSelectedStrutturaFromStorage()?.denominazione;
      // this.ricercaForm.controls['struttura'].patchValue(this.sessionService.getSelectedStrutturaFromStorage()?.codice);
      this.ricercaForm.controls['tipo_apporto_SSN'] ? null : this.ricercaForm.addControl("tipo_apporto_SSN", new FormControl());
      this.ricercaForm.controls['tipo_apporto_SSN'].patchValue(this.sessionService.getSelectedStrutturaFromStorage()?.tipoRapportoSsn);
      this.ricercaForm.controls['anno'] ? null : this.ricercaForm.addControl("anno", new FormControl());
      this.ricercaForm.controls['anno'].patchValue(anno);
      this.ricercaForm.controls['ambito'] ? null : this.ricercaForm.addControl("ambito", new FormControl());

      if (this.sessionService.getSelectedStrutturaFromStorage()?.descrTipoStruttura?.indexOf('TERRITORIALE') != -1) {
        this.ricercaForm.controls['ambito'].patchValue("Territoriale");
      } else {
        this.ricercaForm.controls['ambito'].patchValue("Semiresidenziale");
      }

    } else {
      this.ricercaForm.controls['anno'] ? null : this.ricercaForm.addControl("anno", new FormControl());
      this.ricercaForm.controls['anno'].patchValue(anno);
    }

  }

  formatter = (struttura: Strutture) => struttura.denominazioneStruttura || "Tutte";
  public modelStruttura?: Strutture = {denominazioneStruttura:"Tutte", codStruttura : "", codice:""};
  // public modelStruttura?: Strutture;
  // strutturaSearch: OperatorFunction<string, readonly Strutture[]> = (text$: Observable<string>) =>
  //   text$.pipe(
  //     debounceTime(200),
  //     distinctUntilChanged(),
  //     map((term: string) => term.length < 1 ? []
  //       : this.struttureList.filter(v => v && v.denominazioneStruttura && v.denominazioneStruttura.toLowerCase().indexOf(term.toLowerCase()) > -1)
  //         .sort((a, b) => this.strutturaCompare(a.denominazioneStruttura!, b.denominazioneStruttura!, term))
  //         .slice(0, 10)
  //     )
  //   )

  strutturaSortingValue(a: string, term: string) {
    return 10000
      * (1 / (a.toLowerCase().indexOf(term.toLowerCase()) + 1))
      * (1 / a.length);
  }

  strutturaCompare(a: string, b: string, term: string) {
    return this.strutturaSortingValue(b, term) - this.strutturaSortingValue(a, term);

  }

  changeapertura_contatto() {

  }

  changeentita() {

  }

  changestato_attuale() {
  }

  changeStruttura() {
    this.defaultSelectAsl=this.modelStruttura?.codAzienda;

  }

  routeHasParams(): boolean {

    let hasParams = false
    let entita = this.route.snapshot.queryParamMap.get('entita');
    let stato = this.route.snapshot.queryParamMap.get('stato');
    let aperturaContatto = this.route.snapshot.queryParamMap.get('apertura_contatto');
    let ambito = this.route.snapshot.queryParamMap.get('ambito');
    let tipo = this.route.snapshot.queryParamMap.get('tipo');
    let struttura = this.route.snapshot.queryParamMap.get('struttura');
    let denomStruttura = this.route.snapshot.queryParamMap.get('denomStruttura');
    let asl = this.route.snapshot.queryParamMap.get('asl');
    if (aperturaContatto == "0") {
      aperturaContatto = "";
    }
    let advandcedSearch = this.route.snapshot.queryParamMap.get('advanced');
    let anno = this.route.snapshot.queryParamMap.get('anno');
    if(asl != null && asl != ""){
      this.ricercaForm.get('asl')?.setValue(asl);
    }
    if (
      advandcedSearch != null &&
      advandcedSearch != "" &&
      advandcedSearch == "true" &&
      entita != null &&
      entita != "" &&
      stato != null &&
      stato != "" &&
      aperturaContatto != null &&
      anno != null

    ) {


      this.switchToAdvancedForm();
      this.actived = "active";
      this.activedNo = "";
      this.isFormAdvanced = true;
      this.ricercaForm.controls['entita'].patchValue(entita);
      this.ricercaForm.controls['stato_attuale'].patchValue(stato);
      this.ricercaForm.controls['apertura_contatto'].patchValue(aperturaContatto);
      this.ricercaForm.controls['ambito'].patchValue(ambito);
      this.ricercaForm.controls['tipo_apporto_SSN'].patchValue(tipo);
      // this.ricercaForm.controls['struttura'].setValue(struttura);
      // this.modelStruttura = new Strutture();
      let modStrutture = new Strutture();
      modStrutture.codStruttura = struttura!;
      modStrutture.denominazioneStruttura = denomStruttura!;
      this.modelStruttura = modStrutture;

      if(struttura == "tutte"){

        this.modelStruttura = {codStruttura:"", denominazioneStruttura :"Tutte"}

      } else {

        this.modelStruttura = this.struttureList.find(v => {return v.codStruttura == struttura})

      }
      // this.modelStruttura.codStruttura = struttura!;
      // this.modelStruttura.denominazioneStruttura = denomStruttura!;
      this.ricercaForm.controls['anno'].patchValue(Number(anno));
      this.annoSelezionato = Number(anno)
      this.defaultSelectAsl = asl!
      hasParams = true;

    }

    return hasParams;

  }

  getEntita() {

    return this.ricercaForm.get('entita')?.value;

  }

  public utente: Utente = new Utente();
  public OPERATORE_STRUTTURA: String = "operatore-struttura";
  public OPERATORE_ASL: String = "operatore-asl";
  public OPERATORE_DSM: String = "operatore-dsm";
  public OPERATORE_REGIONE: String = "operatore-regione";

  public getRuolo(ruolo: String) {
    this.getUtente();
    if (this.utente) {

      if (this.utente.nomeRuolo == ruolo) {

        return true;

      }

    }

    return false;

  }

  public getUtente() {

    var usr = sessionStorage.getItem("utente");

    if (usr != null && usr != undefined) {

      this.utente = JSON.parse(usr);
    }

  }

  /**
   * Download file xsd
   * @param name
   */
  public downloadXsd(name: string): void {

    this.apiService.downloadXsd(name)
      .subscribe({

        next: (data: any): void => {

          const blob: Blob = new Blob([data], {type: 'application/xml'});
          const xsdFileName: string = name + '.xsd';
          saveAs(blob, xsdFileName);

        }, error: (e): void => {

          alert(JSON.stringify(e));

        }

      });

  }

  public aslList: Asl[] = [];
  defaultSelectAsl:string|undefined = "";

  public async getAllAsl() {
    this.apiService.getAllAsl().subscribe(data => {
      for (let item of data) {
        if (item.codiceAzienda != "98" && item.codiceAzienda != "098" && item.codiceAzienda != "998" && item.codiceAzienda != "999") {
          this.aslList.push(item);
        }
      }
    });
  }

  changeAsl() {
    // this.resetData();
    this.getAllStrutture()
    // this.getData();
  }

  private async activateStrutture() {
    this.sessionService.strutturaChanged.subscribe(res => {
      if (res) {
        this.getAllStrutture();
      }
    });
  }

  cambioDimensionePaginaSelezionata(event: any) {
    this.pagina.number =
      Math.round(
        this.pagina.number * (
          this.pagina.size / event.target.value
        ));
    this.pagina.size = event.target.value;
    this.resetPagina=true;
    this.cercaPaginaAnagrafica();
  }

  entitaSelezionata() {
    return !!this.getEntita();
  }

  statoAttualeIsSet() {
    return !!this.ricercaForm.get('stato_attuale')?.value;
    ;
  }
}

class UploadXmlAnagraficheRecordDto {
  caricati!: number
  errati!: number
  validi!: number
}

class UploadXmlAnagraficheResponseDto {
  anagraficheDto!: UploadXmlAnagraficheRecordDto[]
  contattiDto !: UploadXmlAnagraficheRecordDto[]
  prestazioniDto!: UploadXmlAnagraficheRecordDto[]
  diagnosiDto!: UploadXmlAnagraficheRecordDto[]
  interventiDto!: UploadXmlAnagraficheRecordDto[]
  presenzeDto!: UploadXmlAnagraficheRecordDto[]
  success!: boolean
}
