<div class="container py-3">
  <!-- Ricerca -->
  <div class="card">
    <div class="card-header">
<!--      <nav aria-label="breadcrumb">-->
<!--        <ol class="breadcrumb">-->
<!--          <li class="breadcrumb-item"><a href="javascript:;">Dashboard</a></li>-->
<!--          <li class="breadcrumb-item">Monitoraggio</li>-->
<!--          <li class="breadcrumb-item">-->
<!--            <a href="">Flussi in ingresso</a>-->
<!--          </li>-->
<!--        </ol>-->
<!--      </nav>-->

      <div class="title-3">Flusso in uscita #{{ id }}</div>
    </div>
    <div *ngIf="true"></div>
    <div class="card-body">
      <div class="pt-2"><strong>Flusso: </strong>{{ flusso }}</div>
      <div class="pt-2"><strong>Struttura: </strong>{{ struttura }}</div>
      <div class="pt-2"><strong>Data e ora: </strong>{{ data }}</div>
      <div class="pt-2"><strong>Attività: </strong> {{ attivita }}</div>
      <div class="pt-2"><strong>Anno: </strong> {{ annoCompetenza }}</div>
      <div class="pt-2">
        <strong>Semestre: </strong>
        <!--        <span *ngIf="semestre == 'S1'">Primo semestre</span>-->
        <!--        <span *ngIf="semestre == 'S2'">Secondo semestre</span>-->
      </div>
      <div class="title-4 pt-5 pb-3">Tracciati</div>
      <div class="table-responsive">
        <div class="list-table table-even">
          <table
            class="table-colf table-lg"
            demon-cf="tableList"
            demon-cf-id="trasmesse"
            demon-done="3"
          >
            <thead>
            <tr>
              <th></th>
              <th>Stato</th>
              <!-- <th>Data</th> -->
              <th>DSM</th>
              <th>Codice fiscale</th>
              <th>Cognome e nome</th>
              <th>N° dati di contatto</th>
              <th>N° prestazioni</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let item of listaItems()">
              <tr>
                <td class="colf-check">
                  <div [hidden]="checkboxRipristinaHidden(item)">
                  <div class="form-group" style="margin: 0px;">
                    <div class="form-checkbox">
                      <div class="checkbox">
                        <input
                          id="checkbox-{{item.id}}"
                          type="checkbox"
                          name="trasmetti"
                          value="1"
                          [checked]="isChecked(item)"
                          (click)="checkboxClick(item)"  />
                        <label for="checkbox-{{item.id}}">&nbsp;</label>
                      </div>
                    </div>
                  </div>
                  </div>
                </td>
                <td class="colf-form" data-label="Stato">
                  <div>
                      {{item.descrizioneStato}}
                  </div>
                </td>
                <!-- <td data-label="Data">
                  <div>{{ item.data }}</div>
                </td> -->
                <td data-label="DSM">
                  <div>{{ item.dsm }}</div>
                </td>
                <td data-label="Codice fiscale">
                  <div>{{ item.codiceFiscale }}</div>
                </td>
                <td data-label="Cognome e nome">
                  <div>{{ item.nomeCognome }}</div>
                </td>
                <td data-label="N° dati di contatto">
                  <div>{{ item.nContatti }}</div>
                </td>
                <td data-label="N° prestazioni">
                  <div>{{ item.nPrestazioni }}</div>
                </td>
                <td data-label="">
                  <div>
                    <a class="link-u"
                       (click)="openDettagloPresaInCarico(item.id)"
                    >Dettaglio</a>
                  </div>
                </td>
              </tr>
            </ng-container>
            </tbody>
          </table>
        </div>
      </div>

      <div class="pt-5">
        <div class="form-group has-feedback">
          <label>Motivazione dello scarto</label>
          <textarea class="form-control" name="motivazioniScarti"
                    disabled>{{motivazioneScarto}}</textarea>
          <div class="form-feedback">
            <div class="feedback-text invalid-feedback">&nbsp;</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Toolbar -->
  <div class="btn-fixed" style="height: 64px">
    <div class="btn-toolbar">
      <div class="container">
        <div class="btn-group-left">
          <button type="button" class="btn btn-secondary" (click)="navigateBack()">
            Torna alla lista
          </button>
        </div>
        <div class="btn-group-center"></div>
        <div class="btn-group-right">
          <button [disabled]="ripristinaDisabled()"
                   type="button" class="btn btn-secondary"
                  (click)="ripristina()">Ripristina</button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- modal -->
<ng-template  let-modal>

</ng-template>
