import { Injectable } from '@angular/core';
import { GlobalConstants } from "../util/GlobalConstants";
import { BehaviorSubject, Observable } from "rxjs";
import {
  DettaglioAnagrafica,
  Contatto,
  Utente,
  StrutturaStr,
  Presenza,
  DiagnosiEScale,
  Intervento,
  Prestazione,
  AnagraficaSessionSettings
} from '../model/Models';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  //  tipologiaUtente:string = GlobalConstants.TipologiaUtente.REGIONE;

  public annoRicercaContatti:number|undefined;
  listDate:Date[]=[]

  private messageObjectSubject = new BehaviorSubject("");
  messageObject = this.messageObjectSubject.asObservable();


  private dettaglioAnagraficaBehaviorSubject = new BehaviorSubject<DettaglioAnagrafica>(new DettaglioAnagrafica());
  dettaglioAnagrafica = this.dettaglioAnagraficaBehaviorSubject.asObservable();


  private _loading = new BehaviorSubject<boolean>(false);
  public readonly loading$ = this._loading.asObservable();


  private selectedAnagraficaSubject = new BehaviorSubject("");
  selectedAnagrafica = this.selectedAnagraficaSubject.asObservable();

  private selectedContattoSubject = new BehaviorSubject<Contatto>(new Contatto());
  selectedContatto = this.selectedContattoSubject.asObservable();

  // presenze
  private presenzeListSubject = new BehaviorSubject<Presenza[]>([]);
  presenzeList = this.presenzeListSubject.asObservable();

  // interventi
  private interventiListSubject = new BehaviorSubject<Intervento[]>([]);
  interventiList = this.interventiListSubject.asObservable();

  // diagnosiScale
  private diagnosiEscaleListSubject = new BehaviorSubject<DiagnosiEScale[]>([]);
  diagnosiEscaleList = this.diagnosiEscaleListSubject.asObservable();

  // prestazioni
  private prestazioniListSubject = new BehaviorSubject<Prestazione[]>([]);
  prestazioniList= this.prestazioniListSubject.asObservable();

  private anagraficaSessionSettings:AnagraficaSessionSettings = new AnagraficaSessionSettings();

  private selectedPrestazioneSubject = new BehaviorSubject("");
  selectedPrestazione = this.selectedPrestazioneSubject.asObservable()

  private selectedInterventoSubject = new BehaviorSubject("");
  selectedIntervento = this.selectedInterventoSubject.asObservable()

  private selectedDiagnosiScaleSubject = new BehaviorSubject("");
  selectedDiagnosiScale = this.selectedDiagnosiScaleSubject.asObservable()

  private utenteProfileSubject = new BehaviorSubject("");
  utenteProfile = this.utenteProfileSubject.asObservable()

  private selectedLottoSubject = new BehaviorSubject("");
  selectedLotto = this.selectedLottoSubject.asObservable()

/**
 * variabile usata per determinare quale tab deve essere aperto quando si accede alla pagina di dettaglio dell'anagrafica
 */
  private tabOpenAnagraficaSubject = new BehaviorSubject("");
  comingPathAnagrafica = this.tabOpenAnagraficaSubject.asObservable();


  private tabOpenContattoSubject = new BehaviorSubject("");
  comingPathContatto = this.tabOpenContattoSubject.asObservable();


  private strutturaChangedBehaviorSubject = new BehaviorSubject<boolean>(false);
  strutturaChanged = this.strutturaChangedBehaviorSubject.asObservable();

  private listContattiDataNotAvailableSubject = new BehaviorSubject<string[]>([]);
  listContattiDataNotAvailable = this.listContattiDataNotAvailableSubject.asObservable();

  private listDIagnosiEscaleDataNotAvailableSubject = new BehaviorSubject<string[]>([]);
  listDIagnosiEscaleDataNotAvailable = this.listDIagnosiEscaleDataNotAvailableSubject.asObservable();


  private listInterventiDataNotAvailableSubject = new BehaviorSubject<string[]>([]);
  listInterventiDataNotAvailable = this.listInterventiDataNotAvailableSubject.asObservable();


  private listPrestazioniDataNotAvailableSubject = new BehaviorSubject<string[]>([]);
  listPrestazioniDataNotAvailable = this.listPrestazioniDataNotAvailableSubject.asObservable();

  private listPresenzeDataNotAvailableSubject = new BehaviorSubject<string[]>([]);
  listPresenzeDataNotAvailable = this.listPresenzeDataNotAvailableSubject.asObservable();

  private refreshDataSubject = new BehaviorSubject<boolean>(false);
  refreshData = this.refreshDataSubject.asObservable();

  private selectedPresenzaBehaviorSubject = new BehaviorSubject<Presenza>(new Presenza());
  selectedPresenza = this.selectedPresenzaBehaviorSubject.asObservable();

  private listPresenzeBehaviorSubject = new BehaviorSubject<Presenza[]>([]);
  listPresenze = this.listPresenzeBehaviorSubject.asObservable();


  private contattiListSubject = new BehaviorSubject<Contatto[]>([]);
  contattiList = this.contattiListSubject.asObservable();

  private hasOpenContattiSubject = new BehaviorSubject<boolean>(false);
  hasOpenContatti = this.hasOpenContattiSubject.asObservable();


  setDettaglioAnagrafica(anagrafica: DettaglioAnagrafica) {
    this.dettaglioAnagraficaBehaviorSubject.next(anagrafica);
  }

  notifyComponent(message: string) {
    this.messageObjectSubject.next(message)
  }

  setSelectedAnagrafica(message: string){
    this.selectedAnagraficaSubject.next(message)
  }


  setSelectedContatto(contatto: Contatto){
    this.selectedContattoSubject.next(contatto)
    this.setInterventi(contatto.interventiList!!)
    this.setPresenze(contatto.presenzaList!)
    this.setPrestazioni(contatto.prestazioniList!)
    this.setDiagnosiEScale(contatto.diagnosiEScaleList!)
  }

  clearSelectedContatto(){
    //this.selectedContattoSubject.next()
    this.setInterventi([])
    this.setPresenze([])
    this.setPrestazioni([])
    this.setDiagnosiEScale([])
  }

  setSelectedPrestazione(message: string){
    this.selectedPrestazioneSubject.next(message)
  }

  setSelectedIntervento(message: string){
    this.selectedInterventoSubject.next(message)
  }

  setSelectedDiagnosiScale(message: string){
    this.selectedDiagnosiScaleSubject.next(message)
  }

  setUtenteProfile(message: string){
    this.utenteProfileSubject.next(message)
  }

  setOpenTabAnagrafica(message: string){
    this.tabOpenAnagraficaSubject.next(message)
  }
  setOpenTabContatto(message: string){
    this.tabOpenContattoSubject.next(message)
  }


  setDIagnosiEscaleDataNotAvailableList(listDate:string[]){

    this.listDIagnosiEscaleDataNotAvailableSubject.next(listDate)

  }

  setInterventiDataNotAvailableList(listDate:string[]){

    this.listInterventiDataNotAvailableSubject.next(listDate)

  }

  setContattiDataNotAvailableList(listDate:string[]){

    this.listContattiDataNotAvailableSubject.next(listDate)

  }
  setPresenzeDataNotAvailableList(listDate:string[]){

    this.listPresenzeDataNotAvailableSubject.next(listDate)

  }

  setPrestazioniDataNotAvailableList(listDate:string[]){

    this.listPrestazioniDataNotAvailableSubject.next(listDate)

  }

  notifyStrutturaChanged(){
    this.strutturaChangedBehaviorSubject.next(true)
    this.strutturaChangedBehaviorSubject.next(false)

  }

  setRefreshdata(value:boolean){

    this.refreshDataSubject.next(value)
  }

  setUtenteOnStorage(utente$:Utente){

    sessionStorage.setItem("utente", JSON.stringify(utente$))
  }

  getUtenteFromStorage():Utente|null{
    let utenteString = sessionStorage.getItem("utente")
    if(utenteString != null){
      let utente = JSON.parse(utenteString)
      return utente;
    }else{
      return null;
    }

  }
  setSelectedStrutturaOnStorage(struttura:StrutturaStr){

    sessionStorage.setItem("struttura", JSON.stringify(struttura))
  }

  clearSelectedStrutturaFromStorage(){

    sessionStorage.removeItem("struttura")
  }

  isSelectedStrutturaFromStorageTerritoriale() : boolean{
        return this.getSelectedStrutturaFromStorage()!.descrTipoStruttura!.indexOf('TERRITORIALE')>0;
  }
  isSelectedStrutturaFromStorageSemiresidenziale() : boolean{
    return this.getSelectedStrutturaFromStorage()?this.getSelectedStrutturaFromStorage()!.descrTipoStruttura!.indexOf('SEMIRESIDENZIALE')>0:false;
  }
  getSelectedStrutturaFromStorage():StrutturaStr|null{
    let selectedStruttura = sessionStorage.getItem("struttura")
    if(selectedStruttura != null){
      return JSON.parse(selectedStruttura);
    }else{
      return null;
    }

  }



  setSelectedLotto(message: string){
    this.selectedLottoSubject.next(message)
  }

  clearUtenteFromStorage(){
    sessionStorage.removeItem("utente")
  }

  convertToDateStruct(mydate: Date): NgbDateStruct | null{
    return mydate ? {
      year: mydate.getFullYear(),
      month: mydate.getMonth() + 1,
      day: mydate.getDate()
    } : null;
  }

  equalsDate(date1: Date,date2: Date): boolean {
    return   date1.getFullYear() ==   date2.getFullYear()
      &&  date1.getMonth() ==   date2.getMonth()
      &&  date1.getDate() ==   date2.getDate();
  }

  toNativeDate(mydate: NgbDateStruct|null): Date | null{
    if(mydate==null){
      return null;
    } else {
        let res = mydate ? new Date(mydate.year, mydate.month - 1, mydate.day, 0,0,0) : null;
        if(res!=null){
          /**
           * annulla eventuali offset dovuti al time zone
           * vedi https://stackoverflow.com/questions/1486476/json-stringify-changes-time-of-date-because-of-utc
           */
          res = new Date(res.getTime() - (res.getTimezoneOffset() * 60000));
        }
        return res;
      }

  }


  setSelectedPresenza(presenza:Presenza){


    this.selectedPresenzaBehaviorSubject.next(presenza)
  }

  setListPresenze(listPresenza:Presenza[]){


    this.listPresenzeBehaviorSubject.next(listPresenza)
  }

  setListContatti(listContatti:Contatto[]){


    this.contattiListSubject.next(listContatti)
  }

  setHasOpenContatto(value:boolean){

    this.hasOpenContattiSubject.next(value)
  }

  setPresenze(presenzeList:Presenza[]){

    this.presenzeListSubject.next(presenzeList)
  }

  setInterventi(interventiList:Intervento[]){
    this.interventiListSubject.next(interventiList)
  }

  setDiagnosiEScale(diagnosiEScaleList:DiagnosiEScale[]){

    this.diagnosiEscaleListSubject.next(diagnosiEScaleList)
  }
  setPrestazioni(listPrestazioni:Prestazione[]){

    this.prestazioniListSubject.next(listPrestazioni)
  }


  setAnagraficaSessionSettings(anagraficaSessionSettings:AnagraficaSessionSettings){
    this.anagraficaSessionSettings = anagraficaSessionSettings;
  }

  getAnagraficaSessionSettings() : AnagraficaSessionSettings{
      return this.anagraficaSessionSettings;
  }

  setSelectedRuoloOnStorage(selectedRole: number) {
    sessionStorage.setItem("ruoloselezionato",String(selectedRole));
  }
  getSelectedRuoloOnStorage() : number|undefined{
    return sessionStorage.getItem("ruoloselezionato")?+(sessionStorage.getItem("ruoloselezionato")!):undefined;
  }

  clearSession() {
    sessionStorage.clear();
  }
}
