import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-showdata',
  templateUrl: './showdata.component.html',
  styleUrls: ['./showdata.component.css']
})
export class ShowdataComponent implements OnInit {

  @Input("misureValue")
  misureValue: any = null;
  @Input("misureLabel")
  misureLabel: string = "";
  @Input("misureColor")
  misureColor: string = "";
  @Input("misureIcon")
  misureIcon: string = "";
  @Input("misureClick")
  misureClick: string = "";
  @Input("misureYear")
  misureYear: number = new Date().getFullYear();
  constructor(private router: Router) { }

  ngOnInit(): void {
  }
  /**
   * Intercetta click su card
   * @param url 
   */
  cardClick(url: string) {

    if (url != null && url != undefined && url != "") {

      this.router.navigateByUrl(url + "&anno=" + this.misureYear);

    }

  }

}
