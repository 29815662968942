import { error } from '@angular/compiler/src/util';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as saveAs from 'file-saver';
import { Pagina } from 'src/app/components/table/paginator/paginazione-logic';
import { CreaIstanzaInvioResponse } from 'src/app/model/CreaIstanzaInvioResponse';
import { Asl, Page, Strutture, Utente } from 'src/app/model/Models';
import { TrasmissioneFlusso } from 'src/app/model/TrasmissioneFlusso';
import { ApiService } from 'src/app/services/api.service';
import { DocumentService } from 'src/app/services/document.service';
import { SessionService } from 'src/app/services/session.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  templateUrl: './invio-dati-a-regione.component.html',
  styleUrls: ['./invio-dati-a-regione.component.css']
})
export class InvioDatiARegioneComponent implements OnInit {

  ricercaForm!: FormGroup;
  currentYear = new Date().getFullYear();
  yearArray: any = [];
  mainUser!: Utente;
  strutture: Page<Strutture> = new Page<Strutture>();

  public aslList: Asl[] = [];
  tipoFlusso: string = '';

  idIstanzaFlusso:number = 0;
  dataScadenzaFlusso: Date = new Date('0000-00-00')

  contatti: number = -1;

  anagrafiche: number = -1;

  prestazioni: number = -1;

  numeroPaginaSelezionata: number = 0;
  dimensionePaginaSelezionata: number= 10;
  orederBy: string = 'createdOn';
  direction: string= 'DESC';

  showCounter: boolean = false;
  stringPre: String = '';

  paginaStorico:Page<TrasmissioneFlusso> = new Page<TrasmissioneFlusso>();


  etichette: {[key: number]: string} = {
    // 112: 'Gennaio',
    // 212: 'Febbraio',
    // 312: 'Marzo',
    // 412: 'Aprile',
    // 512: 'Maggio',
    // 612: 'Giugno',
    // 712: 'Luglio',
    // 812: 'Agosto',
    // 912: 'Settembre',
    // 1012: 'Ottobre',
    // 1112: 'Novembre',
    // 1212: 'Dicembre',
    // 101: 'Annuale',
    102: 'Primo semestre',
    702: 'Secondo semestre',
    // 103: 'Primo quadrimestre',
    // 503: 'Secondo quadrimestre',
    // 903: 'Terzo quadrimestre',
    // 104: 'Primo trimestre',
    // 404: 'Secondo trimestre',
    // 704: 'Terzo trimestre',
    // 1004: 'Quarto trimestre',
    // 106: 'Primo bimestre',
    // 306: 'Secondo bimestre',
    // 506: 'Terzo bimestre',
    // 706: 'Quarto bimestre',
    // 906: 'Quinto bimestre',
    // 1106: 'Sesto bimestre',
    };

  isLoading: boolean = false;
  showResult: boolean = false;
  resultInvii: CreaIstanzaInvioResponse[] = []



  constructor(private apiService: ApiService,
    private documentService: DocumentService,
    public toastService: ToastService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sessionService: SessionService) {
      this.paginaStorico.number = 0;
      this.paginaStorico.size = 10;
    }

  ngOnInit(): void {


    this.caricaStorico();

    this.populateYearArray();

    this.ricercaFormInit();

    this.loadUser();

    this.getAllAsl()

    this.getStrutture(undefined);

  }

  loadUser() {
    this.mainUser = this.sessionService.getUtenteFromStorage()!!

  }


  /**
   * Recupera ASL
   */
  public async getAllAsl() {

    this.apiService.getAllAsl().subscribe(data => {

      for(let item of data){

        if(item.codiceAzienda != "98" && item.codiceAzienda != "098" && item.codiceAzienda != "998" && item.codiceAzienda != "999"){

          this.aslList.push(item);

        }

      }

    });

  }

  private populateYearArray() {

    for (let i = this.currentYear - 1; i >= 1900; i--) {

      this.yearArray.push(i);

    }

  }

  private ricercaFormInit() {

    this.ricercaForm = new FormGroup({
      anno: new FormControl(""),
      flusso: new FormControl(""),
      etichetta: new FormControl(""),
      struttura: new FormControl(""),
      asl: new FormControl(""),
    });
    this.ricercaForm.get('anno')?.setValue(this.currentYear);
    this.ricercaForm.get('stato')?.setValue("2");

  }


  getStrutture(event: any ){

    let asl = '';
    if(event != undefined || event != '')
      asl = event.target.value;

    this.apiService.getStruttureAll("",asl).subscribe((data: any) => {
      this.strutture = data;

    });
  }

  changeFlusso( event: any )
  {
    if(event.target.value=='SISM-SEMIRES')
    {
      this.tipoFlusso = 'SEMIRESIDENZIALE';
      this.stringPre = 'presenze';
    }
    else if ( event.target.value=='SISM-TERR' )
    {
      this.tipoFlusso = 'TERRITORIALE';
      this.stringPre = 'prestazioni';
    }
    else
    {
      this.tipoFlusso = '';
      this.stringPre = '';
    }


  }

  printAslName(codiceAsl: string | undefined)
  {

    let ret: string | undefined = '';
    this.aslList.forEach(value => {
      if (value.codiceAzienda == codiceAsl) {
        ret = value.denominazioneAzienda;
      }
    })
    return ret;

  }


  searchFlussoAndContatti()
  {
    var anno = this.ricercaForm.get('anno')?.value;
    var flusso = this.ricercaForm.get('flusso')?.value
    var etichetta = this.ricercaForm.get('etichetta')?.value
    var struttura = this.ricercaForm.get('struttura')?.value
    var asl = this.ricercaForm.get('asl')?.value

    if ( anno && flusso && etichetta && asl )
    {
      this.documentService.getIstanzaFlusso( anno, flusso, etichetta, struttura, asl).subscribe((id: any) => {

        this.idIstanzaFlusso = id;

        if(id != 0)
        {
          this.documentService.getDataScadenzaFlusso(id).subscribe((data: any) => {
            this.dataScadenzaFlusso = data;
          })
        }

      },
      );


      this.documentService.getAnagrafiche( anno, flusso, etichetta, struttura, asl).subscribe((data: string) => {

        this.anagrafiche= Number(data);

      },
      );

      this.documentService.getContatti( anno, flusso, etichetta, struttura, asl).subscribe((data: string) => {
        this.contatti= Number(data);

      },
      );

      this.documentService.getPrestazioni( anno, flusso, etichetta, struttura, asl).subscribe((data: string) => {

        this.prestazioni= Number(data);

      },
      );


      this.showCounter = true;

    }
    else
    {
      this.prestazioni = 0;

      this.anagrafiche = 0;

      this.contatti = 0;

      this.idIstanzaFlusso = 0;

      this.showCounter = false;

    }

  }

  clearSearchResult(){
    this.prestazioni = 0;

    this.anagrafiche = 0;

    this.contatti = 0;

    this.idIstanzaFlusso = 0;

    this.showCounter = false;
  }


  inviaRegione()
  {

    this.isLoading = true;
    this.documentService.inviaDatiRegione( this.ricercaForm.get('anno')?.value,
                                        this.ricercaForm.get('flusso')?.value,
                                        this.ricercaForm.get('etichetta')?.value,
                                        this.ricercaForm.get('struttura')?.value,
                                        this.ricercaForm.get('asl')?.value).subscribe((data: any) => {

      // this.showResult= true
      this.toastService.show("File caricato correttamente");
      //this.resultInvii = data;

      this.rechargePage();


    },
    err => {
      this.toastService.showProblemaTecnico();
      this.isLoading = false;
    });

  }

  rechargePage()
  {

    this.caricaStorico();
    this.ricercaFormInit();
    this.isLoading = false;
    this.showResult = false;
    this.idIstanzaFlusso = 0;
    this.showCounter = false;

  }


  caricaStorico()
  {
    this.documentService.getStorico(
      this.paginaStorico.size,
      this.paginaStorico.number,
      this.orederBy,
      this.direction
    ).subscribe((data: any) => {
      console.log(data);
      this.paginaStorico = data;

      console.log(this.paginaStorico);
    });
  }

  returnFile( stringaFile: string | undefined)
  {
    let listNames = stringaFile?.split('||');

    return listNames;

  }

  downloadSingleFile(idTrasmissione: number | undefined, nomeFile: string)
  {
    this.documentService.downloadFileTrasmissione(idTrasmissione, nomeFile).subscribe(blob => {
      saveAs(blob,nomeFile);
    });
  }


  cambiaPagina(event: any) {
    this.caricaStorico();
  }

  changeOrder(event: any) {
    this.caricaStorico();

  }


  getFilesArchiviati(flusso: any) {
    return flusso.filesArchiviati?.split('||');
  }
}
