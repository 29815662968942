<div class="container py-3" demon-cf="prestazione" demon-cf-id="prestazione" id="demonView6-7" demon-ev="true">


  <div class="card">


    <div class="card-header">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">

          <li class="breadcrumb-item"><a (click)="$event.preventDefault();navigateToRicercaAnagrafica()"
                                         href="">Gestione
            pazienti</a></li>
          <li class="breadcrumb-item"><a (click)="$event.preventDefault();navigateToAnagrafica()"
                                         href="">{{getTitleName()}}</a></li>

        </ol>
      </nav>
      <div class="row">

        <div class="title-3 col-9" demon-prestazione="titolo">Intervento #{{intervento ? intervento.id : ''}}</div>
        <div class="col-3">

          <button class="btn btn-outline-secondary btn-sm"
                  *ngIf="(getRuolo(OPERATORE_STRUTTURA) || getRuolo(OPERATORE_REGIONE)) && intervento?.id != null && intervento?.id != undefined"
                  (click)="$event.preventDefault(); openAuditDialog(audit)">
            Storico modifiche
          </button>
        </div>
      </div>
    </div>


    <div class="card-body">
      <!-- Stato e motivazione scarto -->
      <div class="group pt-4 alert alert-danger" style="background-color: #ffe1cc !important;"
           *ngIf="intervento&&intervento.id != null && (intervento.statoAttuale.id == 4 ||intervento.statoAttuale.id ==  7 ||intervento.statoAttuale.id ==  9)">

        <div class="form-group field-10">
          Stato: {{intervento.statoAttuale.descrizione}}
        </div>
        <div class="form-group field-10" *ngIf="intervento.motivazioneScarto != null">
          Motivazione dello scarto: {{intervento.motivazioneScarto}}
        </div>
      </div>

      <form *ngIf="interventoForm" [formGroup]="interventoForm" (ngSubmit)="submitForm(interventoForm.value)"
            autocomplete="off">

        <div class="pt-5">

          <div class="group-inline">

            <!-- Data -->
            <div class="group-inline">
              <div class="form-group field-10 has-feedback">
                <label>Data*</label>
                <div class="input-group">
                  <input class="form-control" placeholder="aaaa-mm-gg" name="date"
                         [(ngModel)]="dateModel" ngbDatepicker #d="ngbDatepicker" [minDate]="minDate"
                         [maxDate]="maxDate" formControlName="date"
                         [readonly]="!hasWritePermission(mainUser) || campiPrimoInterventoReadonly() || mustDisableForm() || (this.contattoHasDataChiusura(contatto) && intervento.id)">

                  <button class="btn btn-secondary" (click)="d.toggle()" type="button"
                          [attr.disabled]="!hasWritePermission(mainUser)? false:null || isFirstInterventoOfContatto()? false:null || mustDisableForm()? false: null || (this.contattoHasDataChiusura(contatto) && intervento.id ? false: null)">
                    <img class="img-responsive" src="../assets/icons/calendar_ko.png"
                         style="width: 20px; height:20px">
                  </button>


                </div>

                <div *ngIf="interventoForm.hasError('required', 'date') && formSubmitted"
                     class="alert alert-danger">
                  Campo obbligatorio
                </div>

                <div *ngIf="interventoForm.hasError('dateExist', 'date') && formSubmitted"
                     class="alert alert-danger">
                  Per questa data è già presente un intevento
                </div>
                <div *ngIf="interventoForm.hasError('dataNonInIntervallo', 'date')
                                  && (interventoForm.controls['date'].touched || formSubmitted )"
                     class="alert alert-danger">
                  La data deve essere compresta tra l'apertura e la chiusura del contatto
                </div>

                <div *ngIf="interventoForm.controls['date'].hasError('ngbDate') && formSubmitted"
                     class="alert alert-danger">
                  Il formato della data non è valido
                </div>
              </div>
              <div class="form-group field-60">


              </div>
            </div>
            <!-- RispostaPrevalente -->
            <div class="form-group field-50 has-feedback">

              <div class="form-group field-30">
                <label>Risposta prevalente</label>
                <div>
                  <select class="form-control" name="rispostaPrevalente"
                          formControlName="rispostaPrevalente"
                          [attr.disabled]="!hasWritePermission(mainUser) || mustDisableForm()? false: null || (this.contattoHasDataChiusura(contatto) && intervento.id ? false: null)">
                    <option value=""></option>
                    <option [value]="rp.codice" *ngFor="let rp of listRispotePrevalenti">
                      {{rp.descrizione}}</option>

                  </select>
                </div>

              </div>
              <div *ngIf="interventoForm.hasError('required', 'rispostaPrevalente') && formSubmitted"
                   class="alert alert-danger">Campo obbligatorio
              </div>

            </div>


            <!-- PI GROUP -->
            <div class="container" *ngIf="rispostaPrevalenteIs12() || rispostaPrevalenteIsIn123(rispostaPrevalente)">
              <fieldset disabled="{{isContattoClosedAndLastIntervento()? false: null}}">
<!--                disabled="{{(this.contattoHasDataChiusura(contatto) && intervento.id != undefined) ? false: null}}">-->
                <!-- title -->
                <div class="row">

                  <div class="title-5">
                    Progetto di intervento
                  </div>

                </div>

                <hr class="py-0 mt-4">
                <!-- PI GROUP CHECKS-->
                <div class="row" *ngIf="rispostaPrevalenteIs12()">


                  <!-- PI-GROUP -->
                  <div class="container">

                    <div class="group-inline pt-4">

                      <!-- tratamento psicoterapeutico -->
                      <div class="form-group form-group-radio">
                        <label>Trattamento psicoterapeutico</label>

                        <div class="form-radio">
                          <div class="radio">
                            <input type="radio" name="piTrattamentoPsicoterapeutico"
                                   id="piTrattamentoPsicoterapeuticoSi" value="true"
                                   formControlName="piTrattamentoPsicoterapeutico"
                                   [attr.disabled]="(!hasWritePermission(mainUser)  || mustDisableForm())? false: null ">
                            <label for="piTrattamentoPsicoterapeuticoSi">Sì</label>
                          </div>
                          <div class="radio">
                            <input type="radio" name="piTrattamentoPsicoterapeutico"
                                   id="piTrattamentoPsicoterapeuticoNo" value="false"
                                   formControlName="piTrattamentoPsicoterapeutico"
                                   [attr.disabled]="(!hasWritePermission(mainUser) || mustDisableForm())? false: null ">
                            <label for="piTrattamentoPsicoterapeuticoNo">No</label>
                          </div>
                        </div>


                        <div
                          *ngIf="interventoForm.hasError('required', 'piTrattamentoPsicoterapeutico') && formSubmitted"
                          class="alert alert-danger">Campo obbligatorio
                        </div>

                      </div>

                      <!-- tratamento farmacologico -->
                      <div class="form-group form-group-radio">
                        <label>Trattamento farmacologico</label>
                        <div class="form-radio">
                          <div class="radio">
                            <input type="radio" name="piTrattamentoFarmacologico"
                                   id="piTrattamentoFarmacologicoSi" value="true"
                                   formControlName="piTrattamentoFarmacologico"
                                   [attr.disabled]="!hasWritePermission(mainUser) || mustDisableForm()? false: null ">
                            <label for="piTrattamentoFarmacologicoSi">Sì</label>
                          </div>
                          <div class="radio">
                            <input type="radio" name="piTrattamentoFarmacologico"
                                   id="piTrattamentoFarmacologicoNo" value="false"
                                   formControlName="piTrattamentoFarmacologico"
                                   [attr.disabled]="!hasWritePermission(mainUser) || mustDisableForm()? false: null ">
                            <label for="piTrattamentoFarmacologicoNo">No</label>
                          </div>
                        </div>

                        <div *ngIf="interventoForm.hasError('required', 'piTrattamentoFarmacologico') && formSubmitted"
                             class="alert alert-danger">Campo obbligatorio
                        </div>


                      </div>


                      <!-- interventi riabilitatvi -->
                      <div class="form-group form-group-radio">
                        <label>Interventi riabilitativi*</label>
                        <div class="form-radio">
                          <div class="radio">
                            <input type="radio" name="piInterventiRiabilitativi"
                                   id="piInterventiRiabilitativiSi" value="true"
                                   formControlName="piInterventiRiabilitativi"
                                   [attr.disabled]="!hasWritePermission(mainUser)  || mustDisableForm()? false: null ">
                            <label for="piInterventiRiabilitativiSi">Sì</label>
                          </div>
                          <div class="radio">
                            <input type="radio" name="piInterventiRiabilitativi"
                                   id="piInterventiRiabilitativiNo" value="false"
                                   formControlName="piInterventiRiabilitativi"
                                   [attr.disabled]="!hasWritePermission(mainUser)  || mustDisableForm()? false: null ">
                            <label for="piInterventiRiabilitativiNo">No</label>
                          </div>
                        </div>

                        <div *ngIf="interventoForm.hasError('required', 'piInterventiRiabilitativi') && formSubmitted"
                             class="alert alert-danger">Campo obbligatorio
                        </div>
                      </div>

                      <!-- inserimenti lavorativi -->
                      <div class="form-group form-group-radio">
                        <label>Inserimenti lavorativi*</label>
                        <div class="form-radio">
                          <div class="radio">
                            <input type="radio" name="piInserimentiLavorativi"
                                   id="piInserimentiLavorativiSi" value="true"
                                   formControlName="piInserimentiLavorativi"
                                   [attr.disabled]="!hasWritePermission(mainUser)  || mustDisableForm()? false: null">
                            <label for="piInserimentiLavorativiSi">Sì</label>
                          </div>
                          <div class="radio">
                            <input type="radio" name="piInserimentiLavorativi"
                                   id="piInserimentiLavorativiNo" value="false"
                                   formControlName="piInserimentiLavorativi"
                                   [attr.disabled]="!hasWritePermission(mainUser)  || mustDisableForm()? false: null">
                            <label for="piInserimentiLavorativiNo">No</label>
                          </div>
                        </div>

                        <div *ngIf="interventoForm.hasError('required', 'piInserimentiLavorativi') && formSubmitted"
                             class="alert alert-danger">Campo obbligatorio
                        </div>
                      </div>


                      <!-- interventi sociali -->
                      <div class="form-group form-group-radio">
                        <label>Interv. sociale e/o di rete*</label>
                        <div class="form-radio">
                          <div class="radio">
                            <input type="radio" name="piInterventoSociale"
                                   id="piInterventoSocialeSi" value="true"
                                   formControlName="piInterventoSociale"
                                   [attr.disabled]="!hasWritePermission(mainUser)  || mustDisableForm()? false: null">
                            <label for="piInterventoSocialeSi">Sì</label>
                          </div>
                          <div class="radio">
                            <input type="radio" name="piInterventoSociale"
                                   id="piInterventoSocialeNo" value="false"
                                   formControlName="piInterventoSociale"
                                   [attr.disabled]="!hasWritePermission(mainUser)  || mustDisableForm()? false: null">
                            <label for="piInterventoSocialeNo">No</label>
                          </div>
                        </div>

                        <div *ngIf="interventoForm.hasError('required', 'piInterventoSociale') && formSubmitted"
                             class="alert alert-danger">Campo obbligatorio
                        </div>
                      </div>


                      <!-- colloqui psichiatrici -->

                      <div class="form-group form-group-radio">
                        <label>Colloqui psichiatrici e psicologici di sostegno*</label>
                        <div class="form-radio">
                          <div class="radio">
                            <input type="radio" name="piColloquiSostegno"
                                   id="piColloquiSostegnoSi" value="true"
                                   formControlName="piColloquiSostegno"
                                   [attr.disabled]="!hasWritePermission(mainUser) || mustDisableForm()? false: null">
                            <label for="piColloquiSostegnoSi">Sì</label>
                          </div>
                          <div class="radio">
                            <input type="radio" name="piColloquiSostegno"
                                   id="piColloquiSostegnoNo" value="false"
                                   formControlName="piColloquiSostegno"
                                   [attr.disabled]="!hasWritePermission(mainUser) || mustDisableForm()? false: null">
                            <label for="piColloquiSostegnoNo">No</label>
                          </div>
                        </div>

                        <div *ngIf="interventoForm.hasError('required', 'piColloquiSostegno') && formSubmitted"
                             class="alert alert-danger">Campo obbligatorio
                        </div>
                      </div>

                      <!-- coinvolgimento ai familiari -->
                      <div class="form-group form-group-radio">
                        <label>Coinvolgimento/sostegno ai familiari*</label>
                        <div class="form-radio">
                          <div class="radio">
                            <input type="radio" name="piCoinvolgimentoFamiliari"
                                   id="piCoinvolgimentoFamiliariSi" value="true"
                                   formControlName="piCoinvolgimentoFamiliari"
                                   [attr.disabled]="!hasWritePermission(mainUser)  || mustDisableForm()? false: null">
                            <label for="piCoinvolgimentoFamiliariSi">Sì</label>
                          </div>
                          <div class="radio">
                            <input type="radio" name="piCoinvolgimentoFamiliari"
                                   id="piCoinvolgimentoFamiliariNo" value="false"
                                   formControlName="piCoinvolgimentoFamiliari"
                                   [attr.disabled]="!hasWritePermission(mainUser)  || mustDisableForm()? false: null">
                            <label for="piCoinvolgimentoFamiliariNo">No</label>
                          </div>
                        </div>

                        <div *ngIf="interventoForm.hasError('required', 'piCoinvolgimentoFamiliari') && formSubmitted"
                             class="alert alert-danger">Campo obbligatorio
                        </div>
                      </div>

                      <!-- acertamenti medico legai -->
                      <div class="form-group form-group-radio">
                        <label>Accertamenti medico legali</label>
                        <div class="form-radio">
                          <div class="radio">
                            <input type="radio" name="piAccertamentiMedicoLegali"
                                   id="piAccertamentiMedicoLegaliSi" value="true"
                                   formControlName="piAccertamentiMedicoLegali"
                                   [attr.disabled]="!hasWritePermission(mainUser) || mustDisableForm()? false: null">
                            <label for="piAccertamentiMedicoLegaliSi">Sì</label>
                          </div>
                          <div class="radio">
                            <input type="radio" name="piAccertamentiMedicoLegali"
                                   id="piAccertamentiMedicoLegaliNo" value="false"
                                   formControlName="piAccertamentiMedicoLegali"
                                   [attr.disabled]="!hasWritePermission(mainUser) || mustDisableForm()? false: null">
                            <label for="piAccertamentiMedicoLegaliNo">No</label>
                          </div>
                        </div>

                        <div *ngIf="interventoForm.hasError('required', 'piAccertamentiMedicoLegali') && formSubmitted"
                             class="alert alert-danger">Campo obbligatorio
                        </div>
                      </div>

                      <!-- invio in struttura -->
                      <div class="form-group form-group-radio">
                        <label>Invio in struttura residenziale/semires.</label>
                        <div class="form-radio">
                          <div class="radio">
                            <input type="radio" name="piInvioInStruttura"
                                   id="piInvioInStrutturaSi" value="true"
                                   formControlName="piInvioInStruttura"
                                   [attr.disabled]="!hasWritePermission(mainUser)  || mustDisableForm()? false: null">
                            <label for="piInvioInStrutturaSi">Sì</label>
                          </div>
                          <div class="radio">
                            <input type="radio" name="piInvioInStruttura"
                                   id="piInvioInStrutturaNo" value="false"
                                   formControlName="piInvioInStruttura"
                                   [attr.disabled]="!hasWritePermission(mainUser) || mustDisableForm()? false: null">
                            <label for="piInvioInStrutturaNo">No</label>
                          </div>
                        </div>

                        <div *ngIf="interventoForm.hasError('required', 'piInvioInStruttura') && formSubmitted"
                             class="alert alert-danger">Campo obbligatorio
                        </div>
                      </div>

                      <!-- invio in ospedale -->
                      <div class="form-group form-group-radio">
                        <label>Invio in ospedale</label>
                        <div class="form-radio">
                          <div class="radio">
                            <input type="radio" name="piInvioInOspedale"
                                   id="piInvioInOspedaleSi" value="true"
                                   formControlName="piInvioInOspedale"
                                   [attr.disabled]="!hasWritePermission(mainUser) || mustDisableForm()? false: null">
                            <label for="piInvioInOspedaleSi">Sì</label>
                          </div>
                          <div class="radio">
                            <input type="radio" name="piInvioInOspedale"
                                   id="piInvioInOspedaleNo" value="false"
                                   formControlName="piInvioInOspedale"
                                   [attr.disabled]="!hasWritePermission(mainUser) || mustDisableForm()? false: null">
                            <label for="piInvioInOspedaleNo">No</label>
                          </div>
                        </div>

                        <div *ngIf="interventoForm.hasError('required', 'piInvioInOspedale') && formSubmitted"
                             class="alert alert-danger">Campo obbligatorio
                        </div>
                      </div>

                    </div>

                  </div>

                </div>
                <!-- PI GROUP PI_CONS ALTRO -->
                <div class="row" *ngIf="rispostaPrevalenteIsIn123(rispostaPrevalente)">

                  <!-- consultazione valutazione -->
                  <div class="form-group form-group-radio">
                    <label>Consultazione valutazione</label>
                    <div class="form-radio">
                      <div class="radio">
                        <input type="radio" name="piConsultazioneValutazione"
                               id="piConsultazioneValutazioneSi" value="true"
                               formControlName="piConsultazioneValutazione"
                               [attr.disabled]="!hasWritePermission(mainUser) || mustDisableForm()? false: null">
                        <label for="piConsultazioneValutazioneSi">Sì</label>
                      </div>
                      <div class="radio">
                        <input type="radio" name="piConsultazioneValutazione"
                               id="piConsultazioneValutazioneNo" value="false"
                               formControlName="piConsultazioneValutazione"
                               [attr.disabled]="!hasWritePermission(mainUser) || mustDisableForm()? false: null">
                        <label for="piConsultazioneValutazioneNo">No</label>

                      </div>
                    </div>
                    <div *ngIf="interventoForm.hasError('required', 'piConsultazioneValutazione') && formSubmitted"
                         class="alert alert-danger">Campo obbligatorio
                    </div>
                  </div>

                  <!-- altro -->
                  <div class="form-group form-group-altro">
                    <label>Altro</label>
                    <input type="text" class="form-control" id="piAltro" name="piAltro"
                           formControlName="piAltro"
                           [attr.disabled]="!hasWritePermission(mainUser) || mustDisableForm()? false: null">


                    <div *ngIf="interventoForm.hasError('required', 'piAltro') && formSubmitted"
                         class="alert alert-danger">
                      Campo obbligatorio
                    </div>

                  </div>
                </div>
              </fieldset>
            </div>


            <!-- IE GROUP -->
            <div class="container" *ngIf="sectionIeVisible && isContattoClosedAndLastIntervento()">

              <!-- tittle -->
              <div class="row">

                <div class="title-5">
                  Intervento effettuato durante il contatto
                </div>
              </div>


              <hr class="py-0 mt-4">

              <!-- first ie-section checks-->
              <div class="row" *ngIf="!rispostaPrevalenteIs3(rispostaPrevalente)">

                <div class="group-inline pt-4">

                  <!-- tratamento psicoterapeutico -->
                  <div class="form-group form-group-radio">
                    <label>Trattamento psicoterapeutico</label>

                    <div class="form-radio">
                      <div class="radio">
                        <input type="radio" name="ieTrattamentoPsicoterapeutico"
                               id="ieTrattamentoPsicoterapeuticoSi" value="true"
                               formControlName="ieTrattamentoPsicoterapeutico"
                               [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                        <label for="ieTrattamentoPsicoterapeuticoSi">Sì</label>
                      </div>
                      <div class="radio">
                        <input type="radio" name="ieTrattamentoPsicoterapeutico"
                               id="ieTrattamentoPsicoterapeuticoNo" value="false"
                               formControlName="ieTrattamentoPsicoterapeutico"
                               [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                        <label for="ieTrattamentoPsicoterapeuticoNo">No</label>
                      </div>
                    </div>


                    <div *ngIf="interventoForm.hasError('required', 'ieTrattamentoPsicoterapeutico') && formSubmitted"
                         class="alert alert-danger">Campo obbligatorio
                    </div>


                  </div>

                  <!-- tratamento farmacologico -->
                  <div class="form-group form-group-radio">
                    <label>Trattamento farmacologico</label>
                    <div class="form-radio">
                      <div class="radio">
                        <input type="radio" name="ieTrattamentoFarmacologico"
                               id="ieTrattamentoFarmacologicoSi" value="true"
                               formControlName="ieTrattamentoFarmacologico"
                               [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                        <label for="ieTrattamentoFarmacologicoSi">Sì</label>
                      </div>
                      <div class="radio">
                        <input type="radio" name="ieTrattamentoFarmacologico"
                               id="ieTrattamentoFarmacologicoNo" value="false"
                               formControlName="ieTrattamentoFarmacologico"
                               [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                        <label for="ieTrattamentoFarmacologicoNo">No</label>
                      </div>
                    </div>

                    <div *ngIf="interventoForm.hasError('required', 'ieTrattamentoFarmacologico') && formSubmitted"
                         class="alert alert-danger">Campo obbligatorio
                    </div>


                  </div>


                  <!-- intervento riabilitativi -->
                  <div class="form-group form-group-radio">
                    <label>Interventi riabilitativi</label>
                    <div class="form-radio">
                      <div class="radio">
                        <input type="radio" name="ieInterventiRiabilitativi"
                               id="ieInterventiRiabilitativiSi" value="true"
                               formControlName="ieInterventiRiabilitativi"
                               [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                        <label for="ieInterventiRiabilitativiSi">Sì</label>
                      </div>
                      <div class="radio">
                        <input type="radio" name="ieInterventiRiabilitativi"
                               id="ieInterventiRiabilitativiNo" value="false"
                               formControlName="ieInterventiRiabilitativi"
                               [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                        <label for="ieInterventiRiabilitativiNo">No</label>
                      </div>
                    </div>

                    <div *ngIf="interventoForm.hasError('required', 'ieInterventiRiabilitativi') && formSubmitted"
                         class="alert alert-danger">Campo obbligatorio
                    </div>
                  </div>

                  <!-- inserimenti lavorativi -->
                  <div class="form-group form-group-radio">
                    <label>Inserimenti lavorativi</label>
                    <div class="form-radio">
                      <div class="radio">
                        <input type="radio" name="ieInserimentiLavorativi"
                               id="ieInserimentiLavorativiSi" value="true"
                               formControlName="ieInserimentiLavorativi"
                               [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                        <label for="ieInserimentiLavorativiSi">Sì</label>
                      </div>
                      <div class="radio">
                        <input type="radio" name="ieInserimentiLavorativi"
                               id="ieInserimentiLavorativiNo" value="false"
                               formControlName="ieInserimentiLavorativi"
                               [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                        <label for="ieInserimentiLavorativiNo">No</label>
                      </div>
                    </div>

                    <div *ngIf="interventoForm.hasError('required', 'ieInserimentiLavorativi') && formSubmitted"
                         class="alert alert-danger">Campo obbligatorio
                    </div>
                  </div>

                  <!-- interventi sociali -->
                  <div class="form-group form-group-radio">
                    <label>Interv. sociale e/o di rete</label>
                    <div class="form-radio">
                      <div class="radio">
                        <input type="radio" name="ieInterventoSociale"
                               id="ieInterventoSocialeSi" value="true"
                               formControlName="ieInterventoSociale"
                               [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                        <label for="ieInterventoSocialeSi">Sì</label>
                      </div>
                      <div class="radio">
                        <input type="radio" name="ieInterventoSociale"
                               id="ieInterventoSocialeNo" value="false"
                               formControlName="ieInterventoSociale"
                               [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                        <label for="ieInterventoSocialeNo">No</label>
                      </div>
                    </div>

                    <div *ngIf="interventoForm.hasError('required', 'ieInterventoSociale') && formSubmitted"
                         class="alert alert-danger">Campo obbligatorio
                    </div>
                  </div>


                  <!-- colloqui psichiatrici -->
                  <div class="form-group form-group-radio">
                    <label>Colloqui psichiatrici e psicologici di sostegno</label>
                    <div class="form-radio">
                      <div class="radio">
                        <input type="radio" name="ieColloquiSostegno" id="ieColloquiSostegnoSi"
                               value="true" formControlName="ieColloquiSostegno"
                               [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                        <label for="ieColloquiSostegnoSi">Sì</label>
                      </div>
                      <div class="radio">
                        <input type="radio" name="ieColloquiSostegno" id="ieColloquiSostegnoNo"
                               value="false" formControlName="ieColloquiSostegno"
                               [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                        <label for="ieColloquiSostegnoNo">No</label>
                      </div>
                    </div>

                    <div *ngIf="interventoForm.hasError('required', 'ieColloquiSostegno') && formSubmitted"
                         class="alert alert-danger">Campo obbligatorio
                    </div>
                  </div>

                  <!-- coinvolgimento sostegno familiari -->
                  <div class="form-group form-group-radio">
                    <label>Coinvolgimento/sostegno ai familiari</label>
                    <div class="form-radio">
                      <div class="radio">
                        <input type="radio" name="ieCoinvolgimentoFamiliari"
                               id="ieCoinvolgimentoFamiliariSi" value="true"
                               formControlName="ieCoinvolgimentoFamiliari"
                               [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                        <label for="ieCoinvolgimentoFamiliariSi">Sì</label>
                      </div>
                      <div class="radio">
                        <input type="radio" name="ieCoinvolgimentoFamiliari"
                               id="ieCoinvolgimentoFamiliariNo" value="false"
                               formControlName="ieCoinvolgimentoFamiliari"
                               [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                        <label for="ieCoinvolgimentoFamiliariNo">No</label>
                      </div>
                    </div>

                    <div *ngIf="interventoForm.hasError('required', 'ieCoinvolgimentoFamiliari') && formSubmitted"
                         class="alert alert-danger">Campo obbligatorio
                    </div>
                  </div>

                  <!-- accertamenti medico legali -->
                  <div class="form-group form-group-radio">
                    <label>Accertamenti medico legali</label>
                    <div class="form-radio">
                      <div class="radio">
                        <input type="radio" name="ieAccertamentiMedicoLegali"
                               id="ieAccertamentiMedicoLegaliSi" value="true"
                               formControlName="ieAccertamentiMedicoLegali"
                               [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                        <label for="ieAccertamentiMedicoLegaliSi">Sì</label>
                      </div>
                      <div class="radio">
                        <input type="radio" name="ieAccertamentiMedicoLegali"
                               id="ieAccertamentiMedicoLegaliNo" value="false"
                               formControlName="ieAccertamentiMedicoLegali"
                               [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                        <label for="ieAccertamentiMedicoLegaliNo">No</label>
                      </div>
                    </div>

                    <div *ngIf="interventoForm.hasError('required', 'ieAccertamentiMedicoLegali') && formSubmitted"
                         class="alert alert-danger">Campo obbligatorio
                    </div>
                  </div>

                  <!-- invio in struttura residenziale/semiresidenziale -->
                  <div class="form-group form-group-radio">
                    <label>Invio in struttura residenziale/semires.</label>
                    <div class="form-radio">
                      <div class="radio">
                        <input type="radio" name="ieInvioInStruttura" id="ieInvioInStrutturaSi"
                               value="true" formControlName="ieInvioInStruttura"
                               [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                        <label for="ieInvioInStrutturaSi">Sì</label>
                      </div>
                      <div class="radio">
                        <input type="radio" name="ieInvioInStruttura" id="ieInvioInStrutturaNo"
                               value="false" formControlName="ieInvioInStruttura"
                               [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                        <label for="ieInvioInStrutturaNo">No</label>
                      </div>
                    </div>

                    <div *ngIf="interventoForm.hasError('required', 'ieInvioInStruttura') && formSubmitted"
                         class="alert alert-danger">Campo obbligatorio
                    </div>
                  </div>
                  <div style="background-color: #dfdfdf; width: 100%">
                    <div class="row align-items-center"
                         *ngIf="this.invioInStrutturaSelected && !rispostaPrevalenteIs3(rispostaPrevalente)">

                      <div class="row">
                        <div *ngIf="interventoForm.hasError('invioNotSelected', 'ieInvioInStruttura')"
                             class="alert alert-danger">Si deve selezionare almeno una tra i valori degli
                          invii
                        </div>
                      </div>

                      <div class="group-inline pt-4">


                        <!-- invio in struttura -->
                        <div class="form-group form-group-radio">
                          <label>Invio struttura STIPT</label>
                          <div class="form-radio">
                            <div class="radio">
                              <input type="radio" name="ieInvioStpit" id="ieInvioStpitSi" value="true"
                                     formControlName="ieInvioStpit"
                                     [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                              <label for="ieInvioStpitSi">Sì</label>
                            </div>
                            <div class="radio">
                              <input type="radio" name="ieInvioStpit" id="ieInvioStpitNo"
                                     value="false" formControlName="ieInvioStpit"
                                     [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                              <label for="ieInvioStpitNo">No</label>
                            </div>
                          </div>

                          <div *ngIf="interventoForm.hasError('required', 'ieInvioStpit') && formSubmitted"
                               class="alert alert-danger">Campo obbligatorio
                          </div>
                        </div>

                        <!-- luogo struttura stpit -->
                        <div class="form-group form-group-radio" *ngIf="ieInvioStpitSelected">
                          <label>Luogo struttura STIPT</label>
                          <div class="form-radio">
                            <div class="radio">
                              <input type="radio" name="ieLuogoStpit" id="ieLuogoStpitRegione"
                                     value="true" formControlName="ieLuogoStpit"
                                     [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                              <label for="ieLuogoStpitRegione">Regione</label>
                            </div>
                            <div class="radio">
                              <input type="radio" name="ieLuogoStpit" id="ieLuogoStpitFuoriRegione"
                                     value="false" formControlName="ieLuogoStpit"
                                     [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                              <label for="ieLuogoStpitFuoriRegione">Fuori regione</label>
                            </div>
                          </div>

                          <div *ngIf="interventoForm.hasError('required', 'ieLuogoStpit') && formSubmitted"
                               class="alert alert-danger">Campo obbligatorio
                          </div>
                        </div>

                        <!-- invio in struttura SRTRI -->
                        <div class="form-group form-group-radio">
                          <label>Invio struttura SRTRI</label>
                          <div class="form-radio">
                            <div class="radio">
                              <input type="radio" name="ieInvioSrtri" id="ieInvioSrtriSi" value="true"
                                     formControlName="ieInvioSrtri"
                                     [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                              <label for="ieInvioSrtriSi">Si</label>
                            </div>
                            <div class="radio">
                              <input type="radio" name="ieInvioSrtri" id="ieInvioSrtriNo"
                                     value="false" formControlName="ieInvioSrtri"
                                     [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                              <label for="ieInvioSrtriNo">No</label>
                            </div>
                          </div>

                          <div *ngIf="interventoForm.hasError('required', 'ieInvioSrtri') && formSubmitted"
                               class="alert alert-danger">Campo obbligatorio
                          </div>
                        </div>

                        <!-- luogo struttura SRTRI -->
                        <div class="form-group form-group-radio" *ngIf="ieInvioSrtriSelected">
                          <label>Luogo struttura SRTRI</label>
                          <div class="form-radio">
                            <div class="radio">
                              <input type="radio" name="ieLuogoSrtri" id="ieLuogoSrtriSi" value="true"
                                     formControlName="ieLuogoSrtri"
                                     [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                              <label for="ieLuogoSrtriSi">Regione</label>
                            </div>
                            <div class="radio">
                              <input type="radio" name="ieLuogoSrtri" id="ieLuogoSrtriNo"
                                     value="false" formControlName="ieLuogoSrtri"
                                     [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                              <label for="ieLuogoSrtriNo">Fuori Regione</label>
                            </div>
                          </div>

                          <div *ngIf="interventoForm.hasError('required', 'ieLuogoSrtri') && formSubmitted"
                               class="alert alert-danger">Campo obbligatorio
                          </div>
                        </div>


                        <!-- invio struttura SRTRE -->
                        <div class="form-group form-group-radio">
                          <label>Invio struttura SRTRE</label>
                          <div class="form-radio">
                            <div class="radio">
                              <input type="radio" name="ieInvioSrtre" id="ieInvioSrtreSi" value="true"
                                     formControlName="ieInvioSrtre"
                                     [attr.disabled]="!hasWritePermission(mainUser)? false:null">
                              <label for="ieInvioSrtreSi">Si</label>
                            </div>
                            <div class="radio">
                              <input type="radio" name="ieInvioSrtre" id="ieInvioSrtreNo"
                                     value="false" formControlName="ieInvioSrtre"
                                     [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                              <label for="ieInvioSrtreNo">No</label>
                            </div>
                          </div>

                          <div *ngIf="interventoForm.hasError('required', 'ieInvioSrtre') && formSubmitted"
                               class="alert alert-danger">Campo obbligatorio
                          </div>
                        </div>

                        <!-- luogo struttura SRTRE -->
                        <div class="form-group form-group-radio" *ngIf="ieInvioSrtreSelected">
                          <label>Luogo struttura SRTRE</label>
                          <div class="form-radio">
                            <div class="radio">
                              <input type="radio" name="ieLuogoSrtre" id="ieLuogoSrtreSi" value="true"
                                     formControlName="ieLuogoSrtre"
                                     [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                              <label for="ieLuogoSrtreSi">Regione</label>
                            </div>
                            <div class="radio">
                              <input type="radio" name="ieLuogoSrtre" id="ieLuogoSrtreNo"
                                     value="false" formControlName="ieLuogoSrtre"
                                     [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                              <label for="ieLuogoSrtreNo">Fuori regione</label>
                            </div>
                          </div>

                          <div *ngIf="interventoForm.hasError('required', 'ieLuogoSrtre') && formSubmitted"
                               class="alert alert-danger">Campo obbligatorio
                          </div>
                        </div>

                        <!-- invio struttura srs24 -->
                        <div class="form-group form-group-radio">
                          <label>Invio struttura SRSR24</label>
                          <div class="form-radio">
                            <div class="radio">
                              <input type="radio" name="ieInvioSrsr24" id="ieInvioSrsr24Si"
                                     value="true" formControlName="ieInvioSrsr24"
                                     [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                              <label for="ieInvioSrsr24Si">Si</label>
                            </div>
                            <div class="radio">
                              <input type="radio" name="ieInvioSrsr24" id="ieInvioSrsr24No"
                                     value="false" formControlName="ieInvioSrsr24"
                                     [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                              <label for="ieInvioSrsr24No">No</label>
                            </div>
                          </div>

                          <div *ngIf="interventoForm.hasError('required', 'ieInvioSrsr24') && formSubmitted"
                               class="alert alert-danger">Campo obbligatorio
                          </div>
                        </div>

                        <!-- luogo struttura SRSR24 -->
                        <div class="form-group form-group-radio" *ngIf="ieInvioSrsr24Selected">
                          <label>Luogo struttura SRSR24</label>
                          <div class="form-radio">
                            <div class="radio">
                              <input type="radio" name="ieLuogoSrsr24" id="ieLuogoSrsr24Si"
                                     value="true" formControlName="ieLuogoSrsr24"
                                     [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                              <label for="ieLuogoSrsr24Si">Regione</label>
                            </div>
                            <div class="radio">
                              <input type="radio" name="ieLuogoSrsr24" id="ieLuogoSrsr24No"
                                     value="false" formControlName="ieLuogoSrsr24"
                                     [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                              <label for="ieLuogoSrsr24No">Fuori regione</label>
                            </div>
                          </div>

                          <div *ngIf="interventoForm.hasError('required', 'ieLuogoSrsr24') && formSubmitted"
                               class="alert alert-danger">Campo obbligatorio
                          </div>
                        </div>


                        <!-- invio struttura SRSR12 -->
                        <div class="form-group form-group-radio">
                          <label>Invio struttura SRSR12</label>
                          <div class="form-radio">
                            <div class="radio">
                              <input type="radio" name="ieInvioSrsr12" id="ieInvioSrsr12Si"
                                     value="true" formControlName="ieInvioSrsr12"
                                     [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                              <label for="ieInvioSrsr12Si">Si</label>
                            </div>
                            <div class="radio">
                              <input type="radio" name="ieInvioSrsr12" id="ieInvioSrsr12No"
                                     value="false" formControlName="ieInvioSrsr12"
                                     [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                              <label for="ieInvioSrsr12No">No</label>
                            </div>
                          </div>

                          <div *ngIf="interventoForm.hasError('required', 'ieInvioSrsr12') && formSubmitted"
                               class="alert alert-danger">Campo obbligatorio
                          </div>
                        </div>


                        <!-- Luogo struttura SRSR12 -->
                        <div class="form-group form-group-radio" *ngIf="ieInvioSrsr12Selected">
                          <label>Luogo struttura SRSR12</label>
                          <div class="form-radio">
                            <div class="radio">
                              <input type="radio" name="ieLuogoSrsr12" id="ieLuogoSrsr12Si"
                                     value="true" formControlName="ieLuogoSrsr12"
                                     [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                              <label for="ieLuogoSrsr12Si">Regione</label>
                            </div>
                            <div class="radio">
                              <input type="radio" name="ieLuogoSrsr12" id="ieLuogoSrsr12No"
                                     value="false" formControlName="ieLuogoSrsr12"
                                     [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                              <label for="ieLuogoSrsr12No">Fuori regione</label>
                            </div>
                          </div>

                          <div *ngIf="interventoForm.hasError('required', 'ieLuogoSrsr12') && formSubmitted"
                               class="alert alert-danger">Campo obbligatorio
                          </div>
                        </div>
                      </div>

                      <div class="group-inline pt-4">

                        <!-- invio in struttura gruppo appartamento (GA) -->
                        <div class="form-group form-group-radio">
                          <label>Invio in struttura gruppo appartamento (GA)</label>
                          <div class="form-radio">
                            <div class="radio">
                              <input type="radio" name="ieInvioGa" id="ieInvioGaSi" value="true"
                                     formControlName="ieInvioGa"
                                     [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                              <label for="ieInvioGaSi">Si</label>
                            </div>
                            <div class="radio">
                              <input type="radio" name="ieInvioGa" id="ieInvioGaNo" value="false"
                                     formControlName="ieInvioGa"
                                     [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                              <label for="ieInvioGaNo">No</label>
                            </div>
                          </div>

                          <div *ngIf="interventoForm.hasError('required', 'ieInvioGa') && formSubmitted"
                               class="alert alert-danger">Campo obbligatorio
                          </div>
                        </div>

                        <!-- Luogo struttura gruppo appartamento (GA) -->
                        <div class="form-group form-group-radio" *ngIf="ieInvioGaSelected">
                          <label>Luogo struttura gruppo appartamento (GA)</label>
                          <div class="form-radio">
                            <div class="radio">
                              <input type="radio" name="ieLuogoGa" id="ieLuogoGaSi" value="true"
                                     formControlName="ieLuogoGa"
                                     [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                              <label for="ieLuogoGaSi">Regione</label>
                            </div>
                            <div class="radio">
                              <input type="radio" name="ieLuogoGa" id="ieLuogoGaNo" value="false"
                                     formControlName="ieLuogoGa"
                                     [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                              <label for="ieLuogoGaNo">Fuori regione</label>
                            </div>
                          </div>

                          <div *ngIf="interventoForm.hasError('required', 'ieLuogoGa') && formSubmitted"
                               class="alert alert-danger">Campo obbligatorio
                          </div>
                        </div>


                        <!-- Invio in struttura centro diurno (CD) -->
                        <div class="form-group form-group-radio">
                          <label>Invio in struttura centro diurno (CD)</label>
                          <div class="form-radio">
                            <div class="radio">
                              <input type="radio" name="ieInvioCd" id="ieInvioCdSi" value="true"
                                     formControlName="ieInvioCd"
                                     [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                              <label for="ieInvioCdSi">Si</label>
                            </div>
                            <div class="radio">
                              <input type="radio" name="ieInvioCd" id="ieInvioCdNo" value="false"
                                     formControlName="ieInvioCd"
                                     [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                              <label for="ieInvioCdNo">No</label>
                            </div>
                          </div>

                          <div *ngIf="interventoForm.hasError('required', 'ieInvioCd') && formSubmitted"
                               class="alert alert-danger">Campo obbligatorio
                          </div>
                        </div>

                        <!-- Luogo struttura centro diurno (CD) -->
                        <div class="form-group form-group-radio" *ngIf="ieInvioCdSelected">
                          <label>Luogo struttura centro diurno (CD)</label>
                          <div class="form-radio">
                            <div class="radio">
                              <input type="radio" name="ieLuogoCd" id="ieLuogoCdSi" value="true"
                                     formControlName="ieLuogoCd"
                                     [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                              <label for="ieLuogoCdSi">Regione</label>
                            </div>
                            <div class="radio">
                              <input type="radio" name="ieLuogoCd" id="ieLuogoCdNo" value="false"
                                     formControlName="ieLuogoCd"
                                     [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                              <label for="ieLuogoCdNo">Fuori regione</label>
                            </div>
                          </div>

                          <div *ngIf="interventoForm.hasError('required', 'ieLuogoCd') && formSubmitted"
                               class="alert alert-danger">Campo obbligatorio
                          </div>
                        </div>


                        <!-- Invio in ospedale -->
                        <!-- <div class="form-group form-group-radio">
                            <label>Invio in ospedale</label>
                            <div class="form-radio">
                                <div class="radio">
                                    <input type="radio" name="ieInvioOspedale" id="ieInvioOspedaleSi"
                                        value="true" formControlName="ieInvioOspedale"
                                        [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                                    <label for="ieInvioOspedaleSi">Si</label>
                                </div>
                                <div class="radio">
                                    <input type="radio" name="ieInvioOspedale" id="ieInvioOspedaleNo"
                                        value="false" formControlName="ieInvioOspedale"
                                        [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                                    <label for="ieInvioOspedaleNo">No</label>
                                </div>
                            </div>

                            <div *ngIf="interventoForm.hasError('required', 'ieInvioOspedale') && formSubmitted"
                                class="alert alert-danger">Campo obbligatorio
                            </div>
                        </div> -->

                      </div>


                      <!-- <div class="group-inline pt-4">

                           Reparto ospedaliero psichiatrico (Rep.40)
                          <div class="form-group  form-group-radio" *ngIf="ieInvioOspedaleSelected">
                              <label>Reparto ospedaliero psichiatrico (Rep.40)</label>
                              <div class="form-radio">
                                  <div class="radio">
                                      <input type="radio" name="ieInvioOspedaleRep40"
                                          id="ieInvioOspedaleRep40Si" value="true"
                                          formControlName="ieInvioOspedaleRep40"
                                          [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                                      <label for="ieInvioOspedaleRep40Si">Si</label>
                                  </div>
                                  <div class="radio">
                                      <input type="radio" name="ieInvioOspedaleRep40"
                                          id="ieInvioOspedaleRep40No" value="false"
                                          formControlName="ieInvioOspedaleRep40"
                                          [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                                      <label for="ieInvioOspedaleRep40No">No</label>
                                  </div>
                              </div>

                              <div *ngIf="interventoForm.hasError('required', 'ieInvioOspedaleRep40') && formSubmitted"
                                  class="alert alert-danger">Campo obbligatorio
                              </div>
                          </div>


                          <div class="form-group form-group-radio" *ngIf="ieInvioOspedaleSelected">
                              <label>Luogo struttura ospedaliera</label>
                              <div class="form-radio">
                                  <div class="radio">
                                      <input type="radio" name="ieLuogoOspedale" id="ieLuogoOspedaleSi"
                                          value="true" formControlName="ieLuogoOspedale"
                                          [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                                      <label for="ieLuogoOspedaleSi">Regione</label>
                                  </div>
                                  <div class="radio">
                                      <input type="radio" name="ieLuogoOspedale" id="ieLuogoOspedaleNo"
                                          value="false" formControlName="ieLuogoOspedale"
                                          [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                                      <label for="ieLuogoOspedaleNo">Fuori regione</label>
                                  </div>
                              </div>

                              <div *ngIf="interventoForm.hasError('required', 'ieLuogoOspedale') && formSubmitted"
                                  class="alert alert-danger">Campo obbligatorio
                              </div>
                          </div>




                      </div> -->


                    </div>
                  </div>
                  <!-- Invio in ospedale -->
                  <div class="form-group form-group-radio">
                    <label>Invio in ospedale</label>
                    <div class="form-radio">
                      <div class="radio">
                        <input type="radio" name="ieInvioOspedale" id="ieInvioOspedaleSi"
                               value="true" formControlName="ieInvioOspedale"
                               [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                        <label for="ieInvioOspedaleSi">Si</label>
                      </div>
                      <div class="radio">
                        <input type="radio" name="ieInvioOspedale" id="ieInvioOspedaleNo"
                               value="false" formControlName="ieInvioOspedale"
                               [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                        <label for="ieInvioOspedaleNo">No</label>
                      </div>
                    </div>

                    <div *ngIf="interventoForm.hasError('required', 'ieInvioOspedale') && formSubmitted"
                         class="alert alert-danger">Campo obbligatorio
                    </div>
                  </div>
                  <div style="background-color: #dfdfdf; width: 100%">
                    <div class="group-inline pt-4"
                         *ngIf="ieInvioOspedaleSelected && !rispostaPrevalenteIs3(rispostaPrevalente)">

                      <!-- Reparto ospedaliero psichiatrico (Rep.40) -->
                      <div class="form-group  form-group-radio">
                        <label>Reparto ospedaliero psichiatrico (Rep.40)</label>
                        <div class="form-radio">
                          <div class="radio">
                            <input type="radio" name="ieInvioOspedaleRep40"
                                   id="ieInvioOspedaleRep40Si" value="true"
                                   formControlName="ieInvioOspedaleRep40"
                                   [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                            <label for="ieInvioOspedaleRep40Si">Si</label>
                          </div>
                          <div class="radio">
                            <input type="radio" name="ieInvioOspedaleRep40"
                                   id="ieInvioOspedaleRep40No" value="false"
                                   formControlName="ieInvioOspedaleRep40"
                                   [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                            <label for="ieInvioOspedaleRep40No">No</label>
                          </div>
                        </div>

                        <div *ngIf="interventoForm.hasError('required', 'ieInvioOspedaleRep40') && formSubmitted"
                             class="alert alert-danger">Campo obbligatorio
                        </div>
                      </div>


                      <div class="form-group form-group-radio">
                        <label>Luogo struttura ospedaliera</label>
                        <div class="form-radio">
                          <div class="radio">
                            <input type="radio" name="ieLuogoOspedale" id="ieLuogoOspedaleSi"
                                   value="true" formControlName="ieLuogoOspedale"
                                   [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                            <label for="ieLuogoOspedaleSi">Regione</label>
                          </div>
                          <div class="radio">
                            <input type="radio" name="ieLuogoOspedale" id="ieLuogoOspedaleNo"
                                   value="false" formControlName="ieLuogoOspedale"
                                   [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                            <label for="ieLuogoOspedaleNo">Fuori regione</label>
                          </div>
                        </div>

                        <div *ngIf="interventoForm.hasError('required', 'ieLuogoOspedale') && formSubmitted"
                             class="alert alert-danger">Campo obbligatorio
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group form-group-radio">
                    <label>Consultazione valutazione</label>
                    <div class="form-radio">
                      <div class="radio">
                        <input type="radio" name="ieConsultazioneValutazione"
                               id="ieConsultazioneValutazioneSi" value="true"
                               formControlName="ieConsultazioneValutazione"
                               [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                        <label for="ieConsultazioneValutazioneSi">Si</label>
                      </div>
                      <div class="radio">
                        <input type="radio" name="ieConsultazioneValutazione"
                               id="ieConsultazioneValutazioneNo" value="false"
                               formControlName="ieConsultazioneValutazione"
                               [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                        <label for="ieConsultazioneValutazioneNo">No</label>
                      </div>
                    </div>

                    <div *ngIf="interventoForm.hasError('required', 'ieConsultazioneValutazione') && formSubmitted"
                         class="alert alert-danger">Campo obbligatorio
                    </div>
                  </div>
                  <div class="form-group form-group-altro">


                    <!-- <div class="form-group field-wide"> -->
                    <label>Altro</label>
                    <input type="text" style="width: 80%" class="form-control" name="ieAltro" formControlName="ieAltro"
                           [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                    <!-- </div> -->

                    <div *ngIf="interventoForm.hasError('required', 'ieAltro') && formSubmitted"
                         class="alert alert-danger">Campo obbligatorio
                    </div>


                  </div>

                </div>

              </div>


              <!--hr class="py-0 mt-4"
                  *ngIf="(this.invioInStrutturaSelected || this.ieInvioOspedaleSelected) && !rispostaPrevalenteIs3(rispostaPrevalente)"-->

              <!-- consultazione valutazione -->

              <!-- second ie-section checks -->


              <!-- <hr class="py-0 mt-4" *ngIf="!rispostaPrevalenteIs3(rispostaPrevalente)"> -->

              <div *ngIf="rispostaPrevalenteIs3(rispostaPrevalente)" class="row">
                <div class="form-group form-group-radio">
                  <label>Consultazione valutazione</label>
                  <div class="form-radio">
                    <div class="radio">
                      <input type="radio" name="ieConsultazioneValutazione"
                             id="ieConsultazioneValutazioneSi" value="true"
                             formControlName="ieConsultazioneValutazione"
                             [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                      <label for="ieConsultazioneValutazioneSi">Si</label>
                    </div>
                    <div class="radio">
                      <input type="radio" name="ieConsultazioneValutazione"
                             id="ieConsultazioneValutazioneNo" value="false"
                             formControlName="ieConsultazioneValutazione"
                             [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                      <label for="ieConsultazioneValutazioneNo">No</label>
                    </div>
                  </div>

                  <div *ngIf="interventoForm.hasError('required', 'ieConsultazioneValutazione') && formSubmitted"
                       class="alert alert-danger">Campo obbligatorio
                  </div>
                </div>
                <div class="form-group form-group-altro">


                  <!-- <div class="form-group field-wide"> -->
                  <label>Altro</label>
                  <input type="text" style="width: 80%" class="form-control" name="ieAltro" formControlName="ieAltro"
                         [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false: null">
                  <!-- </div> -->

                  <div *ngIf="interventoForm.hasError('required', 'ieAltro') && formSubmitted"
                       class="alert alert-danger">Campo obbligatorio
                  </div>


                </div>

              </div>


            </div>


          </div>


        </div>


      </form>
    </div>
  </div>

  <block-ui>

  </block-ui>

  <!-- Toolbar -->
  <div class="btn-fixed fixed" demon-cf="toolbarFixed" demon-ev="true" demon-cf-id="8">
    <div class="btn-toolbar">
      <div class="container">
        <div class="btn-group-left">
          <button type="button" class="btn btn-outline-secondary" demon-bt="indietro" demon-cf="ModalConfirm"
                  demon-body="Tornando indietro perderai i dati inseriti nella prestazione, vuoi continuare comunque?"
                  demon-call="$D._sbObj.oprestazione.evIndietro" demon-ev="true" demon-cf-id="9"
                  (click)="openGoBackDialog(content)">
            Torna alla lista
          </button>
        </div>
        <div class="btn-group-right">

          <button [attr.disabled]=" mustDisableCancelBtn()? false: null" *ngIf="cancellaVisibile()" type="button"
                  class="btn btn-outline-danger"
                  (click)="openCancellaDialog(cancella)">Cancella
          </button>
        </div>
        <div class="btn-group-right">
          <button [attr.disabled]=" mustDisableForm()? false: null" *ngIf="salvaVisibile()" type="button"
                  class="btn btn-secondary"
                  (click)="submitForm(interventoForm.value)">
            <!-- [disabled]="!interventoForm.valid || !interventoForm.dirty" -->
            Salva
          </button>

        </div>
      </div>
    </div>
  </div>


</div>

<ng-template #content let-modal>
  <div class="modal-header" style="background-color: #012346 !important; cursor: pointer;">
    <h4 class="modal-title" id="modal-basic-title" style="color: white;">Attenzione</h4>
    <a (click)="modal.dismiss('Cross click')">
      <svg class="s-icon icon-xxs">
        <use xlink:href="assets/icons/sprite.svg#close"></use>
      </svg>
    </a>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <label>Tornando indietro perderai i dati inseriti nell'intervento, vuoi continuare comunque?</label>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Back click')">Ok</button>
  </div>
</ng-template>


<ng-template #cancella let-modal>
  <div class="modal-header" style="background-color: #012346 !important; cursor: pointer;">
    <h4 class="modal-title" id="modal-basic-title" style="color: white;">Attenzione</h4>
    <a (click)="modal.dismiss('Cross click')">
      <svg class="s-icon icon-xxs">
        <use xlink:href="assets/icons/sprite.svg#close"></use>
      </svg>
    </a>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <label>Sei sicuro di voler cancellare intervento con id {{intervento!!.id!!}} ?</label>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-danger"
            (click)="cancellaIntervento(intervento!!.id!!)">Cancella
    </button>
  </div>
</ng-template>
<ng-template #audit let-modal>
  <div class="modal-header" style="background-color: #012346 !important; cursor: pointer;">
    <h4 class="modal-title" id="modal-basic-title" style="color: white;">Storico modifiche
      intervento {{intervento!!.id!!}}</h4>
  </div>
  <div class="modal-body">
    <app-dyn-table [title]="storicoTitle" [dataMessageResults]="auditIntervento"></app-dyn-table>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('')">Ok</button>
  </div>
</ng-template>
