
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from "@angular/router";
import { SessionService } from "../../services/session.service";
import {NgbDateStruct, NgbModal, NgbTypeahead} from '@ng-bootstrap/ng-bootstrap';
import { Lotti, Page, Strutture } from 'src/app/model/Models';
import { ApiService } from 'src/app/services/api.service'
import {PresaInCaricoDettaglioModalComponent} from "../presa-in-carico-dettaglio-modal/presa-in-carico-dettaglio-modal.component";
@Component({
  selector: 'app-flussi-in-ingresso',
  templateUrl: './flussi-in-ingresso.component.html',
  styleUrls: ['./flussi-in-ingresso.component.css']
})
export class FlussiInIngressoComponent implements OnInit {
  ricercaForm!: FormGroup;
  dateModel!: NgbDateStruct;
  pagina: Page<Lotti> = new Page<Lotti>();
  strutture: Page<Strutture> = new Page<Strutture>();
  currentYear = new Date().getFullYear();
  yearArray: any = [];
  constructor(private apiService: ApiService,
              private router: Router,
              private sessionService: SessionService) { }

  ngOnInit(): void {

    this.populateYearArray();
    this.getStrutture();
    this.ricercaFormInit();
    this.getLotti();

  }

  private ricercaFormInit() {

    this.ricercaForm = new FormGroup({
      anno: new FormControl(""),
      struttura: new FormControl(""),
      attivita: new FormControl(""),
      periodo: new FormControl(""),
      statoElaborazione: new FormControl("")
    });
    this.ricercaForm.get('anno')?.setValue(this.currentYear);
    this.ricercaForm.get('periodo')?.setValue("S2");

  }

  private populateYearArray() {

    for (let i = this.currentYear - 1; i >= 1900; i--) {

      this.yearArray.push(i);

    }

  }

  resetFormRicerca() {

    this.ricercaForm.get('anno')?.setValue(this.currentYear);
    this.ricercaForm.get('struttura')?.setValue("");
    this.ricercaForm.get('attivita')?.setValue("");
    this.ricercaForm.get('periodo')?.setValue("S1");
    this.ricercaForm.get('statoElaborazione')?.setValue("");

  }

  getStrutture(){
    this.apiService.getStruttureAll("","").subscribe((data: any) => {
      this.strutture = data;
    });
  }

  cambiaPagina(event: any) {

    this.getLotti();

  }

  async getLotti() {

    var anno = this.ricercaForm.get('anno')?.value;
    var struttura = this.ricercaForm.get('struttura')?.value;
    var attivita = this.ricercaForm.get('attivita')?.value;
    var periodo = this.ricercaForm.get('periodo')?.value;
    var statoElaborazione = this.ricercaForm.get('statoElaborazione')?.value;

    this.apiService.getLottiFlussiIngresso(anno, struttura, attivita, periodo, statoElaborazione, this.pagina.number,
      this.pagina.size).subscribe((data: any) => {

        this.pagina = data;

      });
  }

  selectLotto(lotto: Lotti) {
    this.sessionService.setSelectedLotto(JSON.stringify(lotto));
    this.router.navigate(['dettaglio-lotto']);
  }


}
