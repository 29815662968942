<div class="container">
  <h2>{{title}}</h2>
  <div class="table-responsive">
    <div class="list-table table-even">
      <table class="table-lg" *ngIf="dataMessageResults&&dataMessageResults.length>0">
        <thead>
        <tr>
          <th *ngFor="let header of getHeaders()">{{header}}</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of dataMessageResults">
          <td *ngFor="let key of getHeaders()">{{item[key]}}</td>
        </tr>
        </tbody>
      </table>
      <div *ngIf="!dataMessageResults||dataMessageResults.length<1">Nessun dato disponibile.</div>
    </div>
  </div>
</div>
