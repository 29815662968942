export class TheadColumn {
  columnSortingName:string = '';
  columnDescription:string = '';

  constructor(columnSortingName: string, columnDescription:string) {
    this.columnSortingName = columnSortingName;
    this.columnDescription = columnDescription;
  }

}
