<div
  *ngIf="isLoading"
  class="spinner-border text-primary landingspinner"
  style="width: 5rem; height: 5rem"
  role="status">
  <span class="sr-only">Loading...</span>
</div>
<div class="container py-3">
  <div class="card">
    <div class="card-header">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a routerLink="/ricerca-anagrafica"
                                         (click)="$event.preventDefault();">Ricerca anagrafica</a> / contatti {{getStringParameter()}}</li>
        </ol>
      </nav>
      <div class="row">
        <div class="title-3 col-9" demon-anagrafica="titolo"> {{getTitleName()}}</div>
        <div class="col-3" style="text-align: end">
          <button class="btn btn-outline-secondary btn-sm" *ngIf="(getRuolo(OPERATORE_STRUTTURA)) && dettaglioAnagrafica?.id" (click)="openStoricoContatti(audit)" >
            Storico modifiche contatti
          </button>
        </div>
      </div>
    </div>
    <!-- Contatti e prestazioni -->
    <div class="card-body" id="contatto">

      <!-- Risultati -->
      <div class="pt-3">
        <div class="table-responsive">
          <div class="list-table table-even">

            <table class="table-lg">

              <thead app-thead [theadColumns]="theadColumns" [tableSorter]="tableSorter"
                     (changeOrder)="changeOrder($event)">
              </thead>

              <!-- <thead>
              <tr>
                <th>Tipo prestazione</th>
                <th>Data</th>
                <th>Diagnosi apertura</th>
                <th>Diagnosi chiusura</th>
                <th>Sede/Struttura</th>
                <th>N° prestazioni</th>
              </tr>
              </thead> -->
              <tbody>

              <tr *ngIf="page.loading">
                <td [attr.colspan]="theadColumns.length">
                  <div class="spinner-border centered"></div>
                </td>
              </tr>
              <ng-container *ngIf="!page.loading">
                <tr (click)="rigaContattoClick(contattoDto)" class="tr-hover demon-cloned"
                    data-demon-tr="6-anagraficaContatti-0" *ngFor="let contattoDto of page.content">

                    <td class="td-hover" data-label="Alert" style="text-align: center">
                      <div>
                        <div><img *ngIf="contattoDto.warning" src="assets/icons/warning.png"/></div>
                      </div>
                    </td>
                  <td class="td-hover" data-label="ASL">
                    <div *ngIf="getRuolo(OPERATORE_ASL)||getRuolo(OPERATORE_DSM)">
                      <span *ngIf="contattoDto.codasl == utente.codiceAsl">Nella ASL</span>
                      <span *ngIf="contattoDto.codasl != utente.codiceAsl">Altrove</span>
                    </div>
                    <div *ngIf="getRuolo(OPERATORE_STRUTTURA)||getRuolo(OPERATORE_REGIONE)">
                      {{contattoDto.codasl?contattoDto.asl:'Fuori regione'}}
                    </div>
                  </td>
                    <td class="td-hover" data-label="Id">
                      <div>{{contattoDto.id}}</div>
                    </td>
                    <td class="td-hover" *ngIf="!getRuolo(OPERATORE_STRUTTURA)"  data-label="Tipo prestazione">
                      <div>{{contattoDto.tipoPrestazione}}</div>
                    </td>
                    <td
                        class="td-hover" data-label="Sede/Struttura">
                      <div>{{contattoDto.codiceStrutturaOperatoreInserimento}} - {{contattoDto.descrizioneStrutturaOperatoreInserimento}} - {{getDescrizioneASL(contattoDto.codiceAslOperatoreInserimento)}}</div>
                    </td>
                    <td class="td-hover" *ngIf="!getRuolo(this.OPERATORE_STRUTTURA) || (getRuolo(OPERATORE_STRUTTURA) && contattoDto.tipoPrestazione.toLowerCase() == 'territoriale' ) " data-label="Data Richiesta">
                      <div>{{contattoDto.dataRichiesta | date:'yyyy-MM-dd'}}</div>
                    </td>
                    <td class="td-hover" *ngIf="!getRuolo(this.OPERATORE_STRUTTURA) || (getRuolo(OPERATORE_STRUTTURA) && contattoDto.tipoPrestazione.toLowerCase() == 'territoriale' )" data-label="Apertura contatto">
                      <span *ngIf="contattoDto.dataApertura">Sì</span>
                      <span *ngIf="!contattoDto.dataApertura">No</span>
                    </td>
                    <td class="td-hover" data-label="Data Apertura">
                      <div>{{contattoDto.dataApertura | date:'yyyy-MM-dd'}}</div>
                    </td>
                    <td class="td-hover" data-label="Data Chiusura">
                      <div>{{contattoDto.dataChiusura | date:'yyyy-MM-dd'}}</div>
                    </td>
                    <td class="td-hover" *ngIf="!this.getRuolo(this.OPERATORE_STRUTTURA) || ( getRuolo(OPERATORE_STRUTTURA) && contattoDto.tipoPrestazione.toLowerCase() == 'semiresidenziale' )" data-label="N° presenze">
                      <div>{{contattoDto.numeroPresenze}}</div>
                    </td>
                    <td class="td-hover" data-label="N° prestazioni">
                      <div>{{contattoDto.numeroPrestazioni}}</div>
                    </td>
                    <td class="td-hover" data-label="Stato">
                      <div>{{descrizioneStato(contattoDto.statoAttuale)}}</div>
                      <!-- <div>{{contattoDto.statoAttuale}}</div> -->
                    </td>

                </tr>
              </ng-container>
              </tbody>
            </table>
          </div>
        </div>

        <app-paginator [page]="page" (cambiaPaginaEvent)="cambiaPagina($event)"></app-paginator>

        <!-- <div class="d-flex justify-content-between pt-3">


          <select name="pageSize" class="form-control form-pagination ng-untouched ng-pristine ng-valid" style="width: auto;" demon-list="anagraficaContatti.rows"><option value="10">10 per pagina </option><option value="20">20 per pagina </option><option value="50">50 per pagina </option><option value="100">100 per pagina </option></select>
        </div> -->


        <div class="btn-toolbar">
          <div class="btn-group-left">
          </div>
          <div class="btn-group-center">
            <button *ngIf="aggiungiContattoVisibile()" type="button" class="btn btn-secondary"
                    demon-nav="anagrafica/nuovo-contatto" demon-ev="true" (click)="openAggiungiContattoForm()"
                    [disabled]="!areAllContattiClosed()">
              Aggiungi contatto
            </button>
          </div>
          <div class="btn-group-right">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="btn-fixed fixed">
    <div class="btn-toolbar">
      <div class="container">
        <div class="btn-group-left">
          <button type="button" class="btn btn-outline-secondary"
                  routerLink="/ricerca-anagrafica">
            Torna alla lista
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #audit let-modal>
  <div class="modal-header" style="background-color: #012346 !important; cursor: pointer;">
    <h4 class="modal-title" id="modal-basic-title" style="color: white;">Storico modifiche
      contatti</h4>
  </div>
  <div class="modal-body">
    <app-dyn-table [title]="'Storico contatti'" [dataMessageResults]="storicoList"></app-dyn-table>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('')">Ok</button>
  </div>
</ng-template>
<block-ui>

</block-ui>

