<!-- Risultati -->
<div class="pt-3">
  <div class="table-responsive">
    <div class="list-table table-even">

      <table class="table-lg" demon-cf="tableList" demon-cf-id="anagraficaPrestazioni-1" demon-ev="true" demon-done="3">
        <thead app-thead [theadColumns]="theadColumns" [tableSorter]="tableSorter" (changeOrder)="changeOrder()">
        </thead>


        <tbody>

        <tr *ngIf="pageInterventi.loading"><td  [attr.colspan]="theadColumns.length">
          <div class="spinner-border centered"></div></td></tr>
        <ng-container *ngFor="let intervento of pageInterventi.content" >
          <tr (click)="interventoClick(intervento)" class="tr-hover demon-cloned"
              data-demon-tr="6-anagraficaPrestazioni-1-1"
              *ngIf="!pageInterventi.loading && ( getRuolo(OPERATORE_STRUTTURA)||getRuolo(OPERATORE_REGIONE) || intervento.statoAttuale.id > 1 )" >
            <td class="td-hover" data-label="Alert" style="text-align: center">
              <div><img *ngIf="alert(intervento.statoAttuale)" src="assets/icons/warning.png" /></div>
            </td>
            <td class="td-hover" data-label="ID">
              <div>{{intervento.id}}</div>

            </td>
            <td class="td-hover" data-label="ID">
              <div>{{intervento.codiceStrutturaOperatoreInserimento}} - {{intervento.descrizioneStrutturaOperatoreInserimento}} - {{getDescrizioneASL(intervento.codiceAslOperatoreInserimento)}}</div>
            </td>
            <td class="td-hover" data-label="Data">
              <div>{{contatto.dataApertura | date:'yyyy-MM-dd'}}</div>
            </td>
            <td class="td-hover" data-label="Data">
              <div>{{intervento.data | date:'yyyy-MM-dd'}}</div>
            </td>
            <td class="td-hover" data-label="Risposta prev">
              <div>{{this.getRispostaPrevalenteDescrizione(intervento.rispostaPrevalente!!)}}</div>
            </td>
            <td class="td-hover" data-label="Stato">
              <div>{{descrizioneStato(intervento.statoAttuale.id)}}</div>
              <!-- <div>{{contattoDto.statoAttuale}}</div> -->
            </td>


          </tr>
        </ng-container>






        </tbody>
      </table>


    </div>



  </div>

  <app-paginator [page]="pageInterventi" (cambiaPaginaEvent)="cambiaPagina()">
  </app-paginator>



  <div class="btn-toolbar">
    <div class="btn-group-left">
    </div>
    <div class="btn-group-center">
      <!-- aggiungiInterventoVisibile() && !contattoIsClosed() -->
      <button *ngIf="(!isValidato() || this.contatto.dataChiusura === undefined || this.contatto.dataChiusura === null)  && this.contatto.aperturaFlag === '1' && getRuolo(OPERATORE_STRUTTURA)" type="button" class="btn btn-secondary" demon-bt="prestazioneNuova"
        (click)="openInterventoForm()">
        Aggiungi Intervento
      </button>
    </div>
    <div class="btn-group-right">
    </div>
  </div>

</div>
