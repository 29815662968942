<div class="card mt-2" style="background-color: white; border-radius: 15px;min-height: 180px">
  <div style="justify-content: space-between">
    <div class="card-header text-center" style="min-height: 50px">
      <div class="row align-items-center" >
        <div class="col-3">
          <div (click)="cardClick(misureClick)"
               [ngStyle]="{
              'background-color': misureColor,
              'border-radius': '50px',
              width: '50px',
              height: '50px',
              display: 'flex',
              'align-items': 'center',
              'justify-content': 'center',
              cursor: 'pointer'
            }"
          >
            <img
              src="{{ misureIcon }}"
              class="s-icon icon-sm"
              style="margin-left: 10px; margin-top: 5px"
            />
          </div>
        </div>
        <div class="col-9">
          <div class="row">
            <div
              class="col-12"
              style="
              line-height: 20px;
              text-align: center;
              font-weight: bold;
              margin-top: 5px;
            "
              [innerHTML]="misureLabel"
            ></div>
          </div>
        </div>

        <div class="card-body" style="display: grid; min-height: 90px; align-items: center">

          <div class="row">
            <div
              class="col-12"
              style="
              line-height: 20px;
              text-align: center;
              font-size: 21pt;
              font-weight: bold;
              margin-left: -10px;
              cursor: pointer;
            "
            >
              <a (click)="cardClick(misureClick)">{{ misureValue }}</a>
            </div>
          </div>
          </div>
        </div>
      </div>
  </div>
</div>
