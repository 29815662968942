import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {ModalDismissReasons, NgbDate, NgbDateStruct, NgbModal, NgbTypeahead} from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { debounceTime, distinctUntilChanged, firstValueFrom, from, map, Observable, of, OperatorFunction, Subject } from 'rxjs';
import { Funzionalita } from 'src/app/model/Funzionalita';
import {
  Contatto,
  DettaglioAnagrafica,
  Diagnosi,
  DiagnosiEScale,
  DiagnosiEscaleAudit,
  NotAvailableDatesContainer,
  Presenza,
  Utente
} from 'src/app/model/Models';
import { ApiService } from 'src/app/services/api.service';
import { SessionService } from 'src/app/services/session.service';
import { ToastService } from 'src/app/services/toast.service';
import { dataNonValidaValidator, emptyDiagnosiValidator, scalaBprsValidator, scalaHonosValidator } from 'src/app/util/codicefiscale.validators';
import {CommonsUtil} from "../../util/CommonsUtil";

@Component({
  selector: 'app-diagnosi-scale',
  templateUrl: './diagnosi-scale.component.html',
  styleUrls: ['./diagnosi-scale.component.css']
})
export class DiagnosiScaleComponent implements OnInit {


  anagrafica!: DettaglioAnagrafica

  diagnosiScala!: DiagnosiEScale

  mainUser!: Utente

  contatto!: Contatto

  diagnosiScaleForm!: FormGroup

  minDate?: NgbDateStruct

  maxDate = { year: 2035, month: 1, day: 1 }

  dateModel!: NgbDateStruct

  closeResult = ''

  formSubmitted = false;

  listDiagnosi: Diagnosi[] = []

  listDiagnosiEScale:DiagnosiEScale[ ] = []

  listDate: string[] = []

  modelDiagnosi!: any
  enableStatiAttuali: Array<number> = [1,2,4,7,9,0];


  // for diagnosi typeahead
  @ViewChild('instanceDiagnosiScala', { static: true }) instanceDiagnosiScala: NgbTypeahead | undefined;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  formatter = (diagnosi: Diagnosi) => (diagnosi.codice != undefined ? diagnosi.codice!! + ' - ' + diagnosi.descrizione! : '')

  resultFormatter = (diagnosi: Diagnosi) => (diagnosi.codice != undefined ? diagnosi.codice!! + ' - ' + diagnosi.descrizione! : '');

  diagnosiScaleSearch: OperatorFunction<string, readonly Diagnosi[]> = (textDiagnosi$: Observable<string>) =>
    textDiagnosi$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term: string) => term.length < 2 ? []
        : this.listDiagnosi
          .filter(dia =>
            dia && dia.descrizione && dia.descrizione.toLowerCase().indexOf(term.toLowerCase()) > -1
            || dia && dia.codice && dia.codice.toLowerCase().indexOf(term.toLowerCase()) > -1
          ) )
    )

  @BlockUI()
  blockUI!: NgBlockUI;

  constructor(private router: Router, private sessionService: SessionService,
    private modalService: NgbModal, private apiService: ApiService, private toastService: ToastService) {
    this.loadDiagnosiScaleForm();
  }

  async ngOnInit() {

    this.loadUser()
    this.loadAnagrafica()
    this.loadContatto()
    this.loadNotAvailableDateList()
    this.loadDiagnosiEScala()

    this.observeStrutturaChange()
    this.limitMaxDate()
  }

  loadUser() {
    this.mainUser = this.sessionService.getUtenteFromStorage()!!
  }
  disabledDates:NgbDateStruct[]=[]
  isDisabled=(date:NgbDateStruct,current: {month: number,year:number}|undefined)=> {
    //in current we have the month and the year actual
    return this.disabledDates.find(x=>new NgbDate(x.year,x.month,x.day).equals(date))?
      true:false;
  }
  loadDiagnosiEScala() {
    this.sessionService.selectedDiagnosiScale.subscribe(res => {
      this.diagnosiScala = JSON.parse(res);

      this.getAllDiagnosi().then(res => {

        this.listDiagnosi = res

        this.modelDiagnosi = this.getDiagnosiModel(this.contatto, this.diagnosiScala, this.listDiagnosi)

        // seconda parte solo se si puo cambiare il dato di una diagnosi essistente
        if (!this.isFirstDiagnosiOfContatto(this.contatto.diagnosiEScaleList!!) && !this.isDiagnosiEScaleNotNew(this.diagnosiScala)) {
          this.limitDateByContattoDate(this.contatto)
        }

        //if(!this.isFirstDiagnosiOfContatto(this.contatto.diagnosiEScaleList!!)){
        //  this.limitDateByDiagnosiDate(this.contatto!!.diagnosiEScaleList!![this.contatto.diagnosiEScaleList!!.length -1]!!)
        //}

        if(!this.isFirstDiagnosiOfContatto(this.contatto.diagnosiEScaleList!!)){

          // this.limitDateByDiagnosiDate(this.contatto!!.diagnosiEScaleList!![this.contatto.diagnosiEScaleList!!.length -1]!!)
          //this.limitDateByDiagnosiDate(this.diagnosiScala)

        }


      })

      if (this.diagnosiScala.id != null || this.diagnosiScala.id != undefined) {
        this.setFormValues(this.diagnosiScala)
      } else {
        this.observeFormForDuplicateDates()
      }
      this.dateModel = this.loadDateModel(this.contatto)!!

    })
  }

  observeFormForDuplicateDates() {

    this.diagnosiScaleForm.get('data')?.valueChanges.subscribe(val => {

      this.dateExistInDates(val)
    })
  }



  loadContatto() {

    this.sessionService.selectedContatto.subscribe(res => {
      this.contatto = res

    })
  }

  loadNotAvailableDateList() {


    this.sessionService.listDIagnosiEscaleDataNotAvailable.subscribe(res => {
      this.listDate = res
      this.disabledDates = [];
      for(let l of this.listDate){
        let c:NgbDateStruct = JSON.parse(l);
        this.disabledDates.push(c);
      }
    })

  }

  loadAnagrafica() {

    this.sessionService.selectedAnagrafica.subscribe(res => {
      this.anagrafica = JSON.parse(res)
    })
  }

  loadDateModel(contatto: Contatto): NgbDateStruct | null {

    let date: NgbDateStruct | null = null

    if (this.isFirstDiagnosiOfContatto(contatto.diagnosiEScaleList!!)) {
      date = this.sessionService.convertToDateStruct(new Date(contatto.dataApertura!!))!!
    } else if (this.diagnosiScala.id != undefined || this.diagnosiScala.id != null) {
      date = this.sessionService.convertToDateStruct(new Date(this.diagnosiScala.data!!))!!
    }



    return date;
  }

  navigateToRicercaAnagrafica() {
    this.router.navigate(['ricerca-anagrafica'])
  }

  navigateToAnagrafica() {
    this.router.navigate(['contatto'])
  }

  getTitleName(): string {
    if (this.anagrafica.nome === "") {
      return "Nuova Prestazione"
    } else {
      return "" + this.anagrafica.cognome + " " + this.anagrafica.nome + ' (CF: ' + this.anagrafica.codiceFiscale + ")"
    }
  }

  loadDiagnosiScaleForm() {

    this.diagnosiScaleForm = new FormGroup({
      data: new FormControl("", [Validators.required]),
      diagnosiScala: new FormControl("",[ emptyDiagnosiValidator()]),
      scalaHonos: new FormControl("", [scalaHonosValidator()]),
      scalaBprs: new FormControl("", [scalaBprsValidator()])
    })
  }

  getDiagnosiModel(contatto: Contatto, diagnosiScale: DiagnosiEScale, diagnosiList: Diagnosi[]): Diagnosi |null{

    let diagnosi:Diagnosi|null = new Diagnosi()
    if (!this.isDiagnosiEScaleNotNew(diagnosiScale) && this.isFirstDiagnosiOfContatto(contatto.diagnosiEScaleList!!)) {
      diagnosi = this.getDiagnosiModelFromContatto(contatto)
    } else if (this.isDiagnosiEScaleNotNew(diagnosiScale) && !this.isFirstDiagnosiOfContatto(contatto.diagnosiEScaleList!!)) {
      diagnosi = this.filterDiagnosi(diagnosiScale, diagnosiList)
    } else {
      diagnosi = null
    }
    return diagnosi
  }

  filterDiagnosi(diagnosiScale: DiagnosiEScale, diagnosiList: Diagnosi[]) {
    let diagnosi = new Diagnosi()
    diagnosiList.forEach(diag => {
      if (diag.codice === diagnosiScale.diagnosi) {
        diagnosi = diag
      }
    })
    return diagnosi
  }

  isDiagnosiEScaleNotNew(diagnosiScale: DiagnosiEScale): boolean {

    return diagnosiScale.id != null && diagnosiScale.id != undefined
  }

  getDiagnosiModelFromContatto(contatto: Contatto): Diagnosi {


    let diagnosi = new Diagnosi()

    diagnosi.codice = contatto.codiceDiagnosiApertura!!
    diagnosi.descrizione = contatto.descrizioneDiagnosiApertura!!

    return diagnosi;
  }

  setFormValues(diagnosiScala: DiagnosiEScale) {

    this.diagnosiScaleForm.get('scalaHonos')!!.patchValue(diagnosiScala.scalaHonos)

    this.diagnosiScaleForm.get('scalaBprs')!!.patchValue(diagnosiScala.scalaBprs)

  }

  submitForm(values: any) {

    this.formSubmitted = true

    if (this.diagnosiScaleForm.valid) {

      let diagnosiScala: DiagnosiEScale = values

      diagnosiScala.id = this.diagnosiScala.id
      diagnosiScala.data = this.sessionService.toNativeDate(this.dateModel)!!
      diagnosiScala.contatto = this.contatto
      diagnosiScala.diagnosi = this.modelDiagnosi.codice
      diagnosiScala.descrizioneDiagnosi = this.modelDiagnosi.descrizione
      diagnosiScala.codiceAslOperatoreInserimento = this.contatto.codiceAslOperatoreInserimento
      this.blockUI.start("Salvataggio in corso")



      let codiceStruttura = this.sessionService.getSelectedStrutturaFromStorage()?.codice


      this.apiService.saveDiagnosiEScala(diagnosiScala, codiceStruttura!!).subscribe({
        next: (res) => {
          let diagnosi: DiagnosiEScale = res
          this.sessionService.setSelectedDiagnosiScale(JSON.stringify(diagnosi!!))
          this.toastService.showSuccess('Salvataggio effettuato con successo');
          this.refreshData()
          this.back();
        },

        error: (err) => {

          console.log(err);

          // this.blockUI.stop()

          this.toastService.showError('Correggere gli errori di compilazione');

        },

        complete: () => {


          this.blockUI.stop()
        }
      })
    } else {
      this.toastService.showError("Correggere gli errori di compilazione")
    }
  }


  dateExistInDates(date: NgbDateStruct) {
    let exist = this.listDate.indexOf(JSON.stringify(date)) !== -1

    if (exist === true) {
      this.diagnosiScaleForm.get('data')?.setErrors({ dateExist: true })
    } else {
      this.diagnosiScaleForm.get('data')?.setErrors({ dateExist: null })
      this.diagnosiScaleForm.controls['data'].updateValueAndValidity({onlySelf:true, emitEvent:false})
    }
  }

  back() {
    this.sessionService.setOpenTabContatto("TabDiagnosiScale")
    this.router.navigate(['contatto'])
  }

  hasWritePermission(utente: Utente): boolean {
    if (utente.funzionalita.includes(Funzionalita.ANAGRAFICHE_INSERIMENTO)) {
      return true
    } else {
      return false
    }
  }

  openGoBackDialog(content: any) {

    if (!this.diagnosiScaleForm.dirty) {

      this.back()

    } else {

      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;

        if (this.closeResult === 'Closed with: Back click') {
          this.back()

        }
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;


      });
    }

  }

  getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  openCancellaDialog(cancella: any) {

    this.modalService.open(cancella, { ariaLabelledBy: 'modal-basic-title' }).result
      .then((result) => {
          this.closeResult = `Closed with: ${result}`;

      if (this.closeResult === 'Closed with: Back click') {
        this.back()
      }
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });


  }

  cancellaVisibile(): Boolean {

    if (this.diagnosiScala && this.diagnosiScala.id != null && this.salvaVisibile()) {
      if(this.isContattoClosedAndLastDiagnosi() ||this.isFirstDiagnosiOfListContatto()){
        return false;
      }
      return true
    } else {
      return false
    }

  }

  salvaVisibile() {

    return this.mainUser.funzionalita.includes(Funzionalita.ANAGRAFICHE_INSERIMENTO);
  }

  cancellaDiagnosiEScala(id: number) {

    this.modalService.dismissAll()
    if(!this.canDeleteDiagnosiEScale()){
      this.toastService.showError('Non è popssibile eliminare la diagnosi, è la prima o l\'ultima del contatto chiuso. ');
      return;
    }
    this.blockUI.start("Cancellazione  in corso")
    this.apiService.cancellaDiagnosiEScala(id).subscribe({
      next: (res) => {
        console.log(res);
        this.toastService.showSuccess('Cancellazione effettuata con successo');

        this.refreshData()
        this.back()
      },
      error: (err) => {

        this.blockUI.stop()
        this.toastService.showError('Si è verificato un errore durante la cancellazione');
      },
      complete: () => {

        this.blockUI.stop()

      }
    })
  }

  async getAllDiagnosi() {


    return await firstValueFrom(this.apiService.getAllDiagnosi())

  }

  campiPrimaDiagnosiReadonly(listDiagnosi: DiagnosiEScale[]) {

    // if(this.isFirstDiagnosiOfContatto(listDiagnosi)){
      let  isFirst: boolean = false;
      const itsort = this.contatto.diagnosiEScaleList?this.contatto.diagnosiEScaleList.sort((a, b) => this.getTime(a.data)-this.getTime(b.data)):[];
      isFirst = (itsort.findIndex(value => value.id == this.diagnosiScala.id) == 0)||itsort.findIndex(value => value.id == this.diagnosiScala.id) == 0;
      return isFirst;
    // } else {
      /*const minId = listDiagnosi
        .reduce((min, obj) => Math.min(min, obj!.id!), Infinity);
      return minId == this.diagnosiScala.id;*/
      //
    //   const itsort = this.contatto.diagnosiEScaleList?this.contatto.diagnosiEScaleList.sort((a, b) => this.getTime(a.data)-this.getTime(b.data)):[];
    //   const isLast = (itsort.findIndex(value => value.id == this.diagnosiScala.id) == itsort?.length! - 1)||itsort.findIndex(value => value.id == this.diagnosiScala.id) == 0;
    //   return isLast;
    // }

  }

  isFirstDiagnosiOfContatto(listDiagnosi: DiagnosiEScale[]) {

    if (listDiagnosi.length === 0) {
      return true
    } else {
      return false
    }

  }

  limitDateByContattoDate(contatto: Contatto) {

    let minDateModel = this.sessionService.convertToDateStruct(new Date(contatto.dataApertura!!))
    this.minDate = { year: minDateModel!!.year, month: minDateModel!!.month, day: minDateModel!!.day }
    if(contatto.dataChiusura){

      const itsort = this.contatto.diagnosiEScaleList?this.contatto.diagnosiEScaleList.sort((a, b) => this.getTime(a.data)-this.getTime(b.data)):[];

      let maxDateModel = this.sessionService.convertToDateStruct(!itsort || itsort?.length < 1 ? new Date(this.contatto.dataChiusura!!) : new Date(itsort![itsort?.length! - 1].data!));


      this.maxDate = { year: maxDateModel!!.year, month: maxDateModel!!.month, day: maxDateModel!!.day }

    } else {

      let todayDate = this.sessionService.convertToDateStruct(new Date())
      this.maxDate = {year:todayDate?.year!!, month:todayDate?.month!!, day:todayDate?.day!!}

    }

  }

  limitDateByDiagnosiDate(diagnosi:DiagnosiEScale) {

    let minDateModel = this.sessionService.convertToDateStruct(new Date(diagnosi.data!!))
    this.minDate = { year: minDateModel!!.year, month: minDateModel!!.month, day: minDateModel!!.day}

  }

  refreshData(){

    this.sessionService.setRefreshdata(true)
  }

  observeStrutturaChange(){


    this.sessionService.strutturaChanged.subscribe(res => {

      if(res){
        this.router.navigate(['ricerca-anagrafica'])
      }
    })
  }

  limitMaxDate(){
    const itsort = this.contatto.diagnosiEScaleList?this.contatto.diagnosiEScaleList.sort((a, b) => /*a.id && b.id && a.id <= b.id ? -1 : 1*/this.getTime(a.data)-this.getTime(b.data)):[];

    let todayDate = this.sessionService.convertToDateStruct(!itsort||itsort.length<1?new Date():new Date(itsort![itsort?.length!-1].data!));

    //let todayDate = this.sessionService.convertToDateStruct(new Date())

    this.maxDate = {year:todayDate?.year!!, month:todayDate?.month!!, day:todayDate?.day!!}
  }

  // prova() {
  //   console.log( 'modelDiagnosi' + this.modelDiagnosi )
  //   console.log( 'value' + this.diagnosiScaleForm.get('diagnosiScala')?.value )
  //   console.log( new CommonsUtil().validFields(this.diagnosiScaleForm) )
  //   console.log( this.diagnosiScaleForm.valid )
  // }
  public OPERATORE_STRUTTURA: String = "operatore-struttura";
  public OPERATORE_ASL: String = "operatore-asl";
  public OPERATORE_DSM: String = "operatore-dsm";
  public OPERATORE_REGIONE: String = "operatore-regione";
  auditDiagnosiEScale: DiagnosiEscaleAudit[] = [];
  storicoTitle: any;
  public getRuolo(ruolo: String) {


    if (this.mainUser) {

      if (this.mainUser.nomeRuolo == ruolo) {

        return true;

      }

    }

    return false;

}


openAuditDialog(audit: any){

  this.storicoTitle = ""

  this.apiService.getIDiagnosiEscaleAuditByIdAndStruttura(this.diagnosiScala?.id!!, this.sessionService.getSelectedStrutturaFromStorage()?.codice!).subscribe(res => {
    if(res){

      this.auditDiagnosiEScale = res;

    }

  });
  this.modalService.open(audit, { ariaLabelledBy: 'modal-basic-title', size: 'xl', scrollable: true}).result.then((result) => {
    this.closeResult = `Closed with: ${result}`;

    if (this.closeResult === 'Closed with: Back click') {
      this.back()

    }
  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;


  });
}

canDeleteDiagnosiEScale(): boolean{

  let maxDateObject = this.getLastDiagnosi();
  let minDateObject = this.getFirstDiagnosi();

  if(this.isFirstDiagnosi(minDateObject)){

    return false;

    }

  if(this.contatto.dataChiusura && this.isLastDiagnosi(maxDateObject)){

    return false

    }

  return true;

}

getFirstDiagnosi(): DiagnosiEScale{

  let minDateObject = this.contatto!.diagnosiEScaleList!.reduce((a, b) => new Date(a.data!).getTime() < new Date(b.data!).getTime() ? a : b);
  return minDateObject;

}

getLastDiagnosi() : DiagnosiEScale{

  let maxDateObject = this.contatto!.diagnosiEScaleList!.reduce((a, b) => new Date(a.data!).getTime() > new Date(b.data!).getTime() ? a : b);
  return maxDateObject;

}

isLastDiagnosi(lastDiagnosi: DiagnosiEScale): boolean{

  if(this.diagnosiScala.id == lastDiagnosi.id){

    return true;

  }

  return false;

}

isFirstDiagnosi(firstDiagnosi: DiagnosiEScale): boolean{

  if(this.diagnosiScala.id == firstDiagnosi.id){

    return true;

  }

  return false;
}

mustDisableForm(){

  if(this.diagnosiScala.statoAttuale && this.diagnosiScala.statoAttuale.id && this.diagnosiScala.statoAttuale.id >= 0 ){

    return this.enableStatiAttuali.indexOf(this.diagnosiScala.statoAttuale.id) == -1

  }

  return false;

}
  mustDisableCancelBtn() {

    if (this.diagnosiScala.statoAttuale.id && this.diagnosiScala.statoAttuale.id > 0) {

      let statiAttuali: Array<number> = [1, 2, 4, 7, 9];
      return statiAttuali.indexOf(this.diagnosiScala.statoAttuale.id) == -1

    }

    return true;

  }

  private isFirstDiagnosiOfListContatto() {
    const itsort = this.contatto.diagnosiEScaleList?this.contatto.diagnosiEScaleList.sort((a, b) => a.id && b.id && a.id >= b.id ? -1 : 1):[];
    const isi = itsort.findIndex(value => value.id == this.diagnosiScala.id) == itsort?.length! - 1;
    if (isi) {
      return true;
    } else {
      return false;
    }
  }

  public  isContattoClosedAndLastDiagnosi() {
      const itsort = this.contatto.diagnosiEScaleList?this.contatto.diagnosiEScaleList.sort((a, b) => /*a.id && b.id && a.id <= b.id ? -1 : 1*/this.getTime(a.data)-this.getTime(b.data)):[];
      const isLast = itsort.findIndex(value => value.id == this.diagnosiScala.id) == itsort?.length! - 1;
      if (this.contatto.dataChiusura && isLast) {
        return true;
      } else {
        return false;
      }
    }

  private getTime(date?: Date) {
    return date != null ? new Date(date).getTime() : 0;
  }
}
