import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Page} from "../../../model/Models";
import {Pagina, PaginazioneLogic} from "./paginazione-logic";

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.css']
})
export class PaginatorComponent implements OnInit {
  // @ts-ignore
  @Input()
  page: Page<Object> = new Page<Object>();
  paginazioneLogic: PaginazioneLogic = new PaginazioneLogic();

  @Output()
  cambiaPaginaEvent = new EventEmitter<string>();
  dimensionePaginaDefault:any;

  constructor() {
  }

  ngOnInit(): void {
    this.dimensionePaginaDefault = this.page.size;
  }


  paginaLogic() : Pagina<Object>{
    let pagina = new Pagina<Object>();
    pagina.numeroPagina = this.page.number +1;
    pagina.dimensionePagina  = this.page.size
    pagina.numeroTotaleElementi   = this.page.numberOfElements;
    pagina.numeroTotalePagine  = this.page.totalPages;
    pagina.listaElementi = this.page.content;
    return pagina;

  }

  cambiaPaginaEmit() {
      this.cambiaPaginaEvent.emit('');
  }

  cambiaPagina(number: number | null) {
      if(number!=null){
        this.page.number = number!-1;
        this.cambiaPaginaEmit();
      }
  }

  cambioDimensionePaginaSelezionata(event: any) {
    this.page.number =
      Math.round(
      this.page.number * (
        this.page.size / event.target.value
      ));
    this.page.size = event.target.value;

    this.cambiaPaginaEmit();
  }


  classepaginatoreAttivo(indice: number) {
    if (indice ==  this.paginaLogic().numeroPagina) {
      return 'active';
    } else {
      return '';
    }
  }

}
