<div
  *ngIf="isLoading"
  class="spinner-border text-primary landingspinner"
  style="width: 5rem; height: 5rem"
  role="status"
>
  <span class="sr-only">Loading...</span>
</div><!--   -->
<iframe width="1" height="1" [src]="getSafeUrl('')"></iframe>
<div class="container py-3 dashboard">
  <ul
    class="nav nav-tabs"
    id="searchTab"
    role="tablist"
    stykle="border-radius:15px;"
  >
    <li class="nav-item">
      <a
        class="nav-link title-3 {{ dashboardActive }}"
        id="dashboard-tab"
        data-toggle="tab"
        href="#dashboard"
        role="tab"
        aria-controls="dashboard"
        [attr.aria-selected]="dashboard"
        (click)="switchToDashboard()"
      >
        Dashboard
      </a>
    </li>
    <li class="nav-item">
      <a
        class="nav-link title-3 {{ reportActive }}"
        id="report-tab"
        data-toggle="tab"
        href="#report"
        role="tab"
        aria-controls="report"
        [attr.aria-selected]="reportTab"
        (click)="switchToReport()"
      >
        Report
      </a>
    </li>
    <li *ngIf="reportDataVisible" class="nav-item">
      <div
        class="nav-link title-3 {{ reportDataActive }}"
        [attr.aria-selected]="reportData"
        id="report-data"
        data-toggle="tab"
        href="#reportData"
        role="tab"
        aria-controls="reportData"
        style="cursor: pointer"
      >
        <a (click)="switchToReportData(reportTitle, reportLink)" class="text-primary">
          {{ reportTitle }}
        </a>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          style="padding: 0px; width: 0.5em; height: 0.5em"
          (click)="closeReportData()"
        ></button>
      </div>
    </li>
  </ul>

  <div
    *ngIf="!reportTab && !reportData"
    class="card"
    style="
      margin: 0px;
      border-radius: 15px;
      border-top-left-radius: 0;
      background-color: #fff;
    "
  >

    <div class="row" style="margin: 25px">
      <div class="col-12">
        <div
          class="card mt-12"
          style="
            background-color: #f0f6fc;
            border-radius: 15px;
            border-color: #0461ad;
            border-width: 1px;
            border-style: solid;
          "
        >
          <div class="row" style="margin: 25px">
            <div class="col-12" style="font-weight: bold">
              <h2>Utenti e contatti</h2>
            </div>
          </div>


          <!-- FILTRO UTENTI -->
          <div
            class="row"
            style="margin: 25px; margin-top: 5px; justify-content: center"
          >
            <form [formGroup]="ricercaForm">
              <div class="group-inline">
                <!--                  <div class="groupo-inline">-->
                <div class="col-2 form-group">
                  <label style="font-weight: bold">Anno: </label>
                  <select
                    class="form-control"
                    formControlName="anno"
                    name="anno"
                    formGorupName="ricercaForm"
                    (change)="changeAnno($event)"
                  >
                    <!--option value="{{ annoSelezionato }}" selected>
                      {{ annoSelezionato }}
                    </option-->

                    <option *ngFor="let year of anni" value="{{ year }}" [attr.selected]="annoSelezionato&&annoSelezionato==+year?true:null">
                      {{ year }}
                    </option>
                  </select>
                </div>
                <!--                  </div>-->
                <div
                  *ngIf="getRuolo(OPERATORE_REGIONE)"
                  class="groupo-inline"
                >
                  <div class="col-3 form-group">
                    <label style="font-weight: bold">Asl: </label>
                    <select
                      class="form-control"
                      formControlName="asl"
                      name="asl"
                      formGorupName="ricercaForm"

                      [(ngModel)]="defaultSelectAsl"
                      (change)="changeAsl()"
                    >
                      <option value="">Tutte</option>
                      <option
                        *ngFor="let asl of aslList"
                        value="{{ asl.codiceAzienda }}"
                      >
                        {{ asl.denominazioneAzienda }}
                      </option>
                    </select>
                  </div>
                </div>
                <div
                  *ngIf="!getRuolo(OPERATORE_STRUTTURA)"
                  class="groupo-inline"
                >
                  <div class="col-2 form-group">
                    <label style="font-weight: bold">Ambito: </label>
                    <select
                      class="form-control"
                      formControlName="ambito"
                      name="ambito"
                      formGorupName="ricercaForm"
                      (change)="changeAmbito($event)"
                      [(ngModel)]="defaultSelectAmbito"
                    >
                      <option value="">Tutte</option>
                      <option value="Territoriale">Territoriale</option>
                      <option value="Semiresidenziale">
                        Semiresidenziale
                      </option>
                    </select>
                  </div>
                </div>
                <div
                  *ngIf="!getRuolo(OPERATORE_STRUTTURA)"
                  class="groupo-inline"
                >
                  <div class="col-2 form-group">
                    <label style="font-weight: bold">Tipo Rapporto SSN:</label>
                    <select
                      class="form-control"
                      formControlName="tipoSsn"
                      name="tipoSsn"
                      formGorupName="ricercaForm"
                      (change)="changeSsn($event)"
                      [(ngModel)]="defaultSelectSsn"
                    >
                      <option value="0">Tutti</option>
                      <option value="1">Pubblico</option>
                      <option value="2">Privato</option>
                    </select>
                  </div>
                </div>
                <div
                  *ngIf="!getRuolo(OPERATORE_STRUTTURA)"
                  class="groupo-inline"
                >
                  <div *ngIf="getRuolo(OPERATORE_ASL) || getRuolo(OPERATORE_DSM)" class="col-3 form-group">
                    <label style="font-weight: bold">Competenza territoriale: </label>
                    <select
                      class="form-control"
                      formControlName="competenzeterritoriali"
                      name="competenzeterritoriali"
                      formGorupName="ricercaForm"
                      (change)="changeCompetenzeTerritoriali()"
                      [(ngModel)]="defaultCompetenzeTerritoriali"
                    >
                      <option value="struttureInAsl">Strutture dell'Asl</option>
                      <option value="fuoriasl">Strutture fuori Asl</option>
                    </select>
                  </div>
                </div>
                <div
                  *ngIf="!getRuolo(OPERATORE_STRUTTURA)"
                  class="groupo-inline"
                >
                  <div class="col-3 form-group">
                    <label style="font-weight: bold">Struttura: </label>
                    <select style="width:250px"
                            class="form-control"
                            formControlName="struttura"
                            name="struttura"
                            formGorupName="ricercaForm"
                            [(ngModel)]="defaultSelectStrutture"
                    >
<!--                      (change)="changeStruttura()"-->

                      <option value="tutte">Tutte</option>
                      <ng-container *ngIf="showStrutture()"><option
                        *ngFor="let struttura of struttureList"
                        value="{{ struttura.codStruttura }}"
                      >
                        {{ struttura.denominazioneStruttura }}
                      </option></ng-container>
                    </select>
                  </div>
                </div>
                <div
                  class="groupo-inline"
                >
                  <div class="col-3 form-group">
                    <label style="font-weight: bold">&nbsp;</label>
                    <div class="btn-group-center">
                      <button type="button" class="btn btn-secondary"
                           (click)="clickCalcola()" >
                        Avvia Ricerca
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-12 form-group">
                  <label style="font-weight: bold">Per visualizzare i dati nella dashboard corrispondenti ai criteri di selezione cliccare su “Avvia ricerca”.<br>Si ricorda che è possibile modificare i valori di default definendo specifici criteri di selezione cliccando sui singoli campi.</label>
                </div>
              </div>

            </form>
          </div>

          <!-- FINE FILTRO -->

          <div style="margin: 25px; justify-content: space-between; display: flex">
            <div class="col-3">
              <app-showdata
                [misureValue]="utentiPrevalenti0101"
                [misureLabel]="misureLabel1"
                [misureIcon]="iconUser"
                [misureColor]="colorBlue"
              >
              </app-showdata>
            </div>
            <div class="col-3">
              <app-showdata
                [misureValue]="utentiPrevalenti"
                [misureLabel]="misureLabel2"
                [misureIcon]="iconUser"
                [misureColor]="colorBlue"
              >
              </app-showdata>
            </div>
            <div class="col-3">
              <app-showdata
                [misureValue]="utentiIncidenti"
                [misureLabel]="misureLabel3"
                [misureIcon]="iconUser"
                [misureColor]="colorBlue"
              >
              </app-showdata>
            </div>
            <div class="col-3">
              <app-showdata
                [misureValue]="contattiPrevalenti"
                [misureLabel]="misureLabel4"
                [misureIcon]="iconPeople"
                [misureColor]="colorBlue"
              >
              </app-showdata>
            </div>
          </div>
          <div class="row" style="margin: 25px">
            <div class="col-3">
              <app-showdata
                [misureValue]="utentiContattiAttivi"
                [misureLabel]="misureLabel5"
                [misureIcon]="iconUser"
                [misureColor]="colorBlue"
              >
              </app-showdata>
            </div>
            <div class="col-3">
              <app-showdata
                [misureValue]="utentiContattiNonAvviati0101"
                [misureLabel]="misureLabel6"
                [misureIcon]="iconUser"
                [misureColor]="colorBlue"
              >
              </app-showdata>
            </div>
            <div class="col-3">
              <app-showdata
                [misureValue]="dataUltimaAperturaContatto"
                [misureLabel]="misureLabel7"
                [misureIcon]="iconCalendarUp"
                [misureColor]="colorBlue"
              >
              </app-showdata>
            </div>
            <div class="col-3">
              <app-showdata
                [misureValue]="dataUltimaConclusioneContatto"
                [misureLabel]="misureLabel8"
                [misureIcon]="iconCalendarKo"
                [misureColor]="colorBlue"
              >
              </app-showdata>
            </div>
          </div>
          <div style="margin: 25px; justify-content: space-between; display: flex; gap:15px">
            <div style="flex: 20">
              <app-showdataclick
                [misureValue]="contattiValidati"
                [misureLabel]="misureLabel27"
                [misureIcon]="iconPersonOff"
                [misureColor]="colorGreen"
                [misureClick]="emptyLink"
              >
              </app-showdataclick>
            </div>
            <div style="flex: 20">
              <app-showdataclick
                [misureValue]="interventiValidati"
                [misureLabel]="misureLabel28"
                [misureIcon]="iconMedication"
                [misureColor]="colorGreen"
                [misureClick]="emptyLink"
              >
              </app-showdataclick>
            </div>
            <div style="flex: 20">
              <app-showdataclick
                [misureValue]="diagnosiValidate"
                [misureLabel]="misureLabel29"
                [misureIcon]="iconDiagnosis"
                [misureColor]="colorGreen"
                [misureClick]="emptyLink"
              >
              </app-showdataclick>
            </div>
            <div style="flex: 20">
              <app-showdataclick
                [misureValue]="prestazioniValidate"
                [misureLabel]="misureLabel30"
                [misureIcon]="iconMedicalServices"
                [misureColor]="colorGreen"
                [misureClick]="emptyLink"
              >
              </app-showdataclick>
            </div>
            <div style="flex: 20">
              <app-showdataclick
                [misureValue]="presenzeValidate"
                [misureLabel]="misureLabel31"
                [misureIcon]="iconPresence"
                [misureColor]="colorGreen"
                [misureClick]="emptyLink"
              >
              </app-showdataclick>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="getRuolo(OPERATORE_STRUTTURA)" class="row" style="margin: 25px">
      <div class="col-12">
        <div
          class="card mt-12"
          style="
            background-color: #f0f6fc;
            border-radius: 15px;
            border-color: #fad163;
            border-width: 1px;
            border-style: solid;
          "
        >
          <div class="row" style="margin: 25px">
            <div class="col-12" style="font-weight: bold">
              <h2>Sintesi delle anomalie</h2>
            </div>
          </div>
          <div style="margin: 25px; justify-content: space-between; display: flex; gap: 15px">
            <div style="flex:20">
              <app-showdata
                [misureValue]="contattiNonInviati"
                [misureLabel]="misureLabel9"
                [misureIcon]="iconPersonRemove"
                [misureColor]="colorYellow"
                [misureClick]="misureLink11"
                [misureYear]="annoSelezionato!"
              >
              </app-showdata>
            </div>
            <div style="flex:20">
              <app-showdata
                [misureValue]="interventiNonInviati"
                [misureLabel]="misureLabel10"
                [misureIcon]="iconMedication"
                [misureColor]="colorYellow"
                [misureClick]="misureLink12"
                [misureYear]="annoSelezionato!"

              >
              </app-showdata>
            </div>
            <div style="flex:20">
              <app-showdata
                [misureValue]="diagnosiScaleNonInviate"
                [misureLabel]="misureLabel11"
                [misureIcon]="iconDiagnosis"
                [misureColor]="colorYellow"
                [misureClick]="misureLink13"
                [misureYear]="annoSelezionato!"
              >
              </app-showdata>
            </div>
            <div style="flex:20">
              <app-showdata
                [misureValue]="prestazioniNonInviate"
                [misureLabel]="misureLabel12"
                [misureIcon]="iconMedicalServices"
                [misureColor]="colorYellow"
                [misureClick]="misureLink14"
                [misureYear]="annoSelezionato!"
              >
              </app-showdata>
            </div>
          </div>
          <div style="margin: 25px; justify-content: space-between; display: flex; gap:15px">
            <div style="flex: 25">
              <app-showdata
                [misureValue]="presenzeNonInviate"
                [misureLabel]="misureLabel13"
                [misureIcon]="iconNoAccounts"
                [misureColor]="colorYellow"
                [misureClick]="misureLink15"
                [misureYear]="annoSelezionato!"
              >
              </app-showdata>
            </div>
            <div style="flex: 25">
              <app-showdata
                [misureValue]="contattiSenzaInterventiDiaPrestPres"
                [misureLabel]="misureLabel14"
                [misureIcon]="iconDiagnosis"
                [misureColor]="colorYellow"
                [misureClick]="misureLink16"
                [misureYear]="annoSelezionato!"
              >
              </app-showdata>
            </div>
            <div *ngIf="this.isTerritoriale()" style="flex: 25">
              <app-showdata
                [misureValue]="contattiApertiSenzaPrestazioni"
                [misureLabel]="misureLabel15"
                [misureIcon]="iconMedicalServices"
                [misureColor]="colorYellow"
                [misureClick]="misureLink17"
                [misureYear]="annoSelezionato!"
              >
              </app-showdata>
            </div>
            <div *ngIf="this.isSemiresidenziale()" style="flex: 25">
              <app-showdata
                [misureValue]="contattiApertiSenzaPrestazioni"
                [misureLabel]="misureLabel32"
                [misureIcon]="iconMedicalServices"
                [misureColor]="colorYellow"
                [misureClick]="misureLink17"
                [misureYear]="annoSelezionato!"
              >
              </app-showdata>
            </div>
            <div *ngIf="this.isTerritoriale()" style="flex: 25">
              <app-showdata
                [misureValue]="contattiUltimaPrestazione180"
                [misureLabel]="misureLabel16"
                [misureIcon]="iconMedicalServices"
                [misureColor]="colorYellow"
                [misureClick]="misureLink18"
                [misureYear]="annoSelezionato!"
              >
              </app-showdata>
            </div>
            <div *ngIf="this.isSemiresidenziale()" style="flex: 25">
              <app-showdata
                [misureValue]="contattiUltimaPrestazione180"
                [misureLabel]="misureLabel33"
                [misureIcon]="iconMedicalServices"
                [misureColor]="colorYellow"
                [misureClick]="misureLink18"
                [misureYear]="annoSelezionato!"
              >
              </app-showdata>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="getRuolo(OPERATORE_STRUTTURA) || getRuolo(OPERATORE_ASL) || getRuolo(OPERATORE_REGIONE)"
      class="row"
      style="margin: 25px"
    >
      <div class="col-12">
        <div
          class="card mt-12"
          style="
            background-color: #f0f6fc;
            border-radius: 15px;
            border-color: #b0385c;
            border-width: 1px;
            border-style: solid;
          "
        >
          <div class="row" style="margin: 25px">
            <div class="col-12" style="font-weight: bold">
              <h2>Sintesi del processo di validazione</h2>
            </div>
          </div>

          <!-- FILTRO SINTESI -->
          <div
            class="row"
            style="margin: 25px; margin-top: 5px; justify-content: center"
          >
            <form [formGroup]="ricercaSintesiForm">
              <div class="group-inline">
                <!--                  <div class="groupo-inline">-->
                <div class="col-2 form-group">
                  <label style="font-weight: bold">Anno: </label>
                  <select
                    class="form-control"
                    formControlName="annoSintesi"
                    name="annoSintesi"
                    formGorupName="ricercaSintesiForm"
                    (change)="changeAnnoSintesi($event)"
                  >
                    <!--option value="{{ annoSelezionato }}" selected>
                      {{ annoSelezionato }}
                    </option-->
                    <option  value="0" >
                      Tutti
                    </option>
                    <option *ngFor="let year of anni" value="{{ year }}" [attr.selected]="annoSelezionatoSintesi&&annoSelezionatoSintesi==+year?true:null">
                      {{ year }}
                    </option>
                  </select>
                </div>
                <!--                  </div>-->
                <div
                  *ngIf="getRuolo(OPERATORE_REGIONE)"
                  class="groupo-inline"
                >
                  <div class="col-3 form-group">
                    <label style="font-weight: bold">Asl: </label>
                    <select
                      class="form-control"
                      formControlName="aslSintesi"
                      name="aslSintesi"
                      formGorupName="ricercaSintesiForm"
                      (change)="changeAslSintesi()"
                      [(ngModel)]="defaultSelectAslSintesi"
                    >
                      <option value="">Tutte</option>
                      <option
                        *ngFor="let asl of aslList"
                        value="{{ asl.codiceAzienda }}"
                      >
                        {{ asl.denominazioneAzienda }}
                      </option>
                    </select>
                  </div>
                </div>
                <div
                  *ngIf="!getRuolo(OPERATORE_STRUTTURA)"
                  class="groupo-inline"
                >
                  <div class="col-2 form-group">
                    <label style="font-weight: bold">Ambito: </label>
                    <select
                      class="form-control"
                      formControlName="ambitoSintesi"
                      name="ambitoSintesi"
                      formGorupName="ricercaSintesiForm"
                      (change)="changeAmbitoSintesi($event)"
                      [(ngModel)]="defaultSelectAmbitoSintesi"
                    >
                      <option value="">Tutte</option>
                      <option value="Territoriale">Territoriale</option>
                      <option value="Semiresidenziale">
                        Semiresidenziale
                      </option>
                    </select>
                  </div>
                </div>
                <div
                  *ngIf="!getRuolo(OPERATORE_STRUTTURA)"
                  class="groupo-inline"
                >
                  <div class="col-2 form-group">
                    <label style="font-weight: bold">Tipo Rapporto SSN:</label>
                    <select
                      class="form-control"
                      formControlName="tipoSsnSintesi"
                      name="tipoSsnSintesi"
                      formGorupName="ricercaSintesiForm"
                      (change)="changeSsnSintesi($event)"
                      [(ngModel)]="defaultSelectSsnSintesi"
                    >
                      <option value="0">Tutti</option>
                      <option value="1">Pubblico</option>
                      <option value="2">Privato</option>
                    </select>
                  </div>
                </div>
                <div
                  *ngIf="!getRuolo(OPERATORE_STRUTTURA)"
                  class="groupo-inline"
                >
                  <div *ngIf="false" class="col-3 form-group">
                    <label style="font-weight: bold">Competenza territoriale: </label>
                    <select
                      class="form-control"
                      formControlName="competenzeterritorialiSintesi"
                      name="competenzeterritorialiSintesi"
                      formGorupName="ricercaSintesiForm"
                      (change)="changeCompetenzeTerritorialiSintesi()"
                      [(ngModel)]="defaultCompetenzeTerritorialiSintesi"
                    >
                      <option value="tutte"></option>
                      <option value="struttureInAsl">Strutture dell'Asl</option>
                      <option value="fuoriasl">Strutture fuori Asl</option>
                    </select>
                  </div>
                </div>
                <div
                  *ngIf="!getRuolo(OPERATORE_STRUTTURA)"
                  class="groupo-inline"
                >
                  <div class="col-3 form-group">
                    <label style="font-weight: bold">Struttura: </label>
                    <select style="width:250px"
                            class="form-control"
                            formControlName="strutturaSintesi"
                            name="strutturaSintesi"
                            formGorupName="ricercaSintesiForm"

                            [(ngModel)]="defaultSelectStruttureSintesi"
                    >
<!--                      (change)="changeStrutturaSintesi($event)"-->
                      <option value="tutte">Tutte</option>
                      <!--                        <option *ngIf="!this.getRuolo(OPERATORE_REGIONE)" value="fuoriasl">Strutture fuori Asl</option>-->
                      <!--                        <option *ngIf="!this.getRuolo(OPERATORE_REGIONE)" value="struttureInAsl">Strutture dell'Asl</option>-->
                      <option
                        *ngFor="let struttura of struttureListSintesi"
                        value="{{ struttura.codStruttura }}"
                      >
                        {{ struttura.denominazioneStruttura }}
                      </option>
                    </select>
                  </div>
                </div>
                <div
                  class="groupo-inline"
                >
                  <div class="col-3 form-group">
                    <label style="font-weight: bold">&nbsp;</label>
                    <div class="btn-group-center">
                      <button type="button" class="btn btn-secondary"
                              (click)="clickCalcolaSintesi()" >
                        Avvia Ricerca
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 form-group">
                <label style="font-weight: bold">Per visualizzare i dati nella dashboard corrispondenti ai criteri di selezione cliccare su “Avvia ricerca”.<br>Si ricorda che è possibile modificare i valori di default definendo specifici criteri di selezione cliccando sui singoli campi.</label>
              </div>
            </form>
          </div>
          <!-- FINE FILTRO -->
          <div style="margin: 25px;  justify-content: space-between; display: flex; gap:15px">
            <div style="flex: 20">
              <app-showdataclick
                [misureValue]="contattiScartati"
                [misureLabel]="misureLabel17"
                [misureIcon]="iconPersonOff"
                [misureColor]="colorRed"
                [misureClick]="misureLink1"
                [misureYear]="annoSelezionatoSintesi!"
                [misureAmbito]="ambitoSelezionatoSintesi!"
                [misureTipo]="tipossnSelezionatoSintesi!"
                [misureStruttura]="formSintesiStruttura!"
                [misureDenomStruttura]="denominazioneStruttura!"
                [misureAsl]="formSintesiAsl!"
              >
              </app-showdataclick>
            </div>
            <div style="flex: 20">
              <app-showdataclick
                [misureValue]="interventiScartati"
                [misureLabel]="misureLabel18"
                [misureIcon]="iconMedication"
                [misureColor]="colorRed"
                [misureClick]="misureLink2"
                [misureYear]="annoSelezionatoSintesi!"
                [misureAmbito]="ambitoSelezionatoSintesi!"
                [misureTipo]="tipossnSelezionatoSintesi!"
                [misureStruttura]="formSintesiStruttura!"
                [misureDenomStruttura]="denominazioneStruttura!"
                [misureAsl]="formSintesiAsl!"
              >
              </app-showdataclick>
            </div>
            <div style="flex: 20">
              <app-showdataclick
                [misureValue]="diagnosiScartate"
                [misureLabel]="misureLabel19"
                [misureIcon]="iconDiagnosis"
                [misureColor]="colorRed"
                [misureClick]="misureLink3"
                [misureYear]="annoSelezionatoSintesi!"
                [misureAmbito]="ambitoSelezionatoSintesi!"
                [misureTipo]="tipossnSelezionatoSintesi!"
                [misureStruttura]="formSintesiStruttura!"
                [misureDenomStruttura]="denominazioneStruttura!"
                [misureAsl]="formSintesiAsl!"
              >
              </app-showdataclick>
            </div>
            <div style="flex: 20">
              <app-showdataclick
                [misureValue]="prestazioniScartate"
                [misureLabel]="misureLabel20"
                [misureIcon]="iconMedicalServices"
                [misureColor]="colorRed"
                [misureClick]="misureLink5"
                [misureYear]="annoSelezionatoSintesi!"
                [misureAmbito]="ambitoSelezionatoSintesi!"
                [misureTipo]="tipossnSelezionatoSintesi!"
                [misureStruttura]="formSintesiStruttura!"
                [misureDenomStruttura]="denominazioneStruttura!"
                [misureAsl]="formSintesiAsl!"
              >
              </app-showdataclick>
            </div>
            <div style="flex: 20">
              <app-showdataclick
                [misureValue]="presenzeScartate"
                [misureLabel]="misureLabel21"
                [misureIcon]="iconPresence"
                [misureColor]="colorRed"
                [misureClick]="misureLink4"
                [misureYear]="annoSelezionatoSintesi!"
                [misureAmbito]="ambitoSelezionatoSintesi!"
                [misureTipo]="tipossnSelezionatoSintesi!"
                [misureStruttura]="formSintesiStruttura!"
                [misureDenomStruttura]="denominazioneStruttura!"
                [misureAsl]="formSintesiAsl!"
              >
              </app-showdataclick>
            </div>
          </div>
          <div style="margin: 25px; justify-content: space-between; display: flex; gap:15px">
            <div style="flex: 20">
              <app-showdataclick
                [misureValue]="contattiDaValidare"
                [misureLabel]="misureLabel22"
                [misureIcon]="iconPersonOff"
                [misureColor]="colorYellow"
                [misureClick]="misureLink6"
                [misureYear]="annoSelezionatoSintesi!"
                [misureAmbito]="ambitoSelezionatoSintesi!"
                [misureTipo]="tipossnSelezionatoSintesi!"
                [misureStruttura]="formSintesiStruttura!"
                [misureDenomStruttura]="denominazioneStruttura!"
                [misureAsl]="formSintesiAsl!"
              >
              </app-showdataclick>
            </div>
            <div style="flex: 20">
              <app-showdataclick
                [misureValue]="interventiDaValidare"
                [misureLabel]="misureLabel23"
                [misureIcon]="iconMedication"
                [misureColor]="colorYellow"
                [misureClick]="misureLink7"
                [misureYear]="annoSelezionatoSintesi!"
                [misureAmbito]="ambitoSelezionatoSintesi!"
                [misureTipo]="tipossnSelezionatoSintesi!"
                [misureStruttura]="formSintesiStruttura!"
                [misureDenomStruttura]="denominazioneStruttura!"
                [misureAsl]="formSintesiAsl!"
              >
              </app-showdataclick>
            </div>
            <div style="flex: 20">
              <app-showdataclick
                [misureValue]="diagnosiDaValidare"
                [misureLabel]="misureLabel24"
                [misureIcon]="iconDiagnosis"
                [misureColor]="colorYellow"
                [misureClick]="misureLink8"
                [misureYear]="annoSelezionatoSintesi!"
                [misureAmbito]="ambitoSelezionatoSintesi!"
                [misureTipo]="tipossnSelezionatoSintesi!"
                [misureStruttura]="formSintesiStruttura!"
                [misureDenomStruttura]="denominazioneStruttura!"
                [misureAsl]="formSintesiAsl!"
              >
              </app-showdataclick>
            </div>
            <div style="flex: 20">
              <app-showdataclick
                [misureValue]="prestazioniDaValidare"
                [misureLabel]="misureLabel25"
                [misureIcon]="iconMedicalServices"
                [misureColor]="colorYellow"
                [misureClick]="misureLink9"
                [misureYear]="annoSelezionatoSintesi!"
                [misureAmbito]="ambitoSelezionatoSintesi!"
                [misureTipo]="tipossnSelezionatoSintesi!"
                [misureStruttura]="formSintesiStruttura!"
                [misureDenomStruttura]="denominazioneStruttura!"
                [misureAsl]="formSintesiAsl!"
              >
              </app-showdataclick>
            </div>
            <div style="flex: 20">
              <app-showdataclick
                [misureValue]="presenzeDaValidare"
                [misureLabel]="misureLabel26"
                [misureIcon]="iconPresence"
                [misureColor]="colorYellow"
                [misureClick]="misureLink10"
                [misureYear]="annoSelezionatoSintesi!"
                [misureAmbito]="ambitoSelezionatoSintesi!"
                [misureTipo]="tipossnSelezionatoSintesi!"
                [misureStruttura]="formSintesiStruttura!"
                [misureDenomStruttura]="denominazioneStruttura!"
                [misureAsl]="formSintesiAsl!"
              >
              </app-showdataclick>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="reportTab"
    class="card"
    style="margin: 0px; border-radius: 15px; background-color: #f0f6fc"
  >

    <!--div class="row" style="margin: 25px">
      <div class="col-12">
        <h2>Report operatori</h2>
      </div>
    </div-->
    <!--div class="row" *ngIf="isRegione()" style="margin: 25px {{isRegione()?'visibility:visible':'visibility:hidden'}}"-->
      <div class="row" style="margin: 25px">
      <div

        class="col-3"
      >
        <div
          class="card mt-3"
          style="background-color: white; border-radius: 10px"
        >
          <div class="card-body">
            <div class="row" style="margin: 10px">
              <div class="col-12">
                <div
                  style="
                    background-color: #0461ad;
                    /* border-radius: 50px; */
                    margin-top: 5px;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                  "
                >
                  <img
                    src="assets/icons/graph.png"
                    class="s-icon icon-sm"
                    style="
                      margin-left: 10px;
                      margin-top: 5px;
                      width: 100px;
                      height: 100px;
                    "
                    (click)="
                      switchToReportData(
                        'Indicatori Operatori',
                        '/knowage/servlet/AdapterHTTP?ACTION_NAME=EXECUTE_DOCUMENT_ACTION&OBJECT_LABEL=SB001&TOOLBAR_VISIBLE=true&ORGANIZATION=DEFAULT_TENANT&NEW_SESSION=true&ROLE=' + utente.nomeRuolo + params
                      )
                    "
                  />
                </div>
              </div>
            </div>
            <div
              class="card-text"
              style="text-align: center; font-weight: bold"
            >
              Indicatori Operatori
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf=" getRuolo(OPERATORE_ASL) ||
          getRuolo(OPERATORE_DSM) ||
          getRuolo(OPERATORE_REGIONE) ||
          (getRuolo(OPERATORE_STRUTTURA) && isTerritoriale())"
        class="col-3"
      >
        <div
          class="card mt-3"
          style="background-color: white; border-radius: 10px"
        >
          <div class="card-body">
            <div class="row" style="margin: 10px">
              <div class="col-12">
                <div
                  style="
                    background-color: #0461ad;
                    /* border-radius: 50px; */
                    margin-top: 5px;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                  "
                >
                  <img
                    src="assets/icons/graph.png"
                    class="s-icon icon-sm"
                    style="
                      margin-left: 10px;
                      margin-top: 5px;
                      width: 100px;
                      height: 100px;
                    "
                    (click)="
                      switchToReportData(
                        'Indicatori Territoriale',
                        '/knowage/servlet/AdapterHTTP?ACTION_NAME=EXECUTE_DOCUMENT_ACTION&OBJECT_LABEL=SB003&TOOLBAR_VISIBLE=true&ORGANIZATION=DEFAULT_TENANT&NEW_SESSION=true&ROLE=' + utente.nomeRuolo + params
                      )
                    "
                  />
                </div>
              </div>
            </div>
            <div
              class="card-text"
              style="text-align: center; font-weight: bold"
            >
              Indicatori Territoriale
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="  getRuolo(OPERATORE_ASL) ||
          getRuolo(OPERATORE_DSM) ||
          getRuolo(OPERATORE_REGIONE) ||
          (getRuolo(OPERATORE_STRUTTURA) && !isTerritoriale())"
        class="col-3"
      >
        <div
          class="card mt-3"
          style="background-color: white; border-radius: 10px"
        >
          <div class="card-body">
            <div class="row" style="margin: 10px">
              <div class="col-12">
                <div
                  style="
                    background-color: #0461ad;
                    /* border-radius: 50px; */
                    margin-top: 5px;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                  "
                >
                  <img
                    src="assets/icons/graph.png"
                    class="s-icon icon-sm"
                    style="
                      margin-left: 10px;
                      margin-top: 5px;
                      width: 100px;
                      height: 100px;
                    "
                    (click)="
                      switchToReportData(
                        'Indicatori Semiresidenziale',
                        '/knowage/servlet/AdapterHTTP?ACTION_NAME=EXECUTE_DOCUMENT_ACTION&OBJECT_LABEL=SB002&TOOLBAR_VISIBLE=true&ORGANIZATION=DEFAULT_TENANT&NEW_SESSION=true&ROLE=' + utente.nomeRuolo + params
                      )
                    "
                  />
                </div>
              </div>
            </div>
            <div
              class="card-text"
              style="text-align: center; font-weight: bold"
            >
              Indicatori Semiresidenziale
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="row" style="margin: 25px; {{isRegione()?'visibility:visible':'visibility:visible'}} ">
      <div class="col-3">
        <div
          class="card mt-3"
          style="background-color: white; border-radius: 10px">
          <div class="card-body">
            <div class="row" style="margin: 10px">
              <div class="col-12">
                <div
                  style="
                    background-color: #0461ad;
                    /* border-radius: 50px; */
                    margin-top: 5px;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                  "
                >
                  <img
                    src="assets/open-iconic-master/svg/cloud-download.svg"
                    class="s-icon icon-sm white-svg"
                    style="
                      margin-left: 10px;
                      margin-top: 5px;
                      width: 100px;
                      height: 77px;
                      color: white;
                    "
                  />
                </div>
              </div>
            </div>

            <div
              class="card-text"
              style="text-align: center; font-weight: bold"
            >
              <div ngbDropdown class="d-inline-block" *ngIf="!downloadInCorso">
                <button type="button" class="btn btn-outline-secondary" id="dropdownBasic1" ngbDropdownToggle>
                  SCARICA DATI CSV
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                  <button ngbDropdownItem (click)=" downloadCsv('anagrafica',downloadcsv)" style="font-weight: bolder">
                    ANAGRAFICA
                  </button>
                  <button ngbDropdownItem (click)=" downloadCsv('contatto-territoriale',downloadcsv)"
                          *ngIf="!getRuolo(OPERATORE_STRUTTURA) || (getRuolo(OPERATORE_STRUTTURA) && isTerritoriale())"
                          style="font-weight: bolder">CONTATTO TERRITORIALE
                  </button>
                  <button ngbDropdownItem (click)=" downloadCsv('intervento-territoriale',downloadcsv)"
                          *ngIf="!getRuolo(OPERATORE_STRUTTURA) || (getRuolo(OPERATORE_STRUTTURA) && isTerritoriale())"
                          style="font-weight: bolder">INTERVENTO
                  </button>
                  <button ngbDropdownItem (click)=" downloadCsv('diagnosi-scale',downloadcsv)"
                          *ngIf="!getRuolo(OPERATORE_STRUTTURA) || (getRuolo(OPERATORE_STRUTTURA) && isTerritoriale())"
                          style="font-weight: bolder">DIAGNOSI & SCALE
                  </button>
                  <button ngbDropdownItem (click)=" downloadCsv('prestazione-territoriale',downloadcsv)"
                          *ngIf="!getRuolo(OPERATORE_STRUTTURA) || (getRuolo(OPERATORE_STRUTTURA) && isTerritoriale())"
                          style="font-weight: bolder">PRESTAZIONE TERRITORIALE
                  </button>
                  <button ngbDropdownItem (click)=" downloadCsv('contatto-semiresidenziale',downloadcsv)"
                          *ngIf="!getRuolo(OPERATORE_STRUTTURA) || (getRuolo(OPERATORE_STRUTTURA) && !isTerritoriale())"
                          style="font-weight: bolder">CONTATTO SEMIRESIDENZIALE
                  </button>
                  <button ngbDropdownItem (click)=" downloadCsv('presenze-semiresidenziale',downloadcsv)"
                          *ngIf="!getRuolo(OPERATORE_STRUTTURA) || (getRuolo(OPERATORE_STRUTTURA) && !isTerritoriale())"
                          style="font-weight: bolder">PRESENZA SEMIRESIDENZIALE
                  </button>
                  <button ngbDropdownItem (click)=" downloadCsv('prestazione-semiresidenziale',downloadcsv)"
                          *ngIf="!getRuolo(OPERATORE_STRUTTURA) || (getRuolo(OPERATORE_STRUTTURA) && !isTerritoriale())"
                          style="font-weight: bolder">PRESTAZIONE SEMIRESIDENZIALE
                  </button>
                </div>
              </div>
              <div class="alert-secondary" *ngIf="downloadInCorso" style="height: 40px; line-height: 40px; vertical-align: middle">
                DOWNLOAD IN CORSO
                <div class="spinner-border spinner-border-sm" role="status" style="vertical-align: middle !important; margin-left: 5px">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    </div>-->
    <!--     <div [innerHtml]="innerHtml"></div>-->
  </div>
  <div
    *ngIf="reportData"
    class="card"
    style="margin: 0px; border-radius: 15px; background-color: #f0f6fc"
  >
    <div class="row" style="margin: 25px">
      <div class="col">
        <iframe width="100%" height="600" [src]="safeLinkReport"></iframe>
      </div>
    </div>
  </div>
</div>


<ng-template #downloadcsv let-modal>
  <div class="modal-header" style="background-color: #012346 !important; cursor: pointer;">
    <h4 class="modal-title" id="modal-basic-title_31" style="color: white;">ATTENZIONE</h4>
    <a (click)="modal.dismiss('Cross click')">
      <svg class="s-icon icon-xxs">
        <use xlink:href="assets/icons/sprite.svg#close"></use>
      </svg>
    </a>
  </div>
  <div class="modal-body">
    <div class="container">

      <!--        <label>Specifica ANNO e PERIODO</label>-->
      <h3 style="margin-top: 5px; margin-bottom: 2px">{{labelAnnoPeriodo}}</h3>
      <div style="margin-top: 2px; margin-bottom: 10px; font-size: 120%;">{{labelSubAnnoPeriodo}}</div>
      <div class="row">

        <!--div class="col-6 form-group">
          <label style="font-weight: bold">{{labelPeriodo}}: </label>
          <select
            class="form-control"
            name="periodo"
            [(ngModel)]="periodoCSV">
            <option value="0" selected>Tutti</option>
            <option value="1">Gennaio</option>
            <option value="2">Febbraio</option>
            <option value="3">Marzo</option>
            <option value="4">Aprile</option>
            <option value="5">Maggio</option>
            <option value="6">Giugno</option>
            <option value="7">Luglio</option>
            <option value="8">Agosto</option>
            <option value="9">Settembre</option>
            <option value="10">Ottobre</option>
            <option value="11">Novembre</option>
            <option value="12">Dicembre</option>
          </select>
        </div-->
        <div class="card" [style]="errorMisura ? 'border-color: red;' : ''" >
          <div class="card-body">
            <div class="row">
              <div class="col-12 form-group">
                <label style="font-weight: bold">Misura di frequenza * : </label>
                <select
                  class="form-control"
                  name="misuraDiFrequenza"
                  [(ngModel)]="misuraDiFrequenzaCSV"
                >
                  <option value="" disabled selected>Effettuare la selezione</option>
                  <option value="Incidente">Incidente</option>
                  <option value="Prevalente">Prevalente</option>
                </select>
              </div>
              <div class="form-group col-6">
                <label style="font-weight: bold">Periodo dal * </label>
                <div class="input-group">
                  <input type="text" class="form-control" placeholder="aaaa-mm-gg" name="dp" autocomplete="off"
                         ngbDatepicker #aperturaDatoDa="ngbDatepicker" name="selectedDate" [(ngModel)]="dateDa"
                         [maxDate]="maxDateA">
                  <div class="input-group-append">
                    <button class="btn btn-secondary" (click)="aperturaDatoDa.toggle()" type="button">
                      <img class="img-responsive" src="../assets/icons/calendar_ko.png" style="width: 20px; height:20px">
                    </button>
                  </div>
                </div>
              </div>
              <div class="form-group col-6 ">
                <label style="font-weight: bold">Periodo al * </label>
                <div class="input-group">
                  <input type="text" class="form-control" placeholder="aaaa-mm-gg" name="dp" autocomplete="off"
                         ngbDatepicker #aperturaDatoA="ngbDatepicker" name="selectedDate" [(ngModel)]="dateA"
                          [maxDate]="maxDateA">
                  <div class="input-group-append">
                    <button class="btn btn-secondary" (click)="aperturaDatoA.toggle()" type="button">
                      <img class="img-responsive" src="../assets/icons/calendar_ko.png" style="width: 20px; height:20px">
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 form-group" [style]="errorAnno ? 'border-color: red;' : ''" *ngIf="typeCSV.toLowerCase() == 'intervento-territoriale' || typeCSV.toLowerCase() == 'diagnosi-scale' || typeCSV.toLowerCase() == 'prestazione-territoriale' || typeCSV.toLowerCase() == 'presenze-semiresidenziale'  || typeCSV.toLowerCase() == 'prestazione-semiresidenziale'">
          <label style="font-weight: bold">{{labelAnno}}: </label>
          <select
            class="form-control"
            name="anno"
            [(ngModel)]="annoCSV">
            <option value="0" *ngIf="typeCSV.toLowerCase() == 'intervento-territoriale' || typeCSV.toLowerCase() == 'diagnosi-scale'">Tutti</option>
            <option *ngFor="let year of anni" value="{{ year }}">
              {{ year }}
            </option>
          </select>
        </div>
        <div class="card" style="margin-top: 10px">
          <div class="card-body">
            <div class="row">


              <div class="col-4 form-group" *ngIf="getRuolo(OPERATORE_REGIONE)">
                <label style="font-weight: bold">Asl: </label>
                <select
                  class="form-control"
                  name="asl"
                  [(ngModel)]="aslCSV"
                  (change)="changeAslCsv()"
                >
                  <option value="">Tutte</option>
                  <option
                    *ngFor="let asl of aslList"
                    value="{{ asl.codiceAzienda }}"
                  >
                    {{ asl.denominazioneAzienda }}
                  </option>
                </select>
              </div>
              <div class="col-4 form-group" *ngIf="!getRuolo(OPERATORE_STRUTTURA) && typeCSV.toLowerCase() == 'anagrafica' ">
                <label style="font-weight: bold">Ambito: </label>
                <select
                  class="form-control"
                  name="ambito"
                  [(ngModel)]="ambitoCSV"
                  (change)="changeAmbitoCsv()"
                >
                  <option value="">Tutti</option>
                  <option value="Territoriale">Territoriale</option>
                  <option value="Semiresidenziale">
                    Semiresidenziale
                  </option>
                </select>
              </div>
              <div class="col-4 form-group" *ngIf="!getRuolo(OPERATORE_STRUTTURA)">
                <label style="font-weight: bold">Tipo Rapporto SSN:</label>
                <select
                  class="form-control"
                  name="tipoSsn"
                  [(ngModel)]="ssnCSV"
                  (change)="changeSsnCsv()"
                >
                  <option value="0">Tutti</option>
                  <option value="1">Pubblico</option>
                  <option value="2">Privato</option>
                </select>
              </div>
              <div *ngIf="getRuolo(OPERATORE_ASL) || getRuolo(OPERATORE_DSM)" class="col-4 form-group">
                <label style="font-weight: bold">Competenza territoriale: </label>
                <select
                  class="form-control"
                  name="competenzeterritoriali"
                  [(ngModel)]="competenzeTerritorialiCCSV"
                  (change)="changeCompetenzeCsv()"
                >
                  <option value="struttureInAsl">Strutture dell'Asl</option>
                  <option value="fuoriasl">Strutture fuori Asl</option>
                </select>
              </div>

            </div>


            <div class="row">
              <div class="col-12 form-group" *ngIf="!getRuolo(OPERATORE_STRUTTURA)">
                <label style="font-weight: bold">Struttura: </label>
                <select
                  class="form-control"
                  name="strutturaSintesi"
                  [(ngModel)]="struttureCSV"
                >
                  <option value="">Tutte</option>
                  <!--                        <option *ngIf="!this.getRuolo(OPERATORE_REGIONE)" value="fuoriasl">Strutture fuori Asl</option>-->
                  <!--                        <option *ngIf="!this.getRuolo(OPERATORE_REGIONE)" value="struttureInAsl">Strutture dell'Asl</option>-->
                  <option
                    *ngFor="let struttura of struttureListCsv"
                    value="{{ struttura.codStruttura }}"
                  >
                    {{ struttura.denominazioneStruttura }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-6 form-group" *ngIf="!(typeCSV.toLowerCase() == 'anagrafica'&&isSemiresidenziale())&&(typeCSV.toLowerCase() == 'anagrafica' || typeCSV.toLowerCase() == 'contatto-territoriale' )  " >
                <label style="font-weight: bold">Avvio Contatto: </label>
                <select
                  class="form-control"
                  name="avvioContatto"
                  [(ngModel)]="avvioContattoCSV"
                >
                  <option value="tutti" *ngIf="!tipoStrutturaSemiresidenziale()">Tutti</option>
                  <option value="avviato">Avviato</option>
                  <option value="non_avviato" *ngIf="!tipoStrutturaSemiresidenziale()">Non avviato</option>

                </select>
              </div>
              <div class="col-6 form-group" *ngIf="typeCSV.toLowerCase() != 'contatto-semiresidenziale' && typeCSV.toLowerCase() != 'contatto-territoriale'">
                <label style="font-weight: bold">Stato trasmissione contatto:</label>
                <select
                  class="form-control"
                  name="statoContatto"
                  [(ngModel)]="statoContattoCSV"
                >
                  <option value="-1">Tutti</option>
                  <option value="0" *ngIf="getRuolo(OPERATORE_STRUTTURA)||getRuolo(OPERATORE_REGIONE)">Da Modificare</option>
                  <option value="1" *ngIf="!getRuolo(OPERATORE_ASL)&&!getRuolo(OPERATORE_DSM)">Da Trasmettere</option>
                  <option value="2">Da Validare</option>
                  <option value="3">Validati</option>
                  <option value="4">Scartati</option>
                </select>
              </div>
              <div class="col-6 form-group" *ngIf="typeCSV.toLowerCase() != 'anagrafica'">
                <label style="font-weight: bold">Stato trasmissione {{labelTrasmissione}}:</label>
                <select
                  class="form-control"
                  name="statoRecord"
                  [(ngModel)]="statoRecordCSV"
                >
                  <option value="-1">Tutti</option>
                  <option value="0" *ngIf="getRuolo(OPERATORE_STRUTTURA)||getRuolo(OPERATORE_REGIONE)">Da Modificare</option>
                  <option value="1" *ngIf="!getRuolo(OPERATORE_ASL)&&!getRuolo(OPERATORE_DSM)">Da Trasmettere</option>
                  <option value="2">Da Validare</option>
                  <option value="3">Validati</option>
                  <option value="4">Scartati</option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-6 form-group">
                <label style="font-weight: bold">Nome:</label>
                <input
                  type="text"
                  class="form-control"
                  name="nome"
                  [(ngModel)]="nomeCSV">
              </div>
              <div class="col-6 form-group">
                <label style="font-weight: bold">Cognome:</label>
                <input type="text"
                       class="form-control"
                       name="cognome"
                       [(ngModel)]="cognomeCSV">
              </div>
            </div>
            <div class="row">
              <div class="col-6 form-group">
                <label style="font-weight: bold">Tipo identificativo:</label>
                <select
                  class="form-control"
                  name="tipoIdentificativo"
                  [(ngModel)]="tipoIdentificativoCSV"
                >
                  <option value="">Tutti</option>
                  <option value="CF">CF</option>
                  <option value="STP">STP</option>
                  <option value="ENI">ENI</option>
                </select>
              </div>
              <div class="col-6 form-group">
                <label style="font-weight: bold">Identificativo: <span *ngIf="tipoIdentificativoCSV">*</span></label>
                <input type="text"
                       class="form-control"
                       [style]="errorIdentificativo && tipoIdentificativoCSV? 'border-color: red;' : ''"
                       name="codiceFiscale"
                       [(ngModel)]="codiceFiscaleCSV">
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" style="text-align: justify; padding: 10px;">
    <h6 class="text-info">*Campo obbligatorio</h6>
    <h6 class="text-danger" style="font-weight: bolder;text-align: justify; line-height: 30px">La funzione richiesta comporta una estrazione
      di dati anche personali.<br>Cliccando su “Conferma download” ci si assume le responsabilità di potenziali lesioni
      sulla riservatezza e integrità della copia dei dati anche personali diventati di suo possesso. Cliccare su "
      Conferma download " comporta un tracciamento negli activity log del sistema.</h6>
  </div>
  <hr>
  <div class="modal-footer" style="align-self: end">
    <button type="button" class="btn btn-outline-danger" (click)="downloadCsvFile()">Conferma download</button>
  </div>
</ng-template>
