import { StringMap } from "@angular/compiler/src/compiler_facade_interface"
import { DatepickerViewModel } from "@ng-bootstrap/ng-bootstrap/datepicker/datepicker-view-model"



export class Utente{
  userName?:string
	userId?:string
  cognome?:string
	nome?:string
  codiciStrutture?:string[]
	codiceAsl?:string
	nomeRuolo?:string
  idRuolo?:number
  ruoli?: Ruolo[] = [];
	flussiUtente?:FlussoUtente[]
  funzionalita:string[] = [];
}

export interface Ruolo{
  id:number,
  nome:string
}

export class FlussoUtente {
	codiceFlusso?:string
	supervisore?:boolean
	monitoraggio?:boolean
	compilatore?:boolean
	trasmissore?:boolean
}

export class Asl {
  codiceAzienda?:string
  denominazioneAzienda?:string
}

export class RicercaAnagrafica {
  idAnagrafica:number = 0;

  cognome: string = '';
  nome: string = '';
  aslResidenza: string = '';
  codiceAslResidenza: string = '';
  codiceFiscale: string= '';
  numeroContatti:number = 0;
  numeroPrestazioni:number= 0;
  dataAperturaUltimoContatto:Date|null = null;
  alert:boolean = false;

  ultimaAslPrestazione: string = '';
	ultimaAslPrestazioneDesc: string = '';
	ultimaStrutturaPrestazione: string = '';
	ultimaStrutturaPrestazioneDesc: string = '';
	ultimaAslAttiva: string = '';
	ultimaStrutturaAttiva: string = '';
  ultimaTipoPrestazioneAttiva: string = '';
  dataUltimaPrestazione:Date | undefined;
  dataUltimaPresenza:Date | undefined;
  tipoUltimaPrestazione: string= '';


//questi sotto da eliminare
  cfCertificato:string= '';


  tipoPrestazioneContattoUltimaPrestazione:string =''
  tipoUltimaPrestazioneTerr:string =''
  tipoUltimaPrestazioneSemi:string =''
  lastAslActiveCodeSemi:string =''
  lastAslActiveCodeTerr:string =''
  contattoAttivo: any;
  contattoAttivoExtra: any;
  haUnContattoAttivoTerritoriale?: number;
  haUnContattoAttivoSemiresidenziale?: undefined;
  haUnContattoAttivoTerritorialeExtra?: number;
  haUnContattoAttivoSemiresidenzialeExtra?: undefined;



}

export class DettaglioAnagrafica {
  /**
   * è null quando il dato non è stato ancora salvato nel database
   */
  id: number|null = null;
  cfCertificato:string= ''; //TODO popolare in funzione dei dati che arrivano da asur
  cognome: string = '';
  nome: string = '';
  codiceFiscale: string= '';
  sesso: string= '';

  dataNascita?: Date;
  statoCivile : string= '';
  cittadinanza: string= '';
  cittadinanzaCod : string = "";
  regioneResidenzaCod : string = "";
  codiceComuneNascita: string= '';
  descrizioneComuneNascita: string= '';


  statoResidenza: string= '';
  statoResidenzaCod: string = "";
  statoCivileCod: string = "";
  regioneResidenza: string= '';
  comuneResidenza: string= '';
  comuneResidenzaCod: string = "";
  distrettoResidenza:string='';
  distrettoResidenzaCod:string='';
  municipioResidenza:string='';
  municipioResidenzaCod: string = "";

  aslResidenza: string= '';
  aslResidenzaCod: string = "";
  tutelaGiuridica: string= '';
  collocazioneSocioAmbientale: string= '';
  collocazioneSocioAmbientaleCod: string= '';
  titoloDiStudio: string= '';
  attivita: string= '';
  titoloStudioCod: string = "";
  invalidita: string= '';
  invaliditaCod: string = "";
  tutelaGiuridicaCod : string = "";
  familiarePaziente: string= '';
  attivitaCod: string = "";
  attualmenteSottoposto: string= '';
  attualmenteSottopostoCod: string= '';

  precedentementeInternato: string= '';

  ultimaPresenza?:Date;

  descrizioneStrutturaOperatoreInserimento:string ='';
  codiceStrutturaOperatoreInserimento: string = '';
  codiceAslOperatoreInserimento: string = '';
}


export class ContattoDto{
   id:number = 0;
   descrizioneStrutturaOperatoreInserimento:string = '';
   codiceStrutturaOperatoreInserimento:string = '';
   tipoPrestazione:string = '';
   dataRichiesta?:Date;
   dataApertura?:Date;
   dataChiusura?:Date;
   statoAttuale:number= 0;
   numeroPrestazioni:number= 0;
   numeroPresenze:number= 0;
   warning: boolean = false;
   asl?: string;
   codasl?: string;
  codiceAslOperatoreInserimento?: string;
}

export class Contatto{

  id?:number
  dataRichiesta?:Date
  tipoPrestazione?:string
  richiestaPrev?:string
  aperturaFlag?:string
  dataApertura?:Date
  codiceDiagnosiApertura?:string
  descrizioneDiagnosiApertura?:string
  precedentiContatti?:string
  inviante?:string
  interventoRiabilitativi?:boolean | null
  interventoInserimentiLavorativi?:boolean | null
  interventoInterveniSociali?:boolean | null
  interventoColloquiPsichiatrici?:boolean | null
  interventoCoinvolgimento?:boolean | null
  altro?:string
  nrInseritoPrestazioni?:number
  dataChiusura?:Date
  codiceDiagnosiChiusura?:string
  descrizioneDiagnosiChiusura?:string
  modalitaConclusione?:string
  statoAttuale:ConfStato = new ConfStato();
  interventoEffettuatoRiabilitativi?: boolean | null
  interventoEffettuatoLavorativi?:boolean | null
  interventoEffettuatoInterveniSociali?:boolean | null
  interventoEffettuatoColloquiPsichiatrici?:boolean | null
  interventoEffettuatoCoinvolgimento?:boolean | null

  interventoEffettuatoAltro?:string | null

  statoTrasmissione?:string

  anagrafica?:DettaglioAnagrafica

  prestazioniList?:Prestazione[]

  diagnosiEScaleList?:DiagnosiEScale[]

  codiceStrutturaOperatoreInserimento?:string;
  descrizioneStrutturaOperatoreInserimento?:string;

  usernameInserimento?:string;
  usernameAggiornamento?:string;
  codiceAslOperatoreInserimento?:string;
  dataInserimento?:Date;
  dataAggiornamento?:Date;
  //motivazioneScarto:string='';

  motivazioneScarto?:string;

  interventiList?:Intervento[]

  presenzaList?:Presenza[]
}

export class Prestazione{
  id?:number
  modalitaDiErogazione?:string
  descrizioneModalitaErogazione?:string
  dataPrestazione?:Date
  tipo?:string
  descrizioneTipo?:string
  sede?:string
  operatore1?:string
  operatore1Codice?:string
  operatore2?:string
  operatore2Codice?:string
  operatore3?:string
  operatore3Codice?:string
  codiceStrutturaOperatoreInserimento?:string;
  descrizioneStrutturaOperatoreInserimento?:string;

  contatto?:Contatto

  nrInseritoPrestazione?:number
  statoTrasmissione?:string
  dataInserimento?:Date
  presenza?:Presenza
  eliminato?:boolean;
  motivazioneScarto:string='';
  statoAttuale:ConfStato = new ConfStato();
  codiceAslOperatoreInserimento?: string;
}


export class StatoCivile{
  codice?:string
  descrizione?:string
}

export class StatoEstero{

  alpha2?:string
  descrizione?:string
}

export class Comune {
  descrizione: string = '';
  codice: string = '';
  codRegione: string ='';
}
export class Regione {

  regione?:string
  codRegione?:string
}

export class CollazioneSocioAmbientale {
  codice:string = '';
  descrizione?:string
}

export class TitoloStudio {
  codice:string = '';
  descrizione?:string
}

export class Ativitta {
  codice:string = '';
  descrizione?:string
}

export class Invalidita {
  codice: string = ''
  descrizione?:string
}

export class TutelaGiuridica {
  codice:string = ''
  descrizione?:string

}

export class Provedimento {
  codice:string = ''
  descrizione?:string
}

export class SoccioSanitario {

  codiceDsm?:string
}

export class Cittadinanza {
  descrizione:string = '';
  alpha2?:string
  codIstat?: number;
}

export class PrecedenteContatto {
  codice:string = ''
  descrizione?:string
}

export class Diagnosi {
  codice:string = ''
  descrizione?:string
}
export class Inviante {
  codice:string = ''
  descrizione?:string
}
export class ModalitaConclusione {
  codice:string = ''
  descrizione?:string
}

export class ModalitaErogazione {
  codice?:string
  descrizione?:string
}


export class Operatore {
  codice?:string
  descrizione?:string
}

export class SedeIntervento {

  codice?:string;
  descrizione?:string
}

export class TipoPrestazione {
  codice?:string
  descrizione?:string
}
export class RichiestaPrevalente {
  codice:string = ''
  descrizione?:string
}

export class PreseInCarico{
  stato?: string;
  data?: Date;
  dsm?: string;
  codiceFiscale?: string;
  cognomeNome?: string;
  nDatiContatto?: number;
  nPrestazioni?: number;
  isSelected?: boolean;
  idAnagrafica?: number;
}

export class Lotti{
  stato?: string;
  descrizioneStato?: string;
  descrizioneScartatiValidati:string = '';
  id?: string;
  dataLotto?: Date;
  tipoPrestazione?: string;
  struttura?: string;
  codiceStruttura?: string;
  annoCompetenza?: number;
  semestreCompetenza?: string;
  motivazioneScarto:string = '';

  numeroValidati?:number;
  numeroScartati?:number;
  numeroCorretti?:number;
  numeroDaCorreggere?:number;
  numeroTotale?:number;

}
export class DettaglioLotto{
  id!: number;
  codiceFiscale?: string;
  data?: Date;
  dsm?: string;
  nContatti?: number;
  nPrestazioni?: number;
  nomeCognome?: string;
  stato:string = '';
  descrizioneStato:string = '';
  anagraficaId?: number;
  lottoInvioAslId?: number;
}

export class DettaglioLottoUscita extends DettaglioLotto{
  isSelected:boolean = false;
}

export class Strutture{
  denominazioneStruttura?: string;
  anno?: number;
  codAzienda?: string;
  codStruttura?: string;
  tipoRapportoSsn?: number;
  descrTipoStruttura?: string;
  codice?:string;
}


export class Distretto {
  codice?:string
  descrizione?:string;
}
export class Municipio {
  codice?:string
  descrizione?:string;
}

export class Dashboard{

  contatti!:number;
  prestazioni!: number;
  utentiPrevalenti!: number;
  utentiIncidenti!: number;
  stranieraUtentiIncidenti!: number;
  sconosciutaUtentiIncidenti!: number;
  italianaUtentiIncidenti!: number;
  apolideUtentiIncidenti!: number;
  stranieraUtentiPrevalenti!: number;
  sconosciutaUtentiPrevalenti!: number;
  italianaUtentiPrevalenti!: number;
  apolideUtentiPrevalenti!: number;

}

export class Page<T> {
  content:T[] = [];
  totalPages: number = 0;
  totalElements: number = 0;
  last: boolean = false;
  number: number= 0;
  size: number= 20;
  numberOfElements: number= 0;
  first: boolean = false;
  empty: boolean = false;
  loading: boolean = true;
}


export class DettaglioPreseInCaricoDto {
   anagrafica?:DettaglioAnagrafica;
   contatti:Contatto[] = [];
   prestazioni:DettaglioPreseInCaricoPrestazioneDto[]= [];
}


export class DettaglioPreseInCaricoPrestazioneDto extends Prestazione{
    idContatto:number = 0;
}

export class DettaglioLottoRipristinaBody{
    listaIdDettagliLotto:number[] = [];
}


export class Intervento {
  id?:number
  periodo?:String
  data?:Date
  rispostaPrevalente?:string
  codiceStrutturaOperatoreInserimento?:string
  descrizioneStrutturaOperatoreInserimento?:string
  // PI
  piTrattamentoPsicoterapeutico?:Boolean
  piTrattamentoFarmacologico?:Boolean
  piInterventiRiabilitativi?:Boolean
  piInserimentiLavorativi?:Boolean
  piInterventoSociale?:Boolean
  piColloquiSostegno?:Boolean
  piCoinvolgimentoFamiliari?:Boolean
  piAccertamentiMedicoLegali?:Boolean
  piInvioInStruttura?:Boolean
  piInvioInOspedale?:Boolean
  piConsultazioneValutazione?:Boolean
  piAltro?:String

  // IE
  ieTrattamentoPsicoterapeutico?:Boolean
  ieTrattamentoFarmacologico?:Boolean
  ieInterventiRiabilitativi?:Boolean
  ieInserimentiLavorativi?:Boolean
  ieInterventoSociale?:Boolean
  ieColloquiSostegno?:Boolean
  ieCoinvolgimentoFamiliari?:Boolean
  ieAccertamentiMedicoLegali?:Boolean
  ieInvioInStruttura?:Boolean
  ieInvioStpit?:Boolean
  ieLuogoStpit?:Boolean
  ieInvioSrtri?:Boolean
  ieLuogoSrtri?:Boolean
  ieInvioSrtre?:Boolean
  ieLuogoSrtre?:Boolean
  ieInvioSrsr24?:Boolean
  ieLuogoSrsr24?:Boolean
  ieInvioSrsr12?:Boolean
  ieLuogoSrsr12?:Boolean
  ieInvioGa?:Boolean
  ieLuogoGa?:Boolean
  ieInvioCd?:Boolean
  ieLuogoCd?:Boolean
  ieInvioOspedale?:Boolean
  ieInvioOspedaleRep40?:Boolean
  ieLuogoOspedale?:Boolean
  ieConsultazioneValutazione?:Boolean
  ieAltro?:String
  contatto?:Contatto
  motivazioneScarto:string='';
  statoAttuale:ConfStato = new ConfStato();
  codiceAslOperatoreInserimento?: string;
}


export class DiagnosiEScale {
  id?:number
  data?:Date
  diagnosi?:string
  descrizioneDiagnosi?:string
  scalaHonos?:string
  scalaBprs?:string
  motivazioneScarto:string='';
  contatto?:Contatto
  statoAttuale:ConfStato = new ConfStato();
  codiceStrutturaOperatoreInserimento?:string;
  descrizioneStrutturaOperatoreInserimento?:string;
  motivazioniScarto?:string
  codiceAslOperatoreInserimento?: string;
}

export class StrutturaStr {
	codice?:string
  codAzienda?:number
	denominazione?:string
  descrTipoStruttura?:string
  tipoRapportoSsn?:string
}

export class RispostaPrevalente{
  codice?:string
  descrizione?:string
}

export class PresenzaDto {
  presenza:Presenza = new Presenza();
  numeroPrestazioni:number = 0;
}

export class Presenza {
  id ?:number
  codiceAslOperatoreInserimento?:string
  codiceDsmOperatoreInserimento?:string
  codiceRegione?:string
  codiceStrutturaOperatoreInserimento?:string
  dataPresenza?:Date
  dataAggiornamento?:Date
  dataEliminazione?:Date
  dataInserimento?:Date
  descrizioneStrutturaOperatoreInserimento?:string
  eliminato?:boolean
  idPresenzaImportazioneMassiva?:string
  tipoSemires?:string
  modPresenza?:string
  usernameAggiornamento?:string
  usernameInserimento?:string

  contatto?:Contatto
  descrizioneTipoSemires?:string
  motivazioneScarto:string='';
  statoAttuale:ConfStato = new ConfStato();
  descrizioneAslOperatoreInserimento: any;
}


export class ConfStato {
    id:number = 0;
    descrizione:string = '';
}

export class ConfFiltriRicerca {
  id:number = 0;
  descrizione:string = '';
}


export class NotAvailableDatesContainer {
  contattiDates ?:Date[]
  interventiDates ?:Date[]
  presenzeDates ?:Date[]
  diagnosiEScaleDates?:Date[]
  prestazioniDates?:Date[];
}



export class AnagraficaSessionSettings {
  pageSize:number = 20;
  anno: number | undefined;
  ambito: string | undefined;
  tipo_apporto_SSN: number | undefined;
  struttura: string | undefined;
  apertura_contatto: number | undefined;
  entita: string | undefined;
  stato_attuale: number | undefined;
  asl: string | undefined;
}



export class AslValidator{
  id ?:number
  codiceFiscale?: string;
  tipoStruttura?: string;
  struttura?: string;
  numeroContatti?: number;
  numeroInterventi?: number;
  numeroDiagnosi?: number;
  numeroPresenze?: number;
  numeroPrestazioni?: number;

}

export class AnagraficaStruttura{
  anagraficaId?: number;
  idAnonimoStr?:string;
  codiceAslOperatoreInserimento?: string;
  codiceDsmOperatoreInserimento?: string;
  codiceStrutturaOperatoreInserimento?: string;
  statoCivile?: string;
  collocazioneSocioAmbientale?: string;
  titoloDiStudio?: string;
  attivita?: string;
  familiarePaziente?: string;
  invalidita?: string;
  tutelaGiuridica?: string;
  attualmenteSottoposto?: string;
  precedentementeInternato?: string;
  usernameInserimento?: string;
  usernameAggiornamento?: string;
  dataInserimento?: Date;
  dataAggiornamento?: Date;
  eliminato?: boolean;
  dataEliminazione?: Date;
  cittadinanza?: string;
  regioneResidenza?: string;
  comuneResidenza?: string;
  aslResidenza?: string;
  distrettoResidenza?: string;
  municipioResidenza?: string;
  statoResidenza?: string;
}

export class AnagraficaAudit {
  id?: number;
  dataAudit?: Date;
  usernameAudit?: string;
  aslResidenza?: string;
  attivita?: string;
  attualmenteSottoposto?: string;
  cfCertificato?: string;
  cittadinanza?: string;
  codiceAslOperatoreInserimento?: string;
  codiceFiscale?: string;
  codiceStrutturaOperatoreInserimento?: string;
  cognome?: string;
  collocazioneSocioAmbientale?: string;
  comuneResidenza?: string;
  dataAggiornamento?: Date;
  dataInserimento?: Date;
  dataNascita?: Date;
  descrizioneComuneNascita?: string;
  descrizioneStrutturaOperatoreInserimento?: string;
  distrettoResidenza?: string;
  familiarePaziente?: string;
  invalidita?: string;
  municipioResidenza?: string;
  nome?: string;
  precedentementeInternato?: string;
  regioneResidenza?: string;
  sesso?: string;
  statoCivile?: string;
  statoResidenza?: string;
  titoloDiStudio?: string;
  tutelaGiuridica?: string;
  usernameAggiornamento?: string;
  usernameInserimento?: string;
  codiceComuneNascita?: string;
  codiceDsmOperatoreInserimento?: string;
  nazioneNascita?: string;
  dataEliminazione?: Date;
  eliminato?: boolean;
  idAnonimoStr?: string;

}

export class ContattoAudit{
  id?: number;
  dataAudit?: Date;
  usernameAudit?: string;
  codiceAslOperatoreInserimento?: string;
  codiceStrutturaOperatoreInserimento?: string;
  dataAggiornamento?: Date;
  dataApertura?: Date;
  dataChiusura?: Date;
  dataInserimento?: Date;
  dataRichiesta?: Date;
  descrizioneStrutturaOperatoreInserimento?: string;
  interventoCoinvolgimento?: boolean;
  interventoColloquiPsichiatrici?: boolean;
  interventoEffettuatoAltro?: boolean;
  interventoEffettuatoCoinvolgimento?: boolean;
  interventoEffettuatoColloquiPsichiatrici?: boolean;
  interventoEffettuatoInterveniSociali?: boolean;
  interventoEffettuatoLavorativi?: boolean;
  interventoEffettuatoRiabilitativi?: boolean;
  interventoInserimentiLavorativi?: boolean;
  interventoInterveniSociali?: boolean;
  interventoRiabilitativi?: boolean;
  inviante?: string;
  modalitaConclusione?: string;
  precedentiContatti?: string;
  richiestaPrev?: string;
  tipoPrestazione?: string;
  usernameAggiornamento?: string;
  usernameInserimento?: string;
  anagraficaId?: number;
  codiceDiagnosiApertura?: string;
  codiceDiagnosiChiusura?: string;
  descrizioneDiagnosiApertura?: string;
  descrizioneDiagnosiChiusura?: string;
  idContattoImportazioneMassiva?: string;
  codiceDsmOperatoreInserimento?: string;
  aperturaFlag?: string;
  dataEliminazione?: Date;
  eliminato?: boolean;
  idAnonimoStr?: string;

}

export class InterventoAudit{

  id?: number;
  dataAudit?: Date;
  usernameAudit?: string;
  codiceAslOperatoreInserimento?: string;
  codiceDsmOperatoreInserimento?: string;
  codiceStrutturaOperatoreInserimento?: string;
  data?: Date;
  dataAggiornamento?: Date;
  dataEliminazione?: Date;
  dataInserimento?: Date;
  descrizioneStrutturaOperatoreInserimento?: string;
  eliminato?: boolean;
  idInterventoImportazioneMassiva?: string;
  ieAccertamentiMedicoLegali?: boolean;
  ieCoinvolgimentoFamiliari?: boolean;
  ieColloquiSostegno?: boolean;
  ieConsultazioneValutazione?: boolean;
  ieInserimentiLavorativi?: boolean;
  ieInterventiRiabilitativi?: boolean;
  ieInterventoSociale?: boolean;
  ieInvioCd?: boolean;
  ieInvioGa?: boolean;
  ieInvioInStruttura?: boolean;
  ieInvioOspedale?: boolean;
  ieInvioOspedaleRep40?: boolean;
  ieInvioSrsr12?: boolean;
  ieInvioSrsr24?: boolean;
  ieInvioSrtre?: boolean;
  ieInvioSrtri?: boolean;
  ieInvioStpit?: boolean;
  ieLuogoCd?: boolean;
  ieLuogoGa?: boolean;
  ieLuogoOspedale?: boolean;
  ieLuogoSrsr12?: boolean;
  ieLuogoSrsr24?: boolean;
  ieLuogoSrtre?: boolean;
  ieLuogoSrtri?: boolean;
  ieLuogoStpit?: boolean;
  ieTrattamentoFarmacologico?: boolean;
  ieTrattamentoPsicoterapeutico?: boolean;
  ieAltro?: string;
  piAccertamentiMedicoLegali?: boolean;
  piAltro?: string;
  piCoinvolgimentoFamiliari?: boolean;
  piColloquiSostegno?: boolean;
  piConsultazioneValutazione?: boolean;
  piInserimentiLavorativi?: boolean;
  piInterventiRiabilitativi?: boolean;
  piInterventoSociale?: boolean;
  piInvioInOspedale?: boolean;
  piInvioInStruttura?: boolean;
  piTrattamentoFarmacologico?: boolean;
  piTrattamentoPsicoterapeutico?: boolean;
  rispostaPrevalente?: string;
  usernameAggiornamento?: string;
  usernameInserimento?: string;
  contattoId?: number;
  statoAttuale?: number;
  motivazioneScarto?: string;
  idAnonimoStr?: string;

}

export class DiagnosiEscaleAudit{

  id?: number;
  dataAudit?: Date;
  usernameAudit?: string;
  codiceAslOperatoreInserimento?: string;
  codiceDsmOperatoreInserimento?: string;
  codiceStrutturaOperatoreInserimento?: string;
  data?: Date;
  dataAggiornamento?: Date;
  dataEliminazione?: Date;
  dataInserimento?: Date;
  descrizioneStrutturaOperatoreInserimento?: string;
  diagnosi?: string;
  eliminato?: boolean;
  idDiagnosiImportazioneMassiva?: string;
  scalaBprs?: string;
  scalaHonos?: string;
  usernameAggiornamento?: string;
  usernameInserimento?: string;
  contattoId?: number;
  descrizioneDiagnosi?: string;
  statoAttuale?: number;
  motivazioneScarto?: string;
  idAnonimoStr?: string;

}

export class PrestazioneAudit{

  id?: number;
  dataAudit?: Date;
  usernameAudit?: string;
  codiceAslOperatoreInserimento?: string;
  codiceStrutturaOperatoreInserimento?: string;
  dataAggiornamento?: Date;
  dataEliminazione?: Date;
  dataInserimento?: Date;
  dataPrestazione?: Date;
  descrizioneStrutturaOperatoreInserimento?: string;
  modalitaDiErogazione?: string;
  operatore1?: string;
  operatore1Codice?: string;
  operatore2?: string;
  operatore2Codice?: string;
  operatore3?: string;
  operatore3Codice?: string;
  sede?: string;
  tipo?: string;
  usernameAggiornamento?: string;
  usernameInserimento?: string;
  contattoId?: number;
  descrizioneTipo?: string;
  descrizioneModalitaErogazione?: string;
  idPrestazioneImportazioneMassiva?: string;
  codiceDsmOperatoreInserimento?: string;
  sedeIntervento?: string;
  strutturaResidenziale?: string;
  eliminato?: boolean;
  presenzaId?: number;
  nrInseritoPrestazione?: number;
  statoAttuale?: number;
  motivazioneScarto?: string;
  idAnonimoStr?: string;

}

export class PresenzaAudit{

  id?: number;
  dataAudit?: Date;
  usernameAudit?: string;
  dataAggiornamento?: Date;
  dataEliminazione?: Date;
  dataInserimento?: Date;
  codiceAslOperatoreInserimento?: string;
  codiceDsmOperatoreInserimento?: string;
  codiceStrutturaOperatoreInserimento?: string;
  dataPresenza?: Date;
  descrizioneStrutturaOperatoreInserimento?: string;
  eliminato?: boolean;
  idPresenzaImportazioneMassiva?: string;
  tipoSemires?: string;
  descrizioneTipoSemires?: string;
  modPresenza?: string;
  usernameAggiornamento?: string;
  usernameInserimento?: string;
  contattoId?: number;
  statoAttuale?: number;
  motivazioneScarto?: string;
  idAnonimoStr?: string;

}

export class StoricoImportXml{
  id?: number;
  utente?: string;
  struttura?: string;
  timestamp?: Date;
  nomeFile?: string;
  tipoFile?: string;
  caricati?: number;
  errati?: number;
  totali?: number;
  scartoXsd?: boolean;
  reportScarto?: string;

}

export class AnagraficaStorica{
  id: number| null = null;
  cognome: string = "";
  nome: string = "";
  codiceFiscale: string = "";
  sesso: string = "";
  statoCivile: string = "";
  cittadinanza: string = "";
  dataNascita: Date | null = null;
  descrizioneComuneNascita: string = "";
  statoResidenza: string = "";
  regioneResidenza: string = "";
  comuneResidenza: string = "";
  distrettoResidenza: string = "";
  municipioResidenza: string = "";
  aslResidenza: string = "";
  collocazioneSocioAmbientale: string = "";
  titoloDiStudio: string = "";
  attivita: string = "";
  invalidita: string = "";
  familiarePaziente: string = "";
  tutelaGiuridica: string = "";
  attualmenteSottoposto: string = "";
  precedentementeInternato: string = "";
  contattoId: number | undefined = undefined;
}

export interface SearchFilterAnagraficaDTO {
  filter: Object;
  sortColumn: string | undefined |null;
  sortDirection: string| undefined|null;
  ruolo: number | undefined;
  asl: string | undefined;
  limit: number;
  offset: number;
}
