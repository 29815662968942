
<div class="pt-3">
    <div class="table-responsive">
      <div class="list-table table-even">

        <table class="table-lg" demon-cf="tableList" demon-cf-id="anagraficaPrestazioni-1" demon-ev="true" demon-done="3">
          <thead app-thead [theadColumns]="theadColumns" [tableSorter]="tableSorter" (changeOrder)="changeOrder()">
          </thead>

          <tbody>
          <tr *ngIf="pagePresenze.loading"><td  [attr.colspan]="theadColumns.length">
            <div class="spinner-border centered"></div></td></tr>
          <ng-container  *ngFor="let presenzaDto of pagePresenze.content">
            <tr (click)="presenzaClick(presenzaDto.presenza)" class="tr-hover demon-cloned"
                data-demon-tr="6-anagraficaPrestazioni-1-1"
                *ngIf="!pagePresenze.loading && ( getRuolo(OPERATORE_STRUTTURA)||getRuolo(OPERATORE_REGIONE) || presenzaDto.presenza.statoAttuale.id >1 ) ">
              <td class="td-hover" data-label="Alert">
                <div><img *ngIf="alert(presenzaDto.presenza.statoAttuale)" src="assets/icons/warning.png" /></div>
              </td>
              <td class="td-hover" data-label="Id">
                <div>{{presenzaDto.presenza.id}}</div>
              </td>
              <td class="td-hover" data-label="ID">
                <div>{{presenzaDto.presenza.codiceStrutturaOperatoreInserimento}} - {{presenzaDto.presenza.descrizioneStrutturaOperatoreInserimento}} - {{getDescrizioneASL(presenzaDto.presenza.codiceAslOperatoreInserimento)}}</div>
              </td>
              <td class="td-hover" data-label="Data">
                <div>{{contatto.dataApertura | date:'yyyy-MM-dd'}}</div>
              </td>
              <td class="td-hover" data-label="Data">
                <div>{{presenzaDto.presenza.dataPresenza | date:'yyyy-MM-dd'}}</div>
              </td>
              <!-- <td class="td-hover" data-label="Modalita">
                <div>{{getTipoStrutturaLabel(presenzaDto.presenza.tipoSemires!!) }}</div>
              </td> -->
              <td class="td-hover" data-label="Tipo struttura">
                <div>{{getModalitaLabel(presenzaDto.presenza.modPresenza!!)}}</div>
              </td>
              <td class="td-hover" data-label="Numero Prestazioni">
                <div>
                  {{presenzaDto.numeroPrestazioni}}
                </div>
              </td>
              <td class="td-hover" data-label="Stato">
                <div>{{descrizioneStato(presenzaDto.presenza.statoAttuale.id)}}</div>
                <!-- <div>{{contattoDto.statoAttuale}}</div> -->
              </td>
            </tr>
          </ng-container>



          </tbody>
        </table>


      </div>



    </div>

    <app-paginator [page]="pagePresenze" (cambiaPaginaEvent)="cambiaPagina()">
    </app-paginator>



    <div class="btn-toolbar">
      <div class="btn-group-left">
      </div>
      <div class="btn-group-center">
        <!-- aggiungiPresenzaVisibile() && !contattoIsClosed() -->
        <button *ngIf="(!isValidato() || this.contatto.dataChiusura === undefined || this.contatto.dataChiusura === null)  && this.contatto.aperturaFlag === '1' && getRuolo(OPERATORE_STRUTTURA)" type="button" class="btn btn-secondary" demon-bt="prestazioneNuova"
          (click)="openPresenzaForm()">
          Aggiungi Presenza
        </button>
      </div>
      <div class="btn-group-right">
      </div>
    </div>

  </div>
