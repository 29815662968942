
export class TableSorter {
  sorterMap:Map<string, Sorter> = new Map();

  public add(columnName:string){
    this.sorterMap.set(columnName, new Sorter(Sorter.NONE) );
  }

  toggle(columnName: string) {
    this.sorterMap.forEach((value: Sorter, key: string) => {
      if(columnName == key){
        // @ts-ignore
        this.sorterMap.get(columnName).toggle();
      } else {
        value.reset();
      }
    });
  }

  class(columnName: string) {
    // @ts-ignore
    let s:Sorter = this.sorterMap.get(columnName);
    if(s.sort == Sorter.NONE){
      return '';
    } else if(s.sort == Sorter.ASC){
      return 'sortable-up';
    } else {
      return 'sortable-down';
    }
  }

  orderByName() {
    let res = null;
    this.sorterMap.forEach((value: Sorter, key: string) => {
      if(value.sort != Sorter.NONE){
        // @ts-ignore
        res = key;
      }
    });
    return res;
  }


  orderByDirection() {
    let res = null;
    this.sorterMap.forEach((value: Sorter, key: string) => {
      if(value.sort == Sorter.ASC){
        // @ts-ignore
        res = 'ASC';
      } else if(value.sort == Sorter.DESC){
        // @ts-ignore
        res = 'DESC';
      }
    });
    return res;
  }
}


export class Sorter {
  public static ASC = 'asc';
  public static DESC = 'desc';
  public static NONE = '';

  sort = '';

  constructor(sort:string) {
    this.sort = sort;
  }

  reset() {
    this.sort = Sorter.NONE;
  }

  // tslint:disable-next-line:typedef
  toggle() {
    if (this.sort === Sorter.DESC){
      this.sort = Sorter.NONE;
    } else if (this.sort === Sorter.ASC){
      this.sort = Sorter.DESC;
    } else if (this.sort ===  Sorter.NONE) {
      this.sort =  Sorter.ASC;
    }
  }

  // @ts-ignore
  public class() {
    if (this.sort === Sorter.DESC ){
      return 'fas fa-arrow-down';
    } else if (this.sort === Sorter.ASC ){
      return 'fas fa-arrow-up';
    } else if (this.sort === Sorter.NONE) {
      return '';
    }
  }
}
