
export class PaginazioneLogic {
//  private static MASSIMO_NUMERO_PAGINE = 3;
//  private static MASSIMO_SX = 2;
//  private static MASSIMO_DX = 2;

  constructor() { }

  massimoNumeroPagine():number {
    return this.isDesktop()? 10: 3;
  }

  massimoDx():number {
    return this.isDesktop()? 5: 1;
  }
  massimoSx():number {
    return this.isDesktop()? 5: 1;
  }

  isDesktop():boolean {
    return window.outerWidth > 991;
  }


  primaVisibile(pagina: Pagina<any>) {
    return pagina.numeroTotalePagine > 1;
  }

  indietroVisibile(pagina: Pagina<any>) {
    return this.isDesktop() &&
      this.indietro(pagina) != null && this.indietro(pagina)!> 1;
  }

  indietro(pagina: Pagina<any>) {
    if (pagina.numeroPagina == 1) {
      return null;
    } else if (pagina.numeroPagina - this.massimoNumeroPagine() > 1 ) {
      return pagina.numeroPagina - this.massimoNumeroPagine() ;
    } else {
      return 1;
    }
  }

  numeriPagine(pagina: Pagina<any>) {
    let  result: number[] = [];
    let pageNumber = pagina.numeroPagina;
    result.push(pageNumber);

    //aggiunge a destra
    let iDx = 1;
    for ( ;iDx <= this.massimoDx(); iDx++) {
      if (pageNumber + iDx >  pagina.numeroTotalePagine) {
        break;
      } else{
        result.push(pageNumber + iDx);
      }

    }

    let iSx = 1;
    for ( ;iSx <= this.massimoSx(); iSx++) {
      if (pageNumber - iSx < 1 ) {
        break;
      } else{
        result.push( pageNumber - iSx);
      }

    }

    if (result.length < this.massimoNumeroPagine()) {
      for ( ; result.length < this.massimoNumeroPagine(); iDx++) {

        if (pageNumber + iDx > pagina.numeroTotalePagine) {
          break;
        } else {
          result.push( pageNumber + iDx);
        }
      }
    }


    if (result.length < this.massimoNumeroPagine()) {
      for ( ;result.length < this.massimoNumeroPagine(); iSx++) {

        if (pageNumber - iSx < 1 ) {
          break;
        } else{
          result.push( pageNumber - iSx );
        }

      }
    }

    result = result.sort((n1,n2) => n1 - n2);
//    console.log('numeriPagine ' + result);
    return result
  }

  avantiVisibile(pagina: Pagina<any>) {
    return this.isDesktop() &&
      this.avanti(pagina)!=null && this.avanti(pagina)! < this.ultima(pagina);
  }

  avanti(pagina: Pagina<any>) {
    if (pagina.numeroPagina == pagina.numeroTotalePagine) {
      return null;
    } else if (pagina.numeroPagina + this.massimoNumeroPagine() < pagina.numeroTotalePagine ) {
      return pagina.numeroPagina + this.massimoNumeroPagine() ;
    } else {
      return pagina.numeroTotalePagine;
    }
  }

  ultima(pagina: Pagina<any>) {
    return pagina.numeroTotalePagine;
  }
  ultimaVisibile(pagina: Pagina<any>) {
    if (pagina.numeroTotalePagine > 1) {
      return true;
    } else {
      return false;
    }
  }
}

export class Pagina<T> {
  numeroPagina: number = 0;
  dimensionePagina: number= 0;
  numeroTotaleElementi: number= 0;
  numeroTotalePagine: number= 0;
  listaElementi: T[] = [];
}
