export const environment = {
  production: true,
  version: '0.0.1',
  // @ts-ignore
  apiUrl: window["env"]["apiUrl"] || 'http://localhost:9090/sism',
  // @ts-ignore
  keycloak: {
    // @ts-ignore
    issuer: window["env"]["keycloakUrl"] || 'http://195.231.32.67:8081/auth/',
    realm: 'ABRFlow',
    clientId: 'AngularManager',
  },

  dateInsertCheck:true,
  // @ts-ignore
  knowageUrl: window["env"]["knowageUrl"] || "http://195.231.32.67:18080",
  // @ts-ignore
  asurUrl: window["env"]["asurUrl"] || "https://sismapp.opendoor.it/sismasurwebservices",
  // @ts-ignore
  logoutIAM: window["env"]["logoutIAM"] ||'https://qiam.regione.lazio.it:443/oidc/logout'
};
