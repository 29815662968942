import { Component, OnInit } from '@angular/core';
import {TheadColumn} from "../../components/table/thead/thead-column";
import {TableSorter} from "../../components/table/thead/table-sorter";
import {Page, StoricoImportXml} from "../../model/Models";
import {ApiService} from "../../services/api.service";
import {saveAs} from "file-saver";
import {SessionService} from "../../services/session.service";

@Component({
  selector: 'app-importazionedati',
  templateUrl: './importazionedati.component.html',
  styleUrls: ['./importazionedati.component.css']
})
export class ImportazionedatiComponent implements OnInit {
  theadColumns: TheadColumn[];
  tableSorter: TableSorter = new TableSorter();
  pagina: Page<StoricoImportXml> = new Page<StoricoImportXml>();
  pgrouped: Map<any, any>|undefined;
  constructor(private apiService: ApiService, private sessionService: SessionService,) {
    this.theadColumns = this.theadColumnsInit();
    this.tableSorter.toggle('id');

  }

  ngOnInit(): void {
    this.loadStorico();
  }
  private theadColumnsInit(): TheadColumn[] {
    let res: TheadColumn[] = [];
    res.push(new TheadColumn('timestamp', 'Data'));
    res.push(new TheadColumn('utente', 'Utente'));
    res.push(new TheadColumn('struttura', 'Struttura'));
    res.push(new TheadColumn('tipo_file', 'Tipo import'));
    res.push(new TheadColumn('report_scarto', 'File report'));
    res.push(new TheadColumn('nome_file', 'Nome file'));
    res.push(new TheadColumn('id', 'Id'));
    res.push(new TheadColumn('scarto_xsd', 'Errore xsd'));
    res.push(new TheadColumn('caricati', 'Elaborati'));
    res.push(new TheadColumn('errati', 'Errati'));
    res.push(new TheadColumn('totali', 'Totali'));

    return res;
  }

  changeOrder(event: any) {
    this.loadStorico();
  }

  loadStorico(){
    let struttura = this.sessionService.getSelectedStrutturaFromStorage()?.codice;
    this.apiService.getStoricoByStruttura(
      struttura!,
      this.pagina.number,
      this.pagina.size,
      this.tableSorter.orderByName(),
      this.tableSorter.orderByDirection()).subscribe(res => {
      this.pagina = res;
      this.pagina.loading = false;

      this.pgrouped = groupBy(res.content,v => v.timestamp );
console.log(this.pgrouped);
    });

  }
  cambiaPagina(event: any) {
    this.loadStorico();
  }

  downloadXmlImport(id: number, tipo: string, fileName: string){
    this.apiService.downloadXmlimport(id, tipo) .subscribe( {

      next: (data: any) :void => {

        let blob :Blob = new Blob();
        if(tipo=="storico_file"){
          blob  = new Blob([data], {type: 'application/xml'});
        } else {
          blob  = new Blob([data], {type: 'text/csv'});
        }
        saveAs(blob, fileName);

      }, error: (e) :void => {

        alert(JSON.stringify(e));

      }

    });
  }

  protected readonly groupBy = groupBy;

  canDownload(item: any[]) {
    return item.some(value => value.scartoXsd)  || item.some(value => value.errati>0);
  }
}
function groupBy(list: any[], keyGetter: (arg0: any) => any) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}
