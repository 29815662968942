import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-dyn-table',
  templateUrl: './dyn-table.component.html',
  styleUrls: ['./dyn-table.component.css']
})
export class DynTableComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  @Input() dataMessageResults!: any[];
  @Input() title!: string;

  getHeaders() {
    let headers: string[] = [];
    if(this.dataMessageResults) {
      this.dataMessageResults.forEach((value) => {
        Object.keys(value).forEach((key) => {
          if(!headers.find((header) => header == key)){
            headers.push(key)
          }
        })
      })
    }
    return headers;
  }
}
