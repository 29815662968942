import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  DettaglioAnagrafica,
  DiagnosiEScale,
  Page,
  Presenza,
  PrestazioneAudit,
  SedeIntervento,
  StrutturaStr,
  Utente
} from 'src/app/model/Models';

import { ApiService } from '../../services/api.service';
import { Prestazione, Contatto, TipoPrestazione, Operatore, ModalitaErogazione } from '../../model/Models';
import {ModalDismissReasons, NgbDateStruct, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators, ValidationErrors } from '@angular/forms';
import { SessionService } from '../../services/session.service';
import { Router } from "@angular/router";
import { ToastService } from "../../services/toast.service";
import { Funzionalita } from 'src/app/model/Funzionalita';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { dataNonValidaValidator } from '../../util/codicefiscale.validators';
import {CommonsUtil} from "../../util/CommonsUtil";
import {firstValueFrom, forkJoin, Subscription} from "rxjs";

@Component({
  selector: 'app-prestazioni',
  templateUrl: './prestazioni.component.html',
  styleUrls: ['./prestazioni.component.css']
})
export class PrestazioniComponent implements OnInit, OnDestroy{

  minDate: any
  maxDate = { year: 2035, month: 1, day: 1 } //{ year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() }

  dateModel!: NgbDateStruct

  prestazioneForm!: FormGroup

  prestazione!: Prestazione

  formSubmitted = false

  contatto?: Contatto

  listTipoPrestazione: TipoPrestazione[] = []

  listOperatori: Operatore[] = []

  listModalitaErrogazione: ModalitaErogazione[] = []

  listSedeIntervento: SedeIntervento[] = []

  anagrafica!: DettaglioAnagrafica

  closeResult = ''

  progresiveNrOnPrestazione = 0

  listPresenze: Presenza[] = []

  listAltrePrestazioni: Prestazione[] = []

  mainUser!: Utente

  presenzaSelezionata?: Presenza

  listDate: string[] = []

  enableStatiAttuali: Array<number> = [1,2,4,7,9,0];
  @BlockUI()
  blockUI!: NgBlockUI;
  private subscribe1: Subscription | undefined;
  private subscribe2: Subscription| undefined;
  private subscribe3: Subscription | undefined;
  private subscribe4: Subscription | undefined;

  constructor(private apiService: ApiService, private sessionService: SessionService,
    private router: Router, private modalService: NgbModal,
    private toastService: ToastService) {
    this.prestazione = new Prestazione();
    this.mainUser = this.sessionService.getUtenteFromStorage()!!
    this.prestazioneForm = new FormGroup({
      date: new FormControl("", [Validators.required, dataNonValidaValidator()]),
      prestazioneTipo: new FormControl( [Validators.required]),
      sedeIntervento: new FormControl( [Validators.required]),
      prestazioneOperatore1: new FormControl( [Validators.required]),
      prestazioneOperatoreCodice1: new FormControl( [Validators.required, Validators.minLength(4)]),
      prestazioneOperatore2: new FormControl(),
      prestazioneOperatoreCodice2: new FormControl(),
      prestazioneOperatore3: new FormControl(),
      prestazioneOperatoreCodice3: new FormControl(),
    })

//    if (!this.isFormTerritoriale()) {
      this.subscribe2 = this.sessionService.selectedContatto.subscribe(contatto => {
        if (contatto.tipoPrestazione != "Territoriale") {
          this.addPresenzaFormField()
        } else {
          this.addModalitaErogazioneFormField()
        }
      });

  }

  ngOnDestroy(): void {
        this.subscribe1?.unsubscribe();
    this.subscribe2?.unsubscribe();
    this.subscribe3?.unsubscribe();
    this.subscribe4?.unsubscribe();
    }



  async ngOnInit() {
    await this.loadConttatto()
    await this.loadPrestazione();

    this.loadAnagrafica()
    this.initPrestazioneForm()

    this.loadTipiPrestazione()

    this.loadOperatori()

    this.loadAltrePrestazioni()

    if (!this.isFormTerritoriale()) {
      this.loadSedeInterventoSemiresidenziali()
      this.removeModalitErogazioneFormField()
      this.addPresenzaFormField()
    } else {
      this.addModalitaErogazioneFormField()
      this.loadSedeInterventoTerritoriali()
      this.loadModalitaErogazione()
    }

    this.loadNotAvailableDates()
    this.loadPresenze()

    this.observePresenza()
    this.observeStrutturaChange()
    this.observeFormChanges()



    if (!this.isFormTerritoriale() && !this.isPrestazioneNew(this.prestazione)) {
      // let vf = this.prestazione.presenza?.id
      // console.log("id " + vf);
      this.setPresenzaValue(this.prestazione.presenza?.id!!)
    }



    this.limitMaxDate()

    //questo controllo non va fatto
//    this.observeFormForDuplicateDates()
  }



  loadAnagrafica() {
    this.subscribe3 = this.sessionService.selectedAnagrafica.subscribe(res => {
      this.anagrafica = JSON.parse(res)
    })
  }

  async loadConttatto() {
    let res = await firstValueFrom( this.sessionService.selectedContatto);

    if (res != null ) {
      this.contatto = res
      this.setProgressiveNumber(this.contatto!!)
      this.setMinDate(this.contatto!!)

    } else {
      this.leaveEmptyPage()
    }
  }

  async setProgressiveNumber(contatto: Contatto) {
    /**
     * modifica del 15/04/2024 per prendere l'ultimo numero inserimento prestazione da db
     */
    this.progresiveNrOnPrestazione = await firstValueFrom(this.apiService.getLastNrInseritoPrestazione(contatto.id!))
    // if (contatto.prestazioniList != undefined) {
    //   contatto.prestazioniList!!.forEach(pres => {
    //
    //     if (pres.nrInseritoPrestazione!! > this.progresiveNrOnPrestazione
    //         && pres.eliminato==false) {
    //       this.progresiveNrOnPrestazione = pres.nrInseritoPrestazione!!
    //     }
    //
    //   });
    //
    // }
  }

   async  loadPrestazione() {
    let res = await firstValueFrom( this.sessionService.selectedPrestazione);
    if (res != null  ) {
      if("" != res) {
        this.prestazione = JSON.parse(res)
      }
      if (!this.isPrestazioneNew(this.prestazione)) {
        this.dateModel = this.sessionService.convertToDateStruct(new Date(this.prestazione.dataPrestazione!!))!!

      }
    } else {
      this.leaveEmptyPage()
    }

  }

  leaveEmptyPage() {
      this.router.navigate(['ricerca-anagrafica'])
  }

  initPrestazioneForm() {
    if (this.prestazioneForm.contains("prestazioneModalita")) {
       this.prestazioneForm.get('prestazioneModalita')?.patchValue(this.prestazione.modalitaDiErogazione, {emitEvent: false} );
    }
    if (this.prestazioneForm.contains("presenza")) {
       this.prestazioneForm.get('presenza')?.patchValue(this.prestazione.presenza, {emitEvent: false} );
    }

    this.prestazioneForm.get('prestazioneTipo')?.patchValue(this.prestazione.tipo, {emitEvent: false} );
    this.prestazioneForm.get('prestazioneTipo')?.patchValue(this.prestazione.tipo, {emitEvent: false} );
    this.prestazioneForm.get('sedeIntervento')?.patchValue(this.prestazione ? this.prestazione.sede : "", {emitEvent: false} );
    this.prestazioneForm.get('prestazioneOperatore1')?.patchValue(this.prestazione ? this.prestazione.operatore1 : "", {emitEvent: false} );
    this.prestazioneForm.get('prestazioneOperatoreCodice1')?.patchValue(this.prestazione.operatore1Codice, {emitEvent: false} );
    this.prestazioneForm.get('prestazioneOperatore2')?.patchValue(this.prestazione.operatore2, {emitEvent: false} );
    this.prestazioneForm.get('prestazioneOperatoreCodice2')?.patchValue(this.prestazione.operatore2Codice, {emitEvent: false} );
    this.prestazioneForm.get('prestazioneOperatore3')?.patchValue(this.prestazione.operatore3, {emitEvent: false} );
    this.prestazioneForm.get('prestazioneOperatoreCodice3')?.patchValue(this.prestazione.operatore3Codice, {emitEvent: false} );
    //i validatori messi nel costruttore per qualche motivo non funzionano, li aggiungo qui
    this.prestazioneForm.get('date')!!.addValidators([Validators.required, Validators.minLength(5)])
    this.prestazioneForm.get('prestazioneTipo')!!.addValidators([Validators.required])
    this.prestazioneForm.get('sedeIntervento')!!.addValidators([Validators.required])
    this.prestazioneForm.get('prestazioneOperatore1')!!.addValidators([Validators.required])
    this.prestazioneForm.get('prestazioneOperatoreCodice1')!!.addValidators([Validators.required, Validators.minLength(4), Validators.min(1000)])

  }

  addPresenzaFormField() {
    // presenza: new FormControl("",),

    this.prestazioneForm.addControl("presenza", new FormControl("", [Validators.required]))
  }
  validFields(){
    new CommonsUtil().validFields(this.prestazioneForm)
  }



  salva() {
    let prestazione = this.getSubmitedValues()
    if (!this.isFormTerritoriale()) {
      prestazione.presenza = this.presenzaSelezionata
    }
    let struttura = this.sessionService.getSelectedStrutturaFromStorage()
    this.blockUI.start("Salvataggio in corso")
    this.apiService.savePrestazione(prestazione, struttura?.codice!!).subscribe({
      next: (res) => {
        if (res.id != null) {
          this.prestazione = res
          this.sessionService.setSelectedPrestazione(JSON.stringify(this.prestazione))
          this.refreshData()
          this.back()
        }
      },
      error: (e) => {
        console.error(e);
        this.blockUI.stop()
        this.toastService.showError('Attenzione si è verificato un problema tecnico');
      },
      complete: () => {
        this.blockUI.stop()
        this.back()
        this.toastService.showSuccess('Salvataggio effettuato con successo');
      }
    });
  }

/**
 * VEDI RICHIESTA : se per questo contatto ci sono prestazioni analoghe per data, tipo e modalità erogazione (quest ultima solo nel caso TERR)
 * invece di procedere subito al salvataggio si mostra una popup all'operatore, perchè è probabile che si stia sbagliando a
 * compilare il form
 */
  prestazioniAnaloghePresenti() : boolean {
      let res = false;
      if (this.isFormTerritoriale()) {
          this.listAltrePrestazioni.forEach(elem => {
            const d1 = new Date(elem.dataPrestazione! );
            const d2 = this.sessionService.toNativeDate(this.dateModel)!!;

            if(new CommonsUtil().equalsDate(d1, d2)
                  && elem.tipo ==  this.prestazioneForm.controls['prestazioneTipo'].value
                  && elem.modalitaDiErogazione ==  this.prestazioneForm.controls['prestazioneModalita'].value){
                res = true;
            }
          });
      } else {
        this.listAltrePrestazioni.forEach(elem => {
            const d1 = new Date(elem.dataPrestazione! );
            const d2 = this.sessionService.toNativeDate(this.dateModel)!!;
            if(new CommonsUtil().equalsDate(d1, d2)
              && elem.tipo ==  this.prestazioneForm.controls['prestazioneTipo'].value ){
              res = true;
            }
        });
      }
      return res;
  }

  submitForm() {
    this.formSubmitted = true

    if (//this.prestazioneForm.valid
        new CommonsUtil().validFields(this.prestazioneForm) ) {

        if(this.prestazioniAnaloghePresenti()) {
            this.openPrestazioniWarningDialog();

        } else {
          this.salva();
        }
    } else{
      this.toastService.showError('Correggere gli errori di compilazione');
    }
  }




  getSubmitedValues(): Prestazione {

    let prestazione = new Prestazione()

    if (this.prestazione.id != null) {
      prestazione.id = this.prestazione.id
    }

    if (this.prestazioneForm.contains("prestazioneModalita")) {


      prestazione.modalitaDiErogazione = this.prestazioneForm.controls['prestazioneModalita'].value


      this.listModalitaErrogazione.forEach(me => {
        if (prestazione.modalitaDiErogazione === me.codice) {
          prestazione.descrizioneModalitaErogazione = me.descrizione
        }
      })

    }


    prestazione.dataPrestazione = this.sessionService.toNativeDate(this.dateModel)!!

    prestazione.tipo = this.prestazioneForm.controls['prestazioneTipo'].value


    this.listTipoPrestazione.forEach(tipo => {
      if (prestazione.tipo === tipo.codice) {
        prestazione.descrizioneTipo = tipo.descrizione
      }
    })

    prestazione.descrizioneTipo

    if(prestazione.id == null) {
      prestazione.nrInseritoPrestazione = this.progresiveNrOnPrestazione + 1;
    } else {
      prestazione.nrInseritoPrestazione = this.prestazione.nrInseritoPrestazione;
    }


    prestazione.sede = this.prestazioneForm.controls['sedeIntervento'].value



    prestazione.operatore1 = this.prestazioneForm.controls['prestazioneOperatore1'].value
    prestazione.operatore1Codice = this.prestazioneForm.controls['prestazioneOperatoreCodice1'].value
    prestazione.operatore2 = this.prestazioneForm.controls['prestazioneOperatore2'].value
    prestazione.operatore2Codice = this.prestazioneForm.controls['prestazioneOperatoreCodice2'].value
    prestazione.operatore3 = this.prestazioneForm.controls['prestazioneOperatore3'].value
    prestazione.operatore3Codice = this.prestazioneForm.controls['prestazioneOperatoreCodice3'].value


    this.contatto!.anagrafica = this.anagrafica;
    prestazione.contatto = this.contatto

    return prestazione;

  }

  loadTipiPrestazione() {
    if (this.isFormTerritoriale()) {
      this.apiService.getAllTipiPrestazioneTerritoriali().subscribe(res => {
        this.listTipoPrestazione = res
      })
    } else {
      this.apiService.getAllTipiPrestazioneSemiresidenziali().subscribe(res => {
        this.listTipoPrestazione = res
      })
    }
  }

  loadAltrePrestazioni() {
    this.apiService.getPrestazioniByContatto(this.contatto!.id!).subscribe(res => {
     // console.log('res size ' + res.length);
      this.listAltrePrestazioni = res.filter(prestazione => prestazione.id != this.prestazione.id)
      //console.log('size ' + this.listAltrePrestazioni.length);
    })
  }

   loadOperatori() {
    this.apiService.getAllOperatori().subscribe(res => {
      this.listOperatori = res
    })
  }

  loadSedeInterventoTerritoriali() {
    this.apiService.getAllSedeinterventoTerritoriali().subscribe(res => {
      this.listSedeIntervento = res
    })

  }

  loadSedeInterventoSemiresidenziali() {
    this.apiService.getAllSedeinterventoSemiresidenziali().subscribe(res => {
      this.listSedeIntervento = res
    })

  }

  loadModalitaErogazione() {
    this.apiService.getAllModalitaErogazione().subscribe(res => {
      this.listModalitaErrogazione = res
    })
  }

  hasOperatoreValue(value: any): boolean {

    if (value != "" && value != undefined && value != null) {
      return true
    } else {
      return false
    }
  }

  getTitleName(): string {
    if(this.anagrafica) {
      if (this.anagrafica.nome === "") {
        return "Nuova Prestazione"
      } else {
        return "" + this.anagrafica.cognome + " " + this.anagrafica.nome + ' (CF: ' + this.anagrafica.codiceFiscale + ")"
      }
    } else return "";

  }

  addModalitaErogazioneFormField() {
    this.prestazioneForm.addControl("prestazioneModalita", new FormControl(this.prestazione.modalitaDiErogazione, [Validators.required]))
  }

  removeModalitErogazioneFormField() {
    if (this.prestazioneForm.contains("prestazioneModalita")) {
      this.prestazioneForm.removeControl("prestazioneModalita")
    }
  }



  salvaVisibile() {
    let utente = this.sessionService.getUtenteFromStorage();
    return utente?.funzionalita.includes(Funzionalita.ANAGRAFICHE_INSERIMENTO);
  }

  navigateToDashboard() {
    this.router.navigate(['dashboard'])
  }

  navigateToRicercaAnagrafica() {
    this.router.navigate(['ricerca-anagrafica'])
  }

  navigateToAnagrafica() {
    this.router.navigate(['contatto'])
  }

  openGoBackDialog(content: any) {

    if (!this.prestazioneForm.touched) {

      this.back()

    } else {

      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;

        if (this.closeResult === 'Closed with: Back click') {
          this.back()

        }
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;


      });
    }

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  back() {

    this.sessionService.setOpenTabContatto("TabPrestazoni")
    this.router.navigate(['contatto'])
  }

  setMinDate(contatto: Contatto) {
    let dataAperturaContatto = this.sessionService.convertToDateStruct(new Date(contatto.dataApertura!!))
    this.minDate = { year: dataAperturaContatto!!.year, month: dataAperturaContatto!!.month, day: dataAperturaContatto!!.day }

  }

  getDicituraTipoPrestazioneContatto() {
    return this.isFormTerritoriale() ? "Territoriale" : "Semiresidenziale";
  }

  hasWritePermission(utente: Utente): boolean {
    if(!utente){
      return false;
    } else {

    }
    if (utente.funzionalita.includes(Funzionalita.ANAGRAFICHE_INSERIMENTO) ) {
      return true
    } else {
      return false
    }

  }


  setOp2Codice(event: any) {
    if (event.target.value != "" || undefined) {
      this.prestazioneForm.get('prestazioneOperatoreCodice2')!!.setValidators([Validators.required, Validators.minLength(4), Validators.min(1000)])
      this.prestazioneForm.get('prestazioneOperatoreCodice2')!!.updateValueAndValidity({ onlySelf: true })
    } else {
      this.prestazioneForm.get('prestazioneOperatoreCodice2')!!.removeValidators([Validators.required, Validators.minLength(4), Validators.min(1000)])
      this.prestazioneForm.get('prestazioneOperatoreCodice2')!!.updateValueAndValidity({ onlySelf: true })
    }

  }

  setOp3Codice(event: any) {

    if (event.target.value != "" || undefined) {
      this.prestazioneForm.get('prestazioneOperatoreCodice3')!!.setValidators([Validators.required, Validators.minLength(4), Validators.min(1000)])
      this.prestazioneForm.get('prestazioneOperatoreCodice3')!!.updateValueAndValidity({ onlySelf: true })
    } else {
      this.prestazioneForm.get('prestazioneOperatoreCodice3')!!.removeValidators([Validators.required, Validators.minLength(4), Validators.min(1000)])
      this.prestazioneForm.get('prestazioneOperatoreCodice3')!!.updateValueAndValidity({ onlySelf: true })
    }

  }

  cancellaPrestazione() {


    this.modalService.dismissAll()

    this.apiService.cancellaPrestazione(this.prestazione.id!!).subscribe({

      next: (res) => {

        this.toastService.showSuccess("prestazione con id " + res + " cancellata con successo")
        this.refreshData()
        this.back()
      },
      error: (err) => {

        console.error(err);
        this.toastService.showError("e successo un problema durante la cancellazione")
      },
      complete: () => {

        //console.log("completed");

      }

    })
  }

  cancellaVisibile(): Boolean {

    if (this.prestazione.id != null && this.salvaVisibile()) {

      return true
    } else {
      return false
    }

  }

  openCancellaDialog(cancella: any) {
    this.modalService.open(cancella, { ariaLabelledBy: 'modal-basic-title-2' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      if (this.closeResult === 'Closed with: Back click') {
        this.back()
      }
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }


  isPrestazioneNew(prestazione: Prestazione) {

    return prestazione.id == null || prestazione.id == undefined
  }

  isFormTerritoriale(): boolean {
    return this.contatto?.tipoPrestazione == "Territoriale";
//    return this.sessionService.isSelectedStrutturaFromStorageTerritoriale()
  }

  observeStrutturaChange() {
    this.subscribe1 = this.sessionService.strutturaChanged.subscribe(res => {
      if (res) {
        this.router.navigate(['ricerca-anagrafica'])
      }
    })
  }

  loadPresenze() {
    this.subscribe4 = this.sessionService.listPresenze.subscribe(res => {
      res.sort((a, b) => {
        // Parse the date strings to Date objects
        const dateA = new Date(a.dataPresenza!);
        const dateB = new Date(b.dataPresenza!);

        // Compare the dates in descending order
        return dateB.getTime() - dateA.getTime();
      });
      this.listPresenze = res
    })
  }

  observeFormChanges() {
    this.prestazioneForm.valueChanges.forEach(val => {

      if( !this.prestazioneForm.get('prestazioneOperatore2')?.value
        || this.prestazioneForm.get('prestazioneOperatore2')?.value == ''){

        this.prestazioneForm.get('prestazioneOperatoreCodice2')!!.removeValidators([Validators.required,
                                              Validators.minLength(4),Validators.min(1000)])
        this.prestazioneForm.get('prestazioneOperatoreCodice2')!!.updateValueAndValidity({ onlySelf: true })
        this.prestazioneForm.get('prestazioneOperatoreCodice2')?.patchValue(null, {emitEvent: false} );

        this.prestazioneForm.get('prestazioneOperatore3')?.patchValue(null, {emitEvent: false});
        this.prestazioneForm.get('prestazioneOperatoreCodice3')?.patchValue(null, {emitEvent: false});
      } else {
        this.prestazioneForm.get('prestazioneOperatoreCodice2')!!.addValidators([Validators.required,
                        Validators.minLength(4), Validators.min(1000)])
        this.prestazioneForm.get('prestazioneOperatoreCodice2')!!.updateValueAndValidity({ onlySelf: true })

      }

      if( !this.prestazioneForm.get('prestazioneOperatore3')?.value
        || this.prestazioneForm.get('prestazioneOperatore3')?.value == ''){
        this.prestazioneForm.get('prestazioneOperatoreCodice3')?.patchValue(null, {emitEvent: false});
        this.prestazioneForm.get('prestazioneOperatoreCodice3')!!.removeValidators([Validators.required,
                    Validators.minLength(4), Validators.min(1000)])
        this.prestazioneForm.get('prestazioneOperatoreCodice3')!!.updateValueAndValidity({ onlySelf: true })
      } else {
        this.prestazioneForm.get('prestazioneOperatoreCodice3')!!.addValidators([Validators.required,
                    Validators.minLength(4), Validators.min(1000)])
        this.prestazioneForm.get('prestazioneOperatoreCodice3')!!.updateValueAndValidity({ onlySelf: true })
      }

    });
  }


  /**
   * al cambio della presenza selezionata valorizzo la variabile presenzaSelezionata
   * e il campo "date" del form (che nel caso semires è nascosto)  prende in automatico
   * la data della presenza
   */
  observePresenza() {

    this.prestazioneForm.get('presenza')?.valueChanges.subscribe(res => {

      this.listPresenze.forEach(pres => {
        if (pres.id == res) {
          this.presenzaSelezionata = pres
          this.limitMinDateByPresenzaDate(this.presenzaSelezionata)
        }
      })

      if(this.presenzaSelezionata) {
        this.dateModel = this.sessionService.convertToDateStruct(new Date(this.presenzaSelezionata.dataPresenza!))!;
      }
    })
  }

  limitMinDateByPresenzaDate(presenza: Presenza) {

    let presenzaDate = this.sessionService.convertToDateStruct(new Date(presenza.dataPresenza!!))
    this.minDate = { year: presenzaDate!!.year, month: presenzaDate!!.month, day: presenzaDate!!.day }
    this.maxDate = { year: presenzaDate!!.year, month: presenzaDate!!.month, day: presenzaDate!!.day }
  }


  setPresenzaValue(id: number) {
    let presenza = new Presenza()
    this.listPresenze.forEach(pres => {
      if (pres.id == id) {
        presenza = pres
      }
    })


    this.prestazioneForm.get('presenza')?.patchValue(presenza.id)


  }

  refreshData(){

    this.sessionService.setRefreshdata(true)
  }


  limitMaxDate(){

    let todayDate = this.sessionService.convertToDateStruct(new Date());

    if(this.contatto?.dataChiusura){

      let dataChiusuraContatto = this.sessionService.convertToDateStruct(new Date(this.contatto.dataChiusura!!));
      this.maxDate = {year:dataChiusuraContatto?.year!!, month:dataChiusuraContatto?.month!!, day:dataChiusuraContatto?.day!!}

    } else {

      this.maxDate = {year:todayDate?.year!!, month:todayDate?.month!!, day:todayDate?.day!!}

    }

  }

  loadNotAvailableDates(){

    this.sessionService.listPrestazioniDataNotAvailable.subscribe(res => {


      this.listDate = res

    })
  }

  dateExistInDates(date: NgbDateStruct) {


    let exist = this.listDate.indexOf(JSON.stringify(date)) !== -1


    if (exist === true) {



      this.prestazioneForm.get('date')?.setErrors({ dateExist: true })

    } else {


      this.prestazioneForm.get('date')?.setErrors({ dateExist: null })
      this.prestazioneForm.controls['date'].updateValueAndValidity({onlySelf:true, emitEvent:false})

    }
  }


  isFirstOfContatto() {
    if(this.contatto && this.contatto.prestazioniList){
      // @ts-ignore
      if (this.contatto.prestazioniList!!.length === 0) {
        return true
      } else {
        return false
      }
    } return false;
  }


/**
 * METODI e varibili per la dialog di warning prima del salvataggio
 */
  @ViewChild('prestazioniwarning') prestazioniwarningRef?: NgbModalRef;
  dicituraPrestazioniWarning = "";

  openPrestazioniWarningDialog() {
    if (this.isFormTerritoriale()) {
      this.dicituraPrestazioniWarning = "E' stata già inserita una prestazione con stessa data, modalità erogazione e tipologia.";
    } else {
      this.dicituraPrestazioniWarning = "E' stata già inserita una prestazione con stessa data e tipologia";
    }

    this.modalService.open(this.prestazioniwarningRef!, { ariaLabelledBy: 'modal-basic-title-4' }).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
      // if (this.closeResult === 'Closed with: Back click') {
      //   this.back()
      // }
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  getDicituraPrestazioniWarning(){
      return this.dicituraPrestazioniWarning;
  }

  confermaPrestazioniWarningDialog(modal:any){
    modal.close('');
    this.salva()
  }

  mustDisableForm(){

    if(this.prestazione.statoAttuale.id && this.prestazione.statoAttuale.id >= 0 ){

      return this.enableStatiAttuali.indexOf(this.prestazione.statoAttuale.id) == -1

    }

    return false;

 }

  mustDisableCancelBtn() {

    if (this.prestazione.statoAttuale.id && this.prestazione.statoAttuale.id > 0) {

      let statiAttuali: Array<number> = [1, 2, 4, 7, 9];
      return statiAttuali.indexOf(this.prestazione.statoAttuale.id) == -1

    }

    return true;

  }
 public OPERATORE_STRUTTURA: String = "operatore-struttura";
 public OPERATORE_ASL: String = "operatore-asl";
 public OPERATORE_DSM: String = "operatore-dsm";
 public OPERATORE_REGIONE: String = "operatore-regione";
 auditPrestazione: PrestazioneAudit[] = [];
 storicoTitle: any;
 public getRuolo(ruolo: String) {

   if (this.mainUser) {

     if (this.mainUser.nomeRuolo == ruolo) {

       return true;

     }

   }

   return false;

}


openAuditDialog(audit: any){
  this.storicoTitle = "";
  let tipoStruttura = this.sessionService.getSelectedStrutturaFromStorage()?.descrTipoStruttura?.indexOf("TERRITORIALE") != -1 ? 1 : 2;
 this.apiService.getPrestazioneAuditByIdAndStruttura(this.prestazione?.id!!, this.sessionService.getSelectedStrutturaFromStorage()?.codice!, tipoStruttura).subscribe(res => {

   if(res){

     this.auditPrestazione = res;

   }

 });
 this.modalService.open(audit, { ariaLabelledBy: 'modal-basic-title', size: 'xl', scrollable: true}).result.then((result) => {
   this.closeResult = `Closed with: ${result}`;

   if (this.closeResult === 'Closed with: Back click') {
     this.back()

   }
 }, (reason) => {
   this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;


 });
}

isTipoPrestazioneTerritoriale(): boolean {
  if (this.contatto != null) {
    return this.contatto.tipoPrestazione == "Territoriale";
  } else {
    return false;
  }
}
}
