import {AnagraficaStruttura, AnagraficaAudit, ConfStato} from '../../model/Models';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {
  StrutturaStr,
  DettaglioAnagrafica,
  Page,
  Asl,
  StatoEstero,
  Comune,
  CollazioneSocioAmbientale,
  TitoloStudio,
  Ativitta,
  Invalidita,
  TutelaGiuridica,
  Provedimento,
  Cittadinanza,
  Regione,
  StatoCivile, ContattoDto, AnagraficaSessionSettings
} from '../../model/Models';
import {FormControl, FormGroup, Validators, FormBuilder, AbstractControl, ValidationErrors} from '@angular/forms';
import {Router} from "@angular/router";
import {ApiService} from "../../services/api.service";
import {SessionService} from "../../services/session.service";

import {firstValueFrom, Observable, of, OperatorFunction, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, takeUntil} from 'rxjs/operators';
import {NgbDateStruct, NgbTypeahead} from '@ng-bootstrap/ng-bootstrap';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

import {TheadColumn} from 'src/app/components/table/thead/thead-column';
import {TableSorter} from 'src/app/components/table/thead/table-sorter';
import {ToastService} from "../../services/toast.service";
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {Utente, Contatto, Municipio, Distretto} from '../../model/Models';
import {Funzionalita} from "../../model/Funzionalita";
import {
  codiceFiscaleValidator,
  emptyComuneNascitaValidator,
  codiceNonValidoValidator,
  dataNonValidaValidator
} from '../../util/codicefiscale.validators';
import {CommonsUtil} from "../../util/CommonsUtil";
import {RESTORED_VIEW_CONTEXT_NAME} from '@angular/compiler/src/render3/view/util';
import CodiceFiscale from "codice-fiscale-js";


@Component({
  selector: 'app-anagrafica',
  templateUrl: './anagrafica.component.html',
  styleUrls: ['./anagrafica.component.css']
})
export class AnagraficaComponent implements OnInit, OnDestroy {


  minDate = {year: 1920, month: 1, day: 1};
  maxDate = {year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate()}


  dettaglioAnagrafica?: DettaglioAnagrafica | null
  anagraficaStruttura?: AnagraficaStruttura | null;
  dettaglioAnagraficaForm!: FormGroup;


  public modelDescrizioneComuneNascita?: Comune;
  public modelDescrizioneComuneResidenza?: Comune;

  page: Page<ContattoDto> = new Page<ContattoDto>();
  // contattiList!: Contatto[]
  listAsl: Asl[] = []
  listStatiesteri: StatoEstero[] = []
  listStatiCivili: StatoCivile[] = []
  listComune: Comune[] = []
  listComuneResidenza: Comune[] = []
  listDescrizioniComune: Comune[] = []
  listRegione: Regione[] = []
  listCollazioneSocioAmbientali: CollazioneSocioAmbientale[] = []
  listTitoliStudio: TitoloStudio[] = []
  listAtivitta: Ativitta[] = []
  listInvalidita: Invalidita[] = []
  listTuteleGiuridice: TutelaGiuridica[] = []
  listProvedimenti: Provedimento[] = []
  listCitadiNanza: Cittadinanza[] = []
  listMunicipi: Municipio[] = []
  listDistretti: Distretto[] = []
  utente!: Utente
  formTouched?: Observable<Boolean>
  dataNascitaButtonDisable: boolean = false;
  anagraficaExists: boolean = false;
  anagraficaId!: number | null;
  cfCertificato: string = "2";
  isLoading: boolean = false;
  isControllaClick: boolean = false;
  messageDatiCertificati: string = "";
  public OPERATORE_STRUTTURA: String = "operatore-struttura";
  public OPERATORE_ASL: String = "operatore-asl";
  public OPERATORE_DSM: String = "operatore-dsm";
  public OPERATORE_REGIONE: String = "operatore-regione";
  // for Typeahead params
  @ViewChild('instance', {static: true}) instance: NgbTypeahead | undefined;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  _inputCF: string | undefined;
  _inputSCF: string | undefined;

  @Input() set inputCF(v: string) {
    this._inputCF = v;
    if (this._inputCF) this.dettaglioAnagraficaForm.get('anagraficaCF')?.patchValue(this._inputCF);
  }

  @Input() set inputSCF(v: string) {
    this._inputSCF = v;
    if (this._inputSCF) this.dettaglioAnagraficaForm.get('anagraficaSCF')?.patchValue(this._inputSCF);
  }

  @Output() anagraficaOKEvent = new EventEmitter<boolean>();

  formatter = (comune: Comune) => comune.descrizione;

  comuneNascitaSearch: OperatorFunction<string, readonly Comune[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term: string) => term.length < 1 ? []
        : this.listDescrizioniComune.filter(v => v && v.descrizione && v.descrizione.toLowerCase().indexOf(term.toLowerCase()) > -1)
          .sort((a, b) => this.comuneCompare(a.descrizione, b.descrizione, term))
          .slice(0, 10)
      )
    )
  comuneResidenzaSearch: OperatorFunction<string, readonly Comune[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term: string) => term.length < 1 ? []
        : this.listComuneResidenza.filter(v => v && v.descrizione && v.descrizione.toLowerCase().indexOf(term.toLowerCase()) > -1)
          .sort((a, b) => this.comuneCompare(a.descrizione, b.descrizione, term))
          .slice(0, 10)
      )
    )
  public canDelete: boolean = false;
  public deleteWarningMessage: string = '';

  comuneSortingValue(a: string, term: string) {
    return 10000
      * (1 / (a.toLowerCase().indexOf(term.toLowerCase()) + 1))
      * (1 / a.length);
  }

  comuneCompare(a: string, b: string, term: string) {
    return this.comuneSortingValue(b, term) - this.comuneSortingValue(a, term);

  }


  dateModel!: NgbDateStruct;

  date: { year: number; month: number; } | undefined;

  dettaglioAnagraficaFormSubmitted = false;

  tabShowed?: TabShowedState = TabShowedState.ANAGRAFICA;


  theadColumns: TheadColumn[];

  tableSorter: TableSorter = new TableSorter();

  closeResult = '';

  isTabContatti: boolean = false


  allAnagraficaContatti: Contatto[] = []

  listOfAvailableContattoDates: string[] = []

  statiEsteriCodes = ["098", "998", "999"]


  mainUser!: Utente

  listRegioneImigrants = [
    {"codRegione": "098", "regione": "SENZA FISSA DIMORA"},
    {"codRegione": "998", "regione": "RESIDENTE ALL’ESTERO"},
    {"codRegione": "999", "regione": "NON NOTO/NON RISULTA"}]

  listAslImigrants = [{"codiceAzienda": "098", "denominazioneAzienda": "SENZA FISSA DIMORA"},
    {"codiceAzienda": "998", "denominazioneAzienda": "RESIDENTE ALL’ESTERO"}, {
      "codiceAzienda": "999",
      "denominazioneAzienda": "NON NOTO/NON RISULTA"
    }]


  codiceStatoItalia = "IT"
  codiceRegioneLazio = "120"
  codiceComuneRoma = "058091";


  @BlockUI()
  blockUI!: NgBlockUI;
  verificatoConSuccesso: boolean = false;


  constructor(
    private router: Router,
    private toastService: ToastService,
    private apiService: ApiService,
    private fb: FormBuilder,
    private sessionService: SessionService,
    private modalService: NgbModal) {
    this.theadColumns = this.theadColumnsInit();
  }

  private destroy: Subject<any> = new Subject();
  showControllaCfSection: boolean = true;
  confStati: ConfStato[] = [];

  async ngOnInit(): Promise<void> {

    await this.makeDefaultFormCalls();

    const res = await firstValueFrom(this.sessionService.selectedAnagrafica.pipe(takeUntil(this.destroy)));

    //--------
    if (res) {

      this.loadUser();
      this.dettaglioAnagrafica = JSON.parse(res);
      this.dettaglioAnagrafica?.id && this.sessionService.getSelectedStrutturaFromStorage()?.codice ? this.checkIfCanDeleteAnagrafica(this.dettaglioAnagrafica.id, this.sessionService.getSelectedStrutturaFromStorage()?.codice!) : {};
      // 1- CARICA DEFAULT FORM
      await this.loadAnagraficaForm();
      this.emptyFormAnagraficaStruttura();
      if(this.dettaglioAnagrafica?.cfCertificato!="1"){
        this.dettaglioAnagrafica!.regioneResidenza = "";
        this.dettaglioAnagrafica!.comuneResidenza = "";
        this.dettaglioAnagrafica!.aslResidenza = "";
        this.dettaglioAnagrafica!.distrettoResidenza = "";
        this.dettaglioAnagrafica!.municipioResidenza = "";
      } else {
        this.dettaglioAnagrafica!.municipioResidenza = "";
      }
      await this.checkConditionalFields(this.dettaglioAnagrafica!);
      if (this.dettaglioAnagrafica?.id != null) {
        this.isControllaClick = true;
        this.showControllaCfSection = false;
        this.loadContatti(this.dettaglioAnagrafica?.id!!, 0, 10, null, null);
        this.loadHasOpenContattiStatus(this.dettaglioAnagrafica);
        //await this.loadAnagraficaStruttura(this.dettaglioAnagrafica?.id);
      }

      // 2- MAKE ONLY THE DEFAULT CALLS
      this.disableNonEditableAnagraficaForm();

      // CHECK FOR STATE
      if (this.dettaglioAnagrafica?.cfCertificato === "1" || this.anagraficaStruttura == null) {

        await this.checkConditionalFields(this.dettaglioAnagrafica!!);

      }

      if (!this.hasWritePermission(this.mainUser)) {
        this.dettaglioAnagraficaForm.get('cittadinanza')?.disable();
        this.dettaglioAnagraficaForm.get('regioneResidenza')?.disable();
        this.dettaglioAnagraficaForm.get('aslResidenza')?.disable();
        this.dettaglioAnagraficaForm.get('comuneResidenza')?.disable();
        this.dettaglioAnagraficaForm.get('distrettoResidenza')?.disable();
        this.dettaglioAnagraficaForm.get('municipioResidenza')?.disable();
      }
      this.apiService.asurGetAssistito(this.dettaglioAnagrafica?.codiceFiscale).subscribe({

        next: (res: any) => {

          //popolare dati da asur
          if (res.success) {
            this.setResidenza(res);
            this.cfCertificato = "1"

          } else {
            this.cfCertificato = "2"
          }

        }
      });
    } else {

      this.leaveEmptyPage();

    }

    this.loadTab();

    this.setCodiceFiscaleUpperCase();


    this.dettaglioAnagraficaForm.markAsPristine();

    this.observeStrutturaChanged();

    this.apiService.getAllConfStato().subscribe(data => {
      if (data) {
        this.confStati = data;
      }
    })

  }

  loadUser() {

    this.mainUser = this.sessionService.getUtenteFromStorage()!!;

  }


  observeStrutturaChanged() {
    this.sessionService.strutturaChanged.subscribe(res => {

      if (res) {

        this.loadContatti(this.dettaglioAnagrafica?.id!!, this.page.number, this.page.size, this.tableSorter.orderByName(), this.tableSorter.orderByDirection());

      }

    })
  }


  loadHasOpenContattiStatus(anagrafica: DettaglioAnagrafica) {

    this.apiService.getAllAnagraficaContatti(anagrafica.id!!).subscribe(res => {

      if (res) {

        this.allAnagraficaContatti = res;

      }

    })

  }

  async checkConditionalFields(dtana: DettaglioAnagrafica | AnagraficaStruttura) {

    this.getAllRegioni(); //mettere fuori da qui
    await this.getAllStatiEsteri();

    if (!dtana.regioneResidenza) {

      this.removeStatoResidenzaFormField();
      this.removeComuneFormField();
      this.removeAslFormField();
      this.removeDistrettoFormField();
      this.removeMunicipioFormField();

    } else {

      if (dtana.regioneResidenza == '998') { // RESIDENTE ALL’ESTERO

        this.addOrKeepStatoResidenzaFormField();
        this.removeComuneFormField();
        this.removeAslFormField();
        this.removeDistrettoFormField();
        this.removeMunicipioFormField();

      } else if (dtana.regioneResidenza == '098'
        || dtana.regioneResidenza == '999') {
        //SENZA FISSA DIMORA  oppure NON NOTO/NON RISULTA
        this.removeStatoResidenzaFormField();
        this.removeComuneFormField();
        this.removeAslFormField();
        this.removeDistrettoFormField();
        this.removeMunicipioFormField();

      } else {

        this.removeStatoResidenzaFormField();
        this.addOrKeepComuneFormField(dtana);
        if (this.isRegioneResidenzaLazio(dtana.regioneResidenza)) {
          this.addOrKeepAslResidenzaFormField(dtana);
          this.addOrKeepDistrettoResidenzaFormField(dtana);
        } else {
          this.removeAslFormField();
          this.removeDistrettoFormField();
          this.removeMunicipioFormField();

        }

      }

      this.getAllComuniByRegione(dtana.regioneResidenza!!);
      this.getAslbyComune(dtana.comuneResidenza!);
      this.getDistrettobyAsl(dtana.aslResidenza!);

      //  if (this.sessionService.getSelectedStrutturaFromStorage()?.codice == dtana.codiceStrutturaOperatoreInserimento) {
      if (dtana.regioneResidenza == this.codiceRegioneLazio) {

        if (dtana instanceof DettaglioAnagrafica && dtana.cfCertificato === '1') {
          await this.getMunicipiByDistrettoResidenza(dtana!.distrettoResidenza);
        } else {
          await this.getMunicipiByDistrettoResidenza(dtana?.distrettoResidenza!);
        }

      }

      //  }
      // if (this.dettaglioAnagraficaForm.get("regioneResidenza"))
      //   this.dettaglioAnagraficaForm.get('regioneResidenza')?.patchValue(dtana.regioneResidenza);
      //--if (this.dettaglioAnagraficaForm.get("comuneResidenza"))
      //--  this.dettaglioAnagraficaForm.get('comuneResidenza')?.patchValue(dtana.comuneResidenza);
      if (this.dettaglioAnagraficaForm.get("aslResidenza"))
        this.dettaglioAnagraficaForm.get('aslResidenza')?.patchValue(dtana.aslResidenza);
      if (this.dettaglioAnagraficaForm.get("municipioResidenza"))
        this.dettaglioAnagraficaForm.get('municipioResidenza')?.patchValue("");
        // this.dettaglioAnagraficaForm.get('municipioResidenza')?.patchValue(dtana.municipioResidenza);
      if (this.dettaglioAnagraficaForm.get("distrettoResidenza"))
        this.dettaglioAnagraficaForm.get('distrettoResidenza')?.patchValue("");
        // this.dettaglioAnagraficaForm.get('distrettoResidenza')?.patchValue(dtana.distrettoResidenza);

    }

  }

  /*async checkConditionalFieldsNonCert(dettaglioAnagrafica: AnagraficaStruttura) {

    this.getAllRegioni(); //mettere fuori da qui
    this.getAllStatiEsteri();

    if (!dettaglioAnagrafica.regioneResidenza) {

      this.removeStatoResidenzaFormField();
      this.removeComuneFormField();
      this.removeAslFormField();
      this.removeDistrettoFormField();
      this.removeMunicipioFormField();

    } else {

      if (dettaglioAnagrafica!!.regioneResidenza == '998') { // RESIDENTE ALL’ESTERO

        this.addOrKeepStatoResidenzaFormField();
        this.removeComuneFormField();
        this.removeAslFormField();
        this.removeDistrettoFormField();
        this.removeMunicipioFormField();

      } else if (dettaglioAnagrafica!!.regioneResidenza == '098'
        || dettaglioAnagrafica!!.regioneResidenza == '999') {
        //SENZA FISSA DIMORA  oppure NON NOTO/NON RISULTA
        this.removeStatoResidenzaFormField();
        this.removeComuneFormField();
        this.removeAslFormField();
        this.removeDistrettoFormField();
        this.removeMunicipioFormField();

      } else {

        this.removeStatoResidenzaFormField();
        this.addOrKeepComuneFormField();

        if (this.isRegioneResidenzaLazio(dettaglioAnagrafica!!.regioneResidenza!)) {

          this.addOrKeepAslResidenzaFormField();
          this.addOrKeepDistrettoResidenzaFormField();

        } else {

          this.removeAslFormField();
          this.removeDistrettoFormField();
          this.removeMunicipioFormField();

        }

      }

      this.getAllComuniByRegione(dettaglioAnagrafica?.regioneResidenza!!);
      this.getAslbyComune(dettaglioAnagrafica!.comuneResidenza!);
      this.getDistrettobyAsl(dettaglioAnagrafica!.aslResidenza!);

      if (this.sessionService.getSelectedStrutturaFromStorage()?.codice == dettaglioAnagrafica?.codiceStrutturaOperatoreInserimento) {
        if( dettaglioAnagrafica!!.regioneResidenza == this.codiceRegioneLazio){

            this.getMunicipiByDistrettoResidenza(dettaglioAnagrafica.distrettoResidenza!);

      }

      }
       // if (this.dettaglioAnagraficaForm.get("comuneResidenza"))
       //   this.dettaglioAnagraficaForm.get('comuneResidenza')?.patchValue(dettaglioAnagrafica!!.comuneResidenza);
      if (this.dettaglioAnagraficaForm.get("aslResidenza"))
        this.dettaglioAnagraficaForm.get('aslResidenza')?.patchValue(dettaglioAnagrafica!!.aslResidenza);
      if (this.dettaglioAnagraficaForm.get("municipioResidenza"))
        this.dettaglioAnagraficaForm.get('municipioResidenza')?.patchValue(dettaglioAnagrafica!!.municipioResidenza);
      if (this.dettaglioAnagraficaForm.get("distrettoResidenza"))
        this.dettaglioAnagraficaForm.get('distrettoResidenza')?.patchValue(dettaglioAnagrafica!!.distrettoResidenza);

    }

  }
*/


  isRegioneStatoEstero(codice: string): boolean {

    if (this.statiEsteriCodes.includes(codice)) {
      // this.modelDescrizioneComuneNascita!!.codice = "999"
      return true;

    } else {

      return false;

    }

  }

  get anagraficaSCF() {
    return this.dettaglioAnagraficaForm.get('anagraficaSCF');
  }

  get anagraficaCF() {
    return this.dettaglioAnagraficaForm.get('anagraficaCF');
  }

  async loadAnagraficaForm() {

    if (this.dettaglioAnagrafica?.dataNascita == null) {
      // this.dateModel = this.sessionService.convertToDateStruct(new Date())!!
    } else {
      this.dateModel = this.sessionService.convertToDateStruct(new Date(this.dettaglioAnagrafica?.dataNascita!!))!!;
    }

    this.modelDescrizioneComuneNascita = new Comune();
    this.modelDescrizioneComuneNascita.codice = this.dettaglioAnagrafica!.codiceComuneNascita;
    this.modelDescrizioneComuneNascita.descrizione = this.dettaglioAnagrafica!.descrizioneComuneNascita;
//    if (this.dettaglioAnagrafica?.cfCertificato === "1") {
    //this.apiService.getAllComuni().subscribe((res) => {
    this.modelDescrizioneComuneResidenza = new Comune();
    this.modelDescrizioneComuneResidenza.codice = this.dettaglioAnagrafica!.comuneResidenza;
    let comuneRes = this.listComune.find(x => x.codice == this.dettaglioAnagrafica!.comuneResidenza);
    this.modelDescrizioneComuneResidenza.descrizione = comuneRes?.descrizione!;
    //})

    //  }


    this.dettaglioAnagraficaForm = this.fb.group({
        anagraficaCF: new FormControl(),
        anagraficaSCF: new FormControl(),
        cognome: new FormControl(this.dettaglioAnagrafica ? this.dettaglioAnagrafica?.cognome : "", [Validators.required]),
        nome: new FormControl(this.dettaglioAnagrafica ? this.dettaglioAnagrafica?.nome : "", [Validators.required]),
        codiceFiscale: new FormControl(this.dettaglioAnagrafica ? this.dettaglioAnagrafica?.codiceFiscale : "",
          [Validators.required]),
        sesso: new FormControl(this.dettaglioAnagrafica ? this.dettaglioAnagrafica?.sesso : "", [Validators.required]),
        dataNascita: new FormControl(this.dateModel, [Validators.required, dataNonValidaValidator()]),
        descrizioneComuneNascita: ["", [Validators.required, emptyComuneNascitaValidator()]],
        statoCivile: new FormControl(this.dettaglioAnagrafica ? this.dettaglioAnagrafica?.statoCivile : "", [Validators.required]),
        cittadinanza: new FormControl((this.dettaglioAnagrafica && this.dettaglioAnagrafica.cfCertificato === '1') ? this.dettaglioAnagrafica?.cittadinanza : "", [Validators.required]),
        statoResidenza: new FormControl((this.dettaglioAnagrafica && this.dettaglioAnagrafica.cfCertificato === "1") ? this.dettaglioAnagrafica?.statoResidenza : "", [Validators.required]),
        regioneResidenza: new FormControl((this.dettaglioAnagrafica && this.dettaglioAnagrafica.cfCertificato === '1') ? this.dettaglioAnagrafica?.regioneResidenza : "", [Validators.required]),
        comuneResidenza: ["", [Validators.required, emptyComuneNascitaValidator()]],
        //comuneResidenza: new FormControl(this.dettaglioAnagrafica?.comuneResidenza ? this.dettaglioAnagrafica?.comuneResidenza : "", [Validators.required]),
        aslResidenza: new FormControl((this.dettaglioAnagrafica && this.dettaglioAnagrafica.cfCertificato === "1") ? this.dettaglioAnagrafica?.aslResidenza : "", [Validators.required]),
        collocazioneSocioAmbientale: new FormControl(this.dettaglioAnagrafica ? this.dettaglioAnagrafica?.collocazioneSocioAmbientale : "", [Validators.required]),
        titoloDiStudio: new FormControl(this.dettaglioAnagrafica ? this.dettaglioAnagrafica?.titoloDiStudio : "", [Validators.required]),
        attivita: new FormControl(this.dettaglioAnagrafica ? this.dettaglioAnagrafica?.attivita : "", [Validators.required]),
        invalidita: new FormControl(this.dettaglioAnagrafica ? this.dettaglioAnagrafica?.invalidita : "", [Validators.required]),
        familiarePaziente: new FormControl(this.dettaglioAnagrafica ? this.dettaglioAnagrafica?.familiarePaziente : "", [Validators.required]),
        tutelaGiuridica: new FormControl(this.dettaglioAnagrafica ? this.dettaglioAnagrafica?.tutelaGiuridica : "", [Validators.required]),
        attualmenteSottoposto: new FormControl(this.dettaglioAnagrafica ? this.dettaglioAnagrafica?.attualmenteSottoposto : "", [Validators.required]),
        precedentementeInternato: new FormControl(this.dettaglioAnagrafica ? this.dettaglioAnagrafica?.precedentementeInternato : "", [Validators.required]),
      }, {validator: codiceFiscaleValidator}
    );
    //FIXME CABLATURA CON CODICE REGIONE LAZIO
    if (this.dettaglioAnagrafica?.regioneResidenza != this.codiceRegioneLazio) {

      this.dettaglioAnagraficaForm.removeControl('aslResidenza');
      this.dettaglioAnagraficaForm.removeControl('distrettoResidenza');
      this.dettaglioAnagraficaForm.removeControl('municipioResidenza');

    }

  }

  setState(event: any) {

    this.dettaglioAnagrafica!!.statoResidenza = event.target.value;
    if (this.dettaglioAnagrafica?.cfCertificato == "1" || this.anagraficaStruttura == null) {

      this.checkConditionalFields(this.dettaglioAnagrafica!!);

    } else {

      //this.checkConditionalFieldsNonCert(this.anagraficaStruttura!! )
      this.checkConditionalFields(this.anagraficaStruttura!!).then();

    }

  }

  setAslResidenza(event: any) {


    if (this.dettaglioAnagrafica?.cfCertificato == "1" || this.anagraficaStruttura == null) {
      this.dettaglioAnagrafica!!.aslResidenza = event.target.value;
      this.dettaglioAnagrafica!!.municipioResidenza = '';
      this.dettaglioAnagrafica!!.distrettoResidenza = '';
      this.checkConditionalFields(this.dettaglioAnagrafica!!);

    } else {
      this.anagraficaStruttura!!.aslResidenza = event.target.value;
      this.anagraficaStruttura!!.municipioResidenza = '';
      this.anagraficaStruttura!!.distrettoResidenza = '';
      //this.checkConditionalFieldsNonCert(this.anagraficaStruttura!!)
      this.checkConditionalFields(this.anagraficaStruttura!!).then();

    }
  }

  async setDistrettoResidenza(event: any) {


      this.dettaglioAnagrafica!!.distrettoResidenza = event.target.value;
      this.dettaglioAnagrafica!!.municipioResidenza = '';


    await this.getMunicipiByDistrettoResidenza(event.target.value)
  }

  setComuneCodice(event: any) {

    //    this.dettaglioAnagraficaForm.get('aslResidenza')?.patchValue('');
    if (this.dettaglioAnagrafica?.cfCertificato == "1" || this.anagraficaStruttura == null) {
      this.dettaglioAnagrafica!!.comuneResidenza = event.item.codice!;
      // this.dettaglioAnagrafica!!.comuneResidenza = event.target.value;
      this.dettaglioAnagrafica!!.aslResidenza = '';
      this.dettaglioAnagrafica!!.municipioResidenza = '';
      this.dettaglioAnagrafica!!.distrettoResidenza = '';
      this.checkConditionalFields(this.dettaglioAnagrafica!!);
    } else {
      this.anagraficaStruttura!!.comuneResidenza = event.item.codice!;
      // this.anagraficaStruttura!!.comuneResidenza = event.target.value;
      this.anagraficaStruttura!!.aslResidenza = '';
      this.anagraficaStruttura!!.municipioResidenza = '';
      this.anagraficaStruttura!!.distrettoResidenza = '';
      //this.checkConditionalFieldsNonCert(this.anagraficaStruttura!!)
      this.checkConditionalFields(this.anagraficaStruttura!!).then();

    }
  }

  setRegioneCodice(event: any) {

    if (this.dettaglioAnagrafica == null) {
      this.dettaglioAnagrafica = new DettaglioAnagrafica();
    }
    this.dettaglioAnagrafica.regioneResidenza = event.target.value;
    this.dettaglioAnagrafica.comuneResidenza = '';
    this.modelDescrizioneComuneResidenza = undefined;
    this.dettaglioAnagrafica.aslResidenza = '';
    this.dettaglioAnagrafica.distrettoResidenza = '';
    this.dettaglioAnagrafica.municipioResidenza = '';
    if (this.dettaglioAnagrafica?.cfCertificato == "1" || this.anagraficaStruttura == null) {
      this.checkConditionalFields(this.dettaglioAnagrafica!!);
    } else {
      this.anagraficaStruttura!.regioneResidenza = event.target.value;
      this.anagraficaStruttura.comuneResidenza = '';
      this.modelDescrizioneComuneResidenza = undefined;
      this.anagraficaStruttura.aslResidenza = '';
      this.anagraficaStruttura.distrettoResidenza = '';
      this.anagraficaStruttura.municipioResidenza = '';
      //this.checkConditionalFieldsNonCert(this.anagraficaStruttura!!)
      this.checkConditionalFields(this.anagraficaStruttura!!).then();

    }
  }

  isCitadiNanzaItalia(codice: string) {

    if (codice === this.codiceStatoItalia) {

      return true;

    } else {

      return false;

    }

  }

  isStatoItalia(codice: string): boolean {

    if (codice === this.codiceStatoItalia) {

      return true;

    } else {

      return false;

    }

  }


  isComuneCodiceRoma(codice: string): boolean {

    if (codice === this.codiceComuneRoma) {

      return true;

    } else {

      return false;

    }

  }

  isRegioneResidenzaLazio(codice: string): boolean {

    if (codice == this.codiceRegioneLazio) {

      return true;

    } else {

      return false;

    }

  }

  isMunicipioResidenzaPresente() {

    if (this.dettaglioAnagraficaForm.get("municipioResidenza")) {

      return true;

    } else {

      return false;

    }

  }

  isDistrettoResidenzaPresente() {

    if (this.dettaglioAnagraficaForm.get("distrettoResidenza")) {

      return true;

    } else {

      return false;

    }

  }

  isStatoResidenzaPresente() {

    if (this.dettaglioAnagraficaForm.get("statoResidenza")) {

      return true;

    } else {

      return false;

    }

  }

  isComuneResidenzaPresente() {

    if (this.dettaglioAnagraficaForm.get("comuneResidenza")) {

      return true;

    } else {

      return false;

    }

  }

  isAslResidenzaPresente() {

    if (this.dettaglioAnagraficaForm.get("aslResidenza")) {

      return true;

    } else {

      return false;

    }

  }

  addOrKeepMunicipioResidenzaFormField() {

    if (!this.dettaglioAnagraficaForm.get("municipioResidenza")) {

      this.dettaglioAnagraficaForm.addControl("municipioResidenza",
        new FormControl(this.anagraficaStruttura ? this.anagraficaStruttura?.municipioResidenza :
          "", [Validators.required]));
      // this.dettaglioAnagraficaForm.addControl("municipioResidenza",
      //   new FormControl(this.dettaglioAnagrafica ? this.dettaglioAnagrafica?.municipioResidenza :
      //     "", [Validators.required]));

    }

  }

  addOrKeepStatoResidenzaFormField() {

    if (!this.dettaglioAnagraficaForm.get("statoResidenza")) {

      this.dettaglioAnagraficaForm.addControl("statoResidenza",
        new FormControl(this.anagraficaStruttura ? this.anagraficaStruttura?.statoResidenza :
          "", [Validators.required]));
      // this.dettaglioAnagraficaForm.addControl("statoResidenza",
      //   new FormControl(this.dettaglioAnagrafica ? this.dettaglioAnagrafica?.statoResidenza :
      //     "", [Validators.required]));

    }

  }

  addOrKeepComuneFormField(dettaglioAnagrafica: DettaglioAnagrafica | AnagraficaStruttura) {

    if (!this.dettaglioAnagraficaForm.get("comuneResidenza")) {

      this.dettaglioAnagraficaForm.addControl("comuneResidenza",
        new FormControl(dettaglioAnagrafica ? dettaglioAnagrafica?.comuneResidenza : "", [Validators.required]));
    }

  }

  addOrKeepAslResidenzaFormField(dtana: DettaglioAnagrafica | AnagraficaStruttura) {

    if (!this.dettaglioAnagraficaForm.get("aslResidenza")) {

      this.dettaglioAnagraficaForm.addControl("aslResidenza",
        new FormControl(dtana ? dtana?.aslResidenza : "",
          [Validators.required]));

    }

  }

  addOrKeepDistrettoResidenzaFormField(dtana: DettaglioAnagrafica | AnagraficaStruttura) {

    if (!this.dettaglioAnagraficaForm.get("distrettoResidenza")) {

      this.dettaglioAnagraficaForm.addControl("distrettoResidenza",
        new FormControl(dtana ? dtana?.distrettoResidenza : "",
          [Validators.required]));
    }

  }

  removeAslFormField() {

    if (this.dettaglioAnagraficaForm.contains("aslResidenza")) {

      this.dettaglioAnagraficaForm.removeControl("aslResidenza");

    }

    this.dettaglioAnagrafica!.aslResidenza = '';

  }

  removeComuneFormField() {

    if (this.dettaglioAnagraficaForm.contains("comuneResidenza")) {

      this.dettaglioAnagraficaForm.removeControl("comuneResidenza");

    }

    this.dettaglioAnagrafica!.comuneResidenza = '';

  }

  removeStatoResidenzaFormField() {

    if (this.dettaglioAnagraficaForm.contains("statoResidenza")) {

      this.dettaglioAnagraficaForm.removeControl("statoResidenza");

    }

    this.dettaglioAnagrafica!.statoResidenza = '';

  }

  addDistrettoFormField() {

    this.dettaglioAnagraficaForm.addControl("distrettoResidenza", new FormControl(this.dettaglioAnagrafica ? this.dettaglioAnagrafica?.distrettoResidenza : "", [Validators.required]));
    this.getAllDistretti();

  }

  removeDistrettoFormField() {

    if (this.dettaglioAnagraficaForm.contains("distrettoResidenza")) {

      this.dettaglioAnagraficaForm.removeControl("distrettoResidenza");

    }

    this.dettaglioAnagrafica!.distrettoResidenza = '';

  }

  // addMunicipioFormField() {
  //   this.dettaglioAnagraficaForm.addControl("municipioResidenza", new FormControl(this.dettaglioAnagrafica ? this.dettaglioAnagrafica?.municipioResidenza : "", [Validators.required]))
  //   this.getAllMunicipi()
  // }

  removeMunicipioFormField() {
    if (this.dettaglioAnagraficaForm.contains("municipioResidenza")) {
      this.dettaglioAnagraficaForm.removeControl("municipioResidenza")
    }
    if(this.dettaglioAnagrafica)this.dettaglioAnagrafica!.municipioResidenza = ''

  }

  async makeDefaultFormCalls() {

    await this.getAllComuni()
    await this.getAllStatiCivili()
    await this.getAllCitadinanza()
    await this.getAllStatiEsteri()
    // this.getAllRegioni()
    // this.getAllAsl()
    await this.getAllCollazioneSocioAmbientali()
    await this.getAllTitoliStudio()
    await this.getAllAtivitta()
    await this.getAllInvalidita()
    await this.getAllTuteleGiuridice()
    await this.getAllProvedimenti()

  }

  // loadStatoEsteroOptionsForRegioniAsl() {
  //   this.listAsl = this.listAslImigrants
  //   this.listRegione = this.listRegioneImigrants
  // }

  async checkFormHasError() {
    let keys = Object.keys(this.dettaglioAnagraficaForm.controls)
    try {
      keys.forEach(key => {
        let controlErrors: ValidationErrors = this.dettaglioAnagraficaForm.get(key)!.errors!;
        if (controlErrors != null) {
          this.dettaglioAnagraficaForm.get(key)?.markAsDirty();
          throw "Error"
        }
      });
    } catch (err) {
      return true
    }
    return false
  }
  async saveAnagrafica(fromcontatto:boolean|undefined=false) {
    this.dettaglioAnagraficaFormSubmitted = true;

    this.blockUI.start("Salvataggio in corso")
    //this.dettaglioAnagraficaForm.markAllAsTouched();
    const isvalid = this.dettaglioAnagraficaForm.valid;//await this.checkFormHasError()
    if (isvalid) {

    let updateAnagrafica: boolean = false;
    let codiceASL = this.sessionService.getUtenteFromStorage()?.codiceAsl
    let codiceStruttura = this.sessionService.getSelectedStrutturaFromStorage()?.codice;
    //let cf = this.dettaglioAnagraficaForm.get('anagraficaCF')?.value?this.dettaglioAnagraficaForm.get('anagraficaCF')?.value:this.dettaglioAnagraficaForm.get('anagraficaSCF')?.value;
    let cf = this.dettaglioAnagraficaForm.get('codiceFiscale')?.value;
    this.pazienteElement = await firstValueFrom(this.apiService.asurGetAssistito(cf));
    // this.apiService.asurGetAssistito(cf).subscribe({
    //
    //   next: (res: any) => {
    //
    //     //popolare dati da asur
    //     if (res.success) {
    //       this.pazienteElement = res;
    //       this.cfCertificato = "1"
    //
    //     } else {
    //       this.cfCertificato = "2"
    //     }
    //
    //   }
    // });
    const res = await firstValueFrom(this.apiService.getAnagraficaByCf(cf));

    if (res && res.id != null) {

      updateAnagrafica = true;
      this.dettaglioAnagrafica = res;
      //aggiornare anagrafica con cfcertificato = 1 se l'anagrafica compare in ASUR


      //COMMIT: Try 2 fix anagrafica page
      if (this.cfCertificato === '1' || (this.dettaglioAnagrafica!.cfCertificato != null && this.dettaglioAnagrafica!.cfCertificato == "1")) {
        this.dettaglioAnagrafica.descrizioneComuneNascita = this.pazienteElement.data.paziente[0].comuneNascita._value_1;
        if(this.pazienteElement.data.paziente[0].nazioneNascita != "100"){
          if(this.pazienteElement.data.paziente[0].comuneNascita.codice.endsWith("999")){
            let prefix: string = this.pazienteElement.data.paziente[0].comuneNascita.codice.substring(0, this.pazienteElement.data.paziente[0].comuneNascita.codice.length - 3);
            // Estrai gli ultimi tre "9" e mettili come primi tre caratteri
            let invertedString: string = this.pazienteElement.data.paziente[0].comuneNascita.codice.slice(-3) + prefix;
            this.modelDescrizioneComuneNascita!.codice = invertedString;
          } else {

            this.modelDescrizioneComuneNascita!.codice = this.pazienteElement.data.paziente[0].comuneNascita.codice
          }
        }
        this.setResidenza(this.pazienteElement);
        this.dettaglioAnagrafica!.cfCertificato = "1";

        const res2 = await firstValueFrom(this.apiService.saveDettaglioAnagrafica(this.dettaglioAnagrafica!, codiceStruttura!!));
        if (res2.id != null) {
          console.log("salvatagio riuscito");
          this.dettaglioAnagrafica = res2
          this.anagraficaOKEvent.emit(true);

          // this.toastService.showSuccess('Salvataggio effettuato con successo');
          //this.sessionService.setSelectedAnagrafica(JSON.stringify(this.dettaglioAnagrafica));
        } else {
          this.blockUI.stop()
          this.dettaglioAnagraficaFormSubmitted = false
          this.anagraficaOKEvent.emit(false);

          this.toastService.showError('Si è verificato un errore in fase di inserimento');
        }
      } else {
        /**
         * @author mattia.ripanucci
         * @date 22/03/2023
         *
         * CONTROLLO SE IL CF NON è CERTIFICATO E VBENGONO MODIFICATI I CAMPI RELATIVI
         * ALLA RESIDENZA IN QUEL CASO AGGIORNO ANCHE SU ANAGRAFICA
         */


        if (this.dettaglioAnagraficaForm.contains("cittadinanza")) {

          if (this.dettaglioAnagrafica.cittadinanza != this.dettaglioAnagraficaForm.get('cittadinanza')?.value) {

            updateAnagrafica = true;

          }

        }

        if (this.dettaglioAnagraficaForm.contains("statoResidenza")) {

          if (this.dettaglioAnagrafica.statoResidenza != this.dettaglioAnagraficaForm.get('statoResidenza')?.value) {

            updateAnagrafica = true;

          }

        }

        if (this.dettaglioAnagraficaForm.contains("regioneResidenza")) {

          if (this.dettaglioAnagrafica.regioneResidenza != this.dettaglioAnagraficaForm.get('regioneResidenza')?.value) {

            updateAnagrafica = true;

          }
        }

        if (this.dettaglioAnagraficaForm.contains("comuneResidenza")) {

          if (this.dettaglioAnagrafica.comuneResidenza != this.dettaglioAnagraficaForm.get('comuneResidenza')?.value) {

            updateAnagrafica = true;

          }

        }


        if (this.dettaglioAnagraficaForm.contains("distrettoResidenza")) {

          if (this.dettaglioAnagrafica.distrettoResidenza != this.dettaglioAnagraficaForm.get('distrettoResidenza')?.value) {

            updateAnagrafica = true;

          }

        }

        if (updateAnagrafica) {
          if (this.dettaglioAnagraficaForm.valid) {
            const res3 = await firstValueFrom(this.apiService.saveDettaglioAnagrafica(this.getInsertedValues(), codiceStruttura!!));
            if (res3.id != null) {

              console.log("salvatagio anagrafica in caso di cambio residenza e cf non certificato riuscito");
              this.dettaglioAnagrafica = res3
              this.anagraficaOKEvent.emit(true);

              this.toastService.showSuccess('Per completare il salvataggio delle informazioni della tabella anagrafica è necessario inserire un nuovo contatto');
              this.sessionService.setSelectedAnagrafica(JSON.stringify(this.dettaglioAnagrafica));
              this.blockUI.stop()


            } else {
              this.blockUI.stop()
              this.dettaglioAnagraficaFormSubmitted = false
              this.anagraficaOKEvent.emit(false);

              this.toastService.showError('Si è verificato un errore in fase di inserimento');
            }

          } else {
            this.toastService.showError('Correggere gli errori di compilazione');
          }
        }
        /**
         * fine
         * @author mattia.ripanucci
         * @date 22/03/2023
         *
         * CONTROLLO SE IL CF NON è CERTIFICATO E VBENGONO MODIFICATI I CAMPI RELATIVI
         * ALLA RESIDENZA IN QUEL CASO AGGIORNO ANCHE SU ANAGRAFICA
         */
      }
      if (this.dettaglioAnagraficaForm.valid) {

        this.anagraficaStruttura = this.getInsertedAnagraficaStruttura();
        await this.saveAnagraficaStruttura(this.anagraficaStruttura);
        this.anagraficaOKEvent.emit(true);

        this.sessionService.setSelectedAnagrafica(JSON.stringify(this.dettaglioAnagrafica));
        if (!fromcontatto) {
          this.sessionService.clearSelectedContatto();
          this.sessionService.setSelectedContatto(new Contatto());
          this.toastService.showSuccess('Per completare il salvataggio delle informazioni della tabella anagrafica è necessario inserire un nuovo contatto');

          this.router.navigate(['contatto'])
        } else {
          this.toastService.showSuccess('Per completare il salvataggio delle informazioni della tabella anagrafica è necessario inserire un nuovo contatto');
        }
      } else {

        this.toastService.showError('Correggere gli errori di compilazione');
        this.anagraficaOKEvent.emit(false);


      }

    } else {

      if (this.dettaglioAnagraficaForm.valid) {

        this.dettaglioAnagrafica = this.getInsertedValues();
        this.dettaglioAnagrafica.codiceAslOperatoreInserimento = codiceASL!;
        this.dettaglioAnagrafica.cfCertificato = this.cfCertificato;
        const res4 = await firstValueFrom(this.apiService.saveDettaglioAnagrafica(this.dettaglioAnagrafica, codiceStruttura!!));
        if (res4.id != null) {
          this.dettaglioAnagrafica = res4
          this.anagraficaStruttura = this.getInsertedAnagraficaStruttura();
          this.saveAnagraficaStruttura(this.anagraficaStruttura);
          this.anagraficaOKEvent.emit(true);
          this.blockUI.stop()

          this.sessionService.setSelectedAnagrafica(JSON.stringify(this.dettaglioAnagrafica));
          if (!fromcontatto) {
            this.sessionService.clearSelectedContatto();
            this.sessionService.setSelectedContatto(new Contatto());
            this.toastService.showSuccess('Per completare il salvataggio delle informazioni della tabella anagrafica è necessario inserire un nuovo contatto');
            this.router.navigate(['contatto'])
          } else {
            this.toastService.showSuccess('Per completare il salvataggio delle informazioni della tabella anagrafica è necessario inserire un nuovo contatto');
          }

        } else {
          this.dettaglioAnagraficaFormSubmitted = false
          this.anagraficaOKEvent.emit(false);
          this.toastService.showError('Si è verificato un errore in fase di inserimento');
        }

      } else {

        this.toastService.showError('Correggere gli errori di compilazione');
        this.anagraficaOKEvent.emit(false);

      }

    }
  } else {
  this.toastService.showError('Correggere gli errori di compilazione');
  this.anagraficaOKEvent.emit(false);
}
    this.blockUI.stop()

  }

  getInsertedAnagraficaStruttura(): AnagraficaStruttura {

    let anagraficaStruttura = new AnagraficaStruttura();
    anagraficaStruttura.anagraficaId = this.dettaglioAnagrafica?.id!;
    let struttura = this.sessionService.getSelectedStrutturaFromStorage();
    anagraficaStruttura.codiceStrutturaOperatoreInserimento = struttura?.codice!;
    anagraficaStruttura.codiceAslOperatoreInserimento = this.utente.codiceAsl;
    anagraficaStruttura.statoCivile = this.dettaglioAnagraficaForm.controls['statoCivile'].value;
    anagraficaStruttura.collocazioneSocioAmbientale = this.dettaglioAnagraficaForm.controls['collocazioneSocioAmbientale'].value
    anagraficaStruttura.titoloDiStudio = this.dettaglioAnagraficaForm.controls['titoloDiStudio'].value
    anagraficaStruttura.attivita = this.dettaglioAnagraficaForm.controls['attivita'].value
    anagraficaStruttura.invalidita = this.dettaglioAnagraficaForm.controls['invalidita'].value
    anagraficaStruttura.familiarePaziente = this.dettaglioAnagraficaForm.controls['familiarePaziente'].value
    anagraficaStruttura.tutelaGiuridica = this.dettaglioAnagraficaForm.controls['tutelaGiuridica'].value
    anagraficaStruttura.attualmenteSottoposto = this.dettaglioAnagraficaForm.controls['attualmenteSottoposto'].value
    anagraficaStruttura.precedentementeInternato = this.dettaglioAnagraficaForm.controls['precedentementeInternato'].value
    anagraficaStruttura.cittadinanza = this.dettaglioAnagraficaForm.controls['cittadinanza'].value
    anagraficaStruttura.regioneResidenza = this.dettaglioAnagraficaForm.controls['regioneResidenza'].value

    if (this.dettaglioAnagraficaForm.contains("comuneResidenza") || (this.dettaglioAnagraficaForm.get("comuneResidenza") != null && this.dettaglioAnagraficaForm.get("comuneResidenza")!.status == 'DISABLED')) {
      //anagraficaStruttura.comuneResidenza = this.dettaglioAnagraficaForm.controls['comuneResidenza'].value
      if (this.modelDescrizioneComuneResidenza) {
        anagraficaStruttura.comuneResidenza = this.modelDescrizioneComuneResidenza.codice;
      }
    }
    if (this.dettaglioAnagraficaForm.contains("aslResidenza") || (this.dettaglioAnagraficaForm.get("aslResidenza") != null && this.dettaglioAnagraficaForm.get("aslResidenza")!.status == 'DISABLED')) {

      anagraficaStruttura.aslResidenza = this.dettaglioAnagraficaForm.controls['aslResidenza'].value

    }

    if (anagraficaStruttura.regioneResidenza == '098'  // SENZA FISSA DIMORA
      || anagraficaStruttura.regioneResidenza == '998' //RESIDENTE ALL’ESTERO
      || anagraficaStruttura.regioneResidenza == '999') {  //NON NOTO/NON RISULTA
      anagraficaStruttura.aslResidenza = anagraficaStruttura.regioneResidenza;
    } else {
      anagraficaStruttura.statoResidenza = 'IT';
    }

    if (this.dettaglioAnagraficaForm.contains("distrettoResidenza") || (this.dettaglioAnagraficaForm.get("distrettoResidenza") != null && this.dettaglioAnagraficaForm.get("distrettoResidenza")!.status == 'DISABLED')) {
      anagraficaStruttura.distrettoResidenza = this.dettaglioAnagraficaForm.controls['distrettoResidenza'].value
    }
    if (this.dettaglioAnagraficaForm.contains("municipioResidenza") || (this.dettaglioAnagraficaForm.get("municipioResidenza") != null && this.dettaglioAnagraficaForm.get("municipioResidenza")!.status == 'DISABLED')) {
      anagraficaStruttura.municipioResidenza = this.dettaglioAnagraficaForm.controls['municipioResidenza'].value
    }
    if (this.dettaglioAnagraficaForm.contains("statoResidenza") || (this.dettaglioAnagraficaForm.get("statoResidenza") != null && this.dettaglioAnagraficaForm.get("statoResidenza")!.status == 'DISABLED')) {
      anagraficaStruttura.statoResidenza = this.dettaglioAnagraficaForm.controls['statoResidenza'].value
    }
    return anagraficaStruttura;

  }

  async saveAnagraficaStruttura(anagraficaStruttura: AnagraficaStruttura) {

    this.anagraficaStruttura = await firstValueFrom(this.apiService.saveAnagraficaStruttura(anagraficaStruttura, this.mainUser.idRuolo!));

  }

  getInsertedValues(): DettaglioAnagrafica {

    let anagrafica = new DettaglioAnagrafica()

    if (this.dettaglioAnagrafica?.id != null) {
      anagrafica.id = this.dettaglioAnagrafica?.id
    }

    anagrafica.cognome = this.dettaglioAnagraficaForm.controls['cognome'].value
    anagrafica.nome = this.dettaglioAnagraficaForm.controls['nome'].value
    anagrafica.codiceFiscale = this.dettaglioAnagraficaForm.controls['codiceFiscale'].value
    anagrafica.sesso = this.dettaglioAnagraficaForm.controls['sesso'].value


    anagrafica.dataNascita = this.sessionService.toNativeDate(this.dateModel)!!
    anagrafica.codiceComuneNascita = this.modelDescrizioneComuneNascita!.codice
    anagrafica.descrizioneComuneNascita = this.modelDescrizioneComuneNascita!.descrizione
    anagrafica.statoCivile = this.dettaglioAnagraficaForm.controls['statoCivile'].value
    anagrafica.cittadinanza = this.dettaglioAnagraficaForm.controls['cittadinanza'].value
    anagrafica.regioneResidenza = this.dettaglioAnagraficaForm.controls['regioneResidenza'].value

    if (anagrafica.regioneResidenza == '098'  // SENZA FISSA DIMORA
      || anagrafica.regioneResidenza == '998' //RESIDENTE ALL’ESTERO
      || anagrafica.regioneResidenza == '999') {  //NON NOTO/NON RISULTA
      anagrafica.aslResidenza = anagrafica.regioneResidenza;
    } else {
      anagrafica.statoResidenza = 'IT';
    }

    if (this.dettaglioAnagraficaForm.contains("statoResidenza") || (this.dettaglioAnagraficaForm.get("statoResidenza") != null && this.dettaglioAnagraficaForm.get("statoResidenza")!.status == 'DISABLED')) {
      anagrafica.statoResidenza = this.dettaglioAnagraficaForm.controls['statoResidenza'].value
    }
    if (this.dettaglioAnagraficaForm.contains("comuneResidenza") || (this.dettaglioAnagraficaForm.get("comuneResidenza") != null && this.dettaglioAnagraficaForm.get("comuneResidenza")!.status == 'DISABLED')) {
      // anagrafica.comuneResidenza = this.dettaglioAnagraficaForm.controls['comuneResidenza'].value
      if (this.modelDescrizioneComuneResidenza) {

        anagrafica.comuneResidenza = this.modelDescrizioneComuneResidenza.codice;

      }
    }
    if (this.dettaglioAnagraficaForm.contains("aslResidenza") || (this.dettaglioAnagraficaForm.get("aslResidenza") != null && this.dettaglioAnagraficaForm.get("aslResidenza")!.status == 'DISABLED')) {
      anagrafica.aslResidenza = this.dettaglioAnagraficaForm.controls['aslResidenza'].value
    }
    if (this.dettaglioAnagraficaForm.contains("distrettoResidenza") || (this.dettaglioAnagraficaForm.get("distrettoResidenza") != null && this.dettaglioAnagraficaForm.get("distrettoResidenza")!.status == 'DISABLED')) {
      anagrafica.distrettoResidenza = this.dettaglioAnagraficaForm.controls['distrettoResidenza'].value
    }
    if (this.dettaglioAnagraficaForm.contains("municipioResidenza") || (this.dettaglioAnagraficaForm.get("municipioResidenza") != null && this.dettaglioAnagraficaForm.get("municipioResidenza")!.status == 'DISABLED')) {
      anagrafica.municipioResidenza = this.dettaglioAnagraficaForm.controls['municipioResidenza'].value
    }

    anagrafica.collocazioneSocioAmbientale = this.dettaglioAnagraficaForm.controls['collocazioneSocioAmbientale'].value
    anagrafica.titoloDiStudio = this.dettaglioAnagraficaForm.controls['titoloDiStudio'].value
    anagrafica.attivita = this.dettaglioAnagraficaForm.controls['attivita'].value
    anagrafica.invalidita = this.dettaglioAnagraficaForm.controls['invalidita'].value
    anagrafica.familiarePaziente = this.dettaglioAnagraficaForm.controls['familiarePaziente'].value
    anagrafica.tutelaGiuridica = this.dettaglioAnagraficaForm.controls['tutelaGiuridica'].value
    anagrafica.attualmenteSottoposto = this.dettaglioAnagraficaForm.controls['attualmenteSottoposto'].value
    anagrafica.precedentementeInternato = this.dettaglioAnagraficaForm.controls['precedentementeInternato'].value
    anagrafica.cfCertificato = this.cfCertificato;

    return anagrafica;

  }


  leaveEmptyPage() {


    this.router.navigate(['ricerca-anagrafica'])

  }

  utenteHasInfoStrutturaVisualizzazione() {
    let utente = this.sessionService.getUtenteFromStorage();
    return utente?.funzionalita.includes(Funzionalita.INFO_STRUTTURA_VISUALIZZAZIONE);
  }

  private theadColumnsInit(): TheadColumn[] {
    let res: TheadColumn[] = [];

    let utente = this.sessionService.getUtenteFromStorage();

    res.push(new TheadColumn('', 'Alert'));
    res.push(new TheadColumn('id', 'Id'));

    if (!this.getRuolo(this.OPERATORE_STRUTTURA))
      res.push(new TheadColumn('tipo_prestazione', 'Tipo prestazione'));

    res.push(new TheadColumn('descrizione_struttura_operatore_inserimento', 'Codice e denominazione struttura'));

   // console.log(this.page.content);
    if (!this.getRuolo(this.OPERATORE_STRUTTURA) || (this.getRuolo(this.OPERATORE_STRUTTURA) && this.getDescrTipoStruttura() == 'territoriale')) {
      res.push(new TheadColumn('data_richiesta', 'Data richiesta'));
      res.push(new TheadColumn('', 'Apertura contatto'));
    }
    res.push(new TheadColumn('data_apertura', 'Data apertura'));
    res.push(new TheadColumn('data_chiusura', 'Data chiusura'));

    if (!this.getRuolo(this.OPERATORE_STRUTTURA) || (this.getRuolo(this.OPERATORE_STRUTTURA) && this.getDescrTipoStruttura() == 'semiresidenziale'))
      res.push(new TheadColumn('', 'N° presenze'));

    res.push(new TheadColumn('', 'N° prestazioni'));
    res.push(new TheadColumn('stato_attuale', 'Stato trasmissione'));


    // res.push(new TheadColumn('tipoPrestazione', 'Tipo prestazione'));
    // res.push(new TheadColumn('dataRichiesta', 'Data'));
    // res.push(new TheadColumn('descrizioneDiagnosiApertura', 'Diagnosi apertura'));
    // res.push(new TheadColumn('descrizioneDiagnosiChiusura', 'Diagnosi chiusura'));
    // res.push(new TheadColumn('descrizioneStrutturaOperatoreInserimento', 'Sede/Struttura'));
    // res.push(new TheadColumn('prestazioniList.size', 'N° prestazioni'));
    // res.push(new TheadColumn('statoTrasmissione', 'Stato trasmissione'));

    return res;
  }

  getDescrTipoStruttura() {
    if (this.sessionService.getSelectedStrutturaFromStorage() != null) {
      if (this.sessionService.isSelectedStrutturaFromStorageTerritoriale()) {
        return "territoriale";
      } else {
        return "semiresidenziale"
      }
      ;
    } else {
      return "";
    }
  }


  loadTab() {
    this.sessionService.comingPathAnagrafica.subscribe(res => {
      if (res) {
        if (res === "TabContatti") {
          this.tabShowedContatti()
        } else {
          this.tabShowedAnagrafica()
        }
      }

    })
  }


  getTitleName(): string {

    if (!this.dettaglioAnagrafica?.nome) {
      return "Nuovo Paziente"
    } else {
      return "" + this.dettaglioAnagrafica?.cognome + " " + this.dettaglioAnagrafica?.nome + ' (CF: ' + this.dettaglioAnagrafica?.codiceFiscale + ")"
    }

  }


  tabContattiAccessbile() {
    return this.dettaglioAnagrafica?.id != null;
  }

  rigaContattoClick(contattoDto: ContattoDto) {
    this.apiService.selectContattoById(contattoDto.id).subscribe(res => {
      this.sessionService.setSelectedContatto(res);
      this.router.navigate(['contatto']);
    });

    // this.apiService.getContattoById(contattoDto.id).subscribe(res => {
    //   this.sessionService.setSelectedContatto(res);
    //   this.router.navigate(['contatto']);
    // });

  }

  showAnagraficaForm() {

    this.isTabContatti = false

  }

  showContattiTable() {
    this.isTabContatti = true
  }


  tabShowedAnagrafica() {
    this.tabShowed = TabShowedState.ANAGRAFICA;
  }

  isTabShowedAnagrafica() {
    return this.tabShowed == TabShowedState.ANAGRAFICA;
  }

  tabShowedContatti() {

    return this.tabShowed = TabShowedState.CONTATTI;
  }

  async loadContatti(id: number, pageNr: number, pageSize: number, order: string | null, direction: string | null) {
    const anss: AnagraficaSessionSettings = this.sessionService.getAnagraficaSessionSettings();

    this.apiService.getPaginaContatti(id,
      false, //FIXME questo non dovrebbe servire
      '',
      null,  //FIXME togliere questo parametro
      '',
      '',
      null,
      null,  //FIXME togliere questo parametro
      anss.anno,
      anss.ambito,
      anss.tipo_apporto_SSN,
      anss.struttura,
      anss.asl,
      anss.apertura_contatto,
      anss.entita,
      anss.stato_attuale,
      pageNr, pageSize, order, direction,
      this.mainUser.idRuolo).subscribe(res => {
      this.page = res
    })


    // this.apiService.getPaginaContatti(id, pageNr, pageSize, order, direction)
    //   .subscribe(res => {
    //   this.page = res
    // })
  }

  cambiaPagina(event: any) {
    this.loadContatti(this.dettaglioAnagrafica?.id!!, this.page.number, this.page.size, this.tableSorter.orderByName(), this.tableSorter.orderByDirection())
  }

  changeOrder(event: any) {
    this.loadContatti(this.dettaglioAnagrafica?.id!!, this.page.number, this.page.size, this.tableSorter.orderByName(), this.tableSorter.orderByDirection())

  }

  openAggiungiContattoForm() {

    this.sessionService.clearSelectedContatto();
    this.sessionService.setSelectedContatto(new Contatto())
    this.router.navigate(['contatto'])
  }

  // getAllAsl() {
  //   this.apiService.getAllAsl().subscribe(res => {
  //     this.listAsl = res
  //   })
  // }

  // getMunicipiByAsl(codiceAsl: string) {
  //   if (codiceAsl != '') {
  //     this.apiService.getMunicipiByAsl(codiceAsl).subscribe(res => {
  //       this.listMunicipi = res;
  //       if(this.listMunicipi.length>0){
  //         this.addOrKeepMunicipioResidenzaFormField();
  //       } else {
  //         this.removeMunicipioFormField();
  //       }
  //     })
  //   } else {
  //     this.listMunicipi = [];
  //     this.removeMunicipioFormField();
  //   }
  // }

  async getMunicipiByDistrettoResidenza(distrettoResidenza: string) {
    if (distrettoResidenza != '') {
      this.listMunicipi = await firstValueFrom(this.apiService.getMunicipiByDistrettoResidenza(distrettoResidenza));
      //.subscribe(res => {
      //  this.listMunicipi = res;
      if (this.listMunicipi&&this.listMunicipi.length > 0) {
        this.addOrKeepMunicipioResidenzaFormField();
      } else {
        this.removeMunicipioFormField();
      }
      //})
    } else {
      this.listMunicipi = [];
      this.removeMunicipioFormField();
    }
  }

  getDistrettobyAsl(codiceAsl: string) {

    if (codiceAsl != '') {
      this.apiService.getDistrettoByAsl(codiceAsl).subscribe(res => {
        this.listDistretti = res
      })
    } else {
      this.listDistretti = [];
    }
  }

  getAslbyComune(codiceComune: string) {

    if (codiceComune != '') {
      this.apiService.getAslByComune(codiceComune).subscribe(res => {
        this.listAsl = res
      })
    } else {
      this.listAsl = [];
    }
  }

  async getAllStatiEsteri() {

    this.apiService.getAllStatiEsteri().subscribe(res => {
      this.listStatiesteri = res

      // set italia first element
      let italiaIndex = this.listStatiesteri.findIndex(c => c.alpha2 === "IT");
      //   this.listStatiesteri = this.moveObjectIndex(this.listStatiesteri, italiaIndex, 0)
      this.listStatiesteri.splice(italiaIndex, 1);

      // set apolide last element
      let apolideIndex = this.listStatiesteri.findIndex(c => c.alpha2 === "ZZ");
      this.listStatiesteri = this.moveObjectIndex(this.listStatiesteri, apolideIndex, this.listStatiesteri.length)
    })
  }


  async getAllStatiCivili() {
    this.apiService.getAllStatiCivili().subscribe(res => {
      this.listStatiCivili = res
    })

  }


  async getAllComuni() {

    this.listComune = await firstValueFrom(this.apiService.getAllComuni());
    //.subscribe(res => {
    //  this.listComune = res
    this.listDescrizioniComune = [];
    this.listComune.forEach(elem => {
      this.listDescrizioniComune.push(elem)
    });

    // })
  }


  getAllComuniByRegione(codice: string) {
    if (codice != '') {
      this.apiService.getAllComuniByRegione(codice).subscribe(res => {
        this.listComuneResidenza = res;
      });
    } else {
      this.listComuneResidenza = [];
    }

  }

  async getAllCollazioneSocioAmbientali() {

    this.apiService.getAllCollazioeSocioAmbientali().subscribe(res => {
      this.listCollazioneSocioAmbientali = res
    })

  }

  async getAllTitoliStudio() {

    this.apiService.getAllTitoliStudio().subscribe(res => {
      this.listTitoliStudio = res
    })

  }

  async getAllAtivitta() {

    this.apiService.getAllAtivitta().subscribe(res => {
      this.listAtivitta = res
    })

  }

  async getAllInvalidita() {

    this.apiService.getAllInvalidita().subscribe(res => {
      this.listInvalidita = res
    })

  }

  async getAllTuteleGiuridice() {

    this.apiService.getAllTuteleGiuridice().subscribe(res => {
      this.listTuteleGiuridice = res
    })

  }

  async getAllProvedimenti() {

    this.apiService.getAllProvedimenti().subscribe(res => {
      this.listProvedimenti = res
    })

  }

  async getAllCitadinanza() {

    this.apiService.getAllCitadinanza().subscribe(res => {
      this.listCitadiNanza = res


      // set italia first element
      let italiaIndex = this.listCitadiNanza.findIndex(c => c.alpha2 === "IT");
      this.listCitadiNanza = this.moveObjectIndex(this.listCitadiNanza, italiaIndex, 0)

      // set apolide last element
      let apolideIndex = this.listCitadiNanza.findIndex(c => c.alpha2 === "ZZ");
      this.listCitadiNanza = this.moveObjectIndex(this.listCitadiNanza, apolideIndex, this.listCitadiNanza.length)
    })

  }

  getAllRegioni() {
    this.apiService.getAllRegioni().subscribe(res => {
      this.listRegione = res
    })
  }

  async getAllDistretti() {

    this.apiService.getAllDistretti().subscribe(res => {
      this.listDistretti = res

    })

  }


  async getAllMunicipi() {

    this.apiService.getAllMunicipi().subscribe(res => {
      this.listMunicipi = res

    })

  }


  openGoBackDialog(content: any) {

    if (!this.dettaglioAnagraficaForm.touched) {

      this.back()
    } else {

      this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;

        if (this.closeResult === 'Closed with: Back click') {
          this.back()

        }
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;


      });
    }


  }

  openImportXmlDialog(xmlimport: any) {

    this.modalService.open(xmlimport, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

      if (this.closeResult === 'Closed with: Back click') {

        this.back()


      }
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;


    });


  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  back() {
    this.router.navigate(['ricerca-anagrafica'])
  }

  aggiungiContattoVisibile() {
    let utente = this.sessionService.getUtenteFromStorage();
    return utente?.funzionalita.includes(Funzionalita.ANAGRAFICHE_INSERIMENTO);
  }

  salvaVisibile() {
    let utente = this.sessionService.getUtenteFromStorage();
    return utente?.funzionalita.includes(Funzionalita.ANAGRAFICHE_INSERIMENTO);
  }

  navigateToDashboard() {
    this.router.navigate(['dashboard'])
  }

  getTransmisionState(statoTrasmissione: string) {

    let spanclass = ""

    if (statoTrasmissione === "VALIDATO") {
      spanclass = "badge badge-success"
    } else if (statoTrasmissione === "DA_TRASMETTERE") {
      spanclass = "badge badge-warning"
    } else if (statoTrasmissione === "SCARTATTO") {
      spanclass = "badge badge-danger"
    }

    return spanclass
  }

  moveObjectIndex(array: any, sourceIndex: number, destIndex: number) {
    var placeholder = {};

    var objectToMove = array.splice(sourceIndex, 1, placeholder)[0];
    // place the object in the desired position
    array.splice(destIndex, 0, objectToMove);
    // take out the temporary object
    array.splice(array.indexOf(placeholder), 1);

    return array
  }


  tabAnagraficaClasses() {
    return this.tabShowed == TabShowedState.ANAGRAFICA ?
      'active' : '';
  }

  tabPaneAnagraficaClasses() {
    return this.tabShowed == TabShowedState.ANAGRAFICA ?
      'active show' : '';
  }

  tabContattiClasses() {
    let res = this.tabShowed == TabShowedState.CONTATTI ?
      'active' : '';

    return res;
  }

  tabPaneContattiClasses() {
    return this.tabShowed == TabShowedState.CONTATTI ?
      'active show' : '';
  }

  formatText(text: string | null): string | null {

    if (text != null && text.length > 25) {

      return text.slice(0, 25) + "..."
    } else {

      return text ? text : ""
    }


  }

  ngOnDestroy() {
    this.sessionService.setOpenTabAnagrafica("")
    this.destroy.next({});
  }

  setCodiceFiscaleUpperCase() {
    if (this.dettaglioAnagraficaForm.get("codiceFiscale")) {
      const cfControl = this.dettaglioAnagraficaForm.get('codiceFiscale');
      cfControl!!.valueChanges.subscribe(() => {
        cfControl!!.patchValue(cfControl!!.value.toUpperCase(), {emitEvent: false});
      });
    }

  }


  hasWritePermission(utente: Utente): boolean {


    if (utente.funzionalita.includes(Funzionalita.ANAGRAFICHE_INSERIMENTO)) {
      return true
    } else {
      return false
    }

  }


  gestionePazientiVisibile() {
    let utente = this.sessionService.getUtenteFromStorage();
    let res =
      utente?.funzionalita.includes(Funzionalita.ANAGRAFICHE_VISUALIZZAZIONE) ||
      utente?.funzionalita.includes(Funzionalita.ANAGRAFICHE_INSERIMENTO);
    return res;
  }


  cancellaAnagrafica() {

    this.modalService.dismissAll()
    var struttura = this.sessionService.getSelectedStrutturaFromStorage();
    this.apiService.cancellaAnagrafica(this.dettaglioAnagrafica?.id!!, struttura?.codice!).subscribe({

      next: (res) => {

        this.toastService.showSuccess("anagrafica con id " + res + " cancellata con successo")

      },

      error: (err) => {
        console.log(err);
        this.toastService.showError("è successo un problema durante la cancellazione")
      },

      complete: () => {
        this.router.navigate(['ricerca-anagrafica'])
      }

    })

  }

  cancellaVisibile(): Boolean {
    if (this.canDelete && this.dettaglioAnagrafica && this.dettaglioAnagrafica!!.id != null && this.salvaVisibile()) {
      return true
    } else {
      return false
    }
  }


  openCancellaDialog(cancella: any) {

    this.modalService.open(cancella, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

      if (this.closeResult === 'Closed with: Back click') {
        this.back()

      }
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;


    });


  }

  areAllContattiClosed(): boolean {
    let isAllContattiClosed = this.allAnagraficaContatti.every(
      cont => cont.dataChiusura != null || cont.dataChiusura != undefined || cont.aperturaFlag == "2")
    return isAllContattiClosed
  }

  // errorLength(control: AbstractControl) {
  //     return new CommonsUtil().formControlErrorLength(control.errors)
  // }


  descrizioneStato(stato: number) {

    for (let confStato of this.confStati) {

      if (stato == 5 && !this.getRuolo(this.OPERATORE_REGIONE))
        stato = 3;

      if (stato == confStato.id) {

        return confStato.descrizione;

      }

    }

    return "Stato non trovato";
    // return this.apiService.getConfStatoById(stato).subscribe(data =>{
    //    return data.descrizione
    //  })
    // return new CommonsUtil().descrizioneStato(stato);
  }

  contattiHasError() {
    return this.page.content.some(value => value.warning);
  }
  pazienteElement: any = {};
  async getAnagraficaByCf() {

    this.isLoading = true;
    this.getAllComuni();

    let cf = this.dettaglioAnagraficaForm.get('anagraficaCF')?.value ? this.dettaglioAnagraficaForm.get('anagraficaCF')?.value : this.dettaglioAnagraficaForm.get('anagraficaSCF')?.value;
    if (!cf) {
      this.isLoading = false;
      this.toastService.showError("Specificare almeno un codice di ricerca")
      return;
    }
    if (this.dettaglioAnagraficaForm.get('anagraficaSCF')?.value
      && !this.dettaglioAnagraficaForm.get('anagraficaSCF')?.value.toUpperCase().startsWith("ENI")
      && !this.dettaglioAnagraficaForm.get('anagraficaSCF')?.value.toUpperCase().startsWith("STP")) {
      this.isLoading = false;
      this.toastService.showError("Specificare un valore corretto per il codice ENI/STP")
      return;
    }
    this.isControllaClick = true;
    let struttura = this.sessionService.getSelectedStrutturaFromStorage();

    const res = await firstValueFrom(this.apiService.getAnagraficaByCf(cf))
    this.isLoading = true;
    if (res != null) {
      this.messageDatiCertificati = "";
      this.dettaglioAnagrafica = res;

      if(this.dettaglioAnagrafica.cfCertificato)this.cfCertificato =this.dettaglioAnagrafica.cfCertificato;

      this.anagraficaExists = true;
      this.anagraficaId = res.id;
      if (this.dettaglioAnagrafica?.cfCertificato == "1" || this.anagraficaStruttura == null) {
        await this.checkConditionalFields(this.dettaglioAnagrafica!!);

      } else {

        //this.checkConditionalFieldsNonCert(this.anagraficaStruttura!!)
        await this.checkConditionalFields(this.anagraficaStruttura!!);


      }          //popolare dati da tabella anagrafica
      this.dettaglioAnagraficaForm.get('codiceFiscale')?.setValue(this.dettaglioAnagrafica!!.codiceFiscale);
      this.dettaglioAnagraficaForm.get('codiceFiscale')?.disable();

      this.dettaglioAnagraficaForm.get('anagraficaCF')?.value ? this.dettaglioAnagraficaForm.get('anagraficaCF')?.setValue('') : null;
      this.dettaglioAnagraficaForm.get('anagraficaSCF')?.value ? this.dettaglioAnagraficaForm.get('anagraficaSCF')?.setValue('') : null;

      if (this.dettaglioAnagrafica.dataNascita != null) {
       // console.log(this.dettaglioAnagrafica.dataNascita)
        this.dateModel = this.sessionService.convertToDateStruct(new Date(this.dettaglioAnagrafica.dataNascita))!!
       // console.log(this.dateModel)
      }

      this.dettaglioAnagraficaForm.get('dataNascita')?.disable();
      this.dataNascitaButtonDisable = true;
      this.dettaglioAnagraficaForm.get('cognome')?.setValue(this.dettaglioAnagrafica!!.cognome);
      this.dettaglioAnagraficaForm.get('cognome')?.disable();
      this.dettaglioAnagraficaForm.get('nome')?.setValue(this.dettaglioAnagrafica!!.nome);
      this.dettaglioAnagraficaForm.get('nome')?.disable();
      this.dettaglioAnagraficaForm.get('sesso')?.setValue(this.dettaglioAnagrafica!!.sesso);
      this.dettaglioAnagraficaForm.get('sesso')?.disable();
      this.modelDescrizioneComuneNascita = new Comune()
      this.modelDescrizioneComuneNascita!.descrizione = this.dettaglioAnagrafica.descrizioneComuneNascita
      this.modelDescrizioneComuneNascita!.codice = this.dettaglioAnagrafica.codiceComuneNascita
      this.dettaglioAnagraficaForm.get('descrizioneComuneNascita')?.disable();
      if (this.dettaglioAnagrafica.codiceStrutturaOperatoreInserimento === struttura?.codice || this.dettaglioAnagrafica.cfCertificato == '1') {
        this.dettaglioAnagraficaForm.get('cittadinanza')?.patchValue(this.dettaglioAnagrafica!!.cittadinanza);
        if (this.dettaglioAnagrafica.cfCertificato === '1') {
          this.dettaglioAnagraficaForm.get('cittadinanza')?.disable();
          this.dettaglioAnagraficaForm.get('regioneResidenza')?.disable();
        }
        // this.dettaglioAnagraficaForm.get('regioneResidenza')?.setValue(this.dettaglioAnagrafica!!.regioneResidenza);
        this.modelDescrizioneComuneResidenza = new Comune()
        let comuneRes = this.listComune.find(x => x.codice == this.dettaglioAnagrafica!!.comuneResidenza)
        if (comuneRes) {
          this.modelDescrizioneComuneResidenza!.descrizione = comuneRes!.descrizione
          this.modelDescrizioneComuneResidenza!.codice = comuneRes!.codice
          // this.dettaglioAnagraficaForm.get('comuneResidenza')?.patchValue(this.dettaglioAnagrafica!!.comuneResidenza);
          if (this.dettaglioAnagrafica.cfCertificato === '1') {
            this.dettaglioAnagraficaForm.get('comuneResidenza')?.disable();
          }
        }
        //FIXME CABLATURA CON CODICE REGIONE LAZIO
        // if (this.dettaglioAnagrafica!!.regioneResidenza === this.codiceRegioneLazio) {
        if (this.dettaglioAnagraficaForm.get('regioneResidenza')?.value === this.codiceRegioneLazio) {

          // this.dettaglioAnagraficaForm.get('aslResidenza')?.patchValue(this.dettaglioAnagrafica!!.aslResidenza);
          // this.dettaglioAnagraficaForm.get('distrettoResidenza')?.patchValue(this.dettaglioAnagrafica!!.distrettoResidenza);
          // this.dettaglioAnagraficaForm.get('municipioResidenza')?.patchValue(this.dettaglioAnagrafica!!.municipioResidenza);
          if (this.dettaglioAnagrafica.cfCertificato === '1') {
            this.dettaglioAnagraficaForm.get('aslResidenza')?.patchValue(this.dettaglioAnagrafica!!.aslResidenza);
            this.dettaglioAnagraficaForm.get('aslResidenza')?.disable();
            //this.dettaglioAnagraficaForm.get('municipioResidenza')?.disable();
            //this.dettaglioAnagraficaForm.get('distrettoResidenza')?.disable();

          }

        } else {
          this.dettaglioAnagraficaForm.get('regioneResidenza')?.enable();
          this.dettaglioAnagraficaForm.get('regioneResidenza')?.patchValue("");
          this.dettaglioAnagraficaForm.get('comuneResidenza')?.enable();
          this.dettaglioAnagraficaForm.get('comuneResidenza')?.patchValue("");
          this.dettaglioAnagraficaForm.removeControl('aslResidenza');
          this.dettaglioAnagraficaForm.removeControl('distrettoResidenza');
          this.dettaglioAnagraficaForm.removeControl('municipioResidenza');

        }
        // if (this.dettaglioAnagrafica!!.regioneResidenza === this.codiceRegioneLazio) {
        //
        //   this.dettaglioAnagraficaForm.get('aslResidenza')?.patchValue(this.dettaglioAnagrafica!!.aslResidenza);
        //   this.dettaglioAnagraficaForm.get('distrettoResidenza')?.patchValue(this.dettaglioAnagrafica!!.distrettoResidenza);
        //   this.dettaglioAnagraficaForm.get('municipioResidenza')?.patchValue(this.dettaglioAnagrafica!!.municipioResidenza);
        //   if (this.dettaglioAnagrafica.cfCertificato === '1') {
        //
        //     this.dettaglioAnagraficaForm.get('aslResidenza')?.disable();
        //     //this.dettaglioAnagraficaForm.get('municipioResidenza')?.disable();
        //     //this.dettaglioAnagraficaForm.get('distrettoResidenza')?.disable();
        //
        //   }
        //
        // } else {
        //
        //   this.dettaglioAnagraficaForm.removeControl('aslResidenza');
        //   this.dettaglioAnagraficaForm.removeControl('distrettoResidenza');
        //   this.dettaglioAnagraficaForm.removeControl('municipioResidenza');
        //
        // }
      } else {

        this.dettaglioAnagraficaForm.get('cittadinanza')?.patchValue("");
        this.dettaglioAnagraficaForm.get('cittadinanza')?.enable();
        this.dettaglioAnagraficaForm.get('regioneResidenza')?.enable();
        this.dettaglioAnagraficaForm.get('regioneResidenza')?.patchValue("");
        this.dettaglioAnagraficaForm.get('comuneResidenza')?.enable();
        this.dettaglioAnagraficaForm.get('comuneResidenza')?.patchValue("");
        // this.dettaglioAnagraficaForm.get('aslResidenza')?.patchValue("");
        // this.dettaglioAnagraficaForm.get('municipioResidenza')?.enable();
        // this.dettaglioAnagraficaForm.get('distrettoResidenza')?.enable();
        // this.dettaglioAnagraficaForm.get('aslResidenza')?.patchValue("");
        // this.dettaglioAnagraficaForm.get('municipioResidenza')?.patchValue("");
        // this.dettaglioAnagraficaForm.get('distrettoResidenza')?.patchValue("");
        this.dettaglioAnagraficaForm.removeControl('aslResidenza');
        this.dettaglioAnagraficaForm.removeControl('distrettoResidenza');
        this.dettaglioAnagraficaForm.removeControl('municipioResidenza');

      }


      //TODO: Recuperare dati anagrafica struittura se presenti
      //await this.loadAnagraficaStruttura(res.id)

      //Controlla se il cf è certificato nel caso non lo sia controllo se esiste in ASUR e nel caso setto il cf a certificato
      // if (this.dettaglioAnagrafica.cfCertificato != "1") {
        this.isLoading = false;
        this.apiService.asurGetAssistito(cf).subscribe({

          next: (res: any) => {

            //popolare dati da asur
            if (res.success) {
              this.pazienteElement = res;
              if(res.data.paziente[0].nazioneNascita != "100"){
                if(res.data.paziente[0].comuneNascita.codice.endsWith("999")){
                  let prefix: string = res.data.paziente[0].comuneNascita.codice.substring(0, res.data.paziente[0].comuneNascita.codice.length - 3);
                  // Estrai gli ultimi tre "9" e mettili come primi tre caratteri
                  let invertedString: string = res.data.paziente[0].comuneNascita.codice.slice(-3) + prefix;
                  this.modelDescrizioneComuneNascita!.codice = invertedString;
                } else {

                  this.modelDescrizioneComuneNascita!.codice = res.data.paziente[0].comuneNascita.codice
                }
              }
              this.setResidenza(res);
              this.cfCertificato = "1"

            } else {
              this.cfCertificato = "2"
            }

          }
        });

      // }

      this.isLoading = false;

    } else {
      this.isLoading = true;
      //this.dettaglioAnagrafica = null;
      this.dettaglioAnagrafica = new DettaglioAnagrafica();

      //eseguire chiamata asur
      const res: any = await firstValueFrom(this.apiService.asurGetAssistito(cf));
          //popolare dati da asur
          if (res.success) {
            this.pazienteElement = res;
            this.messageDatiCertificati = "";
            this.cfCertificato = "1";
            this.dettaglioAnagraficaForm.get('codiceFiscale')?.setValue(res.data.paziente[0]!!.codiceFiscale);
            this.dettaglioAnagraficaForm.get('codiceFiscale')?.disable();

            if (res.data.paziente[0].dataNascita != null) {
             // console.log("Prendo la data di nascita da ASUR")
            //  console.log(res.data.paziente[0].dataNascita);
              this.dateModel = this.sessionService.convertToDateStruct(new Date(res.data.paziente[0].dataNascita.substring(0,10)))!!
             // console.log(this.dateModel);

            }

            this.dettaglioAnagraficaForm.get('dataNascita')?.disable();
            this.dataNascitaButtonDisable = true;

            if (res.data.paziente[0].cognome != null) {

              this.dettaglioAnagraficaForm.get('cognome')?.setValue(res.data.paziente[0].cognome);
              this.dettaglioAnagraficaForm.get('cognome')?.disable();

            } else {

              this.dettaglioAnagraficaForm.get('cognome')?.setValue("");
              this.dettaglioAnagraficaForm.get('cognome')?.enable();

            }

            if (res.data.paziente[0].nome != null) {

              this.dettaglioAnagraficaForm.get('nome')?.setValue(res.data.paziente[0].nome);
              this.dettaglioAnagraficaForm.get('nome')?.disable();

            } else {

              this.dettaglioAnagraficaForm.get('nome')?.setValue("");
              this.dettaglioAnagraficaForm.get('nome')?.enable();

            }

            if (res.data.paziente[0].sesso != null) {

              this.dettaglioAnagraficaForm.get('sesso')?.setValue(res.data.paziente[0].sesso);
              this.dettaglioAnagraficaForm.get('sesso')?.disable();

            } else {

              this.dettaglioAnagraficaForm.get('sesso')?.setValue("");
              this.dettaglioAnagraficaForm.get('sesso')?.enable();

            }

            if (res.data.paziente[0].comuneNascita != null) {

              this.modelDescrizioneComuneNascita = new Comune();
              this.modelDescrizioneComuneNascita!.descrizione = res.data.paziente[0].comuneNascita._value_1
              if(res.data.paziente[0].nazioneNascita != "100"){
                if(res.data.paziente[0].comuneNascita.codice.endsWith("999")){
                  let prefix: string = res.data.paziente[0].comuneNascita.codice.substring(0, res.data.paziente[0].comuneNascita.codice.length - 3);
                  // Estrai gli ultimi tre "9" e mettili come primi tre caratteri
                  let invertedString: string = res.data.paziente[0].comuneNascita.codice.slice(-3) + prefix;
                  this.modelDescrizioneComuneNascita!.codice = invertedString;
                } else {

                  this.modelDescrizioneComuneNascita!.codice = res.data.paziente[0].comuneNascita.codice
                }
              } else {

                this.modelDescrizioneComuneNascita!.codice = res.data.paziente[0].comuneNascita.codice
              }
              this.dettaglioAnagraficaForm.get('descrizioneComuneNascita')?.disable();

            } else {

              this.modelDescrizioneComuneNascita = new Comune();
              this.dettaglioAnagraficaForm.get('descrizioneComuneNascita')?.enable();

            }

            if (res.data.paziente[0].nazioneCittadinanza != null) {

              let cittadinanza = this.listCitadiNanza.find(x => x.codIstat == Number(res.data.paziente[0].nazioneCittadinanza.codice))
              this.dettaglioAnagraficaForm.get('cittadinanza')?.patchValue(cittadinanza?.alpha2);
              this.dettaglioAnagraficaForm.get('cittadinanza')?.disable();

            } else {

              this.dettaglioAnagraficaForm.get('cittadinanza')?.patchValue("");
              this.dettaglioAnagraficaForm.get('cittadinanza')?.enable();

            }

            await this.setResidenza(res);
            // if (res.data.paziente[0].comuneResidenza != null) {
            //
            //   let comuneResidenza = this.listComune.find(x => x.codice === res.data.paziente[0].comuneResidenza.codice);
            //   this.getAllComuniByRegione(comuneResidenza?.codRegione!);
            //   if (!this.dettaglioAnagraficaForm.get("comuneResidenza")) {
            //
            //     this.dettaglioAnagraficaForm.addControl("comuneResidenza",
            //       new FormControl( "", [Validators.required]));
            //   }
            //   //this.addOrKeepComuneFormField(this.dettaglioAnagrafica!);
            //   //FIXME CABLATURA CON CODICE REGIONE LAZIO
            //   if (comuneResidenza?.codRegione! == this.codiceRegioneLazio) {
            //
            //     this.getAslbyComune(res.data.paziente[0].comuneResidenza.codice);
            //     if (!this.dettaglioAnagraficaForm.get("aslResidenza")) {
            //
            //       this.dettaglioAnagraficaForm.addControl("aslResidenza",
            //         new FormControl( "",
            //           [Validators.required]));
            //
            //     }
            //     //this.addOrKeepAslResidenzaFormField(this.dettaglioAnagrafica!);
            //
            //   }
            //
            //   this.dettaglioAnagraficaForm.get('regioneResidenza')?.patchValue(comuneResidenza?.codRegione);
            //   this.dettaglioAnagraficaForm.get('regioneResidenza')?.disable();
            //
            // } else {
            //
            //   this.dettaglioAnagraficaForm.get('regioneResidenza')?.patchValue("");
            //   this.dettaglioAnagraficaForm.get('regioneResidenza')?.enable();
            //
            // }
            //
            // if (res.data.paziente[0].comuneResidenza != null) {
            //   this.modelDescrizioneComuneResidenza = new Comune()
            //   let comuneRes = this.listComune.find(x => x.codice == res.data.paziente[0].comuneResidenza.codice)
            //   this.modelDescrizioneComuneResidenza!.descrizione = comuneRes!.descrizione
            //   this.modelDescrizioneComuneResidenza!.codice = comuneRes!.codice
            //   // this.dettaglioAnagraficaForm.get('comuneResidenza')?.patchValue(res.data.paziente[0].comuneResidenza.codice);
            //   this.dettaglioAnagraficaForm.get('comuneResidenza')?.disable();
            //
            // } else {
            //   this.modelDescrizioneComuneResidenza = new Comune();
            //   this.dettaglioAnagraficaForm.get('comuneResidenza')?.patchValue("");
            //   this.dettaglioAnagraficaForm.get('comuneResidenza')?.enable();
            //
            // }
            //
            // if (res.data.paziente[0].aslResidenza != null) {
            //
            //
            //   let comuneResidenza = this.listComune.find(x => x.codice === res.data.paziente[0].comuneResidenza.codice);
            //   let codAslResidenza = res.data.paziente[0].aslResidenza.codice.replace(comuneResidenza?.codRegione, "");
            //   this.getDistrettobyAsl(codAslResidenza)
            //   this.dettaglioAnagraficaForm.get('aslResidenza')?.patchValue(codAslResidenza);
            //   this.dettaglioAnagraficaForm.get('aslResidenza')?.disable();
            //
            // } else {
            //
            //   this.dettaglioAnagraficaForm.get('aslResidenza')?.patchValue("");
            //   this.dettaglioAnagraficaForm.get('aslResidenza')?.enable();
            //
            // }
            //
            // if (res.data.paziente[0].distrettoResidenza != null) {
            //
            //   if (!this.dettaglioAnagraficaForm.get("distrettoResidenza")) {
            //
            //     this.dettaglioAnagraficaForm.addControl("distrettoResidenza",
            //       new FormControl( "",
            //         [Validators.required]));
            //   }
            //   //this.addOrKeepDistrettoResidenzaFormField(this.dettaglioAnagrafica!)
            //   await this.getMunicipiByDistrettoResidenza(res.data.paziente[0].distrettoResidenza.codice)
            //   this.dettaglioAnagraficaForm.get('distrettoResidenza')?.patchValue(res.data.paziente[0].distrettoResidenza.codice);
            //   //this.dettaglioAnagraficaForm.get('distrettoResidenza')?.disable();
            //
            // } else {
            //
            //   this.dettaglioAnagraficaForm.get('distrettoResidenza')?.patchValue("");
            //   this.dettaglioAnagraficaForm.get('distrettoResidenza')?.enable();
            //
            // }

            /*if (res.data.paziente[0].municipioResidenza != null) {

              if (!this.dettaglioAnagraficaForm.get("municipioResidenza")) {

                this.dettaglioAnagraficaForm.addControl("municipioResidenza",
                  new FormControl(
                    "", [Validators.required]));
              }
              //this.addOrKeepMunicipioResidenzaFormField();
              this.dettaglioAnagraficaForm.get('municipioResidenza')?.patchValue(res.data.paziente[0].municipioResidenza.codice);
              //this.dettaglioAnagraficaForm.get('municipioResidenza')?.disable();

            } else {

              this.dettaglioAnagraficaForm.get('municipioResidenza')?.patchValue("");
              this.dettaglioAnagraficaForm.get('municipioResidenza')?.enable();

            }*/
            this.isLoading = true;

          } else {
            this.isLoading = true;
            this.cfCertificato = "2";
            this.cfNotFound(cf);
          }

       this.isLoading = false;

    }

    this.isLoading = false;

  }

  async setResidenza(res: any){
    if (res.data.paziente[0].comuneResidenza != null) {

      let comuneResidenza = this.listComune.find(x => x.codice === res.data.paziente[0].comuneResidenza.codice);
      this.getAllComuniByRegione(comuneResidenza?.codRegione!);
      if (!this.dettaglioAnagraficaForm.get("comuneResidenza")) {

        this.dettaglioAnagraficaForm.addControl("comuneResidenza",
          new FormControl( "", [Validators.required]));
      }
      //this.addOrKeepComuneFormField(this.dettaglioAnagrafica!);
      //FIXME CABLATURA CON CODICE REGIONE LAZIO
      if (comuneResidenza?.codRegione! == this.codiceRegioneLazio) {

        this.getAslbyComune(res.data.paziente[0].comuneResidenza.codice);
        if (!this.dettaglioAnagraficaForm.get("aslResidenza")) {

          this.dettaglioAnagraficaForm.addControl("aslResidenza",
            new FormControl( "",
              [Validators.required]));

        }
        //this.addOrKeepAslResidenzaFormField(this.dettaglioAnagrafica!);

      }

      this.dettaglioAnagraficaForm.get('regioneResidenza')?.patchValue(comuneResidenza?.codRegione);
      this.dettaglioAnagraficaForm.get('regioneResidenza')?.disable();

    } else {

      this.dettaglioAnagraficaForm.get('regioneResidenza')?.patchValue("");
      this.dettaglioAnagraficaForm.get('regioneResidenza')?.enable();

    }

    if (res.data.paziente[0].comuneResidenza != null) {
      this.modelDescrizioneComuneResidenza = new Comune()
      let comuneRes = this.listComune.find(x => x.codice == res.data.paziente[0].comuneResidenza.codice)
      this.modelDescrizioneComuneResidenza!.descrizione = comuneRes!.descrizione
      this.modelDescrizioneComuneResidenza!.codice = comuneRes!.codice
      // this.dettaglioAnagraficaForm.get('comuneResidenza')?.patchValue(res.data.paziente[0].comuneResidenza.codice);
      this.dettaglioAnagraficaForm.get('comuneResidenza')?.disable();

    } else {
      this.modelDescrizioneComuneResidenza = new Comune();
      this.dettaglioAnagraficaForm.get('comuneResidenza')?.patchValue("");
      this.dettaglioAnagraficaForm.get('comuneResidenza')?.enable();

    }

    if (res.data.paziente[0].aslResidenza != null) {


      let comuneResidenza = this.listComune.find(x => x.codice === res.data.paziente[0].comuneResidenza.codice);
      let codAslResidenza = res.data.paziente[0].aslResidenza.codice.replace(comuneResidenza?.codRegione, "");
      this.getDistrettobyAsl(codAslResidenza)
      this.dettaglioAnagraficaForm.get('aslResidenza')?.patchValue(codAslResidenza);
      this.dettaglioAnagraficaForm.get('aslResidenza')?.disable();

    } else {

      this.dettaglioAnagraficaForm.get('aslResidenza')?.patchValue("");
      this.dettaglioAnagraficaForm.get('aslResidenza')?.enable();

    }

    if (res.data.paziente[0].distrettoResidenza != null) {

      if (!this.dettaglioAnagraficaForm.get("distrettoResidenza")) {

        this.dettaglioAnagraficaForm.addControl("distrettoResidenza",
          new FormControl( "",
            [Validators.required]));
      }
      //this.addOrKeepDistrettoResidenzaFormField(this.dettaglioAnagrafica!)
      await this.getMunicipiByDistrettoResidenza(res.data.paziente[0].distrettoResidenza.codice)
      // this.dettaglioAnagraficaForm.get('distrettoResidenza')?.patchValue(res.data.paziente[0].distrettoResidenza.codice);
      //this.dettaglioAnagraficaForm.get('distrettoResidenza')?.disable();

    } else {

      this.dettaglioAnagraficaForm.get('distrettoResidenza')?.patchValue("");
      this.dettaglioAnagraficaForm.get('distrettoResidenza')?.enable();

    }
  }
  async loadAnagraficaStruttura(anagraficaId: number | null) {

    if (this.dettaglioAnagrafica != null) {

      var struttura = this.sessionService.getSelectedStrutturaFromStorage();
      if (!struttura) {
        return;
      }
      const res = await firstValueFrom(this.apiService.getAnagraficaStrutturaByCodStrutturaAndIdAnagrafica(struttura!.codice!, anagraficaId!));
      //.subscribe({

      // next: async (res) => {

      if (res != null) {
        if (this.dettaglioAnagrafica?.cfCertificato === "1") {

          await this.checkConditionalFields(this.dettaglioAnagrafica!!);

        } else {

          //this.checkConditionalFieldsNonCert(res);
          await this.checkConditionalFields(res);

        }
        this.anagraficaStruttura = res;
        this.dettaglioAnagraficaForm.get('statoCivile')?.patchValue(res.statoCivile);
        this.dettaglioAnagraficaForm.get('collocazioneSocioAmbientale')?.patchValue(res.collocazioneSocioAmbientale);
        this.dettaglioAnagraficaForm.get('titoloDiStudio')?.patchValue(res.titoloDiStudio);
        this.dettaglioAnagraficaForm.get('attivita')?.patchValue(res.attivita);
        this.dettaglioAnagraficaForm.get('familiarePaziente')?.patchValue(res.familiarePaziente);
        this.dettaglioAnagraficaForm.get('invalidita')?.patchValue(res.invalidita);
        this.dettaglioAnagraficaForm.get('tutelaGiuridica')?.patchValue(res.tutelaGiuridica);
        this.dettaglioAnagraficaForm.get('attualmenteSottoposto')?.patchValue(res.attualmenteSottoposto);
        this.dettaglioAnagraficaForm.get('precedentementeInternato')?.patchValue(res.precedentementeInternato);

        if (this.dettaglioAnagrafica?.cfCertificato === "2") {

          this.dettaglioAnagraficaForm.get('cittadinanza')?.patchValue(res.cittadinanza);
          this.dettaglioAnagraficaForm.get('cittadinanza')?.enable();
          this.dettaglioAnagraficaForm.get('regioneResidenza')?.enable();
          this.dettaglioAnagraficaForm.get('regioneResidenza')?.patchValue(res.regioneResidenza);
          if (res.regioneResidenza && this.isRegioneResidenzaLazio(res.regioneResidenza)) {
            this.addOrKeepAslResidenzaFormField(res);
            this.addOrKeepDistrettoResidenzaFormField(res);
          } else {
            this.removeAslFormField();
            this.removeDistrettoFormField();
            this.removeMunicipioFormField();

          }

          //this.apiService.getAllComuni().subscribe((result) => {
          this.modelDescrizioneComuneResidenza = new Comune();
          this.modelDescrizioneComuneResidenza.codice = res.comuneResidenza!;
          let comuneRes = this.listComune.find(x => x.codice == res.comuneResidenza!);
          this.modelDescrizioneComuneResidenza.descrizione = comuneRes?.descrizione!;
          //})

          // this.dettaglioAnagraficaForm.get('comuneResidenza')?.patchValue(res.comuneResidenza);
          this.dettaglioAnagraficaForm.get('comuneResidenza')?.enable();
          this.dettaglioAnagraficaForm.get('aslResidenza')?.patchValue(res.aslResidenza);
          this.dettaglioAnagraficaForm.get('aslResidenza')?.enable();
          this.dettaglioAnagraficaForm.get('distrettoResidenza')?.patchValue(res.distrettoResidenza);
          this.dettaglioAnagraficaForm.get('distrettoResidenza')?.enable();

          if (res.municipioResidenza) {

            await this.getMunicipiByDistrettoResidenza(res.distrettoResidenza!)
            // this.addOrKeepMunicipioResidenzaFormField()
            this.dettaglioAnagraficaForm.get('municipioResidenza')?.enable();
            // this.dettaglioAnagraficaForm.get('municipioResidenza')?.patchValue(res.municipioResidenza);

          }
          if (res.regioneResidenza == "998") {

            this.addOrKeepStatoResidenzaFormField();
            this.dettaglioAnagraficaForm.get('statoResidenza')?.patchValue(res.statoResidenza);
            this.dettaglioAnagraficaForm.get('statoResidenza')?.enable();

          }

        }

      }
      //   },
      //   error: (err) => {
      //     console.log(err)
      //   }
      // });

    }

  }

  emptyFormAnagraficaStruttura() {

    this.dettaglioAnagraficaForm.get('statoCivile')?.patchValue("");
    this.dettaglioAnagraficaForm.get('collocazioneSocioAmbientale')?.patchValue("");
    this.dettaglioAnagraficaForm.get('titoloDiStudio')?.patchValue("");
    this.dettaglioAnagraficaForm.get('attivita')?.patchValue("");
    this.dettaglioAnagraficaForm.get('familiarePaziente')?.patchValue("");
    this.dettaglioAnagraficaForm.get('invalidita')?.patchValue("");
    this.dettaglioAnagraficaForm.get('tutelaGiuridica')?.patchValue("");
    this.dettaglioAnagraficaForm.get('attualmenteSottoposto')?.patchValue("");
    this.dettaglioAnagraficaForm.get('precedentementeInternato')?.patchValue("");

  }

  disableNonEditableAnagraficaForm() {

    this.dettaglioAnagraficaForm.get('codiceFiscale')?.disable();
    this.dettaglioAnagraficaForm.get('dataNascita')?.disable();
    this.dataNascitaButtonDisable = true;
    this.dettaglioAnagraficaForm.get('cognome')?.disable();
    this.dettaglioAnagraficaForm.get('nome')?.disable();
    this.dettaglioAnagraficaForm.get('sesso')?.disable();
    this.dettaglioAnagraficaForm.get('descrizioneComuneNascita')?.disable();

    if (this.dettaglioAnagrafica?.cfCertificato === "1") {

      this.dettaglioAnagraficaForm.get('cittadinanza')?.disable();
      this.dettaglioAnagraficaForm.get('regioneResidenza')?.disable();
      this.dettaglioAnagraficaForm.get('aslResidenza')?.disable();
      this.dettaglioAnagraficaForm.get('comuneResidenza')?.disable();
      //this.dettaglioAnagraficaForm.get('distrettoResidenza')?.disable();
      //this.dettaglioAnagraficaForm.get('municipioResidenza')?.disable();

    } else {

      this.dettaglioAnagraficaForm.get('cittadinanza')?.enable();
      this.dettaglioAnagraficaForm.get('regioneResidenza')?.enable();
      this.dettaglioAnagraficaForm.get('aslResidenza')?.enable();
      this.dettaglioAnagraficaForm.get('comuneResidenza')?.enable();
      this.dettaglioAnagraficaForm.get('distrettoResidenza')?.enable();
      this.dettaglioAnagraficaForm.get('municipioResidenza')?.enable();

    }

  }

  public getRuolo(ruolo: String) {

    this.getUtente();

    if (this.utente) {

      if (this.utente.nomeRuolo == ruolo) {

        return true;

      }

    }

    return false;

  }

  public getUtente() {

    var usr = sessionStorage.getItem("utente");

    if (usr != null && usr != undefined) {

      this.utente = JSON.parse(usr);

    }

  }

  auditAnagrafica: AnagraficaAudit[] = [];

  openAuditDialog(audit: any) {

    this.apiService.getAnagraficaAuditByIdAndStruttura(this.dettaglioAnagrafica?.id!!, this.sessionService.getSelectedStrutturaFromStorage()?.codice!).subscribe(res => {

      if (res) {

        this.auditAnagrafica = res;

      }

    });
    this.modalService.open(audit, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
      scrollable: true
    }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

      if (this.closeResult === 'Closed with: Back click') {
        this.back()

      }
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;


    });
  }

  private cfNotFound(cf: undefined) {
    this.dettaglioAnagrafica = null;
    this.messageDatiCertificati = "Codice fiscale non trovato in Azienda Sanitaria Unica Regionale inserire tutti i dati";
    this.toastService.showSuccess("Codice fiscale non trovato in Azienda Sanitaria Unica Regionale inserire tutti i dati")
    this.dettaglioAnagraficaForm.get('codiceFiscale')?.setValue(cf);
    this.dettaglioAnagraficaForm.get('codiceFiscale')?.enable();
    this.dettaglioAnagraficaForm.get('dataNascita')?.enable();
    this.dataNascitaButtonDisable = false;
    this.dettaglioAnagraficaForm.get('cognome')?.setValue("");
    this.dettaglioAnagraficaForm.get('cognome')?.enable();
    this.dettaglioAnagraficaForm.get('nome')?.setValue("");
    this.dettaglioAnagraficaForm.get('nome')?.enable();
    this.dettaglioAnagraficaForm.get('sesso')?.setValue("");
    this.dettaglioAnagraficaForm.get('sesso')?.enable();
    this.dettaglioAnagraficaForm.get('descrizioneComuneNascita')?.patchValue("");
    this.dettaglioAnagraficaForm.get('descrizioneComuneNascita')?.enable();
    this.dettaglioAnagraficaForm.get('cittadinanza')?.patchValue("");
    this.dettaglioAnagraficaForm.get('cittadinanza')?.enable();
    this.dettaglioAnagraficaForm.get('regioneResidenza')?.enable();
    this.dettaglioAnagraficaForm.get('regioneResidenza')?.patchValue("");
    this.dettaglioAnagraficaForm.get('comuneResidenza')?.patchValue("");
    this.dettaglioAnagraficaForm.get('comuneResidenza')?.enable();
    this.dettaglioAnagraficaForm.get('aslResidenza')?.patchValue("");
    this.dettaglioAnagraficaForm.get('aslResidenza')?.enable();
    this.dettaglioAnagraficaForm.get('distrettoResidenza')?.patchValue("");
    this.dettaglioAnagraficaForm.get('distrettoResidenza')?.enable();
    this.dettaglioAnagraficaForm.get('municipioResidenza')?.patchValue("");
    this.dettaglioAnagraficaForm.get('municipioResidenza')?.enable();
  }

  private checkIfCanDeleteAnagrafica(id: number, codStruttura: string) {

    this.apiService.checkIfCanDeleteAnagrafica(id!, codStruttura).subscribe(v => {
      this.canDelete = v?.canDelete;
      this.deleteWarningMessage += v?.contatti || v?.prestazioni || v?.interventi ? ' Attenzione !!! Verranno eliminati anche ' : '';
      this.deleteWarningMessage += v.contatti ? ' : ' + v.contatti + ' contatti' : '';
      this.deleteWarningMessage += v.prestazioni ? ' : ' + v.prestazioni + ' prestazioni' : '';
      this.deleteWarningMessage += v.interventi ? ' : ' + v.interventi + ' interventi' : '';
      this.deleteWarningMessage += v.diagnosi ? ' : ' + v.diagnosi + ' diagnosi' : '';
      this.deleteWarningMessage += v.presenze ? ' : ' + v.presenze + ' presenze' : '';

    });

  }

  clearFormInput(ssss: string) {
    this.dettaglioAnagraficaForm.get(ssss)?.patchValue("");
  }

  isAnagraficaStrutturaFieldsVisible() {

    if (this.getRuolo(this.OPERATORE_STRUTTURA)) {

      return true;

    } else {

      return false;

    }

  }

}

enum TabShowedState {
  ANAGRAFICA, CONTATTI
}




