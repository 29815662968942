<div class="container py-3">
  <!-- Ricerca -->
  <div class="card">
    <div class="card-header">
      <div class="title-3">Ricerca flussi in ingresso</div>
    </div>
    <div class="card-body">
      <form [formGroup]="ricercaForm">
        <div class="group-inline">
          <div class="form-group field-8">
            <label>Anno</label>
            <select class="form-control" formControlName="anno" name="anno">
              <option value="{{ currentYear }}" selected>
                {{ currentYear }}
              </option>

              <option *ngFor="let year of yearArray" value="{{ year }}">
                {{ year }}
              </option>
            </select>
          </div>
          <div class="form-group field-16">
            <label>Semestre</label>
            <select
              class="form-control"
              formControlName="periodo"
              name="periodo"
              tabindex="-98"
            >
              <option value="S1">Primo semestre</option>
              <option value="S2">Secondo semestre</option>
            </select>
          </div>
          <div class="form-group field-16">
            <label>Stato</label>
            <select
              class="form-control"
              formControlName="statoElaborazione"
              name="statoElaborazione"
              tabindex="-98"
            >
              <option value="">Seleziona stato</option>
              <option value="ELABORATO">Elaborato</option>
              <option value="NON_ELABORATO">Non elaborato</option>
            </select>
          </div>
          <div class="form-group field-8">
            <label>Struttura</label>
            <select
              class="form-control"
              formControlName="struttura"
              name="struttura"
            >
              <option value="">Seleziona Struttura</option>
              <option
                *ngFor="let struttura of strutture.content"
                value="{{ struttura.codStruttura }}"
                value=""
              >
                {{ struttura.denominazioneStruttura }}
              </option>
            </select>
          </div>
          <div class="groupo-inline">
            <div class="form-group field-16">
              <label>Attività</label>
              <select
                class="form-control"
                formControlName="attivita"
                name="attivita"
              >
                <option value="">Tutte</option>
                <option value="Territoriale">Territoriale</option>
                <option value="Semiresidenziale">Semiresidenziale</option>
              </select>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="card-footer">
      <div class="btn-toolbar">
        <div class="btn-group-left">
          <button
            class="btn btn-outline-secondary"
            (click)="resetFormRicerca()"
          >
            Pulisci
          </button>
        </div>
        <div class="btn-group-center"></div>
        <div class="btn-group-right">
          <button class="btn btn-secondary" (click)="getLotti()">Cerca</button>
        </div>
      </div>
    </div>
  </div>

  <!-- Risultati -->
  <div class="card mt-5">
    <div class="card-body">
      <div class="table-responsive">
        <div class="list-table table-even">
          <table class="table-colf table-lg">
            <thead>
              <tr>
                <th>Flusso</th>
                <th>Stato</th>
                <th>Struttura</th>
                <th>Data</th>
                <th>ID</th>
                <th>Attività</th>
                <th>Semestre</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="tr-hover"
                *ngFor="let lotto of pagina.content"
                (click)="selectLotto(lotto)"
              >
                <td class="td-hover" data-label="Flusso">SISM</td>
                <td class="td-hover" data-label="Flusso">
                  {{ lotto.descrizioneStato }}
                </td>
                <td class="td-hover" data-label="Struttura">
                  {{ lotto.struttura }}
                </td>
                <td class="td-hover" data-label="Data e ora">
                  {{ lotto.dataLotto }}
                </td>
                <td class="td-hover" data-label="ID">
                  {{ lotto.id }}
                </td>
                <td class="td-hover" data-label="Attività">
                  {{ lotto.tipoPrestazione }}
                </td>
                <td class="td-hover" data-label="Semestre">
                  <span *ngIf="lotto.semestreCompetenza == 'S1'"
                    >Primo semestre</span
                  >
                  <span *ngIf="lotto.semestreCompetenza == 'S2'"
                    >Secondo semestre</span
                  >
                </td>
              </tr>
              <tr *ngIf="pagina.content.length == 0">
                <td colspan="9">
                  <p class="text-center">Impostare un filtro di ricerca</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- PAGINATION -->
      <app-paginator
        [page]="pagina"
        (cambiaPaginaEvent)="cambiaPagina($event)"
      ></app-paginator>
    </div>
  </div>
</div>


