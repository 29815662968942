import {Asl, ContattoAudit} from './../../model/Models';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl, Validators, FormGroup, FormBuilder, ValidationErrors} from '@angular/forms';
import {ModalDismissReasons, NgbDateStruct, NgbModal, NgbTypeahead} from '@ng-bootstrap/ng-bootstrap';
import {ApiService} from '../../services/api.service';
import {
  Contatto,
  Page,
  Prestazione,
  DettaglioAnagrafica,
  RichiestaPrevalente,
  Inviante,
  Diagnosi,
  PrecedenteContatto,
  ModalitaConclusione,
  Comune,
  Intervento,
  StrutturaStr,
  Presenza,
  NotAvailableDatesContainer,
  ConfStato, DiagnosiEScale
} from '../../model/Models';
import {Router} from '@angular/router';
import {SessionService} from '../../services/session.service';
import {TheadColumn} from 'src/app/components/table/thead/thead-column';
import {TableSorter} from 'src/app/components/table/thead/table-sorter';
import {ToastService} from "../../services/toast.service";
import {Funzionalita} from "../../model/Funzionalita";
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {
  debounceTime,
  distinctUntilChanged,
  firstValueFrom,
  lastValueFrom,
  map,
  Observable,
  of,
  OperatorFunction,
  Subject, Subscription
} from 'rxjs';
import {emptyDiagnosiValidator} from 'src/app/util/codicefiscale.validators';
import {dataNonValidaValidator} from '../../util/codicefiscale.validators';
import {Utente} from '../../model/Models';
import {environment} from 'src/environments/environment';
import {CommonsUtil} from "../../util/CommonsUtil";
import {AnagraficaComponent} from "../anagrafica/anagrafica.component";
import {
  StoricoAnagraficaEditComponent
} from "../../components/storico-anagrafica-edit/storico-anagrafica-edit.component";

@Component({
  selector: 'app-contatto',
  templateUrl: './contatto.component.html',
  styleUrls: ['./contatto.component.css']
})
export class ContattoComponent implements OnInit, OnDestroy {


  minDate = {year: 1920, month: 1, day: 1};
  maxDate = {year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate()}

  aperturaMaxDate = {year: 2050, month: 1, day: 1}
  aperturaMinDate = {year: 1920, month: 1, day: 1}


  richiestaDataModel!: NgbDateStruct


  aperturaDataModel!: NgbDateStruct | null;
  minAperturaDateModel!: NgbDateStruct | null;


  minChiusuraDateModel: any
  chiusuraDataModel!: NgbDateStruct | null;

  contattoForm!: FormGroup

  contattoFormSubmitted = false

  contatto!: Contatto

  page: Page<Prestazione> = new Page<Prestazione>();

  anagraficaOK: boolean = false;

  theadColumns!: TheadColumn[];

  tableSorter: TableSorter = new TableSorter();

  anagrafica: DettaglioAnagrafica = new DettaglioAnagrafica();

  listRichiestePrevalenti: RichiestaPrevalente[] = []

  listInvianti: Inviante[] = []

  listPrecedentiContatti: PrecedenteContatto[] = []

  listDiagnosiApertura: Diagnosi[] = []

  listDiagnosiChiusura: Diagnosi[] = []

  listaModalitaConclusione: ModalitaConclusione[] = []

  closeResult = ''

  tabShowed?: TabShowedState = TabShowedState.CONTATTI;

  modelAperturaDiagnosi!: any

  modelChiusuraDiagnosi!: any

  aperturaFlag: string | null = null;

  mainUser!: Utente

  deleteVisible = false

  contattoApertoSetting: Contatto = new Contatto();

  contattoChiusoSetting: Contatto = new Contatto();

  contattiApertiSettings: Contatto[] = [];

  listContatti?: Contatto[]

  listPresenze: Presenza[] = []

  listOfContattiNotAvailableDates: string[] = []

  listOfDiagnosiEScaleNotAvailableDates: string[] = []

  listOfInterventiNotAvailableDates: string[] = []

  listOfPresenzeNotAvailableDates: string[] = []

  listOfPrestazioniNotAvailableDates: string[] = []

  notAvailableDatesContainer$?: NotAvailableDatesContainer

  dateCheck = environment.dateInsertCheck

  enableStatiAttuali: Array<number> = [1, 2, 4, 7, 9, 0];

  confStati: ConfStato[] = [];
  private aslList: Asl[] = [];


  @ViewChild('chiudiContatto', {static: true}) chiudiContattoModal: any;
  @ViewChild('sendemailsollecito', {static: true}) sendEmailSollecitoModal: any;

  @ViewChild('strAnaEdit') appStoricoAnagraficaEdit: StoricoAnagraficaEditComponent | undefined;


  //@ViewChild('anagraficapanel') anagraficaPanel: AnagraficaComponent | undefined;

  // for diagnosi typeahead
  @ViewChild('instance', {static: true}) instance: NgbTypeahead | undefined;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  formatter = (diagnosi: Diagnosi) => (diagnosi.codice != undefined ? diagnosi.codice!! + ' - ' + diagnosi.descrizione! : '')

  resultFormatter = (diagnosi: Diagnosi) => (diagnosi.codice != undefined ? diagnosi.codice!! + ' - ' + diagnosi.descrizione! : '');

  diagnosiSearch: OperatorFunction<string, readonly Diagnosi[]> = (textApertura$: Observable<string>) =>
    textApertura$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term: string) => term.length < 2 ? []
        : this.listDiagnosiApertura
          .filter(v =>
            v && v.descrizione && v.descrizione.toLowerCase().indexOf(term.toLowerCase()) > -1
            || v && v.codice && v.codice.toLowerCase().indexOf(term.toLowerCase()) > -1
          ))
    )


  @ViewChild('instanceChiusura', {static: true}) instanceChiusura: NgbTypeahead | undefined;
  focusChiusura$ = new Subject<string>();
  clickChiusura$ = new Subject<string>();

  chiusuraformatter = (diagnosi: Diagnosi) => (diagnosi.codice != '' ? diagnosi.codice!! + ' - ' + diagnosi.descrizione! : '');

  resultChiusuraformatter = (diagnosi: Diagnosi) => (diagnosi.codice != '' ? diagnosi.codice!! + ' - ' + diagnosi.descrizione! : '');

  diagnosiChiusuraSearch: OperatorFunction<string, readonly Diagnosi[]> = (textChiusura$: Observable<string>) =>
    textChiusura$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term: string) => term.length < 2 ? []
        : this.listDiagnosiChiusura
          .filter(v =>
            v && v.descrizione && v.descrizione.toLowerCase().indexOf(term.toLowerCase()) > -1
            || v && v.codice && v.codice.toLowerCase().indexOf(term.toLowerCase()) > -1
          ))
    )


  @BlockUI()
  blockUI!: NgBlockUI;
  public interventiError: boolean = false;
  public chiudiContattoMode: string | undefined;
  private subscribe1: Subscription | undefined;
  private subscribe2: Subscription | undefined;

  constructor(private apiService: ApiService,
              public toastService: ToastService,
              private sessionSevice: SessionService,
              private fb: FormBuilder,
              private router: Router,
              private modalService: NgbModal) {

  }

  public anagraficaSalvata(b: boolean) {
    this.anagraficaOK = b;
  }

  ngOnInit(): void {
    this.loadUser()

    this.loadTab()

    this.loadAnagrafica()

    this.loadContatto()
    //this.loadListOfNotavailableDatesForContatti()
    //alert("init");

    this.getAllPrecedentiContatti();
    this.getAllModalitaConclusione();
    this.getRichiestePrevalenti()
    this.getDiagnosi();

    this.loadTable(this.contatto)

    this.initContattoForm()

    if (!this.isFormTerritoriale()) {
      this.getInviantiSemiResidenziali()
      this.aperturaFlag = "1"
    } else {
      // this.removeSemiresidencaleFormFields()
      this.getInviantiTerritoriali()
    }

    // se contatto non e nuovo puo avere figli
    if (!this.contattoIsNew(this.contatto)) {
      this.addChiusuraFormFields()

      this.loadPresenze(this.contatto)
    } else {
      this.removeChiusuraFormFields()
    }

    this.loadPrestazioni(this.contatto.id!!, 0, this.page.size, null, null)


    this.contattoForm.markAsPristine()

    this.setValidatorsBasedOnFlag(this.aperturaFlag)

    this.observeForDataRefresh()

    this.observeStrutturaChange();
    this.observeDataChiusuraChange();
    this.dataChiusuraExist();
    this.limitMaxDate();
    this.loadContatti();

    this.apiService.getAllConfStato().subscribe(data => {
      if (data) {
        this.confStati = data;
      }
    })
    this.apiService.getAllAsl().subscribe(data => {
      for (let item of data) {
        if (item.codiceAzienda != "98" && item.codiceAzienda != "098" && item.codiceAzienda != "998" && item.codiceAzienda != "999") {
          this.aslList.push(item);
        }
      }
    });
    this.loadHasOpenContattiStatus(this.anagrafica);
  }

  observeDataChiusuraChange() {
    this.contattoForm.get('dataChiusura')?.valueChanges.subscribe(date => {
      if (this.contattoForm.get('dataChiusura')?.value == null) {
        this.contattoForm.get('modalitaConclusione')?.removeValidators([Validators.required])
        this.contattoForm.get('diagnosiChiusura')?.removeValidators([Validators.required])
      } else {
        this.contattoForm.get('modalitaConclusione')?.addValidators([Validators.required])
        this.contattoForm.get('diagnosiChiusura')?.addValidators([Validators.required])

      }
      this.contattoForm.controls['modalitaConclusione'].updateValueAndValidity();
      this.contattoForm.controls['diagnosiChiusura'].updateValueAndValidity();
      //      this.contattoForm.get('modalitaConclusione')?.patchValue(this.contattoForm.get('modalitaConclusione')?.value);
    })
  }

  observeStrutturaChange() {
    this.sessionSevice.strutturaChanged.subscribe(res => {
      if (res) {
        this.router.navigate(['ricerca-anagrafica'])
      }
    })
  }

  loadAnagrafica() {

    this.sessionSevice.selectedAnagrafica.subscribe(res => {
      if (res && res != "" && res != null && res != undefined) {
        this.anagrafica = JSON.parse(res);
        // this.loadListOfNotavailableDatesForContatti()

        if (this.anagrafica && this.anagrafica.id) {
          this.apiService.getContattoApertoSetting(this.anagrafica!.id!,
            (this.sessionSevice.isSelectedStrutturaFromStorageTerritoriale() ? "Territoriale" : "Semiresidenziale"))
            .subscribe(res => {
              this.contattoApertoSetting = res
            });
          this.apiService.getContattiApertiSetting(this.anagrafica!.id!,
            (this.sessionSevice.isSelectedStrutturaFromStorageTerritoriale() ? "Territoriale" : "Semiresidenziale"))
            .subscribe(res => {
              // this.contattoApertoSetting = res
              this.contattiApertiSettings = res}
            );
          this.apiService.getContattoChiusoSetting(this.anagrafica!.id!,
            (this.sessionSevice.isSelectedStrutturaFromStorageTerritoriale() ? "Territoriale" : "Semiresidenziale"))
            .subscribe(res => {
              this.contattoChiusoSetting = res
            });
        } else {
          this.leaveEmptyPage()
        }
      } else {
        this.leaveEmptyPage()
      }

    })
  }

  loadTable(contatto: Contatto) {
    let res: TheadColumn[] = [];
    res.push(new TheadColumn('', 'Alert'));
    res.push(new TheadColumn('id', 'IDPrestazione'));
    res.push(new TheadColumn('codiceStrutturaOperatoreInserimento', 'Codice - Denom. Struttura - ASL'));
    res.push(new TheadColumn('contatto.dataApertura', 'Data apertura contatto'));
    // res.push(new TheadColumn('nrInseritoPrestazione', 'Nr°'));
    res.push(new TheadColumn('dataPrestazione', 'Data prestazione'));
    res.push(new TheadColumn('tipo', 'Tipo prestazione'));
    // if (this.isTipoPrestazioneTerritoriale()) {
    //   res.push(new TheadColumn('modalitaDiErogazione', 'Modalità erogazione'));
    // }
    res.push(new TheadColumn('statoAttuale', 'Stato trasmissione'));


    this.theadColumns = res;

    // if (this.isContattoSemiResideziale(contatto.tipoPrestazione!!)) {
    //   this.theadColumns = this.loadTableResidenziale()
    // } else {
    //   this.theadColumns = this.loadTableTeritoriale()
    // }
  }

  loadContatto() {
    this.subscribe2 = this.sessionSevice.selectedContatto.subscribe(res => {
      if (res != undefined && res != null) {
        this.contatto = res
        if (this.contattoIsNew(this.contatto)) {
          //this.tabShowed = TabShowedState.ANAGRAFICA;

          this.contatto.tipoPrestazione = this.sessionSevice.isSelectedStrutturaFromStorageTerritoriale() ? "Territoriale" : "Semiresidenziale";
          this.aperturaFlag = null;
        } else {
          this.aperturaFlag = this.contatto.aperturaFlag!!
          this.loadListOfNotavailableDatesForContatti();
        }

      } else {
        this.leaveEmptyPage()
      }
    })

    if (!this.contattoIsNew(this.contatto)) {

      this.apiService.selectContattoById(this.contatto.id!!).subscribe({
        next: (res) => {
          this.contatto = res

          this.sessionSevice.setSelectedContatto(this.contatto)

          // if(this.mustDisableForm()){

          //   this.disableForm();

          // }

        },
        error: (err) => {
          console.error(err);

        }
      })
    }

  }

  descrizioneStato(stato: number) {

    for (let confStato of this.confStati) {

      if (stato == 5 && !this.getRuolo(this.OPERATORE_REGIONE))
        stato = 3;

      if (stato == confStato.id) {

        return confStato.descrizione;

      }

    }
    return "Stato non trovato";
    // return this.apiService.getConfStatoById(stato).subscribe(data =>{
    //    return data.descrizione
    //  })
    // return new CommonsUtil().descrizioneStato(stato);
  }

  mustDisableForm() {

    if (this.contatto.statoAttuale.id && this.contatto.statoAttuale.id >= 0) {

      return this.enableStatiAttuali.indexOf(this.contatto.statoAttuale.id) == -1

    }

    return false;

  }

  mustDisableCancelBtn() {

    if (this.contatto.statoAttuale.id && this.contatto.statoAttuale.id > 0) {

      let statiAttuali: Array<number> = [1, 2, 4, 7, 9];
      return statiAttuali.indexOf(this.contatto.statoAttuale.id) == -1

    }

    return true;

  }

  canClose() {
    if (this.contatto.id) {
      if (this.contatto.dataChiusura === null || this.isNotContattoValidato()) {
        return true;
      } else if (this.contatto.statoAttuale.id == 3 || this.contatto.statoAttuale.id == 5 || this.contatto.statoAttuale.id == 6 || this.contatto.statoAttuale.id == 8) {
        return false;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  canEdit() {
    if (this.contatto.id) {
      if (this.contatto.statoAttuale.id == 3 || this.contatto.statoAttuale.id == 5 || this.contatto.statoAttuale.id == 6 || this.contatto.statoAttuale.id == 8) {
        return false;
      } else if (this.contatto.dataChiusura === null || this.isNotContattoValidato()) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  isNotContattoValidato(): boolean {

    if (this.contatto.statoAttuale && this.contatto.statoAttuale.id >= 0) {

      return this.enableStatiAttuali.indexOf(this.contatto.statoAttuale.id) != -1

    } else {

      return false

    }

  }

  disableForm() {

    // this.contattoForm.get('dataChiusura')?.disable();
    // this.contattoForm.get('modalitaConclusione')?.disable();
    this.contattoForm.get('diagnosiChiusura')?.disable();
    this.contattoForm.get('dataRichiesta')?.disable();
    this.contattoForm.get('richiestaPrev')?.disable();
    this.contattoForm.get('aperturaFlag')?.disable();
    // this.contattoForm.get('dataApertura')?.disable();
    this.contattoForm.get('diagnosiApertura')?.disable();
    this.contattoForm.get('precedentiContatti')?.disable();
    this.contattoForm.get('inviante')?.disable();

    if (!this.isFormTerritoriale()) {
      this.contattoForm.get('interventoRiabilitativi')?.disable();
      this.contattoForm.get('interventoInserimentiLavorativi')?.disable();
      this.contattoForm.get('interventoInterveniSociali')?.disable();
      this.contattoForm.get('interventoColloquiPsichiatrici')?.disable();
      this.contattoForm.get('interventoCoinvolgimento')?.disable();
      this.contattoForm.get('altro')?.disable();
      this.contattoForm.get('interventoEffettuatoRiabilitativi')?.disable();
      this.contattoForm.get('interventoEffettuatoLavorativi')?.disable();
      this.contattoForm.get('interventoEffettuatoInterveniSociali')?.disable();
      this.contattoForm.get('interventoEffettuatoColloquiPsichiatrici')?.disable();
      this.contattoForm.get('interventoEffettuatoCoinvolgimento')?.disable();
      this.contattoForm.get('interventoEffettuatoAltro')?.disable();
    }
  }

  loadUser() {
    this.mainUser = this.sessionSevice.getUtenteFromStorage()!!
  }

  async loadListOfNotavailableDatesForContatti() {
    if (this.dateCheck) {
      let formType = ""
      if (this.isFormTerritoriale()) {
        formType = 'territoriale'
      } else {
        formType = "semiresidenziale"
      }

      if (this.contatto) {
        if (this.contatto.id) {
          this.notAvailableDatesContainer$ = await (this.apiService.getAllContattoNotAvailabeDates(formType,
            this.contatto.id))
          await this.setUnavailableDates(this.notAvailableDatesContainer$)
        }
      } else {
        console.error(this.contatto)
        console.error('questa cosa non dovrebbe mai accadere');
        //alert("Attrnzione si è verificato un errore sul server");
      }
    } else {
      console.log("date check false ..dev mode");
    }
  }


  async setUnavailableDates(notAvailableDatesContainer$: NotAvailableDatesContainer) {

    notAvailableDatesContainer$.contattiDates?.forEach(dt => {

      this.listOfContattiNotAvailableDates.push(JSON.stringify(this.sessionSevice.convertToDateStruct(new Date(dt))))

    })

    this.sessionSevice.setContattiDataNotAvailableList(this.listOfContattiNotAvailableDates)

    notAvailableDatesContainer$.interventiDates?.forEach(dt => {

      this.listOfInterventiNotAvailableDates.push(JSON.stringify(this.sessionSevice.convertToDateStruct(new Date(dt))))

    })

    this.sessionSevice.setInterventiDataNotAvailableList(this.listOfInterventiNotAvailableDates)

    notAvailableDatesContainer$.presenzeDates?.forEach(dt => {

      this.listOfPresenzeNotAvailableDates.push(JSON.stringify(this.sessionSevice.convertToDateStruct(new Date(dt))))

    })

    this.sessionSevice.setPresenzeDataNotAvailableList(this.listOfPresenzeNotAvailableDates)

    notAvailableDatesContainer$.diagnosiEScaleDates?.forEach(dt => {

      this.listOfDiagnosiEScaleNotAvailableDates.push(JSON.stringify(this.sessionSevice.convertToDateStruct(new Date(dt))))

    })

    this.sessionSevice.setDIagnosiEscaleDataNotAvailableList(this.listOfDiagnosiEScaleNotAvailableDates)

    notAvailableDatesContainer$.prestazioniDates?.forEach(dt => {

      this.listOfPrestazioniNotAvailableDates.push(JSON.stringify(this.sessionSevice.convertToDateStruct(new Date(dt))))

    })
    this.sessionSevice.setPrestazioniDataNotAvailableList(this.listOfPrestazioniNotAvailableDates)
    await this.delay(1000);
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }
  isContattoSemiResideziale(tipoPrestazione: string): boolean {
    if (tipoPrestazione === "Semiresidenziale") {
      return true
    } else {
      return false
    }
  }

  // private loadTableTeritoriale(): TheadColumn[] {
  //   let res: TheadColumn[] = [];
  //   res.push(new TheadColumn('id', 'ID'));
  //   res.push(new TheadColumn('nrInseritoPrestazione', 'Nr°'));
  //   res.push(new TheadColumn('dataPrestazione', 'Data'));
  //   res.push(new TheadColumn('tipo', 'Tipo'));
  //   res.push(new TheadColumn('modalitaDiErogazione', 'Modalità erogazione'));
  //   res.push(new TheadColumn('statoTrasmissione', 'Stato'));
  //   res.push(new TheadColumn('', 'Alert'));
  //
  //   return res;
  // }
  //
  // private loadTableResidenziale(): TheadColumn[] {
  //   let res: TheadColumn[] = [];
  //   res.push(new TheadColumn('id', 'ID'));
  //   res.push(new TheadColumn('nrInseritoPrestazione', 'Nr°'));
  //   res.push(new TheadColumn('dataPrestazione', 'Data'));
  //   res.push(new TheadColumn('tipo', 'Tipo'));
  //   res.push(new TheadColumn('statoTrasmissione', 'Stato'));
  //   res.push(new TheadColumn('', 'Alert'));
  //
  //   return res;
  // }

  leaveEmptyPage() {
    this.router.navigate(['ricerca-anagrafica'])
  }

  getTitleName(): string {
    if (this.anagrafica.nome === "") {
      return "Nuovo Contatto"
    } else {
      return "" + this.anagrafica.cognome + " " + this.anagrafica.nome + ' (CF: ' + this.anagrafica.codiceFiscale + ")"
    }
  }


  initContattoForm() {
    if (!this.contattoIsNew(this.contatto)) {
      this.setDateModels(this.contatto)
    }

    // set apertura diagnosi
    this.modelAperturaDiagnosi = new Diagnosi()
    this.modelAperturaDiagnosi.codice = this.contatto.codiceDiagnosiApertura;
    this.modelAperturaDiagnosi.descrizione = this.contatto.descrizioneDiagnosiApertura

    this.contattoForm = this.fb.group({
      dataRichiesta: new FormControl("", [Validators.required, dataNonValidaValidator()]),
      //      tipoPrestazione: new FormControl(this.contatto.tipoPrestazione, [Validators.required]),
      richiestaPrev: new FormControl(this.contatto.richiestaPrev, [Validators.required]),
      aperturaFlag: new FormControl(this.aperturaFlag,),
      dataApertura: new FormControl([Validators.required, dataNonValidaValidator()]),
      diagnosiApertura: new FormControl("", [Validators.required, emptyDiagnosiValidator()]),
      precedentiContatti: new FormControl(this.contatto.precedentiContatti, [Validators.required]),
      inviante: new FormControl(this.contatto.inviante, [Validators.required]),
      // dataChiusura: new FormControl(this.chiusuraDataModel, [Validators.required]),
      // diagnosiChiusura: new FormControl(this.contatto.codiceDiagnosiChiusura, [emptyChiusuraDiagnosiValidator()]),
    });

    if (this.isFormTerritoriale()) {
      this.contattoForm.controls['aperturaFlag'].addValidators([Validators.required])
    } else {
      this.removeRichiestaPrevFormField()
      this.addSemiresidenzialeFormFields()
    }

    this.observeFormChanges()
  }


  setDateModels(contatto: Contatto) {
    let richiestaData = new Date(contatto.dataRichiesta!!)
    this.richiestaDataModel = this.sessionSevice.convertToDateStruct(richiestaData)!!

    if (contatto.dataApertura != null) {
      let apeData = new Date(contatto.dataApertura!!)
      this.aperturaDataModel = this.sessionSevice.convertToDateStruct(apeData)!!
    }

    //    this.richiestaDataModel = this.sessionSevice.convertToDateStruct(new Date())!!
    //    this.aperturaDataModel = this.sessionSevice.convertToDateStruct(new Date())!!

    if (contatto.dataChiusura == null) {

      this.chiusuraDataModel = null;

      // this.chiusuraDataModel = this.sessionSevice.convertToDateStruct(new Date())!!
    } else {

      this.chiusuraDataModel = this.sessionSevice.convertToDateStruct(new Date(contatto.dataChiusura))!!

    }

  }

  observeFormChanges() {

    this.contattoForm.valueChanges.forEach(val => {

      let obj = val

      if (!this.isFormTerritoriale()) {


        //per pi altro
        if (this.proggetoInterventiBlockIsFalse(obj.interventoRiabilitativi, obj.interventoInserimentiLavorativi,
          obj.interventoInterveniSociali, obj.interventoColloquiPsichiatrici, obj.interventoCoinvolgimento)) {

          this.contattoForm.controls['altro']?.addValidators([Validators.required])
          this.contattoForm.controls['altro']?.updateValueAndValidity({onlySelf: true, emitEvent: false})

        } else {
          this.contattoForm.controls['altro']?.removeValidators([Validators.required])
          this.contattoForm.controls['altro']?.updateValueAndValidity({onlySelf: true, emitEvent: false})
          // this.contattoForm.updateValueAndValidity({onlySelf:true})
        }


        // makes ie section obligattory
        this.setIeSectionValidators(obj.dataChiusura)

        // per ie altro obligatory
        if (this.interveniEffetuatiBlockIsFalse(obj.interventoEffettuatoRiabilitativi, obj.interventoEffettuatoLavorativi,
          obj.interventoEffettuatoInterveniSociali, obj.interventoEffettuatoColloquiPsichiatrici,
          obj.interventoEffettuatoCoinvolgimento)) {

          this.contattoForm.controls['interventoEffettuatoAltro']?.setValidators([Validators.required])
          this.contattoForm.get('interventoEffettuatoAltro')?.updateValueAndValidity({onlySelf: true})

        } else {
          this.contattoForm.get('interventoEffettuatoAltro')?.removeValidators([Validators.required])
          this.contattoForm.get('interventoEffettuatoAltro')?.updateValueAndValidity({onlySelf: true})

        }
        ;

        this.setValidatorsBasedOnFlag(obj.aperturaFlag)

      } else {
        this.setValidatorsBasedOnFlag(obj.aperturaFlag)
      }


    })


    this.contattoForm.get('dataRichiesta')?.valueChanges.subscribe(date => {

      this.limitAperturaDateByRichiestaDate(date)
    })

    this.contattoForm.get('dataApertura')?.valueChanges.subscribe(date => {
      if (date) {
        this.limitChiusuraDateByAperturaDate(date)
      }

    })

  }


  limitAperturaDateByRichiestaDate(dataRichiesta: NgbDateStruct) {

    switch (dataRichiesta) {

      case undefined:

        break;
      default:
        // apertura by data richiesta
        this.minAperturaDateModel = {year: dataRichiesta!!.year, month: dataRichiesta!!.month, day: dataRichiesta!!.day}
        break;
    }


  }


  limitChiusuraDateByAperturaDate(dataApertura: NgbDateStruct) {

    switch (dataApertura) {
      case undefined:
        break;
      default:
        // chiusura date by apertura date
        this.minChiusuraDateModel = {year: dataApertura!!.year, month: dataApertura!!.month, day: dataApertura!!.day}

        break;

    }


  }

  proggetoInterventiBlockIsFalse(p1: string, p2: string, p3: string, p4: string, p5: string,) {

    if (p1 == "false" && p2 == "false" && p3 == "false" && p4 == "false" && p5 == "false") {

      return true

    } else {
      return false
    }

  }

  interveniEffetuatiBlockIsFalse(p1: string, p2: string, p3: string, p4: string, p5: string,) {

    if (p1 == "false" && p2 == "false" && p3 == "false" && p4 == "false" && p5 == "false") {

      return true

    } else {
      return false
    }

  }


  addChiusuraFormFields() {
    if (this.contatto.codiceDiagnosiChiusura != null && this.contatto.descrizioneDiagnosiChiusura != null) {
      this.modelChiusuraDiagnosi = new Diagnosi()
      this.modelChiusuraDiagnosi.codice = this.contatto.codiceDiagnosiChiusura;
      this.modelChiusuraDiagnosi.descrizione = this.contatto.descrizioneDiagnosiChiusura
    } else {
      this.modelChiusuraDiagnosi = null;
    }

    this.contattoForm.addControl("dataChiusura", new FormControl(this.chiusuraDataModel, [Validators.required]))
    this.contattoForm.addControl('diagnosiChiusura', new FormControl(this.contatto.codiceDiagnosiChiusura))
    this.contattoForm.addControl("modalitaConclusione", new FormControl(this.contatto.modalitaConclusione))

  }

  removeChiusuraFormFields() {

    this.contattoForm.removeControl("dataChiusura")
    this.contattoForm.removeControl('diagnosiChiusura')
    this.contattoForm.removeControl("modalitaConclusione")

  }

  removeRichiestaPrevFormField() {

    this.contattoForm.removeControl("richiestaPrev")
  }

  addSemiresidenzialeFormFields() {

    this.contattoForm.addControl("interventoRiabilitativi", new FormControl(this.setValue(this.contatto.interventoRiabilitativi), [Validators.required]))
    this.contattoForm.addControl("interventoInserimentiLavorativi", new FormControl(this.setValue(this.contatto.interventoInserimentiLavorativi), [Validators.required]))
    this.contattoForm.addControl("interventoInterveniSociali", new FormControl(this.setValue(this.contatto.interventoInterveniSociali), [Validators.required]))
    this.contattoForm.addControl("interventoColloquiPsichiatrici", new FormControl(this.setValue(this.contatto.interventoColloquiPsichiatrici), [Validators.required]))
    this.contattoForm.addControl("interventoCoinvolgimento", new FormControl(this.setValue(this.contatto.interventoCoinvolgimento), [Validators.required]))
    this.contattoForm.addControl("altro", new FormControl(this.contatto.altro))

    this.contattoForm.addControl("interventoEffettuatoRiabilitativi", new FormControl(this.setValue(this.contatto.interventoEffettuatoRiabilitativi)))
    this.contattoForm.addControl("interventoEffettuatoLavorativi", new FormControl(this.setValue(this.contatto.interventoEffettuatoLavorativi)))
    this.contattoForm.addControl("interventoEffettuatoInterveniSociali", new FormControl(this.setValue(this.contatto.interventoEffettuatoInterveniSociali)))
    this.contattoForm.addControl("interventoEffettuatoColloquiPsichiatrici", new FormControl(this.setValue(this.contatto.interventoEffettuatoColloquiPsichiatrici)))
    this.contattoForm.addControl("interventoEffettuatoCoinvolgimento", new FormControl(this.setValue(this.contatto.interventoEffettuatoCoinvolgimento)))
    this.contattoForm.addControl("interventoEffettuatoAltro", new FormControl(this.contatto.interventoEffettuatoAltro))

  }


  setIeSectionValidators(date: NgbDateStruct) {


    if (date != null || date != undefined) {

      this.contattoForm.controls["interventoEffettuatoRiabilitativi"].addValidators([Validators.required])
      this.contattoForm.controls["interventoEffettuatoRiabilitativi"].updateValueAndValidity({onlySelf: true})

      this.contattoForm.controls["interventoEffettuatoLavorativi"].addValidators([Validators.required])
      this.contattoForm.controls["interventoEffettuatoLavorativi"].updateValueAndValidity({onlySelf: true})

      this.contattoForm.controls["interventoEffettuatoInterveniSociali"].addValidators([Validators.required])
      this.contattoForm.controls["interventoEffettuatoInterveniSociali"].updateValueAndValidity({onlySelf: true})

      this.contattoForm.controls["interventoEffettuatoColloquiPsichiatrici"].addValidators([Validators.required])
      this.contattoForm.controls["interventoEffettuatoColloquiPsichiatrici"].updateValueAndValidity({onlySelf: true})

      this.contattoForm.controls["interventoEffettuatoCoinvolgimento"].addValidators([Validators.required])
      this.contattoForm.controls["interventoEffettuatoCoinvolgimento"].updateValueAndValidity({onlySelf: true})


    } else {

      this.contattoForm.controls["interventoEffettuatoRiabilitativi"].removeValidators([Validators.required])
      this.contattoForm.controls["interventoEffettuatoRiabilitativi"].updateValueAndValidity({onlySelf: true})

      this.contattoForm.controls["interventoEffettuatoLavorativi"].removeValidators([Validators.required])
      this.contattoForm.controls["interventoEffettuatoLavorativi"].updateValueAndValidity({onlySelf: true})

      this.contattoForm.controls["interventoEffettuatoInterveniSociali"].removeValidators([Validators.required])
      this.contattoForm.controls["interventoEffettuatoInterveniSociali"].updateValueAndValidity({onlySelf: true})

      this.contattoForm.controls["interventoEffettuatoColloquiPsichiatrici"].removeValidators([Validators.required])
      this.contattoForm.controls["interventoEffettuatoColloquiPsichiatrici"].updateValueAndValidity({onlySelf: true})

      this.contattoForm.controls["interventoEffettuatoCoinvolgimento"].removeValidators([Validators.required])
      this.contattoForm.controls["interventoEffettuatoCoinvolgimento"].updateValueAndValidity({onlySelf: true})


    }
  }

  setValue(value: any) {

    if (value === false) {
      return "false"
    } else if (value === true) {
      return "true"
    } else if (value === null || value === undefined) {
      return ""
    } else {
      return undefined
    }

  }

  isFormTerritoriale(): boolean {
    return this.isTipoPrestazioneTerritoriale();
    //    return this.sessionSevice.isSelectedStrutturaFromStorageTerritoriale()
  }

  removePiSectionFormFields() {

    this.contattoForm.removeControl("interventoRiabilitativi")
    this.contattoForm.removeControl("interventoInserimentiLavorativi")
    this.contattoForm.removeControl("interventoInterveniSociali")
    this.contattoForm.removeControl("interventoColloquiPsichiatrici")
    this.contattoForm.removeControl("interventoCoinvolgimento")
    this.contattoForm.removeControl("altro")

  }

  removeIeSectionFormFields() {


    this.contattoForm.removeControl("interventoEffettuatoRiabilitativi")
    this.contattoForm.removeControl("interventoEffettuatoLavorativi")
    this.contattoForm.removeControl("interventoEffettuatoInterveniSociali")
    this.contattoForm.removeControl("interventoEffettuatoColloquiPsichiatrici")
    this.contattoForm.removeControl("interventoEffettuatoCoinvolgimento")
    this.contattoForm.removeControl("interventoEffettuatoAltro")


  }

  tabInterventiVisible() {
    return this.isTipoPrestazioneTerritoriale();
    //    return this.sessionSevice.isSelectedStrutturaFromStorageTerritoriale();
  }

  tabDiagnosiVisible() {
    return this.isTipoPrestazioneTerritoriale();
    //    return this.sessionSevice.isSelectedStrutturaFromStorageTerritoriale();
  }

  tabPrestazioniAccessible() {
    // this.dettaglioAnagrafica.id != null;
    return this.contatto.id != null
  }


  getInsertedValues() {
    let contatto = new Contatto()

    if (this.contatto.id != null) {
      contatto.id = this.contatto.id;
    }
    contatto.dataRichiesta = this.sessionSevice.toNativeDate(this.richiestaDataModel)!!
    contatto.tipoPrestazione = this.sessionSevice.isSelectedStrutturaFromStorageTerritoriale() ? "Territoriale" : "Semiresidenziale";
    //this.contattoForm.controls['tipoPrestazione'].value

    if (this.contattoForm.contains("richiestaPrev")) {
      contatto.richiestaPrev = this.contattoForm.controls['richiestaPrev'].value
    }

    if (this.aperturaFlag != null) {

      contatto.aperturaFlag = this.aperturaFlag!
    }


    contatto.dataApertura = this.sessionSevice.toNativeDate(this.aperturaDataModel)!!

    if (this.modelAperturaDiagnosi != null) {
      contatto.codiceDiagnosiApertura = this.modelAperturaDiagnosi.codice
      contatto.descrizioneDiagnosiApertura = this.modelAperturaDiagnosi.descrizione
    }
    if (this.confermadiagnosichiusuraterritoriale === "true") {
      contatto.codiceDiagnosiChiusura = this.lastDiagnosiEScale.diagnosi;
      contatto.descrizioneDiagnosiChiusura = this.lastDiagnosiEScale.descrizioneDiagnosi;
    }
    contatto.precedentiContatti = this.contattoForm.controls['precedentiContatti'].value
    contatto.inviante = this.contattoForm.controls['inviante'].value

    if (this.isContattoSemiResideziale(this.contatto.tipoPrestazione!!)) {

      contatto.interventoRiabilitativi = this.contattoForm.controls['interventoRiabilitativi'].value
      contatto.interventoInserimentiLavorativi = this.contattoForm.controls['interventoInserimentiLavorativi'].value
      contatto.interventoInterveniSociali = this.contattoForm.controls['interventoInterveniSociali'].value
      contatto.interventoColloquiPsichiatrici = this.contattoForm.controls['interventoColloquiPsichiatrici'].value
      contatto.interventoCoinvolgimento = this.contattoForm.controls['interventoCoinvolgimento'].value
      contatto.altro = this.contattoForm.controls['altro'].value

    }
    if (!this.isContattoSemiResideziale(this.contatto.tipoPrestazione!) && this.contattoIsNew(this.contatto) && this.aperturaFlag === "1") {
      contatto.codiceDiagnosiApertura = this.modelAperturaDiagnosi.codice
      contatto.descrizioneDiagnosiApertura = this.modelAperturaDiagnosi.descrizione
    }
    if (!this.contattoIsNew(this.contatto)) {


      contatto.dataChiusura = this.sessionSevice.toNativeDate(this.chiusuraDataModel)!!
      if (this.modelChiusuraDiagnosi != undefined) {
        contatto.codiceDiagnosiChiusura = this.modelChiusuraDiagnosi.codice
        contatto.descrizioneDiagnosiChiusura = this.modelChiusuraDiagnosi.descrizione

      }


      contatto.modalitaConclusione = this.contattoForm.controls['modalitaConclusione'].value
    }


    if (this.isContattoSemiResideziale(this.contatto.tipoPrestazione!!)) {
      if (this.dataChiusuraExist()) {
        contatto.interventoEffettuatoRiabilitativi = this.contattoForm.controls['interventoEffettuatoRiabilitativi'].value
        contatto.interventoEffettuatoLavorativi = this.contattoForm.controls['interventoEffettuatoLavorativi'].value
        contatto.interventoEffettuatoInterveniSociali = this.contattoForm.controls['interventoEffettuatoInterveniSociali'].value
        contatto.interventoEffettuatoColloquiPsichiatrici = this.contattoForm.controls['interventoEffettuatoColloquiPsichiatrici'].value
        contatto.interventoEffettuatoCoinvolgimento = this.contattoForm.controls['interventoEffettuatoCoinvolgimento'].value
        contatto.interventoEffettuatoAltro = this.contattoForm.controls['interventoEffettuatoAltro'].value
      } else {
        contatto.interventoEffettuatoRiabilitativi = null
        contatto.interventoEffettuatoLavorativi = null
        contatto.interventoEffettuatoInterveniSociali = null
        contatto.interventoEffettuatoColloquiPsichiatrici = null
        contatto.interventoEffettuatoCoinvolgimento = null
        contatto.interventoEffettuatoAltro = null
      }
    }

    contatto.anagrafica = this.anagrafica

    return contatto

  }


  contattoIsNew(contatto: Contatto) {

    return contatto.id == null || contatto.id == undefined
  }

  /**
   * Ottiene la max data tra interventi, presenze, prestazioni e diagnosi per il confronto con la data chiusura del contatto
   * @returns
   */
  checkDateForCloseContact(): Date {

    let listInter = this.contatto.interventiList;
    let listPresenze = this.contatto.presenzaList;
    let listPrestazioni = this.contatto.prestazioniList;
    let listDiagnosi = this.contatto.diagnosiEScaleList;
    let dateList = new Array();

    if (listInter != null && listInter != undefined && listInter.length > 0) {

      let maxIntervallData = listInter.reduce((a, b) => {

        return a.data! > b.data! ? a : b;

      });

      dateList.push(maxIntervallData.data);

    }

    if (listPresenze != null && listPresenze != undefined && listPresenze.length > 0) {

      let maxPresenzeData = listPresenze.reduce((a, b) => {

        return a.dataPresenza! > b.dataPresenza! ? a : b;

      });

      dateList.push(maxPresenzeData.dataPresenza);

    }

    if (listPrestazioni != null && listPrestazioni != undefined && listPrestazioni.length > 0) {

      let maxPrestazioniData = listPrestazioni.reduce((a, b) => {

        return a.dataPrestazione! > b.dataPrestazione! ? a : b;
      });

      dateList.push(maxPrestazioniData.dataPrestazione);

    }

    if (listDiagnosi != null && listDiagnosi != undefined && listDiagnosi.length > 0) {

      let maxDiagnosiData = listDiagnosi.reduce((a, b) => {

        return a.data! > b.data! ? a : b;

      });

      dateList.push(maxDiagnosiData.data);

    }

    let maxData = dateList.reduce((a, b) => {

      return a > b ? a : b;

    });

    return maxData;

  }

  checkDateForOpenContact(): Date | null {

    let listInter = this.contatto.interventiList;
    let listPresenze = this.contatto.presenzaList;
    let listPrestazioni = this.contatto.prestazioniList;
    let listDiagnosi = this.contatto.diagnosiEScaleList;
    let dateList = new Array();

    if (listInter != null && listInter != undefined && listInter.length > 0) {

      let minIntervallData = listInter.reduce((a, b) => {

        return a.data! < b.data! ? a : b;

      });

      dateList.push(minIntervallData.data);

    }

    if (listPresenze != null && listPresenze != undefined && listPresenze.length > 0) {

      let minPresenzeData = listPresenze.reduce((a, b) => {

        return a.dataPresenza! < b.dataPresenza! ? a : b;

      });

      dateList.push(minPresenzeData.dataPresenza);

    }

    if (listPrestazioni != null && listPrestazioni != undefined && listPrestazioni.length > 0) {

      let minPrestazioniData = listPrestazioni.reduce((a, b) => {

        return a.dataPrestazione! < b.dataPrestazione! ? a : b;
      });

      dateList.push(minPrestazioniData.dataPrestazione);

    }

    if (listDiagnosi != null && listDiagnosi != undefined && listDiagnosi.length > 0) {

      let minDiagnosiData = listDiagnosi.reduce((a, b) => {

        return a.data! < b.data! ? a : b;

      });

      dateList.push(minDiagnosiData.data);

    }

    if (dateList.length > 0) {
      let minData = dateList.reduce((a, b) => {

        return a < b ? a : b;

      });

      return minData;

    } else {

      return null;

    }


  }

  async submitForm(redirect2interventoForm?: boolean) {
    // this.printErrors();

    //if(!this.contatto.id) {
    //   await this.anagraficaPanel?.saveAnagrafica(true);
    // }

    //lo forzo
    this.anagraficaOK = true;
    if ((!this.contatto.id && this.anagraficaOK) || this.contatto.id) {

      this.contattoFormSubmitted = true

      let piAltroError = false
      let ieAltroError = false
      let diagnosiChiusuraError = false;
      let modalitaConclusioneError = false;
      let interventoEffettuatoRiabilitativiError = false;
      let interventoEffettuatoLavorativiError = false;
      let interventoEffettuatoInterveniSocialiError = false;
      let interventoEffettuatoColloquiPsichiatriciError = false;
      let interventoEffettuatoCoinvolgimentoError = false;

      if (this.contattoForm.contains('altro')) {
        piAltroError = this.contattoForm.controls['altro'].getError("required")
      }
      if (this.contattoForm.contains('diagnosiChiusura')) {
        diagnosiChiusuraError = this.contattoForm.controls['diagnosiChiusura'].getError("required")
      }
      if (this.contattoForm.contains('modalitaConclusione')) {
        modalitaConclusioneError = this.contattoForm.controls['modalitaConclusione'].getError("required")
      }
      if (this.contattoForm.contains('interventoEffettuatoRiabilitativi')) {
        interventoEffettuatoRiabilitativiError = this.contattoForm.controls['interventoEffettuatoRiabilitativi'].getError("required")
      }
      if (this.contattoForm.contains('interventoEffettuatoLavorativi')) {
        interventoEffettuatoLavorativiError = this.contattoForm.controls['interventoEffettuatoLavorativi'].getError("required")
      }
      if (this.contattoForm.contains('interventoEffettuatoInterveniSociali')) {
        interventoEffettuatoInterveniSocialiError = this.contattoForm.controls['interventoEffettuatoInterveniSociali'].getError("required")
      }
      if (this.contattoForm.contains('interventoEffettuatoColloquiPsichiatrici')) {
        interventoEffettuatoColloquiPsichiatriciError = this.contattoForm.controls['interventoEffettuatoColloquiPsichiatrici'].getError("required")
      }
      if (this.contattoForm.contains('interventoEffettuatoCoinvolgimento')) {
        interventoEffettuatoCoinvolgimentoError = this.contattoForm.controls['interventoEffettuatoCoinvolgimento'].getError("required")
      }
      if (this.contattoForm.contains('interventoEffettuatoAltro')) {
        ieAltroError = this.contattoForm.controls['interventoEffettuatoAltro'].getError("required")
      }


      // let valid = this.contattoForm.valid
      let update: boolean = false;
      if (this.contatto.id) {
        update = true;
      }
      if (!this.dataChiusuraExist()) {
        if (this.contattoForm.get('dataChiusura')) {

          this.contattoForm.get('dataChiusura')?.removeValidators([Validators.required])
          this.contattoForm.controls['dataChiusura'].updateValueAndValidity({onlySelf: true, emitEvent: false})
        }
      }

      if ( //this.contattoForm.valid problema con il form: anche se tutti i campi sono validi a volte il form non risulta valido (soluzione tampone new CommonsUtil().validFields)
        new CommonsUtil().validFields(this.contattoForm)
        // this.contattoForm.valid
        && !piAltroError
        && !ieAltroError
        && (!diagnosiChiusuraError || diagnosiChiusuraError == null)
        && (!modalitaConclusioneError || modalitaConclusioneError == null)
        && !interventoEffettuatoRiabilitativiError
        && !interventoEffettuatoLavorativiError
        && !interventoEffettuatoInterveniSocialiError
        && !interventoEffettuatoColloquiPsichiatriciError
        && !interventoEffettuatoCoinvolgimentoError) {
        if (this.chiusuraDataModel == null || this.chiusuraDataModel == undefined || this.contattoCanBeClosed()) {

          let contatto = this.getInsertedValues()
          contatto.codiceAslOperatoreInserimento = this.utente.codiceAsl;
          let codiceStruttura = this.sessionSevice.getSelectedStrutturaFromStorage()?.codice

          if (!this.isOutOfRangeOtherContact(contatto)) {

            this.toastService.showError('La data apertura non può essere compresa tra la data apertura e la data chiusura di un contatto esistente');
            return;

          }

          let minDate = this.checkDateForOpenContact();

          if (minDate != null) {

            minDate = new Date(minDate);

            if (contatto.dataApertura!.getTime() > minDate.getTime()) {

              this.toastService.showError('La data apertura non può essere maggiore delle date interventi, diagnosi, presenze e/o prestazioni');
              return;

            }

          }

          if (this.dataChiusuraExist()) {
            let maxDate = this.checkDateForCloseContact();

            maxDate = new Date(maxDate);

            if (maxDate.getTime() > contatto.dataChiusura!.getTime()) {

              if(this.isContattoSemiResideziale(this.contatto!.tipoPrestazione!)){
                this.toastService.showError('La data di chiusura non può essere precedente alle date delle presenze e/o prestazioni');

              } else {
                this.toastService.showError('La data di chiusura non può essere precedente alle date degli interventi, diagnosi e/o prestazioni');

              }

              return;

            }

          }

          let canClose: boolean = true;
          if(contatto.tipoPrestazione == 'Semiresidenziale'){

            if ((contatto.dataChiusura != null && contatto.dataChiusura != undefined )||
              (contatto.codiceDiagnosiChiusura != undefined &&
              contatto.codiceDiagnosiChiusura != null &&
              contatto.codiceDiagnosiChiusura != "") ||
              (contatto.modalitaConclusione != null &&
              contatto.modalitaConclusione !=  "" &&
              contatto.modalitaConclusione !=  undefined )) {
              if (contatto.dataChiusura == null || contatto.dataChiusura == undefined || contatto.codiceDiagnosiChiusura == null || contatto.codiceDiagnosiChiusura == undefined ||  contatto.modalitaConclusione == null || contatto.modalitaConclusione == undefined) {
                canClose = false;
              }
            }

          }
          this.blockUI.start("Salvataggio in corso")
          if(canClose){
          if ((!this.sameStrutturaAsl(contatto)) || update) {
            if (this.hasOpenContatto() || update) {
              if (contatto.aperturaFlag == '2' || (((contatto.dataChiusura != null && contatto.dataChiusura != undefined && this.contatto.dataChiusura != contatto.dataChiusura) || (contatto.dataApertura != null && contatto.dataApertura != undefined &&  this.contatto.dataApertura != contatto.dataApertura)) && !this.dateIsInRangeContattoSetting(contatto)) || update) {
                let confermaDiagnosi: boolean = false;
                if(this.confermadiagnosichiusuraterritoriale == 'true'){
                  confermaDiagnosi = true;

                }
                this.apiService.saveContatto(contatto, codiceStruttura!!, redirect2interventoForm, confermaDiagnosi ).subscribe({
                  next: (res) => {

                    if (res.id != null) {

                      this.contatto = res;
                      this.toastService.showSuccess('Salvataggio effettuato con successo');
                      this.sessionSevice.setSelectedContatto(this.contatto);

                    }
                    redirect2interventoForm ? this.router.navigate(['intervento-form']) : null;

                  },

                  error: (e) => {

                    console.error(e);

                    this.blockUI.stop();

                    this.toastService.showError('Correggere gli errori di compilazione');
                  },

                  complete: () => {

                    if (this.isTipoPrestazioneTerritoriale() && this.contatto.interventiList && this.contatto.interventiList?.length > 0) {

                      const interventi: Intervento[] = this.contatto.interventiList;
                      const interventiS: Intervento[] = interventi.sort((a, b) => a.id && b.id && a.id >= b.id ? -1 : 1);
                      this.blockUI.stop();

                      if (interventiS[0].rispostaPrevalente == '1' || interventiS[0].rispostaPrevalente == '2' || interventiS[0].rispostaPrevalente == '3') {
                        let intv = interventiS[0];

                        if (this.chiusuraTerritoriale) {
                          intv.ieTrattamentoPsicoterapeutico = this.contattoForm.get('ieTrattamentoPsicoterapeutico')?.value;
                          intv.ieTrattamentoFarmacologico = this.contattoForm.get('ieTrattamentoFarmacologico')?.value;
                          intv.ieInterventiRiabilitativi = this.contattoForm.get('ieInterventiRiabilitativi')?.value;
                          intv.ieInserimentiLavorativi = this.contattoForm.get('ieInserimentiLavorativi')?.value;
                          intv.ieInterventoSociale = this.contattoForm.get('ieInterventoSociale')?.value;
                          intv.ieColloquiSostegno = this.contattoForm.get('ieColloquiSostegno')?.value;
                          intv.ieCoinvolgimentoFamiliari = this.contattoForm.get('ieCoinvolgimentoFamiliari')?.value;
                          intv.ieAccertamentiMedicoLegali = this.contattoForm.get('ieAccertamentiMedicoLegali')?.value;
                          intv.ieInvioInStruttura = this.contattoForm.get('ieInvioInStruttura')?.value;
                          intv.ieInvioStpit = this.contattoForm.get('ieInvioStpit')?.value;
                          intv.ieLuogoStpit = this.contattoForm.get('ieLuogoStpit')?.value;
                          intv.ieInvioSrtri = this.contattoForm.get('ieInvioSrtri')?.value;
                          intv.ieLuogoSrtri = this.contattoForm.get('ieLuogoSrtri')?.value;
                          intv.ieInvioSrtre = this.contattoForm.get('ieInvioSrtre')?.value;
                          intv.ieLuogoSrtre = this.contattoForm.get('ieLuogoSrtre')?.value;
                          intv.ieInvioSrsr24 = this.contattoForm.get('ieInvioSrsr24')?.value;
                          intv.ieLuogoSrsr24 = this.contattoForm.get('ieLuogoSrsr24')?.value;
                          intv.ieInvioSrsr12 = this.contattoForm.get('ieInvioSrsr12')?.value;
                          intv.ieLuogoSrsr12 = this.contattoForm.get('ieLuogoSrsr12')?.value;
                          intv.ieInvioGa = this.contattoForm.get('ieInvioGa')?.value;
                          intv.ieLuogoGa = this.contattoForm.get('ieLuogoGa')?.value;
                          intv.ieInvioCd = this.contattoForm.get('ieInvioCd')?.value;
                          intv.ieLuogoCd = this.contattoForm.get('ieLuogoCd')?.value;
                          intv.ieInvioOspedale = this.contattoForm.get('ieInvioOspedale')?.value;
                          intv.ieInvioOspedaleRep40 = this.contattoForm.get('ieInvioOspedaleRep40')?.value;
                          intv.ieLuogoOspedale = this.contattoForm.get('ieLuogoOspedale')?.value;
                          intv.ieConsultazioneValutazione = this.contattoForm.get('ieConsultazioneValutazione')?.value;
                          intv.ieAltro = this.contattoForm.get('ieAltro')?.value;
                          //-------------------------
                          this.apiService.aggiornaIeIntervento(intv, codiceStruttura!!).subscribe({
                            next: (res) => {

                              this.toastService.showSuccess('Salvataggio effettuato con successo');
                              this.back();
                            },
                            error: (err) => {
                              console.error(err);
                              this.blockUI.stop()
                              this.toastService.showError('Correggere gli errori di compilazione');
                            },
                            complete: () => {
                              this.blockUI.stop()
                              this.back()
                              this.toastService.showSuccess('Salvataggio effettuato con successo');
                            }
                          })
                        } else {
                          this.blockUI.stop()
                          this.back();
                        }
                      } else {
                        this.blockUI.stop()
                        this.back();
                      }
                    } else {
                      this.blockUI.stop()
                      this.back();
                    }
                    this.blockUI.stop();
                  }
                });
              }
              else {
                this.blockUI.stop()
                //this.toastService.showError("Attenzione non è possibile inserire il contatto con questa data perchè esiste già un contatto chiuso presso la struttura " + this.contattoChiusoSetting.descrizioneStrutturaOperatoreInserimento + " codice " + this.contattoChiusoSetting.codiceStrutturaOperatoreInserimento + " dell'ASL " + this.contattoChiusoSetting.codiceAslOperatoreInserimento);
                this.toastService.showError("Attenzione non è possibile inserire il contatto con questa data perchè " + this.getStrinDateIsInRangeContattoSetting(contatto) + " esiste già un contatto chiuso presso altra Asl");
                // la struttura " + this.contattoChiusoSetting.descrizioneStrutturaOperatoreInserimento + " codice " + this.contattoChiusoSetting.codiceStrutturaOperatoreInserimento + " dell'ASL " + this.contattoChiusoSetting.codiceAslOperatoreInserimento
              }
            } else {
              this.blockUI.stop()
              // this.toastService.showError("Attenzione non è possibile inserire il contatto: esiste già un contatto aperto presso la struttura " + this.contattoApertoSetting.descrizioneStrutturaOperatoreInserimento + " codice " + this.contattoApertoSetting.codiceStrutturaOperatoreInserimento + " dell'ASL " + this.contattoApertoSetting.codiceAslOperatoreInserimento);
              this.modalService.open(this.sendEmailSollecitoModal, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
                this.closeResult = `Closed with: ${result}`;

                if (this.closeResult === 'Closed with: Back click') {
                  this.back()

                }
              }, (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;


              });
            }
          } else {
            this.blockUI.stop()
            //this.toastService.showError("Attenzione non è possibile inserire il contatto con questa data perchè esiste già un contatto chiuso presso la struttura " + this.contattoChiusoSetting.descrizioneStrutturaOperatoreInserimento + " codice " + this.contattoChiusoSetting.codiceStrutturaOperatoreInserimento + " dell'ASL " + this.contattoChiusoSetting.codiceAslOperatoreInserimento);
            this.toastService.showError("Attenzione non è possibile inserire il contatto con questa data perchè " + this.getStrinDateIsInRangeContattoSetting(contatto) + " esiste già un contatto aperto");

          }
        }else {
            this.blockUI.stop()
            //this.toastService.showError("Attenzione non è possibile inserire il contatto con questa data perchè esiste già un contatto chiuso presso la struttura " + this.contattoChiusoSetting.descrizioneStrutturaOperatoreInserimento + " codice " + this.contattoChiusoSetting.codiceStrutturaOperatoreInserimento + " dell'ASL " + this.contattoChiusoSetting.codiceAslOperatoreInserimento);
            this.toastService.showError("Attenzione non è possibile chiudere il contatto: data chiusura, modalità conclusione e codice diagnosi sono obbligatorie.");

          }
        } else {
          this.blockUI.start("Salvataggio in corso")
          this.blockUI.stop()
          this.toastService.showError(this.getMissingDataMessage())
        }
      } else {

        this.blockUI.stop()
        this.toastService.showError('Correggere errori di compilazione');
      }
    } else {
      this.tabShowed = TabShowedState.ANAGRAFICA;
    }
  }

  /**
   *
   * @param aslContattoAperto
   * @param aslUtente
   */
  sendEmail(aslContattoAperto: string, aslUtente: string) {

   // console.log("Send email")
   //  this.apiService.getContattiApertiSetting(this.anagrafica!.id!,
   //    (this.sessionSevice.isSelectedStrutturaFromStorageTerritoriale() ? "Territoriale" : "Semiresidenziale"))
   //    .subscribe(res => {
        // this.contattoApertoSetting = res
        // let  contattiApertiSetting = res
        let contattiId = "";
        for(let cnt of this.contattiApertiSettings){
          contattiId = contattiId + ", " + cnt.id;
        }
        // this.apiService.insertNotifica(aslContattoAperto, aslUtente, this.contattoApertoSetting.id!, this.anagrafica).subscribe({
        this.apiService.insertNotifica(aslContattoAperto, aslUtente, contattiId, this.anagrafica, this.sessionSevice.getSelectedStrutturaFromStorage()?.codice!).subscribe({
          next: (res) => {
            if (res) {

            }
          }, error: (e) => {
            console.error(e)
          }, complete: () => {

          }
      });
    // this.apiService.insertNotifica(aslContattoAperto, aslUtente, this.contattoApertoSetting.id!, this.anagrafica).subscribe({
    //   next: (res) => {
    //     if (res) {
    //
    //     }
    //   }, error: (e) => {
    //     console.error(e)
    //   }, complete: () => {
    //
    //   }
    // });

  }

  loadContatti() {

    this.apiService.getAllContattiByAnagraficaIdAndStruttura(this.anagrafica.id!, this.sessionSevice.getSelectedStrutturaFromStorage()?.codice!).subscribe(res => {

      if (res) {
        this.listContatti = res;
      }

    });

  }

  isOutOfRangeOtherContact(contatto: Contatto): boolean {

    let dataApertura = contatto.dataApertura;

    if (dataApertura) {

      for (let cont of this.listContatti!) {

        if (contatto.id != cont.id) {

          if (dataApertura >= new Date(cont.dataApertura!) && dataApertura < new Date(cont.dataChiusura!)) {

            return false;

          }

        }

      }

    } else {

      return true;

    }

    return true;

  }

  openPrestazioneForm() {
    this.sessionSevice.setSelectedPrestazione("");
    this.router.navigate(['prestazione'])
  }


  loadPrestazioni(id: number, pageNr: number, pageSize: number, order: string | null, direction: string | null) {


    if (id != null) {


      this.apiService.getPaginaPrestazioni(id, pageNr, pageSize, order, direction).subscribe(res => {


        this.page = res


      })

    }


  }


  changeOrder() {
    this.loadPrestazioni(this.contatto.id!!, this.page.number, this.page.size, this.tableSorter.orderByName(), this.tableSorter.orderByDirection())

  }

  cambiaPagina() {
    this.loadPrestazioni(this.contatto.id!!, this.page.number, this.page.size, this.tableSorter.orderByName(), this.tableSorter.orderByDirection())
  }

  selectPrestazione(prestazione: Prestazione) {

    this.sessionSevice.setSelectedPrestazione(JSON.stringify(prestazione))
    this.router.navigate(['prestazione'])
  }


  openGoBackDialog(content: any) {
    if (!this.contattoForm.touched) {
      this.back()
    } else {
      this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
        if (this.closeResult === 'Closed with: Back click') {
          this.back()
        }
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  openImportXmlDialog(importxml: any) {


    this.modalService.open(importxml, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

      if (this.closeResult === 'Closed with: Back click') {
        this.back()

      }
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;


    });


  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  back() {
    //this.sessionSevice.setOpenTabAnagrafica("TabContatti")
    this.router.navigate(['contattiList'])
  }


  async getDiagnosi() {
    this.apiService.getAllDiagnosi().subscribe(res => {
      this.listDiagnosiApertura = res

      this.listDiagnosiChiusura = res

    })
  }


  async getAllPrecedentiContatti() {
    this.apiService.getAllPrecedentiContatti().subscribe(res => {
      this.listPrecedentiContatti = res
    })
  }

  async getAllModalitaConclusione() {
    this.apiService.getAllModalitaConclusione().subscribe(res => {
      this.listaModalitaConclusione = res
    })
  }


  async getRichiestePrevalenti() {
    this.apiService.getAllRichiestePrevalenti().subscribe(res => {
      this.listRichiestePrevalenti = res
    })
  }


  async getInviantiTerritoriali() {

    this.apiService.getAllInviantiTerritoriale().subscribe(res => {
      this.listInvianti = res
    })


  }

  async getInviantiSemiResidenziali() {

    this.apiService.getAllInviantiSemiresidenziali().subscribe(res => {
      this.listInvianti = res
    })


  }


  /**
   * metodi per la gestione dei permessi
   */
  salvaVisibile() {
    if(this.tabShowed == TabShowedState.ANAGRAFICA){
      return false;
    }
    let utente = this.sessionSevice.getUtenteFromStorage();
    return utente?.funzionalita.includes(Funzionalita.ANAGRAFICHE_INSERIMENTO);
  }

  aggiungiPrestazioneVisibile() {
    let utente = this.sessionSevice.getUtenteFromStorage();
    let aperturaFlagValue = this.contattoForm.controls['aperturaFlag'].value
    let elegible = utente?.funzionalita.includes(Funzionalita.ANAGRAFICHE_INSERIMENTO) && aperturaFlagValue !== "2"
    return elegible
  }

  isValidato() {
    if (this.contatto.statoAttuale.id == 3) {
      return true
    } else {
      return false;
    }
  }

  navigateToDashboard() {
    this.router.navigate(['dashboard'])
  }

  navigateToAnagrafica() {
    this.router.navigate(['contattiList'])
  }


  loadTab() {
    this.sessionSevice.comingPathContatto.subscribe(res => {
      if (res === "TabPrestazoni") {
        this.tabShowedPrestazioni()
      } else if (res === "TabDiagnosiScale") {
        this.tabShowedDiagnosiScale()
      } else if (res === "TabInterventi") {
        this.tabShowedInterventi()
      } else if (res === "TabPresenze") {
        this.tabShowedPresenze()
      } else {
        this.tabShowedContatti()
      }
    })
  }

  tabShowedContatti() {
    this.deleteVisible = false
    this.tabShowed = TabShowedState.CONTATTI;
  }

  tabShowedAnagrafica() {
    this.deleteVisible = true
    this.tabShowed = TabShowedState.ANAGRAFICA;
  }

  tabShowedPrestazioni() {
    this.deleteVisible = true
    this.tabShowed = TabShowedState.PRESTAZIONI;
  }

  tabShowedInterventi() {
    this.deleteVisible = true
    this.tabShowed = TabShowedState.INTERVENTI;
  }

  tabShowedDiagnosiScale() {
    this.deleteVisible = true
    this.tabShowed = TabShowedState.DIAGNOSISCALE;
  }

  ngOnDestroy() {
    this.sessionSevice.setOpenTabContatto("");
    if(this.subscribe1)this.subscribe1.unsubscribe();
    if(this.subscribe2)this.subscribe2.unsubscribe();
    //console.log("Call undestroy")
  }


  tabShowedPresenze() {
    this.deleteVisible = true
    this.tabShowed = TabShowedState.PRESENZE;
  }

  tabContattiClasses() {
    return this.tabShowed == TabShowedState.CONTATTI ?
      'active' : '';
  }

  tabPaneContattiClasses() {
    return this.tabShowed == TabShowedState.CONTATTI ?
      'active show' : '';
  }

  tabPaneAnagraficaClasses() {
    return this.tabShowed == TabShowedState.ANAGRAFICA ?
      'active show' : '';
  }

  tabPrestazioniClasses() {
    return this.tabShowed == TabShowedState.PRESTAZIONI ?
      'active' : '';
  }

  tabInterventiClasses() {
    return this.tabShowed == TabShowedState.INTERVENTI ?
      'active' : '';
  }

  tabPresenzeClasses() {
    return this.tabShowed == TabShowedState.PRESENZE ?
      'active' : '';
  }

  tabDiagnosiEScaleClasses() {
    return this.tabShowed == TabShowedState.DIAGNOSISCALE ?
      'active' : '';
  }

  tabPanePrestazioniClasses() {
    return this.tabShowed == TabShowedState.PRESTAZIONI ?
      'active show' : '';
  }

  tabPaneInterventiClasses() {
    return this.tabShowed == TabShowedState.INTERVENTI ?
      'active show' : '';
  }

  tabPaneDiagnosiScaleClasses() {
    return this.tabShowed == TabShowedState.DIAGNOSISCALE ?
      'active show' : '';
  }

  tabPanePresenzeClasses() {
    return this.tabShowed == TabShowedState.PRESENZE ?
      'active show' : '';
  }

  formatText(text: string | null): string | null {

    if (text != null && text.length > 25) {

      return text.slice(0, 25) + "..."
    } else {

      return text ? text : ""
    }


  }

  getTransmisionState(statoTrasmissione: string) {

    let spanclass = ""

    if (statoTrasmissione === "VALIDATO") {
      spanclass = "badge badge-success"
    } else if (statoTrasmissione === "DA_TRASMETTERE") {
      spanclass = "badge badge-warning"
    } else if (statoTrasmissione === "SCARTATTO") {
      spanclass = "badge badge-danger"
    }

    return spanclass
  }

  isTipoPrestazioneTerritoriale(): boolean {
    if (this.contatto != null) {
      return this.contatto.tipoPrestazione == "Territoriale";
    } else {
      return false;
    }
  }

  invianteDisabled() {
    if (!this.hasWritePermission(this.mainUser) || this.isAperturaFlagTerritorialeNo()) {
      return false;
    } else return null;
  }

  hasWritePermission(utente: Utente): boolean {

    if (utente.funzionalita.includes(Funzionalita.ANAGRAFICHE_INSERIMENTO)) {
      return true
    } else {
      return false
    }

  }


  setAperturaFlag(event: any) {
    this.aperturaFlag = event.target.value
    if (this.aperturaFlag == "1" && this.hasOpenContatto()) {
      // this.toastService.showError("Attenzione, per questo setting esiste" +
      //   " già un contatto aperto il giorno " + this.contattoApertoSetting.dataApertura
      //   + ' dalla struttura con codice ' + this.contattoApertoSetting.codiceStrutturaOperatoreInserimento)
      this.contattoForm.get('aperturaFlag')?.patchValue('1')
      this.aperturaFlag = "1"
      this.setValidatorsBasedOnFlag(this.aperturaFlag)
    } else {
      if (!this.contattoIsNew(this.contatto) && this.aperturaFlag == "2") {
        this.openChiudiContattoDialog('solofigli');
        this.setValidatorsBasedOnFlag(this.aperturaFlag)
      }


      if (this.aperturaFlag == "2") {
        this.aperturaDataModel = null;
        this.modelAperturaDiagnosi = null;
        this.contattoForm.get('precedentiContatti')?.patchValue('');
        this.contattoForm.get('inviante')?.patchValue('');
        this.modelChiusuraDiagnosi = null;
        this.chiusuraDataModel = null;
        this.contattoForm.get('modalitaConclusione')?.patchValue('');
      }

    }

  }


  hasOpenContatto() {

    if (this.contattoApertoSetting == null || this.contattoApertoSetting == undefined) {

      return true;

    } else {

      let res: boolean = false;
      for (let cnt of this.contattiApertiSettings) {

        res = cnt != null && this.contattoApertoSetting.id != this.contatto.id &&
          cnt.codiceAslOperatoreInserimento == this.utente.codiceAsl;
        if (res) {

          return res;

        }

      }
      // let res : boolean = this.contattoApertoSetting != null && this.contattoApertoSetting.id != this.contatto.id &&
      //   // this.contattoApertoSetting.codiceStrutturaOperatoreInserimento != this.contatto.codiceStrutturaOperatoreInserimento &&
      //   // this.contattoApertoSetting.codiceAslOperatoreInserimento == this.codiceAslcodiceAslOperatoreInserimento;
      //   this.contattoApertoSetting.codiceAslOperatoreInserimento == this.utente.codiceAsl;
      // return res;
// return this.contattoApertoSetting != null && this.contattoApertoSetting.id != this.contatto.id &&
//   // this.contattoApertoSetting.codiceStrutturaOperatoreInserimento != this.contatto.codiceStrutturaOperatoreInserimento &&
//   // this.contattoApertoSetting.codiceAslOperatoreInserimento == this.codiceAslcodiceAslOperatoreInserimento;
//   this.contattoApertoSetting.codiceAslOperatoreInserimento == this.utente.codiceAsl;
      return res;
    }

  }

  sameStrutturaAsl(contatto: Contatto) {
    if(this.contattoApertoSetting == null || this.contattoApertoSetting == undefined){

      return false;

    }

    let res : boolean = true;

    for(let cnt of this.contattiApertiSettings){

      res = cnt != null &&
        cnt.id != this.contatto.id &&
        cnt.codiceStrutturaOperatoreInserimento == this.sessionSevice.getSelectedStrutturaFromStorage()?.codice &&
        cnt.codiceAslOperatoreInserimento == this.utente.codiceAsl && (!this.areAllContattiClosed()) ;
      if(res){
        return res
      }
    }
    // let a = this.contattoApertoSetting != null &&
    //   this.contattoApertoSetting.id != this.contatto.id &&
    //   this.contattoApertoSetting.codiceStrutturaOperatoreInserimento == this.sessionSevice.getSelectedStrutturaFromStorage()?.codice &&
    //   this.contattoApertoSetting.codiceAslOperatoreInserimento == this.utente.codiceAsl && (!this.areAllContattiClosed()) ;

    //console.log(a)
    return res;
  }
  // sameStrutturaAsl(contatto: Contatto) {
  //   let a = this.contattoApertoSetting != null &&
  //     this.contattoApertoSetting.id != this.contatto.id &&
  //     this.contattoApertoSetting.codiceStrutturaOperatoreInserimento == this.sessionSevice.getSelectedStrutturaFromStorage()?.codice &&
  //     this.contattoApertoSetting.codiceAslOperatoreInserimento == this.utente.codiceAsl && (this.contatto.dataChiusura != contatto.dataChiusura || this.contatto.dataApertura != contatto.dataApertura) ;
  //
  //   //console.log(a)
  //   return a;
  // }
  loadHasOpenContattiStatus(anagrafica: DettaglioAnagrafica) {


    this.apiService.getAllAnagraficaContatti(anagrafica.id!!).subscribe(res => {

      if (res) {

        this.allAnagraficaContatti = res;

      }
    })

  }
  allAnagraficaContatti: Contatto[] = []
  areAllContattiClosed(): boolean {


    let isAllContattiClosed = this.allAnagraficaContatti.every(
      cont => cont.dataChiusura != null || cont.dataChiusura != undefined || cont.aperturaFlag == "2")

    return isAllContattiClosed
  }

  dateIsInRangeContattoSetting(contatto: Contatto) {
    if (this.contattoChiusoSetting != null && this.contattoChiusoSetting.dataChiusura != null && this.contattoChiusoSetting.dataChiusura != undefined) {
      //aggiunto controllo per asl differenti &&  contatto.codiceAslOperatoreInserimento != this.contattoChiusoSetting.codiceAslOperatoreInserimento
      if (new Date(contatto.dataApertura!).getTime() >= new Date(this.contattoChiusoSetting.dataApertura!).getTime() &&
        new Date(contatto.dataApertura!).getTime() <= new Date(this.contattoChiusoSetting.dataChiusura!).getTime()
        &&  contatto.codiceAslOperatoreInserimento != this.contattoChiusoSetting.codiceAslOperatoreInserimento) {
        return true;
      }
    }
    return false;
  }

  getStrinDateIsInRangeContattoSetting(contatto: Contatto) {
    if (this.contattoChiusoSetting != null && this.contattoChiusoSetting.dataChiusura != null && this.contattoChiusoSetting.dataChiusura != undefined) {

      if (new Date(contatto.dataApertura!).getTime() >= new Date(this.contattoChiusoSetting.dataApertura!).getTime() &&
        new Date(contatto.dataApertura!).getTime() <= new Date(this.contattoChiusoSetting.dataChiusura!).getTime()) {
        return ' dalla data ' + this.getformattedDate(new Date(this.contattoChiusoSetting.dataApertura!)) + ' alla data ' + this.getformattedDate(new Date(this.contattoChiusoSetting.dataChiusura!)) + ' ';
      }
    }
    return '';
  }

  private getformattedDate(d: Date) {
    return d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
  }


  isThisContattoAperto() {
    return this.contatto.dataChiusura != null || this.contatto.dataChiusura != undefined || this.contatto.aperturaFlag == "2"
  }

  setValidatorsBasedOnFlag(aperturaFlag: string | null) {
    if (aperturaFlag != null) {
      switch (aperturaFlag) {
        case "1":
          this.contattoForm.get('dataApertura')!!.addValidators([Validators.required, dataNonValidaValidator()])

          if (!this.hasWritePermission(this.mainUser) || !this.canEdit()) {
            this.contattoForm.get('dataApertura')!!.disable({onlySelf: true})
            this.contattoForm.get('diagnosiApertura')!!.disable({onlySelf: true})
            this.contattoForm.get('precedentiContatti')!!.disable({onlySelf: true})
            this.contattoForm.get('inviante')!!.disable({onlySelf: true})
          } else {
            this.contattoForm.get('dataApertura')!!.enable({onlySelf: true})
            this.contattoForm.get('diagnosiApertura')!!.enable({onlySelf: true})
            this.contattoForm.get('precedentiContatti')!!.enable({onlySelf: true})
            this.contattoForm.get('inviante')!!.enable({onlySelf: true})
          }

          if (this.contattoIsNew(this.contatto)) {
            this.contattoForm.get('dataApertura')?.valueChanges.subscribe(dt => {
              this.checkForDuplicateDates(dt)
            })
          }
          break;
        case "2":

          this.contattoForm.get('dataApertura')!!.disable({onlySelf: true})
          this.contattoForm.get('diagnosiApertura')!!.disable({onlySelf: true})
          this.contattoForm.get('precedentiContatti')!!.disable({onlySelf: true})
          this.contattoForm.get('inviante')!!.disable({onlySelf: true})
          break;

      }
    }


  }


  isAperturaFlagTerritorialeNo() {
    return this.aperturaFlag == "2"
  }

  cancellaContatto() {
    let presenzaEntitaRelazionate = false;
    if (this.isFormTerritoriale()) {
      presenzaEntitaRelazionate =
        this.contatto.interventiList!.length > 0
        || this.contatto.diagnosiEScaleList!.length > 0
        || this.contatto.prestazioniList!.length > 0
    } else {
      presenzaEntitaRelazionate =
        this.contatto.presenzaList!.length > 0
      // || this.contatto.prestazioniList!.length > 0
    }

    /*if (presenzaEntitaRelazionate) {
      this.openChiudiContattoDialog('tutto')
    } else {
      this.chiudiContattoMode = 'tutto';
      this.chiudiAllContatto(this.contatto.id!);
    }*/
    this.openChiudiContattoDialog('tutto')


  }

  cancellaVisibile(): Boolean {

    if (this.contatto!!.id != null && this.salvaVisibile() && this.tabShowed==TabShowedState.CONTATTI) {
      return true
    } else {
      return false
    }

  }

  // openCancellaDialog(cancella: any) {

  //   this.modalService.open(cancella, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
  //     this.closeResult = `Closed with: ${result}`;

  //     if (this.closeResult === 'Closed with: Back click') {
  //       this.back()

  //     }
  //   }, (reason) => {
  //     this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;


  //   });


  // }
  lastDiagnosiEScale: any;
  presenzeError: boolean = false;
  diagnosiError: boolean = false;
  confermadiagnosichiusuraterritoriale: string = "";


  dataChiusuraExist() {


    return this.chiusuraDataModel ? true : false
  }


  contattoHasChiusuraDate(contatto: Contatto): Boolean {


    if (contatto.dataChiusura == null || contatto.dataChiusura == undefined) {
      return false
    } else {
      return true
    }
  }


  checkForDuplicateDates(date: NgbDateStruct) {


    let exist = this.listOfContattiNotAvailableDates.indexOf(JSON.stringify(date)) !== -1


    if (exist === true) {
      this.contattoForm.get('dataApertura')?.setErrors({dateExist: true})
      //this.contattoForm.get('dataApertura')?.setValidators([Validators.required]);
      this.contattoForm.controls['dataApertura'].updateValueAndValidity({onlySelf: true, emitEvent: false})
    } else {
      //this.contattoForm.get('dataApertura')?.setErrors({dateExist: null})
      this.contattoForm.get('dataApertura')?.setErrors(null)
      //this.contattoForm.get('dataApertura')?.setValidators([]);
      this.contattoForm.controls['dataApertura'].updateValueAndValidity({onlySelf: true, emitEvent: false})
    }
  }


  observeForDataRefresh() {
  this.subscribe1 = this.sessionSevice.refreshData.subscribe(res => {
      if (res === true) {
        this.refreshContattoData()
        this.sessionSevice.setRefreshdata(false)
      }
    })
  }

  refreshContattoData() {
    this.apiService.selectContattoById(this.contatto.id!!).subscribe(res => {
      this.contatto = res
      this.sessionSevice.setSelectedContatto(this.contatto)
      this.loadListOfNotavailableDatesForContatti()
    })
  }

  contattoIsClosed(): boolean {
    return this.contatto.dataChiusura != null || this.contatto.dataChiusura != undefined || this.contatto.aperturaFlag == "2"
  }


  gruppoAperturaHidden() {
    return this.aperturaFlag == null || this.aperturaFlag == "2";
  }

  chiusuraGroupHidden() {
    return this.contattoIsNew(this.contatto) || this.aperturaFlag == "2"
      || this.contatto.dataApertura == null;
  }


  isDiagnosiChiusuraRequired() {
    return this.chiusuraDataModel != null;
  }


  isModalitaConclusioneRequired() {
    return this.chiusuraDataModel != null;
  }


  loadPresenze(contatto: Contatto) {
    this.apiService.getListaPresenze(contatto.id!!).subscribe({
      next: (res) => {
        this.listPresenze = res;
        this.sessionSevice.setListPresenze(this.listPresenze)
      },
      error: (err) => {
        console.error(err);
      },
      complete: () => {
      }
    })
  }

  presenzeExist() {
    return this.listPresenze!!.length != 0
  }

  contattoCanBeClosed() {
    if (this.isFormTerritoriale()) {
      return this.contatto.interventiList?.length != 0
        && this.contatto.diagnosiEScaleList?.length != 0
        && this.contatto.prestazioniList?.length != 0
    } else {
      return this.contatto.presenzaList?.length != 0
      // && this.contatto.prestazioniList?.length != 0 //FIX Per chiudere un contatto semires basta che ci sia almeno una presenza (togliere il controllo sulla prestazione) 21/02/2023 Mattia Ripanucci
    }
  }

  getMissingDataMessage() {

    let msg = ''

    if (this.sessionSevice.isSelectedStrutturaFromStorageTerritoriale()) {
      if (this.contatto.interventiList?.length == 0) {
        msg = "Nessun intervento trovato"
      } else if (this.contatto.diagnosiEScaleList?.length == 0) {
        msg = "Nessuna diagnosi&scale trovata"
      } else if (this.contatto.prestazioniList?.length == 0) {
        msg = "Nessuna prestazione trovata"
      }
    } else {
      if (this.contatto.presenzaList?.length == 0) {
        msg = "Il contatto non può essere chiuso se non sono registrate presenze"
      } else if (this.contatto.prestazioniList?.length == 0) {
        msg = "Nessuna prestazione trovata"
      }
    }

    return msg;

  }

  limitMaxDate() {
    let todayDate = this.sessionSevice.convertToDateStruct(new Date())
    this.maxDate = {year: todayDate?.year!!, month: todayDate?.month!!, day: todayDate?.day!!}
  }

  openChiudiContattoDialog(mode: string) {
    this.chiudiContattoMode = mode;
    this.modalService.open(this.chiudiContattoModal, {backdrop: 'static', keyboard: false});
  }

  chiudiAllContatto(id: number) {

    this.modalService.dismissAll()
    this.blockUI.start("Cancellazione in corso")
    this.apiService.chiudiContattoById(id, this.chiudiContattoMode).subscribe({
      next: (res) => {
        this.blockUI.stop()
        this.toastService.showSuccess("Cancellazione efettuata con successo")
        this.back()
      },
      error: (err) => {

        this.blockUI.stop()
        this.toastService.showSuccess("E successo un errore durante la cancellazione")
      },
      complete: () => {

      }
    })
  }


  printValidators() {
    this.contattoCanBeClosed();
    new CommonsUtil().printFormValidationErrors(this.contattoForm);
  }

  alert(statoAttuale: ConfStato) {
    return new CommonsUtil().alert(statoAttuale);
  }

  async openchiudiContattoTerritoriale(content: any, form: FormGroup) {

    this.lastDiagnosiEScale = await this.getLastDiagnosiEScale();
    let ldia = await this.getLastDiagnosiEScale();
    const latestCodiceDiagnosiChiusura = ldia;
    this.contatto.codiceDiagnosiChiusura = ldia ? ldia.diagnosi : '';
    this.contattoForm.get('diagnosiChiusura')?.patchValue(this.contatto.codiceDiagnosiChiusura);

    const modalRef = this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'xl'});
    //modalRef.componentInstance.form = form;

    modalRef.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

      if (this.closeResult === 'Closed with: Back click') {
        this.back()
      }
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openInterventoForm() {
    this.sessionSevice.setSelectedDiagnosiScale(JSON.stringify(new DiagnosiEScale()));
    this.router.navigate(['diagnosiescale-form'])
  }

  public async getLastDiagnosiEScale(): Promise<DiagnosiEScale> {
    const ldia = await lastValueFrom(this.apiService.getLatestDiagnosi4Contatto(this.contatto.id));
    return ldia;
  }

  chiusuraTerritoriale: boolean = false;

  async saveChiusuraTerritoriale(): Promise<boolean> {

    if (this.dataChiusuraExist()) {
      let maxDate = this.checkDateForCloseContact();
      maxDate = new Date(maxDate);
      let dd = this.contattoForm.get("dataChiusura")?.value;
      let ddd = new Date(dd.year, dd.month - 1, dd.day, 23, 59);
      if (maxDate.getTime() > ddd.getTime()) {
        this.toastService.showError('La data di chiusura non può essere precedente alle date degli interventi, diagnosi e/o prestazioni');
        return false;
      }
    }

    if (this.contattoForm.valid) {
      this.chiusuraTerritoriale = true;
      await this.submitForm(true);
      return true;
    } else {
      const controls = this.contattoForm.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          this.toastService.showError("Attenzione il valore "+name+" non è valido.");
          console.error(name);
        }
      }
      return false;
    }

  }

  canCloseTerriotriale() {
    return this.contatto.interventiList && this.contatto.interventiList?.length != 0 && this.contatto.diagnosiEScaleList && this.contatto.diagnosiEScaleList?.length != 0 && this.contatto.prestazioniList && this.contatto.prestazioniList?.length != 0;
  }

  prestazioniHasError() {
    return this.page.content.some(value => this.alert(value.statoAttuale));
  }

  setInterventiError(ev: boolean) {
    this.interventiError = ev;
  }

  setPresenzeError(ev: boolean) {
    this.presenzeError = ev;
  }

  setDiagnosiError(ev: boolean) {
    this.diagnosiError = ev;
  }

  public utente: Utente = new Utente();
  public OPERATORE_STRUTTURA: String = "operatore-struttura";
  public OPERATORE_ASL: String = "operatore-asl";
  public OPERATORE_DSM: String = "operatore-dsm";
  public OPERATORE_REGIONE: String = "operatore-regione";
  auditContatto: ContattoAudit[] = [];

  public getRuolo(ruolo: String) {
    this.getUtente();
    if (this.utente) {
      if (this.utente.nomeRuolo == ruolo) {
        return true;
      }
    }
    return false;
  }

  public getUtente() {
    var usr = sessionStorage.getItem("utente");
    if (usr != null && usr != undefined) {
      this.utente = JSON.parse(usr);
    }
  }

  openAuditDialog(audit: any) {
    this.apiService.getContattoAuditByIdAndStruttura(this.contatto?.id!!, this.sessionSevice.getSelectedStrutturaFromStorage()?.codice!).subscribe(res => {
      if (res) {
        this.auditContatto = res;
      }
    });
    this.modalService.open(audit, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
      scrollable: true
    }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

      if (this.closeResult === 'Closed with: Back click') {
        this.back()
      }
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  async saveChiusuraTerritorialeAsync(modal: any) {
    await this.saveChiusuraTerritoriale() ? modal.dismiss() : null
  }

  changeDiagnosi(confermachangediagnosi: any, apertura: boolean) {
    if(this.contatto.tipoPrestazione == 'Territoriale'){

      if (this.contatto.id != null) {

        if(this.contatto.codiceDiagnosiApertura != null && this.contatto.codiceDiagnosiApertura != ""){

          if (apertura) {

            this.messagechangediagnosi = "La modifica della diagnosi di apertura produrrà la modifica della prima diagnosi in Diagnosi&scale";

          } else {

            this.messagechangediagnosi = "La modifica della diagnosi di chiusura produrrà l’inserimento della nuova diagnosi in Diagnosi&scale";

          }

          if (this.contatto.dataChiusura != null && this.contatto.diagnosiEScaleList?.length == 1 && this.contatto.codiceDiagnosiApertura != this.modelAperturaDiagnosi.codice) {
            // 0 this.contatto.codiceDiagnosiApertura == this.contatto.codiceDiagnosiChiusura && this.modelChiusuraDiagnosi.codice == this.contatto.codiceDiagnosiChiusura
            this.messagechangediagnosi = "La modifica della diagnosi di apertura produrrà a cascata sia la modifica della prima diagnosi che della diagnosi di conclusione, come da lei indicato in fase di conclusione del contatto. Se desidera invece modificare solo la diagnosi di apertura, ma non quella di conclusione, dovrà procedere per passi successivi: effettuare la modifica della diagnosi di apertura, che come detto le modifica entrambe (diagnosi apertura e diagnosi conclusione) modificare successivamente la sola diagnosi di chiusura, inserendo quella corretta";

          }

          this.modalService.open(confermachangediagnosi, {
            ariaLabelledBy: 'modal-basic-title',
            size: 'l',
            scrollable: true
          }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;

            if (this.closeResult === 'Closed with: Back click') {
              this.back()
            }
          }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          });
        }
      }
    }
  }

  public messagechangediagnosi: string = "";

  protected readonly CommonsUtil = CommonsUtil;
  storicoList: any;
  storicoTitle: any;

  async openStoricoContatti(audit: any) {
    if (this.tabShowed == TabShowedState.INTERVENTI) {
      this.storicoTitle = "Storico interventi";
      await this.apiService.getStoricoInterventoTerritoriale(this.contatto.id!).subscribe(res => {
        if (res) {
          this.storicoList = res;
        }
      });
    } else if (this.tabShowed == TabShowedState.DIAGNOSISCALE) {
      this.storicoTitle = "Storico diagnosi & scale";
      await this.apiService.getStoricoDiagnosiEScaleTerritoriale(this.contatto.id!).subscribe(res => {
        if (res) {
          this.storicoList = res;
        }
      });
    } else if (this.tabShowed == TabShowedState.PRESENZE) {
      this.storicoTitle = "Storico presenze";
      await this.apiService.getStoricoPresenzeSemiresidenziale(this.contatto.id!).subscribe(res => {
        if (res) {
          this.storicoList = res;
        }
      });
    } else if (this.tabShowed == TabShowedState.PRESTAZIONI) {
      this.storicoTitle = "Storico prestazioni";
      if (this.isTipoPrestazioneTerritoriale()) {
        await this.apiService.getStoricoPrestazioneTerritoriale(this.contatto.id!).subscribe(res => {
          if (res) {
            this.storicoList = res;
          }
        });
      } else {
        await this.apiService.getStoricoPrestazioneSemiresidenziale(this.contatto.id!).subscribe(res => {
          if (res) {
            this.storicoList = res;
          }
        });
      }
    } else if (this.tabShowed == TabShowedState.CONTATTI) {
      this.storicoTitle = "Storico contatto";
      if (this.isTipoPrestazioneTerritoriale()) {
        await this.apiService.getStoricoSingoloContattoTerritoriale(this.contatto.id!).subscribe(res => {
          if (res) {
            this.storicoList = res;
          }
        });
      } else {
        await this.apiService.getStoricoSingoloContattoSemiresidenziale(this.contatto.id!).subscribe(res => {
          if (res) {
            this.storicoList = res;
          }
        });
      }
    } else {

    }
    this.modalService.open(audit, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
      windowClass: "sismModal",
      scrollable: true
    }).result.then((result) => {
    }, (reason) => {
    });

  }

  getLabelTab() {
    if(this.tabShowed==TabShowedState.CONTATTI){
      return "contatto";
    } else if(this.tabShowed==TabShowedState.PRESTAZIONI){
      return "prestazioni";
    } else if(this.tabShowed==TabShowedState.INTERVENTI){
      return "interventi";
    } else if(this.tabShowed==TabShowedState.DIAGNOSISCALE){
      return "diagnosi&scale";
    }else if(this.tabShowed==TabShowedState.PRESENZE){
      return "presenze";
    }else if(this.tabShowed==TabShowedState.ANAGRAFICA){
      return "anagrafica";
    } else {
      return "";
    }
  }
  isEdit: boolean = false;
  edit(){
    this.isEdit = !this.isEdit;
  }

  editVisibile() {
    return this.tabShowed==TabShowedState.ANAGRAFICA && !this.isValidato() && this.getRuolo(this.OPERATORE_STRUTTURA);
  }

  async salvaAnagrafica() {
    const salvato = await this.appStoricoAnagraficaEdit?.salva();
    if (salvato){
      this.isEdit = false;
      this.toastService.showSuccess('I dati anagrafici sono stati aggiornati correttamente.');

    } else {
      this.toastService.showError('Attenzione si è verificato un errore e non è stato possibile effettuare il salvataggio');

    }
  }

  closeModalAndRoute(modal: any, email:boolean, codiceAslOperatoreInserimento:string,codiceAsl:string ) {
    modal.close();
    if(email){
      this.sendEmail(codiceAslOperatoreInserimento,codiceAsl);
    }
    this.router.navigate(['ricerca-anagrafica'])
  }

  getDescrizioneASL(codiceAslOperatoreInserimento: string | undefined) {
    return this.aslList.find(value => value.codiceAzienda==codiceAslOperatoreInserimento)?.denominazioneAzienda;
  }

}

enum TabShowedState {
  CONTATTI, PRESTAZIONI, INTERVENTI, DIAGNOSISCALE, PRESENZE, ANAGRAFICA
}
