import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { Router } from '@angular/router';
import { TableSorter } from 'src/app/components/table/thead/table-sorter';
import { TheadColumn } from 'src/app/components/table/thead/thead-column';
import { Funzionalita } from 'src/app/model/Funzionalita';
import {Asl, ConfStato, Contatto, Diagnosi, DiagnosiEScale, Page, Utente} from 'src/app/model/Models';
import { ApiService } from 'src/app/services/api.service';
import { SessionService } from 'src/app/services/session.service';
import {CommonsUtil} from "../../util/CommonsUtil";

@Component({
  selector: 'app-diagnosi-scale-tab',
  templateUrl: './diagnosi-scale-tab.component.html',
  styleUrls: ['./diagnosi-scale-tab.component.css']
})
export class DiagnosiScaleTabComponent implements OnInit {



  pageDiagnosiScale: Page<DiagnosiEScale> = new Page<DiagnosiEScale>();

  contatto!:Contatto

  tableSorter: TableSorter = new TableSorter();

  theadColumns!: TheadColumn[];

  listDiagnosi: Diagnosi[] = []
  @Output() hasError = new EventEmitter<boolean>();

  confStati: ConfStato[] = [];

  constructor(private apiService:ApiService,private sessionService:SessionService,private router:Router) { }
  private aslList: Asl[] = [];

  ngOnInit(): void {

    this.loadContatto()

    this.theadColumns = this.loadTableHead()

    this.loadDiagnosiScale(this.contatto.id!!, 0, this.pageDiagnosiScale.size, null, null)

    this.loadAllDiagnosi()

    this.apiService.getAllConfStato().subscribe(data => {
      if(data){
        this.confStati = data;
      }
    })
    this.apiService.getAllAsl().subscribe(data => {
      for (let item of data) {
        if (item.codiceAzienda != "98" && item.codiceAzienda != "098" && item.codiceAzienda != "998" && item.codiceAzienda != "999") {
          this.aslList.push(item);
        }
      }
    });
  }

  loadContatto(){
    this.sessionService.selectedContatto.subscribe(res => {

      this.contatto =res

    })
  }

  private loadTableHead(): TheadColumn[] {
    let res: TheadColumn[] = [];
    res.push(new TheadColumn('', 'Alert'));
    res.push(new TheadColumn('id', 'IDDiagnosi'));
    // res.push(new TheadColumn('struttura', 'Codice - Denom. Struttura - ASL'));
    res.push(new TheadColumn('codiceStrutturaOperatoreInserimento', 'Codice - Denom. Struttura - ASL'));
    // res.push(new TheadColumn('data_contatto', 'Data apertura contatto'));
    res.push(new TheadColumn('contatto.dataApertura', 'Data apertura contatto'));
    res.push(new TheadColumn('data', 'Data diagnosi'));
    res.push(new TheadColumn('diagnosi', 'Diagnosi'));
    res.push(new TheadColumn('scalaHonos', 'Scala Honos'));
    res.push(new TheadColumn('scalaBprs', 'Scala Bprs'));
    // res.push(new TheadColumn('statoTrasmissione', 'Stato trasmissione'));
    res.push(new TheadColumn('statoAttuale', 'Stato trasmissione'));

    return res;
  }

  descrizioneStato(stato: number) {

    for(let confStato of this.confStati){

      if (stato == 5 && !this.getRuolo(this.OPERATORE_REGIONE))
        stato = 3;

      if(stato == confStato.id){

        return confStato.descrizione;

      }

    }
    return "Stato non trovato";
    // return this.apiService.getConfStatoById(stato).subscribe(data =>{
    //    return data.descrizione
    //  })
     // return new CommonsUtil().descrizioneStato(stato);
   }

  loadDiagnosiScale(id: number, pageNr: number, pageSize: number, order: string | null, direction: string | null) {


    if (id != null) {

      this.apiService.getPaginaDiagnosiEScala(id, pageNr, pageSize, order, direction).subscribe(res => {

        this.pageDiagnosiScale = res

        if(this.pageDiagnosiScale.content.some(value => this.alert(value.statoAttuale))){
          this.hasError.emit(true);
        }else{
          this.hasError.emit(false);
        }

      })

    }



  }

  aggiungiDiagnosiScaleVisibile() {

    let utente = this.sessionService.getUtenteFromStorage();
    let elegible = utente?.funzionalita.includes(Funzionalita.ANAGRAFICHE_INSERIMENTO) && this.contatto.aperturaFlag !== "2"
    return elegible
  }



  openInterventoForm() {
    this.sessionService.setSelectedDiagnosiScale(JSON.stringify(new DiagnosiEScale()));
    this.router.navigate(['diagnosiescale-form'])
  }

  cambiaPagina() {
    this.loadDiagnosiScale(this.contatto.id!!, this.pageDiagnosiScale.number, this.pageDiagnosiScale.size, this.tableSorter.orderByName(), this.tableSorter.orderByDirection())
  }

  changeOrder() {
    this.loadDiagnosiScale(this.contatto.id!!, this.pageDiagnosiScale.number, this.pageDiagnosiScale.size, this.tableSorter.orderByName(), this.tableSorter.orderByDirection())

  }

  diagnosiClick(diagnosiScale:DiagnosiEScale){

    this.sessionService.setSelectedDiagnosiScale(JSON.stringify(diagnosiScale))

    this.router.navigate(['diagnosiescale-form'])
  }

  loadAllDiagnosi(){

    this.apiService.getAllDiagnosi().subscribe(res => {

      this.listDiagnosi = res
    })

  }

  getDiagnosiNameByCodice(codice:string){

    let name = ''

    this.listDiagnosi.forEach(diag => {

      if(diag.codice === codice){

        name = diag.descrizione!!
      }
    })

    return name;


  }

  contattoIsClosed():boolean{


    return this.contatto.dataChiusura != null || this.contatto.dataChiusura != undefined &&
    this.contatto.codiceDiagnosiChiusura != null || this.contatto.codiceDiagnosiChiusura != undefined

  }

  alert(statoAttuale: ConfStato) {
     return  new CommonsUtil().alert(statoAttuale);
  }

  isValidato(){
    if(this.contatto.statoAttuale.id == 3){
      return true
    } else {
      return false;
    }
  }


  public OPERATORE_STRUTTURA: String = "operatore-struttura";
  public OPERATORE_ASL: String = "operatore-asl";
  public OPERATORE_DSM: String = "operatore-dsm";
  public OPERATORE_REGIONE: String = "operatore-regione";
  public utente: Utente = new Utente();
  public getRuolo(ruolo: String) {
  this.getUtente( );
    if (this.utente) {

      if (this.utente.nomeRuolo == ruolo) {

        return true;

      }

    }

    return false;

  }
  public getUtente() {

    var usr = sessionStorage.getItem("utente");

    if (usr != null && usr != undefined) {

      this.utente = JSON.parse(usr);

    }

  }

  getDescrizioneASL(codiceAslOperatoreInserimento: string | undefined) {
    return this.aslList.find(value => value.codiceAzienda==codiceAslOperatoreInserimento)?.denominazioneAzienda;
  }
}
