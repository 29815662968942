<div class="container py-3" demon-cf="importazionedati" demon-cf-id="anagrafica" id="demonView6-3" demon-ev="true">

  <div class="card mt-5" style="padding: 20px">
    <div class="card-header">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a routerLink="/documentazione"
                                         (click)="$event.preventDefault();">Supporto</a></li>
        </ol>
      </nav>
    </div>
    <div class="card-body">
      <app-docs></app-docs>
    </div>
  </div>
</div>
