import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Strutture, Utente} from "../../../model/Models";

@Component({
  selector: 'app-showdataclick',
  templateUrl: './showdataclick.component.html',
  styleUrls: ['./showdataclick.component.css']
})
export class ShowdataclickComponent implements OnInit {

  public OPERATORE_STRUTTURA: String = "operatore-struttura";
  public OPERATORE_ASL: String = "operatore-asl";
  public OPERATORE_DSM: String = "operatore-dsm";
  public OPERATORE_REGIONE: String = "operatore-regione";

  constructor(private router: Router) {
  }

  @Input("misureValue")
  misureValue: any = null;
  @Input("misureLabel")
  misureLabel: string = "";
  @Input("misureColor")
  misureColor: string = "";
  @Input("misureIcon")
  misureIcon: string = "";
  @Input("misureClick")
  misureClick: string = "";
  @Input("misureYear")
  misureYear: number = new Date().getFullYear();
  @Input("misureAmbito")
  misureAmbito: string = "";
  @Input("misureTipo")
  misureTipo: string = "";
  @Input("misureStruttura")
  misureStruttura: string = "";
  @Input("misureDenomStruttura")
  misureDenomStruttura: string = "";
  @Input("misureAsl")
  misureAsl: string = "";
  public utente: Utente = new Utente();
  public struttura: Strutture = new Strutture();

  ngOnInit(): void {
  }

  /**
   * Intercetta click su card
   * @param url
   */
  cardClick(url: string) {

    if (url != null && url != undefined && url != "") {

      if (this.getRuolo(this.OPERATORE_STRUTTURA)) {

        var struttura = sessionStorage.getItem("struttura");

        if (struttura != null) {

          this.struttura = JSON.parse(struttura);
          let tipoStrutt = "Semiresidenziale";

          if (this.struttura.descrTipoStruttura?.indexOf('TERRITORIALE') != -1) {

            tipoStrutt = "Territoriale";

          }

          this.router.navigateByUrl(url + "&anno=" + this.misureYear + "&ambito=" + tipoStrutt + "&tipo=" + this.struttura.tipoRapportoSsn + "&struttura=" + this.struttura.codice + "&denomStruttura=" + this.struttura.denominazioneStruttura+"&asl="+this.misureAsl);

        }

      } else {

        if(this.misureTipo == "0"){

          this.misureTipo = "";

        }

        this.router.navigateByUrl(url + "&anno=" + this.misureYear + "&ambito=" + this.misureAmbito + "&tipo=" + this.misureTipo + "&struttura=" + this.misureStruttura + "&denomStruttura=" + this.misureDenomStruttura+"&asl="+this.misureAsl);

      }

    }

  }

  public getUtente() {

    var usr = sessionStorage.getItem("utente");

    if (usr != null && usr != undefined) {

      this.utente = JSON.parse(usr);

    }

  }

  /**
   * Ritorna true o false in base all'utente ed il ruolo selezionato
   * ruoli:
   *  operatore-struttura
   *  operatore-asl
   *  operatore-dsm
   * @param ruolo
   * @returns
   */
  public getRuolo(ruolo: String) {
    this.getUtente();
    if (this.utente) {

      if (this.utente.nomeRuolo == ruolo) {

        return true;

      }

    }

    return false;

  }

}
