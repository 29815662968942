<div class="container py-3" demon-cf="anagrafica" demon-cf-id="anagrafica" id="demonView6-3" demon-ev="true">


  <form [formGroup]="contattoForm" (ngSubmit)="submitForm()" autocomplete="off">

    <div class="card">
      <div class="card-header">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">

            <li class="breadcrumb-item"><a (click)="$event.preventDefault();navigateToAnagrafica()"
                                           href="">Anagrafica</a>
            </li>
            <li class="breadcrumb-item"><a (click)="$event.preventDefault();navigateToAnagrafica()"
                                           href="">{{getTitleName()}}</a></li>
          </ol>
        </nav>
        <div class="row">
          <div class="title-3 col-9" demon-contatto="titolo">Contatto #{{contatto.id}}</div>
          <div class="col-3">
            <button class="btn btn-outline-secondary btn-sm"
                    *ngIf="(getRuolo(OPERATORE_STRUTTURA) || getRuolo(OPERATORE_REGIONE)) && contatto?.id && this.tabShowed!=5"
                    (click)="$event.preventDefault(); openStoricoContatti(audit2)">
              Storico modifiche {{getLabelTab()}}
            </button>
          </div>
        </div>

      </div>


      <!--      <button class="btn btn-secondary" type="button" (click)="printValidators()" >-->
      <!--      </button>-->
      <!--ngb-accordion style="padding: 10px" *ngIf="contatto.id">
        <ngb-panel id="storicoanagrafica" [title]="'DATI ANAGRAFICI STORICI'">
          <ng-template ngbPanelContent>
            <app-storico-anagrafica [idContatto]="contatto.id"></app-storico-anagrafica>
          </ng-template>
        </ngb-panel>
      </ngb-accordion-->
      <ul class="nav nav-tabs" id="detailTab" role="tablist">
        <li class="nav-item">
          <a *ngIf="!tabPrestazioniAccessible()" class="nav-link title-4"
             style="opacity: 0.5;cursor:no-drop;">Anagrafica</a>
          <a *ngIf="tabPrestazioniAccessible()" class="nav-link title-4 {{tabPaneAnagraficaClasses()}}" id="anagrafica-tab" data-toggle="tab" href="#anagrafica"
             role="tab" aria-controls="anagrafica" aria-selected="true" (click)="tabShowedAnagrafica()">
            Anagrafica
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link title-4 {{tabContattiClasses()}}" id="contatto-tab" data-toggle="tab" href="#contatto"
             role="tab" aria-controls="contatto" aria-selected="true" (click)="tabShowedContatti()">
            Contatto
          </a>
        </li>

        <!-- interventi tab-->

        <li class="nav-item" *ngIf="tabInterventiVisible()">
          <a *ngIf="!tabPrestazioniAccessible()" class="nav-link title-4" [ngClass]="{'text-danger':interventiError}"
             style="opacity: 0.5;cursor:no-drop;">Interventi</a>
          <a *ngIf="tabPrestazioniAccessible()" class="nav-link title-4 {{tabInterventiClasses()}}" id="interventi-tab"
             [ngClass]="{'text-danger':interventiError}"
             data-toggle="tab" href="#interventi" role="tab" aria-controls="interventi" aria-selected="false"
             (click)="tabShowedInterventi()">
            Interventi
          </a>
        </li>

        <!-- diagnosi scale tab -->

        <li class="nav-item" *ngIf="tabDiagnosiVisible()" style="width: 200px">
          <a *ngIf="!tabPrestazioniAccessible()" class="nav-link title-4" [ngClass]="{'text-danger':diagnosiError}"
             style="opacity: 0.5;cursor:no-drop;">Diagnosi&scale</a>
          <a *ngIf="tabPrestazioniAccessible()" class="nav-link title-4 {{tabDiagnosiEScaleClasses()}}"
             [ngClass]="{'text-danger':diagnosiError}"
             id="diagnosiscale-tab" data-toggle="tab" href="#diagnosiscale" role="tab" aria-controls="diagnosiscale"
             aria-selected="false" (click)="tabShowedDiagnosiScale()">
            Diagnosi&scale
          </a>
        </li>

        <!-- presenze tab-->
        <li class="nav-item" *ngIf="!isTipoPrestazioneTerritoriale()">
          <a *ngIf="!tabPrestazioniAccessible()" class="nav-link title-4" [ngClass]="{'text-danger':presenzeError}"
             style="opacity: 0.5;cursor:no-drop;">Presenze</a>
          <a *ngIf="tabPrestazioniAccessible()" class="nav-link title-4 {{tabPresenzeClasses()}}" id="presenze-tab"
             [ngClass]="{'text-danger':presenzeError}"
             data-toggle="tab" href="#presenze" role="tab" aria-controls="presenze" aria-selected="false"
             (click)="tabShowedPresenze()">
            Presenze
          </a>
        </li>

        <!-- prestazioni tab -->
        <li class="nav-item">
          <a *ngIf="!tabPrestazioniAccessible()" class="nav-link title-4"
             [ngClass]="{'text-danger':prestazioniHasError()}"
             style="opacity: 0.5;cursor:no-drop;">Prestazioni</a>

          <a *ngIf="tabPrestazioniAccessible()" class="nav-link title-4 {{tabPrestazioniClasses()}}"
             [ngClass]="{'text-danger':prestazioniHasError()}"
             id="prestazione-tab" data-toggle="tab" href="#prestazione" role="tab" aria-controls="prestazione"
             aria-selected="false" (click)="tabShowedPrestazioni()">
            Prestazioni
          </a>

        </li>


      </ul>

      <div class="card-body">
        <div class="tab-content" id="detailTabContent">
          <div class="tab-pane fade {{tabPaneAnagraficaClasses()}}" id="anagrafica" role="tabpanel"
               aria-labelledby="contatto-tab">
            <!--button (click)="$event.preventDefault();edit()">Modifica</button-->
            <app-storico-anagrafica *ngIf="!isEdit" [idContatto]="contatto.id"></app-storico-anagrafica>
            <app-storico-anagrafica-edit #strAnaEdit *ngIf="isEdit" [idContatto]="contatto.id"></app-storico-anagrafica-edit>
            <!--app-anagrafica *ngIf="!contatto.id" [inputCF]="anagrafica.codiceFiscale" (anagraficaOKEvent)="anagraficaSalvata($event)" #anagraficapanel></app-anagrafica-->
          </div>
          <!-- Contatto -->
          <div class="tab-pane fade {{tabPaneContattiClasses()}}" id="contatto" role="tabpanel"
               aria-labelledby="contatto-tab">

            <!-- Stato e motivazione scarto -->
            <div class="group pt-4 alert alert-danger" style="background-color: #ffe1cc !important;"
                 *ngIf="contatto.id != null && (contatto.statoAttuale.id == 4 ||contatto.statoAttuale.id ==  7 ||contatto.statoAttuale.id ==  9)">
              <div class="form-group field-10">
                Stato: {{contatto.statoAttuale.descrizione}}
              </div>
              <div class="form-group field-10"
                   *ngIf="contatto.motivazioneScarto != null && contatto.motivazioneScarto != ''">
                Motivazione dello scarto: {{contatto.motivazioneScarto}}
              </div>
            </div>


            <div class="group-inline pt-3">
              <div class="form-group field-10 has-feedback">
                <label>Data richiesta*</label>
                <div class="input-group">
                  <input type="text" name="dataRichiesta" class="form-control" placeholder="aaaa-mm-gg" name="dp"
                         [(ngModel)]="richiestaDataModel" ngbDatepicker #richiestaDato="ngbDatepicker"
                         [minDate]="minDate"
                         [maxDate]="maxDate" formControlName="dataRichiesta"
                         [readonly]="!hasWritePermission(mainUser) || mustDisableForm()">
                  <div class="input-group-append">
                    <button class="btn btn-secondary" (click)="richiestaDato.toggle()" type="button"
                            [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false:null">
                      <img class="img-responsive" src="../assets/icons/calendar_ko.png"
                           style="width: 20px; height:20px">
                    </button>
                  </div>
                </div>

                <div *ngIf="contattoForm.controls['dataRichiesta'].hasError('required') && contattoFormSubmitted"
                     class="alert alert-danger"> Il campo è obbligatorio
                </div>
                <div *ngIf="contattoForm.controls['dataRichiesta'].hasError('ngbDate') && contattoFormSubmitted"
                     class="alert alert-danger"> Il formato della data non è valido
                </div>
              </div>

              <div class="groupo-inline">

              </div>
            </div>

            <div class="form-group field-30" *ngIf="isFormTerritoriale()">
              <label>Richiesta prevalente*</label>
              <div>
                <select class="form-control" name="richiestaPrev" formControlName="richiestaPrev"
                        [attr.disabled]="!hasWritePermission(mainUser)||!canEdit()?'true':null">
                  <option value=""></option>
                  <option [value]="rch.codice" *ngFor="let rch of listRichiestePrevalenti">{{rch.descrizione}}</option>
                </select>
                <div *ngIf="contattoForm.hasError('required', 'richiestaPrev') && contattoFormSubmitted"
                     class="alert alert-danger form-control">Campo obbligatorio
                </div>
              </div>


            </div>
            <hr>

            <div class="title-5">
              Apertura contatto*
            </div>
            <div class="form-group has-feedback" *ngIf="isFormTerritoriale()">

              <div class="form-radio">
                <div class="radio">
                  <input type="radio" name="aperturaFlag" id="aperturaFlag" value="1" formControlName="aperturaFlag"
                         [attr.disabled]="!hasWritePermission(mainUser)? false:null || canEdit() ? null: false"
                         (change)="setAperturaFlag($event)">
                  <label for="aperturaFlag">Si</label>
                </div>
                <div class="radio">
                  <input type="radio" name="aperturaFlag" id="aperturaFlag2" value="2" formControlName="aperturaFlag"
                         [attr.disabled]="!hasWritePermission(mainUser)? false:null || canEdit() ? null: false"
                         (change)="setAperturaFlag($event)">
                  <label for="aperturaFlag2">No</label>
                </div>
              </div>

              <div *ngIf="contattoForm.controls['aperturaFlag'].hasError('required') && contattoFormSubmitted"
                   class="alert alert-danger " style="width: 20%;"> Il campo è obbligatorio
              </div>
            </div>
            <!--            <div>-->
            <!--              this.contattoForm.controls['aperturaFlag'].value {{ this.contattoForm.controls['aperturaFlag'].value }}-->
            <!--              <br> this.aperturaFlag {{ aperturaFlag}}-->
            <!--              <br> aperturaDataModel {{ aperturaDataModel}}-->
            <!--              <br> diagnosiChiusura {{ this.contattoForm.controls['diagnosiChiusura'].value  }}-->
            <!--              <br> modelChiusuraDiagnosi  {{ modelChiusuraDiagnosi }}-->
            <!--              <br> chiusuraDataModel {{ chiusuraDataModel }}-->
            <!--            </div>-->


            <div class="group-inline pt-4" [hidden]="gruppoAperturaHidden()">
              <div class="form-group field-10 has-feedback">
                <label>Data*</label>
                <div class="input-group">
                  <input type="text" class="form-control" placeholder="aaaa-mm-gg" name="dp"
                         [(ngModel)]="aperturaDataModel" ngbDatepicker #aperturaDato="ngbDatepicker" name="dataApertura"
                         [minDate]="minAperturaDateModel!!" [maxDate]="maxDate" formControlName="dataApertura"
                         [readonly]="!hasWritePermission(mainUser) || isAperturaFlagTerritorialeNo() || mustDisableForm()">
                  <div class="input-group-append">
                    <button class="btn btn-secondary" (click)="aperturaDato.toggle()" type="button"
                            [attr.disabled]="!hasWritePermission(mainUser)? false:null || isAperturaFlagTerritorialeNo()? false:null || mustDisableForm()? false:null">
                      <img class="img-responsive" src="../assets/icons/calendar_ko.png"
                           style="width: 20px; height:20px">
                    </button>
                  </div>
                </div>

                <div *ngIf="contattoForm.hasError('required', 'dataApertura') && contattoFormSubmitted"
                     class="alert alert-danger">Campo obbligatorio
                </div>
                <div *ngIf="contattoForm.hasError('dateExist', 'dataApertura') && contattoFormSubmitted"
                     class="alert alert-danger">
                  Questo data ha già un contatto
                </div>
                <div *ngIf="contattoForm.controls['dataApertura'].hasError('ngbDate') && contattoFormSubmitted"
                     class="alert alert-danger">
                  Il formato della data non è valido
                </div>
              </div>
              <div class="form-group field-20 has-feedback">
                <label>Diagnosi*</label>
                <input id="typeahead-focus" type="text" class="form-control" [(ngModel)]="modelAperturaDiagnosi" (change)="changeDiagnosi(confermachangediagnosi, true)"
                       [ngbTypeahead]="diagnosiSearch" (focus)="focus$.next($any($event).target.value)"
                       (click)="click$.next($any($event).target.value)" #instance="ngbTypeahead"
                       [inputFormatter]="formatter"
                       [resultFormatter]="resultFormatter" [editable]="false" formControlName="diagnosiApertura"
                       [readonly]="!hasWritePermission(mainUser)||!canEdit()||isAperturaFlagTerritorialeNo()?'true':null"
                />
                <div
                  *ngIf="contattoForm.controls['diagnosiApertura'].hasError('emptyDiagnosiError') && contattoFormSubmitted"
                  class="alert alert-danger">Campo obbligatorio
                </div>
                <div
                  *ngIf="contattoForm.controls['diagnosiApertura'].hasError('wrongDiagnosiError')  && contattoFormSubmitted"
                  class="alert alert-danger">Il valore deve essere selezionato tra quelli proposti
                </div>
              </div>

              <div class="form-group field-40">
                <label>Precedenti contatti*</label>
                <div>
                  <select class="form-control" name="precedentiContatti" formControlName="precedentiContatti"
                          [attr.disabled]="!hasWritePermission(mainUser)||!canEdit()?'true':null">
                    <option value=""></option>
                    <option [value]="elem.codice" *ngFor="let elem of listPrecedentiContatti">{{elem.descrizione}}
                    </option>
                  </select>
                </div>

                <div *ngIf="contattoForm.hasError('required', 'precedentiContatti') && contattoFormSubmitted"
                     class="alert alert-danger">Campo obbligatorio
                </div>

              </div>

              <div class="form-group field-40">
                <label>Inviante*</label>
                <div>
                  <select class="form-control" name="inviante" formControlName="inviante"
                          [attr.disabled]="!hasWritePermission(mainUser)||!canEdit()?'true':null">
                    <option value=""></option>
                    <option [value]="inv.codice" *ngFor="let inv of listInvianti">{{inv.descrizione}}</option>
                  </select>


                </div>

                <div *ngIf="contattoForm.hasError('required', 'inviante') && contattoFormSubmitted"
                     class="alert alert-danger">Campo obbligatorio
                </div>


              </div>

            </div>


            <div *ngIf="!isFormTerritoriale()">
              <hr>
              <div class="title-5">
                Progetto di intervento
              </div>
              <div class="group-inline pt-4">

                <div class="form-group">
                  <label>Riabilitativi</label>
                  <div class="form-radio">
                    <div class="radio">
                      <input type="radio" name="interventoRiabilitativi" id="interventoRiabilitativiSi" value="true"
                             formControlName="interventoRiabilitativi"
                             [attr.disabled]="!hasWritePermission(mainUser)||!canEdit()?'true':null">
                      <label for="interventoRiabilitativiSi">Sì</label>
                    </div>
                    <div class="radio">
                      <input type="radio" name="interventoRiabilitativi" id="interventoRiabilitativiNo" value="false"
                             formControlName="interventoRiabilitativi"
                             [attr.disabled]="!hasWritePermission(mainUser)||!canEdit()?'true':null">
                      <label for="interventoRiabilitativiNo">No</label>
                    </div>
                    <div *ngIf="contattoForm.hasError('required', 'interventoRiabilitativi') && contattoFormSubmitted"
                         class="alert alert-danger">Campo obbligatorio
                    </div>
                  </div>


                </div>
                <div class="form-group">
                  <label>Inserimenti lavorativi</label>
                  <div class="form-radio">
                    <div class="radio">
                      <input type="radio" name="interventoInserimentiLavorativi" id="interventoInserimentiLavorativiSi"
                             value="true" formControlName="interventoInserimentiLavorativi"
                             [attr.disabled]="!hasWritePermission(mainUser)||!canEdit()?'true':null">
                      <label for="interventoInserimentiLavorativiSi">Sì</label>
                    </div>
                    <div class="radio">
                      <input type="radio" name="interventoInserimentiLavorativi" id="interventoInserimentiLavorativiNo"
                             value="false" formControlName="interventoInserimentiLavorativi"
                             [attr.disabled]="!hasWritePermission(mainUser)||!canEdit()?'true':null">
                      <label for="interventoInserimentiLavorativiNo">No</label>
                    </div>

                    <div
                      *ngIf="contattoForm.hasError('required', 'interventoInserimentiLavorativi') && contattoFormSubmitted"
                      class="alert alert-danger">Campo obbligatorio
                    </div>
                  </div>


                </div>
                <div class="form-group">
                  <label>Interv. sociale e/o di rete</label>
                  <div class="form-radio">
                    <div class="radio">
                      <input type="radio" name="interventoInterveniSociali" id="interventoInterveniSocialiSi"
                             value="true" formControlName="interventoInterveniSociali"
                             [attr.disabled]="!hasWritePermission(mainUser)||!canEdit()?'true':null">
                      <label for="interventoInterveniSocialiSi">Sì</label>
                    </div>
                    <div class="radio">
                      <input type="radio" name="interventoInterveniSociali" id="interventoInterveniSocialiNo"
                             value="false" formControlName="interventoInterveniSociali"
                             [attr.disabled]="!hasWritePermission(mainUser)||!canEdit()?'true':null">
                      <label for="interventoInterveniSocialiNo">No</label>
                    </div>

                    <div
                      *ngIf="contattoForm.hasError('required', 'interventoInterveniSociali') && contattoFormSubmitted"
                      class="alert alert-danger">Campo obbligatorio
                    </div>
                  </div>
                </div>


                <div class="form-group">
                  <label>Colloqui psichiatrici e psicologici di sostegno</label>
                  <div class="form-radio">
                    <div class="radio">
                      <input type="radio" name="interventoColloquiPsichiatrici" id="interventoColloquiPsichiatriciSi"
                             value="true" formControlName="interventoColloquiPsichiatrici"
                             [attr.disabled]="!hasWritePermission(mainUser)||!canEdit()?'true':null">
                      <label for="interventoColloquiPsichiatriciSi">Sì</label>
                    </div>
                    <div class="radio">
                      <input type="radio" name="interventoColloquiPsichiatrici" id="interventoColloquiPsichiatriciNo"
                             value="false" formControlName="interventoColloquiPsichiatrici"
                             [attr.disabled]="!hasWritePermission(mainUser)||!canEdit()?'true':null">
                      <label for="interventoColloquiPsichiatriciNo"
                      >No</label>
                    </div>
                    <div
                      *ngIf="contattoForm.hasError('required', 'interventoColloquiPsichiatrici') && contattoFormSubmitted"
                      class="alert alert-danger">Campo obbligatorio
                    </div>
                  </div>
                </div>


                <div class="form-group">
                  <label>Coinvolgimento/sostegno ai familiari</label>
                  <div class="form-radio">
                    <div class="radio">
                      <input type="radio" name="interventoCoinvolgimento" id="interventoCoinvolgimentoSi" value="true"
                             formControlName="interventoCoinvolgimento"
                             [attr.disabled]="!hasWritePermission(mainUser)||!canEdit()?'true':null">

                      <label for="interventoCoinvolgimentoSi">Sì</label>
                    </div>
                    <div class="radio">
                      <input type="radio" name="interventoCoinvolgimento" id="interventoCoinvolgimentoNo" value="false"
                             formControlName="interventoCoinvolgimento"
                             [attr.disabled]="!hasWritePermission(mainUser)||!canEdit()?'true':null">
                      <label for="interventoCoinvolgimentoNo">No</label>
                    </div>
                    <div *ngIf="contattoForm.hasError('required', 'interventoCoinvolgimento') && contattoFormSubmitted"
                         class="alert alert-danger">Campo obbligatorio
                    </div>
                  </div>
                </div>


                <div class="form-group field-wide">
                  <label>Altro</label>
                  <input type="text" class="form-control" name="altro" formControlName="altro"
                         [attr.disabled]="!hasWritePermission(mainUser)||!canEdit()?'true':null">

                  <div *ngIf="contattoForm.hasError('required', 'altro') && contattoFormSubmitted"
                       class="alert alert-danger">Campo obbligatorio
                  </div>
                </div>


              </div>
            </div>


            <hr *ngIf="!contattoIsNew(contatto)">


            <div [hidden]="chiusuraGroupHidden()">

              <div class="title-5" *ngIf="!contattoIsNew(contatto)">
                Chiusura
              </div>
              <!-- Chiusura territoriale-->

              <div class="pt-4"
                   *ngIf="!contattoIsNew(contatto) && contatto.tipoPrestazione == 'Territoriale' && !(contatto.dataChiusura)">
                <div class="alert alert-warning" style="background-color: #ffe1cc !important;"
                     *ngIf="!canCloseTerriotriale()">
                  Attenzione non è possibile chiudere il contatto perché:
                  <ul>
                    <li *ngIf="contatto && contatto.interventiList && contatto.interventiList.length < 1"> Non è
                      presente nessun intervento.
                    </li>
                    <li *ngIf="contatto && contatto.diagnosiEScaleList && contatto.diagnosiEScaleList.length < 1">Non è
                      presente nessuna diagnosi.
                    </li>
                    <li *ngIf="contatto && contatto.prestazioniList && contatto.prestazioniList.length < 1">Non è
                      presente nessuna prestazione.
                    </li>
                  </ul>
                </div>
                <div>
                  <button class="btn btn-primary" type="button"
                          (click)="openchiudiContattoTerritoriale(chiudiContattoTerritoriale, contattoForm)"
                          [attr.disabled]="!hasWritePermission(mainUser)||!canCloseTerriotriale()? false:null">
                    Chiudi contatto territoriale
                  </button>
                </div>
              </div>
              <!-- Chiusura semi residenziale-->

              <div class="group-inline pt-4"
                   *ngIf="!contattoIsNew(contatto) && (contatto.tipoPrestazione != 'Territoriale' || (contatto.dataChiusura))">
                <div class="form-group field-10 has-feedback">
                  <label>Data</label>
                  <div class="input-group">
                    <input type="text" name="dataChiusura" class="form-control" placeholder="aaaa-mm-gg" name="dp"
                           [(ngModel)]="chiusuraDataModel" ngbDatepicker #chiusuraDato="ngbDatepicker"
                           [minDate]="minChiusuraDateModel" [maxDate]="maxDate" formControlName="dataChiusura"
                           onchange="observeDataChiusuraChange()"
                           [readonly]="!hasWritePermission(mainUser) || !canClose()">
                    <div class="input-group-append">
                      <button class="btn btn-secondary" (click)="chiusuraDato.toggle()" type="button"
                              [attr.disabled]="!hasWritePermission(mainUser)? false:null || canClose()? null:false">
                        <img class="img-responsive" src="../assets/icons/calendar_ko.png"
                             style="width: 20px; height:20px">
                      </button>
                      <!-- <span class="input-group-text">
                                <svg class="s-icon icon-xs">
                                  <use xlink:href="/proto/flussi-lazio/flussi-lazio-v2.0.0/imgs/icons/sprite.svg#calendar"></use>
                                </svg>
                              </span> -->
                    </div>
                  </div>

                  <div *ngIf="contattoForm.hasError('required', 'dataChiusura') && contattoFormSubmitted"
                       class="alert alert-danger">Campo obbligatorio
                  </div>
                </div>
                <div *ngIf="(contatto.tipoPrestazione != 'Territoriale' || (contatto.dataChiusura)) "
                     class="form-group field-20 has-feedback">
                  <label>Diagnosi
                    <!-- <span *ngIf="isDiagnosiChiusuraRequired()">*</span> -->
                  </label>

                  <input *ngIf="(contatto.tipoPrestazione != 'Territoriale')" id="typeahead-focus_2" type="text" class="form-control" [(ngModel)]="modelChiusuraDiagnosi"
                         [ngbTypeahead]="diagnosiChiusuraSearch"
                         (focus)="focusChiusura$.next($any($event).target.value)"
                         (click)="clickChiusura$.next($any($event).target.value)" #instanceChiusura="ngbTypeahead"
                         [inputFormatter]="chiusuraformatter" [resultFormatter]="resultChiusuraformatter"
                         [editable]="false"
                         formControlName="diagnosiChiusura"
                         [attr.disabled]="!hasWritePermission(mainUser)? false:null || canClose()? null:false"/>
                  <input *ngIf="(contatto.tipoPrestazione == 'Territoriale')" id="typeahead-focus_2" type="text" class="form-control" [(ngModel)]="modelChiusuraDiagnosi"
                         [ngbTypeahead]="diagnosiChiusuraSearch"
                         (focus)="focusChiusura$.next($any($event).target.value)" (change)="changeDiagnosi(confermachangediagnosi, false)"
                         (click)="clickChiusura$.next($any($event).target.value)" #instanceChiusura="ngbTypeahead"
                         [inputFormatter]="chiusuraformatter" [resultFormatter]="resultChiusuraformatter"
                         [editable]="false"
                         formControlName="diagnosiChiusura"
                         [attr.disabled]="!hasWritePermission(mainUser)? false:null || canClose()? null:false"/>

                  <div
                    *ngIf="contattoForm.controls['diagnosiChiusura'].hasError('wrongDiagnosiError') && contattoFormSubmitted"
                    class="alert alert-danger">Il valore deve essere selezionato tra quelli proposti
                  </div>
                  <div *ngIf="contattoForm.hasError('required','diagnosiChiusura')
                              && contattoFormSubmitted" class="alert alert-danger">Campo obbligatorio
                  </div>
                </div>
                <div *ngIf="(contatto.tipoPrestazione != 'Territoriale' || (contatto.dataChiusura))"
                     class="form-group field-40">
                  <label>Modalità conclusione<span *ngIf="isModalitaConclusioneRequired()">*</span>
                  </label>
                  <div>
                    <select class="form-control" name="modalitaConclusione" formControlName="modalitaConclusione"
                            [attr.disabled]="!hasWritePermission(mainUser)? false:null || canClose()? null:false">
                      <option value=""></option>
                      <option [value]="inv.codice" *ngFor="let inv of listaModalitaConclusione">{{inv.descrizione}}
                      </option>
                    </select>
                  </div>
                  <div *ngIf="contattoForm.hasError('required','modalitaConclusione')
                              && contattoFormSubmitted" class="alert alert-danger">Campo obbligatorio
                  </div>

                </div>
              </div>


              <div *ngIf="isContattoSemiResideziale(contatto.tipoPrestazione!!) && dataChiusuraExist()">
                <hr>
                <div class="title-5">
                  Intervento effettuato
                </div>
                <div class="group-inline pt-4">
                  <div class="form-group">
                    <label>Riabilitativi</label>
                    <div class="form-radio">
                      <div class="radio">
                        <input type="radio" name="interventoEffettuatoRiabilitativi"
                               id="interventoEffettuatoRiabilitativiSi" value="true"
                               formControlName="interventoEffettuatoRiabilitativi"
                               [attr.disabled]="!hasWritePermission(mainUser)? false:null || canClose()? null:false">
                        <label for="interventoEffettuatoRiabilitativiSi">Sì</label>
                      </div>
                      <div class="radio">
                        <input type="radio" name="interventoEffettuatoRiabilitativi"
                               id="interventoEffettuatoRiabilitativiNo" value="false"
                               formControlName="interventoEffettuatoRiabilitativi"
                               [attr.disabled]="!hasWritePermission(mainUser)? false:null || canClose()? null:false">
                        <label for="interventoEffettuatoRiabilitativiNo">No</label>
                      </div>
                      <div *ngIf="contattoForm.hasError('required','interventoEffettuatoRiabilitativi')
                              && contattoFormSubmitted" class="alert alert-danger">Campo obbligatorio
                      </div>

                    </div>
                  </div>

                  <div class="form-group">
                    <label>Inserimenti lavorativi</label>
                    <div class="form-radio">
                      <div class="radio">
                        <input type="radio" name="interventoEffettuatoLavorativi" id="interventoEffettuatoLavorativiSi"
                               value="true" formControlName="interventoEffettuatoLavorativi"
                               [attr.disabled]="!hasWritePermission(mainUser)? false:null || canClose()? null:false">
                        <label for="interventoEffettuatoLavorativiSi">Sì</label>
                      </div>
                      <div class="radio">
                        <input type="radio" name="interventoEffettuatoLavorativi" id="interventoEffettuatoLavorativiNO"
                               value="false" formControlName="interventoEffettuatoLavorativi"
                               [attr.disabled]="!hasWritePermission(mainUser)? false:null || canClose()? null : false ">
                        <label for="interventoEffettuatoLavorativiNO">No</label>
                      </div>
                      <div *ngIf="contattoForm.hasError('required','interventoEffettuatoLavorativi')
                      && contattoFormSubmitted" class="alert alert-danger">Campo obbligatorio
                      </div>
                    </div>
                  </div>


                  <div class="form-group">
                    <label>Interv. sociale e/o di rete</label>
                    <div class="form-radio">
                      <div class="radio">
                        <input type="radio" name="interventoEffettuatoInterveniSociali"
                               id="interventoEffettuatoInterveniSocialiSi" value="true"
                               formControlName="interventoEffettuatoInterveniSociali"
                               [attr.disabled]="!hasWritePermission(mainUser)? false:null || canClose()? null : false">
                        <label for="interventoEffettuatoInterveniSocialiSi">Sì</label>
                      </div>
                      <div class="radio">
                        <input type="radio" name="interventoEffettuatoInterveniSociali"
                               id="interventoEffettuatoInterveniSocialiNO" value="false"
                               formControlName="interventoEffettuatoInterveniSociali"
                               [attr.disabled]="!hasWritePermission(mainUser)? false:null || canClose()? null : false">
                        <label for="interventoEffettuatoInterveniSocialiNO">No</label>
                      </div>

                      <div *ngIf="contattoForm.hasError('required','interventoEffettuatoInterveniSociali')
                      && contattoFormSubmitted" class="alert alert-danger">Campo obbligatorio
                      </div>
                    </div>
                  </div>


                  <div class="form-group">
                    <label>Colloqui psichiatrici e psicologici di sostegno</label>
                    <div class="form-radio">
                      <div class="radio">
                        <input type="radio" name="interventoEffettuatoColloquiPsichiatrici"
                               id="interventoEffettuatoColloquiPsichiatriciSi" value="true"
                               formControlName="interventoEffettuatoColloquiPsichiatrici"
                               [attr.disabled]="!hasWritePermission(mainUser)? false:null || canClose()? null : false">
                        <label for="interventoEffettuatoColloquiPsichiatriciSi">Sì</label>
                      </div>
                      <div class="radio">
                        <input type="radio" name="interventoEffettuatoColloquiPsichiatrici"
                               id="interventoEffettuatoColloquiPsichiatriciNO" value="false"
                               formControlName="interventoEffettuatoColloquiPsichiatrici"
                               [attr.disabled]="!hasWritePermission(mainUser)? false:null || canClose()? null : false">
                        <label for="interventoEffettuatoColloquiPsichiatriciNO">No</label>
                      </div>

                      <div *ngIf="contattoForm.hasError('required','interventoEffettuatoColloquiPsichiatrici')
                      && contattoFormSubmitted" class="alert alert-danger">Campo obbligatorio
                      </div>
                    </div>
                  </div>


                  <div class="form-group">
                    <label>Coinvolgimento/sostegno ai familiari</label>
                    <div class="form-radio">
                      <div class="radio">
                        <input type="radio" name="interventoEffettuatoCoinvolgimento"
                               id="interventoEffettuatoCoinvolgimentoSi" value="true"
                               formControlName="interventoEffettuatoCoinvolgimento"
                               [attr.disabled]="!hasWritePermission(mainUser)? false:null || canClose()? null : false">
                        <label for="interventoEffettuatoCoinvolgimentoSi">Sì</label>
                      </div>
                      <div class="radio">
                        <input type="radio" name="interventoEffettuatoCoinvolgimento"
                               id="interventoEffettuatoCoinvolgimentoNo" value="false"
                               formControlName="interventoEffettuatoCoinvolgimento"
                               [attr.disabled]="!hasWritePermission(mainUser)? false:null || canClose()? null : false">
                        <label for="interventoEffettuatoCoinvolgimentoNo">No</label>
                      </div>

                      <div *ngIf="contattoForm.hasError('required','interventoEffettuatoCoinvolgimento')
                      && contattoFormSubmitted" class="alert alert-danger">Campo obbligatorio
                      </div>
                    </div>
                  </div>


                  <div class="form-group field-wide">
                    <label>Altro</label>
                    <input type="text" class="form-control" name="interventoEffettuatoAltro"
                           formControlName="interventoEffettuatoAltro"
                           [attr.disabled]="!hasWritePermission(mainUser)? false:null || canClose()? null : false">
                    <div *ngIf="contattoForm.hasError('required','interventoEffettuatoAltro') && contattoFormSubmitted"
                         class="alert alert-danger">
                      Campo obbligatorio
                    </div>
                  </div>

                </div>


              </div>

            </div>


          </div>


          <!-- Prestazioni -->
          <div class="tab-pane fade {{tabPanePrestazioniClasses()}}" id="prestazione" role="tabpanel"
               aria-labelledby="prestazione-tab">


            <!-- Risultati -->
            <div class="pt-3">
              <div class="table-responsive">
                <div class="list-table table-even">

                  <table class="table-lg" demon-cf="tableList" demon-cf-id="anagraficaPrestazioni-1" demon-ev="true"
                         demon-done="3">
                    <thead app-thead [theadColumns]="theadColumns" [tableSorter]="tableSorter"
                           (changeOrder)="changeOrder()">
                    </thead>

                    <tbody>
                    <tr *ngIf="page.loading">
                      <td [attr.colspan]="theadColumns.length">
                        <div class="spinner-border centered"></div>
                      </td>
                    </tr>
                    <ng-container  *ngFor="let prestazione of page.content">
                      <tr (click)="selectPrestazione(prestazione)" class="tr-hover demon-cloned"
                          data-demon-tr="6-anagraficaPrestazioni-1-1"
                          *ngIf="!page.loading && ( getRuolo(OPERATORE_STRUTTURA)||getRuolo(OPERATORE_REGIONE)  || prestazione.statoAttuale.id > 1 )">
                        <td class="td-hover" data-label="Alert" style="text-align: center">
                          <div><img *ngIf="alert(prestazione.statoAttuale)" src="assets/icons/warning.png"/></div>
                        </td>
                        <td class="td-hover" data-label="ID">
                          <div>{{prestazione.id}}</div>
                        </td>
                        <td class="td-hover" data-label="ID">
                          <div>{{prestazione.codiceStrutturaOperatoreInserimento}} - {{prestazione.descrizioneStrutturaOperatoreInserimento}} - {{getDescrizioneASL(prestazione.codiceAslOperatoreInserimento)}}</div>
                        </td>
                        <td class="td-hover" data-label="Data">
                          <div>{{contatto.dataApertura | date:'yyyy-MM-dd'}}</div>
                        </td>
                        <!-- <td class="td-hover" data-label="Nr°">
                          <div>{{prestazione.nrInseritoPrestazione}}</div>
                        </td> -->
                        <td class="td-hover" data-label="Data">
                          <div>{{prestazione.dataPrestazione|date:'yyyy-MM-dd'}}</div>
                        </td>
                        <td class="td-hover" data-label="Tipo">
                          <div>{{formatText(prestazione.descrizioneTipo!!)}}</div>
                        </td>
                        <!-- <td *ngIf="isTipoPrestazioneTerritoriale()" class="td-hover" data-label="Modalità erogazione">
                          <div>{{prestazione.descrizioneModalitaErogazione}}</div>
                        </td> -->
                        <td class="td-hover" data-label="Stato">
                          <div>{{descrizioneStato(prestazione.statoAttuale.id)}}</div>
                          <!-- <div>{{contattoDto.statoAttuale}}</div> -->
                        </td>
                        <!--                        <td class="td-hover" data-label="Stato trasmissione">-->
                        <!--                          <div><span-->
                        <!--                            [ngClass]="getTransmisionState(prestazione.statoTrasmissione!!)">{{prestazione.statoTrasmissione}}</span>-->
                        <!--                          </div>-->
                        <!--                        </td>-->
                      </tr>
                    </ng-container>

                    </tbody>
                  </table>


                </div>


              </div>

              <app-paginator [page]="page" (cambiaPaginaEvent)="cambiaPagina()">
              </app-paginator>


              <div class="btn-toolbar">
                <div class="btn-group-left">
                </div>
                <!-- aggiungiPrestazioneVisibile() &&  -->
                <div class="btn-group-center">
                  <button
                    *ngIf="(!isValidato() || this.contatto.dataChiusura === undefined || this.contatto.dataChiusura === null)  && this.contatto.aperturaFlag === '1' && getRuolo(OPERATORE_STRUTTURA)"
                    type="button"
                    class="btn btn-secondary" demon-bt="prestazioneNuova" (click)="openPrestazioneForm()"
                    [disabled]="!presenzeExist() && !isFormTerritoriale()">
                    Aggiungi prestazione
                  </button>
                </div>
                <div class="btn-group-right">
                </div>
              </div>

            </div>

          </div>

          <!-- Interventi -->
          <div class="tab-pane fade {{tabPaneInterventiClasses()}}" id="interventi" role="tabpanel"
               aria-labelledby="interventi-tab">

            <app-interventi-tab (hasError)="setInterventiError($event)"></app-interventi-tab>


          </div>

          <!-- DiagnosiScale -->

          <div class="tab-pane fade {{tabPaneDiagnosiScaleClasses()}}" id="diagnosiscale" role="tabpanel"
               aria-labelledby="diagnosiscale-tab">

            <app-diagnosi-scale-tab (hasError)="setDiagnosiError($event)"></app-diagnosi-scale-tab>


          </div>


          <!-- DiagnosiScale -->

          <div class="tab-pane fade {{tabPanePresenzeClasses()}}" id="presenze" role="tabpanel"
               aria-labelledby="presenze-tab">

            <app-presenze-tab (hasError)="setPresenzeError($event)"></app-presenze-tab>


          </div>

        </div>


      </div>
    </div>

  </form>


  <block-ui>

  </block-ui>

  <!-- footer -->
  <div class="btn-fixed fixed" demon-cf="toolbarFixed" demon-ev="true" demon-cf-id="6">
    <div class="btn-toolbar">
      <div class="container">
        <div class="btn-group-left">
          <button type="button" class="btn btn-outline-secondary" demon-bt="indietro" demon-cf="ModalConfirm"
                  demon-body="Tornando indietro perderai i dati inseriti nell'anagrafica, vuoi continuare comunque?"
                  demon-call="$D._sbObj.oanagrafica.evIndietro" demon-ev="true" demon-cf-id="7"
                  (click)="openGoBackDialog(content)">
            Torna alla lista
          </button>
        </div>
        <div class="btn-group-right">

          <button *ngIf="cancellaVisibile()" [attr.disabled]="mustDisableCancelBtn()? false: null" type="button"
                  class="btn btn-outline-danger" (click)="cancellaContatto()"
                  [disabled]="deleteVisible">Cancella contatto
          </button>
        </div>
        <div class="btn-group-right">
          <button *ngIf="editVisibile()" (click)="this.edit()" class="btn {{isEdit?'btn-warning':'btn-success'}}">{{isEdit?"Annulla":"Modifica"}}
          </button>
          <button *ngIf="isEdit" type="button" class="btn btn-secondary"
                  (click)="salvaAnagrafica()" >Salva anagrafica
          </button>
          <button *ngIf="salvaVisibile()" type="submit" class="btn btn-secondary"
                  (click)="submitForm()" [disabled]="contattoForm.pristine">Salva
          </button>

        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header" style="background-color: #012346 !important; cursor: pointer;">
    <h4 class="modal-title" id="modal-basic-title_1" style="color: white;">Attenzione</h4>
    <a (click)="modal.dismiss('Cross click')">
      <svg class="s-icon icon-xxs">
        <use xlink:href="assets/icons/sprite.svg#close"></use>
      </svg>
    </a>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <label>Tornando indietro perderai i dati inseriti, vuoi continuare comunque?</label>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Back click')">Ok</button>
  </div>
</ng-template>
<ng-template #confermachangediagnosi let-modal>
  <div class="modal-header" style="background-color: #012346 !important; cursor: pointer;">
    <h4 class="modal-title" id="modal-basic-title_1" style="color: white;">Attenzione</h4>
    <a (click)="modal.dismiss('Cross click')">
      <svg class="s-icon icon-xxs">
        <use xlink:href="assets/icons/sprite.svg#close"></use>
      </svg>
    </a>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <label>{{messagechangediagnosi}}</label>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('')">Ok</button>
  </div>
</ng-template>
<ng-template #cancella let-modal>
  <div class="modal-header" style="background-color: #012346 !important; cursor: pointer;">
    <h4 class="modal-title" id="modal-basic-title" style="color: white;">Attenzione</h4>
    <a (click)="modal.dismiss('Cross click')">
      <svg class="s-icon icon-xxs">
        <use xlink:href="assets/icons/sprite.svg#close"></use>
      </svg>
    </a>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <label>Sei sicuro di voler cancellare il contatto con id {{contatto!!.id!!}} ?</label>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" (click)="cancellaContatto()">Cancella</button>
  </div>
</ng-template>


<ng-template #chiudiContatto let-modal>
  <div class="modal-header" style="background-color: #012346 !important; cursor: pointer;">
    <h4 class="modal-title" id="modal-basic-title_2" style="color: white;">Attenzione</h4>
    <a (click)="modal.dismiss('Cross click')">
      <svg class="s-icon icon-xxs">
        <use xlink:href="assets/icons/sprite.svg#close"></use>
      </svg>
    </a>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <label *ngIf="chiudiContattoMode!='tutto'">Sei sicuro di voler chiudere il contatto con id {{contatto!!.id!!}}
          ?</label>
        <label *ngIf="chiudiContattoMode=='tutto'">Sei sicuro di voler cancellare il contatto con id {{contatto!!.id!!}}
          ?</label>
      </div>

      <div class="row">
        <label class="text mt-4" *ngIf="isFormTerritoriale() && contatto.interventiList && contatto.interventiList.length>0">Si cancellano anche {{contatto.interventiList?.length}}
          Interventi</label>
        <label class="text mt-4" *ngIf="isFormTerritoriale() && contatto.diagnosiEScaleList && contatto.diagnosiEScaleList.length>0">Si cancellano anche
          {{contatto.diagnosiEScaleList?.length}} DiagnosiEscale</label>
        <label class="text mt-4" *ngIf="!isFormTerritoriale() && contatto.presenzaList && contatto.presenzaList.length>0">Si cancellano anche {{contatto.presenzaList?.length}}
          Presenze</label>
        <label class="text mt-4" *ngIf="contatto.prestazioniList&&contatto.prestazioniList.length>0">Si cancellano anche {{contatto.prestazioniList?.length}} Prestazioni</label>
      </div>
      <div class="row" *ngIf="listContatti&&listContatti!.length<2" style="border: 1px solid red;border-radius: 0.3rem; padding: 10px">
        La cancellazione del contatto comporta la cancellazione delle informazioni presenti nella tabella anagrafica del contatto
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" (click)="chiudiAllContatto(contatto.id!!)">Conferma</button>
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('')">Annulla</button>
  </div>
</ng-template>
<ng-template #chiudiContattoTerritoriale let-modal>
  <div class="modal-header" style="background-color: #012346 !important; cursor: pointer;">
    <h4 class="modal-title" id="modal-basic-title_c_c_t" style="color: white;">Chiusura contatto territoriale</h4>
    <a (click)="modal.dismiss('Cross click')">
      <svg class="s-icon icon-xxs">
        <use xlink:href="assets/icons/sprite.svg#close"></use>
      </svg>
    </a>
  </div>
  <div class="modal-body" [formGroup]="contattoForm">
    <div class="container">

      <div class="form-group form-inline field-20 has-feedback" *ngIf="lastDiagnosiEScale">
        <label style="width: 260px; justify-content: end; padding: 15px;">Ultima diagnosi
          del {{lastDiagnosiEScale.data}}:</label>
        <span class="text-secondary" style="padding: 10px; font-size: 110%"> <i>{{lastDiagnosiEScale.diagnosi}}
          : {{lastDiagnosiEScale.descrizioneDiagnosi}}</i></span>
      </div>
      <div style="text-align: center; margin-bottom: 25px" *ngIf="lastDiagnosiEScale"><p style="font-size: 20px">
        Confermi l'ultima diagnosi?</p>
        <div class="btn-group" data-toggle="buttons" style="padding: 10px">
          <label class="btn btn-secondary" [class.active]="confermadiagnosichiusuraterritoriale=='true'">
            <input type="radio" name="options" id="option1" (click)="confermadiagnosichiusuraterritoriale='true'"> SI
            CONFERMO
          </label>
          <label class="btn btn-secondary" [class.active]="confermadiagnosichiusuraterritoriale=='false'">
            <input type="radio" name="options" id="option2" (click)="confermadiagnosichiusuraterritoriale='false'"> NO
            NON CONFERMO
          </label>
        </div>
      </div>
      <div style="text-align: center" *ngIf="!lastDiagnosiEScale">
        <p class="text-danger">Attenzione!! Non è presente nessuna diagnosi:
          <button type="button" class="btn btn-outline-secondary" (click)="openInterventoForm(); modal.dismiss()">Crea
            una nuova diagnosi
          </button>
        </p>
      </div>
      <div style="text-align: center" *ngIf="confermadiagnosichiusuraterritoriale=='false'">
        <p>Se non vuoi confermare l'ultima diagnosi,
          <button type="button" class="btn btn-outline-secondary btn-sm"
                  (click)="openInterventoForm(); modal.dismiss()">Crea una nuova diagnosi
          </button>
        </p>
      </div>

      <div *ngIf="confermadiagnosichiusuraterritoriale=='true'">
        <div class="form-group form-inline field-10 has-feedback">
          <label style="width: 260px; justify-content: end; padding: 15px;">Data chiusura:* </label>
          <div class="input-group">
            <input type="text" name="dataChiusura" class="form-control" placeholder="aaaa-mm-gg" name="dp"
                   [(ngModel)]="chiusuraDataModel" ngbDatepicker #chiusuraDato="ngbDatepicker"
                   [minDate]="minChiusuraDateModel" [maxDate]="maxDate" formControlName="dataChiusura"
                   [readonly]="!hasWritePermission(mainUser) || !canClose()">

            <div class="input-group-append">
              <button class="btn btn-secondary" (click)="chiusuraDato.toggle()" type="button"
                      [attr.disabled]="!hasWritePermission(mainUser)? false:null || canClose()? null:false">
                <img class="img-responsive" src="../assets/icons/calendar_ko.png"
                     style="width: 20px; height:20px">
              </button>
              <!-- <span class="input-group-text">
                        <svg class="s-icon icon-xs">
                          <use xlink:href="/proto/flussi-lazio/flussi-lazio-v2.0.0/imgs/icons/sprite.svg#calendar"></use>
                        </svg>
                      </span> -->
            </div>
          </div>

          <div *ngIf="contattoForm.hasError('required', 'dataChiusura') "
               class="alert alert-danger">Campo obbligatorio
          </div>
        </div>

        <div class="form-group form-inline field-40">
          <label style="width: 260px; justify-content: end; padding: 15px;">Modalità conclusione: <span
            *ngIf="isModalitaConclusioneRequired()">*</span></label>
          <div>
            <select class="form-control" name="modalitaConclusione" formControlName="modalitaConclusione"
                    [attr.disabled]="!hasWritePermission(mainUser)? false:null || !canClose() ? false: null">
              <option value=""></option>
              <option [value]="inv.codice" *ngFor="let inv of listaModalitaConclusione">{{inv.descrizione}}
              </option>
            </select>
            <div *ngIf="contattoForm.hasError('required','modalitaConclusione')
                              " class="alert alert-danger">Campo obbligatorio
            </div>
          </div>
        </div>
          <app-progetto-di-intervento [mainUser]="mainUser" [contatto]="contatto" [groupPInt]="contattoForm"></app-progetto-di-intervento>
      </div>
    </div>
    <div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary"
            *ngIf="lastDiagnosiEScale && confermadiagnosichiusuraterritoriale=='true'"
            (click)="saveChiusuraTerritorialeAsync(modal)" [disabled]="contattoForm.pristine">Conferma chiusura con diagnosi
    </button>
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">Annulla</button>
  </div>
</ng-template>
<ng-template #audit let-modal>
  <div class="modal-header" style="background-color: #012346 !important; cursor: pointer;">
    <h4 class="modal-title" id="modal-basic-title" style="color: white;">Storico modifiche
      contatto {{contatto!!.id!!}}</h4>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="table-responsive">
          <div class="list-table table-even">
            <table class="table-lg" *ngIf="isTipoPrestazioneTerritoriale()">
              <thead>
              <tr>
                <th>Data</th>
                <th>Username</th>
                <th>Data richiesta</th>
                <th>Richiesta prev</th>
                <th>Apertura contatto</th>
                <th>Data apertura</th>
                <th>Diagnosi apertura</th>
                <th>Contatti precedenti</th>
                <th>Inviante</th>
                <th>Data chiusura</th>
                <th>Diagnosi di chiusura</th>
                <th>Modalità conclusione</th>
                <th>Id anonimo struttura</th>
                <th>Data eliminazione</th>
                <th>Eliminato</th>
              </tr>
              </thead>
              <tbody>
              <tr class="tr-hover" *ngFor="let item of auditContatto">
                <td>{{item.dataAudit | date:'yyyy-MM-dd hh:mm'}}</td>
                <td>{{item.usernameAudit }}</td>
                <td>{{item.dataRichiesta | date:'yyyy-MM-dd hh:mm' }}</td>
                <td>{{item.richiestaPrev }}</td>
                <td>{{item.aperturaFlag == '2' ? 'No' : 'Si' }}</td>
                <td>{{item.dataApertura | date:'yyyy-MM-dd hh:mm' }}</td>
                <td>{{item.descrizioneDiagnosiApertura }}</td>
                <td>{{item.precedentiContatti }}</td>
                <td>{{item.inviante }}</td>
                <td>{{item.dataChiusura | date:'yyyy-MM-dd hh:mm'}}</td>
                <td>{{item.descrizioneDiagnosiChiusura}}</td>
                <td>{{item.modalitaConclusione}}</td>
                <td>{{item.idAnonimoStr}}</td>
                <td>{{item.dataEliminazione | date:'yyyy-MM-dd hh:mm' }}</td>
                <td>{{ item.eliminato ? 'Si' : 'No'}}</td>
              </tr>
              </tbody>
            </table>
            <table class="table-lg" *ngIf="!isTipoPrestazioneTerritoriale()">
              <thead>
              <tr>
                <th>Data</th>
                <th>Username</th>
                <th>Data richiesta</th>
                <th>Data apertura</th>
                <th>Diagnosi apertura</th>
                <th>Contatti precedenti</th>
                <th>Inviante</th>
                <th>Intervento riabilitativi</th>
                <th>Intervento inserimento lavorativi</th>
                <th>Intervento interveni sociali</th>
                <th>Intervento colloqui psichiatrici</th>
                <th>Intervento coinvolgimento</th>
                <th>Altro</th>
                <th>Data chiusura</th>
                <th>Diagnosi chiusura</th>
                <th>Modalità conclusione</th>
                <th>Intervento effettuato riabilitativi</th>
                <th>Intervento effettuato lavorativi</th>
                <th>Intervento effettuato interveni sociali</th>
                <th>Intervento effettuato colloqui psichiatrici</th>
                <th>Intervento effettuato coinvolgimento</th>
                <th>Altro</th>
                <th>Id anonimo struttura</th>
                <th>Data eliminazione</th>
                <th>Eliminato</th>
              </tr>
              </thead>
              <tbody>
              <tr class="tr-hover" *ngFor="let item of auditContatto">
                <td>{{ item.dataAudit | date:'yyyy-MM-dd hh:mm'}}</td>
                <td>{{ item.usernameAudit }}</td>
                <td>{{ item.dataRichiesta | date:'yyyy-MM-dd hh:mm'}}</td>
                <td>{{ item.dataApertura | date:'yyyy-MM-dd hh:mm'}}</td>
                <td>{{ item.descrizioneDiagnosiApertura }}</td>
                <td>{{ item.precedentiContatti }}</td>
                <td>{{ item.inviante }}</td>
                <td>{{ item.interventoRiabilitativi }}</td>
                <td>{{ item.interventoInserimentiLavorativi }}</td>
                <td>{{ item.interventoInterveniSociali }}</td>
                <td>{{ item.interventoColloquiPsichiatrici }}</td>
                <td>{{ item.interventoCoinvolgimento }}</td>
                <td>{{ item.interventoEffettuatoAltro }}</td>
                <td>{{ item.dataChiusura | date:'yyyy-MM-dd hh:mm'}}</td>
                <td>{{ item.descrizioneDiagnosiChiusura }}</td>
                <td>{{ item.modalitaConclusione }}</td>
                <td>{{ item.interventoEffettuatoRiabilitativi }}</td>
                <td>{{ item.interventoEffettuatoLavorativi}}</td>
                <td>{{ item.interventoEffettuatoInterveniSociali}}</td>
                <td>{{ item.interventoEffettuatoColloquiPsichiatrici}}</td>
                <td>{{ item.interventoEffettuatoCoinvolgimento}}</td>
                <td>{{ item.interventoEffettuatoAltro}}</td>
                <td>{{ item.idAnonimoStr}}</td>
                <td>{{item.dataEliminazione | date:'yyyy-MM-dd hh:mm' }}</td>
                <td>{{ item.eliminato ? 'Si' : 'No'}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('')">Ok</button>
  </div>
</ng-template>
<ng-template #sendemailsollecito let-modal>
  <div class="modal-header" style="background-color: #012346 !important; cursor: pointer;">
    <h4 class="modal-title" id="modal-basic-title_1" style="color: white;">Attenzione</h4>
    <a (click)="modal.dismiss('Cross click')">
      <svg class="s-icon icon-xxs">
        <use xlink:href="assets/icons/sprite.svg#close"></use>
      </svg>
    </a>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <label>L’utente {{anagrafica.nome}} {{anagrafica.cognome}} ({{anagrafica.codiceFiscale}}) risulta in carico altrove. Una struttura dello stesso ambito di un'altra Asl ha, ad oggi, un contatto aperto e non concluso. Vuole segnalare questa anomalia per sollecitare la conclusione del contatto?</label>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="closeModalAndRoute(modal, true, contattoApertoSetting.codiceAslOperatoreInserimento!, utente.codiceAsl!)">Si</button>
    <button type="button" class="btn btn-outline-dark" (click)="closeModalAndRoute(modal, false, '','')">No</button>
  </div>
</ng-template>
<ng-template #audit2 let-modal>
  <div class="modal-header" style="background-color: #012346 !important; cursor: pointer;">
    <h4 class="modal-title" id="modal-basic-title_" style="color: white;">Storico modifiche</h4>
  </div>
  <div class="modal-body">
    <app-dyn-table [title]="storicoTitle" [dataMessageResults]="storicoList"></app-dyn-table>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('')">Ok</button>
  </div>
</ng-template>
