<ngb-toast
  *ngFor="let toast of toastService.toasts"
  [class]="toast.classname"
  [autohide]="true"
  [delay]="toast.delay || 5000"
  (hidden)="toastService.remove(toast)"
  [header]="toast.header"
  [animation]="true"
>
  <ng-template ngbToastHeader>
    <div class="me-auto ">
      <svg *ngIf="toast.success" class="s-icon icon-sm text-success icon-status">
        <use xlink:href="assets/icons/sprite.svg#success"></use>
      </svg>
      <svg *ngIf="toast.error" class="s-icon icon-sm text-danger icon-status">
        <use xlink:href="assets/icons/sprite.svg#warning"></use>
      </svg>
      <strong class="mx-1">{{toast.header}}</strong>
    </div>
  </ng-template>
  <ng-template
    [ngIf]="isTemplate(toast)" [ngIfElse]="text">
    <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
  </ng-template>

  <ng-template #text >{{ toast.textOrTpl }}</ng-template>
</ngb-toast>
