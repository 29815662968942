import {firstValueFrom} from 'rxjs';
import {Page, Ruolo, StrutturaStr, Strutture, Utente} from './../../model/Models';
import {ApiService} from './../../services/api.service';
import {Component, OnInit} from '@angular/core';
import {Asl} from 'src/app/model/Models';
import {FormGroup, FormControl} from '@angular/forms';
import {SessionService} from 'src/app/services/session.service';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {environment} from "../../../environments/environment";
import {saveAs} from "file-saver";
import {NgbCalendar, NgbDateStruct, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ToastService} from "../../services/toast.service";
import {CommonsUtil} from "../../util/CommonsUtil";

@Component({
  selector: 'app-controlboard',
  templateUrl: './controlboard.component.html',
  styleUrls: ['./controlboard.component.css']
})

export class ControlboardComponent implements OnInit {

  /**
   * Profili utente
   */
  public OPERATORE_STRUTTURA: String = "operatore-struttura";
  public OPERATORE_ASL: String = "operatore-asl";
  public OPERATORE_DSM: String = "operatore-dsm";
  public OPERATORE_REGIONE: String = "operatore-regione";
  /**
   * fine profili utente
   */
  /**
   * gestione tab
   */
  public reportTab: boolean = false;
  public reportData: boolean = false;
  public dashboard: boolean = true;
  public reportDataVisible: boolean = false;
  public dashboardActive: string = "active";
  public reportActive: string = "";
  public reportDataActive: string = "";
  public reportTitle: string = "";
  public safeLinkReport: SafeResourceUrl = "";
  public reportLink: string = "";
  /**
   * fine gestione tab
   */

  public knowageUrl = environment.knowageUrl;
  public utente: Utente = new Utente();
  public struttura: Strutture = new Strutture();
  public isLoading: boolean = false;
  public annoSelezionato: number | undefined = new Date().getFullYear();
  public ambitoSelezionato: string = "";
  public tipossnSelezionato: string = "0";
  public anni = this.generateArrayOfYearsFrom1900();
  public ricercaForm!: FormGroup;
  public ricercaSintesiForm!: FormGroup;
  public aslList: Asl[] = [];
  public struttureList: Strutture[] = [];
  public formAmbito: string = this.ambitoSelezionato;
  public formTipoSsn: string = this.tipossnSelezionato;
  public formStruttura: string = "";
  public formCompetenzeTerritoriali: string = "";
  public formAsl: string = "";

// dati form separati per sintesi
  public annoSelezionatoSintesi: number | undefined = new Date().getFullYear();
  public ambitoSelezionatoSintesi: string = "";
  public tipossnSelezionatoSintesi: string = "0";
  public formSintesiAmbito: string = this.ambitoSelezionatoSintesi;
  public formSintesiTipoSsn: string = this.tipossnSelezionatoSintesi;
  public formSintesiStruttura: string = "";
  public formSintesiCompetenzeTerritoriali: string = "";
  public formSintesiAsl: string = "";
  public struttureListSintesi: Strutture[] = [];
  public struttureListCsv: Strutture[] = [];

  /**
   * parametri componenti card
   */
  public utentiIncidenti: string | number = "" ;
  public utentiPrevalenti:  string | number = "";
  public utentiPrevalenti0101:  string | number = "";
  public contattiPrevalenti:  string | number = "";
  public contattiNonInviati:  string | number = "";
  public interventiNonInviati:  string | number = "";
  public diagnosiScaleNonInviate:  string | number = "";
  public prestazioniNonInviate:  string | number = "";
  public presenzeNonInviate:  string | number = "";
  public contattiSenzaInterventiDiaPrestPres:  string | number = "";
  public contattiApertiSenzaPrestazioni:  string | number = "";
  public contattiUltimaPrestazione180:  string | number = "";
  public contattiScartati:  string | number = "";
  public contattiDaValidare:  string | number = "";
  public contattiValidati:  string | number = "";
  public interventiScartati:  string | number = "";
  public interventiDaValidare:  string | number = "";
  public interventiValidati:  string | number = "";
  public diagnosiScartate:  string | number = "";
  public diagnosiDaValidare:  string | number = "";
  public diagnosiValidate:  string | number = "";
  public prestazioniScartate:  string | number = "";
  public prestazioniDaValidare:  string | number = "";
  public prestazioniValidate:  string | number = "";
  public presenzeScartate:  string | number = "";
  public presenzeDaValidare:  string | number = "";
  public presenzeValidate:  string | number = "";
  public utentiContattiAttivi:  string | number = "";
  public utentiContattiNonAvviati0101:  string | number = "";
  public dataUltimaAperturaContatto?: Date;// = new Date();
  public dataUltimaConclusioneContatto?: Date;// = new Date();
  defaultSelectStrutture = "tutte";
  defaultCompetenzeTerritoriali = "struttureInAsl";
  defaultSelectSsn = "0";
  defaultSelectAmbito = "";
  defaultSelectAsl = "";
  defaultSelectStruttureSintesi = "tutte";
  defaultCompetenzeTerritorialiSintesi = "struttureInAsl";
  defaultSelectSsnSintesi = "0";
  defaultSelectAmbitoSintesi = "";
  defaultSelectAslSintesi = "";
  colorBlue: string = "#0461ad";
  colorYellow: string = "#fad163";
  colorRed: string = "#b0385c";
  colorGreen: string = "#46af46";
  iconUser: string = "assets/icons/person_white_48dp.png";
  iconPeople: string = "assets/icons/people_white_48dp.png";
  iconPersonRemove: string = "assets/icons/person_remove_white_48dp.png";
  iconMedication: string = "assets/icons/medication_white_48dp.png";
  iconCalendarUp: string = "assets/icons/calendar_dacaricare.png";
  iconCalendarKo: string = "assets/icons/calendar_ko.png";
  iconDiagnosis: string = "assets/icons/diagnosis_FILL1_wght400_GRAD0_opsz48_white.png";
  iconMedicalServices: string = "assets/icons/medical_services_FILL1_wght400_GRAD0_opsz48_white.png";
  iconNoAccounts: string = "assets/icons/no_accounts_white_48dp.png";
  iconPersonOff: string = "assets/icons/person_off_white_48dp.png";
  iconPresence: string = "assets/icons/co_present_white_48dp.png";
  misureLabel1: string = "Utenti prevalenti <br />al 01/01 <br /><br />";
  misureLabel2: string = "Utenti prevalenti <br />nell'anno<br /><br />";
  misureLabel3: string = "Utenti incidenti<br /> nell'anno<br /><br />";
  misureLabel4: string = "Contatti prevalenti<br />nell'anno<br /><br />";
  misureLabel5: string = "Utenti con <br />contatti attivi<br /><br />";
  misureLabel6: string = "Utenti con contatti<br /> non avviati<br />dal 01/01";
  misureLabel7: string = "Data ultima<br />apertura di contatto<br /><br />";
  misureLabel8: string = "Data ultima<br />conclusione di contatto<br /><br />";
  misureLabel9: string = "Contatti non <br />inviati<br /><br />";
  misureLabel10: string = "Interventi non<br /> inviati<br /><br />";
  misureLabel11: string = "Diagnosi&scale non<br />inviate<br /><br />";
  misureLabel12: string = "Prestazioni non<br />inviate<br /><br />";
  misureLabel13: string = "Presenze non <br />inviate <br /><br />";
  misureLabel14: string = "Contatti compilati<br />solo parzialmente<br /><br />";
  misureLabel15: string = "Contatti senza <br />prestazioni<br /><br />";
  misureLabel16: string = "Contatti con ultima<br/>prestazione<br />più lontana di 180 giorni";
  misureLabel17: string = "Contatti<br />scartati";
  misureLabel18: string = "Interventi<br />scartati";
  misureLabel19: string = "Diagnosi<br/>scartate";
  misureLabel20: string = "Prestazioni<br/>scartate";
  misureLabel21: string = "Presenze<br/>scartate";
  misureLabel22: string = "Contatti<br />da Validare";
  misureLabel23: string = "Interventi<br />da Validare";
  misureLabel24: string = "Diagnosi<br />da Validare";
  misureLabel25: string = "Prestazioni<br />da Validare";
  misureLabel26: string = "Presenze<br />da Validare";
  misureLabel27: string = "Contatti<br />validati";
  misureLabel28: string = "Interventi<br />validati";
  misureLabel29: string = "Diagnosi<br />validate";
  misureLabel30: string = "Prestazioni<br />validate";
  misureLabel31: string = "Presenze<br />validate";
  misureLabel32: string = "Contatti senza <br />presenze<br /><br />";
  misureLabel33: string = "Contatti con ultima<br/>presenza<br />più lontana di 180 giorni";
  misureLink1: string = "/ricerca-anagrafica?advanced=true&entita=contatto&stato=4&apertura_contatto=0";
  misureLink2: string = "/ricerca-anagrafica?advanced=true&entita=intervento&stato=4&apertura_contatto=1";
  misureLink3: string = "/ricerca-anagrafica?advanced=true&entita=diagnosiescale&stato=4&apertura_contatto=1";
  misureLink4: string = "/ricerca-anagrafica?advanced=true&entita=presenza&stato=4&apertura_contatto=1";
  misureLink5: string = "/ricerca-anagrafica?advanced=true&entita=prestazione&stato=4&apertura_contatto=1";
  misureLink6: string = "/ricerca-anagrafica?advanced=true&entita=contatto&stato=2&apertura_contatto=0";
  misureLink7: string = "/ricerca-anagrafica?advanced=true&entita=intervento&stato=2&apertura_contatto=1";
  misureLink8: string = "/ricerca-anagrafica?advanced=true&entita=diagnosiescale&stato=2&apertura_contatto=0";
  misureLink10: string = "/ricerca-anagrafica?advanced=true&entita=presenza&stato=2&apertura_contatto=1";
  misureLink9: string = "/ricerca-anagrafica?advanced=true&entita=prestazione&stato=2&apertura_contatto=1";
  misureLink11: string = "/ricerca-anagrafica?advanced=true&entita=contatto&stato=1&apertura_contatto=0&anno=0";
  misureLink12: string = "/ricerca-anagrafica?advanced=true&entita=intervento&stato=1&apertura_contatto=1&anno=0";
  misureLink13: string = "/ricerca-anagrafica?advanced=true&entita=diagnosiescale&stato=1&apertura_contatto=1&anno=0";
  misureLink14: string = "/ricerca-anagrafica?advanced=true&entita=prestazione&stato=1&apertura_contatto=1&anno=0";
  misureLink15: string = "/ricerca-anagrafica?advanced=true&entita=presenza&stato=1&apertura_contatto=1&anno=0";
  misureLink16: string = "/ricerca-anagrafica?advanced=true&entita=contatto&stato=20&apertura_contatto=1&anno=0";
  misureLink17: string = "/ricerca-anagrafica?advanced=true&entita=contatto&stato=21&apertura_contatto=1&anno=0";
  misureLink18: string = "/ricerca-anagrafica?advanced=true&entita=contatto&stato=22&apertura_contatto=1&anno=0";

  params: string = "";
  emptyLink: string = "";
  downloadInCorso: boolean = false;
  periodoCSV = 0;
  annoCSV = (new Date()).getFullYear();
  aslCSV: string | undefined = "";
  ambitoCSV = "";
  ssnCSV = 0;
  // struttureCSV : string | undefined = "";
  struttureCSV: string | undefined = "";
  struttureOperatore: string[] | undefined = [];
  statoContattoCSV = -1;
  statoRecordCSV = -1;
  tipoIdentificativoCSV = "";
  typeCSV: string = '';
  nomeCSV = "";
  cognomeCSV = "";
  codiceFiscaleCSV = "";
  competenzeTerritorialiCCSV = "struttureInAsl";

  /**
   * fine parametri per componenti card
   */


  constructor(
    private apiService: ApiService,
    private sessionService: SessionService,
    private sanitizer: DomSanitizer,
    private modalService: NgbModal,
    private toastService: ToastService,
    private calendar: NgbCalendar) {

    this.maxDateA = calendar.getToday();
    //this.dateA = calendar.getToday();
    //this.dateDa = calendar.getPrev(calendar.getToday(), 'd', 1);
  }

  async ngOnInit() {

    this.safeLinkReport = this.sanitizer.bypassSecurityTrustResourceUrl(this.reportLink)
    this.renderPage();

    this.sessionService.strutturaChanged.subscribe(res => {

      if (res) {

        this.renderPage();
        this.resetData();
        this.resetDataSintesi();

      }

    });

  }

  /**
   * Carica i componenti della pagina
   */
  public async renderPage() {

    this.getUtente();
    this.generateArrayOfYears();
    await this.ricercaFormInit()
    await this.ricercaSintesiFormInit()
    if (this.getRuolo(this.OPERATORE_REGIONE)) {
      await this.getAllAsl()
    }
    await this.getAllStrutture();
    // await this.getData();
    await this.getAllStruttureSintesi();
    // await this.getDataSintesi();
    this.closeReportData()
    if(this.getRuolo(this.OPERATORE_STRUTTURA)){
      this.getDataSintesiAnomalie()
    }

  }

  /**
   * Recupero utente da session storage
   *
   */

  public isRegione() {
    return this.getRuolo('operatore-regione');
  }

  public getUtente() {

    var usr = sessionStorage.getItem("utente");

    if (usr != null && usr != undefined) {

      this.utente = JSON.parse(usr);

    }


    if (this.getRuolo('operatore-asl') || this.getRuolo('operatore-dsm')) {

      this.aslCSV = this.utente.codiceAsl;

      //console.log('presa asl')
    }

    if (this.getRuolo('operatore-struttura')) {
      this.aslCSV = this.utente.codiceAsl;
      //this.struttureOperatore = this.utente.codiciStrutture
      //console.log("STRUTTURA PREVALENTE:")
      let strutturaPrevalenteStr = sessionStorage.getItem("struttura");
      if (strutturaPrevalenteStr != null) {
        let struttPrev: Strutture = JSON.parse(strutturaPrevalenteStr);
        //console.log(struttPrev?.codice);
        this.struttureCSV = struttPrev?.codice;
      }
    }

  }

  /**
   * Ritorna true o false in base all'utente ed il ruolo selezionato
   * ruoli:
   *  operatore-struttura
   *  operatore-asl
   *  operatore-dsm
   * @param ruolo
   * @returns
   */
  public getRuolo(ruolo: String) {

    if (this.utente) {

      if (this.utente.nomeRuolo == ruolo) {

        return true;

      }

    }

    return false;

  }

  isTerritoriale() {
    return this.sessionService.isSelectedStrutturaFromStorageTerritoriale();
  }
  isSemiresidenziale() {
    return this.sessionService.isSelectedStrutturaFromStorageSemiresidenziale();
  }

  /**
   * Recupera strutture per utente
   */
  public async getAllStrutture() {

    let anno: number = (new Date()).getFullYear()

    if (this.ricercaForm.get('anno') != undefined) {

      anno = this.ricercaForm.get('anno')?.value;

    }

    if (this.ricercaForm.get("competenzeterritoriali") != undefined) {

      this.formCompetenzeTerritoriali = this.ricercaForm.get("competenzeterritoriali")?.value;

    }

    if (this.ricercaForm.get('asl') != null && this.ricercaForm.get('asl') != undefined) {
      this.formAsl = this.ricercaForm.get('asl')?.value;
    }


    let data = await firstValueFrom(this.apiService.getStruttureByParams(anno, this.tipossnSelezionato, this.ambitoSelezionato, this.formCompetenzeTerritoriali, this.formAsl,this.utente.idRuolo!));
    this.struttureList = data!.content;

    if (this.utente.nomeRuolo == this.OPERATORE_STRUTTURA) {

      var struttura = sessionStorage.getItem("struttura");

      if (struttura != null && struttura != undefined) {

        this.struttura = JSON.parse(struttura);

        let tipoStrutt = "SEMIRESIDENZIALE";

        if (this.struttura.descrTipoStruttura?.indexOf('TERRITORIALE') != -1) {

          tipoStrutt = "TERRITORIALE";

        }
        // this.params = "&PARAMETERS=par_tipo_struttura=" + tipoStrutt + "=par_struttura=" + this.struttura.codice;
        // this.params = "&PARAMETERS=par_struttura%3D" + this.struttura.codice+this.utente.codiceAsl + "%26par_tipo_struttura%3D" + tipoStrutt + "%26par_asl%3D" + this.utente.codiceAsl + "%26par_ruolo_sismapp%3D" + this.utente.nomeRuolo;
        this.params = "&PARAMETERS=par_struttura%3D" + this.struttura.codice + this.utente.codiceAsl + "%26par_tipo_struttura%3D" + tipoStrutt + "%26par_asl%3D" + this.utente.codiceAsl + "%26par_ruolo_sismapp%3D" + this.utente.nomeRuolo;

      }

      this.ricercaForm.get('struttura')?.disable();
      this.ricercaForm.get('tipoSsn')?.disable();
      this.ricercaForm.get('ambito')?.disable();
      this.ricercaForm.controls['struttura'].patchValue(this.struttura.codice);
      this.ricercaForm.controls['tipoSsn'].patchValue(this.struttura.tipoRapportoSsn);

      if (this.struttura.descrTipoStruttura?.indexOf('TERRITORIALE') != -1) {

        this.ricercaForm.controls['ambito'].patchValue("Territoriale");

      } else {

        this.ricercaForm.controls['ambito'].patchValue("Semiresidenziale");

      }

    } else if (this.utente.nomeRuolo == this.OPERATORE_ASL) {

      this.params = "&PARAMETERS=par_asl%3D" + this.utente.codiceAsl + "%26par_ruolo_sismapp%3D" + this.utente.nomeRuolo;

    } else {

      this.params = "";

    }

    this.defaultSelectStrutture='tutte';

  }


  /**
   * Recupera strutture per utente sintesi
   */
  public async getAllStruttureSintesi() {

    let anno: number = (new Date()).getFullYear()

    if (this.ricercaSintesiForm.get('annoSintesi') != undefined) {

      anno = this.ricercaSintesiForm.get('annoSintesi')?.value;

    }

    if (this.ricercaSintesiForm.get("competenzeterritorialiSintesi") != undefined) {

      this.formSintesiCompetenzeTerritoriali = this.ricercaSintesiForm.get("competenzeterritorialiSintesi")?.value;

    }

    if (this.ricercaSintesiForm.get('aslSintesi') != null && this.ricercaSintesiForm.get('aslSintesi') != undefined) {
      this.formSintesiAsl = this.ricercaSintesiForm.get('aslSintesi')?.value;
    }


    let data = await firstValueFrom(this.apiService.getStruttureByParams(anno, this.tipossnSelezionatoSintesi, this.ambitoSelezionatoSintesi, this.formSintesiCompetenzeTerritoriali, this.formSintesiAsl,this.utente.idRuolo!));
    this.struttureListSintesi = data!.content;

    if (this.utente.nomeRuolo == this.OPERATORE_STRUTTURA) {

      var struttura = sessionStorage.getItem("strutturaSintesi");

      if (struttura != null && struttura != undefined) {

        this.struttura = JSON.parse(struttura);
        this.params = "&PARAMETERS=par_struttura=" + this.struttura.codice + this.utente.codiceAsl

      }

      this.ricercaSintesiForm.get('strutturaSintesi')?.disable();
      this.ricercaSintesiForm.get('tipoSsnSintesi')?.disable();
      this.ricercaSintesiForm.get('ambitoSintesi')?.disable();
      this.ricercaSintesiForm.controls['strutturaSintesi'].patchValue(this.struttura.codice);
      this.ricercaSintesiForm.controls['tipoSsnSintesi'].patchValue(this.struttura.tipoRapportoSsn);

      if (this.struttura.descrTipoStruttura?.indexOf('TERRITORIALE') != -1) {

        this.ricercaSintesiForm.controls['ambitoSintesi'].patchValue("Territoriale");

      } else {

        this.ricercaSintesiForm.controls['ambitoSintesi'].patchValue("Semiresidenziale");

      }

    } else if (this.utente.nomeRuolo == this.OPERATORE_ASL) {

      this.params = "&PARAMETERS=par_asl=" + this.utente.codiceAsl;

    } else {

      this.params = "";

    }

    // this.defaultSelectStruttureSintesi='tutte';

  }

  /**
   * Recupera strutture per csv
   */
  public async getAllStruttureCsv() {
    if (this.typeCSV != "anagrafica" && (this.getRuolo(this.OPERATORE_REGIONE) || this.getRuolo(this.OPERATORE_ASL) || this.getRuolo(this.OPERATORE_DSM))) {
      if (this.typeCSV.includes('territoriale')) {
        this.ambitoCSV = "Territoriale"

      }
      if (this.typeCSV.includes('semiresidenziale')) {
        this.ambitoCSV = "Semiresidenziale"

      }
    }
    if (this.competenzeTerritorialiCCSV == 'fuoriasl') {

      this.struttureListCsv = [];

    } else {

      let data = await firstValueFrom(this.apiService.getStruttureByParams(this.annoCSV, this.ssnCSV + "", this.ambitoCSV + "", this.competenzeTerritorialiCCSV, this.aslCSV!,this.utente.idRuolo!));
      this.struttureListCsv = data!.content;

    }


  }

  /**
   * In ascolto per il cambio struttura
   */
  observeStrutturaChanged() {

    this.sessionService.strutturaChanged.subscribe(res => {

      if (res) {

        if (this.utente.nomeRuolo == this.OPERATORE_STRUTTURA) {

          var struttura = sessionStorage.getItem("struttura");

          if (struttura != null && struttura != undefined) {

            this.struttura = JSON.parse(struttura);

          }

          this.ricercaForm.get('struttura')?.disable();
          this.ricercaForm.get('tipoSsn')?.disable();
          this.ricercaForm.get('ambito')?.disable();
          this.ricercaForm.controls['struttura'].patchValue(this.struttura.codice);
          this.ricercaForm.controls['tipoSsn'].patchValue(this.struttura.tipoRapportoSsn);

          if (this.struttura.descrTipoStruttura?.indexOf('TERRITORIALE') != -1) {

            this.ricercaForm.controls['ambito'].patchValue("Territoriale");

          } else {

            this.ricercaForm.controls['ambito'].patchValue("Semiresidenziale");

          }

        }

      }

    });

  }

  /**
   * Inizializza il form
   */
  private async ricercaFormInit() {

    this.ricercaForm = new FormGroup({
      anno: new FormControl(""),
      ambito: new FormControl(""),
      struttura: new FormControl(""),
      tipoSsn: new FormControl(""),
      competenzeterritoriali: new FormControl(""),
      asl: new FormControl("")
    });

    this.ricercaForm.controls['anno'].patchValue((new Date()).getFullYear());
    this.ricercaForm.get('ambito')?.setValue(this.ambitoSelezionato);
    this.ricercaForm.get('tipoSsn')?.setValue(this.tipossnSelezionato);

    if (this.getRuolo(this.OPERATORE_ASL) || this.getRuolo(this.OPERATORE_DSM)) {

      this.ricercaForm.get('competenzeterritoriali')?.setValue(this.defaultCompetenzeTerritoriali);

    } else {

      this.ricercaForm.get('competenzeterritoriali')?.setValue("");

    }

    this.ricercaForm.get('asl')?.setValue(this.defaultSelectAsl);

    if (this.getRuolo(this.OPERATORE_ASL) || this.getRuolo(this.OPERATORE_REGIONE)) {

      this.ricercaForm.get('struttura')?.setValue("tutte")

    }


  }


  /**
   * Inizializza il form delle sintesi
   */
  private async ricercaSintesiFormInit() {

    this.ricercaSintesiForm = new FormGroup({
      annoSintesi: new FormControl(""),
      ambitoSintesi: new FormControl(""),
      strutturaSintesi: new FormControl(""),
      tipoSsnSintesi: new FormControl(""),
      competenzeterritorialiSintesi: new FormControl(""),
      aslSintesi: new FormControl("")
    });

    this.ricercaSintesiForm.controls['annoSintesi'].patchValue((new Date()).getFullYear());
    this.ricercaSintesiForm.get('ambitoSintesi')?.setValue(this.ambitoSelezionatoSintesi);
    this.ricercaSintesiForm.get('tipoSsnSintesi')?.setValue(this.tipossnSelezionatoSintesi);

    if (this.getRuolo(this.OPERATORE_ASL) || this.getRuolo(this.OPERATORE_DSM)) {

      this.ricercaSintesiForm.get('competenzeterritorialiSintesi')?.setValue(this.defaultCompetenzeTerritoriali);

    } else {

      this.ricercaSintesiForm.get('competenzeterritorialiSintesi')?.setValue("");

    }

    this.ricercaSintesiForm.get('aslSintesi')?.setValue(this.defaultSelectAsl);

    if (this.getRuolo(this.OPERATORE_ASL) || this.getRuolo(this.OPERATORE_REGIONE)) {

      this.ricercaSintesiForm.get('strutturaSintesi')?.setValue("tutte")

    }


  }

  /**
   * recupera dati dal form
   */
  public getFormData() {

    this.annoSelezionato = this.ricercaForm.get('anno')?.value;
    this.formAmbito = this.ricercaForm.get('ambito')?.value;
    this.formStruttura = this.ricercaForm.get('struttura')?.value;

    if (this.ricercaForm.get('competenzeterritoriali') != null && this.ricercaForm.get('competenzeterritoriali') != undefined) {

      this.formCompetenzeTerritoriali = this.ricercaForm.get('competenzeterritoriali')?.value;

    } else {

      this.formCompetenzeTerritoriali = "";

    }

    if (this.ricercaForm.get('asl') != null && this.ricercaForm.get('asl') != undefined) {
      this.formAsl = this.ricercaForm.get('asl')?.value;
    }
    this.formTipoSsn = this.ricercaForm.get('tipoSsn')?.value;

  }


  /**
   * recupera dati dal form SINTESI
   */
  public getSintesiFormData() {
    this.annoSelezionatoSintesi = this.ricercaSintesiForm.get('annoSintesi')?.value;
    this.formSintesiAmbito = this.ricercaSintesiForm.get('ambitoSintesi')?.value;
    this.formSintesiStruttura = this.ricercaSintesiForm.get('strutturaSintesi')?.value;

    if (this.ricercaSintesiForm.get('competenzeterritorialiSintesi') != null && this.ricercaSintesiForm.get('competenzeterritorialiSintesi') != undefined) {

      this.formSintesiCompetenzeTerritoriali = this.ricercaSintesiForm.get('competenzeterritorialiSintesi')?.value;

    } else {

      this.formSintesiCompetenzeTerritoriali = "";

    }

    if (this.ricercaSintesiForm.get('aslSintesi') != null && this.ricercaSintesiForm.get('aslSintesi') != undefined) {
      this.formSintesiAsl = this.ricercaSintesiForm.get('aslSintesi')?.value;
    }
    this.formSintesiTipoSsn = this.ricercaSintesiForm.get('tipoSsnSintesi')?.value;

  }

  /**
   * Cambia filtro anno
   * @param event
   */
  changeAnno(event: any) {

    // this.resetData();
    this.annoSelezionato = event.target.value;
    this.getAllStrutture()
    // this.getData();

  }

  /**
   * Cambia filtro anno sintesi
   * @param event
   */
  changeAnnoSintesi(event: any) {

    // this.resetDataSintesi();
    this.annoSelezionatoSintesi = event.target.value;
    this.getAllStruttureSintesi()
    // this.getDataSintesi();

  }


  /**
   * Cambia filtro ambito
   * @param event
   */
  async changeAmbito(event: any) {

    // this.resetData();
    this.ambitoSelezionato = event.target.value;
    await this.getAllStrutture()
    // await this.getData();

  }

  /**
   * Cambia filtro ambito sintesi
   * @param event
   */
  async changeAmbitoSintesi(event: any) {

    // this.resetDataSintesi();
    this.ambitoSelezionatoSintesi = event.target.value;
    this.defaultSelectStruttureSintesi = 'tutte'
    await this.getAllStruttureSintesi()
    // await this.getDataSintesi();

  }

  async changeAmbitoCsv() {

    await this.getAllStruttureCsv();
    if (this.ambitoCSV == 'Semiresidenziale') {
      this.avvioContattoCSV = 'avviato';
    } else {
      //this.avvioContattoCSV='tutti';
    }

  }

  async changeSsnCsv() {

    await this.getAllStruttureCsv();

  }

  /**
   * Cambia filtro struttura
   */
  async changeStruttura() {

    this.resetData();
    await this.getData();

  }
  async clickCalcola() {

    this.resetData();
    await this.getData();

  }
  public denominazioneStruttura: string = "";

  /**
   * Cambia filtro struttura sintesi
   */
  async changeStrutturaSintesi(event: any) {

    this.formSintesiStruttura = event.target.value!;
    this.denominazioneStruttura = this.struttureListSintesi.find(value => value.codStruttura == event.target.value)!.denominazioneStruttura!;
    // await this.getDataSintesi();

  }

  async changeAsl() {
    // this.resetData();
    await this.getAllStrutture();
    // await this.getData();
  }

  async changeAslSintesi() {
    // this.resetDataSintesi();
    await this.getAllStruttureSintesi();
    // await this.getDataSintesi();
  }
  async clickCalcolaSintesi() {

    this.resetDataSintesi();
    await this.getAllStruttureSintesi();
    await this.getDataSintesi();

  }

  changeAslCsv() {

    this.getAllStruttureCsv()

  }

  changeCompetenzeCsv() {

    this.getAllStruttureCsv()

  }

  async changeCompetenzeTerritoriali() {

    // this.resetData();
    await this.getAllStrutture()
    // await this.getData();

  }

  async changeCompetenzeTerritorialiSintesi() {

    // this.resetDataSintesi();
    await this.getAllStruttureSintesi()
    // await this.getDataSintesi();

  }

  /**
   * Cambia filtro ssn
   * @param event
   */
  async changeSsn(event: any) {

    // this.resetData();
    this.tipossnSelezionato = event.target.value;
    await this.getAllStrutture()
    // await this.getData();

  }

  /**
   * Cambia filtro ssn sintesi
   * @param event
   */
  async changeSsnSintesi(event: any) {

    // this.resetDataSintesi();
    this.tipossnSelezionatoSintesi = event.target.value;
    await this.getAllStruttureSintesi()
    // await this.getDataSintesi();

  }

  /**
   * Genera arrray anni
   * @returns
   */
  public generateArrayOfYears() {

    const max = new Date().getFullYear();
    const min = max - 9;
    const years = [];

    for (let i = max; i >= min; i--) {

      years.push('' + i);

    }

    return years;

  }
  public generateArrayOfYearsFrom1900() {

    const max = new Date().getFullYear();
    const min = 2012;
    const years = [];

    for (let i = max; i >= min; i--) {

      years.push('' + i);

    }

    return years;

  }

  public async getDataSintesiAnomalie(){
    this.isLoading = true;
    this.getFormData()
    const usr = sessionStorage.getItem("utente");

    let ruolo: Ruolo | undefined;

    if (this.utente && this.utente.ruoli && this.utente.nomeRuolo) {

      ruolo = this.utente.ruoli.find(value => value.nome == this.utente.nomeRuolo!);

    }

    this.formCompetenzeTerritoriali = "";

    //QUERY GET DATA
    this.apiService.getDashboardData(
      this.annoSelezionato!,
      this.formAmbito,
      this.formTipoSsn,
      this.formStruttura,
      this.formCompetenzeTerritoriali,
      this.formAsl,
      ruolo ? ruolo.id : undefined,
      false,
      true,
      false
    ).subscribe(data => {
      // PREPARE DATA
      if (data.length > 0) {

        this.prepareAllDataSintesiAnomalie(data[0]);
        // this.getDataSintesi();

      } else {

        this.resetData();

      }

      this.isLoading = false;

    });
  }
  /**
   * Recupera dati da backend
   * TODO: separare le form
   */
  public async getData() {

    this.isLoading = true;
    this.getFormData()
    const usr = sessionStorage.getItem("utente");

    let ruolo: Ruolo | undefined;

    if (this.utente && this.utente.ruoli && this.utente.nomeRuolo) {

      ruolo = this.utente.ruoli.find(value => value.nome == this.utente.nomeRuolo!);

    }

    if (this.getRuolo(this.OPERATORE_REGIONE) || this.getRuolo(this.OPERATORE_STRUTTURA)) {

      this.formCompetenzeTerritoriali = "";

    }

    //QUERY GET DATA
    this.apiService.getDashboardData(
      this.annoSelezionato!,
      this.formAmbito,
      this.formTipoSsn,
      this.formStruttura,
      this.formCompetenzeTerritoriali,
      this.formAsl,
      ruolo ? ruolo.id : undefined,
      true,
      false,
      false
    ).subscribe(data => {
      // PREPARE DATA
      if (data.length > 0) {

        this.prepareAllData(data[0]);
        // this.getDataSintesi();

      } else {

        this.resetData();

      }

      this.isLoading = false;

    });

  }

  /**
   * Recupera dati da backend
   * TODO: separare le form
   */
  public async getDataSintesi() {

    this.isLoading = true;
    //await this.getAllStruttureSintesi()
    this.getSintesiFormData()
    const usr = sessionStorage.getItem("utente");

    let ruolo: Ruolo | undefined;

    if (this.utente && this.utente.ruoli && this.utente.nomeRuolo) {

      ruolo = this.utente.ruoli.find(value => value.nome == this.utente.nomeRuolo!);

    }

    if (this.getRuolo(this.OPERATORE_REGIONE) || this.getRuolo(this.OPERATORE_STRUTTURA)) {

      this.formCompetenzeTerritoriali = "";

    }

    //QUERY GET DATA
    this.apiService.getDashboardData(
      this.annoSelezionatoSintesi!,
      this.formSintesiAmbito,
      this.formSintesiTipoSsn,
      this.formSintesiStruttura,
      this.formSintesiCompetenzeTerritoriali,
      this.formSintesiAsl,
      ruolo ? ruolo.id : undefined,
      false,
      false,
      true
    ).subscribe(data => {
      // PREPARE DATA
      if (data.length > 0) {

        this.prepareAllDataSintesi(data[0]);

      } else {

        this.resetData();

      }

      this.isLoading = false;

    });

  }

  /**
   * Reset data
   */
  resetData() {

    this.utentiIncidenti =  "";
    this.utentiPrevalenti =  "";
    this.utentiPrevalenti0101 = "";
    this.contattiPrevalenti =  "";
    // this.contattiNonInviati =  "";
    // this.interventiNonInviati =  "";
    // this.diagnosiScaleNonInviate =  "";
    // this.prestazioniNonInviate =  "";
    // this.presenzeNonInviate =  "";
    // this.contattiSenzaInterventiDiaPrestPres =  "";
    // this.contattiApertiSenzaPrestazioni =  "";
    // this.contattiUltimaPrestazione180 =  "";
    this.utentiContattiAttivi =  "";
    this.utentiContattiNonAvviati0101 =  "";
    this.dataUltimaAperturaContatto = undefined;
    this.dataUltimaConclusioneContatto = undefined;
    this.diagnosiValidate =  "";
    this.contattiValidati =  "";
    this.interventiValidati =  "";
    this.presenzeValidate =  "";
    this.prestazioniValidate =  "";
  }


  /**
   * Reset data
   */
  resetDataSintesi() {

    this.contattiScartati =  "";
    this.contattiDaValidare =  "";
    this.interventiScartati =  "";
    this.interventiDaValidare =  "";
    this.diagnosiScartate =  "";
    this.diagnosiDaValidare =  "";
    this.prestazioniScartate =  "";
    this.prestazioniDaValidare =  "";
    this.presenzeScartate =  "";
    this.presenzeDaValidare =  "";

  }

  /**
   * Prepara dati per dashboard
   * @param data
   */
  async prepareAllData(data: any) {

    this.utentiIncidenti = data.utentiIncidenti;
    this.utentiPrevalenti = data.utentiPrevalenti;
    this.utentiPrevalenti0101 = data.utentiPrevalenti0101;
    this.contattiPrevalenti = data.contattiPrevalenti;


    this.utentiContattiAttivi = data.utentiContattiAttivi;
    this.utentiContattiNonAvviati0101 = data.utentiContattiNonAvviati0101;
    this.dataUltimaAperturaContatto = data.dataUltimaAperturaContatto;
    this.dataUltimaConclusioneContatto = data.dataUltimaConclusioneContatto;
    this.interventiValidati = data.interventiValidati;
    this.diagnosiValidate = data.diagnosiValidate;
    this.prestazioniValidate = data.prestazioniValidate;
    this.presenzeValidate = data.presenzeValidate;
    this.contattiValidati = data.contattiValidati;

  }

  /**
   * Prepara dati per dashboard Sintesi
   * @param data
   */
  async prepareAllDataSintesi(data: any) {
    this.contattiScartati = data.contattiScartati;
    this.contattiDaValidare = data.contattiDaValidare;
    this.interventiScartati = data.interventiScartati;
    this.interventiDaValidare = data.interventiDaValidare;
    this.diagnosiScartate = data.diagnosiScartate;
    this.diagnosiDaValidare = data.diagnosiDaValidare;
    this.prestazioniScartate = data.prestazioniScartate;
    this.prestazioniDaValidare = data.prestazioniDaValidare;
    this.presenzeScartate = data.presenzeScartate;
    this.presenzeDaValidare = data.presenzeDaValidare;

  }

  async prepareAllDataSintesiAnomalie(data: any) {
    this.contattiNonInviati = data.contattiNonInviati;
    this.interventiNonInviati = data.interventiNonInviati;
    this.diagnosiScaleNonInviate = data.diagnosiNonInviate;
    this.prestazioniNonInviate = data.prestazioniNonInviate;
    this.presenzeNonInviate = data.presenzeNonInviate;
    this.contattiSenzaInterventiDiaPrestPres = data.contattiSenzaInterventiDiaPrestPres;
    this.contattiUltimaPrestazione180 = data.ultimaPrestMaggiore180;
    this.contattiApertiSenzaPrestazioni = data.contattiApertiSenzaPrest;
  }

  /**
   * Attiva pagina dashboard
   */
  public switchToDashboard() {

    this.reportTab = false;
    this.reportData = false;
    this.dashboard = false;
    this.dashboardActive = "active";
    this.reportActive = "";
    this.reportDataActive = "";
    this.resetData();
    this.resetDataSintesi();

  }

  /**
   * Attiva pagina report
   */
  public switchToReport() {

    this.reportTab = true;
    this.reportData = false;
    this.dashboard = false;
    this.dashboardActive = "";
    this.reportActive = "active";
    this.reportDataActive = "";

  }

  /**
   * Attiva paginea report data e visualizza iframe knowage
   * @param title
   * @param link
   */
  public switchToReportData(title: string, link: string) {

    if (this.getRuolo(this.OPERATORE_REGIONE)) {

      link += "&PARAMETERS=par_ruolo_sismapp%3D" + this.utente.nomeRuolo;

    }

    if (this.getRuolo(this.OPERATORE_DSM)) {

      link += "&PARAMETERS=par_ruolo_sismapp%3D" + this.utente.nomeRuolo + "%26par_asl%3D" + this.utente.codiceAsl;

    }

    this.safeLinkReport = this.getSafeUrl(link);
    this.reportLink = link;
    this.reportData = true;
    this.reportTab = false;
    this.reportTitle = title;
    this.dashboardActive = "";
    this.reportActive = "";
    this.reportDataActive = "active";
    this.reportDataVisible = true;
    console.log(link)

  }

  /**
   * Chiudi tab knowage report
   */
  public closeReportData() {

    this.reportTab = false;
    this.reportData = false;
    this.reportTitle = "";
    this.dashboard = true;
    this.dashboardActive = "active";
    this.reportActive = "";
    this.reportDataActive = "";
    this.reportDataVisible = false;

  }

  /**
   * Ottiene url safe per iframe
   * @param link
   * @returns
   */
  public getSafeUrl(link: string) {

    return this.sanitizer.bypassSecurityTrustResourceUrl(this.knowageUrl + link)

  }

  /**
   * Recupera ASL
   */
  public async getAllAsl() {

    this.apiService.getAllAsl().subscribe(data => {

      for (let item of data) {

        if (item.codiceAzienda != "98" && item.codiceAzienda != "098" && item.codiceAzienda != "998" && item.codiceAzienda != "999") {

          this.aslList.push(item);

        }

      }

    });

  }

  labelAnnoPeriodo: string = "Specifica ANNO e PERIODO";
  labelSubAnnoPeriodo: string = "";
  labelAnno: string = "Anno";
  labelPeriodo: string = "Periodo";
  maxDateA: NgbDateStruct;
  // @ts-ignore
  dateDa: NgbDateStruct ;
  // @ts-ignore
  dateA: NgbDateStruct ;
  misuraDiFrequenzaCSV: string = '';//'Incidente';
  avvioContattoCSV: string = 'tutti';
  labelTrasmissione: string = 'record';
  errorAnno = false;
  errorMisura = false;
  errorIdentificativo = false;

  /**
   *
   * @param entity
   * @param content
   */
  downloadCsv(entity: string, content: any) {
    this.errorAnno = false;
    this.errorMisura = false;
    this.maxDateA = this.calendar.getToday();
    // @ts-ignore
    this.dateA = undefined;
    // @ts-ignore
    this.dateDa = undefined;
    //this.dateA = this.calendar.getToday();
    //this.dateDa = this.calendar.getPrev(this.calendar.getToday(), 'd', 1);
    this.annoCSV = (new Date()).getFullYear();

    if (entity == "anagrafica") {
      this.labelAnnoPeriodo = "TABELLA ANAGRAFICA";
      this.labelSubAnnoPeriodo = "Specifica MISURA DI FREQUENZA e PERIODO";
      this.labelAnno = "Anno di prevalenza del contatto";
      this.labelPeriodo = "Mese di prevalenza del contatto";
    }
    if (entity == "contatto-territoriale") {
      this.labelAnnoPeriodo = "TABELLA CONTATTO TERRITORIALE";
      this.labelSubAnnoPeriodo = "Specifica MISURA DI FREQUENZA e PERIODO";
      this.labelAnno = "Anno di prevalenza del contatto";
      this.labelPeriodo = "Mese di prevalenza del contatto";
      this.labelTrasmissione = "contatto";
    }
    if (entity == "contatto-semiresidenziale") {
      this.labelAnnoPeriodo = "TABELLA CONTATTO SEMIRESIDENZIALE";
      this.labelSubAnnoPeriodo = "Specifica MISURA DI FREQUENZA e PERIODO";
      this.labelAnno = "Anno di prevalenza del contatto";
      this.labelPeriodo = "Mese di prevalenza del contatto";
      this.labelTrasmissione = "contatto";

    }
    if (entity == "prestazione-territoriale") {
      this.labelAnnoPeriodo = "TABELLA PRESTAZIONE TERRITORIALE";
      this.labelSubAnnoPeriodo = "Specifica MISURA DI FREQUENZA, PERIODO e ANNO PRESTAZIONE";
      this.labelAnno = "Anno prestazione *";
      this.labelPeriodo = "Mese prestazione";
      this.labelTrasmissione = "prestazione";

    }
    if (entity == "prestazione-semiresidenziale") {
      this.labelAnnoPeriodo = "TABELLA PRESTAZIONE SEMIRESIDENZIALE";
      this.labelSubAnnoPeriodo = "Specifica MISURA DI FREQUENZA, PERIODO e ANNO PRESTAZIONE";
      this.labelAnno = "Anno prestazione *";
      this.labelPeriodo = "Mese prestazione";
      this.labelTrasmissione = "prestazione";

    }
    if (entity == "presenze-semiresidenziale") {
      this.labelAnnoPeriodo = "TABELLA PRESENZA SEMIRESIDENZIALE";
      this.labelSubAnnoPeriodo = "Specifica MISURA DI FREQUENZA, PERIODO e ANNO PRESENZA";
      this.labelAnno = "Anno presenza *";
      this.labelPeriodo = "Mese presenza";
      this.labelTrasmissione = "presenza";

    }
    if (entity == "intervento-territoriale") {
      this.labelAnnoPeriodo = "TABELLA INTERVENTO";
      this.labelSubAnnoPeriodo = "Specifica MISURA DI FREQUENZA e PERIODO";
      this.labelAnno = "Anno intervento";
      this.labelPeriodo = "Mese intervento";
      this.labelTrasmissione = "intervento";
      this.annoCSV=0;

    }
    if (entity == "diagnosi-scale") {
      this.labelAnnoPeriodo = "TABELLA DIAGNOSI&SCALE";
      this.labelSubAnnoPeriodo = "Specifica MISURA DI FREQUENZA e PERIODO";
      this.labelAnno = "Anno diagnosi";
      this.labelPeriodo = "Mese diagnosi";
      this.labelTrasmissione = "diagnosi";
      this.annoCSV=0;

    }
    this.typeCSV = entity;
    this.getAllStruttureCsv();
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'xl'}).result.then((result) => {

    }, (reason) => {

    });

  }

  downloadCsvFile() {
    this.errorMisura = false;
    this.errorAnno = false;
if(this.typeCSV.toLowerCase() == 'anagrafica'&&this.isSemiresidenziale()){
  this.avvioContattoCSV="avviato";
}
    if (this.getRuolo(this.OPERATORE_REGIONE) && this.struttureCSV != "") {
      this.aslCSV = this.struttureListCsv.find(struttura => {
        return struttura.codStruttura == this.struttureCSV
      })?.codAzienda;
    }
    if (!this.misuraDiFrequenzaCSV || !this.dateDa || !this.dateA) {
      this.errorMisura = true;
      if(!this.misuraDiFrequenzaCSV)      this.toastService.showError("Attenzione specificare la misura di frequenza");
      if(!this.dateDa)      this.toastService.showError("Attenzione specificare il campo 'periodo dal'");
      if(!this.dateA)      this.toastService.showError("Attenzione specificare il campo 'periodo al'");
    } else if (CommonsUtil.afterNgbDateStruct(this.dateDa,this.dateA)){
      this.errorMisura = true;
      this.toastService.showError("Attenzione 'periodo al' è minore di 'periodo dal'");
    }
    if (!this.annoCSV && (
      this.typeCSV.toLowerCase() == 'prestazione-territoriale' ||
      this.typeCSV.toLowerCase() == 'presenze-semiresidenziale' ||
      this.typeCSV.toLowerCase() == 'prestazione-semiresidenziale'
    )) {
      this.errorAnno = true;
      this.toastService.showError("Attenzione specificare il campo ANNO");
    }
    if(this.tipoIdentificativoCSV&&!this.codiceFiscaleCSV){
      this.errorIdentificativo = true;
      this.toastService.showError("Attenzione specificare il campo IDENTIFICATIVO");
    } else {
      this.errorIdentificativo = false;
    }
    if (!this.errorMisura && !this.errorAnno && !this.errorIdentificativo) {
      this.downloadInCorso = true;

      this.modalService.dismissAll();

      this.apiService.downloadCsv(
        this.typeCSV,
        this.annoCSV,
        this.periodoCSV,
        this.aslCSV,
        this.ambitoCSV,
        this.ssnCSV,
        this.struttureCSV,
        this.statoContattoCSV,
        this.statoRecordCSV,
        this.tipoIdentificativoCSV,
        this.nomeCSV,
        this.cognomeCSV,
        this.codiceFiscaleCSV,
        this.competenzeTerritorialiCCSV,
        this.struttureOperatore,
        this.misuraDiFrequenzaCSV,
        this.dateDa.day + "-" + this.dateDa.month + "-" + this.dateDa.year,
        this.dateA.day + "-" + this.dateA.month + "-" + this.dateA.year,
        this.avvioContattoCSV,
        this.utente.idRuolo!
      )
        .subscribe({
          next: (data: any) => {
            const blob = new Blob([data], {type: 'application/octet-stream'});
            const fileName = 'SISTEP_' + this.typeCSV + '_' + this.misuraDiFrequenzaCSV + '_' + this.dateDa.day + "-" + this.dateDa.month + "-" + this.dateDa.year + '_' +this.dateA.day + "-" + this.dateA.month + "-" + this.dateA.year+ '.csv';
            saveAs(blob, fileName);
            this.downloadInCorso = false;

          }, error: (e) => {
            alert(JSON.stringify(e));
            this.downloadInCorso = false;

          }, complete: () => {
            this.emptyFilter();
          }
        });
    }
  }

  emptyFilter() {

    this.misuraDiFrequenzaCSV = '';
    this.avvioContattoCSV = 'tutti';
    this.aslCSV = "";
    this.ambitoCSV = "";
    this.ssnCSV = 0;
    this.struttureCSV = "";
    this.statoContattoCSV = -1;
    this.statoRecordCSV = -1;
    this.tipoIdentificativoCSV = "";
    this.nomeCSV = "";
    this.cognomeCSV = "";
    this.codiceFiscaleCSV = "";
    this.competenzeTerritorialiCCSV = "struttureInAsl";
    this.errorAnno = false;
    this.errorMisura = false;
    this.getUtente()
  }

  tipoStrutturaSemiresidenziale() {

    return this.ambitoCSV=='Semiresidenziale';
  }

  showStrutture() {
    if(this.defaultCompetenzeTerritoriali&&this.defaultCompetenzeTerritoriali=='fuoriasl'){
      if(this.getRuolo(this.OPERATORE_ASL)||this.getRuolo(this.OPERATORE_DSM)){
        return false;
      } else {
        return true;
      }

    } else { return true}
  }
}
