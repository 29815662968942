<app-header (userLoaded)="userLoaded($event)"></app-header>
<main class="web-view" id="PageV" *ngIf="userIsLoaded">
  <router-outlet></router-outlet>
</main>
<div
  *ngIf="!userIsLoaded"
  class="spinner-border text-primary landingspinner"
  style="width: 5rem; height: 5rem"
  role="status"
></div>
<app-footer></app-footer>


<!-- app per i messaggi toast -->
<app-toasts class="ngb-toasts"
            aria-live="polite" aria-atomic="true"></app-toasts>
<svg version="1.1" display="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
     x="0px" y="0px" viewBox="0 0 64 64">
  <defs>
    <!--
    -->
    <style>
      g:target { display: inline; }
    </style>

    <svg viewBox="0 0 64 64" id="user"><use xlink:href="#user-bg" x="0" y="0"/></svg>
    <svg viewBox="0 0 64 64" id="calendar"><use xlink:href="#calendar-bg" x="0" y="0"/></svg>
    <svg viewBox="0 0 64 64" id="checkbox"><use xlink:href="#checkbox-bg" x="0" y="0"/></svg>
    <svg viewBox="0 0 64 64" id="checkbox-check"><use xlink:href="#checkbox-check-bg" x="0" y="0"/></svg>
    <svg viewBox="0 0 64 64" id="radio"><use xlink:href="#radio-bg" x="0" y="0"/></svg>
    <svg viewBox="0 0 64 64" id="radio-check"><use xlink:href="#radio-check-bg" x="0" y="0"/></svg>
    <svg viewBox="0 0 64 64" id="circle"><use xlink:href="#circle-bg" x="0" y="0"/></svg>
    <svg viewBox="0 0 64 64" id="circle-full"><use xlink:href="#circle-full-bg" x="0" y="0"/></svg>
    <svg viewBox="0 0 64 64" id="close"><use xlink:href="#close-bg" x="0" y="0"/></svg>
    <svg viewBox="0 0 64 64" id="complete"><use xlink:href="#complete-bg" x="0" y="0"/></svg>
    <svg viewBox="0 0 64 64" id="incomplete"><use xlink:href="#incomplete-bg" x="0" y="0"/></svg>
    <svg viewBox="0 0 64 64" id="danger"><use xlink:href="#danger-bg" x="0" y="0"/></svg>
    <svg viewBox="0 0 64 64" id="help"><use xlink:href="#help-bg" x="0" y="0"/></svg>
    <svg viewBox="0 0 64 64" id="info"><use xlink:href="#info-bg" x="0" y="0"/></svg>
    <svg viewBox="0 0 64 64" id="drop-down"><use xlink:href="#drop-down-bg" x="0" y="0"/></svg>
    <svg viewBox="0 0 64 64" id="drop-left"><use xlink:href="#drop-left-bg" x="0" y="0"/></svg>
    <svg viewBox="0 0 64 64" id="drop-right"><use xlink:href="#drop-right-bg" x="0" y="0"/></svg>
    <svg viewBox="0 0 64 64" id="drop-up"><use xlink:href="#drop-up-bg" x="0" y="0"/></svg>
    <svg viewBox="0 0 64 64" id="notify"><use xlink:href="#notify-bg" x="0" y="0"/></svg>
    <svg viewBox="0 0 64 64" id="upload"><use xlink:href="#upload-bg" x="0" y="0"/></svg>
    <svg viewBox="0 0 64 64" id="download"><use xlink:href="#download-bg" x="0" y="0"/></svg>
    <svg viewBox="0 0 64 64" id="open"><use xlink:href="#open-bg" x="0" y="0"/></svg>
    <svg viewBox="0 0 64 64" id="search"><use xlink:href="#search-bg" x="0" y="0"/></svg>
    <svg viewBox="0 0 64 64" id="edit"><use xlink:href="#edit-bg" x="0" y="0"/></svg>
    <svg viewBox="0 0 64 64" id="trash"><use xlink:href="#trash-bg" x="0" y="0"/></svg>
    <svg viewBox="0 0 64 64" id="file-xml"><use xlink:href="#file-xml-bg" x="0" y="0"/></svg>
    <svg viewBox="0 0 64 64" id="warning"><use xlink:href="#warning-bg" x="0" y="0"/></svg>
    <svg viewBox="0 0 64 64" id="success"><use xlink:href="#success-bg" x="0" y="0"/></svg>

  </defs>

  <!-- user -->
  <g viewBox="0 0 64 64" id="user-bg">
    <path d="M32,32A28,28,0,0,0,4,60H7.8C7.8,46.08,18.08,35.8,32,35.8S56.2,46.08,56.2,60H60A28,28,0,0,0,32,32Z"/>
    <path d="M32,8A12,12,0,1,1,20,20,12,12,0,0,1,32,8m0-4A16,16,0,1,0,48,20,16,16,0,0,0,32,4Z"/>
  </g>
  <!-- calendar -->
  <g viewBox="0 0 64 64" id="calendar-bg">
    <path d="M54.91,56H9.09A5.11,5.11,0,0,1,4,50.9V15.79A5.1,5.1,0,0,1,9.09,10.7h8.39V8h4.79v2.7H41.73V8h4.8v2.7h8.38A5.1,5.1,0,0,1,60,15.79V50.9A5.11,5.11,0,0,1,54.91,56ZM9.09,15.5a.29.29,0,0,0-.29.29V50.9a.3.3,0,0,0,.29.3H54.91a.3.3,0,0,0,.29-.3V15.8a.3.3,0,0,0-.29-.3H46.53v2.71h-4.8V15.5H22.27v2.71H17.48V15.5ZM54.61,26.3H9.39V21.5H54.61Z"/>
  </g>
  <!-- checkbox -->
  <g viewBox="0 0 64 64" id="checkbox-bg">
    <rect x="7" y="7" width="50" height="50" style="fill: #fff"/>
    <path d="M57,7V57H7V7H57m3-3H4V60H60V4Z"/>
  </g>
  <!-- checkbox-check -->
  <g viewBox="0 0 64 64" id="checkbox-check-bg">
    <rect x="7" y="7" width="50" height="50" style="fill: #fff"/>
    <path d="M57,7V57H7V7H57m3-3H4V60H60V4Z"/>
    <path d="M23.42,31.13l5.43,6.08,16.69-19.7a2.69,2.69,0,0,1,3.72-.37,2.53,2.53,0,0,1,.41,3.55l0,.06L32.72,40.84a4.8,4.8,0,0,1-6.77.59l-.39-.37-6-6.35A2.64,2.64,0,0,1,19.62,31l0,0a2.64,2.64,0,0,1,3.73.15Z"/>
  </g>
  <!-- radio -->
  <g viewBox="0 0 64 64" id="radio-bg">
    <circle cx="32" cy="32" r="25" style="fill: #fff"/>
    <path d="M32,4A28,28,0,1,1,4,32H4A28,28,0,0,1,32,4Zm0,3.21A24.79,24.79,0,1,0,56.79,32,24.79,24.79,0,0,0,32,7.21Z"/>
  </g>
  <!-- radio-check -->
  <g viewBox="0 0 64 64" id="radio-check-bg">
    <circle cx="32" cy="32" r="25" style="fill: #fff"/>
    <path d="M32,4A28,28,0,1,1,4,32H4A28,28,0,0,1,32,4Zm0,3.21A24.79,24.79,0,1,0,56.79,32,24.79,24.79,0,0,0,32,7.21Z"/>
    <circle cx="32" cy="32" r="12"/>
  </g>
  <!-- circle -->
  <g viewBox="0 0 64 64" id="circle-bg">
    <path d="M60,32A28,28,0,1,1,32,4,28,28,0,0,1,60,32Zm-3.8,0C56.2,18.08,45.92,7.8,32,7.8S7.8,18.08,7.8,32,18.08,56.2,32,56.2,56.2,45.92,56.2,32Z"/>
  </g>
  <!-- circle-full -->
  <g viewBox="0 0 64 64" id="circle-full-bg">
    <path d="M60,32A28,28,0,1,1,32,4,28,28,0,0,1,60,32Z"/>
  </g>
  <!-- close -->
  <g viewBox="0 0 64 64" id="close-bg">
    <path d="M60,32A28,28,0,1,1,32,4,28,28,0,0,1,60,32Zm-3.8,0C56.2,18.08,45.92,7.8,32,7.8S7.8,18.08,7.8,32,18.08,56.2,32,56.2,56.2,45.92,56.2,32Z"/>
    <path d="M45,42.32,42.32,45,32.14,34.82,21.67,45,19,42.32,29.45,32.14,19,21.68,21.68,19,32.14,29.45,42.32,19,45,21.67,34.82,32.14Z"/>
  </g>
  <!-- complete -->
  <g viewBox="0 0 64 64" id="complete-bg">
    <path d="M32,4A28,28,0,1,0,60,32,28,28,0,0,0,32,4ZM46.7,24.45l-17.81,18a2.15,2.15,0,0,1-1.5.61A2.09,2.09,0,0,1,26,42.59l-8.5-7.18a2.1,2.1,0,0,1-.27-3,2.12,2.12,0,0,1,3-.28l7,6L43.72,21.47a2.11,2.11,0,0,1,3,3Z"/>
  </g>
  <!-- incomplete -->
  <g viewBox="0 0 64 64" id="incomplete-bg">
    <path d="M32,4A28,28,0,1,0,60,32,28,28,0,0,0,32,4ZM43.37,40.53a1.93,1.93,0,0,1,0,2.74l-.09.09a1.93,1.93,0,0,1-2.74,0L32,34.83l-8.53,8.53a1.93,1.93,0,0,1-2.74,0l-.09-.09a2,2,0,0,1,0-2.74L29.18,32l-8.53-8.53a2,2,0,0,1,0-2.74A1.91,1.91,0,0,1,22,20.17a1.94,1.94,0,0,1,1.36.55L32,29.17l8.53-8.53a1.93,1.93,0,0,1,2.74,0l.09.09a1.93,1.93,0,0,1,0,2.74L34.84,32Z"/>
  </g>
  <!-- danger -->
  <g viewBox="0 0 64 64" id="danger-bg">
    <path d="M60,32A28,28,0,1,1,32,4,28,28,0,0,1,60,32Zm-3.8,0C56.2,18.08,45.92,7.8,32,7.8S7.8,18.08,7.8,32,18.08,56.2,32,56.2,56.2,45.92,56.2,32Z"/>
    <path d="M20.73,43.36l-.09-.09a1.93,1.93,0,0,1,0-2.74L29.17,32l-8.53-8.53a1.93,1.93,0,0,1,0-2.74h0a1.93,1.93,0,0,1,2.72,0L32,29.17l8.53-8.53a1.93,1.93,0,0,1,2.74,0l.09.09a1.93,1.93,0,0,1,0,2.74L34.83,32l8.53,8.53a1.93,1.93,0,0,1,0,2.74l-.09.09a1.93,1.93,0,0,1-2.74,0L32,34.83l-8.53,8.53A1.93,1.93,0,0,1,20.73,43.36Z"/>
  </g>
  <!-- help -->
  <g viewBox="0 0 64 64" id="help-bg">
    <path d="M60,32A28,28,0,1,1,32,4,28,28,0,0,1,60,32Zm-3.8,0C56.2,18.08,45.92,7.8,32,7.8S7.8,18.08,7.8,32,18.08,56.2,32,56.2,56.2,45.92,56.2,32Z"/>
    <ellipse cx="32" cy="45.52" rx="2.5" ry="2.52"/>
    <path d="M32,40a2,2,0,0,1-2-1.85c-.15-1.92-.15-4.69,1.52-6.51.92-1,1.87-1.84,2.78-2.66,2.49-2.23,3.9-3.61,3.95-6a5.79,5.79,0,0,0-1.69-4.24A6,6,0,0,0,32.05,17c-4.46.11-6,3.69-6,7a2,2,0,0,1-4,0c0-6.44,4-10.86,10-11a10.11,10.11,0,0,1,7.44,2.88A9.82,9.82,0,0,1,42.26,23C42.17,27.3,39.53,29.67,37,32c-.84.76-1.72,1.54-2.51,2.41-.31.34-.65,1.22-.48,3.49A2,2,0,0,1,32.15,40Z"/>
  </g>
  <!-- info -->
  <g viewBox="0 0 64 64" id="info-bg">
    <path d="M60,32A28,28,0,1,1,32,4,28,28,0,0,1,60,32Zm-3.8,0C56.2,18.08,45.92,7.8,32,7.8S7.8,18.08,7.8,32,18.08,56.2,32,56.2,56.2,45.92,56.2,32Z"/>
    <path d="M32,48a2.61,2.61,0,0,0,2.5-2.71V26.74A2.61,2.61,0,0,0,32,24h0a2.61,2.61,0,0,0-2.5,2.71V45.32A2.61,2.61,0,0,0,32,48Z"/>
    <ellipse cx="32" cy="18.52" rx="2.5" ry="2.52"/>
  </g>
  <!-- drop-down -->
  <g viewBox="0 0 64 64" id="drop-down-bg">
    <path d="M32,48a3.88,3.88,0,0,1-2.77-1.15L5.15,22.71a3.93,3.93,0,0,1,5.55-5.56L32,38.51,53.3,17.15a3.93,3.93,0,1,1,5.55,5.56L34.77,46.85A3.88,3.88,0,0,1,32,48Z"/>
  </g>
  <!-- drop-left -->
  <g viewBox="0 0 64 64" id="drop-left-bg">
    <path d="M16,32a3.88,3.88,0,0,1,1.15-2.77L41.29,5.15a3.93,3.93,0,0,1,5.56,5.55L25.49,32,46.85,53.3a3.93,3.93,0,1,1-5.56,5.55L17.15,34.77A3.88,3.88,0,0,1,16,32Z"/>
  </g>
  <!-- drop-right -->
  <g viewBox="0 0 64 64" id="drop-right-bg">
    <path d="M48,32a3.88,3.88,0,0,1-1.15,2.77L22.71,58.85a3.93,3.93,0,1,1-5.56-5.55L38.51,32,17.15,10.7a3.93,3.93,0,0,1,5.56-5.55L46.85,29.23A3.88,3.88,0,0,1,48,32Z"/>
  </g>
  <!-- drop-up -->
  <g viewBox="0 0 64 64" id="drop-up-bg">
    <path d="M32,16a3.88,3.88,0,0,1,2.77,1.15L58.85,41.29a3.93,3.93,0,1,1-5.55,5.56L32,25.49,10.7,46.85a3.93,3.93,0,0,1-5.55-5.56L29.23,17.15A3.88,3.88,0,0,1,32,16Z"/>
  </g>
  <!-- notify -->
  <g viewBox="0 0 64 64" id="notify-bg">
    <path d="M32,60h0a9.18,9.18,0,0,1-9.21-9.1v-.67H10A5.91,5.91,0,0,1,4,44.5a5.79,5.79,0,0,1,1.65-4.18,5.92,5.92,0,0,1,4.14-1.79H10a.68.68,0,0,0,.67-.67v-13A21.2,21.2,0,0,1,32,4h.22A21.3,21.3,0,0,1,53.31,24.83v13a.67.67,0,0,0,.19.47.67.67,0,0,0,.48.2h.11A5.89,5.89,0,0,1,60,44.26a5.76,5.76,0,0,1-1.65,4.17,5.87,5.87,0,0,1-4.14,1.8h-13v.67A9.17,9.17,0,0,1,32,60ZM25.8,50.23v.65a6.19,6.19,0,0,0,12.37,0v-.65ZM10,41.54a2.87,2.87,0,0,0-2.89,2.84,2.82,2.82,0,0,0,.84,2,2.93,2.93,0,0,0,2,.83H54a2.84,2.84,0,1,0,0-5.67h0a3.7,3.7,0,0,1-3.71-3.68v-13a18.28,18.28,0,0,0-36.56,0v13A3.7,3.7,0,0,1,10,41.54Z"/>
  </g>
  <!-- upload -->
  <g viewBox="0 0 64 64" id="upload-bg">
    <path d="M21.52,16.57a2.08,2.08,0,0,0,2.95,0l5.42-5.43v31A2.09,2.09,0,0,0,32,44.22h0a2.06,2.06,0,0,0,1.47-.6,2.1,2.1,0,0,0,.62-1.49v-31l5.44,5.44a2.09,2.09,0,0,0,3-3l-9-9h0l-.14-.14A2.25,2.25,0,0,0,32.41,4L32,4h0a2.06,2.06,0,0,0-1.48.61l-.15.14-8.84,8.87a2,2,0,0,0-.62,1.47A2.06,2.06,0,0,0,21.52,16.57Z"/>
    <path d="M60,42.14V53.39A6.62,6.62,0,0,1,53.41,60H10.6A6.61,6.61,0,0,1,4,53.39V42.14a2.1,2.1,0,1,1,4.19,0V53.39a2.41,2.41,0,0,0,2.4,2.41H53.41a2.41,2.41,0,0,0,2.4-2.41V42.14a2.1,2.1,0,0,1,3.58-1.49A2.14,2.14,0,0,1,60,42.14Z"/>
  </g>
  <!-- download -->
  <g viewBox="0 0 64 64" id="download-bg">
    <path d="M42.47,31.65a2.09,2.09,0,0,0-3,0L34.1,37.08v-31A2.1,2.1,0,0,0,32,4h0a2.06,2.06,0,0,0-1.48.61A2.12,2.12,0,0,0,29.9,6.1v31l-5.43-5.44a2.1,2.1,0,0,0-3,3l9,9h0l.15.13a2.13,2.13,0,0,0,.91.43l.41,0h0a2.11,2.11,0,0,0,1.48-.6l.14-.15,8.85-8.86a2.1,2.1,0,0,0,0-3Z"/>
    <path d="M60,42.14V53.39A6.61,6.61,0,0,1,53.41,60H10.59A6.61,6.61,0,0,1,4,53.39V42.14a2.1,2.1,0,1,1,4.19,0V53.39a2.41,2.41,0,0,0,2.4,2.41H53.41a2.41,2.41,0,0,0,2.4-2.41V42.14A2.11,2.11,0,0,1,57.9,40a2.1,2.1,0,0,1,2.1,2.1Z"/>
  </g>
  <!-- open -->
  <g viewBox="0 0 64 64" id="open-bg">
    <path d="M29.91,11.37A2.08,2.08,0,0,0,32,13.46h7.67L17.76,35.37a2.08,2.08,0,0,0,0,3h0a2.11,2.11,0,0,0,1.48.61,2.13,2.13,0,0,0,1.49-.61l21.9-21.91V24.1a2.1,2.1,0,0,0,4.2,0V11.37h0a.64.64,0,0,0,0-.2,2.25,2.25,0,0,0-.33-.95l-.27-.32h0a2,2,0,0,0-1.47-.61H32a2.06,2.06,0,0,0-1.48.61A2.12,2.12,0,0,0,29.91,11.37Z"/>
    <path d="M60,42.14V53.39A6.61,6.61,0,0,1,53.41,60H10.59A6.61,6.61,0,0,1,4,53.39V42.14a2.1,2.1,0,1,1,4.19,0V53.39a2.41,2.41,0,0,0,2.4,2.41H53.41a2.41,2.41,0,0,0,2.4-2.41V42.14A2.11,2.11,0,0,1,57.9,40a2.1,2.1,0,0,1,2.1,2.1Z"/>
  </g>
  <!-- search -->
  <g viewBox="0 0 64 64" id="search-bg">
    <path d="M60,57.75,43.4,41.42A23.35,23.35,0,0,0,49,26.51,22.51,22.51,0,1,0,26.51,49,23.35,23.35,0,0,0,41.43,43.4L57.75,60ZM26.51,46.21a19.7,19.7,0,1,1,19.7-19.7A19.69,19.69,0,0,1,26.51,46.21Z"/>
  </g>
  <!-- edit -->
  <g viewBox="0 0 64 64" id="edit-bg">
    <path d="M12.1,46.2a1,1,0,0,0,.25,0l.25,0L21,43.84a.19.19,0,0,0,.1-.05L52.24,12.6A5.14,5.14,0,0,0,52,5.37a5.13,5.13,0,0,0-7,0L13.66,36.69a.19.19,0,0,0-.05.09L11.41,45a1,1,0,0,0,.1.73A.92.92,0,0,0,12.1,46.2ZM44.38,9.89l2.55-2.57a2.38,2.38,0,0,1,3.39,3.33l-2.57,2.59-.36.36L47,13.24l-2.65-2.65L44,10.24ZM16.15,38.1,41.7,12.53l.36-.35.35.35,2.65,2.66.35.35-.35.35L19.68,41.27l-.33.33L19,41.29l-2.83-2.45-.4-.36Z"/>
    <path d="M5.44,56H58.56A1.53,1.53,0,0,1,60,57.6v.8A1.53,1.53,0,0,1,58.56,60H5.44A1.53,1.53,0,0,1,4,58.4v-.8A1.53,1.53,0,0,1,5.44,56Z"/>
  </g>
  <!-- trash -->
  <g viewBox="0 0 64 64" id="trash-bg">
    <path d="M48,8H43.65A6,6,0,0,0,38,4H26a6,6,0,0,0-5.65,4H16a6,6,0,0,0-6,6v6h4V54a6,6,0,0,0,6,6H44a6,6,0,0,0,6-6V20h4V14A6,6,0,0,0,48,8ZM46,54a2,2,0,0,1-2,2H20a2,2,0,0,1-2-2V20H46Zm4-38H14V14a2,2,0,0,1,2-2H48a2,2,0,0,1,2,2Z"/>
  </g>
  <!-- file-xml -->
  <g viewBox="0 0 64 64" id="file-xml-bg">
    <path d="M54,28.5H50V17.88L36.12,4H12a6,6,0,0,0-6,6V54a6,6,0,0,0,6,6H44a6,6,0,0,0,6-6v-.5h4A6.51,6.51,0,0,0,60.5,47V35A6.51,6.51,0,0,0,54,28.5ZM47,54a3,3,0,0,1-3,3H12a3,3,0,0,1-3-3V10a3,3,0,0,1,3-3H34.88L47,19.12V28.5H22A6.51,6.51,0,0,0,15.5,35V47A6.51,6.51,0,0,0,22,53.5H47Zm10.5-7A3.5,3.5,0,0,1,54,50.5H22A3.5,3.5,0,0,1,18.5,47V35A3.5,3.5,0,0,1,22,31.5H54A3.5,3.5,0,0,1,57.5,35Z"/>
    <path d="M40.12,19.88H48l-3-3H40.12a3,3,0,0,1-3-3V9l-3-3v7.88A6,6,0,0,0,40.12,19.88Z"/>
    <path d="M32.5,46H30.12L27.2,41.88,24.38,46H22L26,40.36,22.16,35h2.38l2.76,4,2.61-4h2.41L28.5,40.43Z"/>
    <path d="M40.88,46.06H38.79l-3.12-8.42V46H33.75V35h2.86l3.27,8.87L43.09,35h2.84V46H44v-8.4h0Z"/>
    <path d="M50.38,35v9.41h4.9V46H48.45V35Z"/>
  </g>
  <!-- warning -->
  <g viewBox="0 0 64 64" id="warning-bg">
    <path d="M60,32A28,28,0,1,1,32,4,28,28,0,0,1,60,32Zm-3.8,0C56.2,18.08,45.92,7.8,32,7.8S7.8,18.08,7.8,32,18.08,56.2,32,56.2,56.2,45.92,56.2,32Z"/>
    <path d="M32,16a2.61,2.61,0,0,0-2.5,2.71V37.29A2.61,2.61,0,0,0,32,40h0a2.61,2.61,0,0,0,2.5-2.71V18.71A2.61,2.61,0,0,0,32,16Z"/>
    <ellipse cx="32" cy="45.52" rx="2.5" ry="2.52"/>
  </g>
  <!-- success -->
  <g viewBox="0 0 64 64" id="success-bg">
    <path d="M60,32A28,28,0,1,1,32,4,28,28,0,0,1,60,32Zm-3.8,0C56.2,18.08,45.92,7.8,32,7.8S7.8,18.08,7.8,32,18.08,56.2,32,56.2,56.2,45.92,56.2,32Z"/>
    <path d="M43.72,21.47,27.27,38.12l-7-6a2.12,2.12,0,0,0-3,.28,2.1,2.1,0,0,0,.27,3L26,42.59a2.09,2.09,0,0,0,1.36.48,2.15,2.15,0,0,0,1.5-.61l17.81-18a2.11,2.11,0,0,0-3-3Z"/>
  </g>

</svg>
