<div class="container py-3" demon-cf="anagrafica" demon-cf-id="anagrafica" id="demonView6-3" demon-ev="true">
  <div class="card">
    <div class="card-header">
      <div class="title-3" demon-anagrafica="titolo"> Contatto #{{id}}</div>
    </div>

    <ul class="nav nav-tabs" id="detailTab" role="tablist" style="width:100%">
      <li class="nav-item">
        <a class="nav-link title-4 {{isActiveAnagrafica?'active':''}}" id="anagrafica-tab"
          data-toggle="tab" href="#anagrafica" role="tab" aria-controls="anagrafica" aria-selected="true">
          Anagrafica
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link title-4 {{isActiveContatti?'active':''}}" id="contatto-tab" data-toggle="tab" href="#contatto"
          role="tab" aria-controls="contatto" aria-selected="false" [ngStyle]=" ( statoAttuale === '4' && listContatti.length > 0 && listContatti[0].statoAttuale.id === 4 )? {'color': 'rgb(235, 94, 94)'} : {}" >
          Contatti
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link title-4 {{isActiveInterventi?'active':''}}" id="intevento-tab" data-toggle="tab" href="#intervento"
          role="tab" aria-controls="intevento" aria-selected="false" [ngStyle]=" ( statoAttuale === '4' && listInterventi.length > 0 ) ? {'color': 'rgb(235, 94, 94)'} : {}" >
          Interventi
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link title-4 {{isActiveDiagnosi?'active':''}}" id="diagnosi-tab" data-toggle="tab" href="#diagnosi"
          role="tab" aria-controls="diagnosi" aria-selected="false" [ngStyle]=" ( statoAttuale === '4' && listDiagnosiEScale.length > 0 ) ? {'color': 'rgb(235, 94, 94)'} : {}" >
          Diagnosi
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link title-4 {{isActivePresenze?'active':''}}" id="presenze-tab" data-toggle="tab" href="#presenze"
          role="tab" aria-controls="presenze" aria-selected="false" [ngStyle]=" ( statoAttuale === '4' && listPresenze.length > 0 ) ? {'color': 'rgb(235, 94, 94)'} : {}" >
          Presenze
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link title-4 {{isActivePrestazioni?'active':''}}" id="prestazioni-tab" data-toggle="tab" href="#prestazioni"
          role="tab" aria-controls="prestazioni" aria-selected="false"  [ngStyle]=" ( statoAttuale === '4' && listPrestazione.length > 0 ) ? {'color': 'rgb(235, 94, 94)'} : {}"  >
          Prestazioni
        </a>
      </li>
    </ul>


    <div class="card-body">
      <div class="tab-content" id="detailTabContent">
        <!-- ANAGRAFICA -->
        <div class="tab-pane fade active show" id="anagrafica" role="tabpanel"
          aria-labelledby="anagrafica-tab">

          <div class="group-inline pt-4">
            <div class="form-group field-20 has-feedback">
              <label>Cognome</label>
              <input type="text" class="form-control" name="cognome" [value]="dettaglioAnagrafica?.cognome"
                readonly="true">
            </div>
            <div class="form-group field-20 has-feedback">
              <label>Nome</label>
              <input type="text" class="form-control" name="nome" [value]="dettaglioAnagrafica?.nome"
                readonly="true">
            </div>
            <div class="form-group field-16 has-feedback">
              <label>Codice fiscale</label>
              <input type="text" class="form-control" name="codiceFiscale" style="width: 186px!important;"
                [pattern]="'^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$'" [value]="dettaglioAnagrafica?.codiceFiscale"
                 maxlength="16" readonly="true">
            </div>

          </div>

          <div class="group-inline">
            <div class="form-group has-feedback">
              <label>Sesso*</label>
              <div class="form-radio">
                <div class="radio">
                  <input type="radio" name="sesso" id="anagraficaSesso1" value="M"  [checked]="dettaglioAnagrafica?.sesso == 'M'"
                  readonly="true">
                  <label for="anagraficaSesso1">Maschio</label>
                </div>
                <div class="radio">
                  <input type="radio" name="sesso" id="anagraficaSesso2" value="F" [checked]="dettaglioAnagrafica?.sesso == 'F'"
                  readonly="true">
                  <label for="anagraficaSesso2">Femmina</label>
                </div>
                <div class="radio">
                  <input type="radio" name="sesso" id="anagraficaSesso9" value="N" [checked]="dettaglioAnagrafica?.sesso == 'N'"
                  readonly="true">
                  <label for="anagraficaSesso9">Non noto/Non risulta</label>
                </div>

              </div>
            </div>
          </div>

          <hr>

          <!-- Nascita -->
          <div class="title-5">
            Nascita
          </div>
          <!-- datanascita -->
          <div class="group-inline pt-4">
            <div class="form-group field-10 has-feedback">
              <label>Data</label>
              <div class="input-group">
                <!-- <input type="text" class="form-control datepicker" name="anagraficaNsData" readonly="readonly"> -->
                <input class="form-control" placeholder="aaaa-mm-gg" name="dp" id="datepicker" [value]="dettaglioAnagrafica?.dataNascita"
                  ngbDatepicker  #d="ngbDatepicker"
                  readonly="true">
              </div>
            </div>
            <!-- COMUNE nascita TYPEAHEAD -->
            <div class="form-group field-20">
              <label>Comune</label>
              <div>
                <input id="typeahead-focus" type="text" class="form-control" [value]="dettaglioAnagrafica?.descrizioneComuneNascita"
                  readonly="true" />
              </div>
            </div>
          </div>

          <hr>
            <!-- stato civile citadinanza-->
            <div class="group-inline">
              <div class="form-group field-18 has-feedback">
                <label>Stato civile</label>
                <div>
                  <input id="typeahead-focus" type="text" class="form-control" [value]="dettaglioAnagrafica?.statoCivile"
                  readonly="true" />
                </div>
              </div>

              <!-- citadinanza -->
              <div class="form-group field-20 has-feedback">
                <label>Cittadinanza</label>
                <div>
                  <input id="typeahead-focus" type="text" class="form-control" [value]="dettaglioAnagrafica?.cittadinanza"
                  readonly="true" />
                </div>
              </div>
            </div>

            <!-- Residenza -->
            <hr>
            <div class="title-5">
              Residenza
            </div>
            <div class="group-inline pt-4">

              <!-- Regione -->
              <div class="form-group field-24 has-feedback">
                <label>Regione</label>
                <div>

                  <input id="typeahead-focus" type="text" class="form-control" [value]="dettaglioAnagrafica?.regioneResidenza"
                  readonly="true" />
                </div>
              </div>


              <!-- Stato -->
              <div *ngIf="dettaglioAnagrafica?.statoResidenza != null && dettaglioAnagrafica?.statoResidenza != '' && dettaglioAnagrafica?.statoResidenza != undefined " class="form-group field-24 has-feedback" >
                <label>Stato</label>
                <div>
                  <input id="typeahead-focus" type="text" class="form-control" value="yrah" [value]="dettaglioAnagrafica?.statoResidenza"
                  readonly="true" />

                </div>
              </div>

              <!-- Comune residenza -->
              <div class="form-group field-20 has-feedback" >
                <label>Comune</label>
                <div>
                  <input id="typeahead-focus" type="text" class="form-control" [value]="dettaglioAnagrafica?.comuneResidenza"
                  readonly="true" />
                </div>
              </div>

              <!-- Asl -->
              <div *ngIf="dettaglioAnagrafica?.aslResidenza != null && dettaglioAnagrafica?.aslResidenza != '' && dettaglioAnagrafica?.aslResidenza != undefined " class="form-group field-16 has-feedback" >
                <label>ASL</label>
                <div>
                  <input id="typeahead-focus" type="text" class="form-control" [value]="dettaglioAnagrafica?.aslResidenza"
                  readonly="true" />
                </div>
              </div>

              <!-- Distretto -->
              <div *ngIf="dettaglioAnagrafica?.distrettoResidenza != null && dettaglioAnagrafica?.distrettoResidenza != '' && dettaglioAnagrafica?.distrettoResidenza != undefined " class="form-group field-40 has-feedback demon-related" >
                <label>Distretto</label>
                <div>
                  <input id="typeahead-focus" type="text" class="form-control" [value]="dettaglioAnagrafica?.distrettoResidenza"
                  readonly="true" />
                </div>
              </div>

              <!-- Municipio -->
              <div *ngIf="dettaglioAnagrafica?.municipioResidenza != null && dettaglioAnagrafica?.municipioResidenza != '' && dettaglioAnagrafica?.municipioResidenza != undefined " class="form-group field-40 has-feedback demon-related demon-hide" demon-related="residenzaComune"
                demon-value="5775" >
                <label>Municipio</label>
                <div>
                  <input id="typeahead-focus" type="text" class="form-control" [value]="dettaglioAnagrafica?.municipioResidenza"
                  readonly="true" />
                </div>
              </div>
            </div>


            <!-- -->
            <hr>
            <div class="group-inline">

              <div class="form-group field-60 has-feedback">
                <label>Collocazione socio ambientale</label>
                <div>
                  <input id="typeahead-focus" type="text" class="form-control" [value]="dettaglioAnagrafica?.collocazioneSocioAmbientale"
                  readonly="true" />
                </div>
              </div>
              <div class="form-group field-30 has-feedback">
                <label>Titolo di studio</label>
                <div>
                  <input id="typeahead-focus" type="text" class="form-control" [value]="dettaglioAnagrafica?.titoloDiStudio"
                  readonly="true" />
                </div>
              </div>
              <div class="form-group field-36 has-feedback">
                <label>Attività</label>
                <div>
                  <input id="typeahead-focus" type="text" class="form-control" [value]="dettaglioAnagrafica?.attivita"
                  readonly="true" />
                </div>
              </div>

            </div>

            <!-- -->
            <hr>
            <div class="group-inline">

              <div class="form-group has-feedback">
                <label>E' familiare di un paziente in trattamento</label>
                <div class="form-radio">
                  <div class="radio">
                    <input type="radio" name="familiarePaziente" id="anagraficaFamiliare1" value="1" [checked]="dettaglioAnagrafica?.familiarePaziente == '1'"
                      [attr.disabled]="true">
                    <label for="anagraficaFamiliare1">Sì</label>
                  </div>
                  <div class="radio">
                    <input type="radio" name="familiarePaziente" id="anagraficaFamiliare2" value="2"  [checked]="dettaglioAnagrafica?.familiarePaziente == '2'"
                      [attr.disabled]="true">
                    <label for="anagraficaFamiliare2">No</label>
                  </div>
                  <div class="radio">
                    <input type="radio" name="familiarePaziente" id="anagraficaFamiliare9" value="3"  [checked]="dettaglioAnagrafica?.familiarePaziente == '3'"
                      [attr.disabled]="true">
                    <label for="anagraficaFamiliare9">Non noto/Non risulta</label>
                  </div>
                </div>

              </div>


              <div class="form-group field-60 has-feedback">
                <label>Invalidità</label>
                <div>
                  <input id="typeahead-focus" type="text" class="form-control" [value]="dettaglioAnagrafica?.invalidita"
                  readonly="true" />
                </div>
              </div>



              <div class="form-group field-24 has-feedback">
                <label>Tutela giuridica</label>
                <div>
                  <input id="typeahead-focus" type="text" class="form-control" [value]="dettaglioAnagrafica?.tutelaGiuridica"
                  readonly="true" />
                </div>

              </div>

              <div class="form-group field-50 has-feedback">
                <label>Attualmente sottoposto a provvedimenti giudiziari o a una misura di sicurezza</label>
                <div>
                  <input id="typeahead-focus" type="text" class="form-control" [value]="dettaglioAnagrafica?.attualmenteSottoposto"
                  readonly="true" />
                </div>
              </div>

              <div class="form-group field-50 has-feedback">
                <label>Precedente internamento o detenzione in ambito Psichiatrico Penitenziario</label>
                <div class="form-radio">
                  <div class="radio">
                    <input type="radio" name="precedentementeInternato" id="anagraficaPenitenziaria1" value="1"  [checked]="dettaglioAnagrafica?.precedentementeInternato == '1'"
                      [attr.disabled]="true">
                    <label for="anagraficaPenitenziaria1">Sì</label>
                  </div>
                  <div class="radio">
                    <input type="radio" name="precedentementeInternato" id="anagraficaPenitenziaria2" value="2" [checked]="dettaglioAnagrafica?.precedentementeInternato == '2'"
                      [attr.disabled]="true">
                    <label for="anagraficaPenitenziaria2">No</label>
                  </div>
                  <div class="radio">
                    <input type="radio" name="precedentementeInternato" id="anagraficaPenitenziaria9" value="3" [checked]="dettaglioAnagrafica?.precedentementeInternato == '3'"
                      [attr.disabled]="true">
                    <label for="anagraficaPenitenziaria9">Non noto/Non risulta</label>
                  </div>
                </div>

              </div>
            </div>

        </div>




        <!-- CONTATTO -->




        <div class="tab-pane fade {{isActiveContatti?'active show':''}}"  id="contatto" role="tabpanel"
          aria-labelledby="contatto-tab">
          <div class="tab-pane fade active show"  role="tabpanel" *ngIf="listContatti.length == 0"
               aria-labelledby="contatto-tab">
               NON CI SONO CONTATTI DA VISUALIZZARE
          </div>
          <div class="tab-pane fade active show"  role="tabpanel" *ngFor="let contatto of listContatti"
               aria-labelledby="contatto-tab">

               <div *ngIf="contatto.statoAttuale.id == 2 && statoAttuale === '2' " class="bgevidence">
                <hr>
                <div class="group-inline pt-4">
                  <div class="form-group field-10 has-feedback">
                    <input type="checkbox" name="operation" [id]="'valida_'+contatto.id "  class="valida"  [value]="contatto.id"
                      (click)="onChangeValida($event, 'contatti')"
                      [checked]="isSelectedValidati(contatto.id, 'contatti')">
                      <label>Valida     </label>
                  </div>
                  <br>
                  <br>
                  <div class="form-group field-10 has-feedback">
                    <input type="checkbox" name="operation" [id]="'scarta_'+contatto.id " class="scarta" [value]="contatto.id"
                      (click)="onChangeScarta($event, 'contatti')"
                      [checked]="isSelectedScartati(contatto.id, 'contatti')">
                      <label>Scarta    </label>
                  </div>
                </div>
              </div>
            <div *ngIf="contatto.statoAttuale.id == 3" style="text-align: end">
              <button class="btn btn-outline-secondary" (click)="riabilitaModifica(contatto.id, 'contatto')">
                Riabilita modifica alla struttura
              </button>
            </div>
              <div class="group-inline pt-4" *ngIf=" contatto.statoAttuale.id == 2 || contatto.statoAttuale.id == 4" class="bgevidence">
                <div  class="form-group has-feedback">
                  <label [hidden]="!isSelectedScartati(contatto.id, 'contatti') && contatto.statoAttuale.id != 4">Motivazione dello scarto{{ contatto.statoAttuale.id == 2 ? '*':''}}</label>
                  <textarea class="form-control" name="motivazioniScarti"
                  [hidden]="!isSelectedScartati(contatto.id, 'contatti') && contatto.statoAttuale.id != 4"
                  [readOnly]="contatto.statoAttuale.id == 4"
                  (change)="changeMotivazioniScarto(contatto.id, 'contatti', $event)">{{contatto.motivazioneScarto}}</textarea>
                </div>
              </div>
              <div class=" pt-4" *ngIf=" contatto.statoAttuale.id == 2 || contatto.statoAttuale.id == 4">
                <hr>
              </div>

            <div class="group-inline pt-3">
              <div class="form-group field-10 has-feedback">
                <label>Data richiesta</label>
                <div class="input-group">
                  <input type="text" name="dataRichiesta" class="form-control" placeholder="aaaa-mm-gg" name="dp"
                         ngbDatepicker #richiestaDato="ngbDatepicker"  [readonly]="true" [value]="contatto.dataRichiesta">
                </div>
              </div>
              <div class="groupo-inline">
              </div>
            </div>

            <div class="form-group field-30" *ngIf="contatto.tipoPrestazione == 'Territoriale' ">
              <label>Richiesta prevalente</label>
              <div>
                <input id="typeahead-focus" type="text" class="form-control" [value]="contatto.richiestaPrev"
                  readonly="true" />
              </div>

            </div>

            <div class="form-group" *ngIf="contatto.tipoPrestazione == 'Territoriale'">
              <label>Apertura Contatto</label>
              <div class="form-radio">
                <div class="radio">
                  <input type="radio" name="interventoRiabilitativi" id="interventoRiabilitativiSi" value="true" [checked]="contatto.aperturaFlag == '1'"
                        [attr.disabled]="true">
                  <label for="interventoRiabilitativiSi">Sì</label>
                </div>
                <div class="radio">
                  <input type="radio" name="interventoRiabilitativi" id="interventoRiabilitativiNo" value="false" [checked]="contatto.aperturaFlag == '2'  && contatto.aperturaFlag!= null"
                        [attr.disabled]="true">
                  <label for="interventoRiabilitativiNo">No</label>
                </div>
              </div>
            </div>
            <hr>
            <div *ngIf="contatto.aperturaFlag != '2' ">
              <div class="title-5">
                Apertura
              </div>

              <div class="group-inline pt-4" >
                <div class="form-group field-10 has-feedback">
                  <label>Data</label>
                  <div class="input-group">
                    <input type="text" class="form-control" placeholder="aaaa-mm-gg" name="dp"
                          ngbDatepicker #aperturaDato="ngbDatepicker" name="dataApertura"
                          [value]="contatto.dataApertura"
                          [readonly]="true">
                  </div>

                </div>
                <div class="form-group field-80 has-feedback">
                  <label>Diagnosi</label>
                  <input id="typeahead-focus" type="text" class="form-control"
                        [value]="contatto.descrizioneDiagnosiApertura" [attr.disabled]="true" />
                </div>

                <div class="form-group field-40">
                  <label>Precedenti contatti</label>
                  <div>
                    <input id="typeahead-focus" type="text" class="form-control"
                        [value]="contatto.precedentiContatti" [attr.disabled]="true" />
                  </div>

                </div>

                <div class="form-group field-40">
                  <label>Inviante</label>
                  <div>
                    <input id="typeahead-focus" type="text" class="form-control"
                        [value]="contatto.inviante" [attr.disabled]="true" />

                  </div>
                </div>


              </div>


              <div *ngIf="contatto.tipoPrestazione == 'Semiresidenziale'">
                <hr>
                <div class="title-5">
                  Progetto di intervento
                </div>
                <div class="group-inline pt-4">

                  <div class="form-group" *ngIf="contatto.interventoRiabilitativi != null">
                    <label>Riabilitativi</label>
                    <div class="form-radio">
                      <div class="radio">
                        <input type="radio" name="interventoRiabilitativi" id="interventoRiabilitativiSi" value="true" [checked]="contatto.interventoRiabilitativi"
                              [attr.disabled]="true">
                        <label for="interventoRiabilitativiSi">Sì</label>
                      </div>
                      <div class="radio">
                        <input type="radio" name="interventoRiabilitativi" id="interventoRiabilitativiNo" value="false" [checked]="!contatto.interventoRiabilitativi && contatto.interventoRiabilitativi!= null"
                              [attr.disabled]="true">
                        <label for="interventoRiabilitativiNo">No</label>
                      </div>
                    </div>
                  </div>
                  <div class="form-group" *ngIf="contatto.interventoInserimentiLavorativi != null">
                    <label>Inserimenti lavorativi</label>
                    <div class="form-radio">
                      <div class="radio">
                        <input type="radio" name="interventoInserimentiLavorativi" id="interventoInserimentiLavorativiSi" [checked]="contatto.interventoInserimentiLavorativi"
                              value="true"
                              [attr.disabled]="true">
                        <label for="interventoInserimentiLavorativiSi">Sì</label>
                      </div>
                      <div class="radio">
                        <input type="radio" name="interventoInserimentiLavorativi" id="interventoInserimentiLavorativiNo" [checked]="!contatto.interventoInserimentiLavorativi && contatto.interventoInserimentiLavorativi!= null"
                              value="false"
                              [attr.disabled]="true">
                        <label for="interventoInserimentiLavorativiNo">No</label>
                      </div>
                    </div>



                  </div>
                  <div class="form-group" *ngIf="contatto.interventoInterveniSociali != null">
                    <label>Interv. sociale e/o di rete</label>
                    <div class="form-radio">
                      <div class="radio">
                        <input type="radio" name="interventoInterveniSociali" id="interventoInterveniSocialiSi" [checked]="contatto.interventoInterveniSociali"
                              value="true"
                              [attr.disabled]="true">
                        <label for="interventoInterveniSocialiSi">Sì</label>
                      </div>
                      <div class="radio">
                        <input type="radio" name="interventoInterveniSociali" id="interventoInterveniSocialiNo" [checked]="!contatto.interventoInterveniSociali && contatto.interventoInterveniSociali!= null"
                              value="false"
                              [attr.disabled]="true">
                        <label for="interventoInterveniSocialiNo">No</label>
                      </div>
                    </div>
                  </div>


                  <div class="form-group" *ngIf="contatto.interventoColloquiPsichiatrici != null">
                    <label>Colloqui psichiatrici e psicologici di sostegno</label>
                    <div class="form-radio">
                      <div class="radio">
                        <input type="radio" name="interventoColloquiPsichiatrici" id="interventoColloquiPsichiatriciSi" [checked]="contatto.interventoColloquiPsichiatrici"
                              value="true"
                              [attr.disabled]="true">
                        <label for="interventoColloquiPsichiatriciSi">Sì</label>
                      </div>
                      <div class="radio">
                        <input type="radio" name="interventoColloquiPsichiatrici" id="interventoColloquiPsichiatriciNo" [checked]="!contatto.interventoColloquiPsichiatrici && contatto.interventoColloquiPsichiatrici!= null"
                              value="false"
                              [attr.disabled]="true">
                        <label for="interventoColloquiPsichiatriciNo">No</label>
                      </div>
                    </div>
                  </div>
                  <div class="form-group" *ngIf="contatto.interventoCoinvolgimento != null">
                    <label>Coinvolgimento/sostegno ai familiari</label>
                    <div class="form-radio">
                      <div class="radio">
                        <input type="radio" name="interventoCoinvolgimento" id="interventoCoinvolgimentoSi" value="true" [checked]="contatto.interventoCoinvolgimento"
                              [attr.disabled]="true">
                        <label for="interventoCoinvolgimentoSi">Sì</label>
                      </div>
                      <div class="radio">
                        <input type="radio" name="interventoCoinvolgimento" id="interventoCoinvolgimentoNo" value="false" [checked]="!contatto.interventoCoinvolgimento && contatto.interventoCoinvolgimento!= null"
                              [attr.disabled]="true">
                        <label for="interventoCoinvolgimentoNo">No</label>
                      </div>
                    </div>
                  </div>
                  <div  *ngIf="dettaglioAnagrafica?.aslResidenza != null " class="form-group field-wide">
                    <label>Altro</label>
                    <input type="text" class="form-control" name="altro" [value]="contatto.interventoEffettuatoAltro"
                          [attr.disabled]="true">
                  </div>

                </div>
              </div>







              <div>
                <hr>
                <div *ngIf="contatto.dataChiusura != null">
                  <div class="title-5" >
                    Chiusura
                  </div>
                  <div class="group-inline pt-4" >

                    <div class="form-group field-10 has-feedback">
                      <label>Data</label>
                      <div class="input-group">
                        <input type="text" name="dataChiusura" class="form-control" placeholder="aaaa-mm-gg" name="dp"
                              ngbDatepicker #chiusuraDato="ngbDatepicker" [value]="contatto.dataChiusura"
                              [readonly]="true">
                      </div>

                    </div>
                    <div class="form-group field-80 has-feedback">
                      <label>Diagnosi</label>

                      <input type="text" class="form-control" name="altro" [value]="contatto.descrizioneDiagnosiChiusura"
                            [attr.disabled]="true">
                    </div>
                    <div class="form-group field-40">
                      <label>Modalità conclusione</label>
                      <div>
                        <input type="text" class="form-control" name="altro" [value]="contatto.modalitaConclusione"
                        [attr.disabled]="true">
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="contatto.tipoPrestazione == 'Semiresidenziale' && contatto.dataChiusura != null">
                  <hr>
                  <div class="title-5">
                    Intervento effettuato
                  </div>
                  <div class="group-inline pt-4">
                    <div class="form-group" *ngIf="contatto.interventoEffettuatoRiabilitativi != null">
                      <label>Riabilitativi</label>
                      <div class="form-radio">
                        <div class="radio">
                          <input type="radio" name="interventoEffettuatoRiabilitativi"
                                id="interventoEffettuatoRiabilitativiSi" value="true" [checked]="contatto.interventoEffettuatoRiabilitativi"
                                [attr.disabled]="true">
                          <label for="interventoEffettuatoRiabilitativiSi">Sì</label>
                        </div>
                        <div class="radio">
                          <input type="radio" name="interventoEffettuatoRiabilitativi"
                                id="interventoEffettuatoRiabilitativiNo" value="false" [checked]="!contatto.interventoEffettuatoRiabilitativi && contatto.interventoEffettuatoRiabilitativi!= null"
                                [attr.disabled]="true">
                          <label for="interventoEffettuatoRiabilitativiNo">No</label>
                        </div>
                      </div>
                    </div>
                    <div class="form-group" *ngIf="contatto.interventoEffettuatoLavorativi != null">
                      <label>Inserimenti lavorativi</label>
                      <div class="form-radio">
                        <div class="radio">
                          <input type="radio" name="interventoEffettuatoLavorativi" id="interventoEffettuatoLavorativiSi"
                                value="true"  [checked]="contatto.interventoEffettuatoLavorativi"
                                [attr.disabled]="true">
                          <label for="interventoEffettuatoLavorativiSi">Sì</label>
                        </div>
                        <div class="radio">
                          <input type="radio" name="interventoEffettuatoLavorativi" id="interventoEffettuatoLavorativiNO"
                                value="false"  [checked]="!contatto.interventoEffettuatoLavorativi"
                                [attr.disabled]="true">
                          <label for="interventoEffettuatoLavorativiNO">No</label>
                        </div>
                      </div>
                    </div>
                    <div class="form-group" *ngIf="contatto.interventoEffettuatoInterveniSociali != null">
                      <label>Interv. sociale e/o di rete</label>
                      <div class="form-radio">
                        <div class="radio">
                          <input type="radio" name="interventoEffettuatoInterveniSociali"
                                id="interventoEffettuatoInterveniSocialiSi" value="true"  [checked]="contatto.interventoEffettuatoInterveniSociali"
                                [attr.disabled]="true">
                          <label for="interventoEffettuatoInterveniSocialiSi">Sì</label>
                        </div>
                        <div class="radio">
                          <input type="radio" name="interventoEffettuatoInterveniSociali"
                                id="interventoEffettuatoInterveniSocialiNO" value="false" [checked]="!contatto.interventoEffettuatoInterveniSociali && contatto.interventoEffettuatoInterveniSociali!= null"
                                [attr.disabled]="true">
                          <label for="interventoEffettuatoInterveniSocialiNO">No</label>
                        </div>
                      </div>
                    </div>
                    <div class="form-group" *ngIf="contatto.interventoEffettuatoColloquiPsichiatrici != null">
                      <label>Colloqui psichiatrici e psicologici di sostegno</label>
                      <div class="form-radio">
                        <div class="radio">
                          <input type="radio" name="interventoEffettuatoColloquiPsichiatrici"
                                id="interventoEffettuatoColloquiPsichiatriciSi" value="true" [checked]="contatto.interventoEffettuatoColloquiPsichiatrici"
                                [attr.disabled]="true">
                          <label for="interventoEffettuatoColloquiPsichiatriciSi">Sì</label>
                        </div>
                        <div class="radio">
                          <input type="radio" name="interventoEffettuatoColloquiPsichiatrici"
                                id="interventoEffettuatoColloquiPsichiatriciNO" value="false" [checked]="!contatto.interventoEffettuatoColloquiPsichiatrici && contatto.interventoEffettuatoColloquiPsichiatrici!= null"
                                [attr.disabled]="true">
                          <label for="interventoEffettuatoColloquiPsichiatriciNO">No</label>
                        </div>
                      </div>
                    </div>
                    <div class="form-group" *ngIf="contatto.interventoEffettuatoCoinvolgimento != null">
                      <label>Coinvolgimento/sostegno ai familiari</label>
                      <div class="form-radio">
                        <div class="radio">
                          <input type="radio" name="interventoEffettuatoCoinvolgimento"
                                id="interventoEffettuatoCoinvolgimentoSi" value="true" [checked]="contatto.interventoEffettuatoCoinvolgimento"
                                [attr.disabled]="true">
                          <label for="interventoEffettuatoCoinvolgimentoSi">Sì</label>
                        </div>
                        <div class="radio">
                          <input type="radio" name="interventoEffettuatoCoinvolgimento"
                                id="interventoEffettuatoCoinvolgimentoNo" value="false" [checked]="!contatto.interventoEffettuatoCoinvolgimento && contatto.interventoEffettuatoCoinvolgimento!= null"
                                [attr.disabled]="true">
                          <label for="interventoEffettuatoCoinvolgimentoNo">No</label>
                        </div>
                      </div>
                    </div>
                    <div  class="form-group form-group-altro">
                        <label>Altro</label>
                        <input type="text" class="form-control" id="interventoEffettuatoAltro" name="interventoEffettuatoAltro"
                            [value]="contatto.interventoEffettuatoAltro"
                            [attr.disabled]="true">
                    </div>
                  </div>

                </div>

              </div>
            </div>
            <hr class="separator">
          </div>
        </div>



        <!-- INTERVETNI -->


        <div class="tab-pane fade {{isActiveInterventi?'active show':''}}" id="intervento" role="tabpanel"
          aria-labelledby="intervento-tab">
          <div class="tab-pane fade active show"  role="tabpanel" *ngIf="listInterventi.length == 0"
              aria-labelledby="contatto-tab">
              NON CI SONO INTERVENTI DA VISUALIZZARE
          </div>
          <div class="pt-5" *ngFor="let intervento of listInterventi">
            <div *ngIf="intervento.statoAttuale.id == 3" style="text-align: end">
              <button class="btn btn-outline-secondary" (click)="riabilitaModifica(intervento.id, 'intervento')">
                Riabilita modifica alla struttura
              </button>
            </div>
            <div *ngIf="intervento.statoAttuale.id == 2" class="bgevidence">
              <hr>
              <div class="group-inline pt-4">
                <div class="form-group field-10 has-feedback">
                  <input type="checkbox" name="operation" [id]="'valida_'+intervento.id "  class="valida"  [value]="intervento.id"
                    (click)="onChangeValida($event, 'interventi')"
                    [checked]="isSelectedValidati(intervento.id, 'interventi')">
                    <label>Valida     </label>
                </div>
                <br>
                <br>
                <div class="form-group field-10 has-feedback">
                  <input type="checkbox" name="operation" [id]="'scarta_'+intervento.id " class="scarta" [value]="intervento.id"
                    (click)="onChangeScarta($event, 'interventi')"
                    [checked]="isSelectedScartati(intervento.id, 'interventi')">
                    <label>Scarta    </label>
                </div>
              </div>
            </div>
            <div class="group-inline pt-4" *ngIf=" intervento.statoAttuale.id == 2 || intervento.statoAttuale.id == 4" class="bgevidence">
              <div  class="form-group has-feedback">
                <label [hidden]="!isSelectedScartati(intervento.id, 'interventi') && intervento.statoAttuale.id != 4">Motivazione dello scarto{{ intervento.statoAttuale.id == 2 ? '*':''}}</label>
                <textarea class="form-control" name="motivazioniScarti"
                [hidden]="!isSelectedScartati(intervento.id, 'interventi') && intervento.statoAttuale.id != 4"
                [readOnly]="intervento.statoAttuale.id == 4"
                (change)="changeMotivazioniScarto(intervento.id, 'interventi', $event)">{{intervento.motivazioneScarto}}</textarea>
              </div>
            </div>
            <div class=" pt-4" *ngIf=" intervento.statoAttuale.id == 2 || intervento.statoAttuale.id == 4">
              <hr>
            </div>

            <div class="group-inline">

                <!-- Data -->
                <div class="group-inline">
                    <div class="form-group field-10 has-feedback">
                        <label>Data*</label>
                        <div class="input-group">
                            <input class="form-control" placeholder="aaaa-mm-gg" name="date"
                                ngbDatepicker #d="ngbDatepicker" [value]="intervento.data"
                                [readonly]="true">
                        </div>
                    </div>
                    <div class="form-group field-60">
                    </div>
                </div>
                <!-- RispostaPrevalente -->
                <div class="form-group field-50 has-feedback">
                    <div class="form-group field-30">
                        <label>Risposta prevalente</label>
                        <div>
                          <input type="text" class="form-control" name="altro" [value]="intervento.rispostaPrevalente"
                            [attr.disabled]="true">
                        </div>
                    </div>
                </div>

                <!-- PI GROUP -->
                <div class="row" *ngIf="!(intervento.piTrattamentoPsicoterapeutico == null
                && intervento.piTrattamentoFarmacologico == null
                && intervento.piInterventiRiabilitativi == null
                && intervento.piInserimentiLavorativi == null
                && intervento.piInterventoSociale == null
                && intervento.piColloquiSostegno == null
                && intervento.piCoinvolgimentoFamiliari == null
                && intervento.piAccertamentiMedicoLegali == null
                && intervento.piInvioInStruttura == null
                && intervento.piInvioInOspedale == null
                && intervento.piConsultazioneValutazione == null)
                ">

                    <!-- PI-GROUP -->
                    <div>
                        <hr>
                        <div class="title-5">
                            Progetto di intervento
                        </div>

                        <div class="group-inline pt-4">

                            <div class="form-group form-group-radio" *ngIf="intervento.piTrattamentoPsicoterapeutico != null">
                                <label>Trattamento psicoterapeutico</label>

                                <div class="form-radio">
                                    <div class="radio">
                                        <input type="radio" name="piTrattamentoPsicoterapeutico{{intervento.id}}"
                                            id="piTrattamentoPsicoterapeuticoSi" value="true" [checked]="intervento.piTrattamentoPsicoterapeutico"
                                            [attr.disabled]="true">
                                        <label for="piTrattamentoPsicoterapeuticoSi">Sì</label>
                                    </div>
                                    <div class="radio">
                                        <input type="radio" name="piTrattamentoPsicoterapeutico{{intervento.id}}"
                                            id="piTrattamentoPsicoterapeuticoNo" value="false" [checked]="!intervento.piTrattamentoPsicoterapeutico && intervento.piTrattamentoPsicoterapeutico!= null"
                                            [attr.disabled]="true">
                                        <label for="piTrattamentoPsicoterapeuticoNo">No</label>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group form-group-radio" *ngIf="intervento.piTrattamentoFarmacologico != null">
                                <label>Trattamento farmacologico</label>
                                <div class="form-radio">
                                    <div class="radio">
                                        <input type="radio" name="piTrattamentoFarmacologico{{intervento.id}}"
                                            id="piTrattamentoFarmacologicoSi" value="true" [checked]="intervento.piTrattamentoFarmacologico"
                                            [attr.disabled]="true">
                                        <label for="piTrattamentoFarmacologicoSi">Sì</label>
                                    </div>
                                    <div class="radio">
                                        <input type="radio" name="piTrattamentoFarmacologico{{intervento.id}}"
                                            id="piTrattamentoFarmacologicoNo" value="false" [checked]="!intervento.piTrattamentoFarmacologico && intervento.piTrattamentoFarmacologico!= null"
                                            [attr.disabled]="true">
                                        <label for="piTrattamentoFarmacologicoNo">No</label>
                                    </div>
                                </div>

                            </div>



                            <div class="form-group form-group-radio" *ngIf="intervento.piInterventiRiabilitativi != null">
                                <label>Interventi riabilitativi</label>
                                <div class="form-radio">
                                    <div class="radio">
                                        <input type="radio" name="piInterventiRiabilitativi{{intervento.id}}"
                                            id="piInterventiRiabilitativiSi" value="true" [checked]="intervento.piInterventiRiabilitativi"
                                            [attr.disabled]="true">
                                        <label for="piInterventiRiabilitativiSi">Sì</label>
                                    </div>
                                    <div class="radio">
                                        <input type="radio" name="piInterventiRiabilitativi{{intervento.id}}"
                                            id="piInterventiRiabilitativiNo" value="false" [checked]="!intervento.piInterventiRiabilitativi && intervento.piInterventiRiabilitativi!= null"
                                            [attr.disabled]="true">
                                        <label for="piInterventiRiabilitativiNo">No</label>
                                    </div>
                                </div>
                            </div>


                            <div class="form-group form-group-radio" *ngIf="intervento.piInserimentiLavorativi != null">
                                <label>Inserimenti lavorativi</label>
                                <div class="form-radio">
                                    <div class="radio">
                                        <input type="radio" name="piInserimentiLavorativi{{intervento.id}}"
                                            id="piInserimentiLavorativiSi" value="true" [checked]="intervento.piInserimentiLavorativi"
                                            [attr.disabled]="true">
                                        <label for="piInserimentiLavorativiSi">Sì</label>
                                    </div>
                                    <div class="radio">
                                        <input type="radio" name="piInserimentiLavorativi{{intervento.id}}"
                                            id="piInserimentiLavorativiNo" value="false" [checked]="!intervento.piInserimentiLavorativi && intervento.piInserimentiLavorativi!= null"
                                            [attr.disabled]="true">
                                        <label for="piInserimentiLavorativiNo">No</label>
                                    </div>
                                </div>
                            </div>



                            <div class="form-group form-group-radio" *ngIf="intervento.piInterventoSociale != null">
                                <label>Interv. sociale e/o di rete</label>
                                <div class="form-radio">
                                    <div class="radio">
                                        <input type="radio" name="piInterventoSociale{{intervento.id}}"
                                            id="piInterventoSocialeSi" value="true" [checked]="intervento.piInterventoSociale "
                                            [attr.disabled]="true">
                                        <label for="piInterventoSocialeSi">Sì</label>
                                    </div>
                                    <div class="radio">
                                        <input type="radio" name="piInterventoSociale{{intervento.id}}"
                                            id="piInterventoSocialeNo" value="false" [checked]="!intervento.piInterventoSociale && intervento.piInterventoSociale!= null"
                                            [attr.disabled]="true">
                                        <label for="piInterventoSocialeNo">No</label>
                                    </div>
                                </div>
                            </div>


                            <div class="form-group form-group-radio" *ngIf="intervento.piColloquiSostegno != null">
                                <label>Colloqui psichiatrici e psicologici di sostegno</label>
                                <div class="form-radio">
                                    <div class="radio">
                                        <input type="radio" name="piColloquiSostegno{{intervento.id}}" id="piColloquiSostegnoSi"
                                            value="true" [checked]="intervento.piColloquiSostegno"
                                            [attr.disabled]="true">
                                        <label for="piColloquiSostegnoSi">Sì</label>
                                    </div>
                                    <div class="radio">
                                        <input type="radio" name="piColloquiSostegno{{intervento.id}}" id="piColloquiSostegnoNo"
                                            value="false" [checked]="!intervento.piColloquiSostegno && intervento.piColloquiSostegno!= null"
                                            [attr.disabled]="true">
                                        <label for="piColloquiSostegnoNo">No</label>
                                    </div>
                                </div>
                            </div>


                            <div class="form-group form-group-radio" *ngIf="intervento.piCoinvolgimentoFamiliari != null">
                                <label>Coinvolgimento/sostegno ai familiari</label>
                                <div class="form-radio">
                                    <div class="radio">
                                        <input type="radio" name="piCoinvolgimentoFamiliari{{intervento.id}}"
                                            id="piCoinvolgimentoFamiliariSi" value="true" [checked]="intervento.piCoinvolgimentoFamiliari"
                                            [attr.disabled]="true">
                                        <label for="piCoinvolgimentoFamiliariSi">Sì</label>
                                    </div>
                                    <div class="radio">
                                        <input type="radio" name="piCoinvolgimentoFamiliari{{intervento.id}}"
                                            id="piCoinvolgimentoFamiliariNo" value="false" [checked]="!intervento.piCoinvolgimentoFamiliari && intervento.piCoinvolgimentoFamiliari!= null"
                                            [attr.disabled]="true">
                                        <label for="piCoinvolgimentoFamiliariNo">No</label>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group form-group-radio" *ngIf="intervento.piAccertamentiMedicoLegali != null">
                                <label>Accertamenti medico legali</label>
                                <div class="form-radio">
                                    <div class="radio">
                                        <input type="radio" name="piAccertamentiMedicoLegali{{intervento.id}}"
                                            id="piAccertamentiMedicoLegaliSi" value="true" [checked]="intervento.piAccertamentiMedicoLegali"
                                            [attr.disabled]="true">
                                        <label for="piAccertamentiMedicoLegaliSi">Sì</label>
                                    </div>
                                    <div class="radio">
                                        <input type="radio" name="piAccertamentiMedicoLegali{{intervento.id}}"
                                            id="piAccertamentiMedicoLegaliNo" value="false" [checked]="!intervento.piAccertamentiMedicoLegali && intervento.piAccertamentiMedicoLegali!= null"
                                            [attr.disabled]="true">
                                        <label for="piAccertamentiMedicoLegaliNo">No</label>
                                    </div>
                                </div>
                            </div>


                            <div class="form-group form-group-radio" *ngIf="intervento.piInvioInStruttura != null">
                                <label>Invio in struttura residenziale/semires.</label>
                                <div class="form-radio">
                                    <div class="radio">
                                        <input type="radio" name="piInvioInStruttura{{intervento.id}}" id="piInvioInStrutturaSi"
                                            value="true" [checked]="intervento.piInvioInStruttura"
                                            [attr.disabled]="true">
                                        <label for="piInvioInStrutturaSi">Sì</label>
                                    </div>
                                    <div class="radio">
                                        <input type="radio" name="piInvioInStruttura{{intervento.id}}" id="piInvioInStrutturaNo"
                                            value="false" [checked]="!intervento.piInvioInStruttura && intervento.piInvioInStruttura!= null"
                                            [attr.disabled]="true">
                                        <label for="piInvioInStrutturaNo">No</label>
                                    </div>
                                </div>
                            </div>


                            <div class="form-group form-group-radio" *ngIf="intervento.piInvioInOspedale != null">
                                <label>Invio in ospedale</label>
                                <div class="form-radio">
                                    <div class="radio">
                                        <input type="radio" name="piInvioInOspedale{{intervento.id}}" id="piInvioInOspedaleSi"
                                            value="true" [checked]="intervento.piInvioInOspedale"
                                            [attr.disabled]="true">
                                        <label for="piInvioInOspedaleSi">Sì</label>
                                    </div>
                                    <div class="radio">
                                        <input type="radio" name="piInvioInOspedale{{intervento.id}}" id="piInvioInOspedaleNo"
                                            value="false" [checked]="!intervento.piInvioInOspedale && intervento.piInvioInOspedale!= null"
                                            [attr.disabled]="true">
                                        <label for="piInvioInOspedaleNo">No</label>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group form-group-radio" *ngIf="intervento.piConsultazioneValutazione != null">
                                <label>Consultazione valutazione</label>
                                <div class="form-radio">
                                    <div class="radio">
                                        <input type="radio" name="piConsultazioneValutazione{{intervento.id}}"
                                            id="piConsultazioneValutazioneSi" value="true" [checked]="intervento.piConsultazioneValutazione"
                                            [attr.disabled]="true">
                                        <label for="piConsultazioneValutazioneSi">Sì</label>
                                    </div>
                                    <div class="radio">
                                        <input type="radio" name="piConsultazioneValutazione{{intervento.id}}"
                                            id="piConsultazioneValutazioneNo" value="false" [checked]="!intervento.piConsultazioneValutazione && intervento.piConsultazioneValutazione!= null"
                                            [attr.disabled]="true">
                                        <label for="piConsultazioneValutazioneNo">No</label>
                                    </div>
                                </div>
                            </div>


                            <div *ngIf="intervento.piAltro != null && intervento.piAltro != '' " class="form-group form-group-altro">
                                <label>Altro</label>
                                <input type="text" class="form-control" id="piAltro" name="piAltro"
                                    [value]="intervento.piAltro"
                                    [attr.disabled]="true">
                            </div>

                        </div>
                    </div>

                </div>

                <div class="row">
                </div>


                <!-- IE GROUP -->
                <div class="row" *ngIf="!(intervento.ieTrattamentoPsicoterapeutico == null
                  && intervento.ieTrattamentoFarmacologico == null
                  && intervento.ieInterventiRiabilitativi == null
                  && intervento.ieInserimentiLavorativi == null
                  && intervento.ieInterventoSociale == null
                  && intervento.ieColloquiSostegno == null
                  && intervento.ieCoinvolgimentoFamiliari == null
                  && intervento.ieAccertamentiMedicoLegali == null
                  && intervento.ieInvioInStruttura == null
                  && intervento.ieInvioStpit == null
                  && intervento.ieLuogoStpit == null
                  && intervento.ieInvioSrtri == null
                  && intervento.ieLuogoSrtri == null
                  && intervento.ieInvioSrtre == null
                  && intervento.ieLuogoSrtre == null
                  && intervento.ieInvioSrsr24 == null
                  && intervento.ieLuogoSrsr24 == null
                  && intervento.ieInvioSrsr12 == null
                  && intervento.ieLuogoSrsr12 == null
                  && intervento.ieInvioGa == null
                  && intervento.ieLuogoGa == null
                  && intervento.ieInvioCd == null
                  && intervento.ieLuogoCd == null
                  && intervento.ieInvioOspedale == null
                  && intervento.ieInvioOspedaleRep40 == null
                  && intervento.ieLuogoOspedale == null
                  && intervento.ieConsultazioneValutazione == null
                  )
                ">
                    <!-- IE-GROUP -->
                    <div>
                        <hr>
                        <div class="title-5">
                            Intervento effettuato durante il contatto
                        </div>

                        <!-- PART1 -->
                        <div class="row">


                            <div class="group-inline pt-4">


                                <div class="form-group form-group-radio" *ngIf="intervento.ieTrattamentoPsicoterapeutico != null">
                                    <label>Trattamento psicoterapeutico</label>

                                    <div class="form-radio">
                                        <div class="radio">
                                            <input type="radio" name="ieTrattamentoPsicoterapeutico{{intervento.id}}"
                                                id="ieTrattamentoPsicoterapeuticoSi" value="true" [checked]="intervento.ieTrattamentoPsicoterapeutico"
                                                [attr.disabled]="true">
                                            <label for="ieTrattamentoPsicoterapeuticoSi">Sì</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" name="ieTrattamentoPsicoterapeutico{{intervento.id}}"
                                                id="ieTrattamentoPsicoterapeuticoNo" value="false" [checked]="!intervento.ieTrattamentoPsicoterapeutico && intervento.ieTrattamentoPsicoterapeutico!= null"
                                                [attr.disabled]="true">
                                            <label for="ieTrattamentoPsicoterapeuticoNo">No</label>
                                        </div>
                                    </div>
                                </div>


                                <div class="form-group form-group-radio" *ngIf="intervento.ieTrattamentoFarmacologico != null">
                                    <label>Trattamento farmacologico</label>
                                    <div class="form-radio">
                                        <div class="radio">
                                            <input type="radio" name="ieTrattamentoFarmacologico{{intervento.id}}"
                                                id="ieTrattamentoFarmacologicoSi" value="true" [checked]="intervento.ieTrattamentoFarmacologico"
                                                [attr.disabled]="true">
                                            <label for="ieTrattamentoFarmacologicoSi">Sì</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" name="ieTrattamentoFarmacologico{{intervento.id}}"
                                                id="ieTrattamentoFarmacologicoNo" value="false" [checked]="!intervento.ieTrattamentoFarmacologico && intervento.ieTrattamentoFarmacologico!= null"
                                                [attr.disabled]="true">
                                            <label for="ieTrattamentoFarmacologicoNo">No</label>
                                        </div>
                                    </div>
                                </div>



                                <div class="form-group form-group-radio" *ngIf="intervento.ieInterventiRiabilitativi != null">
                                    <label>Interventi riabilitativi</label>
                                    <div class="form-radio">
                                        <div class="radio">
                                            <input type="radio" name="ieInterventiRiabilitativi{{intervento.id}}"
                                                id="ieInterventiRiabilitativiSi" value="true" [checked]="intervento.ieInterventiRiabilitativi"
                                                [attr.disabled]="true">
                                            <label for="ieInterventiRiabilitativiSi">Sì</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" name="ieInterventiRiabilitativi{{intervento.id}}"
                                                id="ieInterventiRiabilitativiNo" value="false" [checked]="!intervento.ieInterventiRiabilitativi && intervento.ieInterventiRiabilitativi!= null"
                                                [attr.disabled]="true">
                                            <label for="ieInterventiRiabilitativiNo">No</label>
                                        </div>
                                    </div>
                                </div>


                                <div class="form-group form-group-radio" *ngIf="intervento.ieInserimentiLavorativi != null">
                                    <label>Inserimenti lavorativi</label>
                                    <div class="form-radio">
                                        <div class="radio">
                                            <input type="radio" name="ieInserimentiLavorativi{{intervento.id}}"
                                                id="ieInserimentiLavorativiSi" value="true" [checked]="intervento.ieInserimentiLavorativi"
                                                [attr.disabled]="true">
                                            <label for="ieInserimentiLavorativiSi">Sì</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" name="ieInserimentiLavorativi{{intervento.id}}"
                                                id="ieInserimentiLavorativiNo" value="false" [checked]="!intervento.ieInserimentiLavorativi && intervento.ieInserimentiLavorativi!= null"
                                                [attr.disabled]="true">
                                            <label for="ieInserimentiLavorativiNo">No</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group form-group-radio" *ngIf="intervento.ieInterventoSociale != null">
                                    <label>Interv. sociale e/o di rete</label>
                                    <div class="form-radio">
                                        <div class="radio">
                                            <input type="radio" name="ieInterventoSociale{{intervento.id}}"
                                                id="ieInterventoSocialeSi" value="true" [checked]="intervento.ieInterventoSociale"
                                                [attr.disabled]="true">
                                            <label for="ieInterventoSocialeSi">Sì</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" name="ieInterventoSociale{{intervento.id}}"
                                                id="ieInterventoSocialeNo" value="false" [checked]="!intervento.ieInterventoSociale && intervento.ieInterventoSociale!= null"
                                                [attr.disabled]="true">
                                            <label for="ieInterventoSocialeNo">No</label>
                                        </div>
                                    </div>
                                </div>


                                <div class="form-group form-group-radio" *ngIf="intervento.ieColloquiSostegno != null">
                                    <label>Colloqui psichiatrici e psicologici di sostegno</label>
                                    <div class="form-radio">
                                        <div class="radio">
                                            <input type="radio" name="ieColloquiSostegno{{intervento.id}}"
                                                id="ieColloquiSostegnoSi" value="true"  [checked]="intervento.ieColloquiSostegno"
                                                [attr.disabled]="true">
                                            <label for="ieColloquiSostegnoSi">Sì</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" name="ieColloquiSostegno{{intervento.id}}"
                                                id="ieColloquiSostegnoNo" value="false" [checked]="!intervento.ieColloquiSostegno && intervento.ieColloquiSostegno!= null"
                                                [attr.disabled]="true">
                                            <label for="ieColloquiSostegnoNo">No</label>
                                        </div>
                                    </div>
                            </div>

                        </div>

                        <!-- PART2 -->
                        <div class="row">

                            <div class="group-inline pt-4">

                                <div class="form-group form-group-radio" *ngIf="intervento.ieCoinvolgimentoFamiliari != null">
                                    <label>Coinvolgimento/sostegno ai familiari</label>
                                    <div class="form-radio">
                                        <div class="radio">
                                            <input type="radio" name="ieCoinvolgimentoFamiliari{{intervento.id}}"
                                                id="ieCoinvolgimentoFamiliariSi" value="true" [checked]="intervento.ieCoinvolgimentoFamiliari"
                                                [attr.disabled]="true">
                                            <label for="ieCoinvolgimentoFamiliariSi">Sì</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" name="ieCoinvolgimentoFamiliari{{intervento.id}}"
                                                id="ieCoinvolgimentoFamiliariNo" value="false" [checked]="!intervento.ieCoinvolgimentoFamiliari && intervento.ieCoinvolgimentoFamiliari!= null"
                                                [attr.disabled]="true">
                                            <label for="ieCoinvolgimentoFamiliariNo">No</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group form-group-radio" *ngIf="intervento.ieAccertamentiMedicoLegali != null">
                                    <label>Accertamenti medico legali</label>
                                    <div class="form-radio">
                                        <div class="radio">
                                            <input type="radio" name="ieAccertamentiMedicoLegali{{intervento.id}}"
                                                id="ieAccertamentiMedicoLegaliSi" value="true" [checked]="intervento.ieAccertamentiMedicoLegali"
                                                [attr.disabled]="true">
                                            <label for="ieAccertamentiMedicoLegaliSi">Sì</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" name="ieAccertamentiMedicoLegali{{intervento.id}}"
                                                id="ieAccertamentiMedicoLegaliNo" value="false" [checked]="!intervento.ieAccertamentiMedicoLegali && intervento.ieAccertamentiMedicoLegali!= null"
                                                [attr.disabled]="true">
                                            <label for="ieAccertamentiMedicoLegaliNo">No</label>
                                        </div>
                                    </div>
                                </div>


                                <div class="form-group form-group-radio" *ngIf="intervento.ieInvioInStruttura != null">
                                    <label>Invio in struttura residenziale/semires.</label>
                                    <div class="form-radio">
                                        <div class="radio">
                                            <input type="radio" name="ieInvioInStruttura{{intervento.id}}"
                                                id="ieInvioInStrutturaSi" value="true" [checked]="!intervento.ieInvioInStruttura"
                                                [attr.disabled]="true">
                                            <label for="ieInvioInStrutturaSi">Sì</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" name="ieInvioInStruttura{{intervento.id}}"
                                                id="ieInvioInStrutturaNo" value="false" [checked]="!intervento.ieInvioInStruttura && intervento.ieInvioInStruttura!= null"
                                                [attr.disabled]="true">
                                            <label for="ieInvioInStrutturaNo">No</label>
                                        </div>
                                    </div>
                                </div>


                                <div class="form-group form-group-radio" *ngIf="intervento.ieInvioStpit != null">
                                    <label>Invio struttura STIPT</label>
                                    <div class="form-radio">
                                        <div class="radio">
                                            <input type="radio" name="ieInvioStpit{{intervento.id}}" id="ieInvioStpitSi"
                                                value="true" [checked]="intervento.ieInvioStpit"
                                                [attr.disabled]="true">
                                            <label for="ieInvioStpitSi">Sì</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" name="ieInvioStpit{{intervento.id}}" id="ieInvioStpitNo"
                                                value="false" [checked]="!intervento.ieInvioStpit && intervento.ieInvioStpit!= null"
                                                [attr.disabled]="true">
                                            <label for="ieInvioStpitNo">No</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group form-group-radio" *ngIf="intervento.ieLuogoStpit != null">
                                    <label>Luogo struttura STIPT</label>
                                    <div class="form-radio">
                                        <div class="radio">
                                            <input type="radio" name="ieLuogoStpit{{intervento.id}}" id="ieLuogoStpitRegione"
                                                value="true" [checked]="intervento.ieLuogoStpit"
                                                [attr.disabled]="true">
                                            <label for="ieLuogoStpitRegione">Regione</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" name="ieLuogoStpit{{intervento.id}}"
                                                id="ieLuogoStpitFuoriRegione"
                                                value="false" [checked]="!intervento.ieLuogoStpit && intervento.ieLuogoStpit!= null"
                                                [attr.disabled]="true">
                                            <label for="ieLuogoStpitFuoriRegione">Fuori regione</label>
                                        </div>
                                    </div>
                                </div>


                                <div class="form-group form-group-radio" *ngIf="intervento.ieInvioSrtri != null">
                                    <label>Invio struttura SRTRI</label>
                                    <div class="form-radio">
                                        <div class="radio">
                                            <input type="radio" name="ieInvioSrtri{{intervento.id}}" id="ieInvioSrtriSi"
                                                value="true" [checked]="intervento.ieInvioSrtri"
                                                [attr.disabled]="true">
                                            <label for="ieInvioSrtriSi">Si</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" name="ieInvioSrtri{{intervento.id}}" id="ieInvioSrtriNo"
                                                value="false" [checked]="!intervento.ieInvioSrtri && intervento.ieInvioSrtri!= null"
                                                [attr.disabled]="true">
                                            <label for="ieInvioSrtriNo">No</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group form-group-radio" *ngIf="intervento.ieLuogoSrtri != null">
                                    <label>Luogo struttura SRTRI</label>
                                    <div class="form-radio">
                                        <div class="radio">
                                            <input type="radio" name="ieLuogoSrtri{{intervento.id}}" id="ieLuogoSrtriSi"
                                                value="true" [checked]="intervento.ieLuogoSrtri"
                                                [attr.disabled]="true">
                                            <label for="ieLuogoSrtriSi">Regione</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" name="ieLuogoSrtri{{intervento.id}}" id="ieLuogoSrtriNo"
                                                value="false" [checked]="!intervento.ieLuogoSrtri && intervento.ieLuogoSrtri!= null"
                                                [attr.disabled]="true">
                                            <label for="ieLuogoSrtriNo">Fuori Regione</label>
                                        </div>
                                    </div>
                                </div>


                                <div class="form-group form-group-radio" *ngIf="intervento.ieInvioSrtre != null">
                                    <label>Invio struttura SRTRE</label>
                                    <div class="form-radio">
                                        <div class="radio">
                                            <input type="radio" name="ieInvioSrtre{{intervento.id}}" id="ieInvioSrtreSi"
                                                value="true" [checked]="intervento.ieInvioSrtre"
                                                [attr.disabled]="true">
                                            <label for="ieInvioSrtreSi">Si</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" name="ieInvioSrtre{{intervento.id}}" id="ieInvioSrtreNo"
                                                value="false" [checked]="!intervento.ieInvioSrtre && intervento.ieInvioSrtre!= null"
                                                [attr.disabled]="true">
                                            <label for="ieInvioSrtreNo">No</label>
                                        </div>
                                    </div>
                                </div>


                                <div class="form-group form-group-radio" *ngIf="intervento.ieLuogoSrtre != null">
                                    <label>Luogo struttura SRTRE</label>
                                    <div class="form-radio">
                                        <div class="radio">
                                            <input type="radio" name="ieLuogoSrtre{{intervento.id}}" id="ieLuogoSrtreSi"
                                                value="true" [checked]="intervento.ieLuogoSrtre"
                                                [attr.disabled]="true">
                                            <label for="ieLuogoSrtreSi">Regione</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" name="ieLuogoSrtre{{intervento.id}}" id="ieLuogoSrtreNo"
                                                value="false" [checked]="!intervento.ieLuogoSrtre && intervento.ieLuogoSrtre!= null"
                                                [attr.disabled]="true">
                                            <label for="ieLuogoSrtreNo">Fuori regione</label>
                                        </div>
                                    </div>
                                </div>


                                <div class="form-group form-group-radio" *ngIf="intervento.ieInvioSrsr24 != null">
                                    <label>Invio struttura SRSR24</label>
                                    <div class="form-radio">
                                        <div class="radio">
                                            <input type="radio" name="ieInvioSrsr24{{intervento.id}}" id="ieInvioSrsr24Si"
                                                value="true" [checked]="intervento.ieInvioSrsr24"
                                                [attr.disabled]="true">
                                            <label for="ieInvioSrsr24Si">Si</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" name="ieInvioSrsr24{{intervento.id}}" id="ieInvioSrsr24No"
                                                value="false" [checked]="!intervento.ieInvioSrsr24 && intervento.ieInvioSrsr24!= null"
                                                [attr.disabled]="true">
                                            <label for="ieInvioSrsr24No">No</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group form-group-radio" *ngIf="intervento.ieLuogoSrsr24 != null">
                                    <label>Luogo struttura SRSR24</label>
                                    <div class="form-radio">
                                        <div class="radio">
                                            <input type="radio" name="ieLuogoSrsr24{{intervento.id}}" id="ieLuogoSrsr24Si"
                                                value="true" [checked]="intervento.ieLuogoSrsr24"
                                                [attr.disabled]="true">
                                            <label for="ieLuogoSrsr24Si">Regione</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" name="ieLuogoSrsr24{{intervento.id}}" id="ieLuogoSrsr24No"
                                                value="false" [checked]="!intervento.ieLuogoSrsr24 && intervento.ieLuogoSrsr24!= null"
                                                [attr.disabled]="true">
                                            <label for="ieLuogoSrsr24No">Fuori regione</label>
                                        </div>
                                    </div>
                                </div>



                                <div class="form-group form-group-radio" *ngIf="intervento.ieInvioSrsr12 != null">
                                    <label>Invio struttura SRSR12</label>
                                    <div class="form-radio">
                                        <div class="radio">
                                            <input type="radio" name="ieInvioSrsr12{{intervento.id}}" id="ieInvioSrsr12Si"
                                                value="true" [checked]="intervento.ieInvioSrsr12"
                                                [attr.disabled]="true">
                                            <label for="ieInvioSrsr12Si">Si</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" name="ieInvioSrsr12{{intervento.id}}" id="ieInvioSrsr12No"
                                                value="false" [checked]="!intervento.ieInvioSrsr12 && intervento.ieInvioSrsr12!= null"
                                                [attr.disabled]="true">
                                            <label for="ieInvioSrsr12No">No</label>
                                        </div>
                                    </div>
                                </div>


                                <div class="form-group form-group-radio" *ngIf="intervento.ieLuogoSrsr12 != null">
                                    <label>Luogo struttura SRSR12</label>
                                    <div class="form-radio">
                                        <div class="radio">
                                            <input type="radio" name="ieLuogoSrsr12{{intervento.id}}" id="ieLuogoSrsr12Si"
                                                value="true" [checked]="intervento.ieLuogoSrsr12"
                                                [attr.disabled]="true">
                                            <label for="ieLuogoSrsr12Si">Regione</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" name="ieLuogoSrsr12{{intervento.id}}" id="ieLuogoSrsr12No"
                                                value="false" [checked]="!intervento.ieLuogoSrsr12 && intervento.ieLuogoSrsr12!= null"
                                                [attr.disabled]="true">
                                            <label for="ieLuogoSrsr12No">Fuori regione</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>



                        <!-- PART4 -->
                        <div class="row">
                            <div class="group-inline pt-4">

                                <div class="form-group form-group-radio" *ngIf="intervento.ieInvioGa != null">
                                    <label>Invio in struttura gruppo appartamento (GA)</label>
                                    <div class="form-radio">
                                        <div class="radio">
                                            <input type="radio" name="ieInvioGa{{intervento.id}}" id="ieInvioGaSi" value="true"
                                            [checked]="intervento.ieInvioGa"
                                            [attr.disabled]="true">
                                            <label for="ieInvioGaSi">Si</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" name="ieInvioGa{{intervento.id}}" id="ieInvioGaNo" value="false"
                                            [checked]="!intervento.ieInvioGa && intervento.ieInvioGa!= null"
                                                [attr.disabled]="true">
                                            <label for="ieInvioGaNo">No</label>
                                        </div>
                                    </div>
                                </div>


                                <div class="form-group form-group-radio" *ngIf="intervento.ieLuogoGa != null">
                                    <label>Luogo struttura gruppo appartamento (GA)</label>
                                    <div class="form-radio">
                                        <div class="radio">
                                            <input type="radio" name="ieLuogoGa{{intervento.id}}" id="ieLuogoGaSi" value="true"
                                            [checked]="intervento.ieLuogoGa"
                                                [attr.disabled]="true">
                                            <label for="ieLuogoGaSi">Regione</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" name="ieLuogoGa{{intervento.id}}" id="ieLuogoGaNo" value="false"
                                            [checked]="!intervento.ieLuogoGa && intervento.ieLuogoGa!= null"
                                                [attr.disabled]="true">
                                            <label for="ieLuogoGaNo">Fuori regione</label>
                                        </div>
                                    </div>
                                </div>




                                <div class="form-group form-group-radio" *ngIf="intervento.ieInvioCd != null">
                                    <label>Invio in struttura centro diurno (CD)</label>
                                    <div class="form-radio">
                                        <div class="radio">
                                            <input type="radio" name="ieInvioCd{{intervento.id}}" id="ieInvioCdSi" value="true"
                                            [checked]="intervento.ieInvioCd"
                                                [attr.disabled]="true">
                                            <label for="ieInvioCdSi">Si</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" name="ieInvioCd{{intervento.id}}" id="ieInvioCdNo" value="false"
                                            [checked]="!intervento.ieInvioCd && intervento.ieInvioCd!= null"
                                                [attr.disabled]="true">
                                            <label for="ieInvioCdNo">No</label>
                                        </div>
                                    </div>
                                </div>



                                <div class="form-group form-group-radio" *ngIf="intervento.ieLuogoCd != null">
                                    <label>Luogo struttura centro diurno (CD)</label>
                                    <div class="form-radio">
                                        <div class="radio">
                                            <input type="radio" name="ieLuogoCd{{intervento.id}}" id="ieLuogoCdSi" value="true"
                                            [checked]="intervento.ieLuogoCd"
                                                [attr.disabled]="true">
                                            <label for="ieLuogoCdSi">Regione</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" name="ieLuogoCd{{intervento.id}}" id="ieLuogoCdNo" value="false"
                                            [checked]="!intervento.ieLuogoCd && intervento.ieLuogoCd!= null"
                                                [attr.disabled]="true">
                                            <label for="ieLuogoCdNo">Fuori regione</label>
                                        </div>
                                    </div>
                                </div>



                                <div class="form-group form-group-radio" *ngIf="intervento.ieInvioOspedale != null">
                                    <label>Invio in ospedale</label>
                                    <div class="form-radio">
                                        <div class="radio">
                                            <input type="radio" name="ieInvioOspedale{{intervento.id}}" id="ieInvioOspedaleSi"
                                                value="true" [checked]="intervento.ieInvioOspedale"
                                                [attr.disabled]="true">
                                            <label for="ieInvioOspedaleSi">Si</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" name="ieInvioOspedale{{intervento.id}}" id="ieInvioOspedaleNo"
                                                value="false" [checked]="!intervento.ieInvioOspedale && intervento.ieInvioOspedale!= null"
                                                [attr.disabled]="true">
                                            <label for="ieInvioOspedaleNo">No</label>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                        <!-- PART5 -->
                        <div class="row">

                            <div class="group-inline pt-4">

                                <div class="form-group  form-group-radio" *ngIf="intervento.ieInvioOspedaleRep40 != null">
                                    <label>Reparto ospedaliero psichiatrico (Rep.40)</label>
                                    <div class="form-radio">
                                        <div class="radio">
                                            <input type="radio" name="ieInvioOspedaleRep40{{intervento.id}}"
                                                id="ieInvioOspedaleRep40Si" value="true"
                                                [checked]="intervento.ieInvioOspedaleRep40"
                                                [attr.disabled]="true">
                                            <label for="ieInvioOspedaleRep40Si">Si</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" name="ieInvioOspedaleRep40{{intervento.id}}"
                                                id="ieInvioOspedaleRep40No" value="false"
                                                [checked]="!intervento.ieInvioOspedaleRep40 && intervento.ieInvioOspedaleRep40!= null"
                                                [attr.disabled]="true">
                                            <label for="ieInvioOspedaleRep40No">No</label>
                                        </div>
                                    </div>
                                </div>


                                <div class="form-group form-group-radio" *ngIf="intervento.ieLuogoOspedale != null">
                                    <label>Luogo struttura ospedaliera</label>
                                    <div class="form-radio">
                                        <div class="radio">
                                            <input type="radio" name="ieLuogoOspedale{{intervento.id}}" id="ieLuogoOspedaleSi"
                                                value="true" [checked]="intervento.ieLuogoOspedale"
                                                [attr.disabled]="true">
                                            <label for="ieLuogoOspedaleSi">Regione</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" name="ieLuogoOspedale{{intervento.id}}" id="ieLuogoOspedaleNo"
                                                value="false" [checked]="!intervento.ieLuogoOspedale && intervento.ieLuogoOspedale!= null"
                                                [attr.disabled]="true">
                                            <label for="ieLuogoOspedaleNo">Fuori regione</label>
                                        </div>
                                    </div>
                                </div>


                                <div class="form-group form-group-radio" *ngIf="intervento.ieConsultazioneValutazione != null">
                                    <label>Consultazione valutazione</label>
                                    <div class="form-radio">
                                        <div class="radio">
                                            <input type="radio" name="ieConsultazioneValutazione{{intervento.id}}"
                                                id="ieConsultazioneValutazioneSi" value="true"
                                                [checked]="intervento.ieConsultazioneValutazione"
                                                [attr.disabled]="true">
                                            <label for="ieConsultazioneValutazioneSi">Si</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" name="ieConsultazioneValutazione{{intervento.id}}"
                                                id="ieConsultazioneValutazioneNo" value="false"
                                                [checked]="!intervento.ieConsultazioneValutazione && intervento.ieConsultazioneValutazione!= null"
                                                [attr.disabled]="true">
                                            <label for="ieConsultazioneValutazioneNo">No</label>
                                        </div>
                                    </div>
                                </div>


                            </div>

                        </div>




                    </div>



                </div>


                <!-- PART6 -->
                <div class="row" >

                  <div *ngIf=" intervento.ieAltro != null &&  intervento.ieAltro!= '' " class="form-group field-wide">
                      <label>Altro</label>
                      <input type="text" class="form-control" name="ieAltro" [value]="intervento.ieAltro"
                          [attr.disabled]="true">
                  </div>
                </div>
              </div>

            </div>

            <hr class="separator">
          </div>
        </div>



        <!-- DIAGNOSI -->



        <div class="tab-pane fade {{isActiveDiagnosi?'active show':''}}" id="diagnosi" role="tabpanel"
          aria-labelledby="diagnosi-tab">
          <div class="tab-pane fade active show"  role="tabpanel" *ngIf="listDiagnosiEScale.length == 0"
                aria-labelledby="contatto-tab">
                NON CI SONO DIAGNOSI DA VISUALIZZARE
          </div>
          <div class="pt-5" *ngFor="let diagnosi of listDiagnosiEScale">
            <div *ngIf="diagnosi.statoAttuale.id == 3" style="text-align: end">
              <button class="btn btn-outline-secondary" (click)="riabilitaModifica(diagnosi.id, 'diagnosi')">
                Riabilita modifica alla struttura
              </button>
            </div>
            <div *ngIf="diagnosi.statoAttuale.id == 2" class="bgevidence">
              <hr>
              <div class="group-inline pt-4">
                <div class="form-group field-10 has-feedback">
                  <input type="checkbox" name="operation" [id]="'valida_'+diagnosi.id "  class="valida"  [value]="diagnosi.id"
                    (click)="onChangeValida($event, 'diagnosi')"
                    [checked]="isSelectedValidati(diagnosi.id, 'diagnosi')">
                    <label>Valida     </label>
                </div>
                <br>
                <br>
                <div class="form-group field-10 has-feedback">
                  <input type="checkbox" name="operation" [id]="'scarta_'+diagnosi.id " class="scarta" [value]="diagnosi.id"
                    (click)="onChangeScarta($event, 'diagnosi')"
                    [checked]="isSelectedScartati(diagnosi.id, 'diagnosi')">
                    <label>Scarta    </label>
                </div>
              </div>
            </div>
            <div class=" pt-4" *ngIf=" diagnosi.statoAttuale.id == 2 || diagnosi.statoAttuale.id == 4" class="bgevidence">
              <div  class="form-group has-feedback">
                <label [hidden]="!isSelectedScartati(diagnosi.id, 'diagnosi') && diagnosi.statoAttuale.id != 4">Motivazione dello scarto{{ diagnosi.statoAttuale.id == 2 ? '*':''}}</label>
                <textarea class="form-control" name="motivazioniScarti"
                [hidden]="!isSelectedScartati(diagnosi.id, 'diagnosi') && diagnosi.statoAttuale.id != 4"
                [readOnly]="diagnosi.statoAttuale.id == 4"
                (change)="changeMotivazioniScarto(diagnosi.id, 'diagnosi', $event)">{{diagnosi.motivazioneScarto}}</textarea>
              </div>
            </div>
            <div class=" pt-4" *ngIf=" diagnosi.statoAttuale.id == 2 || diagnosi.statoAttuale.id == 4">
              <hr>
            </div>
            <div class="group-inline">

                <!-- Data -->
                <div class="form-group field-10 has-feedback">
                    <label>Data</label>
                    <div class="input-group">
                        <input class="form-control" placeholder="aaaa-mm-gg" name="data"
                            ngbDatepicker #d="ngbDatepicker" [value]="diagnosi.data"
                            [readonly]="true">

                    </div>
                </div>

                <!-- Diagnosi -->
                <div class="form-group field-80 has-feedback">
                    <label>Diagnosi</label>

                    <input id="typeahead-focus" type="text" class="form-control"
                    [value]="diagnosi.diagnosi"
                        [attr.disabled]="true" />
                </div>


                <!-- ScalaHonos -->
                <div class="form-group field-wide">
                    <label>Scala honos</label>
                    <input type="number" class="form-control"
                     name="scalaHonos" maxlength="2" min="0" [value]="diagnosi.scalaHonos"
                        [attr.disabled]="true">
                </div>

                <!-- ScalaBprs -->
                <div class="form-group field-wide">
                    <label>Scala bprs</label>
                    <input type="number" class="form-control" maxlength="3" min="1" name="scalaBprs"
                    [value]="diagnosi.scalaBprs"
                        [attr.disabled]="true">
                </div>

            </div>

            <hr class="separator">
          </div>
        </div>




        <!-- PRESENZE -->



        <div class="tab-pane fade {{isActivePresenze?'active show':''}}" id="presenze" role="tabpanel"
          aria-labelledby="presenze-tab">
          <div class="tab-pane fade active show"  role="tabpanel" *ngIf="listPresenze.length == 0"
                aria-labelledby="contatto-tab">
                NON CI SONO PRESENZE DA VISUALIZZARE
          </div>
          <div class="pt-5" *ngFor="let presenza of listPresenze">
            <div *ngIf="presenza.statoAttuale.id == 3" style="text-align: end">
              <button class="btn btn-outline-secondary" (click)="riabilitaModifica(presenza.id, 'presenza')">
                Riabilita modifica alla struttura
              </button>
            </div>
            <div *ngIf="presenza.statoAttuale.id == 2" class="bgevidence">
              <div class="group-inline pt-4">
                <div class="form-group field-10 has-feedback">
                  <input type="checkbox" name="operation" [id]="'valida_'+presenza.id "  class="valida"  [value]="presenza.id"
                    (click)="onChangeValida($event, 'presenze')"
                    [checked]="isSelectedValidati(presenza.id, 'presenze')">
                    <label>Valida     </label>
                </div>
                <br>
                <br>
                <div class="form-group field-10 has-feedback">
                  <input type="checkbox" name="operation" [id]="'scarta_'+presenza.id " class="scarta" [value]="presenza.id"
                    (click)="onChangeScarta($event, 'presenze')"
                    [checked]="isSelectedScartati(presenza.id, 'presenze')">
                    <label>Scarta    </label>
                </div>
              </div>
            </div>
            <div class=" pt-4" *ngIf=" presenza.statoAttuale.id == 2 || presenza.statoAttuale.id == 4" class="bgevidence">
              <div  class="form-group has-feedback">
                <label [hidden]="!isSelectedScartati(presenza.id, 'presenze') && presenza.statoAttuale.id != 4">Motivazione dello scarto{{ presenza.statoAttuale.id == 2 ? '*':''}}</label>
                <textarea class="form-control" name="motivazioniScarti"
                [hidden]="!isSelectedScartati(presenza.id, 'presenze') && presenza.statoAttuale.id != 4"
                [readOnly]="presenza.statoAttuale.id == 4"
                (change)="changeMotivazioniScarto(presenza.id, 'presenze', $event)">{{presenza.motivazioneScarto}}</textarea>
              </div>
            </div>
            <div class=" pt-4" *ngIf=" presenza.statoAttuale.id == 2 || presenza.statoAttuale.id == 4">
              <hr>
            </div>

            <div class="group-inline">


                <div class="group-inline">

                    <!-- Data -->
                    <div class="form-group field-10 has-feedback">
                        <label>Data</label>
                        <div class="input-group">
                            <input class="form-control" placeholder="aaaa-mm-gg" name="data"
                                ngbDatepicker #d="ngbDatepicker" [value]="presenza.dataPresenza"
                                [readonly]="true">
                        </div>
                    </div>

                    <!-- Modalita Presenza -->
                    <div class="form-group field-60">


                        <label>Mod presenza</label>
                        <div>
                          <input id="typeahead-focus" type="text" class="form-control"
                          value="Presenza semiresidenziale {{presenza.modPresenza == '1' ? '<':'>'}} 4 ore"
                              [attr.disabled]="true" />
                        </div>

                    </div>

                </div>

                <div class="form-group field-wide">

                </div>

            </div>

            <hr class="separator">
          </div>
        </div>




        <!-- PRESTAZIONI -->




        <div class="tab-pane fade {{isActivePrestazioni?'active show':''}}" id="prestazioni" role="tabpanel"
          aria-labelledby="prestazioni-tab">

          <div class="tab-pane fade active show"  role="tabpanel" *ngIf="listPrestazione.length == 0"
                aria-labelledby="contatto-tab">
                NON CI SONO PRESTAZIONI DA VISUALIZZARE
          </div>

          <div class="pt-5" demon-prestazione="tipo-1" *ngFor="let prestazione of listPrestazione">
            <div *ngIf="prestazione.statoAttuale.id == 3" style="text-align: end">
              <button class="btn btn-outline-secondary" (click)="riabilitaModifica(prestazione.id, 'prestazione')">
                Riabilita modifica alla struttura
              </button>
            </div>

            <div *ngIf="prestazione.statoAttuale.id == 2" class="bgevidence">
              <div class="group-inline pt-4">
                <div  class="form-group field-10 has-feedback">
                  <input type="checkbox" name="operation" [id]="'valida_'+prestazione.id "  class="valida"  [value]="prestazione.id"
                    (click)="onChangeValida($event, 'prestazioni')"
                    [checked]="isSelectedValidati(prestazione.id, 'prestazioni')">
                    <label>Valida     </label>
                </div>
                <br>
                <br>
                <div  class="form-group field-10 has-feedback">
                  <input type="checkbox" name="operation" [id]="'scarta_'+prestazione.id " class="scarta" [value]="prestazione.id"
                    (click)="onChangeScarta($event, 'prestazioni')"
                    [checked]="isSelectedScartati(prestazione.id, 'prestazioni')">
                    <label>Scarta    </label>
                </div>
              </div>
            </div>
            <div class=" pt-4" *ngIf=" prestazione.statoAttuale.id == 2|| prestazione.statoAttuale.id == 4"  class="bgevidence">
              <div  class="form-group has-feedback">
                <label [hidden]="!isSelectedScartati(prestazione.id, 'prestazioni') && prestazione.statoAttuale.id != 4">Motivazione dello scarto{{ prestazione.statoAttuale.id == 2 ? '*':''}}</label>
                <textarea class="form-control" name="motivazioniScarti"
                [hidden]="!isSelectedScartati(prestazione.id, 'prestazioni') && prestazione.statoAttuale.id != 4"
                [readOnly]="prestazione.statoAttuale.id == 4"
                (change)="changeMotivazioniScarto(prestazione.id, 'prestazioni', $event)">{{prestazione.motivazioneScarto}}</textarea>
              </div>
            </div>
            <div class=" pt-4" *ngIf=" prestazione.statoAttuale.id == 2|| prestazione.statoAttuale.id == 4">
              <hr>
            </div>

            <div class="group-inline mt-4">


              <!-- Data -->
              <div class="form-group field-10 has-feedback">
                <label>Data*</label>
                <div class="input-group">
                  <input class="form-control" placeholder="aaaa-mm-gg" name="date" ngbDatepicker
                    #d="ngbDatepicker" [value]="prestazione.dataPrestazione"
                    [readonly]="true">
                </div>

              </div>

              <!-- Tipo -->
              <div class="form-group field-60">
                <label>Tipo</label>
                <div>
                  <input id="typeahead-focus" type="text" class="form-control"
                        [value]="prestazione.descrizioneTipo"
                              [attr.disabled]="true" />
                </div>
              </div>

              <!-- modalita -->
              <div class="group-inline">

                <!-- modalita erogazione -->
                <div class="form-group field-16" *ngIf="true">
                  <label>Modalità di erogazione</label>
                  <div>
                    <input id="typeahead-focus" type="text" class="form-control"
                        [value]="prestazione.descrizioneModalitaErogazione"
                              [attr.disabled]="true" />
                  </div>
                </div>

              </div>
              <!-- sede -->
              <div class="form-group field-50 has-feedback">
                <label>Sede intervento</label>
                <div>
                  <input id="typeahead-focus" type="text" class="form-control"
                        [value]="prestazione.sede"
                              [attr.disabled]="true" />

                </div>
              </div>

            </div>

            <hr>

            <!-- operatore1 -->
            <div class="group-inline">
              <div class="form-group field-20">
                <label>Operatore 1</label>
                <div>
                  <input id="typeahead-focus" type="text" class="form-control"
                        [value]="prestazione.operatore1"
                              [attr.disabled]="true" />
                </div>

              </div>
              <div class="form-group field-6">
                <label>Codice</label>
                <input id="typeahead-focus" type="text" class="form-control"
                        [value]="prestazione.operatore1Codice"
                              [attr.disabled]="true" />
              </div>


            </div>

            <!-- operatore2 -->
            <div class="group-inline">
              <div class="form-group field-20">
                <label>Operatore 2</label>
                <div>
                  <input id="typeahead-focus" type="text" class="form-control"
                        [value]="prestazione.operatore2"
                              [attr.disabled]="true" />

                </div>
              </div>
              <div class="form-group field-6">
                <label>Codice</label>
                <input id="typeahead-focus" type="text" class="form-control"
                        [value]="prestazione.operatore2Codice"
                              [attr.disabled]="true" />
                </div>
            </div>

            <!-- operatore3 -->
            <div class="group-inline">
              <div class="form-group field-20">
                <label>Operatore 3</label>
                <div>
                  <input id="typeahead-focus" type="text" class="form-control"
                        [value]="prestazione.operatore3"
                              [attr.disabled]="true" />


                </div>
              </div>
              <div class="form-group field-6">
                <label>Codice</label>
                <input id="typeahead-focus" type="text" class="form-control"
                        [value]="prestazione.operatore3Codice"
                              [attr.disabled]="true" />

              </div>


            </div>



            <hr class="separator">
          </div>


        </div>

      </div>
    </div>



 <!-- FOOTER -->

    <div class="card-footer" >
      <div class="btn-toolbar">
        <div class="btn-group-left">
          <button class="btn btn-outline-secondary" (click)="chiudi()">Torna alla lista</button>
        </div>
        <div class="btn-group-center"></div>
        <div class="btn-group-right">
          <button [hidden]="statoAttuale != '2'" class="btn btn-secondary" (click)="salvaTutto()">Salva tutto e chiudi</button>
        </div>
      </div>
    </div>

  </div>
</div>
