<div class="container py-3">
  <ul class="nav nav-tabs" id="searchTab" role="tablist">
    <li class="nav-item">
      <a class="nav-link title-3 {{activedNo?activedNo:''}}" id="simple-tab" data-toggle="tab" href="#simple" role="tab"
         aria-controls="simple" aria-selected="true" (click)="switchToSimpleForm()">
        Ricerca veloce
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link title-3 {{actived?actived:''}}" id="advanced-tab" data-toggle="tab" href="#advanced" role="tab"
         aria-controls="advanced" [attr.aria-selected]="isFormAdvanced" (click)="switchToAdvancedForm()">
        Avanzata
      </a>
    </li>
  </ul>

  <!-- Ricerca -->
  <div class="card">
    <!--    <div class="card-header">-->
    <!--      <div id="ricerca-anag-title"   class="title-3">Ricerca anagrafica</div>-->
    <!--    </div>-->
    <div class="card-body">
      <form [formGroup]="ricercaForm" autocomplete="off">
        <div class="group-inline  pt-3">
          <!--          <div class="form-group field-38" *ngIf="utente.idRuolo != 4 && isFormAdvanced">-->
          <!--            <label>Struttura</label>-->
          <!--            <input id="typeahead-focus-x" type="text" class="form-control"-->
          <!--                   [(ngModel)]="modelStruttura" [ngbTypeahead]="strutturaSearch"-->
          <!--                   (focus)="focus$.next($any($event).target.value)"-->
          <!--                   (click)="click$.next($any($event).target.value)" #instance="ngbTypeahead"-->
          <!--                   [inputFormatter]="formatter" [resultFormatter]="formatter" [editable]="false"-->
          <!--                   formControlName="struttura"/>-->

          <!--          </div>-->
          <div class="form-group field-16" *ngIf="!isFormAdvanced">
            <label>Codice fiscale</label>
            <input type="text" class="form-control" formControlName="codiceFiscale" name="codiceFiscale">
          </div>
          <div [hidden]="true" class="form-group field-14" *ngIf="!isFormAdvanced">
            <label>CF certificati</label>
            <select class="form-control" formControlName="cfCertificati">
              <option value="">Tutti</option>
              <option value="1">Certificati</option>
              <option value="2">Non certificati</option>
            </select>
          </div>
          <div class="form-group field-20" *ngIf="!isFormAdvanced">
            <label>Cognome</label>
            <input type="text" class="form-control" formControlName="cognome" name="cognome">
          </div>
          <div class="form-group field-20" *ngIf="!isFormAdvanced">
            <label>Nome</label>
            <input type="text" class="form-control" formControlName="nome" name="nome">
          </div>
          <div class="form-group field-10 has-feedback" *ngIf="!isFormAdvanced">
            <label>Data di nascita</label>
            <div class="input-group">
              <input type="text" class="form-control" placeholder="aaaa-mm-gg" name="dp" [(ngModel)]="searchDateModel"
                     ngbDatepicker #aperturaDato="ngbDatepicker" name="selectedDate" formControlName="selectedDate"
                     [minDate]="minDate" [maxDate]="maxDate">
              <div class="input-group-append">
                <button class="btn btn-secondary" (click)="aperturaDato.toggle()" type="button">
                  <img class="img-responsive" src="../assets/icons/calendar_ko.png" style="width: 20px; height:20px">
                </button>
              </div>
            </div>
          </div>
          <div class="form-group field-20" *ngIf="!isFormAdvanced">
            <label>IDANA</label>
            <input type="text" class="form-control" formControlName="id" name="id" style="width: 120px !important;">
          </div>

          <div [hidden]="true" class="form-group field-30" *ngIf="isFormAdvanced">
            <label>Nazione di nascita</label>
            <div>
              <select class="form-control" name="selectedNazione" formControlName="selectedNazione">
                <option value=""></option>
                <option [value]="se.alpha2" *ngFor="let se of listStatiesteri">{{ se.descrizione }}</option>
              </select>
            </div>
          </div>

        </div>
        <div class="group-inline  pt-3">
          <div class="form-group field-16" *ngIf="isFormAdvanced">
            <label>Anno</label>
            <select class="form-control" formControlName="anno" name="anno" (change)="changeAnno()">
              <option value="0" selected>
                Tutti
              </option>
              <!--              <option value="{{ annoSelezionato }}" >-->
              <!--                {{ annoSelezionato }}-->
              <!--              </option>-->

              <option *ngFor="let year of anni" value="{{ year }}">
                {{ year }}
              </option>
            </select>
          </div>
          <div class="form-group field-16" *ngIf="isFormAdvanced && getRuolo(OPERATORE_REGIONE)">
            <label>Asl</label>
            <select
              class="form-control"
              formControlName="asl"
              name="asl"
              formGorupName="ricercaForm"
              (change)="changeAsl()"
              [(ngModel)]="defaultSelectAsl"
            >
              <option value="">Tutte</option>
              <option
                *ngFor="let asl of aslList"
                value="{{ asl.codiceAzienda }}"
              >
                {{ asl.denominazioneAzienda }}
              </option>
            </select>
          </div>
          <div class="form-group field-16" *ngIf="isFormAdvanced && !getRuolo(OPERATORE_STRUTTURA) ">
            <label>Ambito</label>
            <select class="form-control" formControlName="ambito" name="ambito" (change)="changeAmbito()">
              <option value="" selected>
                Tutti
              </option>
              <option value="Territoriale">
                Territoriale
              </option>
              <option value="Semiresidenziale">
                Semiresidenziale
              </option>
            </select>
          </div>
          <div class="form-group field-16" *ngIf="isFormAdvanced && !getRuolo(OPERATORE_STRUTTURA)">
            <label>Tipo rapporto SSN</label>
            <select class="form-control" formControlName="tipo_apporto_SSN" name="tipo_apporto_SSN"
                    (change)="changeTipo_apporto_SSN()">
              <option value="" selected>
                Tutti
              </option>
              <option value="1">
                Pubblico
              </option>
              <option value="2">
                Privato
              </option>
            </select>
          </div>
          <div class="form-group field-38" *ngIf="isFormAdvanced && !getRuolo(OPERATORE_STRUTTURA)">
            <label>Struttura</label>
            <!--            <input id="typeahead-focus" type="text" class="form-control"-->
            <!--                                                                   [(ngModel)]="modelStruttura" [ngbTypeahead]="strutturaSearch"-->
            <!--                                                                   (focus)="focus$.next($any($event).target.value)"-->
            <!--                                                                   (click)="click$.next($any($event).target.value)" #instance="ngbTypeahead"-->
            <!--                                                                   [inputFormatter]="formatter" [resultFormatter]="formatter" [editable]="false"-->
            <!--                                                                   formControlName="struttura"/>-->
            <select class="form-control" formControlName="struttura" name="struttura" tabindex="-98" [(ngModel)]="modelStruttura"
                    (change)="changeStruttura()">
              <option [value]="{codStruttura:''}" selected>Tutte</option>
              <option *ngFor="let struttura of struttureList" [ngValue]="struttura">
                {{ struttura.denominazioneStruttura }}
              </option>
              <!--option *ngIf="!getRuolo(OPERATORE_REGIONE) " value="fuoriasl">Strutture fuori ASL</option-->
            </select>
          </div>
          <div class="form-group field-16" *ngIf="isFormAdvanced">
            <label>Apertura contatto</label>
            <select class="form-control" formControlName="apertura_contatto" name="apertura_contatto"
                    (change)="changeapertura_contatto()">
              <option value="" selected>
                Tutti
              </option>
              <option value="1">
                Avviato
              </option>
              <option value="2">
                Non avviato
              </option>
            </select>
          </div>
          <ng-container *ngIf="getRuolo(OPERATORE_STRUTTURA)">
            <div class="form-group field-16" *ngIf="isFormAdvanced">
              <label>Entità <span *ngIf="statoAttualeIsSet()">*</span></label>
              <select class="form-control" formControlName="entita" name="entita" (change)="changeentita()" [ngClass]="{'border-danger':statoAttualeIsSet()&&!entitaSelezionata()}">
                <option value="" selected>Tutte
                </option>
                <option value="contatto">
                  contatto
                </option>
                <option value="intervento">intervento
                </option>
                <option value="diagnosiescale">diagnosi
                </option>
                <option value="presenza">presenza
                </option>
                <option value="prestazione">prestazione
                </option>
              </select>
            </div>
            <div class="form-group field-46" *ngIf="isFormAdvanced">
              <label>Stato delle Informazioni <span *ngIf="entitaSelezionata()">*</span></label>
              <select class="form-control" [ngClass]="{'border-danger':entitaSelezionata()&&!statoAttualeIsSet()}" formControlName="stato_attuale" name="stato_attuale"
                      (change)="changestato_attuale()">
                <option value="" selected>Tutti</option>
                \
                <option *ngFor="let confStato of listConfStati" value="{{ confStato.id }}">
                  {{ confStato.descrizione }}
                </option>
                <ng-container *ngIf="getEntita() == 'contatto' && getRuolo(OPERATORE_STRUTTURA)">
                  <option *ngFor="let filtroRicerca of listConfFiltriRicerca" value="{{ filtroRicerca.id }}">
                    {{ filtroRicerca.descrizione }}
                  </option>
                </ng-container>
              </select>
            </div>
          </ng-container>
        </div>

        <ng-container *ngIf="!getRuolo(OPERATORE_STRUTTURA)">
          <div class="group-inline  pt-3">

            <div class="form-group field-16" *ngIf="isFormAdvanced">
              <label>Entità <span *ngIf="statoAttualeIsSet()">*</span></label>
              <select class="form-control" formControlName="entita" name="entita" (change)="changeentita()" [ngClass]="{'border-danger':statoAttualeIsSet()&&!entitaSelezionata()}">
                <option value="" selected>Tutte
                </option>
                <option value="contatto">
                  Contatto
                </option>
                <option value="intervento">Intervento
                </option>
                <option value="diagnosiescale">Diagnosi
                </option>
                <option value="presenza">Presenza
                </option>
                <option value="prestazione">Prestazione
                </option>
              </select>
            </div>
            <div class="form-group field-46" *ngIf="isFormAdvanced">
              <label>Stato delle Informazioni <span *ngIf="entitaSelezionata()">*</span></label>
              <select class="form-control" [ngClass]="{'border-danger':entitaSelezionata()&&!statoAttualeIsSet()}" formControlName="stato_attuale" name="stato_attuale"
                      (change)="changestato_attuale()">
                <option value="" selected>Tutti</option>
                \
                <option *ngFor="let confStato of listConfStati" value="{{ confStato.id }}">
                  {{ confStato.descrizione }}
                </option>
                <ng-container *ngIf="getEntita() == 'contatto' && getRuolo(OPERATORE_STRUTTURA)">
                  <option *ngFor="let filtroRicerca of listConfFiltriRicerca" value="{{ filtroRicerca.id }}">
                    {{ filtroRicerca.descrizione }}
                  </option>
                </ng-container>
              </select>
            </div>
          </div>
        </ng-container>
      </form>
    </div>
    <div class="card-footer">
      <div class="btn-toolbar">
        <div class="btn-group-left">
          <button class="btn btn-outline-secondary" (click)="pulisciFiltri()">
            Pulisci
          </button>
        </div>
        <div class="btn-group-center"></div>
        <div class="btn-group-right">
          <button class="btn btn-secondary" (click)="cercaPaginaAnagrafica()">
            Cerca
          </button>
        </div>
      </div>
    </div>
  </div>


  <!-- Risultati -->
  <div class="card mt-5">
    <div class="card-title text-info" style="padding-top: 5px; font-size: medium; text-align: center">La tabella mostra gli
      assistiti che hanno o hanno avuto contatti che presentano le caratteristiche selezionate
      attraverso i filtri di ricerca.
    </div>
    <div class="card-body">
      <div>
        <div style="width: 100%; display: flex; justify-content: center; margin-bottom: 25px" *ngIf="pagina_2">
          <span *ngIf="this.pagina.size*(this.pagina.number)>0" (click)="precedentePagina()" style="cursor: pointer">
            <svg class="s-icon icon-xxs text-primary">
              <use xlink:href="assets/icons/sprite.svg#drop-left"></use>
            </svg> Pagina precedente
          </span>
                <span *ngIf="this.pagina.size*(this.pagina.number)==0" style="width:150px">
            &nbsp;
          </span>
                <div style="overflow: auto; margin-left: 25px; margin-right: 25px">
                  <span (click)="go2page(j)" style="margin-left: 10px; cursor: pointer" [class]="{'text':this.pagina.number==j, 'text-info':this.pagina.number==j, 'text-primary':this.pagina.number!=j}"
                        *ngFor="let i of Arr(this.pagina.number+1).fill(1); index as j">{{ j + 1 }}</span>
                </div>
                <span *ngIf="pagina_2&&pagina_2.length>=this.pagina.size" (click)="prossimaPagina()" style="cursor: pointer">
            Pagina successiva <svg class="s-icon icon-xxs text-primary">
              <use xlink:href="assets/icons/sprite.svg#drop-right"></use>
            </svg>
          </span>
        </div>
      </div>
      <div class="table-responsive">
        <div class="list-table table-even">
          <div class="text centered "
               style="font-size: 110%; text-align: center; padding: 20px; border-color: lightgreen !important;"
               *ngIf="showFilterMessage">Per effettuare una ricerca imposta almeno un filtro <span style="font-weight: bold">CODICE FISCALE</span>, <span
            style="font-weight: bold">STRUTTURA</span>,<span style="font-weight: bold">NOME</span> o <span style="font-weight: bold">COGNOME</span> o <span style="font-weight: bold">IDANA</span>..
          </div>
          <!-- SE STRUTTURA-->
          <table class="table-lg" *ngIf="!showFilterMessage && getRuolo(OPERATORE_STRUTTURA)">
            <thead (changeOrder)="changeOrder($event)">
            <tr>
              <th>Alert</th>
              <th>IDANA</th>
              <th>Codice Fiscale</th>
              <th>ASUR</th>
              <th>Cognome</th>
              <th>Nome</th>
              <!--th>
                ASL<br>residenza
              </th-->
              <th>
                Attualmente <br>ha un contatto attivo
              </th>
              <th *ngIf="(getDescrTipoStruttura() == 'semiresidenziale')">
                Data Ult.<br> Presenza&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </th>
              <th>Data Ult .<br> Prestazione&nbsp;&nbsp;&nbsp;</th>
              <th>
                Tipo ultima prestazione
              </th>
            </tr>
            </thead>

            <tbody>
            <tr *ngIf="pagina.loading">
              <td [attr.colspan]="theadColumns.length">
                <div class="spinner-border centered"></div>
              </td>
            </tr>
            <tr *ngIf="!pagina.loading && pagina_2 && pagina_2.length<1">
              <td [attr.colspan]="theadColumns.length">
                <div style="text-align: center">Non ci sono risultati per la ricerca effettuata</div>
              </td>
            </tr>
            <ng-container *ngIf="!pagina.loading">
              <tr *ngFor="let anag of pagina_2" class="tr-hover" (click)="selectAnagrafica(anag)">
                <td class="td-hover" style="text-align: center">
                  <div><img *ngIf="anag.alert" src="assets/icons/warning.png"/></div>
                </td>
                <td class="td-hover">{{ anag.idAnagrafica }}</td>
                <td class="td-hover">{{ anag.codiceFiscale }}</td>
                <td class="td-hover">{{ anag.cfCertificato }}</td>
                <td class="td-hover">{{ anag.cognome }}</td>
                <td class="td-hover">{{ anag.nome }}</td>
                <!--td class="td-hover">{{anag.aslResidenza?anag.aslResidenza:'Fuori regione'}}</td-->
                <td class="td-hover">
                  <span *ngIf="!anag.contattoAttivo && !anag.contattoAttivoExtra ">No</span>
                  <span *ngIf="anag.contattoAttivo ">Si, nella struttura</span>
                  <span *ngIf="anag.contattoAttivoExtra && !anag.contattoAttivo">Si, <br>in altra struttura <br>del territorio regionale</span>
                </td>
                <td
                  *ngIf="(getRuolo(OPERATORE_STRUTTURA) &&  getDescrTipoStruttura() == 'semiresidenziale') "
                  class="td-hover">{{ anag.dataUltimaPresenza| date:'yyyy-MM-dd' }}
                </td>

                <td class="td-hover">{{ anag.dataUltimaPrestazione| date:'yyyy-MM-dd' }}</td>
                <td class="td-hover">{{ anag.tipoUltimaPrestazione }}</td>
              </tr>
            </ng-container>
            </tbody>
          </table>

          <!-- SE ASL DSM -->
          <table class="table-lg" *ngIf="!showFilterMessage && (getRuolo(OPERATORE_ASL)||getRuolo(OPERATORE_DSM))">
            <thead (changeOrder)="changeOrder($event)">
            <tr>
              <th>Alert</th>
              <th>IDANA</th>
              <th>Codice Fiscale</th>
              <th>ASUR</th>
              <th>Cognome</th>
              <th>Nome</th>
              <!--th>Residente</th-->
              <th>
                Struttura<br>
                che ha erogato<br>
                l'ultima prestazione
              </th>
              <th>
                Ha un contatto<br>
                attivo<br>
                territoriale
              </th>
              <th>Data Ult .<br> Prestazione&nbsp;&nbsp;&nbsp;</th>
              <th>
                Ha un contatto<br>
                attivo<br>
                semiresidenziale
              </th>
              <th>
                Data Ult.<br> Presenza&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </th>
            </tr>
            </thead>

            <tbody>
            <tr *ngIf="pagina.loading">
              <td [attr.colspan]="theadColumns.length">
                <div class="spinner-border centered"></div>
              </td>
            </tr>
            <tr *ngIf="!pagina.loading && pagina_2 && pagina_2.length<1">
              <td [attr.colspan]="theadColumns.length">
                <div style="text-align: center">Non ci sono risultati per la ricerca effettuata</div>
              </td>
            </tr>
            <ng-container *ngIf="!pagina.loading">
              <tr *ngFor="let anag of pagina_2" class="tr-hover" (click)="selectAnagrafica(anag)">
                <td class="td-hover" style="text-align: center">
                  <div><img *ngIf="anag.alert" src="assets/icons/warning.png"/></div>
                </td>
                <td class="td-hover">{{ anag.idAnagrafica }}</td>
                <td class="td-hover">{{ anag.codiceFiscale }}</td>
                <td class="td-hover">{{ anag.cfCertificato }}</td>
                <td class="td-hover">{{ anag.cognome }}</td>
                <td class="td-hover">{{ anag.nome }}</td>
                <!--td class="td-hover">
                  <span *ngIf="anag.codiceAslResidenza == utente.codiceAsl">Nella ASL</span>
                  <span *ngIf="anag.codiceAslResidenza != utente.codiceAsl">Altrove</span>
                </td-->
                <td class="td-hover">
                  <span
                    *ngIf="anag.ultimaStrutturaPrestazione!= null && anag.ultimaStrutturaPrestazione!= undefined && (anag.codiceAslResidenza== utente.codiceAsl || anag.ultimaAslPrestazione==utente.codiceAsl)">{{ anag.ultimaStrutturaPrestazione }}
                    - {{ anag.ultimaStrutturaPrestazioneDesc }} -
                    Asl {{ anag.ultimaAslPrestazioneDesc }}</span>
                  <span *ngIf="anag.ultimaAslPrestazione == undefined" class="td-hover">
                </span>
                  <span
                    *ngIf="anag.ultimaStrutturaPrestazione!= null && anag.ultimaStrutturaPrestazione!= undefined && (anag.codiceAslResidenza!= utente.codiceAsl && anag.ultimaAslPrestazione!=utente.codiceAsl)"
                    class="td-hover">
                  Fuori dal territorio della ASL
                </span>
                </td>

                <td class="td-hover">
                  <!--span *ngIf="anag.ultimaAslAttiva == undefined ">No</span>
                  <span
                    *ngIf="anag.lastAslActiveCodeTerr == anag.codiceAslResidenza && anag.tipoUltimaPrestazioneTerr && anag.tipoUltimaPrestazioneTerr.toLowerCase() == 'territoriale'">Sì, in struttura della ASL;</span>
                  <span
                    *ngIf="anag.lastAslActiveCodeTerr != undefined && anag.lastAslActiveCodeTerr != anag.codiceAslResidenza && anag.tipoUltimaPrestazioneTerr && anag.tipoUltimaPrestazioneTerr.toLowerCase() == 'territoriale' ">Sì, in struttura di altra ASL;</span
                  <span *ngIf="anag.haUnContattoAttivoTerritoriale == undefined || anag.haUnContattoAttivoTerritoriale == 0">No</span>
                  <span *ngIf="anag.haUnContattoAttivoTerritoriale && anag.haUnContattoAttivoTerritoriale == 1 ">Si, uno</span>
                  <span *ngIf="anag.haUnContattoAttivoTerritoriale && anag.haUnContattoAttivoTerritoriale > 1 ">Si, più di uno</span>-->
                  <span *ngIf="anag.haUnContattoAttivoTerritoriale == 0 && anag.haUnContattoAttivoTerritorialeExtra == 0">No</span>
                  <span *ngIf="anag.haUnContattoAttivoTerritoriale != 0  && anag.haUnContattoAttivoTerritorialeExtra == 0 ">Si, nella Asl</span>
                  <span *ngIf="anag.haUnContattoAttivoTerritoriale == 0 && anag.haUnContattoAttivoTerritorialeExtra != 0  ">Si, in un'altra Asl</span>
                </td>
                <td class="td-hover">{{ anag.dataUltimaPrestazione| date:'yyyy-MM-dd' }}</td>

                <td class="td-hover">
                  <!--                  <span *ngIf="anag.haUnContattoAttivoSemiresidenziale == undefined || anag.haUnContattoAttivoSemiresidenziale == 0 ">No</span>-->
                  <!--                  <span *ngIf="anag.haUnContattoAttivoSemiresidenziale && anag.haUnContattoAttivoSemiresidenziale == 1 ">Si, uno</span>-->
                  <!--                  <span *ngIf="anag.haUnContattoAttivoSemiresidenziale && anag.haUnContattoAttivoSemiresidenziale > 1 ">Si, più di uno</span>-->
                  <span *ngIf="anag.haUnContattoAttivoSemiresidenziale == 0 && anag.haUnContattoAttivoSemiresidenzialeExtra == 0">No</span>
                  <span *ngIf="anag.haUnContattoAttivoSemiresidenziale != 0  && anag.haUnContattoAttivoSemiresidenzialeExtra == 0 ">Si, nella Asl</span>
                  <span *ngIf="anag.haUnContattoAttivoSemiresidenziale == 0 && anag.haUnContattoAttivoSemiresidenzialeExtra != 0  ">Si, in un'altra Asl</span>
                  <!--span *ngIf="anag.ultimaAslAttiva == undefined ">No</span>
                  <span
                    *ngIf="anag.lastAslActiveCodeSemi == anag.codiceAslResidenza && anag.tipoUltimaPrestazioneSemi && anag.tipoUltimaPrestazioneSemi.toLowerCase() == 'semiresidenziale'">Sì, in struttura della ASL;</span>
                  <span
                    *ngIf="anag.lastAslActiveCodeSemi != undefined && anag.lastAslActiveCodeSemi != anag.codiceAslResidenza && anag.tipoUltimaPrestazioneSemi.toLowerCase() == 'semiresidenziale' ">Sì, in struttura di altra ASL;</span-->
                </td>
                <td class="td-hover">{{ anag.dataUltimaPresenza| date:'yyyy-MM-dd' }}</td>
              </tr>
            </ng-container>
            </tbody>
          </table>
          <!-- SE REGIONE -->
          <table class="table-lg" *ngIf="!showFilterMessage && (getRuolo(OPERATORE_REGIONE))">
            <thead (changeOrder)="changeOrder($event)">
            <tr>
              <th>Alert</th>
              <th>IDANA</th>
              <th>Codice Fiscale</th>
              <th>ASUR</th>
              <th>Cognome</th>
              <th>Nome</th>
              <!--th>ASL<br>residenza</th-->
              <th>
                Struttura<br>
                che ha erogato<br>
                l'ultima prestazione
              </th>
              <th>
                Ha un contatto<br>
                attivo<br>
                territoriale
              </th>
              <th>Data Ult .<br> Prestazione&nbsp;&nbsp;&nbsp;</th>
              <th>
                Ha un contatto<br>
                attivo<br>
                semiresidenziale
              </th>
              <th>
                Data Ult.<br> Presenza&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="pagina.loading">
              <td [attr.colspan]="theadColumns.length">
                <div class="spinner-border centered"></div>
              </td>
            </tr>
            <tr *ngIf="!pagina.loading && pagina_2 && pagina_2.length<1">
              <td [attr.colspan]="theadColumns.length">
                <div style="text-align: center">Non ci sono risultati per la ricerca effettuata</div>
              </td>
            </tr>
            <ng-container *ngIf="!pagina.loading">
              <tr *ngFor="let anag of pagina_2" class="tr-hover" (click)="selectAnagrafica(anag)">
                <td class="td-hover" style="text-align: center">
                  <div><img *ngIf="anag.alert" src="assets/icons/warning.png"/></div>
                </td>
                <td class="td-hover">{{ anag.idAnagrafica }}</td>
                <td class="td-hover">{{ anag.codiceFiscale }}</td>
                <td class="td-hover">{{ anag.cfCertificato }}</td>
                <td class="td-hover">{{ anag.cognome }}</td>
                <td class="td-hover">{{ anag.nome }}</td>
                <!--td class="td-hover">{{anag.aslResidenza?anag.aslResidenza:'Fuori regione'}}</td-->
                <td class="td-hover">
                  <span *ngIf="anag.ultimaAslPrestazione != undefined  && anag.ultimaStrutturaPrestazione!= null && anag.ultimaStrutturaPrestazione!= undefined">{{ anag.ultimaStrutturaPrestazione }}
                    - {{ anag.ultimaStrutturaPrestazioneDesc }} -
                    Asl {{ anag.ultimaAslPrestazioneDesc }}</span>
                </td>
                <td class="td-hover">
                  <!--span *ngIf="anag.ultimaAslAttiva == undefined ">No</span>
                  <span
                    *ngIf="anag.lastAslActiveCodeTerr == anag.codiceAslResidenza && anag.tipoUltimaPrestazioneTerr && anag.tipoUltimaPrestazioneTerr.toLowerCase() == 'territoriale'">Sì, in struttura della ASL;</span>
                  <span
                    *ngIf="anag.lastAslActiveCodeTerr != undefined && anag.lastAslActiveCodeTerr != anag.codiceAslResidenza && anag.tipoUltimaPrestazioneTerr && anag.tipoUltimaPrestazioneTerr.toLowerCase() == 'territoriale' ">Sì, in struttura di altra ASL;</span-->
                  <span *ngIf="anag.haUnContattoAttivoTerritoriale == undefined || anag.haUnContattoAttivoTerritoriale == 0">No</span>
                  <span *ngIf="anag.haUnContattoAttivoTerritoriale && anag.haUnContattoAttivoTerritoriale == 1 ">Si, uno</span>
                  <span *ngIf="anag.haUnContattoAttivoTerritoriale && anag.haUnContattoAttivoTerritoriale > 1 ">Si, più di uno</span>
                </td>
                <td class="td-hover">{{ anag.dataUltimaPrestazione| date:'yyyy-MM-dd' }}</td>
                <td class="td-hover">
                  <span *ngIf="anag.haUnContattoAttivoSemiresidenziale == undefined || anag.haUnContattoAttivoSemiresidenziale == 0 ">No</span>
                  <span *ngIf="anag.haUnContattoAttivoSemiresidenziale && anag.haUnContattoAttivoSemiresidenziale == 1 ">Si, uno</span>
                  <span *ngIf="anag.haUnContattoAttivoSemiresidenziale && anag.haUnContattoAttivoSemiresidenziale > 1 ">Si, più di uno</span>
                  <!--span *ngIf="anag.ultimaAslAttiva == undefined ">No</span>
                  <span
                    *ngIf="anag.lastAslActiveCodeSemi == anag.codiceAslResidenza && anag.tipoUltimaPrestazioneSemi && anag.tipoUltimaPrestazioneSemi.toLowerCase() == 'semiresidenziale'">Sì, in struttura della ASL;</span>
                  <span
                    *ngIf="anag.lastAslActiveCodeSemi != undefined && anag.lastAslActiveCodeSemi != anag.codiceAslResidenza && anag.tipoUltimaPrestazioneSemi.toLowerCase() == 'semiresidenziale' ">Sì, in struttura di altra ASL;</span-->
                </td>
                <td class="td-hover">{{ anag.dataUltimaPresenza| date:'yyyy-MM-dd' }}</td>
              </tr>
            </ng-container>
            </tbody>
          </table>
        </div>

      </div>


      <div style="width: 100%; text-align: center">

        <div>
          <div style="width: 100%; display: flex; justify-content: center; margin-top: 25px" *ngIf="pagina_2">
            <span *ngIf="this.pagina.size*(this.pagina.number)>0" (click)="precedentePagina()" style="cursor: pointer">
              <svg class="s-icon icon-xxs text-primary">
                <use xlink:href="assets/icons/sprite.svg#drop-left"></use>
              </svg> Pagina precedente
            </span>
            <span *ngIf="this.pagina.size*(this.pagina.number)==0" style="width:150px">
               &nbsp;
            </span>
            <div style="overflow: auto; margin-left: 25px; margin-right: 25px">
            <span (click)="go2page(j)" style="margin-left: 10px; cursor: pointer" [class]="{'text':this.pagina.number==j, 'text-info':this.pagina.number==j, 'text-primary':this.pagina.number!=j}"
                  *ngFor="let i of Arr(this.pagina.number+1).fill(1); index as j">{{ j + 1 }}</span>
            </div>
            <span *ngIf="pagina_2&&pagina_2.length>=this.pagina.size" (click)="prossimaPagina()" style="cursor: pointer">
                Pagina successiva <svg class="s-icon icon-xxs text-primary">
                  <use xlink:href="assets/icons/sprite.svg#drop-right"></use>
                    </svg>
            </span>
          </div>
        </div>
        <div style="width: 100%; display: flex; justify-content: end">
          <select name="pageSize" class="form-control form-pagination ng-untouched ng-pristine ng-valid disabled"
                  style="width: auto;" demon-list="flussi.rows"
                  [(ngModel)]="this.pagina.size"
                  (change)="cambioDimensionePaginaSelezionata($event)">
            <option value="{{10}}">10 per pagina</option>
            <option value="{{20}}">20 per pagina</option>
            <option value="{{50}}">50 per pagina</option>
            <option value="{{100}}">100 per pagina</option>
          </select></div>
      </div>
      <!-- PAGINATION -->
      <!--app-paginator [page]="pagina" (cambiaPaginaEvent)="cambiaPagina($event)"></app-paginator-->

    </div>
  </div>


  <!-- Toolbar -->
  <div class="btn-fixed fixed" demon-cf="toolbarFixed" demon-ev="true" demon-cf-id="3">
    <div class="btn-toolbar">
      <div class="container">
        <div class="btn-group-left">

        </div>
        <div class="btn-group-center">
          <button type="button" class="btn btn-outline-secondary" (click)="open(mymodal)"
                  *ngIf="aggiungiNuovaAnagraficaVisibile()">
            Importa XML
          </button>
        </div>
        <div class="btn-group-right">

          <button *ngIf="aggiungiNuovaAnagraficaVisibile()" type="button" class="btn btn-secondary"
                  (click)="aggiungiNuovaAnagrafica()">
            Aggiungi nuovo contatto
          </button>

        </div>
      </div>
    </div>
  </div>

</div>

<ng-template #mymodal let-modal>
  <div class="modal-header bg-primary text-white">
    <h2 class="modal-title" id="modal-basic-title-3">Importazione XML
    </h2>
    <a class="close" aria-label="Close" (click)="modal.dismiss('Cross click');">
      <svg class="s-icon icon-sm">
        <use href="#close"></use>
      </svg>
    </a>
  </div>
  <div class="modal-body">

    <form>

      <div class="container text-center">

        <!-- ANAGRAFICA FILE -->
        <div class="row mt-4 mb-4">
          <div class="col-12">
            <label for="AnagraficaInput" class="form-label">Anagrafica xml file</label>
          </div>
          <div class="col-8">
            <input class="form-control" type="file" id="AnagraficaInput" (change)="onAnagraficaFileSelect($event)">
          </div>
          <div class="col-4">
            <button type="button" class="btn btn-secondary" title="Download Anagrafica.xsd"
                    (click)="downloadXsd('Anagrafica')">Download XSD
            </button>
          </div>
        </div>

        <!-- CONTATTO FILE -->
        <div class="row mb-4">
          <div class="col-12">
            <label for="ContattoInput" class="form-label">Contatto xml file</label>
          </div>
          <div class="col-8">
            <input class="form-control" type="file" id="ContattoInput" (change)="onContattoFileSelect($event)">
          </div>
          <div class="col-4">
            <button *ngIf="isStrutturaTerritoriale()" type="button" class="btn btn-secondary"
                    title="Download TerritorialeContatto.xsd" (click)="downloadXsd('TerritorialeContatto')">Download XSD
            </button>
            <button *ngIf="!isStrutturaTerritoriale()" type="button" class="btn btn-secondary"
                    title="Download SemiresidenzialeContatto.xsd" (click)="downloadXsd('SemiresidenzialeContatto')">
              Download XSD
            </button>
          </div>
        </div>

        <!-- PRESENZA FILE -->
        <div class="row mb-4" *ngIf="!isStrutturaTerritoriale()">
          <div class="col-12">
            <label for="presenzaInput" class="form-label">Presenza xml file</label>
          </div>
          <div class="col-8">
            <input class="form-control" type="file" id="presenzaInput" (change)="onPresenzaFileSelect($event)">
          </div>
          <div class="col-4">
            <button type="button" class="btn btn-secondary" title="Download SemiresidenzialePresenza.xsd"
                    (click)="downloadXsd('SemiresidenzialePresenza')">Download XSD
            </button>
          </div>
        </div>

        <!-- INTERVENTO FILE -->
        <div class="row mb-4" *ngIf="isStrutturaTerritoriale()">
          <div class="col-12">
            <label for="InterventoInput" class="form-label">Intervento xml file</label>
          </div>
          <div class="col-8">
            <input class="form-control" type="file" id="InterventoInput" (change)="onInterventoFileSelect($event)">
          </div>
          <div class="col-4">
            <button type="button" class="btn btn-secondary" title="Download TerritorialeIntervento.xsd"
                    (click)="downloadXsd('TerritorialeIntervento')">Download XSD
            </button>
          </div>
        </div>

        <!-- DIAGNOSI FILE -->
        <div class="row mb-4" *ngIf="isStrutturaTerritoriale()">
          <div class="col-12">
            <label for="DiagnosiInput" class="form-label">Diagnosi xml file</label>
          </div>
          <div class="col-8">
            <input class="form-control" type="file" id="DiagnosiInput" (change)="onDiagnosiFileSelect($event)">
          </div>
          <div class="col-4">
            <button type="button" class="btn btn-secondary" title="Download TerritorialeDiagnosi.xsd"
                    (click)="downloadXsd('TerritorialeDiagnosi')">Download XSD
            </button>
          </div>
        </div>

        <!-- PRESTAZIONE FILE -->
        <div class="row mb-4">
          <div class="col-12">
            <label for="PrestazioneInput" class="form-label">Prestazione xml file</label>
          </div>
          <div class="col-8">
            <input class="form-control" type="file" id="PrestazioneInput" (change)="onPrestazioneFileSelect($event)">
          </div>
          <div class="col-4">
            <button *ngIf="!isStrutturaTerritoriale()" type="button" class="btn btn-secondary"
                    title="Download SemiresidenzialePrestazione.xsd"
                    (click)="downloadXsd('SemiresidenzialePrestazione')">Download XSD
            </button>
            <button *ngIf="isStrutturaTerritoriale()" type="button" class="btn btn-secondary"
                    title="Download TerritorialePrestazione.xsd" (click)="downloadXsd('TerritorialePrestazione')">
              Download XSD
            </button>
          </div>
        </div>
      </div>

    </form>


  </div>


  <div class="modal-footer">
    <div class="mr-auto">
      <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('Cross click');">Chiudi</button>
    </div>
    <div class="ml-auto">
      <button type="button" class="btn btn-secondary" [disabled]="!hasFileToUpload()" (click)="upload()">Importa
      </button>
    </div>
  </div>
</ng-template>

<ng-template #uploadTabellaModal let-modal>
  <div class="modal-header bg-primary text-white">
    <h2 class="modal-title" id="modal-basic-title">Importazione XML
    </h2>
    <a class="close" aria-label="Close" (click)="modal.dismiss('Cross click');">
      <svg class="s-icon icon-sm">
        <use href="#close"></use>
      </svg>
    </a>
  </div>
  <div class="modal-body">
    <div class="container">
      <table class="table table-bordered border-primary">
        <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Record elaborati</th>
          <th scope="col">Record errati</th>
          <th scope="col">Record totali</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let ana of uploadAnagraficeDto?.anagraficheDto">
          <th scope="row">Anagrafica</th>
          <td>{{ ana.caricati }}</td>
          <td>{{ ana.errati }}</td>
          <td>{{ ana.caricati + ana.errati }}</td>
        </tr>
        <tr *ngFor="let con of uploadAnagraficeDto?.contattiDto">
          <th scope="row">Contatti</th>
          <td>{{ con.caricati }}</td>
          <td>{{ con.errati }}</td>
          <td>{{ con.errati + con.caricati }}</td>
        </tr>

        <tr *ngFor="let pr of uploadAnagraficeDto?.presenzeDto">
          <th scope="row">Presenze</th>
          <td>{{ pr.caricati }}</td>
          <td>{{ pr.errati }}</td>
          <td>{{ pr.errati + pr.caricati }}</td>
        </tr>
        <tr *ngFor="let pr of uploadAnagraficeDto?.prestazioniDto">
          <th scope="row">Prestazioni</th>
          <td>{{ pr.caricati }}</td>
          <td>{{ pr.errati }}</td>
          <td>{{ pr.errati + pr.caricati }}</td>
        </tr>

        <tr *ngFor="let dg of uploadAnagraficeDto?.diagnosiDto">
          <th scope="row">Diagnosi</th>
          <td>{{ dg.caricati }}</td>
          <td>{{ dg.errati }}</td>
          <td>{{ dg.errati + dg.caricati }}</td>
        </tr>

        <tr *ngFor="let intr of uploadAnagraficeDto?.interventiDto">
          <th scope="row">Interventi</th>
          <td>{{ intr.caricati }}</td>
          <td>{{ intr.errati }}</td>
          <td>{{ intr.errati + intr.caricati }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="modal-footer">
    <div class="mr-auto">
      <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('Cross click');">Chiudi</button>
    </div>
  </div>
</ng-template>

<block-ui>

</block-ui>
