import {
  AfterViewInit,
  Component,
  ElementRef, EventEmitter,
  HostListener,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {SessionService} from "../../services/session.service";
import {Router} from "@angular/router";
import {Notifica} from "../../model/Notifica";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {KeycloakService} from 'keycloak-angular';

import {ApiService} from '../../services/api.service';

import {StrutturaStr, Utente} from 'src/app/model/Models';
import {Funzionalita} from "../../model/Funzionalita";
import {firstValueFrom, Observable} from 'rxjs';
import Keycloak, {KeycloakProfile} from 'keycloak-js';
import {ToastService} from "../../services/toast.service";
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import packageInfo from '../../../../package.json';
import {CommonsUtil} from "../../util/CommonsUtil";
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss',

  ]
})
export class HeaderComponent implements OnInit, AfterViewInit {
  innerWidth: number = window.innerWidth;
  @ViewChild('navbarCollapsingElementsButton') navbarCollapsingElementsButton?: ElementRef;
  @ViewChild('navbarCollapsingElements') navbarCollapsingElements?: ElementRef;

  public version: string = packageInfo.version;

  @Output() userLoaded = new EventEmitter<boolean>();
  dialogOpen = false
  username = '';
  denominazione = '';
  shortname = '';
  notifiche: Notifica[] = [];
  notifiche2read: Notifica[] = [];
  notifichetotali: number = 0;
  pagesize: number = 5;
  page: number = 1;
  utente?: Utente

  userProfile!: KeycloakProfile

  listPermission: string[] = []

  listStrutture: StrutturaStr[] = []

  strutturaName = ''

  selectedRole: number = 0;

  @ViewChild('selectStrutturaModal', {static: true}) selectStrutturaModal: any;
  @ViewChild('selectRoleModal', {static: true}) selectRoleModal: any;


  constructor(
    private toastService: ToastService,
    private sessionService: SessionService,
    private router: Router,
    private modalService: NgbModal,
    private keycloakService: KeycloakService,
    private apiService: ApiService,
    private sanitizer: DomSanitizer,
    private http: HttpClient,) {

    /*const k = keycloakService.getKeycloakInstance();
    k.createLogoutUrl = function(options) {
      return k.endpoints.logout()
        + '?id_token_hint=' + k.idToken
        + '&post_logout_redirect_uri=' + encodeURIComponent(window.location.href)
        + '&state=' + 'pppp2';
    }*/
  }

  ngOnInit(): void {
    this.loadUser()
    this.getUtente()
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 992 && this.navbarCollapsingElementsButton) {
      if (this.navbarCollapsingElements?.nativeElement.classList.contains("show")) {
        let event = new MouseEvent('click', {bubbles: true});
        this.navbarCollapsingElementsButton.nativeElement.dispatchEvent(event);
      }
    }
  }

  async logout() {
    //this.keycloakService.clearToken();
    sessionStorage.clear();
    //await this.keycloakService.logout();
  }


  ngAfterViewInit(): void {
    this.initNotifiche();
    this.initSession();
  }

  initSession() {

  }

  initNotifiche() {
    /* this.appService.getNotification().subscribe(value => {
       this.notifiche = value;
       this.notifichetotali = this.notifiche.length;
       this.notifiche2read = this.notifiche.filter(value1 => value1.sent==false);
     });*/
  }

  apriModal(notification: TemplateRef<any>) {
    this.dialogOpen = true
    this.modalService.open(notification, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
    }).result.then(async (result) => {
      // await this.appService.markAllNotificationRead().toPromise();
      this.initNotifiche();
    }, (reason) => {
    });
  }

  closeModal() {
    this.modalService.dismissAll()
    this.dialogOpen = false
  }

  getProfileLink() {
    return null;//environment.keycloak.issuer+'realms/'+environment.keycloak.realm+'/account';
  }

  getNotifiche2See() {
    return this.notifiche.slice((this.page * this.pagesize) - this.pagesize, (this.page * this.pagesize));
  }

  async elimina(id: number | undefined) {
    //if(id)await this.appService.markNotificationRead(id).toPromise();
    //await this.appService.markAllNotificationRead().toPromise();
    this.initNotifiche();
  }

  async segnaComeLetta(id: number | undefined) {
    //if(id)await this.appService.markNotificationSingleRead(id).toPromise();
    //await this.appService.markAllNotificationRead().toPromise();
    this.initNotifiche();
  }

  public safeLogout: SafeResourceUrl = "";

  logOut() {

    /*window.open('https://qiam.regione.lazio.it:443/oidc/logout' + '?id_token_hint=' + this.keycloakService.getKeycloakInstance().idToken
      + '&post_logout_redirect_uri=' + encodeURIComponent(window.location.href)
      + '&state=' + "qqsssqq")
*/
  //window.open('https://qiam.regione.lazio.it:443/oidc/logout');
    this.sessionService.clearUtenteFromStorage()
    this.sessionService.clearSelectedStrutturaFromStorage()


    /*const idt = this.keycloakService.getKeycloakInstance().idToken;
    console.log(idt);
    console.log("---------------------");
    const urllo = environment.logoutIAM+"?id_token_hint="+idt;
    console.log(urllo);
    console.log("---------------------");*/
    //this.keycloakService.getKeycloakInstance().logout().then(value => alert('logged out'));
    //this.keycloakService.logout(environment.logoutIAM&&idt?urllo:undefined);
    //this.keycloakService.logout(environment.logoutIAM&&idt?environment.logoutIAM:undefined);
    //this.keycloakService.clearToken();
    this.sessionService.clearSession();
    this.keycloakService.logout().then(value => {
      this.keycloakService.clearToken();
      this.logoutKnowage();
    });
  }

  logoutKnowage(){
    let logoutUrl = this.apiService.knowageLogout()
   try{
     this.safeLogout = this.getSafeUrl(logoutUrl);
   }catch (e) {
     console.log(e);
   }
  }

  public getSafeUrl(link: string) {

    return this.sanitizer.bypassSecurityTrustResourceUrl(link)

  }

  getIdFromToken(token: any) {

    var id: string = ''


    Object.entries(token).forEach(([key, value]) => {

      if (key == "sub") {
        id = JSON.stringify(value)
      }
    });

    return id
  }


  async getUtente() {

    let tokenInfo = await this.keycloakService.getToken()


    let tokenObj = JSON.parse(atob(tokenInfo.split('.')[1]))

    // console.log(tokenObj);


    let id = this.getIdFromToken(tokenObj)


    // console.log("id ", id);

  }

  loadUser() {
    if (!this.utente || !this.utente.nomeRuolo) {
      this.apiService.getUtente().subscribe(res =>
        this.caricaUserInSessione(res))
    } else {
      const r = this.utente.ruoli!.find(value => value.nome == this.utente?.nomeRuolo);
      if (r) {
        this.apiService.getUtenteByRole(r.id).subscribe(res => this.caricaUserInSessione(res))
      }

    }
  }

  getUtenteCharacters(utente: Utente): string {

    if (utente != null) {

      return utente.nome!!.substring(0, 1).toLocaleUpperCase() + " " + utente.cognome!!.substring(0, 1).toLocaleUpperCase()

    } else {

      return ""
    }


  }

  userHasStrutture(utente: Utente) {

    return utente.codiciStrutture?.length != 0


  }

  loadStrutture(utente: Utente): Observable<StrutturaStr[]> {


    return this.apiService.getStrutture(utente.codiciStrutture!!)
  }

  dashboardVisibile() {
    let utente = this.sessionService.getUtenteFromStorage();
    return utente?.funzionalita.includes(Funzionalita.DASHBOARD_VISUALIZZAZIONE);
  }

  gestionePazientiVisibile() {
    let utente = this.sessionService.getUtenteFromStorage();
    let res =
      utente?.funzionalita.includes(Funzionalita.ANAGRAFICHE_VISUALIZZAZIONE) ||
      utente?.funzionalita.includes(Funzionalita.ANAGRAFICHE_INSERIMENTO);
    return res;
  }


  elencoPosizioniVisibile() {
    let utente = this.sessionService.getUtenteFromStorage();
    return utente?.funzionalita.includes(Funzionalita.ELENCO_POSIZIONI_VISUALIZZAZIONE);
  }

  flussiInviatiVisibile() {
    let utente = this.sessionService.getUtenteFromStorage();
    return utente?.funzionalita.includes(Funzionalita.FLUSSI_INVIATI_VISUALIZZAZIONE);
  }

  // monitoraggioVisibile() {
  //   return this.elencoPosizioniVisibile() || this.flussiInviatiVisibile();
  // }
  isOperatoreStruttura() {
    let utente = this.sessionService.getUtenteFromStorage();
    return utente?.nomeRuolo?.includes("operatore-struttura");
  }

  isOperatoreAsl() {
    let utente = this.sessionService.getUtenteFromStorage();
    return utente?.nomeRuolo?.includes("operatore-asl");
  }


  isOperatoreAmministratore() {
    let utente = this.sessionService.getUtenteFromStorage();
    return utente?.nomeRuolo?.includes("amministratore-regione");
  }
  monitoraggioVisibile() {
    return this.isOperatoreAsl();// || this.isOperatoreRegione();
  }


  strutturaIsSelected() {

    if (this.sessionService.getSelectedStrutturaFromStorage() != null || undefined) {
      return true
    } else {
      return false
    }
  }

  utenteIsMultiStruttura(utente: Utente) {


    if (utente.funzionalita.includes(Funzionalita.SELEZIONA_STRUTTURA)) {

      return true
    } else {

      return false
    }

  }

  utenteIsMultiRuolo(utente: Utente) {


    if (utente.ruoli!.length > 1) {

      return true
    } else {

      return false
    }

  }

  openSelectStrutturaModal() {

    this.modalService.open(this.selectStrutturaModal, {backdrop: 'static', keyboard: false});
  }

  selectStruttura(event: any) {
    let sts: StrutturaStr = this.getStrutturaFromCodice(event.target.value)
    this.sessionService.setSelectedStrutturaOnStorage(sts)
    this.sessionService.notifyStrutturaChanged()
    this.router.navigate(['dashboard'])
    this.modalService.dismissAll()
    this.userLoaded.emit(true)

  }

  async selectRuolo(event: any) {
    const u: Utente = await firstValueFrom(this.apiService.getUtenteByRole(event.target.value));
    this.selectedRole = event.target.value;
    if (u) {
      this.caricaUserInSessione(u);
      this.sessionService.setSelectedRuoloOnStorage(this.selectedRole);
      this.sessionService.notifyStrutturaChanged()
      this.router.navigate(['dashboard'])
      this.modalService.dismissAll()
      this.sessionService.clearSelectedStrutturaFromStorage();
      this.userLoaded.emit((this.selectedRole!=4)||this.strutturaIsSelected())

    }
  }

  getStrutturaFromCodice(codice: string): StrutturaStr {

    return this.listStrutture.filter(str => str.codice == codice)[0]
  }

  cambiaStruttura() {

    this.modalService.open(this.selectStrutturaModal);

  }


  isSelected(codice: string): boolean {

    return this.sessionService.getSelectedStrutturaFromStorage()?.codice == codice

  }

  getDescrTipoStruttura() {
    if (this.sessionService.getSelectedStrutturaFromStorage() != null) {
      if (this.sessionService.isSelectedStrutturaFromStorageTerritoriale()) {
        return "(TERR.)";
      } else {
        return "(SEMIRES.)"
      }
      ;
    } else {
      return "";
    }
  }

  getStrutturaName() {
    if (this.sessionService.getSelectedStrutturaFromStorage() && this.sessionService.getSelectedStrutturaFromStorage()!.denominazione!.length > 35) {
      return this.sessionService.getSelectedStrutturaFromStorage()?.denominazione!.substring(0, 30) + '...';
    } else {
      return this.sessionService.getSelectedStrutturaFromStorage()?.denominazione;
    }
  }

  getFullStrutturaName() {
    return this.sessionService.getSelectedStrutturaFromStorage()?.denominazione;
  }

  setStrutturaOnOpen(listStrutture: StrutturaStr[]) {
    if (listStrutture.length > 1 && !this.strutturaIsSelected()) {
      this.openSelectStrutturaModal()
    } else if (listStrutture.length == 1 && !this.strutturaIsSelected()) {
      this.sessionService.setSelectedStrutturaOnStorage(listStrutture[0])
      this.userLoaded.emit(true)
    } else {
      this.userLoaded.emit(true)
    }
  }

  cambiaRuolo() {
    this.modalService.open(this.selectRoleModal);
    this.apiService.clearAllCache();

  }

  private caricaUserInSessione(res: Utente) {
    {
      this.utente = res
      // todo open modal selection if more than 1 struttura
      if (this.utenteIsMultiRuolo(this.utente) && this.selectedRole == 0) {
        this.cambiaRuolo();
      } else if (this.utenteIsMultiStruttura(this.utente) && !this.strutturaIsSelected()) {
        this.loadStrutture(this.utente).subscribe({
          next: (res) => {
            this.listStrutture = res
            this.setStrutturaOnOpen(this.listStrutture)
          },
          error: (err) => {
            console.log(err);
          }
        })
      } else {
        if (this.userHasStrutture(this.utente)) {
          this.loadStrutture(this.utente).subscribe({
            next: (res) => {
              if (res.length == 0) {
                this.toastService.showError('Errore generico');
              } else {
                this.listStrutture = res
                this.setStrutturaOnOpen(this.listStrutture)
              }
            },
            error: (err) => {
              console.log(err);
            }
          })
        } else if(this.utente.idRuolo==1||this.utente.idRuolo==2||this.utente.idRuolo==4||this.utente.idRuolo==5){
          this.selectedRole = this.utente.idRuolo;
          this.userLoaded.emit(true)
        }else{
          alert(this.selectedRole+" Attenzione sembra che non ti siano stati assegnati i ruoli necessari per utilizzare l'applicazione, contatta un amministratore per risolvere il problema.");
          this.logOut();
        }
      }
      this.sessionService.setUtenteProfile(JSON.stringify(this.utente))
      this.sessionService.setUtenteOnStorage(this.utente)
      if (!this.dashboardVisibile()) {
        this.router.navigate(['ricerca-anagrafica'])
      } else {
        this.router.navigate(['dashboard'])
      }
    }
  }

  isOperatoreRegione() {
    let utente = this.sessionService.getUtenteFromStorage();
    return utente?.nomeRuolo?.includes("operatore-regione");
  }
}
