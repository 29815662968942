<div class="container py-3" demon-cf="prestazione" demon-cf-id="prestazione" id="demonView6-7" demon-ev="true">



    <div class="card">
        <div class="card-header">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">

                    <li class="breadcrumb-item"><a (click)="$event.preventDefault();navigateToRicercaAnagrafica()"
                            href="">Gestione
                            pazienti</a></li>
                    <li class="breadcrumb-item"><a (click)="$event.preventDefault();navigateToAnagrafica()"
                            href="">{{getTitleName()}}</a></li>

                </ol>
            </nav>
            <div class="row">
                <div class="title-3 col-9" demon-prestazione="titolo">Diagnosi&scale #{{diagnosiScala.id!!}}</div>
                <div class="col-3">
                    <button class="btn btn-outline-secondary btn-sm" *ngIf="(getRuolo(OPERATORE_STRUTTURA) || getRuolo(OPERATORE_REGIONE)) && diagnosiScala?.id != null && diagnosiScala?.id != undefined" (click)="$event.preventDefault(); openAuditDialog(audit)" >
                        Storico modifiche
                      </button>
                </div>
            </div>

        </div>


        <div class="card-body">
          <!-- Stato e motivazione scarto -->
            <div class="group pt-4 alert alert-danger"  style="background-color: #ffe1cc !important;" *ngIf="diagnosiScala.id != null && (diagnosiScala.statoAttuale.id == 4 ||diagnosiScala.statoAttuale.id ==  7 ||diagnosiScala.statoAttuale.id ==  9)">

            <div class="form-group field-10">
              Stato: {{diagnosiScala.statoAttuale.descrizione}}
            </div>
            <div class="form-group field-10" *ngIf="diagnosiScala.motivazioneScarto != null && diagnosiScala.motivazioneScarto != ''">
              Motivazione dello scarto: {{diagnosiScala.motivazioneScarto}}
            </div>
          </div>


            <form [formGroup]="diagnosiScaleForm" (ngSubmit)="submitForm(diagnosiScaleForm.value)" autocomplete="off">
                <div class="pt-5">
                    <div class="group-inline">
                        <!-- Data -->
                        <div class="group-inline">
                            <div class="form-group field-10 has-feedback">
                                <label>Data*</label>
                                <div class="input-group">
                                    <input class="form-control" placeholder="aaaa-mm-gg" name="data"
                                        [(ngModel)]="dateModel" ngbDatepicker #d="ngbDatepicker" [minDate]="minDate!!"
                                        [maxDate]="maxDate" formControlName="data" [markDisabled]="isDisabled"
                                        [readonly]="!hasWritePermission(mainUser) || campiPrimaDiagnosiReadonly(this.contatto.diagnosiEScaleList!!) || mustDisableForm() || isContattoClosedAndLastDiagnosi()">

                                    <button class="btn btn-secondary" (click)="d.toggle()" type="button"
                                        [attr.disabled]="!hasWritePermission(mainUser)? false:null || campiPrimaDiagnosiReadonly(this.contatto.diagnosiEScaleList!!)? false:null || mustDisableForm()  ? false: null || isContattoClosedAndLastDiagnosi() ? false: null">
                                        <img class="img-responsive" src="../assets/icons/calendar_ko.png"
                                            style="width: 20px; height:20px">
                                    </button>


                                </div>

                                <div *ngIf="diagnosiScaleForm.hasError('required', 'data') && formSubmitted"
                                    class="alert alert-danger">
                                    Campo obbligatorio
                                </div>

                                <div *ngIf="diagnosiScaleForm.hasError('dateExist', 'data') && formSubmitted"
                                    class="alert alert-danger">
                                    Diagnosi già presente per questa data
                                </div>
                                <div *ngIf="diagnosiScaleForm.controls['data'].hasError('ngbDate')&& formSubmitted"
                                    class="alert alert-danger">
                                    Il formato della data non è valido
                                </div>
                            </div>
                            <div class="form-group field-60">


                            </div>
                        </div>

                        <!-- Diagnosi -->
                        <div class="form-group field-20 has-feedback">
                            <label>Diagnosi*</label>

                            <input id="typeahead-focus" type="text"
                                   name="diagnosiScala"
                                   formControlName="diagnosiScala"
                                   class="form-control" [(ngModel)]="modelDiagnosi"
                                [ngbTypeahead]="diagnosiScaleSearch" (focus)="focus$.next($any($event).target.value)"
                                (click)="click$.next($any($event).target.value)" #instanceDiagnosiScala="ngbTypeahead"
                                [inputFormatter]="formatter" [resultFormatter]="resultFormatter" [editable]="false"

                                [attr.disabled]="!hasWritePermission(mainUser)? false:null || campiPrimaDiagnosiReadonly(this.contatto.diagnosiEScaleList!!)? false:null || mustDisableForm() ? false: null || isContattoClosedAndLastDiagnosi() ? false: null" />
                            <div *ngIf="diagnosiScaleForm.controls['diagnosiScala'].hasError('required') && formSubmitted"
                                class="alert alert-danger">Campo obbligatorio</div>

                            <div *ngIf="diagnosiScaleForm.controls['diagnosiScala'].hasError('wrongDiagnosiError')  && formSubmitted"
                                class="alert alert-danger">Il valore deve essere selezionato tra quelli proposti</div>
                        </div>




                        <!-- ScalaHonos -->
                        <div class="form-group field-wide">
                            <label>Scala honos</label>
                            <input type="number" class="form-control"
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                name="scalaHonos" maxlength="2" min="0" formControlName="scalaHonos"
                                [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm() ? false: null">

                            <div *ngIf="diagnosiScaleForm.controls['scalaHonos'].hasError('scalaHonosNonValida') "
                                class="alert alert-danger">Il valore deve essere compreso tra 0 e 48 </div>
                        </div>

                        <!-- ScalaBprs -->
                        <div class="form-group field-wide">
                            <label>Scala bprs</label>
                            <input type="number" class="form-control"
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                maxlength="3" min="1" name="scalaBprs" formControlName="scalaBprs"
                                [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm() ? false: null">

                            <div *ngIf="diagnosiScaleForm.controls['scalaBprs'].hasError('scalaBprsNonValida') "
                                class="alert alert-danger">Il valore deve essere compreso tra 1 e 168</div>
                        </div>

                    </div>


                </div>


            </form>
        </div>
    </div>

    <block-ui>

    </block-ui>

    <!-- Toolbar -->
    <div class="btn-fixed fixed" demon-cf="toolbarFixed" demon-ev="true" demon-cf-id="8">
        <div class="btn-toolbar">
            <div class="container">
                <div class="btn-group-left">
                    <button type="button" class="btn btn-outline-secondary" demon-bt="indietro" demon-cf="ModalConfirm"
                        demon-body="Tornando indietro perderai i dati inseriti nella prestazione, vuoi continuare comunque?"
                        demon-call="$D._sbObj.oprestazione.evIndietro" demon-ev="true" demon-cf-id="9"
                        (click)="openGoBackDialog(content)">
                        Torna alla lista
                    </button>
                </div>
                <div class="btn-group-right">

                    <button *ngIf="cancellaVisibile()" type="button" class="btn btn-outline-danger" [attr.disabled]=" mustDisableCancelBtn()? false: null"
                        (click)="openCancellaDialog(cancella)">Cancella</button>
                </div>
                <div class="btn-group-right">
                    <button *ngIf="salvaVisibile()" type="button" class="btn btn-secondary"
                        (click)="submitForm(diagnosiScaleForm.value)"
                        [attr.disabled]=" mustDisableForm()? false: null">
                        <!-- [disabled]="!diagnosiScaleForm.valid || !diagnosiScaleForm.dirty" -->
                        Salva
                    </button>

<!--                  <button (click)="prova()">prova</button>-->
                </div>
            </div>
        </div>
    </div>



</div>

<ng-template #content let-modal>
    <div class="modal-header" style="background-color: #012346 !important; cursor: pointer;">
        <h4 class="modal-title" id="modal-basic-title" style="color: white;">Attenzione</h4>
        <a (click)="modal.dismiss('Cross click')"><svg class="s-icon icon-xxs">
                <use xlink:href="assets/icons/sprite.svg#close"></use>
            </svg></a>
    </div>
    <div class="modal-body">
        <div class="container">
            <div class="row">
                <label>Tornando indietro perderai i dati inseriti nell'diagnosi e scala, vuoi continuare
                    comunque?</label>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Back click')">Ok</button>
    </div>
</ng-template>


<ng-template #cancella let-modal>
    <div class="modal-header" style="background-color: #012346 !important; cursor: pointer;">
        <h4 class="modal-title" id="modal-basic-title" style="color: white;">Attenzione</h4>
        <a (click)="modal.dismiss('Cross click')"><svg class="s-icon icon-xxs">
                <use xlink:href="assets/icons/sprite.svg#close"></use>
            </svg></a>
    </div>
    <div class="modal-body">
        <div class="container">
            <div class="row">
                <label>Sei sicuro di voler cancellare diagnosi&scale con id {{diagnosiScala!!.id!!}} ?</label>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-danger"
            (click)="cancellaDiagnosiEScala(diagnosiScala!!.id!!)">Cancella</button>
    </div>
</ng-template>
<ng-template #audit let-modal>
    <div class="modal-header" style="background-color: #012346 !important; cursor: pointer;">
      <h4 class="modal-title" id="modal-basic-title" style="color: white;">Storico modifiche Diagnosi e scale {{diagnosiScala!!.id!!}}</h4>
    </div>
    <div class="modal-body">
      <app-dyn-table [title]="storicoTitle" [dataMessageResults]="auditDiagnosiEScale"></app-dyn-table>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.close('')">Ok</button>
    </div>
  </ng-template>
