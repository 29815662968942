import {InterventoAudit} from './../../model/Models';
import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ModalDismissReasons, NgbDateStruct, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {Funzionalita} from 'src/app/model/Funzionalita';
import {Contatto, DettaglioAnagrafica, Intervento, Page, RispostaPrevalente, Utente} from 'src/app/model/Models';
import {ApiService} from 'src/app/services/api.service';
import {SessionService} from 'src/app/services/session.service';
import {ToastService} from 'src/app/services/toast.service';
import {dataNonValidaValidator} from 'src/app/util/codicefiscale.validators';
import {CommonsUtil} from "../../util/CommonsUtil";

@Component({
  selector: 'app-intervento',
  templateUrl: './intervento.component.html',
  styleUrls: ['./intervento.component.css']
})
export class InterventoComponent implements OnInit {


  anagrafica!: DettaglioAnagrafica

  interventoForm!: FormGroup

  intervento!: Intervento

  closeResult = ''

  formSubmitted = false

  minDate = {year: 1920, month: 1, day: 1}

  maxDate = {year: 2035, month: 1, day: 1}

  dateModel!: NgbDateStruct | null

  mainUser!: Utente

  contatto!: Contatto

  sectionIeVisible = false

  listRispotePrevalenti: RispostaPrevalente[] = []

  listDate: string[] = []

  listaInterventiOfContatto: Intervento[] = [];


  // for section pi
  rispostaPrevalente = ''


  // for second ie-section
  invioInStrutturaSelected = false


  // for luogo stpit
  ieInvioStpitSelected = false

  // for luogo srtri
  ieInvioSrtriSelected = false

  // for luogo srtre
  ieInvioSrtreSelected = false

  // for luogo srsr24
  ieInvioSrsr24Selected = false

  // for luogo Srsr12
  ieInvioSrsr12Selected = false

  // for luogo ga
  ieInvioGaSelected = false

  // for luogo cd
  ieInvioCdSelected = false


  // for luogo rep 40
  ieInvioOspedaleSelected = false

  enableStatiAttuali: Array<number> = [0, 1, 2, 4, 7, 9, 0];

  @BlockUI()
  blockUI!: NgBlockUI;

  constructor(private router: Router, private sessionService: SessionService,
              private modalService: NgbModal, private apiService: ApiService, private toastService: ToastService) {
  }

  async ngOnInit(): Promise<void> {

    this.loadUser()

    this.loadRispostePrevalentiList()

    this.loadAnagrafica()

    await this.loadContatto()

    this.loadIntervento()

    this.initListaInterventiOfContattoAndDateModel();

    this.observeForm()

    this.observeStrutturaChange()

    this.limitMaxDate()

  }

  loadUser() {
    this.mainUser = this.sessionService.getUtenteFromStorage()!!

  }

  mustDisableForm() {

    if (this.intervento && this.intervento.statoAttuale && this.intervento.statoAttuale.id && this.intervento.statoAttuale.id >= 0) {

      return this.enableStatiAttuali.indexOf(this.intervento.statoAttuale.id) == -1

    }

    return false;

  }
  mustDisableCancelBtn() {

    if (this.intervento.statoAttuale.id && this.intervento.statoAttuale.id > 0) {

      let statiAttuali: Array<number> = [1, 2, 4, 7, 9];
      return statiAttuali.indexOf(this.intervento.statoAttuale.id) == -1

    }

    return true;

  }

  loadIntervento() {
    this.sessionService.selectedIntervento.subscribe(res => {
      this.intervento = JSON.parse(res)
      // console.log("id intervento ", this.intervento.id);
      if (!this.isInterventoNew(this.intervento)) {
        this.initInterventoForm()
        this.setFormValues(this.intervento)
      } else {
        this.initInterventoForm()
        this.loadNotAvailableDateList()
        this.observeFormForDuplicateDates()
      }
    })
  }

  async loadContatto() {

    await this.sessionService.selectedContatto.subscribe(res => {
      this.contatto = res
      this.limitMinDate(this.contatto)

    })
  }

  loadAnagrafica() {

    this.sessionService.selectedAnagrafica.subscribe(res => {

      this.anagrafica = JSON.parse(res)
    })
  }

  initInterventoForm() {

    this.interventoForm = new FormGroup({

      date: new FormControl("", [Validators.required, dataNonValidaValidator()]),

      rispostaPrevalente: new FormControl("", [Validators.required]),


      // PI
      piTrattamentoPsicoterapeutico: new FormControl("", []),
      piTrattamentoFarmacologico: new FormControl("", []),
      piInterventiRiabilitativi: new FormControl("", []),
      piInserimentiLavorativi: new FormControl("", []),
      piInterventoSociale: new FormControl("", []),
      piColloquiSostegno: new FormControl("", []),
      piCoinvolgimentoFamiliari: new FormControl("", []),
      piAccertamentiMedicoLegali: new FormControl("", []),
      piInvioInStruttura: new FormControl("", []),
      piInvioInOspedale: new FormControl("", []),
      piConsultazioneValutazione: new FormControl("", []),
      piAltro: new FormControl("", []),

      // IE
      ieTrattamentoPsicoterapeutico: new FormControl("", []),
      ieTrattamentoFarmacologico: new FormControl("", []),
      ieInterventiRiabilitativi: new FormControl("", []),
      ieInserimentiLavorativi: new FormControl("", []),
      ieInterventoSociale: new FormControl("", []),
      ieColloquiSostegno: new FormControl("", []),
      ieCoinvolgimentoFamiliari: new FormControl("", []),
      ieAccertamentiMedicoLegali: new FormControl("", []),
      ieInvioInStruttura: new FormControl("", []),
      ieInvioStpit: new FormControl("", []),
      ieLuogoStpit: new FormControl("", []),

      ieInvioSrtri: new FormControl("", []),
      ieLuogoSrtri: new FormControl("", []),

      ieInvioSrtre: new FormControl("", []),
      ieLuogoSrtre: new FormControl("", []),

      ieInvioSrsr24: new FormControl("", []),
      ieLuogoSrsr24: new FormControl("", []),

      ieInvioSrsr12: new FormControl("", []),
      ieLuogoSrsr12: new FormControl("", []),

      ieInvioGa: new FormControl("", []),
      ieLuogoGa: new FormControl("", []),

      ieInvioCd: new FormControl("", []),
      ieLuogoCd: new FormControl("", []),

      ieInvioOspedale: new FormControl("", []),
      ieInvioOspedaleRep40: new FormControl("", []),

      ieLuogoOspedale: new FormControl("", []),
      ieConsultazioneValutazione: new FormControl("", []),
      ieAltro: new FormControl("", []),

    })


  }

  setFormValues(intervento: Intervento) {


    this.interventoForm.get('rispostaPrevalente')!!.patchValue(intervento.rispostaPrevalente)

    this.interventoForm.get('piTrattamentoPsicoterapeutico')!!.patchValue(this.getValueFromBoolean(intervento.piTrattamentoPsicoterapeutico))

    this.interventoForm.get('piTrattamentoFarmacologico')!!.patchValue(this.getValueFromBoolean(intervento.piTrattamentoFarmacologico))

    this.interventoForm.get('piInterventiRiabilitativi')!!.patchValue(this.getValueFromBoolean(intervento.piInterventiRiabilitativi))

    this.interventoForm.get('piInserimentiLavorativi')!!.patchValue(this.getValueFromBoolean(intervento.piInserimentiLavorativi))

    this.interventoForm.get('piInterventoSociale')!!.patchValue(this.getValueFromBoolean(intervento.piInterventoSociale))

    this.interventoForm.get('piColloquiSostegno')!!.patchValue(this.getValueFromBoolean(intervento.piColloquiSostegno))

    this.interventoForm.get('piCoinvolgimentoFamiliari')!!.patchValue(this.getValueFromBoolean(intervento.piCoinvolgimentoFamiliari))

    this.interventoForm.get('piAccertamentiMedicoLegali')!!.patchValue(this.getValueFromBoolean(intervento.piAccertamentiMedicoLegali))

    this.interventoForm.get('piInvioInStruttura')!!.patchValue(this.getValueFromBoolean(intervento.piInvioInStruttura))

    this.interventoForm.get('piInvioInOspedale')!!.patchValue(this.getValueFromBoolean(intervento.piInvioInOspedale))

    this.interventoForm.get('piConsultazioneValutazione')!!.patchValue(this.getValueFromBoolean(intervento.piConsultazioneValutazione))

    this.interventoForm.get('piAltro')!!.patchValue(intervento.piAltro)


    this.interventoForm.get('ieTrattamentoPsicoterapeutico')!!.patchValue(this.getValueFromBoolean(intervento.ieTrattamentoPsicoterapeutico))

    this.interventoForm.get('ieTrattamentoFarmacologico')!!.patchValue(this.getValueFromBoolean(intervento.ieTrattamentoFarmacologico))

    this.interventoForm.get('ieInterventiRiabilitativi')!!.patchValue(this.getValueFromBoolean(intervento.ieInterventiRiabilitativi))

    this.interventoForm.get('ieInserimentiLavorativi')!!.patchValue(this.getValueFromBoolean(intervento.ieInserimentiLavorativi))

    this.interventoForm.get('ieInterventoSociale')!!.patchValue(this.getValueFromBoolean(intervento.ieInterventoSociale))

    this.interventoForm.get('ieColloquiSostegno')!!.patchValue(this.getValueFromBoolean(intervento.ieColloquiSostegno))

    this.interventoForm.get('ieCoinvolgimentoFamiliari')!!.patchValue(this.getValueFromBoolean(intervento.ieCoinvolgimentoFamiliari))

    this.interventoForm.get('ieAccertamentiMedicoLegali')!!.patchValue(this.getValueFromBoolean(intervento.ieAccertamentiMedicoLegali))

    this.interventoForm.get('ieInvioInStruttura')!!.patchValue(this.getValueFromBoolean(intervento.ieInvioInStruttura))

    this.interventoForm.get('ieInvioStpit')!!.patchValue(this.getValueFromBoolean(intervento.ieInvioStpit))

    this.interventoForm.get('ieLuogoStpit')!!.patchValue(this.getValueFromBoolean(intervento.ieLuogoStpit))

    this.interventoForm.get('ieInvioSrtri')!!.patchValue(this.getValueFromBoolean(intervento.ieInvioSrtri))

    this.interventoForm.get('ieLuogoSrtri')!!.patchValue(this.getValueFromBoolean(intervento.ieLuogoSrtri))

    this.interventoForm.get('ieInvioSrtre')!!.patchValue(this.getValueFromBoolean(intervento.ieInvioSrtre))

    this.interventoForm.get('ieLuogoSrtre')!!.patchValue(this.getValueFromBoolean(intervento.ieLuogoSrtre))

    this.interventoForm.get('ieInvioSrsr24')!!.patchValue(this.getValueFromBoolean(intervento.ieInvioSrsr24))

    this.interventoForm.get('ieLuogoSrsr24')!!.patchValue(this.getValueFromBoolean(intervento.ieLuogoSrsr24))

    this.interventoForm.get('ieInvioSrsr12')!!.patchValue(this.getValueFromBoolean(intervento.ieInvioSrsr12))

    this.interventoForm.get('ieLuogoSrsr12')!!.patchValue(this.getValueFromBoolean(intervento.ieLuogoSrsr12))

    this.interventoForm.get('ieInvioGa')!!.patchValue(this.getValueFromBoolean(intervento.ieInvioGa))

    this.interventoForm.get('ieLuogoGa')!!.patchValue(this.getValueFromBoolean(intervento.ieLuogoGa))

    this.interventoForm.get('ieInvioCd')!!.patchValue(this.getValueFromBoolean(intervento.ieInvioCd))

    this.interventoForm.get('ieLuogoCd')!!.patchValue(this.getValueFromBoolean(intervento.ieLuogoCd))

    this.interventoForm.get('ieInvioOspedale')!!.patchValue(this.getValueFromBoolean(intervento.ieInvioOspedale))

    this.interventoForm.get('ieInvioOspedaleRep40')!!.patchValue(this.getValueFromBoolean(intervento.ieInvioOspedaleRep40))

    this.interventoForm.get('ieLuogoOspedale')!!.patchValue(this.getValueFromBoolean(intervento.ieLuogoOspedale))

    this.interventoForm.get('ieConsultazioneValutazione')!!.patchValue(this.getValueFromBoolean(intervento.ieConsultazioneValutazione))

    this.interventoForm.get('ieAltro')!!.patchValue(intervento.ieAltro)


  }

  getValueFromBoolean(value: Boolean | undefined): string {

    let res = ""
    if (value == true) {
      res = "true"
    }
    if (value == false) {
      res = "false"
    }

    return res
  }

  initListaInterventiOfContattoAndDateModel() {


    if (this.intervento.id != null) {
      this.dateModel = this.sessionService.convertToDateStruct(new Date(this.intervento.data!))!;
    } else {
      if (this.isFirstInterventoOfContatto()) {
        this.dateModel = this.sessionService.convertToDateStruct(new Date(this.contatto.dataApertura!))!;
      }

    }
  }


  loadNotAvailableDateList() {


    this.sessionService.listInterventiDataNotAvailable.subscribe(res => {


      this.listDate = res
    })

  }

  observeFormForDuplicateDates() {

    this.interventoForm.get('date')?.valueChanges.subscribe(val => {

      this.dateExistInDates(val)
    })
  }


  isInterventoNew(intervento: Intervento): boolean {

    if (intervento.id == null || intervento.id == undefined) {

      return true
    } else {
      return false
    }
  }

  dateExistInDates(date: NgbDateStruct) {


    let exist = this.listDate.indexOf(JSON.stringify(date)) !== -1


    if (exist === true) {


      this.interventoForm.get('date')?.setErrors({dateExist: true})

    } else {


      this.interventoForm.get('date')?.setErrors({dateExist: null})
      this.interventoForm.controls['date'].updateValueAndValidity({onlySelf: true, emitEvent: false})

    }


  }

  navigateToRicercaAnagrafica() {


    this.router.navigate(['ricerca-anagrafica'])
  }

  navigateToAnagrafica() {
    this.router.navigate(['contatto'])
  }

  getTitleName(): string {

    if (this.anagrafica.nome === "") {
      return "Nuova Prestazione"
    } else {
      return "" + this.anagrafica.cognome + " " + this.anagrafica.nome + ' (CF: ' + this.anagrafica.codiceFiscale + ")"
    }
  }

  submitForm(values: any) {

    this.formSubmitted = true


    if (this.interventoForm.valid) {
      this.sessionService.toNativeDate(values.date)


      let intervento: Intervento = values

      intervento.id = this.intervento.id
      intervento.data = this.sessionService.toNativeDate(values.date)!!
      intervento.contatto = this.contatto
      intervento.codiceAslOperatoreInserimento = this.contatto.codiceAslOperatoreInserimento

      this.blockUI.start("Salvataggio in corso")


      let codiceStruttura = this.sessionService.getSelectedStrutturaFromStorage()?.codice

      this.apiService.saveIntervento(intervento, codiceStruttura!!).subscribe({
        next: (res) => {

          this.intervento = res
          this.sessionService.setSelectedIntervento(JSON.stringify(this.intervento))
          this.toastService.showSuccess('Salvataggio effettuato con successo');
          //          this.router.navigate(['ricerca-anagrafica'])
          this.refreshData()
          this.back();

        },

        error: (err) => {

          console.log(err);

          this.blockUI.stop()

          this.toastService.showError('Correggere gli errori di compilazione');

        },

        complete: () => {


          this.blockUI.stop()
          this.back()
          this.toastService.showSuccess('Salvataggio effettuato con successo');

        }
      })

    } else {

      this.getFormValidationErrors()
      this.toastService.showError("Correggere gli errori di compilazione")
    }


  }

  salvaVisibile() {

    return this.mainUser.funzionalita.includes(Funzionalita.ANAGRAFICHE_INSERIMENTO);
  }

  cancellaVisibile(): Boolean {
    if (this.intervento && this.intervento.id && this.salvaVisibile()) {
      if (this.isContattoClosedAndLastIntervento() || this.isFirstInterventoOfListContatto()) {
        return false;
      } else {
        return true
      }
    } else {
      return false
    }
  }

  isContattoClosedAndLastIntervento() {
    const itsort = this.contatto.interventiList?this.contatto.interventiList.sort((a, b) => this.getTime(a.data)-this.getTime(b.data)):[];
    const isLastIntervnto = itsort.findIndex(value => value.id == this.intervento.id) == itsort?.length! - 1;
    if (this.contatto.dataChiusura && isLastIntervnto) {
      return true;
    } else {
      return false;
    }
  }

  openGoBackDialog(content: any) {

    if (!this.formIsTouched()) {

      this.back()

    } else {

      this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;

        if (this.closeResult === 'Closed with: Back click') {
          this.back()

        }
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;


      });
    }

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  formIsTouched(): boolean {

    return this.interventoForm.touched

  }

  back() {
    this.sessionService.setOpenTabContatto("TabInterventi")
    this.router.navigate(['contatto'])
  }

  openCancellaDialog(cancella: any) {

    this.modalService.open(cancella, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

      if (this.closeResult === 'Closed with: Back click') {
        this.back()

      }
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;


    });


  }

  campiPrimoInterventoReadonly() {
    if (this.isFirstInterventoOfContatto()) {
      return true;
    } else {
      const minId = this.contatto!!.interventiList!!
        .reduce((min, obj) => Math.min(min, obj!.id!), Infinity);
      return minId == this.intervento.id;
    }
  }

  isFirstInterventoOfContatto(): boolean {
    return this.contatto!!.interventiList!!.length == 0
  }


  hasWritePermission(utente: Utente): boolean {

    if (utente.funzionalita.includes(Funzionalita.ANAGRAFICHE_INSERIMENTO)) {
      return true
    } else {
      return false
    }

  }

  observeForm() {

    this.interventoForm.valueChanges.subscribe(val => {

      let formObj = val

      this.rispostaPrevalente = formObj.rispostaPrevalente


      this.validateFirstPiSection(this.rispostaPrevalente)


      this.setPIAltroValidators(formObj.rispostaPrevalente, formObj.piTrattamentoPsicoterapeutico, formObj.piTrattamentoFarmacologico,
        formObj.piInterventiRiabilitativi, formObj.piInserimentiLavorativi, formObj.piInterventoSociale,
        formObj.piColloquiSostegno, formObj.piCoinvolgimentoFamiliari, formObj.piAccertamentiMedicoLegali,
        formObj.piInvioInStruttura, formObj.piInvioInOspedale, formObj.piConsultazioneValutazione)


      this.validateFirstIeSection(this.rispostaPrevalente, this.contatto)

      this.validateSecondIeSection(formObj.ieInvioInStruttura)

      this.validateInvioSection(formObj.ieInvioStpit, formObj.ieInvioSrtri, formObj.ieInvioSrtre,
        formObj.ieInvioSrsr24, formObj.ieInvioSrsr12, formObj.ieInvioGa, formObj.ieInvioCd, formObj.ieInvioOspedale)

      this.setIeAltroValidators(formObj.ieInvioInStruttura, formObj.ieInvioOspedale, formObj.ieTrattamentoPsicoterapeutico,
        formObj.ieTrattamentoFarmacologico, formObj.ieInterventiRiabilitativi, formObj.ieInserimentiLavorativi, formObj.ieInterventoSociale,
        formObj.ieColloquiSostegno, formObj.ieCoinvolgimentoFamiliari, formObj.ieAccertamentiMedicoLegali, formObj.ieConsultazioneValutazione)


      //INIZIO - ripulisco i campi nascosti
      if (!this.rispostaPrevalenteIs12()) {
        this.interventoForm.controls['piTrattamentoPsicoterapeutico'].patchValue(null, {
          emitEvent: false,
          onlySelf: true
        });
        this.interventoForm.controls['piTrattamentoFarmacologico'].patchValue(null, {emitEvent: false, onlySelf: true});
        this.interventoForm.controls['piInterventiRiabilitativi'].patchValue(null, {emitEvent: false, onlySelf: true});
        this.interventoForm.controls['piInserimentiLavorativi'].patchValue(null, {emitEvent: false, onlySelf: true});
        this.interventoForm.controls['piInterventoSociale'].patchValue(null, {emitEvent: false, onlySelf: true});
        this.interventoForm.controls['piColloquiSostegno'].patchValue(null, {emitEvent: false, onlySelf: true});
        this.interventoForm.controls['piCoinvolgimentoFamiliari'].patchValue(null, {emitEvent: false, onlySelf: true});
        this.interventoForm.controls['piAccertamentiMedicoLegali'].patchValue(null, {emitEvent: false, onlySelf: true});
        this.interventoForm.controls['piInvioInStruttura'].patchValue(null, {emitEvent: false, onlySelf: true});
        this.interventoForm.controls['piInvioInOspedale'].patchValue(null, {emitEvent: false, onlySelf: true});
        if (!this.rispostaPrevalenteIsIn123(this.rispostaPrevalente)) {
          this.interventoForm.controls['piConsultazioneValutazione'].patchValue(null, {
            emitEvent: false,
            onlySelf: true
          });
          this.interventoForm.controls['piAltro'].patchValue(null, {emitEvent: false, onlySelf: true});
        }
      }
      if (!this.ieInvioOspedaleSelected) {
        this.interventoForm.controls['ieInvioOspedaleRep40'].patchValue(null, {emitEvent: false, onlySelf: true});
        this.interventoForm.controls['ieLuogoOspedale'].patchValue(null, {emitEvent: false, onlySelf: true});
      }

      if (!this.invioInStrutturaSelected) {
        this.interventoForm.controls['ieInvioStpit'].patchValue(null, {emitEvent: false, onlySelf: true});
        this.interventoForm.controls['ieLuogoStpit'].patchValue(null, {emitEvent: false, onlySelf: true});
        this.interventoForm.controls['ieInvioSrtri'].patchValue(null, {emitEvent: false, onlySelf: true});
        this.interventoForm.controls['ieLuogoSrtri'].patchValue(null, {emitEvent: false, onlySelf: true});
        this.interventoForm.controls['ieInvioSrtre'].patchValue(null, {emitEvent: false, onlySelf: true});
        this.interventoForm.controls['ieLuogoSrtre'].patchValue(null, {emitEvent: false, onlySelf: true});
        this.interventoForm.controls['ieInvioSrsr24'].patchValue(null, {emitEvent: false, onlySelf: true});
        this.interventoForm.controls['ieLuogoSrsr24'].patchValue(null, {emitEvent: false, onlySelf: true});
        this.interventoForm.controls['ieInvioSrsr12'].patchValue(null, {emitEvent: false, onlySelf: true});
        this.interventoForm.controls['ieLuogoSrsr12'].patchValue(null, {emitEvent: false, onlySelf: true});
        this.interventoForm.controls['ieInvioGa'].patchValue(null, {emitEvent: false, onlySelf: true});
        this.interventoForm.controls['ieLuogoGa'].patchValue(null, {emitEvent: false, onlySelf: true});
        this.interventoForm.controls['ieInvioCd'].patchValue(null, {emitEvent: false, onlySelf: true});
        this.interventoForm.controls['ieLuogoCd'].patchValue(null, {emitEvent: false, onlySelf: true});
        // this.interventoForm.controls['ieInvioOspedale'].patchValue(null, {emitEvent: false, onlySelf: true});
        // this.interventoForm.controls['ieInvioOspedaleRep40'].patchValue(null, {emitEvent: false, onlySelf: true});
        // this.interventoForm.controls['ieLuogoOspedale'].patchValue(null, {emitEvent: false, onlySelf: true});

      }

      if (!this.ieInvioStpitSelected) {
        this.interventoForm.controls['ieLuogoStpit'].patchValue(null, {emitEvent: false, onlySelf: true});
      }
      if (!this.ieInvioSrtriSelected) {
        this.interventoForm.controls['ieLuogoSrtri'].patchValue(null, {emitEvent: false, onlySelf: true});
      }
      if (!this.ieInvioSrtreSelected) {
        this.interventoForm.controls['ieLuogoSrtre'].patchValue(null, {emitEvent: false, onlySelf: true});
      }
      if (!this.ieInvioSrsr24Selected) {
        this.interventoForm.controls['ieLuogoSrsr24'].patchValue(null, {emitEvent: false, onlySelf: true});
      }
      if (!this.ieInvioSrsr12Selected) {
        this.interventoForm.controls['ieLuogoSrsr12'].patchValue(null, {emitEvent: false, onlySelf: true});
      }
      if (!this.ieInvioGaSelected) {
        this.interventoForm.controls['ieLuogoGa'].patchValue(null, {emitEvent: false, onlySelf: true});
      }
      if (!this.ieInvioCdSelected) {
        this.interventoForm.controls['ieLuogoCd'].patchValue(null, {emitEvent: false, onlySelf: true});
      }
      if (!this.ieInvioOspedaleSelected) {
        this.interventoForm.controls['ieInvioOspedaleRep40'].patchValue(null, {emitEvent: false, onlySelf: true});
        this.interventoForm.controls['ieLuogoOspedale'].patchValue(null, {emitEvent: false, onlySelf: true});
      }
      //FINE - ripulisco i campi nascosti


    })
  }


  getFormValidationErrors() {
    Object.keys(this.interventoForm.controls).forEach(key => {
      const controlErrors: ValidationErrors | null = this.interventoForm.get(key)!!.errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }

  validateFirstPiSection(value: string) {
    // se risposta prevalente e vuota on in 1 e due
    if (value == "" || value == "1" || value == "2") {
      this.interventoForm.controls['piTrattamentoPsicoterapeutico'].setValidators([Validators.required])
      this.interventoForm.controls['piTrattamentoPsicoterapeutico'].updateValueAndValidity({onlySelf: true})

      this.interventoForm.controls['piTrattamentoFarmacologico'].setValidators([Validators.required])
      this.interventoForm.controls['piTrattamentoFarmacologico'].updateValueAndValidity({onlySelf: true})

      this.interventoForm.controls['piInterventiRiabilitativi'].setValidators([Validators.required])
      this.interventoForm.controls['piInterventiRiabilitativi'].updateValueAndValidity({onlySelf: true})

      this.interventoForm.controls['piInserimentiLavorativi'].setValidators([Validators.required])
      this.interventoForm.controls['piInserimentiLavorativi'].updateValueAndValidity({onlySelf: true})

      this.interventoForm.controls['piInterventoSociale'].setValidators([Validators.required])
      this.interventoForm.controls['piInterventoSociale'].updateValueAndValidity({onlySelf: true})

      this.interventoForm.controls['piColloquiSostegno'].setValidators([Validators.required])
      this.interventoForm.controls['piColloquiSostegno'].updateValueAndValidity({onlySelf: true})

      this.interventoForm.controls['piCoinvolgimentoFamiliari'].setValidators([Validators.required])
      this.interventoForm.controls['piCoinvolgimentoFamiliari'].updateValueAndValidity({onlySelf: true})

      this.interventoForm.controls['piAccertamentiMedicoLegali'].setValidators([Validators.required])
      this.interventoForm.controls['piAccertamentiMedicoLegali'].updateValueAndValidity({onlySelf: true})

      this.interventoForm.controls['piInvioInStruttura'].setValidators([Validators.required])
      this.interventoForm.controls['piInvioInStruttura'].updateValueAndValidity({onlySelf: true})

      this.interventoForm.controls['piInvioInOspedale'].setValidators([Validators.required])
      this.interventoForm.controls['piInvioInOspedale'].updateValueAndValidity({onlySelf: true})

      this.interventoForm.controls['piConsultazioneValutazione'].setValidators([Validators.required])
      this.interventoForm.controls['piConsultazioneValutazione'].updateValueAndValidity({onlySelf: true})

    } else {
      this.interventoForm.controls['piTrattamentoPsicoterapeutico'].removeValidators([Validators.required])
      this.interventoForm.controls['piTrattamentoPsicoterapeutico'].updateValueAndValidity({onlySelf: true})

      this.interventoForm.controls['piTrattamentoFarmacologico'].removeValidators([Validators.required])
      this.interventoForm.controls['piTrattamentoFarmacologico'].updateValueAndValidity({onlySelf: true})

      this.interventoForm.controls['piInterventiRiabilitativi'].removeValidators([Validators.required])
      this.interventoForm.controls['piInterventiRiabilitativi'].updateValueAndValidity({onlySelf: true})

      this.interventoForm.controls['piInserimentiLavorativi'].removeValidators([Validators.required])
      this.interventoForm.controls['piInserimentiLavorativi'].updateValueAndValidity({onlySelf: true})

      this.interventoForm.controls['piInterventoSociale'].removeValidators([Validators.required])
      this.interventoForm.controls['piInterventoSociale'].updateValueAndValidity({onlySelf: true})

      this.interventoForm.controls['piColloquiSostegno'].removeValidators([Validators.required])
      this.interventoForm.controls['piColloquiSostegno'].updateValueAndValidity({onlySelf: true})

      this.interventoForm.controls['piCoinvolgimentoFamiliari'].removeValidators([Validators.required])
      this.interventoForm.controls['piCoinvolgimentoFamiliari'].updateValueAndValidity({onlySelf: true})

      this.interventoForm.controls['piAccertamentiMedicoLegali'].removeValidators([Validators.required])
      this.interventoForm.controls['piAccertamentiMedicoLegali'].updateValueAndValidity({onlySelf: true})

      this.interventoForm.controls['piInvioInStruttura'].removeValidators([Validators.required])
      this.interventoForm.controls['piInvioInStruttura'].updateValueAndValidity({onlySelf: true})

      this.interventoForm.controls['piInvioInOspedale'].removeValidators([Validators.required])
      this.interventoForm.controls['piInvioInOspedale'].updateValueAndValidity({onlySelf: true})

      // se risposta prevalente e 3
      if (value == "3") {
        this.interventoForm.controls['piConsultazioneValutazione'].setValidators([Validators.required])
        this.interventoForm.controls['piConsultazioneValutazione'].updateValueAndValidity({onlySelf: true})

      }
      // se risposta prevalente e fuori tra i primi 3
      else {
        this.interventoForm.controls['piConsultazioneValutazione'].removeValidators([Validators.required])
        this.interventoForm.controls['piConsultazioneValutazione'].updateValueAndValidity({onlySelf: true})
        // this.interventoForm.updateValueAndValidity({ onlySelf: true, emitEvent: false })

      }
    }


    this.interventoForm.updateValueAndValidity({onlySelf: true, emitEvent: false})
  }

  rispostaPrevalenteIs12() {

    return this.rispostaPrevalente == "1" || this.rispostaPrevalente == "2"
  }

  validateFirstIeSection(rispostaPrevalente: string, contatto: Contatto) {


    if (this.rispostaPrevalenteIsIn123(rispostaPrevalente) && this.isContattoClosedAndLastIntervento()) {
      this.sectionIeVisible = true


      this.interventoForm.controls['ieTrattamentoPsicoterapeutico'].setValidators([Validators.required])
      // this.interventoForm.controls['ieTrattamentoPsicoterapeutico'].updateValueAndValidity({ onlySelf: true })


      this.interventoForm.controls['ieTrattamentoFarmacologico'].setValidators([Validators.required])
      // this.interventoForm.controls['ieTrattamentoFarmacologico'].updateValueAndValidity({ onlySelf: true })

      this.interventoForm.controls['ieInterventiRiabilitativi'].setValidators([Validators.required])
      // this.interventoForm.controls['ieInterventiRiabilitativi'].updateValueAndValidity({ onlySelf: true })


      this.interventoForm.controls['ieInserimentiLavorativi'].setValidators([Validators.required])
      // this.interventoForm.controls['ieInserimentiLavorativi'].updateValueAndValidity({ onlySelf: true })

      this.interventoForm.controls['ieInterventoSociale'].setValidators([Validators.required])
      // this.interventoForm.controls['ieInterventoSociale'].updateValueAndValidity({ onlySelf: true })

      this.interventoForm.controls['ieColloquiSostegno'].setValidators([Validators.required])
      // this.interventoForm.controls['ieColloquiSostegno'].updateValueAndValidity({ onlySelf: true })


      this.interventoForm.controls['ieCoinvolgimentoFamiliari'].setValidators([Validators.required])
      // this.interventoForm.controls['ieCoinvolgimentoFamiliari'].updateValueAndValidity({ onlySelf: true })

      this.interventoForm.controls['ieAccertamentiMedicoLegali'].setValidators([Validators.required])
      // this.interventoForm.controls['ieAccertamentiMedicoLegali'].updateValueAndValidity({ onlySelf: true })

      this.interventoForm.controls['ieInvioInStruttura'].setValidators([Validators.required])
      // this.interventoForm.controls['ieInvioInStruttura'].updateValueAndValidity({ onlySelf: true })


      this.interventoForm.controls['ieConsultazioneValutazione'].setValidators([Validators.required])
      // this.interventoForm.controls['ieConsultazioneValutazione'].updateValueAndValidity({ onlySelf: true })


      // this.interventoForm.updateValueAndValidity({ onlySelf: true, emitEvent: false })

    } else {

      this.sectionIeVisible = false

      this.interventoForm.controls['ieTrattamentoPsicoterapeutico'].removeValidators([Validators.required])
      this.interventoForm.controls['ieTrattamentoPsicoterapeutico'].updateValueAndValidity({onlySelf: true})


      this.interventoForm.controls['ieTrattamentoFarmacologico'].removeValidators([Validators.required])
      this.interventoForm.controls['ieTrattamentoFarmacologico'].updateValueAndValidity({onlySelf: true})

      this.interventoForm.controls['ieInterventiRiabilitativi'].removeValidators([Validators.required])
      this.interventoForm.controls['ieInterventiRiabilitativi'].updateValueAndValidity({onlySelf: true})


      this.interventoForm.controls['ieInserimentiLavorativi'].removeValidators([Validators.required])
      this.interventoForm.controls['ieInserimentiLavorativi'].updateValueAndValidity({onlySelf: true})

      this.interventoForm.controls['ieInterventoSociale'].removeValidators([Validators.required])
      this.interventoForm.controls['ieInterventoSociale'].updateValueAndValidity({onlySelf: true})

      this.interventoForm.controls['ieColloquiSostegno'].removeValidators([Validators.required])
      this.interventoForm.controls['ieColloquiSostegno'].updateValueAndValidity({onlySelf: true})


      this.interventoForm.controls['ieCoinvolgimentoFamiliari'].removeValidators([Validators.required])
      this.interventoForm.controls['ieCoinvolgimentoFamiliari'].updateValueAndValidity({onlySelf: true})

      this.interventoForm.controls['ieAccertamentiMedicoLegali'].removeValidators([Validators.required])
      this.interventoForm.controls['ieAccertamentiMedicoLegali'].updateValueAndValidity({onlySelf: true})

      this.interventoForm.controls['ieInvioInStruttura'].removeValidators([Validators.required])
      this.interventoForm.controls['ieInvioInStruttura'].updateValueAndValidity({onlySelf: true})

      this.interventoForm.controls['ieInvioOspedale'].removeValidators([Validators.required])
      this.interventoForm.controls['ieInvioOspedale'].updateValueAndValidity({onlySelf: true})

      this.interventoForm.controls['ieConsultazioneValutazione'].removeValidators([Validators.required])
      this.interventoForm.controls['ieConsultazioneValutazione'].updateValueAndValidity({onlySelf: true})

      // this.interventoForm.updateValueAndValidity({ onlySelf: true, emitEvent: false })
    }


  }

  validateSecondIeSection(invioInStruttura: string) {

    if (this.fieldIsTrue(invioInStruttura)) {

      this.invioInStrutturaSelected = true

    } else {

      this.invioInStrutturaSelected = false
    }

  }


  validateInvioSection(ieInvioStpit: string, ieInvioSrtri: string, ieInvioSrtre: string,
                       ieInvioSrsr24: string, ieInvioSrsr12: string, ieInvioGa: string, ieInvioCd: string, ieInvioOspedale: string) {


    if (this.invioInStrutturaSelected && this.fieldIsFalse(ieInvioStpit) && this.fieldIsFalse(ieInvioSrtri) && this.fieldIsFalse(ieInvioSrtre)
      && this.fieldIsFalse(ieInvioSrsr24) && this.fieldIsFalse(ieInvioSrsr12) && this.fieldIsFalse(ieInvioGa)
      && this.fieldIsFalse(ieInvioCd)) {


      this.interventoForm.get('ieInvioInStruttura')?.setErrors({'invioNotSelected': true})
      // this.interventoForm.get('ieInvioInStruttura')?.updateValueAndValidity({ onlySelf: true, emitEvent: false })

    } else {

      this.interventoForm.get('ieInvioInStruttura')?.setErrors({'invioNotSelected': null})
      this.interventoForm.get('ieInvioInStruttura')?.updateValueAndValidity({onlySelf: true, emitEvent: false})


      this.interventoForm.updateValueAndValidity({onlySelf: true, emitEvent: false})

    }


    // invio stpit
    if (this.fieldIsTrue(ieInvioStpit)) {

      this.ieInvioStpitSelected = true

      this.interventoForm.controls['ieLuogoStpit'].setValidators([Validators.required])
      // this.interventoForm.controls['ieLuogoStpit'].updateValueAndValidity({ onlySelf: true })

    } else {
      this.ieInvioStpitSelected = false
      this.interventoForm.controls['ieLuogoStpit'].removeValidators([Validators.required])
      this.interventoForm.controls['ieLuogoStpit'].updateValueAndValidity({onlySelf: true})

    }


    // invio srtri
    if (this.fieldIsTrue(ieInvioSrtri)) {


      this.ieInvioSrtriSelected = true

      this.interventoForm.controls['ieLuogoSrtri'].setValidators([Validators.required])
      // this.interventoForm.controls['ieLuogoSrtri'].updateValueAndValidity({ onlySelf: true })

    } else {

      this.ieInvioSrtriSelected = false

      this.interventoForm.controls['ieLuogoSrtri'].removeValidators([Validators.required])
      this.interventoForm.controls['ieLuogoSrtri'].updateValueAndValidity({onlySelf: true})

    }


    // invio srtre
    if (this.fieldIsTrue(ieInvioSrtre)) {

      this.ieInvioSrtreSelected = true

      this.interventoForm.controls['ieLuogoSrtre'].setValidators([Validators.required])
      // this.interventoForm.controls['ieLuogoSrtre'].updateValueAndValidity({ onlySelf: true })

    } else {
      this.ieInvioSrtreSelected = false

      this.interventoForm.controls['ieLuogoSrtre'].removeValidators([Validators.required])
      this.interventoForm.controls['ieLuogoSrtre'].updateValueAndValidity({onlySelf: true})

    }


    // invio srs24
    if (this.fieldIsTrue(ieInvioSrsr24)) {

      this.ieInvioSrsr24Selected = true

      this.interventoForm.controls['ieLuogoSrsr24'].setValidators([Validators.required])
      // this.interventoForm.controls['ieLuogoSrsr24'].updateValueAndValidity({ onlySelf: true })


    } else {
      this.ieInvioSrsr24Selected = false
      this.interventoForm.controls['ieLuogoSrsr24'].removeValidators([Validators.required])
      this.interventoForm.controls['ieLuogoSrsr24'].updateValueAndValidity({onlySelf: true})

    }

    // invio srsr12
    if (this.fieldIsTrue(ieInvioSrsr12)) {

      this.ieInvioSrsr12Selected = true

      this.interventoForm.controls['ieLuogoSrsr12'].setValidators([Validators.required])
      // this.interventoForm.controls['ieLuogoSrsr12'].updateValueAndValidity({ onlySelf: true })


    } else {
      this.ieInvioSrsr12Selected = false
      this.interventoForm.controls['ieLuogoSrsr12'].removeValidators([Validators.required])
      this.interventoForm.controls['ieLuogoSrsr12'].updateValueAndValidity({onlySelf: true})

    }


    // invio ga
    if (this.fieldIsTrue(ieInvioGa)) {

      this.ieInvioGaSelected = true

      this.interventoForm.controls['ieLuogoGa'].setValidators([Validators.required])
      // this.interventoForm.controls['ieLuogoGa'].updateValueAndValidity({ onlySelf: true })


    } else {
      this.ieInvioGaSelected = false
      this.interventoForm.controls['ieLuogoGa'].removeValidators([Validators.required])
      this.interventoForm.controls['ieLuogoGa'].updateValueAndValidity({onlySelf: true})

    }


    // invio cd
    if (this.fieldIsTrue(ieInvioCd)) {

      this.ieInvioCdSelected = true

      this.interventoForm.controls['ieLuogoCd'].setValidators([Validators.required])
      // this.interventoForm.controls['ieLuogoCd'].updateValueAndValidity({ onlySelf: true })


    } else {
      this.ieInvioCdSelected = false
      this.interventoForm.controls['ieLuogoCd'].removeValidators([Validators.required])
      this.interventoForm.controls['ieLuogoCd'].updateValueAndValidity({onlySelf: true})

    }


    // invio invio ospedale
    if (this.fieldIsTrue(ieInvioOspedale)) {

      this.ieInvioOspedaleSelected = true
      this.interventoForm.controls['ieInvioOspedaleRep40'].setValidators([Validators.required])
      // this.interventoForm.controls['ieInvioOspedaleRep40'].updateValueAndValidity({ onlySelf: true })


      this.interventoForm.controls['ieLuogoOspedale'].setValidators([Validators.required])
      // this.interventoForm.controls['ieLuogoOspedale'].updateValueAndValidity({ onlySelf: true })


    } else {
      this.ieInvioOspedaleSelected = false
      this.interventoForm.controls['ieInvioOspedaleRep40'].removeValidators([Validators.required])
      this.interventoForm.controls['ieInvioOspedaleRep40'].updateValueAndValidity({onlySelf: true})

      this.interventoForm.controls['ieLuogoOspedale'].removeValidators([Validators.required])
      this.interventoForm.controls['ieLuogoOspedale'].updateValueAndValidity({onlySelf: true})


    }


    // this.interventoForm.updateValueAndValidity({ onlySelf: true, emitEvent: false })


  }


  /**
   * setta il validatore per piAltro in funzione dei campi in input
   */
  setPIAltroValidators(risposta_prev: string, piTrattamentoPsicoterapeutico: string,
                       piTrattamentoFarmacologico: string, piInterventiRiabilitativi: string,
                       piInserimentiLavorativi: string, piInterventoSociale: string,
                       piColloquiSostegno: string, piCoinvolgimentoFamiliari: string,
                       piAccertamentiMedicoLegali: string, piInvioInStruttura: string,
                       piInvioInOspedale: string, piConsultazioneValutazione: string) {

    if ((risposta_prev == "1" || risposta_prev == "2")
      && this.fieldIsFalse(piTrattamentoPsicoterapeutico)
      && this.fieldIsFalse(piTrattamentoFarmacologico) && this.fieldIsFalse(piInterventiRiabilitativi) &&
      this.fieldIsFalse(piInserimentiLavorativi) && this.fieldIsFalse(piInterventoSociale)
      && this.fieldIsFalse(piColloquiSostegno)
      && this.fieldIsFalse(piCoinvolgimentoFamiliari) && this.fieldIsFalse(piAccertamentiMedicoLegali)
      && this.fieldIsFalse(piInvioInStruttura)
      && this.fieldIsFalse(piConsultazioneValutazione)) {
      this.interventoForm.controls['piAltro'].setValidators([Validators.required])
      this.interventoForm.controls['piAltro'].updateValueAndValidity({onlySelf: true})
    } else if (this.fieldIsFalse(piConsultazioneValutazione) && risposta_prev == "3") {
      this.interventoForm.controls['piAltro'].setValidators([Validators.required])
      this.interventoForm.controls['piAltro'].updateValueAndValidity({onlySelf: true})
    } else {
      this.interventoForm.controls['piAltro'].removeValidators([Validators.required])
      this.interventoForm.controls['piAltro'].updateValueAndValidity({onlySelf: true})
    }


    // se qualcuno e no
    if (this.fieldIsTrue(piTrattamentoPsicoterapeutico)
      || this.fieldIsTrue(piTrattamentoFarmacologico) || this.fieldIsTrue(piInterventiRiabilitativi)
      || this.fieldIsTrue(piInserimentiLavorativi) || this.fieldIsTrue(piInterventoSociale)
      || this.fieldIsTrue(piColloquiSostegno) || this.fieldIsTrue(piCoinvolgimentoFamiliari)
      || this.fieldIsTrue(piAccertamentiMedicoLegali) || this.fieldIsTrue(piInvioInStruttura)) {
      this.interventoForm.controls['piAltro'].removeValidators([Validators.required])
      this.interventoForm.controls['piAltro'].updateValueAndValidity({onlySelf: true})
    }

  }

  setIeAltroValidators(ieInvioSrsSS: string, invioOSP: string, iePsicot: string, ieFarma: string, ieRiab: string, ieLav: string,
                       ieIntsoc: string, ieColl: string, ieFam: string, ieMleg: string, ieCons: string) {

    if (this.fieldIsFalse(iePsicot) && this.fieldIsFalse(ieFarma) && this.fieldIsFalse(ieRiab) && this.fieldIsFalse(ieLav)
      && this.fieldIsFalse(ieIntsoc) && this.fieldIsFalse(ieColl) && this.fieldIsFalse(ieFam) && this.fieldIsFalse(ieMleg)
      && this.fieldIsFalse(ieInvioSrsSS) && this.fieldIsFalse(invioOSP) && this.fieldIsFalse(ieCons)) {

      this.interventoForm.controls['ieAltro'].setValidators([Validators.required])
      this.interventoForm.controls['ieAltro'].updateValueAndValidity({onlySelf: true})


    } else if (this.fieldIsFalse(ieRiab) && this.fieldIsFalse(ieLav) && this.fieldIsFalse(ieIntsoc)
      && this.fieldIsFalse(ieColl) && this.fieldIsFalse(ieFam)) {

      this.interventoForm.controls['ieAltro'].setValidators([Validators.required])
      this.interventoForm.controls['ieAltro'].updateValueAndValidity({onlySelf: true})

    } else {


      this.interventoForm.controls['ieAltro'].removeValidators([Validators.required])
      this.interventoForm.controls['ieAltro'].updateValueAndValidity({onlySelf: true})

    }


    if (this.fieldIsTrue(iePsicot) || this.fieldIsTrue(ieFarma) || this.fieldIsTrue(ieRiab)
      || this.fieldIsTrue(ieLav) || this.fieldIsTrue(ieIntsoc) || this.fieldIsTrue(ieColl) || this.fieldIsTrue(ieFam)
      || this.fieldIsTrue(ieMleg) || this.fieldIsTrue(ieInvioSrsSS) || this.fieldIsTrue(invioOSP) || this.fieldIsTrue(ieCons)) {


      this.interventoForm.controls['ieAltro'].removeValidators([Validators.required])
      this.interventoForm.controls['ieAltro'].updateValueAndValidity({onlySelf: true})


    }
  }


  fieldIsFalse(value: string) {

    return value == "false"
  }

  fieldIsTrue(value: string) {
    return value == "true"
  }


  rispostaPrevalenteIsIn123(value: string) {

    return value != "" && value == "1" || value == "2" || value == "3"

  }

  rispostaPrevalenteIs3(value: string) {

    return value == "3"

  }

  contattoHasDataChiusura(contatto: Contatto) {
    return contatto.dataChiusura != undefined && contatto.dataChiusura != null
  }

  cancellaIntervento(id: number) {


    this.modalService.dismissAll()
    this.blockUI.start("Cancellazione  in corso")
    this.apiService.cancellaIntervento(id).subscribe({
      next: (res) => {
        this.toastService.showSuccess('Cancellazione effettuata con successo');
        this.refreshData()
        this.back();
      },
      error: (err) => {

        console.log(err);

        this.blockUI.stop()
        this.toastService.showError('acaduto un errore durante la cancellazione');
      },
      complete: () => {

        this.blockUI.stop()


      }
    })
  }

  loadRispostePrevalentiList() {

    this.apiService.getAllRispostePrevalenti().subscribe(res => {

      this.listRispotePrevalenti = res
    })
  }

  refreshData() {

    this.sessionService.setRefreshdata(true)
  }

  observeStrutturaChange() {


    this.sessionService.strutturaChanged.subscribe(res => {

      if (res) {
        this.router.navigate(['ricerca-anagrafica'])
      }
    })
  }

  limitMinDate(contatto: Contatto) {

    let contattoDate = this.sessionService.convertToDateStruct(new Date(contatto.dataApertura!!))

    this.minDate = {year: contattoDate?.year!!, month: contattoDate?.month!!, day: contattoDate?.day!!}

  }

  limitMaxDate() {

    let todayDate = this.sessionService.convertToDateStruct(new Date())


    if (this.contatto?.dataChiusura) {
      const itsort = this.contatto.interventiList?this.contatto.interventiList.sort((a, b) => /*a.id && b.id && a.id <= b.id ? -1 : 1*/this.getTime(a.data)-this.getTime(b.data)):[];

      let dataChiusuraContatto = this.sessionService.convertToDateStruct(!itsort || itsort?.length < 1 ? new Date(this.contatto.dataChiusura!!) : new Date(itsort![itsort?.length! - 1].data!));
      this.maxDate = {
        year: dataChiusuraContatto?.year!!,
        month: dataChiusuraContatto?.month!!,
        day: dataChiusuraContatto?.day!!
      }

    } else {

      this.maxDate = {year: todayDate?.year!!, month: todayDate?.month!!, day: todayDate?.day!!}

    }
  }
  private getTime(date?: Date) {
    return date != null ? new Date(date).getTime() : 0;
  }
  testForm() {
    new CommonsUtil().printFormValidationErrors(this.interventoForm);
  }

  public OPERATORE_STRUTTURA: String = "operatore-struttura";
  public OPERATORE_ASL: String = "operatore-asl";
  public OPERATORE_DSM: String = "operatore-dsm";
  public OPERATORE_REGIONE: String = "operatore-regione";
  auditIntervento: InterventoAudit[] = [];
  storicoTitle: any;

  public getRuolo(ruolo: String) {


    if (this.mainUser) {

      if (this.mainUser.nomeRuolo == ruolo) {

        return true;

      }

    }

    return false;

  }


  openAuditDialog(audit: any) {
    this.storicoTitle = "";
    this.apiService.getInterventoAuditByIdAndStruttura(this.intervento?.id!!, this.sessionService.getSelectedStrutturaFromStorage()?.codice!).subscribe(res => {

      if (res) {

        this.auditIntervento = res;

      }

    });
    this.modalService.open(audit, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
      scrollable: true
    }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

      if (this.closeResult === 'Closed with: Back click') {
        this.back()

      }
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;


    });
  }

  private isFirstInterventoOfListContatto() {
    const itsort = this.contatto.interventiList?this.contatto.interventiList.sort((a, b) => a.id && b.id && a.id >= b.id ? -1 : 1):[];
    const isIntervnto = itsort.findIndex(value => value.id == this.intervento.id) == itsort?.length! - 1;
    if (isIntervnto) {
      return true;
    } else {
      return false;
    }
  }
}
