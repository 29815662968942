<div class="d-flex justify-content-between pt-3">
  <ul *ngIf="page.content.length!=0" class="pagination"
      demon-list="flussi.pages">

    <!-- prima -->
    <li *ngIf="paginazioneLogic.primaVisibile(paginaLogic())" (click)="cambiaPagina(1)"
        class="page-item page-icon" demon-paging="prev"
    ><a aria-label="Precedente" href="javascript:" class="page-link" tabindex="-1" aria-disabled="true">
      <svg class="s-icon icon-xxs">
        <use href="#drop-left"></use>
      </svg>
    </a></li>

    <!-- indietro -->
    <li *ngIf="paginazioneLogic.indietroVisibile(paginaLogic())"
        (click)="cambiaPagina(paginazioneLogic.indietro(paginaLogic()))"
        class="page-item page-icon" demon-paging="prev"
    ><a aria-label="Precedente" href="javascript:" class="page-link" tabindex="-1" aria-disabled="true">
      indietro</a></li>


    <!-- pagine -->
    <li *ngFor="let indice of paginazioneLogic.numeriPagine(paginaLogic())"
        [class]="classepaginatoreAttivo(indice)"
        (click)="cambiaPagina(indice)"
        class="page-item" demon-paging="page">
      <a href="javascript:" class="page-link">{{indice}}</a>
    </li>

    <!-- avanti -->
    <li *ngIf="paginazioneLogic.avantiVisibile(paginaLogic())"
        (click)="cambiaPagina(paginazioneLogic.avanti(paginaLogic()))"
        class="page-item page-icon" demon-paging="prev">
      <a aria-label="Precedente" href="javascript:" class="page-link" tabindex="-1" aria-disabled="true">
        Avanti</a>
    </li>

    <!-- ultima -->
    <li *ngIf="paginazioneLogic.ultimaVisibile(paginaLogic())"
        (click)="cambiaPagina(page.totalPages)" class="page-item page-icon"><a aria-label="Precedente" href="javascript:" class="page-link" tabindex="-1"
                               aria-disabled="true">
      <svg class="s-icon icon-xxs">
        <use href="#drop-right"></use>
      </svg>
    </a></li>
  </ul>

  <select name="pageSize" class="form-control form-pagination ng-untouched ng-pristine ng-valid disabled"
          style="width: auto;"
          [(ngModel)]="dimensionePaginaDefault"
          (change)="cambioDimensionePaginaSelezionata($event)">
    <option value="{{10}}">10 per pagina</option>
    <option value="{{20}}">20 per pagina</option>
    <option value="{{50}}">50 per pagina</option>
    <option value="{{100}}">100 per pagina</option>
  </select>
</div>
