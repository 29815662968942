import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import CodiceFiscale from 'codice-fiscale-js';

export function codiceFiscaleValidator(control: AbstractControl): ValidationErrors | null {

  const controlCodiceFiscale = control.get('codiceFiscale')

  const controlNome = control.get('nome')

  const controlCognome = control.get('cognome')

  const controlSesso = control.get('sesso')

  const controlDataNascita = control.get('dataNascita')

  const controlDescrizioneComuneNascita = control.get('descrizioneComuneNascita')

  let cfModel;
  if (controlCodiceFiscale?.value != '') {
    if(controlCodiceFiscale?.value.length==16){
      if(controlCodiceFiscale?.value.toUpperCase().startsWith('ENI')||controlCodiceFiscale?.value.toUpperCase().startsWith('STP')){
        if(!isNaN(+(controlCodiceFiscale?.value.substring(3,16)))){
          return null;
        }
      }
    }
    try {
      cfModel = new CodiceFiscale(controlCodiceFiscale!.value)
    } catch (error) {
      controlCodiceFiscale!.setErrors({ "codiceCfNonValid": true })
    }
  }

  if (controlCodiceFiscale?.value != ''
    && controlNome?.value != ''
    && controlCognome?.value != ''
    && controlSesso?.value != ''
    && controlDataNascita?.value != ''
    && controlDescrizioneComuneNascita?.value != undefined
    && controlDescrizioneComuneNascita?.value != 'undefined'
    && controlDescrizioneComuneNascita?.value.codice != '') {

    if (cfModel != null && cfModel.isValid()) {

      try {

        const existingFiscalCode = cfModel.toJSON()

        let sessoFemina = "F"

        let sessoMaschio = "M"

        let sessi = [sessoFemina, sessoMaschio]
        let insertedSesso: any

        if (sessi.includes(controlSesso?.value)) {
          insertedSesso = controlSesso?.value;
        }
        else {
          // insertedSesso = "M"
          insertedSesso = controlSesso?.value
          //          controlSesso.setErrors({ "genderNonValid": true })
        }
        if (insertedSesso == 'N') {

          controlSesso!.setErrors({ "genderError": true })
          return null;
        }
        const insertedFiscalModel = new CodiceFiscale({
          name: controlNome?.value,
          surname: controlCognome?.value,
          gender: insertedSesso,
          day: controlDataNascita?.value.day,
          month: controlDataNascita?.value.month,
          year: controlDataNascita?.value.year,
          birthplace: controlDescrizioneComuneNascita?.value.descrizione,
          birthplaceProvincia: ''
        })





        if (insertedFiscalModel.nameCode != existingFiscalCode.name) {


          controlNome!.setErrors({ "nomeError": true })

        }


        if (insertedFiscalModel.surnameCode != existingFiscalCode.surname) {


          controlCognome!.setErrors({ "cognomeError": true })

        }

        if (controlDataNascita?.value?.day != existingFiscalCode.day
          || controlDataNascita?.value?.month != existingFiscalCode.month
          || controlDataNascita?.value?.year != existingFiscalCode.year) {
          controlDataNascita!.setErrors({ "dataNascitaError": true })
        }




        // console.log("inserted -> ",insertedFiscalModel?.birthplace.nome);
        // console.log("expected -> ",existingFiscalCode.birthplace);

        if (insertedFiscalModel?.birthplace.prov != existingFiscalCode.birthplaceProvincia) {



          controlDescrizioneComuneNascita!.setErrors({ "comuneError": true })
        }

        if (insertedFiscalModel?.gender != existingFiscalCode.gender) {

          controlSesso!.setErrors({ "genderError": true })

        }




      } catch (error) {


      }

    } else {

      controlCodiceFiscale!.setErrors({ "codiceCfNonValid": true })
    }

  }

  return null

}

export function codiceNonValidoValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {

    const codice = control;
    if (codice.valid && codice.value.length >= 16) {

      let cfModel
      try {
        cfModel = new CodiceFiscale(codice!!.value)

      } catch (error) {


        return { cfNotValid: true };
      }

    }
    return null
  }
}

export function emptyComuneNascitaValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {

    const comune = control;

    if (comune.value != undefined) {
      if (comune.value.descrizione == "" && comune.value.codice == "") {


        return { emptyComuneError: true };
      }

    } else {

      return { wrongComuneError: true };

    }

    return null
  }
}

export function dataNonValidaValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {

    const data = control;

    let isDateObject = data.value instanceof Object
    if(!isDateObject){
      return { wrongDateFormat: true };
    }

    return null
  }
}

export function emptyDiagnosiValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {

    const diagnosi = control;

    if (diagnosi.value != undefined) {
      if (diagnosi.value.descrizione == undefined && diagnosi.value.codice == undefined) {


        return { emptyDiagnosiError: true };
      }

    } else {
      return { wrongDiagnosiError: true };
    }


    return null
  }
}

export function emptyChiusuraDiagnosiValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {

    const diagnosi = control;

    if (diagnosi.value === undefined) {

      return { wrongDiagnosiError: true };


    }


    return null
  }
}

export function scalaHonosValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {

    const scalaHonos = control;

    if(scalaHonos.value != null && scalaHonos.value !== "" && ( scalaHonos.value > 48 || scalaHonos.value < 0)){

      return { scalaHonosNonValida: true };

    } else {

      return null

    }


  }

}

export function scalaBprsValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {

    const scalaBprs = control;

    if(scalaBprs.value != null && scalaBprs.value !== "" && ( scalaBprs.value > 168 || Number(scalaBprs.value) < 1)){

      return { scalaBprsNonValida: true };

    } else {

      return null

    }

  }

}
