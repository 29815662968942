<div class="container py-3" demon-cf="importazionedati" demon-cf-id="anagrafica" id="demonView6-3" demon-ev="true">
  <div class="card">
    <div class="card-header">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a routerLink="/dashboard"
                                         (click)="$event.preventDefault();">Dashboard</a></li>
        </ol>
      </nav>
      <div class="row">
        <div class="title-3 col-9" demon-anagrafica="titolo"> Import dati</div>
      </div>
    </div>
  </div>
  <div class="card mt-5">
    <div class="card-body">
      <div class="table-responsive">
        <div class="list-table table-even">

          <table class="table-lg">
            <thead app-thead [theadColumns]="theadColumns" [tableSorter]="tableSorter"
                   (changeOrder)="changeOrder($event)">
            </thead>

            <tbody>
            <tr *ngIf="pagina.loading">
              <td [attr.colspan]="theadColumns.length">
                <div class="spinner-border centered"></div>
              </td>
            </tr>
            <tr *ngIf="!pagina.loading && pagina.content.length<1">
              <td [attr.colspan]="theadColumns.length">
                <div  style="text-align: center">Non ci sono risultati per la ricerca effettuata</div>
              </td>
            </tr>
            <ng-container *ngIf="!pagina.loading">
              <tr *ngFor="let item of pgrouped?.values()" class="tr-hover">
                <td class="td-hover" style="vertical-align: middle">{{item[0].timestamp | date:'yyyy-MM-dd hh:mm:s'}}</td>
                <td class="td-hover" style="vertical-align: middle">{{item[0].utente}}</td>
                <td class="td-hover" style="vertical-align: middle">{{item[0].struttura}}</td>
                <td class="td-hover" style="vertical-align: middle">{{item[0].tipoFile}}</td>
                <td class="td-hover" style="vertical-align: middle"><a *ngIf="canDownload(item)" class="btn btn-link" (click)="downloadXmlImport(item[0].id!, 'report', item[0].reportScarto!)">{{item[0].reportScarto}}</a></td>
                <td class="td-hover" style="vertical-align: middle"><div *ngFor="let i of item; let j = index" style="{{j>0?'border-top:1px solid #ddd':''}}"><button class="btn btn-link"(click)="downloadXmlImport(i.id!, 'storico_file', i.nomeFile!)">{{i.nomeFile}}</button></div></td>
                <td class="td-hover" style="vertical-align: middle"><div *ngFor="let i of item; let j = index" style="line-height: 50px; {{j>0?'border-top:1px solid #ddd':''}}">{{i.id}}</div></td>
                <td class="td-hover" style="vertical-align: middle; text-align: center"><div *ngFor="let i of item; let j = index" style="line-height: 50px;{{j>0?'border-top:1px solid #ddd':''}}">
                  <svg *ngIf="i.scartoXsd" class="s-icon icon-sm text-danger icon-status">
                    <use xlink:href="assets/icons/sprite.svg#warning"></use>
                  </svg>
                  <svg *ngIf="!i.scartoXsd || i.scartoXsd=='false'" class="s-icon icon-sm text-success icon-status">
                    <use xlink:href="assets/icons/sprite.svg#success"></use>
                  </svg>
                </div></td>
                <!--                <td class="td-hover"><button class="btn btn-link">{{item.nomeFile}}</button></td>-->
                <td class="td-hover" style="vertical-align: middle; text-align: center"><div *ngFor="let i of item; let j = index" style="line-height: 50px;{{j>0?'border-top:1px solid #ddd':''}}">{{i.caricati}}</div></td>
                <td class="td-hover" style="vertical-align: middle; text-align: center"><div *ngFor="let i of item; let j = index" style="line-height: 50px;{{j>0?'border-top:1px solid #ddd':''}}">{{i.errati}}</div></td>
                <td class="td-hover" style="vertical-align: middle; text-align: center"><div *ngFor="let i of item; let j = index" style="line-height: 50px;{{j>0?'border-top:1px solid #ddd':''}}">{{i.totali}}</div></td>
<!--                <td class="td-hover"><a class="btn btn-link" >{{item.reportScarto}}</a></td>-->
              </tr>
            </ng-container>
            </tbody>
          </table>
        </div>

      </div>

      <!-- PAGINATION -->
      <app-paginator [page]="pagina" (cambiaPaginaEvent)="cambiaPagina($event)"></app-paginator>

    </div>
  </div>
</div>
