import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Cittadinanza, Comune, Contatto, DettaglioAnagrafica, Intervento, Regione, StatoCivile, StatoEstero, DiagnosiEScale, Presenza, Prestazione } from 'src/app/model/Models';
import { ApiService } from 'src/app/services/api.service';
import { SessionService } from 'src/app/services/session.service';
import { ToastService } from 'src/app/services/toast.service';
import {firstValueFrom} from "rxjs";

@Component({
  templateUrl: './dettaglio-dati-in-ingresso.component.html',
  styleUrls: ['./dettaglio-dati-in-ingresso.component.css']
})
export class DettaglioDatiInIngressoComponent implements OnInit {

  id: string | null = '';
  statoAttuale: string | null = '';
  isNotDaValidare: boolean = false;

  isActiveAnagrafica: boolean = true;
  isActiveContatti: boolean = false;
  isActiveInterventi: boolean = false;
  isActiveDiagnosi: boolean = false;
  isActivePresenze: boolean = false;
  isActivePrestazioni: boolean = false;

  listStatiCivili: StatoCivile[] = [];
  dettaglioAnagrafica?: DettaglioAnagrafica | null
  listContatti: Contatto[] = [];
  listInterventi: Intervento[] = [];
  listDiagnosiEScale: DiagnosiEScale[] = [];
  listPresenze: Presenza[] = [];
  listPrestazione: Prestazione[] = [];


  scartiContatti: {[key: number]: string} = {};
  scartiInterventi: {[key: number]: string} = {};
  scartiDiagnosi: {[key: number]: string} = {};
  scartiPresenze: {[key: number]: string} = {};
  scartiPrestazioni: {[key: number]: string} = {};

  validaContatti: number[] = [];
  validaInterventi: number[] = [];
  validaDiagnosi: number[] = [];
  validaPresenze: number[] = [];
  validaPrestazioni: number[] = [];

  notCheckedContatti: number[] = [];
  notCheckedInterventi: number[] = [];
  notCheckedDiagnosi: number[] = [];
  notCheckedPresenze: number[] = [];
  notCheckedPrestazioni: number[] = [];


  constructor( private route: ActivatedRoute, private router: Router,
    private toastService: ToastService,
    private apiService: ApiService ) { }

  ngOnInit(): void {

    this.id = this.route.snapshot.paramMap.get('id');
    this.statoAttuale = this.route.snapshot.paramMap.get('stato');

    this.init();
  }


  async getAllStatiCivili() {
    this.apiService.getAllStatiCivili().subscribe(res => {
      this.listStatiCivili = res
    })

  }

  async getAnagraficaContatto()
  {

    this.apiService.getAnagraficaAslValidator( this.id ).subscribe((data: any) => {
        // console.log(data);
        this.dettaglioAnagrafica = data;

      });

  }


  async getContattiUtente()
  {

    this.apiService.getContattiAslValidator( this.id, this.statoAttuale ).subscribe((data: any) => {
        // console.log(data);
        if(data != undefined || data!= null)
        {
          this.listContatti = data;

          data.forEach((value: { id: string; })=> {
            this.notCheckedContatti.push(Number(value.id));
          })
        }

      });

  }

  async getInterventiUtente()
  {

    this.apiService.getInterventiAslValidator( this.id, this.statoAttuale ).subscribe((data: any) => {
      // console.log("Interventi: ");
      // console.log(data);
        if(data != undefined || data!= null)
        {
          this.listInterventi = data;

          data.forEach((value: { id: string; })=> {
            console.log(data);
            this.notCheckedInterventi.push(Number(value.id));
          })
        }

      });

  }

  async getDiagnosiEScaleUtente()
  {

    this.apiService.getDiagnosiEScaleAslValidator( this.id, this.statoAttuale ).subscribe((data: any) => {
        this.listDiagnosiEScale = data;
      // console.log(data);
        if(data != undefined || data!= null)
        {
          this.listDiagnosiEScale = data;

          data.forEach((value: { id: string; })=> {
            this.notCheckedDiagnosi.push(Number(value.id));
          })
        }

      });

  }

  async getPresenzeUtente()
  {

    this.apiService.getPresenzeAslValidator( this.id, this.statoAttuale ).subscribe((data: any) => {
      // console.log(data);
        if(data != undefined || data!= null)
        {
          this.listPresenze = data;

          data.forEach((value: { id: string; })=> {
            this.notCheckedPresenze.push(Number(value.id));
          })
        }

      });

  }

  async getPrestazioneUtente()
  {

    this.apiService.getPrestazioneAslValidator( this.id, this.statoAttuale ).subscribe((data: any) => {
      // console.log(data);
        if(data != undefined || data!= null)
        {
          console.log("dataaaa:"+ data)
          console.log(data);
          this.listPrestazione= data;

          data.forEach((value: { id: string; })=> {
            this.notCheckedPrestazioni.push(Number(value.id));
          })
        }

      });

  }


  onChangeValida( event: any , type: string ){
    if ( type=='contatti' )
    {
      if(event.target.checked)
      {

        delete this.scartiContatti[ Number(event.target.value) ];

        this.validaContatti.push(Number(event.target.value));

        if( this.notCheckedContatti.indexOf(Number(event.target.value)) != -1 )
          this.notCheckedContatti.splice( this.notCheckedContatti.indexOf(Number(event.target.value)),1);

      }
      else if ( this.validaContatti.indexOf(Number(event.target.value)) != -1 )
      {
        this.validaContatti.splice( this.validaContatti.indexOf(Number(event.target.value)),1);

        this.notCheckedContatti.push(Number(event.target.value));
      }
    }

    if ( type=='interventi' )
    {
      if(event.target.checked)
      {

        delete this.scartiInterventi[ Number(event.target.value) ];

        this.validaInterventi.push(Number(event.target.value));

        if( this.notCheckedInterventi.indexOf(Number(event.target.value)) != -1 )
          this.notCheckedInterventi.splice( this.notCheckedInterventi.indexOf(Number(event.target.value)),1);

      }
      else if ( this.validaInterventi.indexOf(Number(event.target.value)) != -1 )
      {
        this.validaInterventi.splice( this.validaInterventi.indexOf(Number(event.target.value)),1);

        this.notCheckedInterventi.push(Number(event.target.value));
      }
    }

    if ( type=='diagnosi' )
    {
      if(event.target.checked)
      {

        delete this.scartiDiagnosi[ Number(event.target.value) ];

        this.validaDiagnosi.push(Number(event.target.value));

        if( this.notCheckedDiagnosi.indexOf(Number(event.target.value)) != -1 )
          this.notCheckedDiagnosi.splice( this.notCheckedDiagnosi.indexOf(Number(event.target.value)),1);

      }
      else if ( this.validaDiagnosi.indexOf(Number(event.target.value)) != -1 )
      {
        this.validaDiagnosi.splice( this.validaDiagnosi.indexOf(Number(event.target.value)),1);

        this.notCheckedDiagnosi.push(Number(event.target.value));
      }
    }

    if ( type=='presenze' )
    {
      if(event.target.checked)
      {

        delete this.scartiPresenze[ Number(event.target.value) ];

        this.validaPresenze.push(Number(event.target.value));

        if( this.notCheckedPresenze.indexOf(Number(event.target.value)) != -1 )
          this.notCheckedPresenze.splice( this.notCheckedPresenze.indexOf(Number(event.target.value)),1);

      }
      else if ( this.validaPresenze.indexOf(Number(event.target.value)) != -1 )
      {
        this.validaPresenze.splice( this.validaPresenze.indexOf(Number(event.target.value)),1);

        this.notCheckedPresenze.push(Number(event.target.value));
      }
    }

    if ( type=='prestazioni' )
    {
      if(event.target.checked)
      {

        delete this.scartiPrestazioni[ Number(event.target.value) ];

        this.validaPrestazioni.push(Number(event.target.value));

        if( this.notCheckedPrestazioni.indexOf(Number(event.target.value)) != -1 )
          this.notCheckedPrestazioni.splice( this.notCheckedPrestazioni.indexOf(Number(event.target.value)),1);

      }
      else if ( this.validaPrestazioni.indexOf(Number(event.target.value)) != -1 )
      {
        this.validaPrestazioni.splice( this.validaPrestazioni.indexOf(Number(event.target.value)),1);

        this.notCheckedPrestazioni.push(Number(event.target.value));
      }
    }
  }

  onChangeScarta(  event: any , type: string ){
    if ( type=='contatti' )
    {
      if(event.target.checked)
      {
        if( this.validaContatti.indexOf(Number(event.target.value)) != -1 )
          this.validaContatti.splice( this.validaContatti.indexOf(Number(event.target.value)),1);


        this.scartiContatti[Number(event.target.value)] = '';

        if( this.notCheckedContatti.indexOf(Number(event.target.value)) != -1 )
          this.notCheckedContatti.splice( this.notCheckedContatti.indexOf(Number(event.target.value)),1);

      }
      else
      {
        delete this.scartiContatti[ Number(event.target.value) ];

        this.notCheckedContatti.push(Number(event.target.value));
      }
    }
    if ( type=='interventi' )
    {
      if(event.target.checked)
      {
        if( this.validaInterventi.indexOf(Number(event.target.value)) != -1 )
          this.validaInterventi.splice( this.validaInterventi.indexOf(Number(event.target.value)),1);

        this.scartiInterventi[Number(event.target.value)] = '';

        if( this.notCheckedInterventi.indexOf(Number(event.target.value)) != -1 )
          this.notCheckedInterventi.splice( this.notCheckedInterventi.indexOf(Number(event.target.value)),1);

      }
      else
      {
        delete this.scartiInterventi[ Number(event.target.value) ];

        this.notCheckedInterventi.push(Number(event.target.value));

      }
    }
    if ( type=='diagnosi' )
    {
      if(event.target.checked)
      {
        if( this.validaDiagnosi.indexOf(Number(event.target.value)) != -1 )
          this.validaDiagnosi.splice( this.validaDiagnosi.indexOf(Number(event.target.value)),1);

        this.scartiDiagnosi[Number(event.target.value)] = '';

        if( this.notCheckedDiagnosi.indexOf(Number(event.target.value)) != -1 )
          this.notCheckedDiagnosi.splice( this.notCheckedDiagnosi.indexOf(Number(event.target.value)),1);

      }
      else
      {
        delete this.scartiDiagnosi[ Number(event.target.value) ];

        this.notCheckedDiagnosi.push(Number(event.target.value));

      }
    }
    if ( type=='presenze' )
    {
      if(event.target.checked)
      {
        if( this.validaPresenze.indexOf(Number(event.target.value)) != -1 )
          this.validaPresenze.splice( this.validaPresenze.indexOf(Number(event.target.value)),1);

        this.scartiPresenze[Number(event.target.value)] = '';

        if( this.notCheckedPresenze.indexOf(Number(event.target.value)) != -1 )
          this.notCheckedPresenze.splice( this.notCheckedPresenze.indexOf(Number(event.target.value)),1);

      }
      else
      {
        delete this.scartiPresenze[ Number(event.target.value) ];

        this.notCheckedPresenze.push(Number(event.target.value));

      }
    }
    if ( type=='prestazioni' )
    {
      if(event.target.checked)
      {
        if( this.validaPrestazioni.indexOf(Number(event.target.value)) != -1 )
          this.validaPrestazioni.splice( this.validaPrestazioni.indexOf(Number(event.target.value)),1);

        this.scartiPrestazioni[Number(event.target.value)] = '';

        if( this.notCheckedPrestazioni.indexOf(Number(event.target.value)) != -1 )
          this.notCheckedPrestazioni.splice( this.notCheckedPrestazioni.indexOf(Number(event.target.value)),1);

      }
      else
      {
        delete this.scartiPrestazioni[ Number(event.target.value) ];

        this.notCheckedPrestazioni.push(Number(event.target.value));

      }
    }

    console.log(this.scartiContatti )
  }



  isSelectedValidati(id: number | undefined, type: string) {
    let ret: boolean = false;
    if(type == 'contatti')
    {
      this.validaContatti.forEach(value => {
        if (value == id) {
          ret = true;
        }
      })
    }
    if(type == 'interventi')
    {
      this.validaInterventi.forEach(value => {
        if (value == id) {
          ret = true;
        }
      })
    }
    if(type == 'diagnosi')
    {
      this.validaDiagnosi.forEach(value => {
        if (value == id) {
          ret = true;
        }
      })
    }
    if(type == 'presenze')
    {
      this.validaPresenze.forEach(value => {
        if (value == id) {
          ret = true;
        }
      })
    }
    if(type == 'prestazioni')
    {
      this.validaPrestazioni.forEach(value => {
        if (value == id) {
          ret = true;
        }
      })
    }
    return ret;
  }


  isSelectedScartati(id: number | undefined, type: string) {
    let ret: boolean = false;
    if(type == 'contatti')
    {
      if(this.scartiContatti[Number(id)] != undefined)
        ret = true;
    }
    if(type == 'interventi')
    {
      if(this.scartiInterventi[Number(id)] != undefined)
        ret = true;
    }
    if(type == 'diagnosi')
    {
      if(this.scartiDiagnosi[Number(id)] != undefined)
        ret = true;
    }
    if(type == 'presenze')
    {
      if(this.scartiPresenze[Number(id)] != undefined)
        ret = true;
    }
    if(type == 'prestazioni')
    {
      if(this.scartiPrestazioni[Number(id)] != undefined)
        ret = true;
    }
    return ret;
  }

  changeMotivazioniScarto(id: number | undefined, type: string, event: any  )
  {

    if(type == 'contatti')
    {
      this.scartiContatti[Number(id)] = event.target.value;
    }
    if(type == 'interventi')
    {
      this.scartiInterventi[Number(id)] = event.target.value;
    }
    if(type == 'diagnosi')
    {
      this.scartiDiagnosi[Number(id)] = event.target.value;
    }
    if(type == 'presenze')
    {
      this.scartiPresenze[Number(id)] = event.target.value;   true;
    }
    if(type == 'prestazioni')
    {
      this.scartiPrestazioni[Number(id)] = event.target.value;   true;
    }

  }

  checkContattiIsToValidate( contatti: Contatto[])
  {

    var toValidate = false;
    contatti.forEach(contatto => {
      if(contatto.statoAttuale.id == 2)
        toValidate = true
    });

    return toValidate
  }

  salvaTutto()
  {

    var emptyMotivation = false;
    for (const k in this.scartiContatti )
      if( this.scartiContatti[Number(k)] == undefined ||  this.scartiContatti[Number(k)] == null || this.scartiContatti[Number(k)].length == 0)
        emptyMotivation = true;

    for (const k in this.scartiInterventi )
      if( this.scartiInterventi[Number(k)] == undefined ||  this.scartiInterventi[Number(k)] == null || this.scartiInterventi[Number(k)].length == 0)
        emptyMotivation = true;

    for (const k in this.scartiDiagnosi )
      if( this.scartiDiagnosi[Number(k)] == undefined ||  this.scartiDiagnosi[Number(k)] == null || this.scartiDiagnosi[Number(k)].length == 0)
        emptyMotivation = true;

    for (const k in this.scartiPresenze )
      if( this.scartiPresenze[Number(k)] == undefined ||  this.scartiPresenze[Number(k)] == null || this.scartiPresenze[Number(k)].length == 0)
        emptyMotivation = true;

    for (const k in this.scartiPrestazioni )
      if( this.scartiPrestazioni[Number(k)] == undefined ||  this.scartiPrestazioni[Number(k)] == null || this.scartiPrestazioni[Number(k)].length == 0)
        emptyMotivation = true;



    if( emptyMotivation )
    {
      alert("Inserire la motivazione dello scarto!");
    }
    else if(this.notCheckedContatti.length > 0 && this.checkContattiIsToValidate(this.listContatti) )
    {
      alert("è obbligatorio Validare o Scartare tutti i Contatti!");
    }
    else if(this.notCheckedInterventi.length > 0)
    {
      alert("è obbligatorio Validare o Scartare tutti gli Interventi!");
    }
    else if(this.notCheckedDiagnosi.length > 0)
    {
      alert("è obbligatorio Validare o Scartare tutte le Diagnosi!");
    }
    else if(this.notCheckedPresenze.length > 0)
    {
      alert("è obbligatorio Validare o Scartare tutte le Presenze!");
    }
    else if(this.notCheckedPrestazioni.length > 0)
    {
      alert("è obbligatorio Validare o Scartare tutte le Prestazioni!");
    }
    else
    {


      if( this.validaContatti.length > 0)
        this.apiService.updateStatusContatti(3, '', this.validaContatti ).subscribe();


      if( this.validaInterventi.length > 0)
        this.validaInterventi.forEach(value => {
          this.apiService.updateStatusIntervento(value, 3, '' ).subscribe();
        })


      if( this.validaDiagnosi.length > 0)
        this.validaDiagnosi.forEach(value => {
          this.apiService.updateStatusDiagnosi(value, 3, '' ).subscribe();
        })

      if( this.validaPresenze.length > 0)
        this.validaPresenze.forEach(value => {
          this.apiService.updateStatusPresenza(value, 3, '' ).subscribe();
        })

      if( this.validaPrestazioni.length > 0)
        this.validaPrestazioni.forEach(value => {
          this.apiService.updateStatusPrestazione(value, 3, '' ).subscribe();
        })

      for (const k in this.scartiContatti )
      {
        var listId: number[] = [];
        listId.push(Number(k));
        this.apiService.updateStatusContatti(4, this.scartiContatti [Number(k)], listId).subscribe();
      }


      for (const k in this.scartiInterventi )
        this.apiService.updateStatusIntervento( Number(k), 4, this.scartiInterventi[Number(k)] ).subscribe();

      for (const k in this.scartiDiagnosi )
        this.apiService.updateStatusDiagnosi( Number(k), 4, this.scartiDiagnosi[Number(k)] ).subscribe();

      for (const k in this.scartiPresenze )
        this.apiService.updateStatusPresenza( Number(k), 4, this.scartiPresenze[Number(k)] ).subscribe();

      for (const k in this.scartiPrestazioni )
        this.apiService.updateStatusPrestazione( Number(k), 4, this.scartiPrestazioni[Number(k)] ).subscribe();

      this.toastService.showSuccess("Processo di validazione effettuata con successo!");

      this.router.navigate(['dati-in-ingresso']);
      return true;
    }

    return true;

  }

  chiudi()
  {
    this.router.navigate(['dati-in-ingresso']);
  }

  async riabilitaModifica(id: number|undefined, tipo: string) {
      await firstValueFrom(this.apiService.riabilitaModifica(id!,tipo));
      this.init();
  }

  private init() {
    this.getAnagraficaContatto();

    this.getContattiUtente();

    this.getInterventiUtente();

    this.getDiagnosiEScaleUtente();

    this.getPresenzeUtente();

    this.getPrestazioneUtente();
  }
}
