import {Component, Input, OnInit} from '@angular/core';
import {
  Asl,
  Ativitta,
  CollazioneSocioAmbientale,
  Comune,
  Contatto,
  DettaglioAnagrafica,
  DettaglioPreseInCaricoDto,
  DettaglioPreseInCaricoPrestazioneDto,
  Distretto,
  Invalidita,
  Inviante,
  ModalitaConclusione,
  Operatore,
  PrecedenteContatto,
  Prestazione,
  Provedimento,
  Regione,
  RichiestaPrevalente,
  SedeIntervento,
  StatoCivile,
  StatoEstero,
  TipoPrestazione,
  TitoloStudio,
  TutelaGiuridica
} from "../../model/Models";
import {NgbModalRef} from "@ng-bootstrap/ng-bootstrap/modal/modal-ref";
import {firstValueFrom} from "rxjs";
import {ApiService} from "../../services/api.service";

@Component({
  selector: 'app-presa-in-carico-dettaglio-modal',
  templateUrl: './presa-in-carico-dettaglio-modal.component.html',
  styleUrls: ['./presa-in-carico-dettaglio-modal.component.css']
})
export class PresaInCaricoDettaglioModalComponent implements OnInit {
  @Input() public test: any;
  @Input() public instanceModalRef: NgbModalRef | undefined;


  @Input() public dettaglioAnagrafica?: DettaglioAnagrafica = new DettaglioAnagrafica();
  @Input() public listContati: Contatto[] = [];
  @Input() public listPrestazioni: DettaglioPreseInCaricoPrestazioneDto[] = []

  constructor(private apiService: ApiService) { }

  ngOnInit(): void {
    this.getStatiCivili()
    this.getAllStati()
    this.getAllRegioni()
    this.getAllComuni()
    this.getAllDistretti()
    this.getAllCollazioneSocioAmbientali()
    this.getAllAsl()
    this.getAllTitoliDiStudio()
    this.getAllAttivitta()
    this.getAllInvalidita()
    this.getAllTuteleGiuridice()
    this.getAllProvedimenti()
    this.loadOperatori()

    // this.apiService.getAnagrafica(113).subscribe(res =>{
    //    this.dettaglioAnagrafica = res;
    // });
    //
    // this.apiService.getAllAnagraficaContatti(113).subscribe(res => {
    //   this.listContati = res
    //   this.loadAllPrestazioniDegliContatti(this.listContati)
    // })
  }

  // loadAllPrestazioniDegliContatti(listContati: Contatto[]) {
  //   listContati.forEach(contato => {
  //     contato.prestazioniList?.forEach(prestazione => {
  //       prestazione.contatto = contato
  //       prestazione.dataInserimento = new Date(prestazione.dataInserimento!!)
  //       this.listPrestazioni.push(prestazione)
  //       this.listPrestazioni.forEach(pres => {
  //         console.log(pres.contatto?.id);
  //
  //       })
  //     })
  //   })
  // }


  chiudiBack() {
    this.instanceModalRef!.close('Back click')
  }

  chiudi() {
    this.instanceModalRef!.dismiss('Cross click')
  }


  // // ANAGRAFICA NAME
  // setStatoCivileName(codice: string): string | null {
  //   return 'dummy';
  // }
  //
  // setStatoName(codice: string): string | null {
  //   return 'dummy';
  // }
  //
  // setSessoName(sesso: string) {
  //   return 'dummy';
  // }
  //
  // setRegioneName(codice: string): string | null {
  //   return 'dummy';
  // }
  //
  // setComuneName(codice: string): string | null {
  //   return 'dummy';
  // }
  //
  // setDistrettoName(codice: string): string | null {
  //   return 'dummy';
  // }
  //
  // setAslName(codice: string): string | null {
  //   return 'dummy';
  // }
  //
  // setCollazioneSocioAmbientaleName(codice: string): string | null {
  //   return 'dummy';
  // }
  //
  // setTitoloDiStudioName(codice: string): string | null {
  //   return 'dummy';
  // }
  //
  // setAttivitaName(codice: string): string | null {
  //   return 'dummy';
  // }
  //
  // setInvaliditaName(codice: string): string | null {
  //   return 'dummy';
  // }
  //
  // setFamigliareInTratamentoName(codice: string) {
  //   return 'dummy';
  //
  // }
  //
  // setTuteleGiuridiceName(codice: string): string | null {
  //   return 'dummy';
  // }
  //
  // setProvedimentiName(codice: string): string | null {
  //   return 'dummy';
  // }
  //
  // setPrecedemnteInternatoName(codice: string) {
  //   return 'dummy';
  // }
  //
  //
  //
  // // CONTATTO NAME
  //
  // setRichiestaPrevName(codice: string): string | null {
  //   return 'dummy';
  // }
  //
  // setPrecedentiContatiName(codice: string): string | null {
  //   return 'dummy';
  // }
  //
  // setInviantiName(codice: string): string | null {
  //   return 'dummy';
  // }
  //
  // setModalitaConclusioneName(codice: string): string | null {
  //   return 'dummy';
  // }
  //
  // // PRESTAZIONI NAME
  //
  // setModalitaErrogazioneName(codice:string){
  //   return 'dummy';
  // }
  //
  // setSedeName(codice: string): string | null {
  //   return 'dummy';
  // }
  //
  // setOperatore1Name(codice:string){
  //   return 'dummy';
  // }



  listStatiCivili?: StatoCivile[]
  listStati: StatoEstero[] = []
  listRegioni: Regione[] = []
  listComuni: Comune[] = []
  listDistretti: Distretto[] = []
  listAsl: Asl[] = []
  listCollazioneSoci: CollazioneSocioAmbientale[] = []
  listTitoliDiStudio: TitoloStudio[] = []
  listAttivita: Ativitta[] = []
  listInvalidita: Invalidita[] = []
  listTuteleGiuridice: TutelaGiuridica[] = []
  listProvedimenti: Provedimento[] = []
  listRichiestePrevalenti: RichiestaPrevalente[] = []
  listInvianti: Inviante[] = []
  listPrecedentiContatti: PrecedenteContatto[] = []
  listaModalitaConclusione: ModalitaConclusione[] = []
  listTipoPrestazione: TipoPrestazione[] = []
  listSedeIntervento: SedeIntervento[] = []
  listOperatori: Operatore[] = []


  // ANAGRAFICA
  async getStatiCivili() {
    this.apiService.getAllStatiCivili().subscribe(res => {
      this.listStatiCivili = res
    })
  }

  async getAllStati() {

    this.apiService.getAllStatiEsteri().subscribe(res => {
      this.listStati = res
    })

  }

  async getAllRegioni() {
    this.apiService.getAllRegioni().subscribe(res => {

      this.listRegioni = res
    })
  }

  async getAllComuni() {
    this.apiService.getAllComuni().subscribe(res => {
      this.listComuni = res
    })
  }


  async getAllDistretti() {
    this.apiService.getAllDistretti().subscribe(res => {
      this.listDistretti = res
    })

  }

  async getAllAsl() {
    this.apiService.getAllAsl().subscribe(res => {
      this.listAsl = res
    })

  }

  async getAllCollazioneSocioAmbientali() {
    this.apiService.getAllCollazioeSocioAmbientali().subscribe(res => {

      this.listCollazioneSoci = res
    })

  }

  async getAllTitoliDiStudio() {
    this.apiService.getAllTitoliStudio().subscribe(res => {
      this.listTitoliDiStudio = res
    })

  }

  async getAllAttivitta() {
    this.apiService.getAllAtivitta().subscribe(res => {
      this.listAttivita = res
    })
  }

  async getAllInvalidita() {

    this.apiService.getAllInvalidita().subscribe(res => {
      this.listInvalidita = res
    })

  }

  async getAllTuteleGiuridice() {

    this.apiService.getAllTuteleGiuridice().subscribe(res => {
      this.listTuteleGiuridice = res
    })

  }

  async getAllProvedimenti() {

    this.apiService.getAllProvedimenti().subscribe(res => {
      this.listProvedimenti = res
    })

  }


  // CONTATTO


  async getAllPrecedentiContatti() {
    this.apiService.getAllPrecedentiContatti().subscribe(res => {
      this.listPrecedentiContatti = res
    })
  }

  async getAllModalitaConclusione() {
    this.apiService.getAllModalitaConclusione().subscribe(res => {
      this.listaModalitaConclusione = res
    })
  }

  async getRichiestePrevalenti() {
    this.apiService.getAllRichiestePrevalenti().subscribe(res => {
      this.listRichiestePrevalenti = res
    })
  }

  async getInviantiTerritoriali() {

    this.apiService.getAllInviantiTerritoriale().subscribe(res => {
      this.listInvianti = res
    })


  }

  async getInviantiSemiResidenziali() {

    this.apiService.getAllInviantiSemiresidenziali().subscribe(res => {
      this.listInvianti = res
    })


  }

  // PRESTAZIONI


  async loadOperatori() {

    this.apiService.getAllOperatori().subscribe(res => {
      this.listOperatori = res


    })

  }

  async loadSedeInterventoTerritoriali() {

    this.apiService.getAllSedeinterventoTerritoriali().subscribe(res => {
      let sede = res

      this.mergeSedeIntervento(sede)

    })

  }

  async loadSedeInterventoSemiresidenziali() {

    this.apiService.getAllSedeinterventoSemiresidenziali().subscribe(res => {
      let sede = res
      this.mergeSedeIntervento(sede)

    })

  }

  mergeSedeIntervento(listSede:SedeIntervento[]){

    this.listSedeIntervento = [...listSede]
  }




  // ANAGRAFICA NAME
  setStatoCivileName(codice: string): string | null {

    let stato = null

    this.listStatiCivili?.forEach(st => {

      if (st.codice == codice) {

        stato = st.descrizione;

      }

    })

    return stato
  }

  setStatoName(codice: string): string | null {

    let stato = null

    this.listStati?.forEach(st => {

      if (st.alpha2 == codice) {

        stato = st.descrizione;

      }

    })

    return stato
  }

  setSessoName(sesso: string) {
    if (sesso === 'M') {
      return "Maschio"
    } else {
      return "Femmina"
    }
  }

  setRegioneName(codice: string): string | null {

    let regione = null
    this.listRegioni.forEach(reg => {
      if (codice == reg.codRegione) {
        regione = reg.regione
      }
    })

    return regione
  }

  setComuneName(codice: string): string | null {
    let comune = null
    this.listComuni.forEach(com => {

      if (codice == com.codice) {
        comune = com.descrizione
      }
    })

    return comune
  }

  setDistrettoName(codice: string): string | null {
    let distretto = null
    this.listDistretti.forEach(dist => {

      if (codice == dist.codice) {
        distretto = dist.descrizione
      }
    })

    return distretto
  }

  setAslName(codice: string): string | null {
    let asl = null
    this.listAsl.forEach(as => {

      if (codice == as.codiceAzienda) {
        asl = as.denominazioneAzienda
      }
    })

    return asl
  }

  setCollazioneSocioAmbientaleName(codice: string): string | null {
    let collazione = null
    this.listCollazioneSoci.forEach(coll => {

      if (codice == coll.codice) {
        collazione = coll.descrizione
      }
    })

    return collazione
  }

  setTitoloDiStudioName(codice: string): string | null {
    let titolo = null
    this.listTitoliDiStudio.forEach(ts => {

      if (codice == ts.codice) {
        titolo = ts.descrizione
      }
    })

    return titolo
  }

  setAttivitaName(codice: string): string | null {
    let attivita = null
    this.listAttivita.forEach(ativita => {

      if (codice == ativita.codice) {
        attivita = ativita.descrizione
      }
    })

    return attivita
  }

  setInvaliditaName(codice: string): string | null {
    let invalidita = null
    this.listInvalidita.forEach(inv => {

      if (codice == inv.codice) {
        invalidita = inv.descrizione
      }
    })

    return invalidita
  }

  setFamigliareInTratamentoName(codice: string) {

    if (codice === "1") {
      return "Si"
    } else if (codice === "2") {
      return "No"
    } else {
      return "Non noto/Non risulta"
    }


  }

  setTuteleGiuridiceName(codice: string): string | null {
    let tutela = null
    this.listTuteleGiuridice.forEach(tut => {

      if (codice == tut.codice) {
        tutela = tut.descrizione
      }
    })

    return tutela
  }

  setProvedimentiName(codice: string): string | null {
    let provedimento = null
    this.listProvedimenti.forEach(prv => {

      if (codice == prv.codice) {
        provedimento = prv.descrizione
      }
    })

    return provedimento
  }

  setPrecedemnteInternatoName(codice: string) {

    if (codice === "1") {
      return "Si"
    } else if (codice === "2") {
      return "No"
    } else {
      return "Non noto/Non risulta"
    }


  }



  // CONTATTO NAME

  setRichiestaPrevName(codice: string): string | null {
    let richiesta = null
    this.listRichiestePrevalenti.forEach(rp => {

      if (codice == rp.codice) {
        richiesta = rp.descrizione
      }
    })

    return richiesta
  }

  setPrecedentiContatiName(codice: string): string | null {
    let precedenteContatto = null
    this.listPrecedentiContatti.forEach(pc => {

      if (codice == pc.codice) {
        precedenteContatto = pc.descrizione
      }
    })

    return precedenteContatto
  }

  setInviantiName(codice: string): string | null {
    let inviante = null
    this.listInvianti.forEach(inv => {

      if (codice == inv.codice) {
        inviante = inv.descrizione
      }
    })

    return inviante
  }

  setModalitaConclusioneName(codice: string): string | null {
    let modalita = null
    this.listaModalitaConclusione.forEach(mc => {

      if (codice == mc.codice) {
        modalita = mc.descrizione
      }
    })

    return modalita
  }

  // PRESTAZIONI NAME

  setModalitaErrogazioneName(codice:string){

    if(codice ==="1"){
      return "Programmato"
    }else if(codice === "2"){
      return "Non programmato"
    }else{
      return ""
    }

  }

  setSedeName(codice: string): string | null {
    let seda = null
    this.listSedeIntervento.forEach(sd => {

      if (codice == sd.codice) {
        seda = sd.descrizione
      }
    })

    return seda
  }

  setOperatore1Name(codice:string){

    let operatore = null
    
    this.listOperatori.forEach(op => {

      if (codice == op.codice) {
        operatore = op.descrizione
      }
    })

    return operatore

  }

  formatDate(date:Date):string|null{

    if(date != null || undefined){

      let myDate = new Date(date)
    
      return "" + myDate.getDate() +"-"+ (myDate.getMonth() + 1) +"-"+ myDate.getFullYear()
    }else{
      return null
    }
    
  }

}
