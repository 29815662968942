<div class="container">

    <div class="card">

        <div class="card-header">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">

                    <li class="breadcrumb-item"><a (click)="$event.preventDefault();navigateToAnagrafica()"
                            href="">Gestione
                            pazienti</a></li>
                    <li class="breadcrumb-item"><a (click)="$event.preventDefault();navigateToAnagrafica()"
                            href="">{{getTitleName()}}</a></li>

                </ol>
            </nav>
            <div class="row">
                <div class="title-3 col-9" demon-prestazione="titolo">Presenza #{{presenza.id}}</div>
                <div class="col-3">
                    <button class="btn btn-outline-secondary btn-sm" *ngIf="(getRuolo(OPERATORE_STRUTTURA) || getRuolo(OPERATORE_REGIONE)) && presenza?.id != null && presenza?.id != undefined" (click)="$event.preventDefault(); openAuditDialog(audit)" >
                        Storico modifiche
                      </button>
                </div>
            </div>





        </div>

        <div class="card-body">
          <!-- Stato e motivazione scarto -->
            <div class="group pt-4 alert alert-danger"  style="background-color: #ffe1cc !important;" *ngIf="presenza.id != null && (presenza.statoAttuale.id == 4 ||presenza.statoAttuale.id ==  7 ||presenza.statoAttuale.id ==  9)">
            <div class="form-group field-10">
              Stato: {{presenza.statoAttuale.descrizione}}
            </div>
            <div class="form-group field-10" *ngIf="presenza.motivazioneScarto != null">
              Motivazione dello scarto: {{presenza.motivazioneScarto}}
            </div>
          </div>


            <form [formGroup]="presenzaForm" (ngSubmit)="submitForm(presenzaForm.value)" autocomplete="off">


                <div class="pt-5">

                    <div class="group-inline">


                        <div class="group-inline">


                            <!-- tipo struttura semiresidenziale -->
                            <div class="form-group field-20">


                                <label>Tipo struttura semiresidenziale*</label>
                                <div>
                                    <select class="form-control" name="tipoStruttura"
                                            formControlName="tipoStruttura"
                                        [attr.disabled]="true">
                                        <option value="2" >Centro diurno</option>
                                    </select>
                                </div>

                                <div *ngIf="presenzaForm.hasError('required', 'tipoStruttura') && formSubmitted"
                                    class="alert alert-danger">
                                    Campo obbligatorio
                                </div>
                            </div>
                            <!-- Data -->
                            <div class="form-group field-10 has-feedback">
                                <label>Data*</label>
                                <div class="input-group">
                                    <input class="form-control" placeholder="aaaa-mm-gg" name="data"
                                        [(ngModel)]="dateModel" ngbDatepicker #d="ngbDatepicker" [minDate]="minDate!!"
                                        [maxDate]="maxDate" formControlName="data"
                                        [readonly]="!hasWritePermission(mainUser) || mustDisableForm()">

                                    <button class="btn btn-secondary" (click)="d.toggle()" type="button"
                                        [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false:null ">
                                        <img class="img-responsive" src="../assets/icons/calendar_ko.png"
                                            style="width: 20px; height:20px">
                                    </button>


                                </div>

                                <div *ngIf="presenzaForm.hasError('required', 'data') && formSubmitted"
                                    class="alert alert-danger">
                                    Campo obbligatorio
                                </div>

                                <div *ngIf="presenzaForm.hasError('dateExist', 'data') && formSubmitted"
                                    class="alert alert-danger">
                                    Esiste già una presenza per questa data
                                </div>
                                <div *ngIf="presenzaForm.controls['data'].hasError('ngbDate') && formSubmitted"
                                    class="alert alert-danger">
                                    Il formato della data non è valido
                                </div>
                            </div>

                            <!-- Modalita Presenza -->
                            <div class="form-group field-60">


                                <label>Mod presenza*</label>
                                <div>
                                    <select class="form-control" name="modPresenza" formControlName="modPresenza"
                                        [attr.disabled]="!hasWritePermission(mainUser)? false:null || mustDisableForm()? false:null">
                                        <option value=""></option>
                                        <option value="1">Presenza semiresidenziale < 4 ore</option>
                                        <option value="2">Presenza semiresidenziale > 4 ore</option>
                                    </select>
                                </div>
                                <div *ngIf="presenzaForm.hasError('required', 'modPresenza') && formSubmitted"
                                    class="alert alert-danger">
                                    Campo obbligatorio
                                </div>

                            </div>





                        </div>






                        <div class="form-group field-wide">

                        </div>

                    </div>


                </div>


            </form>
        </div>

    </div>


    <block-ui>

    </block-ui>

    <!-- Toolbar -->
    <div class="btn-fixed fixed" demon-cf="toolbarFixed" demon-ev="true" demon-cf-id="8">
        <div class="btn-toolbar">
            <div class="container">
                <div class="btn-group-left">
                    <button type="button" class="btn btn-outline-secondary" demon-bt="indietro" demon-cf="ModalConfirm"
                        demon-body="Tornando indietro perderai i dati inseriti nella prestazione, vuoi continuare comunque?"
                        demon-call="$D._sbObj.oprestazione.evIndietro" demon-ev="true" demon-cf-id="9"
                        (click)="openGoBackDialog(content)">
                        Torna alla lista
                    </button>
                </div>
                <div class="btn-group-right">

                    <button [attr.disabled]="mustDisableCancelBtn()? false: null" *ngIf="cancellaVisibile()" type="button" class="btn btn-outline-danger"
                        (click)="openCancellaDialog(cancella)">Cancella</button>
                </div>
                <div class="btn-group-right">
                    <button [attr.disabled]="mustDisableForm()? false: null" *ngIf="salvaVisibile()" type="button" class="btn btn-secondary"
                        (click)="submitForm(presenzaForm.value)">
                        <!-- [disabled]="!diagnosiScaleForm.valid || !diagnosiScaleForm.dirty" -->
                        Salva
                    </button>

                </div>
            </div>
        </div>
    </div>
</div>




<ng-template #content let-modal>
    <div class="modal-header" style="background-color: #012346 !important; cursor: pointer;">
        <h4 class="modal-title" id="modal-basic-title" style="color: white;">Attenzione</h4>
        <a (click)="modal.dismiss('Cross click')"><svg class="s-icon icon-xxs">
                <use xlink:href="assets/icons/sprite.svg#close"></use>
            </svg></a>
    </div>
    <div class="modal-body">
        <div class="container">
            <div class="row">
                <label>Tornando indietro perderai i dati inseriti nella presenza, vuoi continuare
                    comunque?</label>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Back click')">Ok</button>
    </div>
</ng-template>


<ng-template #cancella let-modal>
    <div class="modal-header" style="background-color: #012346 !important; cursor: pointer;">
        <h4 class="modal-title" id="modal-basic-title" style="color: white;">Attenzione</h4>
        <a (click)="modal.dismiss('Cross click')"><svg class="s-icon icon-xxs">
                <use xlink:href="assets/icons/sprite.svg#close"></use>
            </svg></a>
    </div>
    <div class="modal-body">
        <div class="container">
            <div class="row">
                <label>Sei sicuro di voler cancellare presenza con id {{presenza!!.id!!}} ?</label>
            </div>
            <div class="row" *ngIf="listPrestazioni && listPrestazioni.length>0">
                <label>Si Cancellerano anche {{listPrestazioni.length}} prestazioni</label>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-danger"
            (click)="cancellaPresenza(presenza!!.id!!)">Cancella</button>
    </div>
</ng-template>
<ng-template #audit let-modal>
    <div class="modal-header" style="background-color: #012346 !important; cursor: pointer;">
      <h4 class="modal-title" id="modal-basic-title" style="color: white;">Storico modifiche Presenza {{presenza!!.id!!}}</h4>
    </div>
    <div class="modal-body">
      <app-dyn-table [title]="storicoTitle" [dataMessageResults]="auditPresenza"></app-dyn-table>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.close('')">Ok</button>
    </div>
  </ng-template>
