<div class="container py-3 card">
  <div class="card-header">
    <div class="title-3">Invio dati in piattaforma flussi</div>
  </div>
  <div class="card-body" style="background-color: #fff">
    <form [formGroup]="ricercaForm">
      <div class="group-inline">
        <div class="form-group field-8">
          <label style="font-weight: bold">Anno *:</label>
          <select class="form-control"
          formControlName="anno"
          name="anno"
          (change)="clearSearchResult();"
         >
            <option value="{{ currentYear }}" selected>
              {{ currentYear }}
            </option>

            <option *ngFor="let year of yearArray" value="{{ year }}">
              {{ year }}
            </option>
          </select>
        </div>
        <div
          class="groupo-inline"
        >
          <div class="col-3 form-group">
            <label style="font-weight: bold">Asl *: </label>
            <select
              class="form-control"
              formControlName="asl"
              name="asl"
              formGorupName="ricercaForm"
              (change)="clearSearchResult(); getStrutture($event);"
            >
              <option value="">Seleziona Asl</option>
              <option
                *ngFor="let asl of aslList"
                value="{{ asl.codiceAzienda }}"
              >
                {{ asl.denominazioneAzienda }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group field-16">
          <label style="font-weight: bold">Ambito *:</label>
          <select
            class="form-control"
            formControlName="flusso"
            name="flusso"
            (change)="changeFlusso($event); clearSearchResult();"
          >
          <option value="">Seleziona Ambito</option>
          <option value="SISM-SEMIRES">Semiresidenziale</option>
          <option value="SISM-TERR">Territoriale</option>
          </select>
        </div>
        <div class="form-group field-16">
          <label style="font-weight: bold">Periodo *:</label>
          <select
            class="form-control"
            formControlName="etichetta"
            name="etichetta"
            tabindex="-98"
            (change)="clearSearchResult();"
          >
          <option value="">Seleziona Periodo</option>
          <option *ngFor="let etichetta of etichette | keyvalue" value="{{ etichetta.key }}">
            {{ etichetta.value }}
          </option>
          </select>
        </div>
        <div class="form-group field-16">
          <label style="font-weight: bold">&nbsp;</label>
          <div class="btn-group-right">
            <button style="min-width: 180px" class="btn btn-secondary" (click)="searchFlussoAndContatti();">Avvia Ricerca</button>
          </div>
        </div>
        <div class="form-group field-32" hidden>
          <label style="font-weight: bold">Struttura:</label>
          <select
            class="form-control"
            formControlName="struttura"
            name="struttura"
            (change)="searchFlussoAndContatti()"
          >
            <option value="">Tutte le Strutture</option>
            <option
              [hidden]="!struttura.descrTipoStruttura?.includes(tipoFlusso)"
              *ngFor="let struttura of strutture.content"
              value="{{ struttura.codStruttura }}"
              value=""
            >
              {{ struttura.denominazioneStruttura }}
            </option>
          </select>
        </div>
      </div>
    </form>
    <div class="card text-primary" style="min-height: 100px !important; font-size: larger">
      <div *ngIf="!showCounter " class="card-body"><p>Effettuare una selezione per inviare dati in piattaforma flussi e cliccare su “Avvia ricerca”.</p></div>
    <div *ngIf=" showCounter " class="card-body">
      <div><p>Per la selezione effettuata sono sono state trovate <span class="badge bg-secondary">{{anagrafiche}}</span> anagrafiche, <span class="badge bg-secondary">{{contatti}}</span> contatti e <span class="badge bg-secondary">{{prestazioni}}</span> {{stringPre}}</p></div>
      <div *ngIf=" idIstanzaFlusso==0 ">
        <p>Per il periodo selezionato <span class="badge bg-secondary">non sono</span> presenti scadenze</p>
      </div>
      <div *ngIf=" idIstanzaFlusso!=0 ">
        <p>Per il periodo selezionato è presente una scadenza con data : <span class="badge bg-secondary">{{dataScadenzaFlusso | date:'d/M/y'}}</span></p>
      </div>
    </div>
    </div>
  </div>
  <div class="card-footer">
    <div class="btn-toolbar">
      <div class="btn-group-left"></div>
      <div class="btn-group-center"></div>
      <div class="btn-group-right">
        <button class="btn btn-primary" (click)="inviaRegione()" [disabled]="idIstanzaFlusso==0  || isLoading ">INVIA I DATI SELEZIONATI IN PIATTAFORMA FLUSSI</button>
      </div>
    </div>

  </div>
  <!-- <div *ngIf="isLoading" style="margin: 0 auto;" >L'operazione potrebbe richiedere alcuni minuti!</div> -->
  <div *ngIf="isLoading" style="margin: 0 auto;">
    <div

        class="spinner-border text-primary landingspinner"
        style="width: 10rem; height: 10rem; margin: 0 auto;"
        role="status"
      >
        <span class="sr-only">Loading...</span>
    </div>
  </div>

  <div *ngIf="showResult" class="card card-search">

    <div class="card-header">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item active" aria-current="page">Configurazione flussi</li>
        </ol>
      </nav>
      <div class="title-3">
        Risultato dell'operazione
      </div>
    </div>
    <div class="card-body">
      <div *ngFor=" let resultInvio of resultInvii">
        <div *ngIf="resultInvio.successo != true" class="table-responsive mt-5" style="max-height: 300px; overflow-y: auto;">
          <div class="form-group field-50" >
            <span>
              <p>Il file Xml
                <b *ngIf="resultInvio.idInstanzaInvio == 0">Anagrafica</b>
                <b *ngIf="resultInvio.idInstanzaInvio == 1">Contatti</b>
                <b *ngIf="resultInvio.idInstanzaInvio == 2">Prestazioni</b>
                Presenta degli errori:
                <b>{{resultInvio.message}}</b>
              </p>
            </span>
          </div>
        </div>
        <div *ngIf="resultInvio.successo == true " class="table-responsive mt-5" style="max-height: 300px; overflow-y: auto;">
          <div class="form-group field-50" >
            <span><p>Operazione di importazione eseguita correttamente con id istanza invio: <b>{{resultInvio.idInstanzaInvio}}</b> </p> </span>
          </div>
        </div>

      </div>
      <div class="form-group group-btn">
        <button class="btn btn-primary"  (click)="rechargePage()" >
          Carica un nuovo File
        </button>
      </div>
    </div>

  </div>


  <!-- Storico -->
  <div class="card mt-5">
    <div class="card-body">
      <div class="title-4" style="margin-bottom: 10px">
        Storico Invii
      </div>
      <div class="table-responsive">
        <div class="list-table table-even">
          <table class="table table-lg">
            <thead>
              <tr>
                <th>
                  Data creazione
                </th>
                <th>Sigla</th>
                <th>Anno</th>
                <th>Asl</th>
                <th>Ambito</th>
                <th>Periodo</th>
                <!-- <th>Struttura</th> -->
                <th>Esito</th>
                <th>Messaggio</th>
                <th>Nr° Invio</th>
                <th style="min-width: 200px;">File</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let flusso of paginaStorico.content">
                <td class="td-hover" data-label="Data">{{flusso.createdOn | date:'d/M/y HH:mm'}}</td>
                <td class="td-hover" data-label="Flusso">{{flusso.siglaFlusso}}</td>
                <td class="td-hover" data-label="Anno">{{flusso.anno}}</td>
                <td class="td-hover" data-label="Asl">{{printAslName(flusso.asl)}}</td>
                <td class="td-hover" data-label="Ambito">{{flusso.ambito}}</td>
                <td class="td-hover" data-label="Periodo">{{etichette[flusso.periodo?flusso.periodo:0]}}</td>
                <!-- <td *ngIf="flusso.codiceStruttura != null"class="td-hover" data-label="Struttura">{{flusso.codiceStruttura}}</td>
                <td *ngIf="flusso.codiceStruttura == null"class="td-hover" data-label="Struttura">Tutte</td> -->
                <td *ngIf="flusso.esitoTrasmissione" class="td-hover" data-label="Esito">Successo</td>
                <td *ngIf="!flusso.esitoTrasmissione" class="td-hover" data-label="Esito">Fallimento</td>
                <td class="td-hover" data-label="Messaggio">{{flusso.msgTrasmissione}}</td>
                <td class="td-hover text-center text-primary" style="font-weight: bold" data-label="Istanza Invio">{{flusso.idIstanzaInvio}}</td>
                <td class="td-hover text-center" data-label="Files">
                  <div class="btn-group" *ngIf="getFilesArchiviati(flusso).length>1">
                    <button type="button" class="btn btn-sm btn-secondary">{{getFilesArchiviati(flusso).length-1}} FILE</button>
                    <button type="button" style="margin-left: 1px !important;" class="btn btn-sm btn-secondary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
                      <span class="visually-hidden">{{getFilesArchiviati(flusso).length-1}} file</span>
                    </button>
                    <ul class="dropdown-menu">
                      <li *ngFor=" let file of flusso.filesArchiviati?.split('||')"><a class="dropdown-item" (click)="downloadSingleFile(flusso.id, file)">
                        {{file}}
                      </a></li>
                    </ul>
                  </div>

                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <!-- PAGINATION -->
  <app-paginator [page]="paginaStorico" (cambiaPaginaEvent)="cambiaPagina($event)"></app-paginator>





</div>
