<!-- Risultati -->
<div class="pt-3">
    <div class="table-responsive">
      <div class="list-table table-even">

        <table class="table-lg" demon-cf="tableList" demon-cf-id="anagraficaPrestazioni-1" demon-ev="true" demon-done="3">
          <thead app-thead [theadColumns]="theadColumns" [tableSorter]="tableSorter" (changeOrder)="changeOrder()">
          </thead>


          <tbody>

          <tr *ngIf="pageDiagnosiScale.loading"><td  [attr.colspan]="theadColumns.length">
            <div class="spinner-border centered"></div></td></tr>
          <ng-container  *ngFor="let ds of pageDiagnosiScale.content" >
            <tr (click)="diagnosiClick(ds)" class="tr-hover demon-cloned"
                data-demon-tr="6-anagraficaPrestazioni-1-1"
                *ngIf="!pageDiagnosiScale.loading  && ( getRuolo(OPERATORE_STRUTTURA)||getRuolo(OPERATORE_REGIONE) || ds.statoAttuale.id > 1 )" >
              <td class="td-hover" data-label="Alert">
                <div><img *ngIf="alert(ds.statoAttuale)" src="assets/icons/warning.png" /></div>
              </td>
              <td class="td-hover" data-label="ID">
                <div>{{ds.id}}</div>
              </td>
              <td class="td-hover" data-label="ID">
                <div>{{ds.codiceStrutturaOperatoreInserimento}} - {{ds.descrizioneStrutturaOperatoreInserimento}} - {{getDescrizioneASL(ds.codiceAslOperatoreInserimento)}}</div>
              </td>
              <td class="td-hover" data-label="Data">
                <div>{{contatto.dataApertura | date:'yyyy-MM-dd'}}</div>
              </td>

              <td class="td-hover" data-label="Data">
                <div>{{ds.data|date:'yyyy-MM-dd'}}</div>
              </td>

              <td class="td-hover" data-label="Diagnosi" style="width: 550px;">
                <div>{{getDiagnosiNameByCodice(ds.diagnosi!!)}}</div>
              </td>
             <td class="td-hover" data-label="Scala Honos">
               <div>{{ds.scalaHonos}}</div>
             </td>
             <td class="td-hover" data-label="Scala Bprs">
               <div>{{ds.scalaBprs}}</div>
             </td>
             <td class="td-hover" data-label="Stato">
              <div>{{descrizioneStato(ds.statoAttuale.id)}}</div>
              <!-- <div>{{contattoDto.statoAttuale}}</div> -->
             </td>
            </tr>
          </ng-container>



          </tbody>
        </table>


      </div>



    </div>

    <app-paginator [page]="pageDiagnosiScale" (cambiaPaginaEvent)="cambiaPagina()">
    </app-paginator>



    <div class="btn-toolbar">
      <div class="btn-group-left">
      </div>
      <div class="btn-group-center">
        <!-- aggiungiDiagnosiScaleVisibile() && !contattoIsClosed() -->
        <button *ngIf="(!isValidato() || this.contatto.dataChiusura === undefined || this.contatto.dataChiusura === null)  && this.contatto.aperturaFlag === '1' && getRuolo(OPERATORE_STRUTTURA)" type="button" class="btn btn-secondary"
          (click)="openInterventoForm()">
          Aggiungi Diagnosi&scale
        </button>
      </div>
      <div class="btn-group-right">
      </div>
    </div>

  </div>
