import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlboardComponent } from './controlboard.component';
import { NgChartsModule } from "ng2-charts";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ShowdataComponent } from './showdata/showdata.component';
import { ShowdataclickComponent } from './showdataclick/showdataclick.component';
import {NgbDatepickerModule, NgbDropdownModule} from "@ng-bootstrap/ng-bootstrap";


@NgModule({
  declarations: [
    ControlboardComponent,
    ShowdataComponent,
    ShowdataclickComponent
  ],
  exports: [
    ControlboardComponent
  ],
    imports: [
        CommonModule,
        NgChartsModule,
        FormsModule,
        ReactiveFormsModule,
        NgbDropdownModule,
        NgbDatepickerModule
    ]
})
export class ControlboardModule { }
