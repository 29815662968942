
<div class="modal fade show" role="dialog" data-backdrop="static" data-keyboard="false" style="display: block;" aria-modal="true">
  <div class="modal-dialog modal-lg" id="Modal1"><div class="modal-content" id="demonView7-70-2">
    <div class="modal-header bg-primary text-white">
      <h4 class="modal-title">Presa in carico {{test}}</h4>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close"
              (click)="chiudi()">
        <svg class="s-icon icon-sm"><use href="#close"></use></svg>
      </button>
    </div>

    <ul class="nav nav-tabs" id="detailTab" role="tablist">
      <li class="nav-item">
        <a class="nav-link title-4 active" id="anagrafica-tab" data-toggle="tab" href="#anagrafica" role="tab" aria-controls="anagrafica" aria-selected="true">
          Anagrafica
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link title-4" id="contatto-tab" data-toggle="tab" href="#contatto" role="tab" aria-controls="contatto" aria-selected="false">
          Contatti
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link title-4" id="prestazioni-tab" data-toggle="tab" href="#prestazioni" role="tab" aria-controls="prestazioni" aria-selected="false">
          Prestazioni sanitarie
        </a>
      </li>
    </ul>

    <div class="modal-body">

      <div class="tab-content" id="detailTabContent">

        <!-- Anagrafica -->
        <div class="tab-pane fade show active" id="anagrafica" role="tabpanel" aria-labelledby="anagrafica-tab">

          <div class="data-read">
            <div class="group-inline">
              <div class="form-group">
                <label>Codice fiscale</label>
                <div class="textbold">{{dettaglioAnagrafica?.codiceFiscale}}</div>
              </div>
            </div>
            <div class="group-inline">
              <div class="form-group">
                <label>Cognome</label>
                <div class="textbold">{{dettaglioAnagrafica?.cognome}}</div>
              </div>
              <div class="form-group">
                <label>Nome</label>
                <div class="textbold">{{dettaglioAnagrafica?.nome}}</div>
              </div>
              <div class="form-group">
                <label>Sesso</label>
                <div class="textbold">{{setSessoName(dettaglioAnagrafica?.sesso!!)}}</div>
              </div>
            </div>

            <div class="group-inline">

              <div class="form-group">
                <label>Stato civile</label>
                <div class="textbold">{{setStatoCivileName(this.dettaglioAnagrafica?.statoCivile!!)}}</div>
              </div>
              <div class="form-group">
                <label>Cittadinanza</label>
                <div class="textbold">{{setStatoName(dettaglioAnagrafica?.cittadinanza!!)}}</div>
              </div>
            </div>


            <!-- Nascita -->
            <div class="title-5 pt-5">
              Nascita
            </div>
            <div class="group-inline pt-4">

              <div class="form-group">
                <label>Data</label>
                <div class="textbold">{{formatDate(dettaglioAnagrafica?.dataNascita!!)}}</div>
              </div>

              <div class="form-group">
                <label>Nazione</label>
                <div class="textbold">{{setStatoName(dettaglioAnagrafica?.statoResidenza!!)}}</div>
              </div>

              <div class="form-group">
                <label>Comune</label>
                <div class="textbold">{{dettaglioAnagrafica?.descrizioneComuneNascita}}</div>
              </div>

            </div>


            <!-- Residenza -->
            <div class="title-5 pt-5">
              Residenza
            </div>
            <div class="group-inline pt-4">
              <div class="form-group">
                <label>Stato</label>
                <div class="textbold">{{setStatoName(dettaglioAnagrafica?.statoResidenza!!)}}</div>
              </div>
              <div class="form-group">
                <label>Regione</label>
                <div class="textbold">{{setRegioneName(dettaglioAnagrafica?.regioneResidenza!!)}}</div>
              </div>
              <div class="form-group">
                <label>Comune</label>
                <div class="textbold">{{setComuneName(dettaglioAnagrafica?.comuneResidenza!!)}}</div>
              </div>
              <div class="form-group">
                <label>Distretto</label>
                <div class="textbold">{{setDistrettoName(dettaglioAnagrafica?.distrettoResidenza!!)}}</div>
              </div>
              <div class="form-group">
                <label>ASL</label>
                <div class="textbold">{{setAslName(dettaglioAnagrafica?.aslResidenza!!)}}</div>
              </div>
            </div>

            <!-- -->
            <div class="group-inline pt-3">

              <div class="form-group">
                <label>Collocazione socio ambientale</label>
                <div class="textbold">{{setCollazioneSocioAmbientaleName(dettaglioAnagrafica?.collocazioneSocioAmbientale!!)}}</div>
              </div>
              <div class="form-group">
                <label>Titolo di studio</label>
                <div class="textbold">
                  {{setTitoloDiStudioName(dettaglioAnagrafica?.titoloDiStudio!!)}}</div>
              </div>
              <div class="form-group field-36">
                <label>Attività</label>
                <div class="textbold">{{setAttivitaName(dettaglioAnagrafica?.attivita!!)}}</div>
              </div>

            </div>

            <!-- -->
            <div class="group-inline pt-3">
              <div class="form-group">
                <label>Invalidità</label>
                <div class="textbold">{{setInvaliditaName(dettaglioAnagrafica?.invalidita!!)}}</div>
              </div>

              <div class="form-group">
                <label>E' familiare di un paziente in trattamento</label>
                <div class="textbold">{{setFamigliareInTratamentoName(dettaglioAnagrafica?.familiarePaziente!!)}}</div>
              </div>

              <div class="form-group">
                <label>Tutela giuridica</label>
                <div class="textbold">{{setTuteleGiuridiceName(dettaglioAnagrafica?.tutelaGiuridica!!)}}</div>
              </div>

              <div class="form-group field-50">
                <label>Attualmente sottoposto a provvedimenti giudiziari o a una misura di sicurezza</label>
                <div class="textbold">{{setProvedimentiName(dettaglioAnagrafica?.attualmenteSottoposto!!)}}</div>
              </div>

              <div class="form-group field-50">
                <label>Precedente internamento o detenzione in ambito Psichiatrico Penitenziario</label>
                <div class="textbold">{{setPrecedemnteInternatoName(dettaglioAnagrafica?.precedentementeInternato!!)}}</div>
              </div>
            </div>
          </div>

        </div>

        <!-- Contatti -->
        <div class="tab-pane fade" id="contatto" role="tabpanel" aria-labelledby="contatto-tab">

          <div class="card border-primary mb-4" *ngFor="let contatto of listContati">
            <div class="card-body">

              <div class="data-read" >

                <div class="title-4">
                  Presa in carico #{{contatto.id}}
                </div>

                <div class="group-inline pt-5">
                  <div class="form-group">
                    <label>Data richiesta</label>
                    <div class="textbold">{{formatDate(contatto.dataRichiesta!!)}}</div>
                  </div>
                  <div class="groupo-inline">
                    <div class="form-group">
                      <label>Tipo prestazione</label>
                      <div class="textbold">{{contatto.tipoPrestazione}}</div>
                    </div>
                  </div>
                  <div class="form-group field-30">
                    <label>Richiesta prev</label>
                    <div class="textbold">{{setRichiestaPrevName(contatto.richiestaPrev!!)}}</div>
                  </div>

                </div>

                <div class="title-5 pt-4">
                  Apertura
                </div>
                <div class="group-inline pt-4">
                  <div class="form-group">
                    <label>Data</label>
                    <div class="textbold">{{formatDate(contatto.dataApertura!!)}}</div>
                  </div>
                  <div class="form-group field-20">
                    <label>Diagnosi</label>
                    <div class="textbold">{{contatto.descrizioneDiagnosiApertura}}</div>
                  </div>
                  <div class="form-group field-40">
                    <label>Precedenti contatti</label>
                    <div class="textbold">{{setPrecedentiContatiName(contatto.precedentiContatti!!)}}</div>
                  </div>
                  <div class="form-group field-40">
                    <label>Inviante</label>
                    <div class="textbold">{{setInviantiName(contatto.inviante!!)}}</div>
                  </div>
                </div>

                <div class="title-5 pt-4">
                  Chiusura
                </div>
                <div class="group-inline pt-4">
                  <div class="form-group">
                    <label>Data</label>
                    <div class="textbold">{{formatDate(contatto.dataChiusura!!)}}</div>
                  </div>
                  <div class="form-group field-20">
                    <label>Diagnosi</label>
                    <div class="textbold">{{contatto.descrizioneDiagnosiChiusura}}</div>
                  </div>
                  <div class="form-group field-40">
                    <label>Modalità conclusione</label>
                    <div class="textbold">{{setModalitaConclusioneName(contatto.modalitaConclusione!!)}}</div>
                  </div>

                </div>

              </div>
            </div>
          </div>


        </div>

        <!-- Prestazioni -->
        <div class="tab-pane fade" id="prestazioni" role="tabpanel" aria-labelledby="prestazioni-tab">

          <div class="card border-primary mb-4" *ngFor="let prestazione of listPrestazioni">
            <div class="card-body">

              <div class="data-read">

                <div class="title-4">
                  Prestazione {{prestazione.descrizioneTipo}} #{{prestazione.id}}
                </div>

                <div class="group-inline pt-5">
                  <div class="form-group">
                    <label>Data</label>
                    <div class="textbold">{{formatDate(prestazione.dataPrestazione!!)}}</div>
                  </div>
                  <div class="form-group field-60">
                    <label>Tipo</label>
                    <div class="textbold">{{prestazione.descrizioneTipo}}</div>
                  </div>
                  <div class="form-group field-16">
                    <label>Modalità di erogazione</label>
                    <div class="textbold">{{setModalitaErrogazioneName(prestazione.modalitaDiErogazione!!)}}</div>
                  </div>
                  <div class="form-group field-60">
                    <label>Sede</label>
                    <div class="textbold">{{setSedeName(prestazione.sede!!)}}</div>
                  </div>
                </div>

                <div class="group-inline pt-3">
                  <div class="form-group field-20">
                    <label>Operatore 1</label>
                    <div class="textbold">{{setOperatore1Name(prestazione.operatore1!!)}}</div>
                  </div>
                  <div class="form-group field-6">
                    <label>Codice</label>
                    <div class="textbold">{{prestazione.operatore1Codice}}</div>
                  </div>
                </div>


                <div class="group-inline pt-3">
                  <div class="form-group field-20">
                    <label>Riferimento contatto id</label>
                    <div class="textbold">{{prestazione.idContatto}}</div>
                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>

      </div>

    </div>
    <div class="modal-footer">
      <div class="mr-auto">
        <button type="button" class="btn btn-outline-secondary" data-dismiss="modal"
                (click)="chiudiBack()">Chiudi</button>
      </div>
    </div>
  </div></div>
</div>
