
<div class="container py-3">
  <div class="card-header">
    <div class="title-3">Ricerca dati</div>
  </div>
  <div class="card-body" style="background-color: #fff">
    <form [formGroup]="ricercaForm">
      <div class="group-inline">
        <div class="form-group field-8">
          <label>Anno</label>
          <select class="form-control"
            formControlName="anno"
            name="anno"
            >
            <option value="{{ currentYear }}" selected>
              {{ currentYear }}
            </option>

            <option *ngFor="let year of yearArray" value="{{ year }}">
              {{ year }}
            </option>
          </select>
        </div>
        <div class="form-group field-16">
          <label>Ambito</label>
          <select
            class="form-control"
            formControlName="tipoStruttura"
            name="tipoStruttura"

          >
          <option value="">Tutte</option>
          <option value="SEMIRESIDENZIALE">Semiresidenziale</option>
          <option value="TERRITORIALE">Territoriale</option>
          </select>
        </div>
        <div class="form-group field-32">
          <label>Struttura</label>
          <select
            class="form-control"
            formControlName="struttura"
            name="struttura"

          >
            <option value="">Seleziona Struttura</option>
            <option
              [hidden]="(ricercaForm.get('tipoStruttura')?.value) != '' ?  !struttura.descrTipoStruttura?.toString()?.includes(ricercaForm.get('tipoStruttura')?.value) : false "
              *ngFor="let struttura of strutture.content"
              value="{{ struttura.codStruttura }}"
              value=""
            >
              {{ struttura.denominazioneStruttura }}
            </option>
          </select>
        </div>
        <div class="form-group field-16">
          <label>Stato</label>
          <select
            class="form-control"
            formControlName="stato"
            name="stato"
            tabindex="-98"
          >
            <option value="2">Da Validare</option>
            <option value="3">Validato</option>
            <option value="4">Scartato</option>
          </select>
        </div>
        <div class="form-group field-16">
          <label>Codice Fiscale</label>
        <input type="text" class="form-control" name="codiceFiscale" style="width: 186px!important;"
               [pattern]="'^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}|^[ENI|eni|stp|STP]{3}[0-9]{13}$'"
               formControlName="codiceFiscale" maxlength="16">
        </div>
      </div>
    </form>
  </div>
  <div class="card-footer">
    <div class="btn-toolbar">
      <div class="btn-group-left">
        <button
          class="btn btn-outline-secondary"
          (click)="resetFormRicerca()"
        >
          Pulisci
        </button>
      </div>
      <div class="btn-group-center"></div>
      <div class="btn-group-right">
        <button class="btn btn-secondary" (click)="getAslValidator()">Cerca</button>
      </div>
    </div>
  </div>



<!-- Risultati -->
<div
  *ngIf="isLoading"
  class="spinner-border text-primary landingspinner"
  style="width: 5rem; height: 5rem"
  role="status"
>
  <span class="sr-only">Loading...</span>
</div>
<div class="card mt-6" style="margin-top: 30px" >
  <div class="card-body">
    <div class="table-responsive">
      <div class="list-table table-even">
        <div class="card-footer">
          <div class="btn-toolbar" style="justify-content: flex-end !important;">
            <div class="btn-group-left" *ngIf="getStato()==2 && pagina.content.length > 0" style="padding: 10px; display: flex; ">
              <button [hidden]="isNotDaValidare"
                class="btn btn-sm btn-secondary"
                (click)="validaTuttaLaPagina()"
              >
                Valida Tutta la pagina
              </button>
              <button [hidden]="isNotDaValidare" class="btn btn-sm btn-outline-secondary" (click)="scartaTuttaLaPagina()">
                Scarta tutta la pagina
              </button>
            </div>
            <div class="btn-group-center"></div>
            <div class="btn-group-right" *ngIf="getStato()==2 && pagina.content.length > 0" style="padding: 10px">
              <button [hidden]="isNotDaValidare"
                      class="btn btn-sm btn-secondary"
                      (click)="$event.preventDefault(); openValidaTuttiDialog(validatutto)"
              >
                Valida Tutto
              </button>
              <button [hidden]="isNotDaValidare" class="btn btn-sm btn-outline-secondary" (click)="$event.preventDefault(); openScartaTuttiDialog(scartatutto)">
                Scarta tutto
              </button>
            </div>
          </div>
        </div>
        <table class="table-colf table-lg">
          <thead>
            <tr>
              <th *ngIf="getStato()==2">Valida</th>
              <th *ngIf="getStato()==2">Scarta</th>
              <th *ngIf="getStato()>2" colspan="2"></th>
              <th>Codice Fiscale</th>
              <th>Ambito</th>
              <th>Struttura</th>
              <th>Nr. Contatti</th>
              <th>Nr. Interventi</th>
              <th>Nr. Diagnosi</th>
              <th>Nr. Presenze</th>
              <th>Nr. Prestazioni</th>
              <th></th>
            </tr>
          </thead>
          <tbody>

          <ng-container *ngIf="!pagina.loading">
            <tr *ngFor="let validator of pagina.content"
            [hidden]="validator.numeroContatti == 0 && validator.numeroContatti == 0 && validator.numeroInterventi == 0 && validator.numeroDiagnosi == 0 && validator.numeroPresenze == 0 && validator.numeroPrestazioni == 0  " >
              <td data-label="Valida" *ngIf="getStato()==2" style="vertical-align: middle">
                <input type="checkbox" name="operation" [id]="'valida_'+validator.id "  class="valida"  [value]="validator.id"
                (click)="onChangeValida($event)"
                [checked]="isSelectedValidati(validator.id)"
                [hidden]="isNotDaValidare">
              </td>
              <td data-label="Scarta" *ngIf="getStato()==2" style="vertical-align: middle">
                <input type="checkbox" name="operation" [id]="'scarta_'+validator.id " class="scarta" [value]="validator.id"
                (click)="onChangeScarta($event)"
                [checked]="isSelectedScartati(validator.id)"
                [hidden]="isNotDaValidare">
              </td>
              <td *ngIf="getStato()>2" colspan="2" style="vertical-align: middle">
                <!--button class="btn btn-secondary" *ngIf="validator.numeroContatti&&validator.numeroContatti>0" (click)="riabilitaModifica(validator.id, 'contatto')">
                  Riabilita modifica
                </button-->
              </td>
              <td data-label="Codice Fiscale" style="vertical-align: middle">
                {{ validator.codiceFiscale }}
              </td>
              <td data-label="Tipo Struttura" style="vertical-align: middle">
                {{ validator.tipoStruttura }}
              </td>
              <td data-label="Struttura" style="vertical-align: middle">
                {{ validator.struttura }}
              </td>
              <td data-label="Nr. Contatti" style="vertical-align: middle">
                {{ validator.numeroContatti }}
              </td>
              <td data-label="Nr. Interventi" style="vertical-align: middle">
                {{ validator.numeroInterventi }}
              </td>
              <td data-label="Nr. Diagnosi" style="vertical-align: middle">
                {{ validator.numeroDiagnosi }}
              </td>
              <td data-label="Nr. Presenza" style="vertical-align: middle">
                {{ validator.numeroPresenze }}
              </td>
              <td data-label="Nr. Prestazioni" style="vertical-align: middle">
                {{ validator.numeroPrestazioni }}
              </td>
              <td data-label="Dettaglio" style="vertical-align: middle">
                <button routerLink="/dettaglio-dati-in-ingresso/{{validator.id}}/{{effectiveSearchedStato}}" class="btn btn-outline-secondary btn-sm">DETTAGLIO</button>
              </td>
            </tr>
          </ng-container>
            <tr *ngIf="pagina.content.length == 0 && !pagina.loading" style="vertical-align: middle">
              <td colspan="11">
                <p class="text-center">Impostare un filtro di ricerca</p>
              </td>
            </tr>
            <tr *ngIf="pagina.loading">
              <td [attr.colspan]="12">
                <div class="spinner-border centered"></div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- PAGINATION -->
    <app-paginator
      [page]="pagina"
      (cambiaPaginaEvent)="cambiaPagina($event)"
    ></app-paginator>
  </div>
  <div class="pt-5 card-body" [hidden]="selScartati.length == 0" >
    <div class="form-group has-feedback">
      <label>Motivazione dello scarto*</label>
      <textarea class="form-control" name="motivazioniScarti"
        [(ngModel)]="motivazioneScarto"
      ></textarea>
      <div class="form-feedback">
        <div class="feedback-text invalid-feedback">&nbsp;</div>
      </div>
    </div>
  </div>

  <div class="card-footer" [hidden]="selScartati.length == 0 && selValidati.length == 0">
    <div class="btn-toolbar">
      <div class="btn-group-left">
      </div>
      <div class="btn-group-center"></div>
      <div class="btn-group-right">
        <button class="btn btn-secondary" (click)="salvaTutto()">Salva</button>
      </div>
    </div>
  </div>

</div>
</div>
<ng-template #validatutto let-modal>
  <div class="modal-header" style="background-color: #012346 !important; cursor: pointer;">
    <h4 class="modal-title" id="modal-basic-title" style="color: white;">Valida Tutto</h4>
  </div>
  <div class="modal-body">
    Sei sicuro di voler validare tutti i dati?
    <br> Verranno validati:<br>
    <ul>
      <li>n. <b>{{tuttiDatiInIngressoCount.content[0].numeroContatti}}</b> Contatti</li>
      <li>n. <b>{{tuttiDatiInIngressoCount.content[0].numeroInterventi}}</b> Interventi</li>
      <li>n. <b>{{tuttiDatiInIngressoCount.content[0].numeroDiagnosi}}</b> Diagnosi</li>
      <li>n. <b>{{tuttiDatiInIngressoCount.content[0].numeroPresenze}}</b> Presenze</li>
      <li>n. <b>{{tuttiDatiInIngressoCount.content[0].numeroPrestazioni}}</b> prestazioni</li>
    </ul>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="validaTutti(); modal.close('')">Si</button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('')">No</button>
  </div>
</ng-template>
<ng-template #scartatutto let-modal>
  <div class="modal-header" style="background-color: #012346 !important; cursor: pointer;">
    <h4 class="modal-title" id="modal-basic-title" style="color: white;">Scarta Tutto</h4>
  </div>
  <div class="modal-body">
    Sei sicuro di voler scartare tutti i dati?
    <br> Verranno scartati:<br/>
    <ul>
      <li>n. <b>{{tuttiDatiInIngressoCount.content[0].numeroContatti}}</b> Contatti</li>
      <li>n. <b>{{tuttiDatiInIngressoCount.content[0].numeroInterventi}}</b> Interventi</li>
      <li>n. <b>{{tuttiDatiInIngressoCount.content[0].numeroDiagnosi}}</b> Diagnosi</li>
      <li>n. <b>{{tuttiDatiInIngressoCount.content[0].numeroPresenze}}</b> Presenze</li>
      <li>n. <b>{{tuttiDatiInIngressoCount.content[0].numeroPrestazioni}}</b> prestazioni</li>
    </ul>

    <div class="pt-5 card-body"  >
      <div class="form-group has-feedback">
        <label>Motivazione dello scarto*</label>
        <textarea class="form-control" name="motivazioniScarti"
                  [(ngModel)]="motivazioneScarto"
        ></textarea>
        <div class="form-feedback">
          <div class="feedback-text invalid-feedback">&nbsp;</div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="scartaTutti();modal.close('')">Si</button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('')">No</button>
  </div>
</ng-template>
