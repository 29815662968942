<div  class="tab-pane fade active show" id="anagrafica" role="tabpanel"
     aria-labelledby="anagrafica-tab">
  <div class="group-inline pt-4" style="padding: 10px">
    <!-- datanascita -->
    <div class="group-inline pt-4">
      <div class="form-group field-16 has-feedback">
        <label style="font-weight: bold">Codice fiscale: <span class="bordodati">{{dettaglioAnagrafica?.codiceFiscale?.toUpperCase()??' N.D.'}}</span></label>
      </div>
      <div class="form-group field-10 has-feedback">
        <label style="font-weight: bold">Data di nascita: <span class="bordodati">{{dettaglioAnagrafica?.dataNascita | date:'yyyy-MM-dd'}}</span></label>
      </div>
    <div class="form-group field-20 has-feedback">
      <label style="font-weight: bold">Cognome: <span class="bordodati">{{dettaglioAnagrafica?.cognome?.toUpperCase()??' N.D.'}}</span></label>
    </div>
    <div class="form-group field-20 has-feedback">
      <label style="font-weight: bold">Nome: <span class="bordodati">{{dettaglioAnagrafica?.nome?.toUpperCase()??' N.D.'}}</span></label>
    </div>
    <div class="form-group has-feedback">
      <label style="font-weight: bold">Sesso: <span class="bordodati">{{dettaglioAnagrafica?.sesso == 'M'?'MASCHIO':(dettaglioAnagrafica?.sesso == 'F'?'FEMMINA':'Non noto/Non risulta')}}</span></label>
      </div>
      <!-- COMUNE nascita TYPEAHEAD -->
      <div class="form-group field-20">
        <label style="font-weight: bold">Comune di nascita: <span class="bordodati">{{dettaglioAnagrafica?.descrizioneComuneNascita?.toUpperCase()??' N.D.'}}</span></label>
      </div>
    </div>

  </div>
  <!-- stato civile citadinanza-->
  <div class="group-inline" style="padding: 10px">
    <!-- citadinanza -->
    <div class="form-group field-20 has-feedback">
      <label style="font-weight: bold">Cittadinanza: <span class="bordodati">{{dettaglioAnagrafica?.cittadinanza?.toUpperCase()??' N.D.'}}</span></label>
    </div>
  </div>
  <div class="group-inline pt-4" style="padding: 10px">
    <!-- Regione -->
    <div class="form-group field-24 has-feedback">
      <label style="font-weight: bold">Regione: <span class="bordodati">{{dettaglioAnagrafica?.regioneResidenza?.toUpperCase()??' N.D.'}}</span></label>
    </div>
    <!-- Comune residenza -->
    <div class="form-group field-20 has-feedback" *ngIf="dettaglioAnagrafica?.comuneResidenza">
      <label style="font-weight: bold">Comune: <span class="bordodati">{{dettaglioAnagrafica?.comuneResidenza?.toUpperCase()??' N.D.'}}</span></label>
    </div>
    <!-- Stato -->
    <div class="form-group field-24 has-feedback" *ngIf="dettaglioAnagrafica?.statoResidenza">

      <label style="font-weight: bold">Stato: <span class="bordodati">{{dettaglioAnagrafica?.statoResidenza?.toUpperCase()??' N.D.'}}</span></label>
    </div>
    <!-- Asl -->
    <div class="form-group field-16 has-feedback" *ngIf="dettaglioAnagrafica?.aslResidenza">
      <label style="font-weight: bold">ASL: <span class="bordodati">{{dettaglioAnagrafica?.aslResidenza?.toUpperCase()??' N.D.'}}</span></label>
    </div>
    <!-- Distretto -->
    <div class="form-group field-20 has-feedback" *ngIf="dettaglioAnagrafica?.distrettoResidenza">
      <label style="font-weight: bold">Distretto: <span class="bordodati">{{dettaglioAnagrafica?.distrettoResidenza?.toUpperCase()??' N.D.'}}</span></label>
    </div>
    <!-- Municipio -->
    <div class="form-group field-20 has-feedback demon-related demon-hide"  *ngIf="dettaglioAnagrafica?.municipioResidenza">
      <label style="font-weight: bold">Municipio: <span class="bordodati">{{dettaglioAnagrafica?.municipioResidenza?.toUpperCase()??' N.D.'}}</span></label>
    </div>
  </div>
  <div class="group-inline" style="padding: 10px">
    <div class="form-group field-18 has-feedback">
      <label style="font-weight: bold">Stato civile: <span class="bordodati">{{dettaglioAnagrafica?.statoCivile?.toUpperCase()??' N.D.'}}</span></label>
    </div>
    <div class="form-group field-60 has-feedback">
      <label style="font-weight: bold">Collocazione socio ambientale: <span class="bordodati">{{dettaglioAnagrafica?.collocazioneSocioAmbientale?.toUpperCase()??' N.D.'}}</span></label>
    </div>
    <div class="form-group field-30 has-feedback">
      <label style="font-weight: bold">Titolo di studio: <span class="bordodati">{{dettaglioAnagrafica?.titoloDiStudio?.toUpperCase()??' N.D.'}}</span></label>
    </div>
    <div class="form-group field-36 has-feedback">
      <label style="font-weight: bold">Attività: <span class="bordodati">{{dettaglioAnagrafica?.attivita?.toUpperCase()??' N.D.'}}</span></label>
    </div>
  </div>
  <div class="group-inline">
    <div class="form-group has-feedback" style="padding: 10px">
      <label style="font-weight: bold">E' familiare di un paziente in trattamento: <span class="bordodati">{{dettaglioAnagrafica?.familiarePaziente == '1'?'SI':(dettaglioAnagrafica?.familiarePaziente == '2'?'NO':'Non noto/Non risulta')}}</span></label>
    </div>
    <div class="form-group field-60 has-feedback" style="padding: 10px">
      <label style="font-weight: bold">Invalidità: <span class="bordodati">{{dettaglioAnagrafica?.invalidita?.toUpperCase()??' N.D.'}}</span></label>
    </div>
    <div class="form-group field-24 has-feedback" style="padding: 10px">
      <label style="font-weight: bold">Tutela giuridica: <span class="bordodati">{{dettaglioAnagrafica?.tutelaGiuridica?.toUpperCase()??' N.D.'}}</span></label>
    </div>
    <div class="form-group field-50 has-feedback" style="padding: 10px">
      <label style="font-weight: bold">Attualmente sottoposto a provvedimenti giudiziari o a una misura di sicurezza: <span class="bordodati">{{dettaglioAnagrafica?.attualmenteSottoposto?.toUpperCase()??' N.D.'}}</span></label>
    </div>
    <div class="form-group field-50 has-feedback" style="padding: 10px">
      <label style="font-weight: bold">Precedente internamento o detenzione in ambito Psichiatrico Penitenziario: <span class="bordodati">{{dettaglioAnagrafica?.precedentementeInternato == '1'?'SI':(dettaglioAnagrafica?.precedentementeInternato == '2'?'NO':'Non noto/Non risulta')}}</span></label>
    </div>
  </div>
</div>
